<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="contractSearchForm"
			:model="contractSearchForm"
			label-width="60px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="医院">
				<el-select
					ref="selectComponent"
					v-model="contractSearchForm.hid"
					placeholder="请选择医院"
					clearable
					filterable
					style="width: 100%"
					@change="handlerHospital()"
				>
					<el-option
						v-for="item in contractData"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item label="医院">
				<el-select
					ref="selectComponent"
					v-model="contractSearchForm.hid"
					placeholder="请选择医院"
					clearable
					filterable
					multiple
					:multiple-limit="1"
					style="width: 100%"
					@change="handlerHospital()"
				>
					<el-option
						v-for="item in contractData"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item> -->

			<el-form-item label="科室">
				<SpecialSelect
					:materialTypeDataP="departmentData"
					@setSelectformDataS="getSelectformDids"
					v-if="showDepmentSelect"
					ref="departmentRef"
				></SpecialSelect>
				<el-input
					v-else
					v-model="contractSearchForm.keywords"
					placeholder="请输入"
				/>
			</el-form-item>
			<el-form-item label="分类">
				<SpecialSelect
					:materialTypeDataP="deviceTypeData"
					@setSelectformDataS="getSelectformTypes"
					ref="departmentRef"
				></SpecialSelect>
			</el-form-item>

			<el-form-item label="关键字">
				<el-input
					v-model="contractSearchForm.keywords"
					placeholder="请输入关键字"
				/>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>
	</div>
</template>

<script>
import {
	getcompanysignListService,
	getHospitalByUserIdListService
} from '@s/company/contract/ContractService';
import { selectPoByOrgIdService } from '@s/hospital/department/DepartmentService';
import { devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';
import { hgetStorage } from '@u/htools.web.js';

import SpecialSelect from '@c/SpecialSelect';

export default {
	components: {
		SpecialSelect
	},
	data() {
		return {
			showDepmentSelect: true,
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			departmentData: [],
			contractSearchForm: {
				hid: null,
				hids: [],
				hidsTemp: [0]
			},
			deviceTypeData: [],
			contractData: [],
			// 就剩一个值的时候存起来
			oneValue: []
		};
	},
	async created() {
		await this.contractList();
		await this.deviceTypeList();
	},
	methods: {
		// 分类
		getSelectformTypes(data) {
			this.contractSearchForm.devTypes = data;
		},
		// 科室
		getSelectformDids(data) {
			this.contractSearchForm.dids = data;
		},
		handlerHospital() {
			console.log('this.$refs.departmentRef');
			console.log(this.$refs.departmentRef);
			this.showDepmentSelect = false;
			if (!this.contractSearchForm.hid) {
				this.contractSearchForm.dids = '';
				this.parentName = '';
				this.departmentData = [];
			} else {
				this.selectPoByOrgIdService();
			}

			setTimeout(() => {
				this.showDepmentSelect = true;
			}, 100);
		},

		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.name = item.department;
					item.level = TLevel;
					item.isOpen = false;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async selectPoByOrgIdService() {
			const json = {
				orgId: this.contractSearchForm.hid
			};
			const res = await selectPoByOrgIdService(json);

			this.addTreeKey(res, 0);
			this.departmentData = [...res];
		},

		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.name = item.deviceType;
					item.level = TLevel;
					item.isOpen = false;
					if (item.children) {
						this.addTreeKeyTypeList(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
		},
		nodeClickHandler(data) {
			console.log(data);
			// this.searchFormData.hospitalOfficeId = data.id;
			// this.parentName = data.department;
		},

		openMaterial(row) {
			console.log(row);
			this.materialTypeData.forEach((item, index, arr) => {
				if (item.id == row.id) {
					if (item.isOpen) {
						this.$set(arr, index, { ...item, isOpen: false });
					} else {
						this.$set(arr, index, { ...item, isOpen: true });
					}
				}
			});
		},
		async contractList() {
			// 是否是三方管理员
			const isAdmin = hgetStorage('roleIds').includes(2);
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			if (isAdmin) {
				const res = await getcompanysignListService(dataJson);
				this.contractData = [...res.records];
			} else {
				const res = await getHospitalByUserIdListService(dataJson);
				this.contractData = [...res];
			}
			if (this.contractData.length > 0) {
				this.contractSearchForm.hidsTemp = [];
			}
			this.contractData.forEach(item => {
				this.contractSearchForm.hidsTemp.push(item.id);
			});
			this.contractSearchForm.hids = [this.contractSearchForm.hid];
			this.$emit('searchForm', this.contractSearchForm);
		},
		searchHandler() {
			this.contractSearchForm.hids = [this.contractSearchForm.hid];
			this.$emit('searchForm', this.contractSearchForm);
		}
	}
};
</script>

<style lang="less" scoped>
.form {
	display: flex;
}

.isOpenIcon {
	width: 40px;
	height: 34px;
	line-height: 1em;
	// background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

/deep/.materialSelectItem {
	padding-left: 0 !important;
}
</style>
