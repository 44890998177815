import {
    hgetRequest,
    hpostRequest
} from '../../util/htools.axios';
/*
 *@Description: 领用借用新增
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const borrowListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/borrow/search', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 领用借用新增
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const borrowAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/borrow/add', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 领用借用修改
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const borrowEditService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/borrow/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 领用借用删除
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const borrowDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/borrow/delete', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 领用借用详情
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const borrowDetailService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/borrow/getByKey', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

export default borrowListService;
