<template>
	<el-form
		ref="elForm"
		:model="elForm"
		:rules="elFormRules"
		label-width="110px"
	>
		<el-collapse v-model="activeName" @change="handleChangeActiveName">
			<el-collapse-item title="不良事件登记" name="1">
				<el-form-item label="使用科室" prop="departid">
					<treeselect
						v-model="elForm.departid"
						:multiple="false"
						:options="optionsDept"
						@open="clearValiddevInfoDepartmentId"
						class="select-item selectdepartid"
						style="width:190px; height:32px;z-index:9999999;"
						:disable-branch-nodes="true"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
						@input="updateValueDepartid"
						z-index="9999999"
						:max-height="200"

					/>
				</el-form-item>
				<el-form-item label="设备" prop="devid">
					<el-select
						clearable
						filterable
						v-model="elForm.devid"
						placeholder="请选择设备"
						style="width: 100%"
						@change="setDevInfo"
					>
						<el-option
							v-for="item in devData"
							:key="item.devInfoId"
							:label="item.devInfoName"
							:value="item.devInfoId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item></el-form-item>

				<el-form-item label="设备名称" prop="devname">
					<el-input
						v-model="elForm.devname"
						placeholder="请输入设备名称"
					/>
				</el-form-item>
				<el-form-item label="设备编号" prop="devnum">
					<el-input
						v-model="elForm.devnum"
						placeholder="请输入设备编号"
					/>
				</el-form-item>
				<el-form-item label="设备规格" prop="devguige">
					<el-input
						v-model="elForm.devguige"
						placeholder="请输入设备规格"
					/>
				</el-form-item>
				<el-form-item label="设备sn" prop="devsn">
					<el-input
						v-model="elForm.devsn"
						placeholder="请输入设备sn"
					/>
				</el-form-item>
				<el-form-item label="设备厂家" prop="devfac">
					<el-input
						v-model="elForm.devfac"
						placeholder="请输入设备厂家"
					/>
				</el-form-item>
				<el-form-item label="设备品牌" prop="devbrand">
					<el-input
						v-model="elForm.devbrand"
						placeholder="请输入设备品牌"
					/>
				</el-form-item>
				<el-form-item label="设备注册号" prop="devRegisterNum">
					<el-input
						v-model="elForm.devRegisterNum"
						placeholder="请输入设备注册号"
					/>
				</el-form-item>
				<el-form-item label="设备生产批次" prop="devProductBatch">
					<el-input
						v-model="elForm.devProductBatch"
						placeholder="请输入设备生产批次"
					/>
				</el-form-item>
				<el-form-item label="生成时间" prop="devProductDate">
					<el-date-picker
						v-model="elForm.devProductDate"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="设备到期时间" prop="devExpireDate">
					<el-date-picker
						v-model="elForm.devExpireDate"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="国家报告编号" prop="contryReportNum">
					<el-input
						v-model="elForm.contryReportNum"
						placeholder="请输入国家报告编号"
					/>
				</el-form-item>

				<el-form-item label="状态">
					<el-select
						clearable
						filterable
						v-model="elForm.reportState"
						placeholder="请选择状态"
						style="width: 100%"
					>
						<el-option
							v-for="item in reportStateData"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-collapse-item>
			<el-collapse-item title="不良事件概况" name="2">
				<el-form-item label="发生时间" prop="eventDate">
					<el-date-picker
						v-model="elForm.eventDate"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="获知时间" prop="eventKnowDate">
					<el-date-picker
						v-model="elForm.eventKnowDate"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="伤害等级" prop="damageLevel">
					<el-select
						clearable
						filterable
						v-model="elForm.damageLevel"
						placeholder="请选择伤害等级"
						style="width: 100%"
					>
						<el-option
							v-for="item in damageLevelData"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>

				<div style="clear: both"></div>
				<el-form-item
					label="伤害表现"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="damageShow"
				>
					<el-input
						type="textarea"
						v-model="elForm.damageShow"
						placeholder="请输入伤害表现"
						class="textAreaRemakes"
					/>
				</el-form-item>
				<div style="clear: both"></div>
				<el-form-item
					label="设备故障表现"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="devErrorShow"
				>
					<el-input
						type="textarea"
						v-model="elForm.devErrorShow"
						placeholder="请输入设备故障表现"
						class="textAreaRemakes"
					/>
				</el-form-item>
				<el-form-item label="病人姓名" prop="patiendsName">
					<el-input
						v-model="elForm.patiendsName"
						placeholder="请输入病人姓名"
					/>
				</el-form-item>
				<el-form-item label="病人生日" prop="patiendsBirthday">
					<el-date-picker
						v-model="elForm.patiendsBirthday"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="病人性别" prop="patiendsSex">
					<el-select
						clearable
						filterable
						v-model="elForm.patiendsSex"
						placeholder="请选择性别"
						style="width: 100%"
					>
						<el-option
							v-for="item in patiendsSexData"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="病人年龄" prop="patiendsAge">
					<el-input
						v-model="elForm.patiendsAge"
						placeholder="请输入病人年龄"
					>
						<el-select
							v-model="elForm.patiendsAgeType"
							slot="append"
							placeholder="请选择"
							class="selectAgeType"
						>
							<el-option label="年" value="0"></el-option>
							<el-option label="月" value="1"></el-option>
							<el-option label="日" value="2"></el-option>
						</el-select>
					</el-input>
				</el-form-item>

				<el-form-item label="病历号" prop="patiendsNum">
					<el-input
						v-model="elForm.patiendsNum"
						placeholder="请输入病历号"
					/>
				</el-form-item>
				<el-form-item
					label="既往病史"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="patiendsHistory"
				>
					<el-input
						type="textarea"
						v-model="elForm.patiendsHistory"
						placeholder="请输入既往病史"
						class="textAreaRemakes"
					/>
				</el-form-item>
			</el-collapse-item>
			<el-collapse-item title="使用情况" name="3">
				<el-form-item
					label="期待效果"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="expectEffect"
				>
					<el-input
						type="textarea"
						v-model="elForm.expectEffect"
						placeholder="请输入期待效果"
						class="textAreaRemakes"
						style
					/>
				</el-form-item>
				<el-form-item label="设备使用时间" prop="devUseDate">
					<el-date-picker
						v-model="elForm.devUseDate"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item
					label="设备使用地点类型"
					prop="usePlaceType"
					label-width="150px"
				>
					<el-select
						clearable
						filterable
						v-model="elForm.usePlaceType"
						placeholder="请选择设备使用地点"
						style="width: 100%"
					>
						<el-option
							v-for="item in usePlaceTypeData"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item></el-form-item>
				<el-form-item
					label="设备使用地点"
					prop="usePlaceName"
					style="width: 80%;"
				>
					<el-input
						style="width: 76.5%;"
						v-model="elForm.usePlaceName"
						placeholder="请输入设备使用地点"
					/>
				</el-form-item>

				<el-form-item
					label="设备使用过程"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="useProcess"
				>
					<el-input
						type="textarea"
						v-model="elForm.useProcess"
						placeholder="请输入设备使用过程"
						class="textAreaRemakes"
					/>
				</el-form-item>
				<el-form-item
					label="合并用药械情况说明"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="useMemo"
				>
					<el-input
						type="textarea"
						v-model="elForm.useMemo"
						placeholder="请输入合并用药械情况说明"
						class="textAreaRemakes"
					/>
				</el-form-item>
			</el-collapse-item>
			<el-collapse-item title="事件初步原因分析与处置" name="4">
				<el-form-item label="原因类型" prop="resoneType">
					<el-select
						clearable
						filterable
						v-model="elForm.resoneType"
						placeholder="请选择原因类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in resoneTypeData"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="原因分析"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="resoneInfo"
				>
					<el-input
						type="textarea"
						v-model="elForm.resoneInfo"
						placeholder="请输入原因分析"
						class="textAreaRemakes"
					/>
				</el-form-item>
				<el-form-item
					label="初步处置"
					style="width: 100%; height: 120px; margin-bottom:0;"
					prop="firstOperation"
				>
					<el-input
						type="textarea"
						v-model="elForm.firstOperation"
						placeholder="请输入初步处置"
						class="textAreaRemakes"
					/>
				</el-form-item>
			</el-collapse-item>
		</el-collapse>
		<div style="text-align: right; margin-top: 20px">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</div>
	</el-form>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { devBaseListService } from '@s/hospital/devBase/DevBaseService';
import {
	addService,
	editService,
	detailService
} from '@s/hospital/BadEventService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';

export default {
	props: ['selectData_p', 'editId_p'],
	components: {
		Treeselect
	},
	data() {
		return {
			// 添加用户 部门下拉
			optionsDept: [],
			devData: [],
			reportStateData: [
				{ id: 0, name: '内部登记' },
				{ id: 1, name: '上报提交' }
			],
			damageLevelData: [
				{ id: 0, name: '死亡' },
				{ id: 1, name: '严重伤害' },
				{ id: 2, name: '其他' }
			],
			patiendsSexData: [
				{ id: 0, name: '男' },
				{ id: 1, name: '女' }
			],
			usePlaceTypeData: [
				{ id: 0, name: '医疗机构' },
				{ id: 1, name: '家庭' },
				{ id: 2, name: '其他' }
			],
			resoneTypeData: [
				{ id: 0, name: '产品原因（包括说明书等）' },
				{ id: 1, name: '操作原因' },
				{ id: 2, name: '患者自身原因' },
				{ id: 3, name: '无法确定' }
			],
			activeName: ['1', '2', '3', '4'],
			editId: -1,
			elForm: {
				// 不良事件登记 =================================================================
				departid: null, // 科室id
				devid: null, // 设备id
				devname: null, // 设备名称
				devnum: null, // 设备编号
				devguige: null, // 设备规格
				devsn: null, // 设备sn
				devfac: null, // 设备厂家
				devbrand: null, // 设备品牌
				devRegisterNum: null, // 设备注册号
				devProductBatch: null, // 设备生产批次
				devProductDate: null, // 生成时间
				devExpireDate: null, // 设备到期时间
				contryReportNum: null, // 国家报告编号
				reportState: null, // 报告状态
				// 不良事件概况 =================================================================
				eventDate: null, // 发生时间
				eventKnowDate: null, // 获知时间
				damageLevel: null, // 伤害等级 下拉
				damageShow: null, // 伤害表现 多行
				devErrorShow: null, // 设备故障表现 多行
				patiendsName: null, // 病人姓名
				patiendsBirthday: null, // 病人生日
				patiendsSex: null, // 病人性别 下拉
				patiendsAge: null, // 病人年龄
				patiendsAgeType: '0', // 病人年龄类型 下拉
				patiendsNum: null, // 病历号
				patiendsHistory: null, // 既往病史 多行
				// 使用情况 =================================================================
				expectEffect: null, // 期待效果
				devUseDate: null, // 设备使用时间
				usePlaceType: null, // 设备使用地点类型 下拉
				usePlaceName: null, // 设备使用地点
				useProcess: null, // 设备使用过程
				useMemo: null, // 合并用药械情况说明
				// 事件初步原因分析与处置 =================================================================
				resoneType: null, // 原因类型
				resoneInfo: null, // 原因分析 多行
				firstOperation: null // 初步处置 多行
				// files: null // 文件
			},

			elFormRules: {
				departid: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'blur'
					}
				],
				devid: [
					{
						required: true,
						message: '请选择设备',
						trigger: 'blur'
					}
				],
				eventDate: [
					{
						required: true,
						message: '请选择发生时间',
						trigger: 'blur'
					}
				],
				eventKnowDate: [
					{
						required: true,
						message: '请选择获知时间',
						trigger: 'blur'
					}
				],
				damageLevel: [
					{
						required: true,
						message: '请选择伤害等级',
						trigger: 'blur'
					}
				],
				devUseDate: [
					{
						required: true,
						message: '请选择设备使用时间',
						trigger: 'blur'
					}
				],
				usePlaceType: [
					{
						required: true,
						message: '请选择设备使用地点类型',
						trigger: 'blur'
					}
				],
				usePlaceName: [
					{
						required: true,
						message: '请输入设备使用地点',
						trigger: 'blur'
					}
				],
				useProcess: [
					{
						required: true,
						message: '请输入设备使用过程',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					// console.log(this.parentName, 'this.parentNname');
					this.elForm = { ...newValue[0] };
					this.elForm.patiendsAgeType = this.elForm.patiendsAgeType.toString();
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		// if (this.editId !== -1) {
		// 	this.getDetail();
		// }
	},
	mounted() {
		// 添加用户部门下拉
		this.departmentList();
		// 设备下拉
		this.getDevList();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		clearValiddevInfoDepartmentId() {
			// this.$refs.elForm.clearValidate(['departid']);
		},
		updateValueDepartid() {
			console.log('this.elForm.departid');
			console.log(this.elForm.departid);
			this.elForm.devid = null;
			this.elForm.devname = null; // 设备名称
			this.elForm.devnum = null; // 设备编号
			this.elForm.devguige = null; // 设备规格
			this.elForm.devsn = null; // 设备sn
			this.elForm.devfac = null; // 设备厂家
			this.elForm.devbrand = null; // 设备品牌
			// this.elForm.devRegisterNum = null; // 设备注册号
			// this.elForm.devProductBatch = null; // 设备生产批次
			// this.elForm.devProductDate = null; // 生成时间
			// this.elForm.devExpireDate = null; // 设备到期时间
			// this.elForm.contryReportNum = null; // 国家报告编号
			this.getDevList();
		},
		setDevInfo() {
			console.log(this.elForm.devid);
			console.log(this.elForm.devData);
			const devData1 = this.devData;
			const devid1 = this.elForm.devid;
			devData1.forEach(item => {
				if (item.devInfoId == devid1) {
					this.elForm.devname = item.devInfoName; // 设备名称
					this.elForm.devnum = item.devInfoNumber; // 设备编号
					this.elForm.devguige = item.devInfoNns1; // 设备规格
					this.elForm.devsn = item.devInfoSerialNo; // 设备sn
					this.elForm.devfac = item.devInfoFactoryName; // 设备厂家
					this.elForm.devbrand = item.devInfoBrand; // 设备品牌
					// this.elForm.devRegisterNum = item.aaaaaa; // 设备注册号
					// this.elForm.devProductBatch = item.aaaaaa; // 设备生产批次
					// this.elForm.devProductDate = item.aaaaaa; // 生成时间
					// this.elForm.devExpireDate = item.aaaaaa; // 设备到期时间
					// this.elForm.contryReportNum = item.aaaaaa; // 国家报告编号
				}
			});
		},
		// 设备下拉
		async getDevList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999,
				did: this.elForm.departid
			};
			const res = await devBaseListService(dataJson);
			this.devData = res.records;
		},
		// 展开折叠面板
		handleChangeActiveName() {
			this.activeName = ['1', '2', '3', '4'];
		},
		async getDetail() {
			const dataJson = {
				id: this.editId
			};
			const res = await detailService(dataJson);
			this.elForm = { ...res };
			this.$emit('powers', res.powers);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await addService(this.elForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.elForm
						};
						await editService(dataJson);
						this.formCancel();
					}
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-collapse-item__header {
	font-weight: bold;
	font-size: 20px;
}
/deep/.el-select > .el-input {
	width: 190px;
}
/deep/ .el-input--small {
	width: 190px;
}
/deep/.textArea {
	height: 100px !important;
}
/deep/.textAreaRemakes {
	width: 89.4%;
}
/deep/.textAreaRemakes textarea {
	min-height: 100px !important;
}
/deep/ .el-collapse-item__arrow {
	display: none;
}
/deep/.selectAgeType > .el-input {
	width: 60px;
}

</style>
