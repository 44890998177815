import {
    huploadConfigJson
} from '@u/htools.web';

let envConfig = null;
const afterRouter = router => {
    router.afterEach(to => {
        if (envConfig) {
            document.title = `${envConfig.mainTitle}-${to.meta.title}`;
            // document.title = `医工云-${to.meta.title}_医工医疗设备智能运维平台`;
        } else {
            huploadConfigJson().then(res => {
                envConfig = res;
                document.title = `${res.mainTitle}-${to.meta.title}`;
                // document.title = `医工云-${to.meta.title}_医工医疗设备智能运维平台`;
            });
        }
        
        const el = document.querySelector('#windowElMain');
        el?.scrollTo(0, 0);
        // if (to.meta.title) {
        //     const keywords = document.getElementsByName('keywords');
        //     const description = document.getElementsByName('description');
        //     const head = document.getElementsByTagName('head');

        //     if (!keywords.length) {
        //         const meta1 = document.createElement('meta');
        //         meta1.name = 'keywords';
        //         head[0].insertBefore(meta1, head[0].children[0]);
        //         meta1.content = `${to.meta.title},医工云,医工医疗设备智能运维平台`;
        //     } else {
        //         keywords[0].content = `${to.meta.title},医工云,医工医疗设备智能运维平台`;
        //     }
        //     if (!description.length) {
        //         const meta2 = document.createElement('meta');
        //         meta2.name = 'description';
        //         head[0].insertBefore(meta2, head[0].children[0]);
        //         meta2.content = `${to.meta.title},医工云,医工医疗设备智能运维平台`;
        //     } else {
        //         description[0].content = `${to.meta.title},医工云,医工医疗设备智能运维平台`;
        //     }
        // }
    });
};

export default afterRouter;
