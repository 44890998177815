import {
    hgetRequest,
    hpostRequest
} from '../../util/htools.axios';

/*
 *@Description: 调拨申请列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const transferListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                begin: '',
                end: '',
                fromWarehouseId: '',
                toWarehouseId: '',
                pageNum: 1,
                pageSize: 10,
                ...data
            };
            const res = await hpostRequest('hwms/transfer/search', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 为出库下拉查找调拨单
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const transferfindForOutService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                goodsId: [],
                ...data
            };
            const res = await hpostRequest('hwms/transfer/findForOut', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const transferAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                fromWarehouseId: '',
                toWarehouseId: '',
                requestTime: '',
                items: [{
                    cnt: 0,
                    goodsId: 0,
                    memo: ''
                }],
                ...data
            };
            dataJson.items.forEach(item => {
                item.cnt = Number(item.cnt);
            });
            const res = await hpostRequest('hwms/transfer/add', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请添加 -> 物料列表添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const transferMaterialAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                cnt: 0,
                goodsId: 0,
                memo: '',
                requestId: 0,
                ...data
            };
            const res = await hpostRequest('hwms/transfer/additem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const transferEditService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                fromWarehouseId: '',
                toWarehouseId: '',
                requestTime: '',
                ...data
            };
            const res = await hpostRequest('hwms/transfer/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请编辑 -> 物料列表编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const transferMaterialEditService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                cnt: 0,
                goodsId: 0,
                memo: '',
                requestId: 0,
                ...data
            };
            const res = await hpostRequest('hwms/transfer/updateitem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const transferDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/transfer/delete', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请删除 -> 物料列表删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const transferMaterialDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/transfer/deleteitem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 调拨申请详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const transferDetailService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/transfer/getdetail', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请改变状态
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const saleApplicationChangeStateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/state', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
export default transferListService;
