import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 列表
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const listService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data,
				page: data.pageNum,
				pagesize: data.pageSize
			};
			delete dataJson.pageNum;
			delete dataJson.pageSize;
			const res = await hpostRequest(`hbtyong/uhhis/search?page=${dataJson.page}&pagesize=${dataJson.pagesize}`, dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 添加
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const addService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong/uhhis/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 编辑
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const editService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				isShow: 0,
				departmentIcon: '',
				departmentName: '',
				departmentSort: '',
				pid: '',
				state: '',
				url: '',
				...data
			};
			const res = await hpostRequest('hbtyong/uhhis/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const deleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentIdList: [],
				...data
			};
			const res = await hpostRequest('hbtyong/uhhis/deletes?apjson=apjson', JSON.stringify(dataJson.ids));
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 详情
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const detailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentId: '',
				...data
			};
			const res = await hgetRequest('hbtyong/uhhis/get1', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default listService;
