import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 员工分析
 *@ClassAuthor: Happy zxh
 *@Date: 2023-03-08 14:29:02
 */
export const empsmmarytabService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				// pageNum: 1,
				// pageSize: 10,
				begin: null,
				end: null,
				hosid: 0,
				orgid: 0,
				uname: null,
				...data
			};
			const res = await hpostRequest('hcz/empsummary/empsmmarytab', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 员工按月统计巡检次数与设备数量
 *@ClassAuthor: Happy zxh
 *@Date: 2023-03-08 14:29:02
 */
 export const empinspectcntService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: null,
				end: null,
				hosid: 0,
				orgid: 0,
				uid: 0,
				...data
			};
			const res = await hpostRequest('hcz/empsummary/empinspectcnt', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 员工按月统计维修数量与响应、到场、完成时间
 *@ClassAuthor: Happy zxh
 *@Date: 2023-03-08 14:29:02
 */
export const emprepaircntandtimebymonthService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: null,
				end: null,
				hosid: 0,
				orgid: 0,
				uid: 0,
				...data
			};
			const res = await hpostRequest('hcz/empsummary/emprepaircntandtimebymonth', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 员工按月统计保养次数与设备数量
 *@ClassAuthor: Happy zxh
 *@Date: 2023-03-08 14:29:02
 */
export const empupkeepcntService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: null,
				end: null,
				hosid: 0,
				orgid: 0,
				uid: 0,
				...data
			};
			const res = await hpostRequest('hcz/empsummary/empupkeepcnt', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修种类
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-05 14:29:02
 */
export const emprepairdevtypecntService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: null,
				end: null,
				hosid: 0,
				orgid: 0,
				uid: 0,
				...data
			};
			const res = await hpostRequest('hcz/empsummary/emprepairdevtypecnt', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default empsmmarytabService;
