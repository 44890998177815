import {
    hgetRequest,
    hpostRequest
} from '../../util/htools.axios';
/*
 *@Description: 相关单号问题
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const saleGetRequestCntService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/getRequestCnt',
                dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const saleupdateimgsService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                imgs: '',
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/updateimgs', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const saleApplicationListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                begin: '',
                end: '',
                code: '',
                states: '',
                pageNum: 1,
                pageSize: 10,
                ...data
            };
            if (dataJson.states !== '') {
                dataJson.states = [dataJson.states];
            } else {
                dataJson.states = [];
            }
            const res = await hpostRequest('hwms/sellRequest/list', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 当前库存列表 》 出库 》 相关单号 新接口
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const saleApplicationFindRequestByGoodsService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/findRequestByGoods',
                dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 为出库下拉菜单查找销售单
 *@MethodAuthor:  zxh
 *@Date: 20220727
 */
export const findSellRequestForOutService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                goodsId: [],
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/findSellRequestForOut',
                dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const saleApplicationAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                code: '',
                requestTime: '',
                items: [{
                    cnt: 0,
                    goodsId: 0,
                    memo: '',
                    price: 0,
                    supplierId: 0
                }],
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/add', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请添加 -> 物料列表添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const saleApplicationMaterialAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                cnt: 0,
                goodsId: 0,
                memo: '',
                price: 0,
                requestId: 0,
                /* supplierId: 0, */
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/additem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const saleApplicationEditService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                code: '',
                requestTime: '',
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请编辑 -> 物料列表编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const saleApplicationMaterialEditService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                cnt: 0,
                goodsId: 0,
                memo: '',
                price: 0,
                requestId: 0,
                supplierId: 0,
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/updateitem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const saleApplicationDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/delete', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请删除 -> 物料列表删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const saleApplicationMaterialDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/deleteitem', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const saleApplicationDetailService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/getdetail', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 销售申请改变状态
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const saleApplicationChangeStateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/sellRequest/state', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

// 设置付款
export const saleApplicationFukuanService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: data.id,
                state: data.state
            };
            const res = await hgetRequest('hwms/sellRequest/fukuan', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

// 设置开票
export const saleApplicationKaipiaoService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: data.id,
                state: data.state
            };
            const res = await hgetRequest('hwms/sellRequest/kaipiao', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// /web/wms/api/sellRequest/detailsearch 销售详情查询
export const detailSearchService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/sellRequest/detailsearch', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 销售申请取消
export const saleRequestCancelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id
			};
			const res = await hgetRequest('hwms/sellRequest/cancel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default saleApplicationListService;
