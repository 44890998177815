import { render, staticRenderFns } from "./MaterialChoose.vue?vue&type=template&id=41e38304&scoped=true"
import script from "./MaterialChoose.vue?vue&type=script&lang=js"
export * from "./MaterialChoose.vue?vue&type=script&lang=js"
import style0 from "./MaterialChoose.vue?vue&type=style&index=0&id=41e38304&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e38304",
  null
  
)

export default component.exports