<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<SellRequestDetailSearch
				@searchForm="searchFormHandler"
				@download="downloadExlcel"
				:downLoading="downLoading"
			></SellRequestDetailSearch>
			<div style="margin-bottom: 10px; margin-top: 10px">
				<!-- 功能按钮 -->
				<!-- <el-button type="success" @click="addStockHandler">添加入库</el-button> -->
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="销售单号" prop="code"></el-table-column>
			<el-table-column
				label="项目组"
				prop="warehouseName"
			></el-table-column>
			<el-table-column label="科室" prop="departname"></el-table-column>
			<el-table-column label="物料" prop="goodsname">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsId,
							goodsName: scope.row.goodsname
						}"
						v-if="isShowMaterialTip"
					></MaterialTooltip>
				</template>
			</el-table-column>

			<el-table-column label="单价" prop="price"></el-table-column>
			<el-table-column label="销售数量" prop="cnt"></el-table-column>
			<el-table-column label="出库数量" prop="donecnt"></el-table-column>
			<el-table-column label="总价">
				<template slot-scope="scope">
					<span>
						{{
							(scope.row.price * 1000 * (scope.row.cnt * 1000)) /
								1000000
						}}
					</span>
				</template>
			</el-table-column>
			<el-table-column label="购入均价">
				<template slot-scope="scope">
					<span>{{ scope.row.avgBuyPrice }}</span>
				</template>
			</el-table-column>
			<el-table-column label="日期" prop="createDate">
				<template slot-scope="scope">
					{{ scope.row.createDate }}
					<!-- {{ scope.row.createDate | parseTime('{y}-{m}-{d}') }} -->
				</template>
			</el-table-column>
			<el-table-column label="备注" prop="memo"></el-table-column>
			<el-table-column label="查看单据">
				<template slot-scope="scope">
					<el-button
						type="success"
						:disabled="!scope.row.imgs"
						@click="viewImgs(scope.row)"
						size="mini"
					>
						查看单据
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
			<TransferingAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></TransferingAddForm>
		</Dialog>

		<Dialog
			title="单据图片列表"
			:visible.sync="isShowImgsDialog"
			width="70%"
		>
			<el-image
				class="p5b1"
				v-for="item in extFile"
				:key="item.name"
				style="width: 120px; height: 120px; margin-right: 14px"
				:src="item.url"
				:preview-src-list="extFileUrlList"
				:fit="'contain'"
			>
				<div slot="error" class="image-slot">暂无图片</div>
			</el-image>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { detailSearchService } from '@s/spareParts/SaleApplicationService';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import TransferingAddForm from '@f/spareParts/transfering/TransferingAdd.form';
import SellRequestDetailSearch from '@f/spareParts/saleApplication/SellRequestDetailSearch';
import MaterialTooltip from '@/components//MaterialTooltip';

export default {
	name: 'Transfering',
	mixins: [ListMixin],
	components: {
		Table,
		Pagination,
		Dialog,
		TransferingAddForm,
		SellRequestDetailSearch,
		MaterialTooltip
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			downLoading: false,
			delTips: '',
			tableDownData: [],
			devExtImgUrlArr: [],
			extFile: [],
			extFileUrlList: [],
			isShowImgsDialog: false,
			isShowMaterialTip: false
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '调拨入库' : '';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			console.log(newValue);
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
			}
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		async getList() {
			this.isShowMaterialTip = false;
			const dataJson = {
				...this.searchForm,
				pageNum: this.pageIndex,
				pageSize: this.pageSize
			};
			const res = await detailSearchService(dataJson);
			this.isShowMaterialTip = true;
			this.listMixin(res);
		},
		async getDwonList() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 99999
			};
			const res = await detailSearchService(dataJson);
			res.records.forEach((item, index) => {
				item.indexId = index + 1;
				item.priceCnt = item.price * item.cnt;
			});
			this.tableDownData = res.records || [];
		},
		async downloadExlcel(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.downLoading = true;
			await this.getDwonList();
			this.getList();

			let maxImgLength = 0;
			const imgsNameArr = [];
			const imgsUrlArr = [];
			// 设置图片
			this.tableDownData.forEach(item => {
				if (item.imgs) {
					item.imgsArr = item.imgs.split(',');
					maxImgLength < item.imgsArr.length &&
						(maxImgLength = item.imgsArr.length);
				}
				item.dfactory = item.goodsDetail.factory;
				item.dgoodsVer = item.goodsDetail.goodsVer;
				item.dforDev = item.goodsDetail.forDev;
				item.dforDevSpec = item.goodsDetail.forDevSpec;
			});
			this.tableDownData.forEach(item => {
				for (let index1 = 1; index1 <= maxImgLength; index1++) {
					item[`imgsName${index1}`] = `单据图片${index1}`;
					if (item.imgs) {
						item[`imgsUrl${index1}`] = `${
							this.$envConfig.imgHttpAdd
						}wms/sellrequest/${item.imgsArr[index1 - 1]}`;
					} else {
						item[`imgsUrl${index1}`] = '';
					}
				}
				item.imgsUrlArr = new Array(maxImgLength);
			});

			for (let index1 = 0; index1 < maxImgLength; index1++) {
				imgsNameArr.push(`单据图片${index1 + 1}`);
				imgsUrlArr.push(`imgsUrl${index1 + 1}`);
			}
			let tHeader = [];
			let filterVal = [];

			tHeader = [
				'序号',
				'销售单号',
				'项目组',
				'科室',
				'物料',
				'单价',
				'销售数量',
				'出库数量',
				'总价',
				'购入均价',
				'厂家',
				'型号',
				'适用设备',
				'适用设备型号',
				'日期',
				'备注',
				...imgsNameArr
			];
			// eslint-disable-next-line no-unused-vars
			filterVal = [
				'indexId',
				'code',
				'warehouseName',
				'departname',
				'goodsname',
				'price',
				'cnt',
				'donecnt',
				'priceCnt',
				'avgBuyPrice',
				'dfactory',
				'dgoodsVer',
				'dforDev',
				'dforDevSpec',
				'createDate',
				'memo',
				...imgsUrlArr
			];

			const data = this.formatJson(filterVal, this.tableDownData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;
			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `销售详情 ${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.downLoading = false;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		},
		viewImgs(row) {
			console.log(row);
			this.extFile = [];
			this.extFileUrlList = [];
			row.imgs && (this.devExtImgUrlArr = row.imgs.split(','));

			row.imgs &&
				this.devExtImgUrlArr.forEach((img, index) => {
					this.extFile.push({
						name: `图片${index}`,
						path: `${img}`,
						url: `${this.$envConfig.imgHttpAdd}wms/sellrequest/${img}`
					});
					this.extFileUrlList.push(
						`${this.$envConfig.imgHttpAdd}wms/sellrequest/${img}`
					);
				});

			if (this.extFile.length > 0) {
				this.isShowImgsDialog = true;
			} else {
				this.$message.warning('暂无单据图片！');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.p5b1 {
	padding: 5px;
	border: 1px solid #cccccc;
}
</style>
