import { render, staticRenderFns } from "./WarehouseAll.vue?vue&type=template&id=08c1bf79&scoped=true"
import script from "./WarehouseAll.vue?vue&type=script&lang=js"
export * from "./WarehouseAll.vue?vue&type=script&lang=js"
import style0 from "./WarehouseAll.vue?vue&type=style&index=0&id=08c1bf79&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c1bf79",
  null
  
)

export default component.exports