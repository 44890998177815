<template>
	<el-form ref="problemForm" :model="problemForm" :rules="problemRules" size="small" label-width="99px">
		<el-form-item label="字段名称" prop="problemKey">
			<el-input :disabled="editId !== -1" v-model="problemForm.problemKey" placeholder="请输入字段名称" />
		</el-form-item>
		<el-form-item label="名称" prop="name">
			<el-input v-model="problemForm.name" placeholder="请输入名称" />
		</el-form-item>
		<el-form-item label="字段值" prop="problemValue">
			<el-input v-model="problemForm.problemValue" placeholder="请输入字段值" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="problemFormCancel">取 消</el-button>
					<el-button type="primary" @click="problemFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
import { problemAddService, problemEditService, problemDetailService } from '@s/base/ProblemService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			problemForm: {
				name: '',
				problemKey: '',
				problemValue: ''
			},
			problemRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				problemKey: [
					{
						required: true,
						message: '请输入字段名称',
						trigger: 'blur'
					}
				],
				problemValue: [
					{
						required: true,
						message: '请输入字段值',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.problemForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		if (this.editId_p !== -1) {
			this.getDetail();
		}
	},
	methods: {
		async getDetail() {
			const dataJson = {
				problemId: this.editId_p
			};
			const res = await problemDetailService(dataJson);
			this.problemForm = { ...res };
		},
		problemFormSubmit() {
			this.$refs.problemForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await problemAddService(this.problemForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.problemForm
						};
						await problemEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.problemFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		problemFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
