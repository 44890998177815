<template>
	<div>
		<el-form
			ref="inspectionForm"
			:model="inspectionForm"
			:rules="inspectionRules"
			size="small"
			label-width="100px"
			:inline="true"
			class="demo-form-inline"
		>
			<!-- <el-form-item label="设备名称" prop="name">
			<el-input v-model="inspectionForm.name" placeholder="请输入设备名称" />
			</el-form-item>-->

			<el-form-item label="医院" prop="hospitalId">
				<el-select
					v-model="inspectionForm.hospitalId"
					placeholder="请选择医院"
					clearable
					filterable
					style="width: 100%"
					@change="selectPoByOrgIdService"
				>
					<el-option
						v-for="item in optionsCompany"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="类型" prop="inspectionType" v-if="inspectionForm.hospitalId">
				<el-select
					v-model="inspectionForm.inspectionType"
					placeholder="请选择"
					filterable
					@change="clearSecDevListData"
				>
					<el-option
						v-for="item in typeInspectionData"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>

			<!-- <el-form-item label="科室" prop="hospitalOfficeId" :show-all-levels="false" v-if="inspectionForm.hospitalId">
				<el-cascader
					:props="{ checkStrictly: true }"
					v-model="inspectionForm.hospitalOfficeId"
					:options="optionsDept"
					clearable
					filterable
				></el-cascader>
			</el-form-item>-->

			<el-form-item
				label="科室"
				prop="hospitalOfficeId"
				v-if="inspectionForm.hospitalId && inspectionForm.inspectionType == 0"
			>
				<el-select
					v-model="parentName"
					class="select-item"
					placeholder="请选择"
					collapse-tags
					clearable
					@change="getHospitalId()"
				>
					<el-option :value="inspectionForm.hospitalOfficeId" style="height: auto">
						<el-tree
							:data="optionsDept"
							node-key="id"
							ref="tree"
							highlight-current
							:props="defaultProps"
							@node-click="nodeClickHandler"
						></el-tree>
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item
				label="设备类型"
				prop="devTypes1"
				v-if="inspectionForm.hospitalId && inspectionForm.inspectionType == 1"
			>
				<treeselect
					v-model="inspectionForm.devTypes1"
					:multiple="true"
					:options="deviceTypeData"
					style="width:300px;"
					:disable-branch-nodes="true"
					:normalizer="normalizer"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>
			</el-form-item>

			<el-form-item label="计划负责人" prop="dutyPeople" v-if="inspectionForm.hospitalId">
				<el-select
					v-model="inspectionForm.dutyPeople"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
				>
					<el-option
						v-for="item in inspectionUserData"
						:key="item.id"
						:label="item.nickName"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="执行日期" prop="executionTime">
				<el-date-picker
					v-model="inspectionForm.executionTime"
					type="date"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"
					:picker-options="pickerOptions"
				></el-date-picker>
			</el-form-item>
			<div class="maintainList">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="设备" name="first">
						<transition name="el-zoom-in-top">
							<el-table
								v-show="activeName === 'first'"
								ref="multipleTableStorageFirst"
								:data="tableDataStorageFirst"
								tooltip-effect="dark"
								style="width: 100%"
							>
								<!-- <el-table-column type="selection" width="55"></el-table-column> -->

								<el-table-column label="设备名称" prop="devInfoId">
									<template slot-scope="scope">
										<p style="color:#606266;font-size:14px;">
											{{ scope.row.devInfoNumber }}
										</p>
										<p style="color:#333333;font-size:16px;font-weight:bold;">
											{{ scope.row.devInfoName }}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoSerialNo ||
													scope.row.devDetail.devInfoSerialNo
											}}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoDepartmentName ||
													scope.row.devDetail.devInfoDepartmentName
											}}
										</p>
									</template>
								</el-table-column>
								<el-table-column label="品牌型号" prop="devName">
									<template slot-scope="scope">
										<!-- <p style="color:#606266;font-size:14px;">
											{{ scope.row.hospitalOfficeName }}
										</p> -->
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoFactoryName ||
													scope.row.devDetail.devInfoFactoryName
											}}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoNns1 ||
													scope.row.devDetail.devInfoNns1
											}}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoBrand ||
													scope.row.devDetail.devInfoBrand
											}}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoTypeName ||
													scope.row.devDetail.devInfoTypeName
											}}
										</p>
										<p style="color:#606266;font-size:14px;">
											{{
												scope.row.devInfoNns3 ||
													scope.row.devDetail.devInfoNns3
											}}￥
											{{
												scope.row.devInfoEnableTime ||
													scope.row.devDetail.devInfoEnableTime
														| parseTime('{y}-{m}-{d} ')
											}}
										</p>
									</template>
								</el-table-column>
								<el-table-column label="费用" prop="price">
									<template slot-scope="scope">
										{{ scope.row.price || scope.row.xjprice || 0 }}￥
									</template>
								</el-table-column>
								<!-- <el-table-column
									label="设备编号"
									prop="devInfoId"
								></el-table-column>
								<el-table-column label="科室名称" prop="devInfoDepartmentName">
									<template slot-scope="scope">
										{{
											scope.row.devInfoDepartmentName ||
												scope.row.devDetail.devInfoDepartmentName
										}}
									</template>
								</el-table-column>
								<el-table-column
									label="设备名称"
									prop="devInfoName"
								></el-table-column>
								<el-table-column
									label="规格型号"
									prop="devInfoNns1"
								></el-table-column>
								<el-table-column label="费用" prop="price">
									<template slot-scope="scope">
										{{ scope.row.price }}{{ scope.row.price ? '￥' : '' }}
									</template>
								</el-table-column> -->
								<el-table-column label="操作" width="120">
									<template slot-scope="scope">
										<el-button
											type="danger"
											@click="delTableDataStorageFirst(scope.row)"
										>
											删除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</transition>
					</el-tab-pane>
					<el-tab-pane label="项目" name="second">
						<transition name="el-zoom-in-bottom">
							<el-table
								v-show="activeName === 'second'"
								ref="multipleTableStorageSecond"
								:data="tableDataStorageSecond"
								tooltip-effect="dark"
								style="width: 100%"
							>
								<!-- <el-table-column type="selection" width="55"></el-table-column> -->

								<el-table-column label="项目名称" prop="name"></el-table-column>
								<el-table-column label="项目一" prop="itemOne"></el-table-column>
								<el-table-column label="项目二" prop="itemTwo"></el-table-column>
								<el-table-column label="项目三" prop="itemThree"></el-table-column>
								<el-table-column label="操作" width="120">
									<template slot-scope="scope">
										<el-button
											type="danger"
											@click="delTableDataStorageSecond(scope.row)"
										>
											删除
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</transition>
					</el-tab-pane>
				</el-tabs>
				<!-- <el-button type="primary" class="btnAdd" @click="showDialog" icon="el-icon-plus"
					:disabled="!inspectionForm.hospitalId || (!parentName && activeName === 'first')"> -->
				<el-button
					type="primary"
					class="btnAdd"
					@click="showDialog"
					icon="el-icon-plus"
					:disabled="idDesabled"
				>
					{{ activeName === 'first' ? '巡检设备' : '巡检项目' }}
				</el-button>
			</div>
			<el-form-item style="width: 100%; text-align: right; margin-top: 20px">
				<span class="dialog-footer">
					<el-button @click="inspectionFormCancel">取 消</el-button>

					<template
						v-if="
							tableDataStorageFirst.length === 0 ||
								tableDataStorageSecond.length === 0
						"
					>
						<el-button type="primary" @click="switchTabs">下一步</el-button>
					</template>
					<template v-else>
						<el-button type="primary" @click="inspectionFormSubmit">确 定</el-button>
					</template>
				</span>
			</el-form-item>
		</el-form>
		<el-dialog
			title="选择设备"
			:visible.sync="dialogVisibleFirst"
			width="70%"
			:append-to-body="true"
		>
			<el-table
				ref="multipleTableFirst"
				:data="tableDataFirst"
				tooltip-effect="dark"
				style="width: 100%"
				@selection-change="handleSelectionChangeFirst"
				@cell-click="handleSelectionChangeFirstCellClick"
				:row-key="getRowKeyFirst"
				class="tableCursor"
			>
				<el-table-column
					type="selection"
					width="55"
					:reserve-selection="true"
				></el-table-column>
				<el-table-column label="设备编号" prop="devInfoId"></el-table-column>
				<el-table-column label="科室名称" prop="devInfoDepartmentName">
					<template slot-scope="scope">
						{{ scope.row.devInfoDepartmentName }}
					</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="devInfoName"></el-table-column>
				<el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
				<!-- <el-table-column label="费用" prop="price">
					<template slot-scope="scope">
						{{ scope.row.price }}{{ scope.row.price ? '￥' : '' }}
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="日期" width="120">
					<template slot-scope="scope">{{ scope.row.date }}</template>
				</el-table-column>-->
			</el-table>

			<el-pagination
				@size-change="handleSizeChangeFirst"
				@current-change="handleCurrentChangeFirst"
				:current-page="devBaseForm.pageNum"
				:page-sizes="[10, 20, 30, 50]"
				:page-size="100"
				layout="total, sizes, prev, pager, next, jumper"
				:total="devBaseForm.total"
			></el-pagination>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleFirst = false">取 消</el-button>
				<el-button type="primary" @click="saveDialogVisibleFirst">保存设备</el-button>
			</span>
		</el-dialog>
		<el-dialog
			title="选择项目"
			:visible.sync="dialogVisibleSecond"
			width="60%"
			:append-to-body="true"
		>
			<el-row :gutter="20">
				<el-col :span="6">
					<div class="grid-content bg-purple">
						<ul class="inspectionItemList">
							<li
								v-for="(item, index) in templateData"
								:key="index"
								@click="inspectionItemList(item.id, index)"
								:class="[index == inspectionItemIndex ? 'active' : '']"
							>
								{{ item.name }}
							</li>
						</ul>
					</div>
				</el-col>
				<el-col :span="18">
					<div class="grid-content bg-purple">
						<el-table
							ref="multipleTableSecond"
							:data="tableDataSecond"
							tooltip-effect="dark"
							style="width: 100%"
							@selection-change="handleSelectionChangeSecond"
							@cell-click="handleSelectionChangeSecondCellClick"
							:row-key="getRowKeySecond"
							class="tableCursor"
						>
							<el-table-column
								type="selection"
								width="55"
								:reserve-selection="true"
							></el-table-column>

							<el-table-column label="项目名称" prop="name"></el-table-column>
							<el-table-column label="项目一" prop="itemOne"></el-table-column>
							<el-table-column label="项目二" prop="itemTwo"></el-table-column>
							<el-table-column label="项目三" prop="itemThree"></el-table-column>
						</el-table>

						<el-pagination
							@size-change="handleSizeChangeSecond"
							@current-change="handleCurrentChangeSecond"
							:current-page="inspectionItemForm.pageNum"
							:page-sizes="[10, 20, 30, 50]"
							:page-size="100"
							layout="total, sizes, prev, pager, next, jumper"
							:total="inspectionItemForm.total"
						></el-pagination>
					</div>
				</el-col>
			</el-row>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleSecond = false">取 消</el-button>
				<el-button type="primary" @click="saveDialogVisibleSecond">保存项目</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	inspectionAddService,
	inspectionEditService,
	inspectionUserListService,
	getSonIdsByDepartmentIdService
} from '@s/company/inspectionTemp/InspectionService';
// 选择医院 下拉
import { getcompanysignListService } from '@s/company/contract/ContractService';
// 选择科室 下拉
import { selectPoByOrgIdService } from '@s/hospital/department/DepartmentService';
// 获取巡检设备列表
import { devBaseListService, devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';
// 获取巡检巡检模板名称 和 巡检计划
import {
	inspectionTempListService,
	inspectionItemListService
} from '@s/company/inspectionTemp/inspectionTempService';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';
// eslint-disable-next-line no-unused-vars
import { hsetStorage, hgetStorage, hremoveStorage } from '@/util/htools.web';
// 编辑科室回绑
import { getTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			pickerOptions: {
				disabledDate() {
					// disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
					// return time.getTime() > Date.now(); // 选当前时间之前的时间
					// return time.getTime() < Date.now() - 86400000; // 选当前时间之后的时间
					return false;
				}
			},
			// 科室
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			optionsCompany: [],
			inspectionUserData: [],
			optionsDept: [],
			dialogVisibleFirst: false,
			tableDataFirst: [],
			dialogVisibleSecond: false,
			templateData: [],
			// 把删除了的存起来
			tableDelDataFirst: [],
			tableDelDataSecond: [],
			inspectionItemIndex: 0,
			tableDataSecond: [],
			tableDataStorageFirst: [],
			tableDataStorageSecond: [],
			activeName: 'first',
			devBaseForm: {
				pageNum: 1,
				pageSize: 10
			},
			inspectionItemForm: {
				pageNum: 1,
				pageSize: 10
			},
			typeInspectionData: [
				{ label: '科室', value: 0 },
				{ label: '设备类型', value: 1 }
			],
			// 设备类型
			deviceTypeData: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			},
			editId: -1,
			inspectionForm: {
				devs: [],
				items: [],
				dutyPeople: null,
				hospitalOfficeId: null,
				// 本地用的数组
				devTypes1: [],
				// 最终用的字符串
				devTypes: null,
				inspectionType: 0
			},
			inspectionRules: {
				hospitalId: [
					{
						required: true,
						message: '请选择医院',
						trigger: 'change'
					}
				],
				executionTime: [
					{
						required: true,
						message: '请选择执行日期',
						trigger: 'change'
					}
				],
				dutyPeople: [
					{
						required: true,
						message: '请选择计划负责人',
						trigger: 'change'
					}
				],
				hospitalOfficeId: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'change'
					}
				],
				inspectionType: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				devTypes1: [
					{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}
				]
			}
		};
	},
	components: {
		// SelectTree,
		Treeselect
	},
	computed: {
		idDesabled() {
			let flag = true;
			if (this.inspectionForm.hospitalId) {
				if (this.parentName || this.inspectionForm.devTypes1?.length != 0) {
					flag = false;
				}
			}
			return flag;
		}
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					newValue[0].devTypes1 = [];
					this.editId = newValue[0].id;
					const devTypes1 = newValue[0].devTypes?.split(',') || [];
					devTypes1?.forEach(item => {
						item = Number(item);
					});
					this.inspectionForm = { ...newValue[0] };
					this.tableDataStorageFirst = newValue[0].devList || [];
					this.tableDataStorageSecond = newValue[0].itemList || [];
					devTypes1?.forEach((item, index) => {
						this.$set(this.inspectionForm.devTypes1, index, Number(item));
						this.$forceUpdate();
					});

					// 获取负责人
					this.inspectionUserList();
					// 设备分类
					await this.deviceTypeList();

					// 获取科室
					await this.inspectionDeptList();

					newValue[0].hospitalId &&
						getTreeNodeById(
							this.optionsDept,
							'id',
							newValue[0].hospitalOfficeId,
							node => {
								this.parentName = node.department;
								this.inspectionForm.hospitalOfficeId = newValue[0].hospitalOfficeId;
							}
						);
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {
		this.getCompanyList();
		// // 弹框 设备选择列表
		// this.devBaseList();
		// // 弹框 左侧模板分类
		// this.inspectionTempList();
		// // 弹框 右侧数据
		// this.inspectionItemList();
	},
	methods: {
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 设备类型
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
			console.log('deviceTypeData');
			console.log(this.deviceTypeData);
		},
		getHospitalId(val) {
			console.log('val');
			console.log(val);
			if (!val) {
				this.inspectionForm.hospitalOfficeId = null;
			}
		},
		// 科室问题
		nodeClickHandler(data) {
			console.log('val');
			// this.tableDelDataFirst = [];
			this.tableDataStorageFirst = [];
			this.inspectionForm.hospitalOfficeId = data.id;
			this.parentName = data.department;
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: this.inspectionForm.hospitalId
			};
			const res = await inspectionUserListService(dataJson);
			this.inspectionUserData = [...res];
		},
		async inspectionDeptList() {
			const json = {
				orgId: this.inspectionForm.hospitalId
			};
			const res = await selectPoByOrgIdService(json);

			const r = this.addTreeKey(res, 0);
			// 获取部门
			this.optionsDept = r;
		},

		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 巡检弹框 S=====================================
		// 巡检设备 巡检项目 切换
		handleClick(tab, event) {
			console.log(tab, event);
		},
		async showDialog() {
			if (this.activeName === 'first') {
				await this.devBaseList();
				this.dialogVisibleFirst = true;
				this.setAllFirst();
			} else {
				// 左侧模板分类
				this.inspectionTempList();
				// 右侧数据
				this.inspectionItemList();
				this.dialogVisibleSecond = true;
				this.setAllSecond();
			}
		},
		// 获取全部里选中的
		setAllFirst() {
			// TODO 优化
			// setTimeout(() => {
			// this.$refs.multipleTableFirst && this.$refs.multipleTableFirst.clearSelection();
			// 把要删除的存起来  遍历取消选中 然后删除

			this.$nextTick(() => {
				if (this.tableDataStorageFirst.length == 0) {
					this.$refs.multipleTableFirst.clearSelection();
				}

				for (let index = 0; index < this.tableDelDataFirst.length; index++) {
					this.tableDataFirst.forEach(item1 => {
						if (
							this.tableDelDataFirst[index] &&
							this.tableDelDataFirst[index].devInfoId === item1.devInfoId
						) {
							this.$refs.multipleTableFirst &&
								this.$refs.multipleTableFirst.toggleRowSelection(item1, false);
							this.tableDelDataFirst.splice(index, 1);
							index--;
						}
					});
				}

				this.tableDataStorageFirst.forEach(item => {
					this.tableDataFirst.forEach(item1 => {
						if (item.devInfoId === item1.devInfoId) {
							this.$refs.multipleTableFirst &&
								this.$refs.multipleTableFirst.toggleRowSelection(item1, true);
						}
					});
				});
			});
			// }, 100);
		},
		setAllSecond() {
			// TODO 优化
			// setTimeout(() => {
			this.$nextTick(() => {
				// 把要删除的存起来  遍历取消选中 然后删除
				// this.tableDelDataSecond.forEach((item, index, arr) => {
				for (let index = 0; index < this.tableDelDataSecond.length; index++) {
					this.tableDataSecond.forEach(item1 => {
						if (
							this.tableDelDataSecond[index] &&
							this.tableDelDataSecond[index].id === item1.id
						) {
							this.$refs.multipleTableSecond &&
								this.$refs.multipleTableSecond.toggleRowSelection(item1, false);
							this.tableDelDataSecond.splice(index, 1);
							index--;
						}
					});
				}
				// });

				this.tableDataStorageSecond.forEach(item => {
					this.tableDataSecond.forEach(item1 => {
						if (item.id === item1.id) {
							console.log(item);
							this.$refs.multipleTableSecond &&
								this.$refs.multipleTableSecond.toggleRowSelection(item1, true);
						}
					});
				});
			});
			// }, 100);
		},
		getRowKeyFirst(row) {
			return row.devInfoId;
		},
		// 巡检设备table
		handleSelectionChangeFirst(val) {
			console.log('val巡检设备table');
			console.log(val);
			this.tableDataStorageFirst = val;
		},
		handleSelectionChangeFirstCellClick(row, column, event) {
			this.$refs.multipleTableFirst.toggleRowSelection(row);
			console.log(column, event);
		},
		saveDialogVisibleFirst() {
			this.dialogVisibleFirst = false;
			// 清空存起来的要删除的数组
			this.tableDelDataFirst = [];
		},
		delTableDataStorageFirst(row) {
			console.log(row);
			this.tableDataStorageFirst.forEach((item, index, arr) => {
				if (item.devInfoId === row.devInfoId) {
					arr.splice(index, 1);
				}
			});
			this.tableDelDataFirst.push(row);
		},
		getRowKeySecond(row) {
			return row.id;
		},
		// 巡检项目table
		handleSelectionChangeSecond(val) {
			// TODELETE
			this.multipleSelectionSecond = val;
			this.tableDataStorageSecond = val;
		},
		handleSelectionChangeSecondCellClick(row, column, event) {
			console.log('// 巡检项目点击单元格');
			this.$refs.multipleTableSecond.toggleRowSelection(row);
			console.log(column, event);
		},
		saveDialogVisibleSecond() {
			this.dialogVisibleSecond = false;
			// 清空存起来的要删除的数组
			this.tableDelDataSecond = [];
		},
		delTableDataStorageSecond(row) {
			this.tableDataStorageSecond.forEach((item, index, arr) => {
				if (item.id === row.id) {
					arr.splice(index, 1);
				}
			});
			this.tableDelDataSecond.push(row);
		},
		//  * 医院 维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});
			this.optionsCompany = res.records;
		},
		clearSecDevListData() {
			this.tableDataStorageFirst = [];
		},
		async selectPoByOrgIdService() {
			this.inspectionForm.dutyPeople = null;
			this.inspectionForm.hospitalOfficeId = null;
			this.parentName = '';
			this.tableDataStorageFirst = [];
			this.optionsDept = [];
			this.inspectionUserData = [];
			// 获取部门
			this.inspectionDeptList();
			// 获取负责人
			this.inspectionUserList();
			// 获取设备分类
			this.deviceTypeList();
		},
		// 获取巡检设备
		async devBaseList() {
			if (this.inspectionForm.hospitalOfficeId) {
				const hids = await getSonIdsByDepartmentIdService({
					ids: this.inspectionForm.hospitalOfficeId
				});
				this.inspectionForm.hids = hids;
			}

			// console.log('this.devBaseForm.hospitalOfficeId');
			// console.log(this.inspectionForm.hospitalOfficeId);
			const dataJson = {
				pageNum: this.devBaseForm.pageNum,
				pageSize: this.devBaseForm.pageSize,
				hid: this.inspectionForm.hospitalId,
				dids: this.inspectionForm.hids,
				devTypes: this.inspectionForm.devTypes1,
				stopState: false,
				withXJBYPrice: true,
				/* did: this.inspectionForm.hospitalOfficeId, */
				...this.searchForm
			};
			if (this.inspectionForm.inspectionType == 0) {
				delete dataJson.devTypes;
			} else if (this.inspectionForm.inspectionType == 1) {
				delete dataJson.dids;
			}

			const res = await devBaseListService(dataJson);
			res.records.forEach(item => {
				if (!item.devDetail) {
					item.devDetail = {};
				}
			});
			this.devBaseForm.total = res.total;
			this.tableDataFirst = res.records;
		},
		handleSizeChangeFirst(val) {
			console.log(`每页 ${val} 条`);
			this.devBaseForm.pageSize = val;
			this.devBaseList();
			this.setAllFirst();
		},
		handleCurrentChangeFirst(val) {
			console.log(`当前页: ${val}`);
			this.devBaseForm.pageNum = val;
			this.devBaseList();
			this.setAllFirst();
		},
		// 获取巡检模板 列表
		async inspectionTempList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await inspectionTempListService(dataJson);
			res.records.unshift({ name: '全部' });
			this.templateData = [...res.records];
		},
		// e是id，index是索引
		async inspectionItemList(e, index) {
			this.inspectionItemIndex = index || 0;
			const dataJson = {
				pageNum: this.inspectionItemForm.pageNum,
				pageSize: this.inspectionItemForm.pageSize,
				inspectionTypeId: e,
				...this.searchForm
			};
			const res = await inspectionItemListService(dataJson);

			this.inspectionItemForm.total = res.total;
			this.tableDataSecond = res.records;
		},
		handleSizeChangeSecond(val) {
			console.log(`每页 ${val} 条`);
			this.inspectionItemForm.pageSize = val;
			this.inspectionItemList();
			this.setAllSecond();
		},
		handleCurrentChangeSecond(val) {
			console.log(`当前页: ${val}`);
			this.inspectionItemForm.pageNum = val;
			this.inspectionItemList();
			this.setAllSecond();
		},
		// 巡检弹框 E=====================================
		switchTabs() {
			if (this.tableDataStorageFirst.length === 0 && this.activeName === 'first') {
				this.$message.warning('请选择巡检设备！');
				return;
			}
			if (this.tableDataStorageSecond.length === 0 && this.activeName === 'second') {
				this.$message.warning('请选择巡检项目！');
				return;
			}
			if (this.tableDataStorageFirst.length === 0 && this.activeName === 'second') {
				this.activeName = 'first';
			}
			if (this.tableDataStorageSecond.length === 0 && this.activeName === 'first') {
				this.activeName = 'second';
			}
		},
		// 最终提交数据
		inspectionFormSubmit() {
			this.inspectionForm.devs = [];
			this.tableDataStorageFirst.forEach(item => {
				this.inspectionForm.devs.push(item.devInfoId);
			});
			this.inspectionForm.items = [];
			this.tableDataStorageSecond.forEach(item => {
				this.inspectionForm.items.push(item.id);
			});

			this.inspectionForm.devTypes = this.inspectionForm.devTypes1.join(',');
			// delete this.inspectionForm.hospitalOfficeId;
			this.$refs.inspectionForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await inspectionAddService(this.inspectionForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.inspectionForm
						};
						await inspectionEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.inspectionFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
			console.log('this.inspectionForm');
			console.log(this.inspectionForm);
		},
		inspectionFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
/deep/ .tableCursor tr {
	cursor: pointer !important;
}

.maintainList {
	position: relative;
}

.maintainList .btnAdd {
	position: absolute;
	top: 0;
	right: 0;
}

.inspectionItemList li {
	height: 30px;
	line-height: 30px;
	padding-left: 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.inspectionItemList li.active {
	background: #3a8ee6;
	color: #ffffff;
}
</style>
