<template>
	<div>
		<el-select
			v-model="formData.typeIds"
			class="select-item"
			clearable
			filterable
			placeholder="请选择"
			multiple
			popper-class="materialOpen"
			@change="changeSelectHandler"
		>
			<SpecialSelectOption
				:materialTypeDataP="materialTypeDataP"
			></SpecialSelectOption>
		</el-select>
	</div>
</template>

<script>
import SpecialSelectOption from '@c/SpecialSelectOption';

export default {
	name: 'select-chird', // 自身组件

	props: ['materialTypeDataP', 'queryInfoGoodP'],
	components: { SpecialSelectOption },

	data() {
		return {
			// 新的物料添加
			isShowGoodDialog: false,
			tableDataGood: [],
			queryInfoGood: {
				pageNum: 1,
				pageSize: 10,
				enable: true
			},
			totalGood: 0,
			dataArrGood: [],
			formData: {}
		};
	},

	watch: {},

	computed: {},

	mounted() {},

	methods: {
		changeSelectHandler(val) {
			this.$emit('setSelectformDataS', val);
		}
	}
};
</script>

<style lang="less" scoped>
.isOpenIcon {
	width: 40px;
	height: 34px;
	line-height: 1em;
	// background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
/deep/.materialSelectItem {
	padding-left: 0 !important;
}
</style>
