import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

import { changeTreeDataToChildren } from '../../../util/htools.tree';

/*
 *@Description: 设备列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const devBasetoggleStopUsingService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			let url = '';
			if (dataJson.stoped) {
				url = `hbtyong_5/devBase/toggleStop?id=${dataJson.id}`;
			} else {
				url = `hbtyong_5/devBase/toggleStop?id=${dataJson.id}&date=${dataJson.stopDate}`;
			}
			const res = await hpostRequest(url, JSON.stringify(dataJson.stopReason));
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const devBaseListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				devInfoName: '',
				orgId: '',
				devType: '',
				did: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_5/devBase/queryPaging', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量单位列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const unitListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_1/units/unitsAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const devBaseAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: 0,
				userId: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_5/devBase/addOneDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const devBaseEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/devBase/updateDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const devBaseDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/devBase/deleteDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 设备详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
// export const devBaseDetailService = data => {
// 	return new Promise(async resolve => {
// 		try {
// 			const dataJson = {
// 				devId: '',
// 				...data
// 			};
// 			const res = await hgetRequest('hbtyong_5/devBase/queryOneDev', dataJson);
// 			resolve(res);
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	});
// };

export const devBaseDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_5/devBase/queryOneDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型列表
 *@ClassAuthor: myw
 *@Date: 2021-05-28 14:15:25
 */
export const devBaseTypeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				deviceType: '',
				...data
			};
			const res = await hpostRequest('hlzlong/deviceType/deviceTypeAllQuery', dataJson);
			resolve(changeTreeDataToChildren(res.records));
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 根据设备类型ID获取标准名称列表
 *@ClassAuthor: myw
 *@Date: 2021-05-28 16:15:27
 */
export const normListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				typeId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/deviceName/deviceNameByTypeId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// POST 将指定设备划拨给指定企业
export const allocatedEquipmentService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/transfer/AllocatedEquipment', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// POST 医院设备详情
export const queryOneDevService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devId: 28,
				...data
			};
			const res = await hgetRequest('hbtyong_5/devBase/queryOneDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 设备添加 回响规格型号 计量单位
export const deviceNameAllQueryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 999,
				...data
			};
			const res = await hpostRequest('hlzlong_1/deviceName/deviceNameAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设备添加 大类 小类 下拉接口
export const sizeClassesBigAllQueryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 999,
				type: 0,
				...data
			};
			// const res = await hpostRequest('hlzlong_1/sizeClasses/sizeClassesBigAllQuery', dataJson);
			const res = await hpostRequest('hlzlong_1/sizeClasses/sizeClassesAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const sizeClassesByBigIdQueryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				sizeClassesId: 10,
				...data
			};
			const res = await hgetRequest('hlzlong_1/sizeClasses/sizeClassesByBigIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 设备详情 报修记录详情 表格一
export const getDevRepairAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				...data
			};
			const res = await hpostRequest('hbtyong_5/devRepairInfoService/getDevRepairAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设备详情 巡检记录详情 表格二
export const getDevMaintainAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionProcess/selectByDevId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 设备详情 保养记录详情 表格三
export const getDevInspectionAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				...data
			};
			const res = await hpostRequest('hlzlong_21/inspectionProcess/selectByDevId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 设备详情 计量记录详情 表格四
export const getDevMeteringAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				...data
			};
			const res = await hpostRequest('hlzlong_22/measureProcess/measureProcessByDevId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 设备历程
export const getDevFlowService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devid: '',
				...data
			};
			const res = await hgetRequest('hbtyong_5/devBase/getDevTimeLine', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default devBaseListService;
