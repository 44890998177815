<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchForm"
			:model="searchForm"
			label-width="80px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="科室" prop="name">
				<treeselect
					v-model="searchForm.departid"
					:multiple="false"
					:options="optionsDept"
					style="width:220px;"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>
			</el-form-item>
			<el-form-item label="设备名称">
				<el-input v-model="searchForm.devname" placeholder="请输入设备名称" />
			</el-form-item>
			<el-form-item label="发生日期">
				<el-date-picker
					v-model="searchForm.eventDate"
					type="date"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"
					style="width:150px;"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="状态">
				<el-select
					v-model="searchForm.reportState"
					clearable
					placeholder="请选择"
				>
					<el-option label="请选择" value=""></el-option>
					<el-option label="内部登记" :value="0"></el-option>
					<el-option label="上报提交" :value="1"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import Treeselect from '@riophae/vue-treeselect';
import { hgetStorage } from '@/util/htools.web';

export default {
	components: {
		Treeselect
	},
	data() {
		return {
			optionsDept: [],
			searchForm: {
				name: ''
			}
		};
	},
	created() {
		this.departmentList();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records || [];
		},
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
