<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<SupplierSearchform @searchForm="searchFormHandler"></SupplierSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'supplier'"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="电话" align="left" fixed="right" width="300">
				<template slot-scope="scope">{{ scope.row.contract }}({{ scope.row.telphone }})</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'supplier', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'supplier', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<SupplierAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></SupplierAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { supplierDeleteService, supplierListService, supplierDetailService } from '@s/spareParts/SupplierService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import SupplierAddForm from '@f/spareParts/supplier/SupplierAdd.form';
import SupplierSearchform from '@f/spareParts/supplier/SupplierSearch.form';

export default {
	name: 'Supplier',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		SupplierAddForm,
		SupplierSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '序号',
					field: 'id'
				},
				{
					label: '名称',
					field: 'name'
				}
			],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增供应商' : '编辑供应商';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.supplierList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.supplierList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.supplierList();
			}
		}
	},
	mounted() {
		this.supplierList();
	},
	methods: {
		async supplierList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await supplierListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await supplierDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = [...ids];
			await supplierDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await supplierDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.supplierList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
