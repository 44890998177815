<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<TransferingSearchForm @searchForm="searchFormHandler"></TransferingSearchForm>
			<div style="margin-bottom: 10px; margin-top: 10px">
				<!-- 功能按钮 -->
				<!-- <el-button type="success" @click="addStockHandler">添加入库</el-button> -->
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="调拨单">
				<template slot-scope="scope">
					{{ scope.row.aboutId }}
				</template>
			</el-table-column>
			<el-table-column label="物料">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsId,
							goodsName: scope.row.goodsName
						}"
						:key="scope.row.id"
					></MaterialTooltip>
				</template>
			</el-table-column>
			<el-table-column label="源库房">
				<template slot-scope="scope">
					{{ scope.row.fromwName }}
				</template>
			</el-table-column>
			<el-table-column label="目标库房">
				<template slot-scope="scope">
					{{ scope.row.towName }}
				</template>
			</el-table-column>
			<el-table-column label="数量">
				<template slot-scope="scope">
					{{ scope.row.cnt }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="130">
				<template slot-scope="scope">
					<el-button
						id="btn-add-row"
						type="primary"
						v-authorize="{ name: 'add', type: 'transfering', id: 'btn-add-row' }"
						@click="addStockHandler(scope.row)"
						size="mini"
					>
						入库
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
			<TransferingAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></TransferingAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { transferingListService } from '@s/spareParts/TransferingService';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import TransferingAddForm from '@f/spareParts/transfering/TransferingAdd.form';
import TransferingSearchForm from '@f/spareParts/transfering/TransferingSearch.form';
import MaterialTooltip from '@/components//MaterialTooltip';

export default {
	name: 'Transfering',
	mixins: [ListMixin],
	components: {
		Table,
		Pagination,
		Dialog,
		TransferingAddForm,
		TransferingSearchForm,
		MaterialTooltip
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '调拨入库' : '';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.currentStockList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.currentStockList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.currentStockList();
			}
		}
	},
	mounted() {
		this.currentStockList();
	},
	methods: {
		async currentStockList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await transferingListService(dataJson);
			this.listMixin(res);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.currentStockList();
		},
		addStockHandler(row) {
			this.selectData = [row];
			this.isShowAEDialog = true;
		}
	}
};
</script>

<style lang="less" scoped></style>
