<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" size="mini" :model="formData" label-width="80px" @submit.native.prevent
			@keyup.enter.native="searchHandler">

			<el-form-item label="物料">
				<el-select v-model="formData.goodsId1" filterable clearable size="mini" placeholder="请选择库房">
					<el-option v-for="(item, index) in goodsList" :key="index" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="库房">
				<el-select v-model="formData.warehouseId1" filterable clearable size="mini" placeholder="请选择库房">
					<el-option label="请选择" value=""></el-option>
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备名称">
				<el-input v-model="formData.devName" placeholder="请输入设备名称" clearable></el-input>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
			@click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialListService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import {
	warehouseonlymyselfService
} from '@s/spareParts/WarehouseService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				// 设备名称
				devName: null,
				// 物料id []
				goodsId: null,
				goodsId1: null,
				// 物料id []
				// 库房 []
				warehouseId: null,
				warehouseId1: null
			},
			// 物料下拉
			goodsList: [],
			warehouseData: []
		};
	},
	created() {
		this.getMaterialListPage();
		this.warehouseonlymyself();
	},
	methods: {
		// 获取物料列表 分页
		async getMaterialListPage() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			const res = await materialListService(dataJson);
			res.records.forEach(item => {
				item.goodsId = item.id;
				item.goodsName = item.name;
				if (item.pic && item.pic.length > 0) {
					item.picList = [
						`${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`
					];
					item.newPic = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`;
				} else {
					item.newPic = item.pic;
					item.picList = [];
				}
			});
			this.goodsList = res.records;
		},
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		searchHandler() {
				this.formData.goodsId = null;
				this.formData.warehouseId = null;
			if (this.formData.goodsId1) {
				this.formData.goodsId = [this.formData.goodsId1];
			}
			if (this.formData.warehouseId1) {
				this.formData.warehouseId = [this.formData.warehouseId1];
			}
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped></style>
