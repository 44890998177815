<template>
  <div class="app-container">
    <div class="head-container">
      <!-- 搜索区域 -->
      <VideoSearchform @searchForm="searchFormHandler"></VideoSearchform>
      <div>
        <!-- 功能按钮 -->
        <Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler" @showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips" :authorize_p="'wvideo'" :isHide_p="{ updateBtn:true }"></Button>
      </div>
    </div>
    <!--表格渲染-->
    <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">

      <el-table-column label="序号" type="index" width="50"></el-table-column>

      <el-table-column property="uname" label="上传人" width="100"></el-table-column>
      <el-table-column label="标题">
        <template slot-scope="scope">
          品名:{{ scope.row.pinming}}，
          厂家:{{ scope.row.changjia}}，
          型号:{{ scope.row.xinghao}}，
          故障:{{ scope.row.guzhang}}
        </template>
      </el-table-column>
      <el-table-column property="createDate" label="上传时间" width="100">
        <template slot-scope="scope">
          {{ scope.row.createDate | parseTime('{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      <el-table-column property="stars" label="观看次数" width="70">
        <template slot-scope="scope">
          <span @click="showPlayHistoryHandler(scope.row)" class="cursorPointer" style="display:inline-block; width:100%;">
            {{scope.row.clicks || 0}}
          </span>
        </template>
      </el-table-column>
      <el-table-column property="stars" label="观看时间" width="70">
        <template slot-scope="scope">
          <span @click="showPlayViewsHandler(scope.row)" class="cursorPointer" style="display:inline-block; width:100%;">
            观看时长
          </span>
        </template>
      </el-table-column>
      <el-table-column property="goodsName" label="评价" width="150">
        <template slot-scope="scope">
          <span @click="showPlayStarHandler(scope.row)">
            <el-rate v-model="scope.row.stars" disabled class="cursorPointer"></el-rate>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" fixed="right" width="290">
        <template slot-scope="scope">
          <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'wvideo', id: 'btn-update-row' }" @click="editSingleHandler(scope.row)">
            编辑
          </el-button>
          <el-button id="btn-remove-row" type="danger" size="mini" v-authorize="{ name: 'remove', type: 'wvideo', id: 'btn-remove-row' }" @click="deleteSingleHandler(scope.row)">
            删除
          </el-button>
          <!-- <el-button id="btn-list-row" type="warning" size="mini" v-authorize="{ name: 'list', type: 'wvideo', id: 'btn-list-row' }" @click="editRateHandler(scope.row)">
            评价
          </el-button> -->
          <el-button type="warning" size="mini" @click="editRateHandler(scope.row)">
            评价
          </el-button>
          <el-button type="success" size="mini" @click="showPlayHandler(scope.row)">
            播放
          </el-button>
          <!-- <el-button id="btn-update-row" type="primary" size="mini" @click="showPlayStarHandler(scope.row)">
            评价历史
          </el-button>
          <el-button id="btn-update-row" type="primary" size="mini" @click="showPlayHistoryHandler(scope.row)">
            观看历史
          </el-button> -->
        </template>
      </el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="30%">
      <VideoAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectData"></VideoAddForm>
    </Dialog>
    <Dialog title="评价" :visible.sync="isShowStarDialog" width="40%">
      <el-rate v-model="star" show-text></el-rate>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changeStateCancel">取消</el-button>
        <el-button type="primary" @click="changeStateSubmit">确定</el-button>
      </div>
    </Dialog>
    <Dialog title="视频" :visible.sync="isShowPlayDialog" width="40%" :before-close="changePlayCancel">
      <!-- <video ref="video" class="video-js vjs-default-skin" width="600" height="400" controls>
        <source :src="playerUrl" />
      </video> -->
      <video width="100%" height="100%" controls ref="refVideo" v-if="isShowPlayDialog">
        <source :src="playerUrl" type="video/mp4">
        <source :src="playerUrl" type="video/ogg">
        <source :src="playerUrl" type="video/webm">
      </video>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changePlayCancel">取消</el-button>
      </div>
    </Dialog>
    <Dialog title="观看评价" :visible.sync="isShowPlayStarDialog" width="40%">
      <el-table :data="tableDataPlayStar" style="width: 100%" height="400">
        <el-table-column prop="uname" label="评价人">
        </el-table-column>
        <el-table-column prop="date" label="时间">
          <template slot-scope="scope">{{scope.row.date | parseTime }}</template>
        </el-table-column>
        <el-table-column prop="star" label="评分">
          <template slot-scope="scope">
            <el-rate v-model="scope.row.stars" disabled></el-rate>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changePlayStarCancel">取消</el-button>
      </div>
    </Dialog>
    <Dialog title="观看历史" :visible.sync="isShowPlayHistoryDialog" width="40%">
      <el-table :data="tableDataPlayHistory" style="width: 100%" height="400">
        <el-table-column prop="uname" label="观看人">
        </el-table-column>
        <el-table-column prop="date" label="时间">
          <template slot-scope="scope">{{scope.row.date | parseTime}}</template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changePlayHistoryCancel">取消</el-button>
      </div>
    </Dialog>
    <Dialog title="观看时间" :visible.sync="isShowPlayViewsDialog" width="40%">
      <el-table :data="tableDataPlayViews" style="width: 100%" height="400">
        <el-table-column prop="uname" label="观看人">
        </el-table-column>
        <el-table-column prop="date" label="开始时间">
          <template slot-scope="scope">{{scope.row.startTime | parseTime}}</template>
        </el-table-column>
        <el-table-column prop="date" label="结束时间">
          <template slot-scope="scope">{{scope.row.endTime | parseTime}}</template>
        </el-table-column>
        <el-table-column prop="date" label="观看时长" align="right">
          <template slot-scope="scope"><span v-if="Math.floor(scope.row.duration / 1000 / 60) > 0">{{Math.floor(scope.row.duration / 1000 / 60)}}分 </span>{{Math.floor(scope.row.duration / 1000 % 60)}}秒</template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changePlayViewsCancel">取消</el-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	videoListService,
	videoGetbykeyService,
	videoDeleteService,
	videoStarService,
	videoClickService,
	videoStarListService,
	videoClickListService,
	videoAddViewService,
	videoUpdateViewService,
	videoListVideoViewsViewService
} from '@s/spareParts/VideoService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import VideoAddForm from '@f/spareParts/video/VideoAdd.form';
import VideoSearchform from '@f/spareParts/video/VideoSearch.form';
import { hgetStorage, htimeFormat, hdateInMonth } from '@/util/htools.web';

export default {
	name: 'Video',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		VideoAddForm,
		VideoSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			isShowStarDialog: false,
			state: '',
			star: 0,
			rowId: '',
			player: null,
			playerUrl: null,
			isShowPlayDialog: false,
			isShowPlayStarDialog: false,
			isShowPlayHistoryDialog: false,
			isShowPlayViewsDialog: false,
			tableDataPlayStar: [],
			tableDataPlayHistory: [],
			tableDataPlayViews: [],
			// 视频组件元素
			elVideo: null,
			// 开始时间
			vTimeS: null,
			// 结束时间
			vTimeE: null,
			// 标记调add还是update
			// true 调更新 false调添加
			vTimeEFlag: false,
			// 播放时间统计
			vCount: null,
			// 弹框视频id
			vVid: null,
			// 判断关闭浏览器是否调接口
			vStatue: false,
			vLen: 0
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增视频' : '编辑视频';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.videoList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.videoList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.videoList();
			}
		}
	},
	created() {},
	mounted() {
		this.videoList();
		window.addEventListener('beforeunload', e => {
			console.log('beforeunload');
			console.log(this.vStatue);
			if (this.vVid && this.vStatue) {
				this.setVideoView();
			}
		});
		window.addEventListener('unload', e => {
			console.log('unload');
			console.log(this.vStatue);
			if (this.vVid && this.vStatue) {
				this.setVideoView();
			}
		});
	},
	methods: {
		async videoList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await videoListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await videoGetbykeyService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await videoDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await videoGetbykeyService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		// 评价
		editRateHandler(row) {
			this.rowId = row.id;
			this.isShowStarDialog = true;
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.videoList();
		},
		// 观看评价
		async showPlayStarHandler(row) {
			const dataJson = {
				page: 1,
				pagesize: 999,
				vid: row.id
			};
			const res = await videoStarListService(dataJson);
			this.tableDataPlayStar = res.records;
			this.isShowPlayStarDialog = true;
		},
		// 观看历史
		async showPlayHistoryHandler(row) {
			this.tableDataPlayHistory = [];
			const dataJson = {
				page: 1,
				pagesize: 999,
				vid: row.id
			};
			const res = await videoClickListService(dataJson);
			this.tableDataPlayHistory = res.records;
			this.isShowPlayHistoryDialog = true;
		},
		async changeStateSubmit() {
			const dataJson = {
				id: this.rowId,
				star: this.star
			};
			await videoStarService(dataJson);
			this.isShowStarDialog = false;
			this.videoList();
		},
		async showPlayHandler(row) {
			this.removeVideoPlayHandle();
			this.vVid = row.id;
			const dataJson = {
				id: row.id
			};
			const res = await videoClickService(dataJson);
			console.log(res);
			this.isShowPlayDialog = true;
			this.playerUrl = row.path || 'https://www.runoob.com/try/demo_source/movie.mp4';
			// this.player = this.$video(this.$refs.video);
			this.getVideoPlayTime();
		},
		getVideoPlayTime() {
			console.log(this.$refs.tableDom);

			this.$nextTick(() => {
				console.log(this.$refs.refVideo);
				// this.$refs.refVideo.currentTime = 66;
				this.$refs.refVideo.addEventListener('play', () => {
					this.vTimeS = new Date().getTime();
					console.log('视频播放');
					// this.setVideoView();
					this.vStatue = true;
				});
				this.$refs.refVideo.addEventListener('pause', () => {
					this.vTimeE = new Date().getTime();
					this.vCount += this.vTimeE - this.vTimeS;
					console.log('视频暂停');
					console.log(this.vTimeE);
					this.setVideoView();
					this.vStatue = false;
				});
				// this.$refs.refVideo.addEventListener('ended', () => {
				// 	this.vTimeE = new Date().getTime();
				// 	this.vCount += this.vTimeE - this.vTimeS;
				// 	this.setVideoView();
				// });
			});
		},
		removeVideoPlayHandle() {
			this.vTimeS = null;
			this.vTimeE = null;
			this.vCount = null;
			this.vVid = null;
			this.vTimeEFlag = false;
		},
		async changePlayCancel() {
			this.isShowPlayDialog = false;
			this.videoList();
		},
		// 添加视频观看时间
		async videoAddView() {
			const dataJson = {
				duration: this.vCount || 0,
				// start: htimeFormat(new Date()),
				// htimeFormat(new Date().getTime() - this.vCount)
				// start: htimeFormat(this.vTimeS),
				start: htimeFormat(this.vTimeS),
				vid: this.vVid,
				uid: hgetStorage('id')
			};
			const url = `${this.$envConfig.OfficialWebsite}/web/wms/api/video/addViewPost?duration=${dataJson.duration}&start=${dataJson.start}&uid=${dataJson.uid}&vid=${dataJson.vid}`;
			navigator.sendBeacon(url);
			// await videoAddViewService(dataJson);
			this.vTimeEFlag = true;
		},
		// 更新视频观看时间
		async videoUpdateView() {
			const dataJson = {
				duration: this.vCount || 0,
				start: htimeFormat(this.vTimeS),
				vid: this.vVid
			};

			await videoUpdateViewService(dataJson);
		},
		setVideoView() {
			if (this.vTimeEFlag) {
				this.videoUpdateView();
			} else {
				this.videoAddView();
			}
		},
		// 观看时间列表
		async showPlayViewsHandler(row) {
			this.tableDataPlayViews = [];
			const dataJson = {
				vid: row.id
				// uid: hgetStorage('id')
			};
			const res = await videoListVideoViewsViewService(dataJson);
			this.vLen = res.length;
			this.tableDataPlayViews = res;
			this.isShowPlayViewsDialog = true;
		},
		async changePlayStarCancel() {
			this.isShowPlayStarDialog = false;
		},
		async changePlayHistoryCancel() {
			this.isShowPlayHistoryDialog = false;
		},
		async changePlayViewsCancel() {
			this.isShowPlayViewsDialog = false;
		},
		changeStateCancel() {
			this.state = '';
			this.textarea = '';
			this.isShowStarDialog = false;
		}
	},
	beforeDestory() {},
	destroyed() {}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
.cursorPointer,
/deep/ .cursorPointer span {
	cursor: pointer !important;
}
</style>
