<template>
  <div class="head-serch">
    <el-form class="form" ref="formEle" size="mini" :model="formData" label-width="80px" @submit.native.prevent @keyup.enter.native="searchHandler">
      <el-form-item label="开始时间">
        <el-date-picker v-model="formData.begin" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width:150px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker v-model="formData.end" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width:150px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="申请单号">
        <el-input v-model="formData.code" placeholder="请输入申请单号" clearable></el-input>
      </el-form-item>
      <el-form-item label="库房">
        <el-select v-model="formData.warehouseId" filterable clearable size="mini" placeholder="请选择库房">
          <el-option label="请选择" value=""></el-option>
          <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="formData.states" clearable placeholder="请选择">
          <el-option label="请选择" value=""></el-option>
          <el-option label="未审核" :value="0"></el-option>
          <el-option label="通过" :value="1"></el-option>
          <el-option label="不通过" :value="2"></el-option>
          <el-option label="已执行" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否开票">
        <el-select v-model="formData.kaipiao" clearable placeholder="请选择">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否付款">
        <el-select v-model="formData.fukuan" clearable placeholder="请选择">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请人">
        <el-select ref="multiSelect" v-model="formData.creator" placeholder="请选择申请人" class="select-item" filterable clearable>
          <el-option v-for="(user, index) in userData" :key="index" :label="user.nickName" :value="user.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				begin: '',
				end: '',
				code: '',
				states: '',
				kaipiao: null,
				fukuan: null,
				creator: null,
				warehouseId: null
			},
			userData: [],
			warehouseData: []
		};
	},
	created() {
		this.userList();
		this.warehouseonlymyself();
	},
	methods: {
		// 库房列表
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
		},
		searchHandler() {
			this.formData.kaipiao = this.formData.kaipiao === '' ? null : this.formData.kaipiao;
			this.formData.fukuan = this.formData.kaipiao === '' ? null : this.formData.fukuan;
			this.$emit('searchForm', this.formData);
		},
		removeDuplicateObj(arr) {
			const newArr = [];
			const obj = {};
			for (let i = 0; i < arr.length; i++) {
				if (!obj[arr[i].creator]) {
					newArr.push(arr[i]);
					obj[arr[i].creator] = true;
				}
			}
			return newArr;
		}
	}
};
</script>

<style lang="less" scoped></style>
