<template>
	<div>
		<div class="search_box">
			<el-date-picker
				v-model="date"
				type="datetimerange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button
				type="success"
				class="search_button"
				@click="searchSubmit"
				size="mini"
			>
				搜索
			</el-button>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>采购数量</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelBuy({
									index: 5,
									title: '采购数量',
									options: options5
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(5)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts5" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>采购费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelBuy({
									index: 6,
									title: '采购费用',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(6)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts6" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>销售数量</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelSell({
									index: 7,
									title: '销售数量',
									options: options7
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(7)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts7" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>销售费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelSell({
									index: 8,
									title: '销售费用',
									options: options8
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(8)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts8" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	purchasebywarehousemonthService,
	saleApplicationbywarehousemonthService
} from '@s/hospital/CompsummaryService';
import Dialog from '@c/ui/Dialog';
import {
	htimeFormat,
	hgetStorage,
	hdateInMonth,
	hmonthRange
} from '@u/htools.web';

const posList = [
	'left',
	'right',
	'top',
	'bottom',
	'inside',
	'insideTop',
	'insideLeft',
	'insideRight',
	'insideBottom',
	'insideTopLeft',
	'insideTopRight',
	'insideBottomLeft',
	'insideBottomRight'
];
app.configParameters = {
	rotate: {
		min: -90,
		max: 90
	},
	align: {
		options: {
			left: 'left',
			center: 'center',
			right: 'right'
		}
	},
	verticalAlign: {
		options: {
			top: 'top',
			middle: 'middle',
			bottom: 'bottom'
		}
	},
	position: {
		options: posList.reduce((map, pos) => {
			map[pos] = pos;
			return map;
		}, {})
	},
	distance: {
		min: 0,
		max: 100
	}
};
app.config = {
	rotate: 90,
	align: 'left',
	verticalAlign: 'middle',
	position: 'insideBottom',
	distance: 15,
	onChange: () => {
		const labelOption = {
			rotate: app.config.rotate,
			align: app.config.align,
			verticalAlign: app.config.verticalAlign,
			position: app.config.position,
			distance: app.config.distance
		};
		myChart.setOption({
			series: [
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				}
			]
		});
	}
};
const labelOption = {
	show: true,
	position: app.config.position,
	distance: app.config.distance,
	align: app.config.align,
	verticalAlign: app.config.verticalAlign,
	rotate: app.config.rotate,
	// formatter: '{c}  {name|{a}}',
	formatter: '{a}',
	fontSize: 16,
	rich: {
		name: {}
	}
};
export default {
	components: {
		Dialog
	},
	data() {
		return {
			date: '',
			dateType: 2, // 月
			dateEndType: 2, // 月
			dialogTitle: '',
			options5: {
				// title: {
				// 	text: '报修费用'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options6: {
				// title: {
				// 	text: '报修费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(datas) {
						let res = `${datas[0].name}<br/>`;
						let val;
						const len = datas.length;
						let i = 0;
						for (; i < len; i++) {
							val = datas[i].value
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,');
							res += `<span style="display:flex;justify-content:space-between;align-items:center;">
										<span style="display:inline-block; margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${datas[i].color};"></span>
										<span style="flex:1;margin-right:10px;">${datas[i].seriesName}</span>
										<b>${val}</b>
									</span>`;
						}
						return res;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options7: {
				// title: {
				// 	text: '报修费用'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			options8: {
				// title: {
				// 	text: '报修费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(datas) {
						let res = `${datas[0].name}<br/>`;
						let val;
						const len = datas.length;
						let i = 0;
						for (; i < len; i++) {
							val = datas[i].value
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,');
							res += `<span style="display:flex;justify-content:space-between;align-items:center;">
										<span style="display:inline-block; margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${datas[i].color};"></span>
										<span style="flex:1;margin-right:10px;">${datas[i].seriesName}</span>
										<b>${val}</b>
									</span>`;
						}
						return res;
					}
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['line', 'bar', 'stack']
						},
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: []
			},
			isShowBigEcharts: false
		};
	},
	mounted() {
		const myDate = new Date();
		const curMonth = myDate.getMonth() + 1;
		const curYear = myDate.getFullYear();
		const curYM = `${curYear}-${curMonth}`;
		const prevMonthes = hmonthRange(curYM, 7, 1);
		prevMonthes.shift();
		prevMonthes.push(curYM);
		const beginMonth = prevMonthes[1];
		let beginTime = `${beginMonth}-01 00:00:00`;
		// const endTime = curYM + "-" + hdateInMonth(beginMonth) + " 23:59:59";
		let endTime = htimeFormat(
			new Date().getTime(),
			`{y}-${curMonth + 1}-01 23:59:59`
		);
		endTime = new Date(endTime).getTime() - 1000 * 60 * 60 * 24;
		endTime = htimeFormat(endTime, '{y}-{m}-{d} 23:59:59');
		// 上面的没用
		beginTime = this.$moment(new Date())
			.subtract(5, 'months')
			.format('YYYY-MM-01 00:00:00');
		endTime = this.$moment().endOf('months').format('YYYY-MM-DD HH:mm:ss');
		this.date = [`${beginTime}`, `${endTime}`];
		this.purchasebywarehousemonthService();
		this.saleApplicationbywarehousemonthService();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index === 5) {
					this.dialogTitle = '采购数量';
					this.options5.series.forEach(item => {
						item.label = labelOption;
					});
					this.myChart.setOption(this.options5);
				}
				if (index === 6) {
					this.dialogTitle = '采购费用';
					this.options6.series.forEach(item => {
						item.label = labelOption;
					});
					this.myChart.setOption(this.options6);
				}
				if (index === 7) {
					this.dialogTitle = '销售数量';
					this.options7.series.forEach(item => {
						item.label = labelOption;
					});
					this.myChart.setOption(this.options7);
				}
				if (index === 8) {
					this.dialogTitle = '销售费用';
					this.options8.series.forEach(item => {
						item.label = labelOption;
					});
					this.myChart.setOption(this.options8);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		searchSubmit() {
			this.purchasebywarehousemonthService();
			this.saleApplicationbywarehousemonthService();
		},
		// 1、处理横坐标 时间
		// 2、去重
		// 3、排序
		detaEchartsTime(res) {
			console.log('res ygAdmin 进销统计');
			console.log(res);
		},
		// ygAdmin 采购申请 -> 按月分仓库统计
		async purchasebywarehousemonthService() {
			// 横坐标 时间
			let dateArr = [];
			let legendArrTemp = [];
			const legendArr = [];
			// 纵坐标 1、	报修数量
			const repaircntArr = [];
			// 纵坐标 2、	报修费用
			const repairpriceArr = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1])
			};
			const res = await purchasebywarehousemonthService(dataJson);
			this.tableDownDataBuy = res;
			// 1、处理横坐标 时间
			// 2、去重
			// 3、排序
			// 4、获取医院列表
			// 5、医院列表去重
			// 6、医院item下的数组
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.warehousename);
				item.mthTime = new Date(item.mth).getTime();
			});
			res.sort((a, b) => {
				return a.mthTime > b.mthTime ? 1 : -1;
			});
			dateArr.sort((a, b) => {
				return a > b ? 1 : -1;
			});
			dateArr = [...new Set(dateArr)];
			legendArrTemp = [...new Set(legendArrTemp)];
			legendArrTemp.forEach(item => {
				legendArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repaircntArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repairpriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
			});
			for (let index = 0; index < legendArr.length; index++) {
				res.forEach(item1 => {
					if (legendArr[index].name == item1.warehousename) {
						repaircntArr[index].data.push(item1.cnt);
						repairpriceArr[index].data.push(item1.sumprice);
					}
				});
			}
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.warehousename);
			});
			console.log('repairpriceArr');
			console.log(repairpriceArr);
			this.echartsInit5({ xAxis: dateArr, series: repaircntArr });
			this.echartsInit6({ xAxis: dateArr, series: repairpriceArr });
		},
		echartsInit5(res) {
			this.options5.xAxis[0].data = [...new Set(res.xAxis)];
			this.options5.series = res.series;
			const chartDom = document.getElementById('echarts5');
			this.$echarts.init(document.getElementById('echarts5')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit6(res) {
			this.options6.xAxis[0].data = [...new Set(res.xAxis)];
			this.options6.series = res.series;
			const chartDom = document.getElementById('echarts6');
			this.$echarts.init(document.getElementById('echarts6')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		// ygAdmin 采购申请 -> 按月分仓库统计
		async saleApplicationbywarehousemonthService() {
			// 横坐标 时间
			let dateArr = [];
			let legendArrTemp = [];
			const legendArr = [];
			// 纵坐标 1、	报修数量
			const repaircntArr = [];
			// 纵坐标 2、	报修费用
			const repairpriceArr = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1])
			};
			const res = await saleApplicationbywarehousemonthService(dataJson);
			this.tableDownDataSell = res;
			// 1、处理横坐标 时间
			// 2、去重
			// 3、排序
			// 4、获取医院列表
			// 5、医院列表去重
			// 6、医院item下的数组
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.warehousename);
				item.mthTime = new Date(item.mth).getTime();
			});
			res.sort((a, b) => {
				return a.mthTime > b.mthTime ? 1 : -1;
			});
			dateArr.sort((a, b) => {
				return a > b ? 1 : -1;
			});
			dateArr = [...new Set(dateArr)];
			legendArrTemp = [...new Set(legendArrTemp)];
			legendArrTemp.forEach(item => {
				legendArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repaircntArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
				repairpriceArr.push({
					name: item,
					type: 'bar',
					data: [],
					emphasis: {
						focus: 'series'
					}
				});
			});
			for (let index = 0; index < legendArr.length; index++) {
				res.forEach(item1 => {
					if (legendArr[index].name == item1.warehousename) {
						repaircntArr[index].data.push(item1.cnt);
						repairpriceArr[index].data.push(item1.sumprice);
					}
				});
			}
			res.forEach(item => {
				dateArr.push(item.mth);
				legendArrTemp.push(item.warehousename);
			});

			this.echartsInit7({ xAxis: dateArr, series: repaircntArr });
			this.echartsInit8({ xAxis: dateArr, series: repairpriceArr });
		},
		echartsInit7(res) {
			this.options7.xAxis[0].data = [...new Set(res.xAxis)];
			this.options7.series = res.series;
			const chartDom = document.getElementById('echarts7');
			this.$echarts.init(document.getElementById('echarts7')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		echartsInit8(res) {
			this.options8.xAxis[0].data = [...new Set(res.xAxis)];
			this.options8.series = res.series;
			const chartDom = document.getElementById('echarts8');
			this.$echarts.init(document.getElementById('echarts8')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		downloadExcelBuy(obj) {
			const tHeader = ['医院', '日期', '采购数量', '采购费用'];
			const filterVal = ['warehousename', 'mth', 'cnt', 'sumprice'];
			const data = this.formatJson(filterVal, this.tableDownDataBuy);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `采购统计${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelSell(obj) {
			const tHeader = ['医院', '日期', '销售数量', '销售费用'];
			const filterVal = ['warehousename', 'mth', 'cnt', 'sumprice'];
			const data = this.formatJson(filterVal, this.tableDownDataSell);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `销售统计${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.el-col {
	margin-bottom: 20px;
}
.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}
.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}
.search_box {
	margin-bottom: 20px;
}
.echart_item {
	width: 500px !important;
	height: 360px;
	margin: 0 auto;
}
.echart_box {
	width: 460px;
	height: 360px;
}
.search_button {
	margin-left: 10px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
