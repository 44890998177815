<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="88px">
				<el-form-item label="名称" prop="name">
					<el-input v-model="formData.name" clearable placeholder="请输入名称" />
				</el-form-item>
				<el-form-item label="库房" prop="warehouseId">
					<el-select ref="multiSelect" v-model="formData.warehouseId" placeholder="请选择库房" class="select-item" filterable clearable>
						<el-option v-for="(warehouse, index) in warehouseData" :key="index" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="变量" prop="name">
					<el-input v-model="formData.name" clearable placeholder="请输入名称" />
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { wareareaAddService, wareareaEditService } from '@s/spareParts/WareareaService';
// eslint-disable-next-line import/no-cycle
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';

export default {
	props: ['selectData_p'],
	components: {},
	data() {
		return {
			editId: -1,
			// 弹窗
			formData: {
				name: '',
				warehouseId: ''
			},
			formRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				warehouseId: [
					{
						required: true,
						message: '请选择库房',
						trigger: 'change'
					}
				]
			},
			warehouseData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.warehouseData.length === 0 && (await this.warehouseList());
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.warehouseList();
	},
	methods: {
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await wareareaAddService(this.formData);
                        this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await wareareaEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowBatchAddDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
