<template>
	<div>
		<el-row>
			<el-col :span="24">
				<el-form
					ref="formEle"
					:inline="true"
					:model="formData"
					:rules="formRules"
					size="small"
					label-width="79px"
					:disabled="isShowDetail_p"
				>
					<el-form-item label="源库房" prop="fromWarehouseId">
						<el-select
							v-model="formData.fromWarehouseId"
							clearable
							placeholder="请选择"
							style="width: 160px"
						>
							<el-option label="请选择" value=""></el-option>
							<el-option
								v-for="(item, index) in warehouseData"
								:label="item.name"
								:value="item.id"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="目标库房" prop="toWarehouseId">
						<el-select
							v-model="formData.toWarehouseId"
							clearable
							placeholder="请选择"
							style="width: 160px"
						>
							<el-option label="请选择" value=""></el-option>
							<el-option
								v-for="(item, index) in warehouseData"
								:label="item.name"
								:value="item.id"
								:key="index"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="申请时间" prop="requestTime">
						<el-date-picker
							v-model="formData.requestTime"
							type="datetime"
							placeholder="请选择申请时间"
						></el-date-picker>
					</el-form-item>
					<el-form-item v-if="!isShowDetail_p">
						<el-button
							:disabled="isAddMetarial"
							type="primary"
							@click="addDetailHandler"
						>
							新增物料
						</el-button>
					</el-form-item>
					<el-table ref="tableDom" :data="tableData" border>
						<el-table-column label="物料" prop="goodsId">
							<template slot-scope="scope">
								<el-select
									v-if="scope.row.isAddDetail"
									class="fastChooseGood"
									v-model="scope.row.goodsId"
									filterable
									@keyup.native="getGoodsVal($event)"
								>
									<el-option
										v-for="(item, index) in materialData"
										:key="index"
										:label="item.name"
										:value="item.id"
									></el-option>
									<p slot="empty" class="faseAddGood">
										没有您想要的物料，
										<span @click="showFastAddGood">点此快速添加</span>
									</p>
								</el-select>

								<MaterialTooltip
									v-else
									:materialObjP="{
										goodsId: scope.row.goodsId,
										goodsName: scope.row.goodsName
									}"
								></MaterialTooltip>
								<!-- <el-popover placement="top-start" title="物料详情" width="200" trigger="click" @show="materialDetail(scope.row.goodsId)" v-else>
                  <ul>
                    <li>编码：{{ materialDetailData.code }}</li>
                    <li>名称：{{ materialDetailData.name }}</li>
                    <li>分类：{{ materialDetailData.typeName }}</li>
                    <li>厂家：{{ materialDetailData.factory }}</li>
                    <li>规格：{{ materialDetailData.spec }}</li>
                    <li>型号：{{ materialDetailData.goodsVer }}</li>
                    <li>标签：{{ materialDetailData.tags }}</li>
                  </ul>
                  <span slot="reference">{{ scope.row.goodsName }}</span>
                </el-popover> -->
							</template>
						</el-table-column>
						<el-table-column label="数量" prop="cnt">
							<template slot-scope="scope">
								<el-input
									v-if="scope.row.isAddDetail"
									v-model="scope.row.cnt"
									type="number"
									placeholder="请输入数量"
								></el-input>
								<span v-else class="numberp10">
									<span
										v-if="
											scope.row.indonecnt == scope.row.outdonecnt &&
												scope.row.indonecnt == scope.row.cnt &&
												scope.row.outdonecnt == scope.row.cnt
										"
										style="color:#67C23A; cursor:pointer;"
									>
										<span title="已入库">{{ scope.row.indonecnt || 0 }}</span>
										/
										<span title="已出库">{{ scope.row.outdonecnt || 0 }}</span>
										/
										<span title="总数">{{ scope.row.cnt }}</span>
									</span>
									<span v-else style="color:#E6A23C; cursor:pointer;">
										<span title="已入库">{{ scope.row.indonecnt || 0 }}</span>
										/
										<span title="已出库">{{ scope.row.outdonecnt || 0 }}</span>
										/
										<span title="总数">{{ scope.row.cnt }}</span>
									</span>
								</span>
							</template>
						</el-table-column>
						<el-table-column label="备注" prop="memo">
							<template slot-scope="scope">
								<el-input
									v-if="scope.row.isAddDetail"
									v-model="scope.row.memo"
									placeholder="请输入备注"
								></el-input>
								<span v-else>{{ scope.row.memo }}</span>
							</template>
						</el-table-column>
						<el-table-column
							label="操作"
							align="left"
							fixed="right"
							width="150"
							v-if="!isShowDetail_p"
						>
							<!-- <template slot-scope="scope">
              <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'transfer', id: 'btn-update-row' }" @click="saveDetailHandler(scope.row)" v-if="scope.row.isAddDetail">
                保存
              </el-button>
              <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'transfer', id: 'btn-update-row' }" @click="editDetailHandler(scope.row)" v-if="!scope.row.isAddDetail">
                编辑
              </el-button>
              <el-button id="btn-remove-row" type="danger" size="mini" v-authorize="{ name: 'remove', type: 'transfer', id: 'btn-remove-row' }" @click="removeDetailHandler(scope)">
                删除
              </el-button>
            </template> -->
							<template slot-scope="scope">
								<el-button
									id="btn-update-row"
									type="primary"
									size="mini"
									@click="saveDetailHandler(scope.row)"
									v-if="scope.row.isAddDetail"
								>
									保存
								</el-button>
								<el-button
									id="btn-update-row"
									type="primary"
									size="mini"
									@click="editDetailHandler(scope.row)"
									v-if="!scope.row.isAddDetail"
								>
									编辑
								</el-button>
								<el-button
									:disabled="scope.row.outdonecnt > 0"
									:title="
										scope.row.outdonecnt > 0 ? '已执行出库的物料不能删除' : ''
									"
									id="btn-remove-row"
									type="danger"
									size="mini"
									@click="removeDetailHandler(scope)"
								>
									删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form>
			</el-col>
			<el-col :span="24" style="text-align: right; margin-top: 10px">
				<span class="dialog-footer" style="text-align: right">
					<template v-if="editId !== -1">
						<el-button @click="reduceStockListHandler" type="primary">
							一键出库
						</el-button>
					</template>
					<el-button @click="formCancel">取 消</el-button>
					<el-button type="primary" @click="formSubmit">确 定</el-button>
				</span>
			</el-col>
		</el-row>
		<!-- TODELETE 一键出库 -->
		<Dialog :title="dialogTitleFastOut" :visible.sync="isShowReduceStockListDialog" width="70%">
			<CurrentStockReduceListForm
				v-if="isShowReduceStockListDialog"
				:isShowReduceStockDialog_p.sync="isShowReduceStockListDialog"
				:isRefreshDetail_p.sync="isRefreshDetail"
				:selectData_p="selectDatainOutRecord.items"
				:selectSearchData_p="{
					inoutType: selectDatainOutRecord.inoutType,
					code: selectDatainOutRecord.code
				}"
				:isShowDetail_p="false"
			></CurrentStockReduceListForm>
		</Dialog>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<MaterialAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				@isToParentGoodsNmae_p="materialList"
				:selectData_p="selectData"
				:isFastAddGood_p.sync="isFastAddGood"
			></MaterialAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	transferAddService,
	transferEditService,
	transferMaterialAddService,
	transferMaterialDeleteService,
	transferMaterialEditService,
	transferDetailService
} from '@s/spareParts/TransferService';
import { materialAllListService, materialDetailService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { currentStockListService } from '@s/spareParts/CurrentStockService';
import { supplierAllListService } from '@s/spareParts/SupplierService';
import { htimeFormat, hremoveStorage } from '@u/htools.web';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';
import ListMixin from '@m/List.mixin';
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import CurrentStockReduceListForm from '@f/spareParts/currentStock/CurrentStockReduceList.form';
import Dialog from '@c/ui/Dialog';
import MaterialTooltip from '@/components//MaterialTooltip';

export default {
	props: [
		'selectData_p',
		'isShowDetail_p',
		'isShowAEDialog_p',
		'selectDataFasttransfer_p',
		'selectNativesData_p'
	],
	mixins: [ListMixin],
	components: { MaterialAddForm, CurrentStockReduceListForm, Dialog, MaterialTooltip },
	data() {
		const validFromWarehouse = (rule, value, callback) => {
			if (value) {
				if (value === this.formData.toWarehouseId) {
					callback(new Error('源库房和目标库房不能相同'));
				}
			}
			callback();
		};
		const validToWarehouse = (rule, value, callback) => {
			if (value) {
				if (value === this.formData.fromWarehouseId) {
					callback(new Error('源库房和目标库房不能相同'));
				}
			}
			callback();
		};
		return {
			isRefreshDetail: false,
			selectData: [],
			selectData1: [],
			isFastAddGood: true,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			// 弹窗
			formData: {
				fromWarehouseId: '',
				toWarehouseId: '',
				requestTime: new Date()
			},
			formRules: {
				fromWarehouseId: [
					{
						required: true,
						message: '请选择源库房',
						trigger: 'change'
					},
					{
						validator: validFromWarehouse,
						trigger: 'change'
					}
				],
				toWarehouseId: [
					{
						required: true,
						message: '请选择目标库房',
						trigger: 'change'
					},
					{
						validator: validToWarehouse,
						trigger: 'change'
					}
				],
				requestTime: [
					{
						required: true,
						message: '请选择申请时间',
						trigger: 'blur'
					}
				]
			},
			supplierData: [],
			materialData: [],
			warehouseData: [],
			materialDetailData: '',
			isShowReduceStockListDialog: false,
			selectDatainOutRecord: null
		};
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		},
		dialogTitleFastOut() {
			return '一键出库';
		}
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					console.log(newValue[0]);
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						/* const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0]; */
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name,
							cnt: item.cnt,
							indonecnt: item.indonecnt,
							outdonecnt: item.outdonecnt,
							id: item.id,
							memo: item.memo,
							price: item.price,
							totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
							requestId: '',
							/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
							isAddDetail: false
						});
					});
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		selectDataFasttransfer_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					// this.formData.fromWarehouseId = newValue[0].warehouseId;
					// this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						/* const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0]; */
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name,
							cnt: item.cnt,
							indonecnt: item.indonecnt,
							outdonecnt: item.outdonecnt,
							id: item.id,
							memo: item.memo,
							price: item.price,
							totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
							requestId: '',
							/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
							isAddDetail: false
						});
					});
				}
			},
			deep: true,
			immediate: true
		},
		// 当前库存列表 备选来的申请调拨出库
		selectNativesData_p: {
			handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.fromWarehouseId = newValue[0].warehouseId;
				newValue.forEach(item => {
					this.tableData.push({
						goodsId: item.goodsid,
						goodsName: item.goodsName,
						cnt: item.cnt,
						indonecnt: item.indonecnt || 0,
						outdonecnt: item.outdonecnt || 0,
						id: item.id,
						memo: item.memo,
						price: item.price || 0,
						totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
						requestId: '',
						/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
						isAddDetail: false
					});
				});
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshDetail: {
			handler(newValue) {
				console.log('newValue 一键出库 刷新');
				console.log(newValue);
				if (newValue) {
					this.showDialogEditHandler();
				}
			},
			deep: true
		}
	},
	created() {
		/* this.supplierList(); */
		this.materialList();
		this.warehouseList();
	},
	methods: {
		// 一键出库后 刷新详情 TODO
		async showDialogEditHandler() {
			const dataJson = {
				id: this.formData.id
			};
			const res = await transferDetailService(dataJson);
			const arr = res.items;
			for (let index = 0; index < arr.length; index++) {
				this.tableData.forEach((item1, index1, arr1) => {
					if (arr[index].id === item1.id) {
						item1.indonecnt = arr[index].indonecnt;
						item1.outdonecnt = arr[index].outdonecnt;
						item1.cnt = arr[index].cnt;
					}
				});
			}
		},
		// 一键出库
		async reduceStockListHandler() {
			const goods = [];
			const obj = {};
			let errorGoodsLen = [];
			let errorGoodsNum = [];
			let errorGoodsName = [];
			let errorMsg = null;
			let flag = false;
			// 过滤调已经执行入库的数据
			const tableData =
				this.tableData.filter(item => {
					return item.cnt != item.outdonecnt;
				}) || [];
			tableData.forEach(item => {
				goods.push(item.goodsId);
			});
			if (goods.length === 0) {
				this.$message.warning('当前没有需要出库的！');
				return false;
			}
			const dataJson = {
				pageNum: 1,
				pageSize: tableData?.length + 1,
				warehouse: this.formData.fromWarehouseId,
				goods: goods
			};
			const res = await currentStockListService(dataJson);
			obj.inoutType = 6;
			obj.code = `${this.formData.fromWareHouseName}-${
				this.formData.toWarehouseName
			}(${htimeFormat(this.formData.requestTime)})`;

			console.log('this.formData++++++++++++++++++');
			console.log(this.formData);
			obj.items = [...res.records];

			// if (obj.items.length === 0) {
			// 	this.$message.warning('当前库存没有合适的物料！');
			// 	return false;
			// }

			// 遍历表格数据和接口数据，获取errorLen表格物料的长度错误 数量错误
			tableData.forEach((item1, index1) => {
				item1.errorLen = 0;
				item1.errorNum = false;
				obj.items.forEach(item2 => {
					if (item1.goodsId == item2.goodsid) {
						item1.errorLen += 1;
					}
					if (item1.goodsId == item2.goodsid && item1.cnt - item1.donecnt > item2.cnt) {
						item1.errorNum = true;
					}
				});
				if (obj.items.length == 0 || !obj.items?.length) {
					item1.errorNum = true;
				}
			});

			// 情况1 S ------------------------------物料放在了不同的地方，需要手动出库
			errorGoodsLen = tableData.filter(item => item.errorLen > 1);
			if (errorGoodsLen?.length > 0) {
				errorGoodsLen.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料放在了不同的地方，需要手动出库!</p>`;
				errorMsg = message;
			}
			// 情况1 E ------------------------------

			// 情况2 S ------------------------------物料，库房中没有，请先把库房中的物料补齐
			errorGoodsLen = tableData.filter(item => item.errorLen == 0);
			if (errorGoodsLen?.length > 0) {
				errorGoodsName = [];
				errorGoodsLen.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料，库房中没有，请先把库房中的物料补齐!</br></p>`;
				errorMsg = errorMsg ? errorMsg + message : message;
			}
			// 情况2 E ------------------------------

			// 情况3 S ------------------------------物料，库房中数量不够，请先把库房中的物料补齐
			errorGoodsNum = tableData.filter(item => item.errorNum && item.errorLen == 1);
			if (errorGoodsNum?.length > 0) {
				errorGoodsName = [];
				errorGoodsNum.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料，库房中数量不够，请先把库房中的物料补齐!</p>`;
				errorMsg = errorMsg ? errorMsg + message : message;
			}
			// 情况3 E ------------------------------

			if (flag) {
				await this.$message({
					dangerouslyUseHTMLString: true,
					type: 'warning',
					showClose: true,
					customClass: 'messageClass',
					message: errorMsg
				});
				return false;
			}

			// 情况3 S ------------------------------
			// let arr1 = [];
			// let arr2 = [];
			// let flag = false;
			// obj.items.forEach(item => {
			// 	arr1.push(item.goodsid);
			// });
			// tableData.forEach(item => {
			// 	arr2.push(item.goodsId);
			// });

			// arr1 = arr1.sort();
			// arr2 = arr2.sort();
			// for (let index = 0; index < arr1.length; index++) {
			// 	if (arr1[index] !== arr2[index]) {
			// 		flag = true;
			// 	}
			// }
			// if (flag) {
			// 	this.$message.warning('请手动出库！');
			// 	return false;
			// }
			// // 情况3 E ------------------------------
			obj.items.forEach(item => {
				item.cnt1 = undefined;
				item.price1 = undefined;
			});

			this.selectDatainOutRecord = obj;
			this.isRefreshDetail = false;
			this.isShowReduceStockListDialog = true;
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				requestId: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			/* const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name; */
		},
		saveDetailHandler(row) {
			if (!row.goodsId || row.cnt === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt <= 0) {
				this.$message.warning('请输入正确的数量');
				return false;
			}
			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			let goodsId = null;
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						goodsId = item.id;
					}
				});
			goodsName &&
				this.tableData.forEach(item => {
					if (item.isAddDetail) {
						item.goodsId = goodsId;
					}
				});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async supplierList() {
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await transferMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await transferMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await transferMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		// 没有您想要的物料，点此快速添加
		showFastAddGood() {
			this.dialogAddHandlerMixin();
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || ''
			});
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				// let flag = true;
				// this.tableData.forEach(item => {
				// 	if (item.cnt == 0) {
				// 		flag = false;
				// 		this.$message.warning(`${item.goodsName}数量不能为0`);
				// 	}
				// 	console.log(flag);
				// 	return true;
				// });
				let itemError = {};
				const flag = this.tableData.some(item => {
					if (item.cnt == 0) {
						itemError = item;
					}
					return item.cnt == 0;
				});
				if (flag) {
					this.$message.warning(`${itemError.goodsName}数量不能为0`);
					return false;
				}
				if (valid) {
					if (this.editId === -1) {
						this.formData.items = [];
						this.formData.requestTime = htimeFormat(this.formData.requestTime);
						this.tableData.forEach(item => {
							this.formData.items.push({
								cnt: item.cnt,
								goodsId: item.goodsId,
								memo: item.memo,
								price: item.price,
								supplierId: item.supplierId
							});
						});
						await transferAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							requestTime: htimeFormat(this.formData.requestTime),
							fromWarehouseId: this.formData.fromWarehouseId,
							toWarehouseId: this.formData.toWarehouseId
						};
						await transferEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
			hremoveStorage('fastSaleOrPurchase');
		}
	}
};
</script>

<style lang="less" scoped>
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}
.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
