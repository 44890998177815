import { hgetRequest, hpostRequest } from '../../util/htools.axios';
import { changeTreeDataToChildren } from '../../util/htools.tree';

/*
 *@Description: 部门列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 09:56:50
 */
export const departmentListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hdepartment/department/departmentAllQuery', dataJson);
			resolve(changeTreeDataToChildren(res));
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 部门添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 09:57:05
 */
export const departmentAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				isShow: 0,
				departmentIcon: '',
				departmentName: '',
				departmentSort: '',
				pid: '',
				state: '',
				url: '',
				...data
			};
			const res = await hpostRequest('hdepartment/department/departmentAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 部门编辑
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 09:57:16
 */
export const departmentEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				isShow: 0,
				departmentIcon: '',
				departmentName: '',
				departmentSort: '',
				pid: '',
				state: '',
				url: '',
				...data
			};
			const res = await hpostRequest('hdepartment/department/departmentInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 部门删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 09:57:33
 */
export const departmentDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentIdList: [],
				...data
			};
			const res = await hpostRequest('hdepartment/department/departmentDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 部门详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 09:57:52
 */
export const departmentDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentId: '',
				...data
			};
			const res = await hgetRequest('hdepartment/department/departmentByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default departmentListService;
