<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<PurchaseApplicationSearchform
				@searchForm="searchFormHandler"
			></PurchaseApplicationSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'purchaseApplication'"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			:row-class-name="tableRowClassName"
		>
			<el-table-column label="序号" width="140">
				<template slot-scope="scope">
					<p style="font-size: 14px;font-weight:bold;">
						{{ scope.row.id }}
					</p>
					<p style="font-size: 12px">
						创建:{{
							scope.row.createDate | parseTime('{y}-{m}-{d}')
						}}
					</p>
					<p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
						更新:{{
							scope.row.lastUpdateDate | parseTime('{y}-{m}-{d}')
						}}
					</p>
				</template>
			</el-table-column>
			<el-table-column label="申请单号" width="180">
				<template slot-scope="scope">
					<p style="font-size: 14px">{{ scope.row.code }}</p>
					<p style="font-size: 12px">
						申请:{{ scope.row.requestTime | parseTime }}
					</p>
					<p style="font-size: 12px">
						申请人:{{ scope.row.creatorName }}
					</p>
				</template>
			</el-table-column>
			<el-table-column label="库房" width="200">
				<template slot-scope="scope">
					<p style="font-size: 14px">
						<b>{{ scope.row.warehouseName }}</b>
					</p>
				</template>
			</el-table-column>
			<el-table-column label="采购数量" width="100">
				<template slot-scope="scope">
					<p style="font-size: 14px">
						<span
							v-if="scope.row.doneCnt == scope.row.sumCnt"
							style="color:#67C23A; cursor:pointer;"
						>
							<span title="已入库">{{ scope.row.doneCnt }}</span>
							/
							<span title="物料总数">{{ scope.row.sumCnt }}</span>
						</span>
						<span v-else style="color:#E6A23C;cursor:pointer;">
							<span title="已入库">{{ scope.row.doneCnt }}</span>
							/
							<span title="物料总数">{{ scope.row.sumCnt }}</span>
						</span>
					</p>
					<p style="font-size: 12px">金额:{{ scope.row.sumPrice }}</p>
				</template>
			</el-table-column>

			<el-table-column label="相关物料" min-width="350">
				<template slot-scope="scope">
					<p
						@click="showMoreMaterial(scope.row)"
						v-if="scope.row.items.length > 3"
					>
						<el-link type="primary">更多</el-link>
					</p>
					<!-- <div style="width:100%; display:flex; flex-wrap: wrap; justify-content: flex-start; align-items: center;"> -->
					<p
						v-for="(item1, index1) in scope.row.items"
						:key="item1.id"
					>
						<template v-if="index1 < scope.row.showMaterialLength">
							<b>{{ index1 + 1 }}</b>
							、{{ item1.goodsName }}：
							<b>{{ item1.price }}</b>
							元 ×
							<b>{{ item1.cnt }}</b>
							个
						</template>
					</p>
					<!-- </div> -->
				</template>
			</el-table-column>

			<!-- <el-table-column label="金额" width="80">
        <template slot-scope="scope">
          {{ scope.row.sumPrice }}
        </template>
      </el-table-column> -->

			<el-table-column label="状态记录" width="150">
				<template slot-scope="scope">
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否开票:
						</span>
						<el-switch
							v-model="scope.row.kaipiao"
							@change="setKaipiao($event, scope.row)"
						></el-switch>
					</p>
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否付款:
						</span>
						<el-switch
							v-model="scope.row.fukuan"
							@change="setFukuan($event, scope.row)"
						></el-switch>
					</p>
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否申请调拨:
						</span>
						<el-switch
							v-model="scope.row.doDB"
							@change="setDB($event, scope.row)"
						></el-switch>
					</p>
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否申请销售:
						</span>
						<el-switch
							v-model="scope.row.doSell"
							@change="setSell($event, scope.row)"
						></el-switch>
					</p>
				</template>
			</el-table-column>
			<!-- <el-table-column label="是否开票">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.kaipiao" @change="setKaipiao($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否付款">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.fukuan" @change="setFukuan($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否申请调拨">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.doDB" @change="setDB($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否申请销售">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.doSell" @change="setSell($event, scope.row)"></el-switch>
        </template>
      </el-table-column> -->
			<el-table-column label="状态" width="100">
				<template slot-scope="scope">
					<span v-if="scope.row.state === 0">未审核</span>
					<span v-else-if="scope.row.state === 1">通过</span>
					<span
						v-else-if="scope.row.state === 2"
						style="color: #f56c6c"
					>
						不通过
					</span>
					<span v-else style="color: #67c23a">已执行</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="申请时间" width="160">
        <template slot-scope="scope">
          <p style="font-size: 14px">{{ scope.row.requestTime | parseTime }}</p>
          <p style="font-size: 12px">
            创建:{{ scope.row.createDate | parseTime('{y}-{m}-{d}') }}
          </p>
          <p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
            更新:{{ scope.row.lastUpdateDate | parseTime('{y}-{m}-{d}') }}
          </p>
        </template>
      </el-table-column> -->
			<el-table-column
				label="操作"
				align="left"
				fixed="right"
				width="350"
			>
				<template slot-scope="scope">
					<!-- <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'purchaseApplication', id: 'btn-update-row' }" @click="shouDetailHandler(scope.row, true)" v-if="scope.row.state !== 0 && scope.row.state !== 1 && scope.row.state !== 2"> -->
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{
							name: 'update',
							type: 'purchaseApplication',
							id: 'btn-update-row'
						}"
						@click="shouDetailHandler(scope.row, true)"
						v-if="scope.row.state !== 0 && scope.row.state !== 2"
					>
						详情
					</el-button>
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{
							name: 'update',
							type: 'purchaseApplication',
							id: 'btn-update-row'
						}"
						@click="editSingleHandler(scope.row)"
						v-if="scope.row.state === 0 || scope.row.state === 2"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						:disabled="scope.row.doneCnt > 0"
						:title="
							scope.row.doneCnt > 0
								? '已执行入库的采购申请不能删除'
								: ''
						"
						type="danger"
						v-authorize="{
							name: 'remove',
							type: 'purchaseApplication',
							id: 'btn-remove-row'
						}"
						@click="deleteSingleHandler(scope.row)"
						v-if="
							scope.row.state === 0 ||
								scope.row.state === 1 ||
								scope.row.state === 2 ||
								scope.row.state === 3
						"
					>
						删除
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{
							name: 'remove',
							type: 'purchaseApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(1, scope.row)"
						v-if="scope.row.state === 0"
					>
						通过
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{
							name: 'remove',
							type: 'purchaseApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(2, scope.row)"
						v-if="scope.row.state === 0"
					>
						拒绝
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{
							name: 'remove',
							type: 'purchaseApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(3, scope.row)"
						v-if="scope.row.state === 1"
					>
						执行采购
					</el-button>
					<div style="margin-top: 10px"></div>
					<el-button
						class="filter-item"
						type="primary"
						@click="showPrintData(scope.row)"
					>
						打印
					</el-button>
					<el-button
						type="danger"
						class="filter-item"
						id="btn-cancel-row"
						v-authorize="{
							name: 'cancel',
							type: 'purchaseApplication',
							id: 'btn-cancel-row'
						}"
						@click="buyRequestCance(scope.row)"
					>
						采购申请取消
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
			<PurchaseApplicationAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:isShowDetail_p="isShowDetail"
			></PurchaseApplicationAddForm>
		</Dialog>
		<Dialog title="备注" :visible.sync="isShowMemoDialog" width="40%">
			<el-input
				type="textarea"
				:rows="5"
				placeholder="请输入备注"
				v-model="textarea"
			></el-input>
			<div style="text-align: right; margin-top: 20px">
				<el-button @click="changeStateCancel">取消</el-button>
				<el-button type="primary" @click="changeStateSubmit">
					确定
				</el-button>
			</div>
		</Dialog>

		<el-dialog
			title="打印"
			:visible.sync="printData"
			width="40%"
			:close-on-click-modal="false"
			append-to-body
		>
			<div class="print-data">
				<div id="printData">
					<table id="myTable" class="table" border="0">
						<thead>
							<tr
								class="tTitle"
								style="border:0px solid #ffffff;"
							>
								<th colspan="6" style=" text-align: center;">
									采购单
								</th>
							</tr>
							<tr
								style="border:0px solid #ffffff; border-bottom:1px solid #333333;"
							>
								<th
									colspan="6"
									style="text-align:left;padding-left:0;padding-right:0;"
								>
									<span
										style="display: flex;justify-content: space-between;"
									>
										<span>
											<b>库房:</b>
											<b style="font-weight:normal;">
												{{ printDataObj.warehouseName }}
											</b>
											<b
												style="margin-right:20px;display:inline-block;"
											></b>
										</span>
										<span>
											<b>单号:</b>
											<b style="font-weight:normal;">
												{{ printDataObj.code }}
											</b>
										</span>
									</span>
								</th>
							</tr>
							<tr class="tpHeadTr">
								<th style="min-width:82px;">物料</th>
								<th style="min-width:82px;">科室</th>
								<th style="min-width:82px;">数量(个)</th>
								<th style="min-width:82px;">单价(元)</th>
								<th style="min-width:82px;">总价(元)</th>
								<th style="min-width:82px;">备注</th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="tpBodyTr"
								v-for="(item, index) in [...printDataList]"
								:key="index"
							>
								<td>{{ item.goodsName }}</td>
								<td>{{ item.departname }}</td>
								<td>{{ Number(item.cnt) }}</td>
								<td>{{ Number(item.price) }}</td>
								<td>{{ Number(item.totalMoney) }}</td>
								<td>{{ item.memo }}</td>
							</tr>
						</tbody>
						<tfoot>
							<!-- <tr style="border:0px solid #ffffff; border-top:1px solid #333333;">
								<td colspan="6" style="padding-left:0;padding-right:0;">
									<span style="display: flex;justify-content: space-between;">
										<span>
											<b>申请人:</b>{{ printDataObj.creatorName }}<b
												style="margin-right:20px;display:inline-block;"></b>
											<b>经办人:</b>
										</span>
										<span><b>日期:</b>{{ printDataObj.requestTime | parseTime('{y}-{m}-{d}') }}</span>
									</span>
								</td>
							</tr> -->
							<tr
								style="border:0px solid #ffffff; border-top:1px solid #333333;"
							>
								<td
									colspan="2"
									style="padding-left:0;padding-right:0;"
								>
									<span>
										<b>申请人:</b>
										{{ printDataObj.creatorName }}
										<b
											style="margin-right:20px;display:inline-block;"
										></b>
										<b>经办人:</b>
									</span>
								</td>
								<!-- 数量合计 -->
								<td
									colspan="1"
									style="padding-left:0;padding-right:0;"
								>
									<b>合计:</b>
									{{ numCount }}
								</td>
								<td
									colspan="1"
									style="padding-left:0;padding-right:0;"
								></td>
								<!-- 价格合计 -->
								<td
									colspan="1"
									style="padding-left:0;padding-right:0;"
								>
									<b>合计:</b>
									{{ priceCount }}
								</td>
								<td
									colspan="1"
									style="padding-left:0;padding-right:0;text-align:right;"
								>
									<span>
										<b>日期:</b>
										{{
											printDataObj.requestTime
												| parseTime('{y}-{m}-{d}')
										}}
									</span>
								</td>
							</tr>
						</tfoot>
					</table>
					<div class="h10"></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printData = false">取 消</el-button>
				<el-button v-print="printObj" type="primary">打印</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	purchaseApplicationDeleteService,
	purchaseApplicationListService,
	purchaseApplicationDetailService,
	purchaseApplicationChangeStateService,
	purchaseApplicationFukuanService,
	purchaseApplicationKaipiaoService,
	purchaseApplicationDBService,
	purchaseApplicationSellService,
	purchasebuyRequestCancelService
} from '@s/spareParts/PurchaseApplicationService';
// eslint-disable-next-line import/no-cycle
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import PurchaseApplicationAddForm from '@f/spareParts/purchaseApplication/PurchaseApplicationAdd.form';
import PurchaseApplicationSearchform from '@f/spareParts/purchaseApplication/PurchaseApplicationSearch.form';

export default {
	name: 'PurchaseApplication',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		PurchaseApplicationAddForm,
		PurchaseApplicationSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			isShowMemoDialog: false,
			state: '',
			textarea: '',
			rowId: '',
			isShowDetail: false,
			// 打印数据
			// 打印库房ID转中文
			warehouseData: [],
			printData: false,
			printDataList: [],
			printDataObj: {},
			printObj: {
				id: 'printData',
				popTitle: '',
				popFooter: ''
			}
		};
	},
	computed: {
		dialogTitle() {
			let title = '';
			if (this.editId === -1) {
				title = '新增采购申请';
			} else if (this.isShowDetail) {
				title = '采购申请详情';
			} else {
				title = '编辑采购申请';
			}
			return title;
		},
		numCount() {
			let num = 0;
			if (this.printDataList && this.printDataList.length > 0) {
				num = this.printDataList.reduce((p, c, i, a) => {
					return p + c.cnt;
				}, 0);
			}
			return num;
		},
		priceCount() {
			let num = 0;
			if (this.printDataList && this.printDataList.length > 0) {
				num = this.printDataList.reduce((p, c, i, a) => {
					return p + c.totalMoney;
				}, 0);
			}
			return num;
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.purchaseApplicationList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.purchaseApplicationList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.purchaseApplicationList();
			}
		}
	},
	mounted() {
		this.purchaseApplicationList();
		// 打印 库房反查
		this.warehouseonlymyself();
	},
	methods: {
		tableRowClassName({ row, rowIndex }) {
			if (rowIndex % 2 === 0) {
				return 'even-row';
			} else {
				return 'odd-row';
			}
		},
		async purchaseApplicationList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await purchaseApplicationListService(dataJson);
			res.records.forEach(item => {
				if (item.items.length > 3) {
					item.showMaterialLength = 3;
				} else {
					item.showMaterialLength = 99;
				}
			});

			this.listMixin(res);
		},

		showMoreMaterial(row) {
			const table = this.tableData;
			table.forEach(item => {
				if (item.id == row.id) {
					if (item.showMaterialLength == 3) {
						item.showMaterialLength = 99;
					} else {
						item.showMaterialLength = 3;
					}
				}
			});
			this.tableData = table;
		},
		showDialogAddHandler() {
			this.isShowDetail = false;
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			this.isShowDetail = false;
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await purchaseApplicationDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await purchaseApplicationDeleteService(dataJson);
			this.isRefreshList = true;
		},
		shouDetailHandler(row, isShowDetail) {
			this.editSingleHandler(row);
			this.isShowDetail = isShowDetail;
		},
		async editSingleHandler(row) {
			this.isShowDetail = false;
			const dataJson = {
				id: row.id
			};
			const res = await purchaseApplicationDetailService(dataJson);
			console.log('res编辑采购');
			console.log(res);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.purchaseApplicationList();
		},
		changeStateHandler(state, row) {
			this.state = state;
			this.rowId = row.id;
			if (state === 1 || state === 3) {
				this.changeStateSubmit();
			}
			if (state === 2) {
				this.isShowMemoDialog = true;
			}
		},
		async changeStateSubmit() {
			const dataJson = {
				id: this.rowId,
				memo: this.textarea,
				newState: this.state
			};
			await purchaseApplicationChangeStateService(dataJson);
			this.changeStateCancel();
			this.purchaseApplicationList();
		},
		changeStateCancel() {
			this.state = '';
			this.textarea = '';
			this.isShowMemoDialog = false;
		},
		// 设置开票
		async setKaipiao(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await purchaseApplicationKaipiaoService(data);
			this.purchaseApplicationList();
			this.$message.success('设置成功');
		},
		// 设置付款
		async setFukuan(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await purchaseApplicationFukuanService(data);
			this.purchaseApplicationList();
			this.$message.success('设置成功');
		},
		// 设置是否申请调拨
		async setDB(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await purchaseApplicationDBService(data);
			this.purchaseApplicationList();
			this.$message.success('设置成功');
		},
		// 设置是否申请销售
		async setSell(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await purchaseApplicationSellService(data);
			this.purchaseApplicationList();
			this.$message.success('设置成功');
		},
		// 获取仓库
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 打印
		async showPrintData(row) {
			this.printDataList = [];
			const dataJson = {
				id: row.id
			};
			const res = await purchaseApplicationDetailService(dataJson);
			if (res.items.length === 0) {
				this.$message.warning('没有数据！');
				return false;
			}
			res.items.forEach(item => {
				item.totalMoney = (item.cnt * 100 * (item.price * 100)) / 10000;
			});
			// 库房id反查
			this.warehouseData.forEach(item => {
				if (item.id === res.warehouseId) {
					res.warehouseName = item.name;
				}
			});
			this.printDataList = res.items;
			this.printDataObj = res;
			this.printData = true;
		},
		// 采购申请取消
		buyRequestCance(row) {
			const dataJson = {
				id: row.id
			};
			this.$confirm('确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await purchasebuyRequestCancelService(dataJson);
					if (res) {
						this.$message.success('操作成功');
					} else {
						this.$message.warning('请联系管理员');
					}
					this.purchaseApplicationList();
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.even-row {
}
/deep/.odd-row {
	background-color: rgba(64, 158, 255, 0.2);
}
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* 表格初始化 bootstrap */
table {
	border-collapse: collapse;
	width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse !important;
}

.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}

.table-bordered th,
.table-bordered td {
	/* border: 1px solid #dee2e6 !important; */
}

.table-dark {
	color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #dee2e6;
}

.table .thead-dark th {
	color: inherit;
	border-color: #dee2e6;
}

.print-data {
	/* border: 1px solid #333; */
	max-height: 600px;
	overflow-y: auto;
}

.print-data .print-row:last-child {
	border-bottom: none;
}

.tTitle th {
	text-align: left;
	font-size: 18px;
	line-height: 40rpx;
}

/* 表格打印 保留表头底部 */
.table thead {
	display: table-header-group;
}

.table tfoot {
	display: table-footer-group;
}

.table tbody {
	display: table-row-group;
}

#printData {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#myTable {
	width: 97%;
}

.h10 {
	width: 100%;
	height: 10px;
}

.tpHeadTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpHeadTr th {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}

.tpBodyTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpBodyTr td {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}
</style>
