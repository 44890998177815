import { hgetRequest, hpostRequest } from '../../util/htools.axios';
// 物料快速添加
// 生成编码
export const materialgenCodeService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest('hwms/goods/genCode', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 物料列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const materialListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				code: '',
				factory: '',
				goodsVer: '',
				manageType: '',
				spec: '',
				tags: '',
				typeIds: '',
				units: '',
				...data
			};
			if (dataJson.typeIds === '' || dataJson.typeIds === 0) {
				dataJson.typeIds = [];
			} else if (!(dataJson.typeIds instanceof Array)) {
				dataJson.typeIds = [dataJson.typeIds];
			}
			const res = await hpostRequest('hwms/goods/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 物料列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const materialAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goods/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料列表 采购销售 新增 物料选择列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const materialAllListService1 = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goods/all?enable=true', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 管理方式列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   managerStyle
 */
export const managerStyleListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goodsManageType/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   measureUnit
 */
export const measureUnitAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/units/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标签列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   tags
 */
export const tagsAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goods/tags', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const materialAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				code: '',
				name: '',
				units: '',
				manageType: '',
				typeId: '',
				tags: '',
				spec: '',
				goodsVer: '',
				factory: '',
				pic: '',
				...data
			};
			const res = await hpostRequest('hwms/goods/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const materialEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				code: '',
				name: '',
				units: '',
				manageType: '',
				typeId: '',
				tags: '',
				spec: '',
				goodsVer: '',
				factory: '',
				pic: '',
				...data
			};
			const res = await hpostRequest('hwms/goods/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const materialDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = [...data];
			const res = await hpostRequest('hwms/goods/deletes', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const materialDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/goods/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料 采购详情查询
 *@MethodAuthor:  zxh
 *@Date: 2023-07-11 14:40:48
 */
export const materialbuyRequestDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/detailsearch', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料 销售详情查询
 *@MethodAuthor:  zxh
 *@Date: 2023-07-11 14:40:48
 */
export const materialsellRequestDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/sellRequest/detailsearch', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料 设置采购推荐
 *@MethodAuthor:  zxh
 *@Date: 2023-07-11 14:40:48
 */
export const materialUpdatecommentbuyService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				buyprice: '',
				goodsid: '',
				supplierId: '',
				...data
			};
			const res = await hgetRequest('hwms/goods/updatecommentbuy', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料 设置销售推荐
 *@MethodAuthor:  zxh
 *@Date: 2023-07-11 14:40:48
 */
export const materialUpdatecommentsellService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				sellprice: '',
				goodsid: '',
				...data
			};
			const res = await hgetRequest('hwms/goods/updatecommentsell', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 是否启用
 *@MethodAuthor:  zxh
 *@Date: 2023-07-11 14:40:48
 */
export const materialsellChangeenableService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			console.log(dataJson);
			const res = await hpostRequest(`hwms/goods/changeenable?state=${data.state}`, [
				data.id
			]);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default materialListService;
