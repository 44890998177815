<template>
	<div class="head-serch">
		<el-form class="form" ref="empSummarySearchForm" :model="empSummarySearchForm" label-width="100px"
			@submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="日期">
				<el-date-picker v-model="date" type="daterange" :picker-options="pickerOptions" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right"
					value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
			</el-form-item>
			<el-form-item label="医院">
				<el-select v-model="empSummarySearchForm.hosid" placeholder="请选择" class="select-item" clearable filterable>
					<el-option v-for="item in hospitalData" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="负责人">
				<!-- <el-select v-model="empSummarySearchForm.uname" placeholder="请选择" class="select-item" clearable filterable>
					<el-option v-for="item in inspectionUserData" :key="item.id" :label="item.nickName"
						:value="item.id"></el-option>
				</el-select> -->
				<el-input v-model="empSummarySearchForm.uname" placeholder="请输入负责人" :clearable="true"/>
			</el-form-item>
			<!-- <el-form-item label="企业" prop="name" v-if="currentUserOrganization === 0">
				<el-select v-model="empSummarySearchForm.organizationId" placeholder="请选择" class="select-item" clearable filterable>
					<el-option
						v-for="(enterprise, index) in enterpriseData"
						:key="index"
						:label="enterprise.organizationName"
						:value="enterprise.id"
					></el-option>
				</el-select>
			</el-form-item> -->
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">
			搜索
		</el-button>
		<el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downLoading"
			@click.enter="download">导出</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { userListService } from '@s/system/UserService';
import { hgetStorage, htimeFormat } from '@/util/htools.web';

export default {
	props: ['downLoading'],
	data() {
		return {
			empSummarySearchForm: {
				hosid: null,
				orgid: null,
				uname: null,
				begin: null,
				end: null
			},
			date: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
			hospitalData: [],
			inspectionUserData: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			currentUserOrganization: '',
			enterpriseData: []
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		this.contractList();
		// this.inspectionUserList();
		// this.enterpriseList();
		this.searchHandler();
	},
	computed: {
		startDate() {
			return this.date ? this.date[0] : '';
		},
		endDate() {
			return this.date ? this.date[1] : '';
		}
	},
	methods: {
		// async enterpriseList() {
		// 	const dataJson = {
		// 		pageNum: 1,
		// 		pageSize: 100000
		// 	};
		// 	const res = await enterpriseListService(dataJson);
		// 	this.enterpriseData = res.records;
		// },
		searchHandler() {
			this.empSummarySearchForm.begin = htimeFormat(
				this.startDate,
				'{y}-{m}-{d} {h}:{i}:{s}'
			);
			this.empSummarySearchForm.end = htimeFormat(this.endDate, '{y}-{m}-{d} {h}:{i}:{s}');
			this.$emit('searchForm', this.empSummarySearchForm);
		},
		download() {
			this.empSummarySearchForm.begin = htimeFormat(
				this.startDate,
				'{y}-{m}-{d} {h}:{i}:{s}'
			);
			this.empSummarySearchForm.end = htimeFormat(this.endDate, '{y}-{m}-{d} {h}:{i}:{s}');
			this.$emit('download', this.empSummarySearchForm);
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: this.empSummarySearchForm.hospitalId,
				pageNum: 1,
				pageSize: 10000
			};
			// const res = await inspectionUserListService(dataJson);
			const res = await userListService(dataJson);
			console.log(res);
			// this.inspectionUserData = [...res];
			this.inspectionUserData = [...res.records];
		}
	}
};
</script>

<style lang="less" scoped>
.form {
	display: flex;
}
</style>
