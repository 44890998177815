<template>
	<el-form ref="formEle" :model="formData" label-width="100px" :rules="formRules">
		<el-form-item label="医院名称">
			<el-input :value="hospital_p.name" disabled />
		</el-form-item>
		<el-form-item label="工程师" prop="engineer">
			<el-select v-model="formData.engineer" placeholder="请选择工程师" clearable multiple filterable style="width: 100%">
				<el-option v-for="item in engineerData" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="contractFormCancel">取 消</el-button>
					<el-button type="primary" @click="contractFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
import { userListService } from '@s/system/UserService';
import { contractAddEngineerService, contractHospitalEngineerService } from '@s/company/contract/ContractDetailService';

export default {
	props: ['hospital_p'],
	data() {
		return {
			formData: {
				engineer: []
			},
			formRules: {
				engineer: [
					{
						required: true,
						message: '请选择工程师',
						trigger: 'change'
					}
				]
			},
			engineerData: []
		};
	},
	created() {
		this.engineerList();
		this.contractHospitalEngineer(this.hospital_p.id);
	},
	methods: {
		async engineerList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: 1000000000,
				userName: '',
				state: 0,
				...this.searchForm
			};
			const res = await userListService(dataJson);
			this.engineerData = [...res.records];
		},
		contractFormSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					const dataJson = {
						hospitalId: this.hospital_p.id,
						userId: this.formData.engineer
					};
					await contractAddEngineerService(dataJson);
					this.$emit('update:isRefreshList_p', true);
					this.contractFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		async contractHospitalEngineer(hospitalId) {
			const dataJson = {
				hospitalId: hospitalId
			};
			const res = await contractHospitalEngineerService(dataJson);
			this.formData.engineer = res.map(item => {
				return item.userId;
			});
		},
		contractFormCancel() {
			this.$emit('update:isShow_p', false);
		}
	}
};
</script>

<style>
</style>
