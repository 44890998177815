<template>
	<div>
		<AllHospitalDevearning
			:queryP="{ type: 'dept' }"
		></AllHospitalDevearning>
	</div>
</template>

<script>
import AllHospitalDevearning from './components/AllHospitalDevearning';

export default {
	components: {
		AllHospitalDevearning
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {}
};
</script>

<style lang="less" scoped>
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
