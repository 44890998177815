<template>
	<div class="app-container">
		<ParameterSearchForm @searchForm="searchFormHandler"></ParameterSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'parameter'"
		></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="状态" width="150">
				<template slot-scope="scope">
					{{ scope.row.isDel === 0 ? '正常' : '禁用' }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="200">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'parameter', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'parameter', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<ParameterAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></ParameterAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { parameterListService, parameterDeleteService } from '@s/base/ParameterService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ParameterAddForm from '@f/base/parameter/ParameterAdd.form';
import ParameterSearchForm from '@f/base/parameter/ParameterSearch.form';
import ListMixin from '@m/List.mixin';

export default {
	name: 'Parameter',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		ParameterAddForm,
		ParameterSearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'name'
				},
				{
					label: '字段名称',
					field: 'parameterKey'
				},
				{
					label: '字段值',
					field: 'parameterValue',
					width: '150'
				}
			],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增参数' : '编辑参数';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.parameterList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.parameterList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.parameterList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.parameterList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.parameterList();
	},
	methods: {
		async parameterList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await parameterListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			// const editId = this.dialogEditHandlerMixin();
			// const dataJson = {
			// 	parameterId: editId
			// };
			// const res = await parameterDetailService(dataJson);
			// this.selectData = [res];
			this.editId = this.dialogEditHandlerMixin();
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				parameterId: ids
			};
			await parameterDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			// const dataJson = {
			// 	parameterId: row.id
			// };
			// const res = await parameterDetailService(dataJson);
			// this.editSingleHandlerMixin(res);
			this.editId = row.id;
			this.isShowAEDialog = true;
		},
		deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						parameterId: ids
					};
					await parameterDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.parameterList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped></style>
