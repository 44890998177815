<template>
	<el-form ref="factoryForm" :model="factoryForm" :rules="factoryRules" size="small" label-width="130px">
		<el-form-item label="厂家" prop="name">
			<el-input v-model="factoryForm.name" placeholder="请输入厂家" />
		</el-form-item>
		<el-form-item label="厂家电话" prop="phone">
			<el-input type="number" v-model="factoryForm.phone" placeholder="请输入厂家电话" />
		</el-form-item>
		<el-form-item label="联系人名称" prop="contactName">
			<el-input v-model="factoryForm.contactName" placeholder="请输入联系人名称" />
		</el-form-item>
		<el-form-item label="联系人电话" prop="contactNamePhone">
			<el-input type="number" v-model="factoryForm.contactNamePhone" placeholder="请输入联系人电话" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="factoryFormCancel">取 消</el-button>
					<el-button type="primary" @click="factoryFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { factoryAddService, factoryEditService } from '@s/hospital/factory/FactoryService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			factoryForm: {
				description: 36,
				name: '',
				isDel: 0
			},
			factoryRules: {
				name: [
					{
						required: true,
						message: '请输入厂家',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '请输入厂家电话',
						trigger: 'blur'
					}
				],
				contactName: [
					{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}
				],
				contactNamePhone: [
					{
						required: true,
						message: '请输入联系人电话',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.factoryForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		
	},
	mounted() {
	},
	methods: {
		factoryFormSubmit() {
			this.$refs.factoryForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await factoryAddService(this.factoryForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.factoryForm
						};
						await factoryEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.factoryFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		factoryFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
