<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="88px">
				<el-form-item label="名称" prop="name">
					<el-input v-model="formData.name" clearable placeholder="请输入权限名称" />
				</el-form-item>
				<el-form-item label="父级">
					<SelectTree v-model="formData.pid" :data="materialTypeData" node-key="id" :isLastLeaf="false"></SelectTree>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { materialTypeAddService, materialTypeEditService, materialTypeListService } from '@s/spareParts/MaterialTypeService';
import SelectTree from '@c/ui/SelectTree';
import { deleteTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p'],
	components: {
		SelectTree
	},
	data() {
		return {
			editId: -1,
			formData: {
				name: '',
				pid: 0
			},
			formRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				pid: [
					{
						required: true,
						message: '请选择父级',
						trigger: 'change'
					}
				]
			},
			materialTypeData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					await this.materialTypeList();
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId === -1 && this.materialTypeList();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.name;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async materialTypeList() {
			const dataJson = {};
			const res = await materialTypeListService(dataJson);
			this.addTreeKey(res, 0);
			if (this.editId === -1) {
				this.materialTypeData = res;
			} else {
				this.materialTypeData = deleteTreeNodeById(res, this.editId);
			}
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await materialTypeAddService(this.formData);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await materialTypeEditService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
