<template>
	<div>
		<div v-for="item in materialTypeDataP" :key="item.value">
			<p style="display:flex; width:100%;">
				<i
					v-if="item.children && item.children.length > 0"
					:class="[
						item.isOpen
							? 'el-icon-caret-bottom isOpenIcon'
							: 'el-icon-caret-right isOpenIcon'
					]"
					@click="openMaterial(item)"
				></i>
				<i class="isOpenIcon" v-else></i>

				<el-option
					style="flex:1;"
					:label="item.name"
					:value="item.value"
					class="materialSelectItem"
				></el-option>
			</p>
			<p style="margin-left:20px;" v-if="item.isOpen">
				<select-chird :materialTypeDataP="item.children"></select-chird>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'select-chird', // 自身组件

	props: ['materialTypeDataP', 'queryInfoGoodP'],
	components: {},

	data() {
		return {
			// 新的物料添加
			isShowGoodDialog: false,
			tableDataGood: [],
			queryInfoGood: {
				pageNum: 1,
				pageSize: 10,
				enable: true
			},
			totalGood: 0,
			dataArrGood: [],
			formData: {}
		};
	},

	watch: {},

	computed: {},

	mounted() {
	},

	methods: {
		openMaterial(row) {
			this.materialTypeDataP.forEach((item, index, arr) => {
				if (item.id == row.id) {
					if (item.isOpen) {
						this.$set(arr, index, { ...item, isOpen: false });
					} else {
						this.$set(arr, index, { ...item, isOpen: true });
					}
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.isOpenIcon {
	width: 40px;
	height: 34px;
	line-height: 1em;
	// background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
/deep/.materialSelectItem {
	padding-left: 0 !important;
}
</style>
