<template>
	<el-row>
		<el-col :span="24">
			<el-form
				ref="userForm"
				:model="userForm"
				:rules="userRules"
				size="small"
				label-width="120px"
			>
				<el-form-item>
					<div class="el-form-item__error">
						<b>注</b>
						:为避免重名，建议使用手机号码作为用户名！
					</div>
				</el-form-item>
				<el-form-item label="用户名" prop="userName">
					<el-input v-model="userForm.userName" placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="userPwd" v-if="editId === -1">
					<el-input type="password" v-model="userForm.userPwd" placeholder="请输入密码" />
				</el-form-item>
				<el-form-item label="昵称" prop="nickName">
					<el-input v-model="userForm.nickName" placeholder="请输入昵称" />
				</el-form-item>
				<el-form-item label="手机号" prop="telphone">
					<el-input
						type="Number"
						v-model="userForm.telphone"
						placeholder="请输入手机号"
					/>
				</el-form-item>

				<el-form-item label="部门" prop="deptId">
					<!-- <SelectTree v-model="userForm.deptId" :data="optionsDept" node-key="id" :isLastLeaf="true"></SelectTree> -->

					<treeselect
						v-model="userForm.deptId"
						:multiple="false"
						:options="optionsDept"
						style="width:200px;"
						:disable-branch-nodes="false"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
					/>
				</el-form-item>
				<template v-if="userForm.roleList !== null">
					<el-form-item label="角色" prop="roleList">
						<el-select
							ref="multiSelect"
							v-model="userForm.roleList"
							multiple
							placeholder="请选择角色"
							class="select-item"
							filterable
							clearable
						>
							<el-option
								v-for="(role, index) in roleData"
								:key="index"
								:label="role.roleName"
								:value="role.id"
							></el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item label="状态" prop="state">
					<el-radio-group style="width: 200px" v-model="userForm.state">
						<el-radio :label="0">启用</el-radio>
						<el-radio :label="1">不启用</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="备注">
					<el-input type="textarea" :rows="4" v-model="userForm.remark"></el-input>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="userFormCancel">取 消</el-button>
				<el-button type="primary" @click="userFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { userAddService, userEditService, userRoleService } from '@s/system/UserService';
// eslint-disable-next-line import/no-cycle
import { roleListService } from '@s/system/RoleService';

import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';

export default {
	props: ['selectData_p', 'organizationId_p', 'editId_p'],
	components: {
		// SelectTree,
		Treeselect
	},
	data() {
		return {
			editId: -1,
			// 添加用户 部门下拉
			optionsDept: [],
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			// 弹窗
			userForm: {
				userName: '',
				userPwd: '',
				nickName: '',
				state: 0,
				organizationId: '',
				lookArea: [],
				remark: '',
				roleList: [],
				deptId: null
			},
			areaData: [
				{
					id: 0,
					name: '无权限查看'
				},
				{
					id: 1,
					name: '贵溪市'
				},
				{
					id: 2,
					name: '余江区'
				},
				{
					id: 3,
					name: '高新区'
				}
			],
			userRules: {
				userName: [
					{ required: true, message: '请输入用户名', trigger: 'blur' },
					{ min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
				],
				userPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				nickName: [{ required: true, message: '请输入用户昵称', trigger: 'blur' }],
				telphone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
				// deptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
				state: [{ required: true, message: '请选择状态', trigger: 'change' }],

				roleList: [
					{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}
				],
				organizationId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}
				],
				lookArea: [
					{
						required: true,
						message: '请选择大屏查看区域',
						trigger: 'change'
					}
				]
			},
			roleData: [],
			enterpriseData: [],
			orgDisabled: false,
			currentUserOrganization: null
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					await this.departmentList();
					this.roleData.length === 0 && (await this.roleList());
					this.editId = newValue[0].id;
					// newValue[0].organizationId = newValue[0].organization === null ? 0 : newValue[0].organization.id;
					this.userForm = { ...newValue[0] };
					this.$set(this.userForm, 'roleList', newValue[0].userRoles);
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		if (this.editId === -1) {
			this.roleList();
		}
		this.departmentList();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		async enterpriseList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await enterpriseListService(dataJson);
			this.enterpriseData = res.records;
		},
		async roleList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				userName: ''
			};
			const res = await roleListService(dataJson);
			this.roleData = res.records;
		},
		changeRoleHandler(e) {
			if (e.length === this.roleData.length) {
				setTimeout(() => {
					this.$refs.multiSelect.blur();
				}, 50);
			}
		},
		changeHandle(e) {
			if (e.includes(0)) {
				this.userForm.lookArea = [0];
				this.areaData.forEach((item, index) => {
					item.disabled = true;
					if (index === 0) {
						item.disabled = false;
					}
				});
			} else {
				this.areaData.forEach(item => {
					item.disabled = false;
				});
			}
		},
		userFormSubmit() {
			this.$refs.userForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await userAddService(this.userForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.userForm
						};
						await userEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
						const dataJson_c = {
							roleList: this.userForm.roleList,
							id: this.editId
						};
						await userRoleService(dataJson_c);
					}
					this.userFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		userFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped></style>
