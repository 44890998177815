<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="elForm" :model="elForm" :rules="elFormRules" label-width="100px">
				<!-- <el-form-item label="等级名称" prop="name">
					<el-input v-model="elForm.name" placeholder="请输入等级名称" />
				</el-form-item>
				<el-form-item label="月人工费用" prop="monthprice">
					<el-input
						v-model="elForm.monthprice"
						type="number"
						placeholder="请输入月人工费用"
					/>
				</el-form-item> -->
				<el-form-item label="科室" prop="departid">
					<treeselect
						v-model="elForm.departid"
						:multiple="false"
						:options="optionsDept"
						style="width:200px;"
						:disable-branch-nodes="false"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
						@close="againGetDevList"
					/>
				</el-form-item>
				<el-form-item label="设备名称" prop="devid">
					<el-select
						v-model="elForm.devid"
						placeholder="请选择"
						class="select-item"
						clearable
						filterable
					>
						<el-option
							v-for="(dev, index) in devListData"
							:key="index"
							:label="dev.devInfoName"
							:value="dev.devInfoId"
						></el-option>
					</el-select>
				</el-form-item>

				<!-- <el-form-item label="科室" prop="departname">
					<el-input v-model="elForm.departname" disabled placeholder="请输入科室" />
				</el-form-item>
				<el-form-item label="设备名称" prop="devname">
					<el-input v-model="elForm.devname" disabled placeholder="请输入设备名称" />
				</el-form-item> -->
				<el-form-item label="执行日期" prop="actionTime">
					<el-date-picker
						v-model="elForm.actionTime"
						type="datetime"
						format="yyyy-MM-dd HH:mm:ss"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="操作人" prop="operator">
					<el-input v-model="elForm.operator" placeholder="请选择操作人" />
				</el-form-item>
				<el-form-item label="价格" prop="price">
					<el-input v-model="elForm.price" type="number" placeholder="请输入价格" />
				</el-form-item>
				<el-form-item label="所作项目" prop="title">
					<el-input v-model="elForm.title" placeholder="请输入所作项目" />
				</el-form-item>
				<el-form-item label="所作操作">
					<el-input type="textarea" v-model="elForm.body"></el-input>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right;margin-top: 20px;">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import { devBaseListService } from '@s/hospital/devBase/DevBaseService';
import { addService, editService, detailService } from '@s/hospital/devBase/DevearningService';
// eslint-disable-next-line import/no-cycle
import Treeselect from '@riophae/vue-treeselect';

export default {
	props: ['selectData_p', 'editId_p', 'elForm_P'],
	components: {
		Treeselect
	},
	data() {
		return {
			editId: -1,
			elForm: {},
			elFormRules: {
				departid: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'change'
					}
				],
				devid: [
					{
						required: true,
						message: '请选择设备名称',
						trigger: 'change'
					}
				],
				actionTime: [
					{
						required: true,
						message: '请选择执行日期',
						trigger: 'change'
					}
				],
				operator: [
					{
						required: true,
						message: '请输入操作人',
						trigger: 'blur'
					}
				],
				price: [
					{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}
				],
				title: [
					{
						required: true,
						message: '请输入所作项目',
						trigger: 'blur'
					}
				],
				body: [
					{
						required: true,
						message: '请输入所作操作',
						trigger: 'blur'
					}
				]
			},
			devListData: [],
			optionsDept: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				await this.departmentList();
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					// console.log(this.parentName, 'this.parentNname');
					this.elForm = { ...newValue[0] };
					console.log(this.elForm, 'this.elForm');
				}
				await this.devList();
			},
			deep: true,
			immediate: true
		},
		'elForm.departid': {
			async handler(newValue) {
				if (newValue == undefined) {
					this.elForm.devid = null;
					this.devListData = [];
				}
			},
			deep: true
		}
	},
	created() {
		this.editId = this.editId_p;
		this.editId_p && (this.elForm = { ...this.elForm, ...this.elForm_P });
		// if (this.editId !== -1) {
		// 	this.getDetail();
		// }
	},
	mounted() {},
	methods: {
		async getDetail() {
			const dataJson = {
				id: this.editId
			};
			const res = await detailService(dataJson);
			this.elForm = { ...res };
			this.$emit('powers', res.powers);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await addService(this.elForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.elForm
						};
						await editService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 科室下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
			console.log(this.optionsDept);
		},
		async againGetDevList() {
			setTimeout(async () => {
				this.elForm.devid = null;
				this.devListData = [];
				await this.devList();
			}, 10);
		},
		async devList() {
			if (!this.elForm.departid) {
				return;
			}
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				did: this.elForm.departid
			};
			const res = await devBaseListService(dataJson);
			this.devListData = res.records;
			console.log(this.devListData);
		}
	}
};
</script>

<style></style>
