<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<SaleApplicationSearchform
				@searchForm="searchFormHandler"
			></SaleApplicationSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'saleApplication'"
				></Button>
			</div>
		</div>

		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			:row-class-name="tableRowClassName"
		>
			<el-table-column label="序号" width="140">
				<template slot-scope="scope">
					<p style="font-size: 14px;font-weight:bold;">
						{{ scope.row.id }}
					</p>
					<p style="font-size: 12px">
						创建:{{
							scope.row.createDate | parseTime('{y}-{m}-{d}')
						}}
					</p>
					<p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
						更新:{{
							scope.row.lastUpdateDate | parseTime('{y}-{m}-{d}')
						}}
					</p>
				</template>
			</el-table-column>
			<el-table-column label="申请单号" width="180">
				<template slot-scope="scope">
					<p style="font-size: 14px">{{ scope.row.code }}</p>
					<p style="font-size: 12px">
						申请:{{ scope.row.requestTime | parseTime }}
					</p>
					<p style="font-size: 12px">
						申请人:{{ scope.row.creatorName }}
					</p>
				</template>
			</el-table-column>

			<el-table-column label="库房" width="200">
				<template slot-scope="scope">
					<p style="font-size: 14px">
						<b>{{ scope.row.warehouseName }}</b>
					</p>
				</template>
			</el-table-column>
			<el-table-column label="销售数量" width="100">
				<template slot-scope="scope">
					<p style="font-size: 14px">
						<span
							v-if="scope.row.doneCnt == scope.row.sumCnt"
							style="color:#67C23A; cursor:pointer;"
						>
							<span title="已出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="物料总数">{{ scope.row.sumCnt }}</span>
						</span>
						<span v-else style="color:#E6A23C;cursor:pointer;">
							<span title="已出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="物料总数">{{ scope.row.sumCnt }}</span>
						</span>
					</p>
					<p style="font-size: 12px">金额:{{ scope.row.sumPrice }}</p>
				</template>
			</el-table-column>
			<el-table-column label="相关物料" min-width="350">
				<template slot-scope="scope">
					<p
						@click="showMoreMaterial(scope.row)"
						v-if="scope.row.items.length > 3"
					>
						<el-link type="primary">更多</el-link>
					</p>
					<p
						style="font-size:12px; "
						v-for="(item1, index1) in scope.row.items"
						:key="item1.id"
					>
						<template v-if="index1 < scope.row.showMaterialLength">
							<b>{{ index1 + 1 }}</b>
							、{{ item1.goodsName }}：
							<b>{{ item1.price }}</b>
							元 ×
							<b>{{ item1.cnt }}</b>
							个
						</template>
					</p>
				</template>
			</el-table-column>

			<!-- <el-table-column label="金额" width="80">
        <template slot-scope="scope">
          {{ scope.row.sumPrice }}
        </template>
      </el-table-column> -->

			<el-table-column label="状态记录" width="150">
				<template slot-scope="scope">
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否开票:
						</span>
						<el-switch
							v-model="scope.row.kaipiao"
							@change="setKaipiao($event, scope.row)"
						></el-switch>
					</p>
					<p style="font-size: 12px">
						<span
							style="width:.8rem; display:inline-block;text-align:right;"
						>
							是否付款:
						</span>
						<el-switch
							v-model="scope.row.fukuan"
							@change="setFukuan($event, scope.row)"
						></el-switch>
					</p>
				</template>
			</el-table-column>
			<!-- <el-table-column label="是否开票">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.kaipiao" @change="setKaipiao($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否付款">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.fukuan" @change="setFukuan($event, scope.row)"></el-switch>
        </template>
      </el-table-column> -->
			<el-table-column label="状态" width="130">
				<template slot-scope="scope">
					<span v-if="scope.row.state === 0">未审核</span>
					<span v-else-if="scope.row.state === 1">通过</span>
					<span
						v-else-if="scope.row.state === 2"
						style="color: #f56c6c"
					>
						不通过
					</span>
					<span v-else style="color: #67c23a">已执行</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="申请时间" width="160">
        <template slot-scope="scope">
          <p style="font-size: 14px">{{ scope.row.requestTime | parseTime }}</p>
          <p style="font-size: 12px">
            创建:{{ scope.row.createDate | parseTime('{y}-{m}-{d}') }}
          </p>
          <p v-if="scope.row.lastUpdateDate" style="font-size: 12px">
            更新:{{ scope.row.lastUpdateDate | parseTime('{y}-{m}-{d}') }}
          </p>
        </template>
      </el-table-column> -->
			<el-table-column
				label="操作"
				align="left"
				fixed="right"
				width="380"
			>
				<template slot-scope="scope">
					<!-- <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'saleApplication', id: 'btn-update-row' }" @click="shouDetailHandler(scope.row, true)" v-if="scope.row.state !== 0 && scope.row.state !== 1 && scope.row.state !== 2"> -->
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{
							name: 'update',
							type: 'saleApplication',
							id: 'btn-update-row'
						}"
						@click="shouDetailHandler(scope.row, true)"
						v-if="scope.row.state !== 0 && scope.row.state !== 2"
					>
						详情
					</el-button>
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{
							name: 'update',
							type: 'saleApplication',
							id: 'btn-update-row'
						}"
						@click="editSingleHandler(scope.row)"
						v-if="scope.row.state === 0 || scope.row.state === 2"
					>
						编辑
					</el-button>
					<el-button
						:disabled="scope.row.doneCnt > 0"
						:title="
							scope.row.doneCnt > 0
								? '已执行出库的销售申请不能删除'
								: ''
						"
						id="btn-remove-row"
						type="danger"
						v-authorize="{
							name: 'remove',
							type: 'saleApplication',
							id: 'btn-remove-row'
						}"
						@click="deleteSingleHandler(scope.row)"
						v-if="
							scope.row.state === 0 ||
								scope.row.state === 1 ||
								scope.row.state === 2 ||
								scope.row.state === 3
						"
					>
						删除
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{
							name: 'remove',
							type: 'saleApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(1, scope.row)"
						v-if="scope.row.state === 0"
					>
						通过
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{
							name: 'remove',
							type: 'saleApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(2, scope.row)"
						v-if="scope.row.state === 0"
					>
						拒绝
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{
							name: 'remove',
							type: 'saleApplication',
							id: 'btn-remove-row'
						}"
						@click="changeStateHandler(3, scope.row)"
						v-if="scope.row.state === 1"
					>
						执行销售
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{
							name: 'remove',
							type: 'saleApplication',
							id: 'btn-remove-row'
						}"
						@click="showupdateimgsDialog(scope.row)"
						v-if="
							scope.row.state !== 0 &&
								scope.row.state !== 1 &&
								scope.row.state !== 2
						"
					>
						上传单据
					</el-button>
					<div style="margin-top: 10px"></div>
					<!-- <el-button class="filter-item" type="primary" @click="showPrintData(scope.row)">打印</el-button> -->
					<el-dropdown
						@command="
							command => handlePrintType(command, scope.row)
						"
						style="margin-right:10px;"
					>
						<el-button type="primary">
							打印
							<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="A4">
								打印销售单(A4)
							</el-dropdown-item>
							<el-dropdown-item command="A5">
								打印出库单(4连200*90)
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-button
						type="danger"
						class="filter-item"
						id="btn-cancel-row"
						v-authorize="{
							name: 'cancel',
							type: 'saleApplication',
							id: 'btn-cancel-row'
						}"
						@click="saleRequestCance(scope.row)"
					>
						销售申请取消
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
			<SaleApplicationAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:isShowDetail_p="isShowDetail"
			></SaleApplicationAddForm>
		</Dialog>
		<Dialog title="备注" :visible.sync="isShowMemoDialog" width="40%">
			<el-input
				type="textarea"
				:rows="5"
				placeholder="请输入备注"
				v-model="textarea"
			></el-input>
			<div style="text-align: right; margin-top: 20px">
				<el-button @click="changeStateCancel">取消</el-button>
				<el-button type="primary" @click="changeStateSubmit">
					确定
				</el-button>
			</div>
		</Dialog>
		<Dialog
			title="单据上传"
			:visible.sync="isShowupdateimgsDialog"
			width="50%"
		>
			<el-form ref="formtoggleStopUsing" label-width="0px">
				<el-form-item label="">
					<Upload
						:action="uploadAction"
						list-type="picture-card"
						:file-list="extFile"
						:on-success="handlerExtSuccess"
						:on-remove="handlerExtRemove"
						:isShowTip_p="false"
					></Upload>
				</el-form-item>

				<el-form-item style="text-align:right;">
					<el-button type="primary" @click="onSubmitupdateimgsDialog">
						确认上传
					</el-button>
					<el-button @click="updateimgsDialogHide">取消</el-button>
				</el-form-item>
			</el-form>
		</Dialog>

		<el-dialog
			title="打印"
			:visible.sync="printData"
			width="40%"
			:close-on-click-modal="false"
			append-to-body
		>
			<div class="print-data">
				<div id="printData">
					<template v-if="printType === 'A4'">
						<table
							id="myTable"
							class="table myPrintTable"
							border="0"
						>
							<thead>
								<tr
									class="tTitle"
									style="border:0px solid #ffffff;"
								>
									<th
										colspan="6"
										style=" text-align: center;"
									>
										销售单
									</th>
								</tr>
								<tr
									style="border:0px solid #ffffff; border-bottom:1px solid #333333;"
								>
									<th
										colspan="6"
										style="text-align:left;padding-left:0;padding-right:0;"
									>
										<span
											style="display: flex;justify-content: space-between;"
										>
											<span>
												<b>库房:</b>
												<b style="font-weight:normal;">
													{{
														printDataObj.warehouseName
													}}
												</b>
												<b
													style="margin-right:20px;display:inline-block;"
												></b>
											</span>
											<span>
												<b>单号:</b>
												<b style="font-weight:normal;">
													{{ printDataObj.code }}
												</b>
											</span>
										</span>
									</th>
								</tr>
								<tr class="tpHeadTr">
									<th style="min-width:82px;">物料</th>
									<th style="min-width:82px;">科室</th>
									<th style="min-width:82px;">数量(个)</th>
									<th style="min-width:82px;">单价(元)</th>
									<th style="min-width:82px;">总价(元)</th>
									<th style="min-width:82px;">备注</th>
								</tr>
							</thead>
							<tbody>
								<tr
									class="tpBodyTr"
									v-for="(item, index) in [...printDataList]"
									:key="index"
								>
									<td>{{ item.goodsName }}</td>
									<td>{{ item.departname }}</td>
									<td>{{ Number(item.cnt) }}</td>
									<td>{{ Number(item.price) }}</td>
									<td>{{ Number(item.totalMoney) }}</td>
									<td>{{ item.memo }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr
									style="border:0px solid #ffffff; border-top:1px solid #333333;"
								>
									<td
										colspan="2"
										style="padding-left:0;padding-right:0;"
									>
										<span>
											<b>申请人:</b>
											{{ printDataObj.creatorName }}
											<b
												style="margin-right:20px;display:inline-block;"
											></b>
											<b>经办人:</b>
										</span>
									</td>
									<td
										colspan="1"
										style="padding-left:0;padding-right:0;"
									>
										<b>合计:</b>
										{{ numCount }}
									</td>
									<td
										colspan="1"
										style="padding-left:0;padding-right:0;"
									></td>
									<td
										colspan="1"
										style="padding-left:0;padding-right:0;"
									>
										<b>合计:</b>
										{{ priceCount }}
									</td>
									<td
										colspan="1"
										style="padding-left:0;padding-right:0;text-align:right;"
									>
										<span style="white-space: nowrap;">
											<b>日期:</b>
											{{
												printDataObj.requestTime
													| parseTime('{y}-{m}-{d}')
											}}
										</span>
									</td>
								</tr>
							</tfoot>
						</table>
						<div class="h10"></div>
					</template>
					<template v-else-if="printType === 'A5'">
						<table
							id="myTableA5"
							class="table myPrintTable"
							border="0"
						>
							<thead>
								<tr
									class="tTitle"
									style="border:0px solid #ffffff;"
								>
									<th
										colspan="7"
										style=" text-align: center;"
									>
										<input
											type="text"
											v-model="printDataObj.titleCompany"
											@input="changeWidth"
											:style="
												`width: ${printTitleWidth}px; text-align:center;border:${printTitleBorder};`
											"
											placeholder="输入公司"
										/>
									</th>
								</tr>
								<tr
									style="height:35px; border:0px solid #ffffff; border-bottom:1px solid #3333333;"
								>
									<th
										colspan="4"
										style="text-align:left; padding-left:0;"
									>
										购货单位:
										<input
											type="text"
											v-model="printDataObj.titleSupplier"
											@input="changeWidth1"
											:style="
												`width: ${printSupplierWidth}px;min-width:150px; text-align:left;border:${printSupplierBorder};z-index:0;`
											"
											placeholder=""
										/>
									</th>
									<th
										colspan="3"
										style="text-align:right; padding-right:0;"
									>
										单号:{{ printDataObj.code }}
									</th>
								</tr>
								<tr class="tpHeadTr noborder">
									<th style="min-width:82px; ">物料</th>
									<th style="min-width:52px;">规格</th>
									<th style="min-width:52px;">数量</th>
									<th style="min-width:52px;">单价(元)</th>
									<th style="min-width:52px;">总价(元)</th>
									<th style="min-width:82px;">厂家</th>
									<th style="min-width:82px;">备注</th>
								</tr>
							</thead>
							<tbody>
								<tr
									class="tpBodyTr noborder"
									v-for="(item, index) in [...printDataList]"
									:key="index"
								>
									<td>{{ item.goodsName }}</td>
									<td>{{ item.goodsPO.spec }}</td>
									<td>{{ Number(item.cnt) }}</td>
									<td>{{ Number(item.price) }}</td>
									<td>{{ Number(item.totalMoney) }}</td>
									<td>{{ item.goodsPO.factory }}</td>
									<td>{{ item.memo }}</td>
								</tr>
								<tr class="tpBodyTr noborder">
									<td rowspan="2">合计</td>
									<td></td>
									<td>{{ numCount }}</td>
									<td></td>
									<td>{{ priceCount }}</td>
									<td></td>
									<td></td>
								</tr>
								<tr class="tpBodyTr noborder">
									<td colspan="6">{{ numCountBig }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr style="border:0px solid #ffffff;">
									<td
										colspan="5"
										style="padding-left: text-align:left;"
									>
										<span>
											<b>出库人:</b>
											{{ printDataObj.creatorName }}
											<b
												style="margin-right:20px;display:inline-block;"
											></b>
											<b>收货人:</b>
										</span>
									</td>
									<td
										colspan="2"
										style="padding-left:0;padding-right:0;text-align:right;"
									>
										<span style="white-space: nowrap;">
											<b>日期:</b>
											{{
												printDataObj.requestTime
													| parseTime('{y}-{m}-{d}')
											}}
										</span>
									</td>
								</tr>
							</tfoot>
						</table>
						<div class="h10"></div>
					</template>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printData = false">取 消</el-button>
				<el-button v-print="printObj" type="primary">打印</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import {
	saleApplicationDeleteService,
	saleApplicationListService,
	saleApplicationDetailService,
	saleApplicationChangeStateService,
	saleApplicationFukuanService,
	saleApplicationKaipiaoService,
	saleupdateimgsService,
	saleRequestCancelService
} from '@s/spareParts/SaleApplicationService';
// eslint-disable-next-line import/no-cycle
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import Upload from '@c/ui/Upload.vue';
import ListMixin from '@m/List.mixin';
import SaleApplicationAddForm from '@f/spareParts/saleApplication/SaleApplicationAdd.form';
import SaleApplicationSearchform from '@f/spareParts/saleApplication/SaleApplicationSearch.form';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'SaleApplication',
	mixins: [ListMixin],
	components: {
		Upload,
		Button,
		Table,
		Pagination,
		Dialog,
		SaleApplicationAddForm,
		SaleApplicationSearchform
	},
	data() {
		return {
			organizationName: null,
			// 上传图片
			uploadAction: '',
			isShowupdateimgsDialog: false,
			extFile: [],
			devExtImgUrlArr: [],
			imgs: null,
			formImgs: { imgs: null },
			// 表格
			tableColumn: [],
			delTips: '',
			isShowMemoDialog: false,
			state: '',
			textarea: '',
			rowId: '',
			isShowDetail: false,
			// 打印数据
			// 打印库房ID转中文
			warehouseData: [],
			// A4是默认的打印    A5是新的4纸连打
			printType: 'A4',
			printData: false,
			printDataList: [],
			printDataObj: {},
			printObj: {
				id: 'printData',
				popTitle: '',
				popFooter: '',
				closeCallback(vue) {
					console.log('closeCallback');
					console.log(vue);
					vue.printTitleBorder = 0;
					vue.printSupplierWidth = null;
					vue.printSupplierBorder = 0;
				}
			},
			printTitleWidth: 400,
			printTitleBorder: 0,
			printSupplierWidth: null,
			printSupplierBorder: 0,
			numCountBig: null
		};
	},
	computed: {
		dialogTitle() {
			let title = '';
			if (this.editId === -1) {
				title = '新增销售申请';
			} else if (this.isShowDetail) {
				title = '销售申请详情';
			} else {
				title = '编辑销售申请';
			}
			return title;
		},
		numCount() {
			let num = 0;
			if (this.printDataList && this.printDataList.length > 0) {
				num = this.printDataList.reduce((p, c, i, a) => {
					return p + c.cnt;
				}, 0);
			}
			return num;
		},
		priceCount() {
			let num = 0;
			if (this.printDataList && this.printDataList.length > 0) {
				num = this.printDataList.reduce((p, c, i, a) => {
					return p + c.totalMoney;
				}, 0);
			}
			return num;
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.saleApplicationList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.saleApplicationList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.saleApplicationList();
			}
		}
	},
	mounted() {
		this.saleApplicationList();
		// 打印 库房反查
		this.warehouseonlymyself();
		this.organizationName = hgetStorage('organizationName');
		setTimeout(() => {
			this.uploadAction = `${this.$envConfig.imgHttpWms}img/upload`;
		}, 200);
	},
	methods: {
		tableRowClassName({ row, rowIndex }) {
			if (rowIndex % 2 === 0) {
				return 'even-row';
			} else {
				return 'odd-row';
			}
		},
		// 上传单据
		showupdateimgsDialog(row) {
			this.devExtImgUrlArr = [];
			this.extFile = [];

			this.getDetailById(row);
			this.isShowupdateimgsDialog = true;
		},
		async getDetailById(row) {
			const dataJson = {
				id: row.id
			};
			const res = await saleApplicationDetailService(dataJson);
			this.formImgs = { ...res };

			this.devExtImgUrlArr = [];
			this.extFile = [];

			this.formImgs.imgs &&
				(this.devExtImgUrlArr = this.formImgs.imgs.split(','));
			this.formImgs.imgs &&
				this.devExtImgUrlArr.forEach((img, index) => {
					this.extFile.push({
						name: `图片${index}`,
						path: `${img}`,
						url: `${this.$envConfig.imgHttpAdd}wms/sellrequest/${img}`
					});
				});
		},

		handlerExtSuccess(response) {
			this.devExtImgUrlArr.push(response.result.path);
			this.formImgs.imgs = this.devExtImgUrlArr.join();
		},
		handlerExtRemove(file, fileList) {
			this.devExtImgUrlArr = [];
			fileList.forEach(item => {
				this.devExtImgUrlArr.push(
					item.path || item.response.result.path
				);
			});
			this.formImgs.imgs = this.devExtImgUrlArr.join();
		},
		async onSubmitupdateimgsDialog() {
			const dataJson = {
				id: this.formImgs.id,
				imgs: this.formImgs.imgs
			};
			const res = await saleupdateimgsService(dataJson);
			if (res) {
				this.$message({
					type: 'success',
					message: '上传单据成功!'
				});
			}
			this.devExtImgUrlArr = [];
			this.extFile = [];
			this.isShowupdateimgsDialog = false;
		},
		updateimgsDialogHide() {
			this.devExtImgUrlArr = [];
			this.extFile = [];
			this.isShowupdateimgsDialog = false;
		},

		async saleApplicationList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await saleApplicationListService(dataJson);
			res.records.forEach(item => {
				if (item.items.length > 3) {
					item.showMaterialLength = 3;
				} else {
					item.showMaterialLength = 99;
				}
			});
			this.listMixin(res);
		},
		showMoreMaterial(row) {
			const table = this.tableData;
			table.forEach(item => {
				if (item.id == row.id) {
					if (item.showMaterialLength == 3) {
						item.showMaterialLength = 99;
					} else {
						item.showMaterialLength = 3;
					}
				}
			});
			this.tableData = table;
		},
		showDialogAddHandler() {
			this.isShowDetail = false;
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			this.isShowDetail = false;
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await saleApplicationDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await saleApplicationDeleteService(dataJson);
			this.isRefreshList = true;
		},
		shouDetailHandler(row, isShowDetail) {
			this.editSingleHandler(row);
			this.isShowDetail = isShowDetail;
		},
		async editSingleHandler(row) {
			this.isShowDetail = false;
			const dataJson = {
				id: row.id
			};
			const res = await saleApplicationDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.saleApplicationList();
		},
		changeStateHandler(state, row) {
			this.state = state;
			this.rowId = row.id;
			if (state === 1 || state === 3) {
				this.changeStateSubmit();
			}
			if (state === 2) {
				this.isShowMemoDialog = true;
			}
		},
		async changeStateSubmit() {
			const dataJson = {
				id: this.rowId,
				memo: this.textarea,
				newState: this.state
			};
			await saleApplicationChangeStateService(dataJson);
			this.changeStateCancel();
			this.saleApplicationList();
		},
		changeStateCancel() {
			this.state = '';
			this.textarea = '';
			this.isShowMemoDialog = false;
		},
		// 设置开票
		async setKaipiao(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await saleApplicationKaipiaoService(data);
			this.saleApplicationList();
			this.$message.success('设置成功');
		},
		// 设置付款
		async setFukuan(e, item) {
			const data = {
				state: e,
				id: item.id
			};
			await saleApplicationFukuanService(data);
			this.saleApplicationList();
			this.$message.success('设置成功');
		},
		// 获取仓库
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 打印
		// async showPrintData() {
		// },
		async handlePrintType(command, row) {
			this.printDataList = [];
			const dataJson = {
				id: row.id
			};
			const res = await saleApplicationDetailService(dataJson);
			if (res.items.length === 0) {
				this.$message.warning('没有数据！');
				return false;
			}
			res.items.forEach(item => {
				item.totalMoney = (item.cnt * 100 * (item.price * 100)) / 10000;
			});
			// 库房id反查
			this.warehouseData.forEach(item => {
				if (item.id === res.warehouseId) {
					res.warehouseName = item.name;
				}
			});
			this.printDataList = res.items;
			this.printDataObj = res;

			this.$set(
				this.printDataObj,
				'titleCompany',
				`${this.organizationName}出库单`
			);
			this.printDataObj.titleSupplier = this.printDataObj.warehouseName;
			this.printType = command;
			this.numCountBig = this.setBig(this.priceCount);
			this.printData = true;
			// setTimeout(() => {
			// 	this.changeWidth();
			// 	this.changeWidth1();
			// }, 1000);
		},
		changeWidth() {
			this.printTitleWidth =
				this.printDataObj.titleCompany.length * 18 + 20;
			this.printTitleBorder = 0;
		},
		changeWidth1() {
			this.printSupplierWidth =
				this.printDataObj.titleSupplier.length * 14 + 30;
			this.printSupplierBorder = 0;
		},
		// 销售申请取消
		saleRequestCance(row) {
			const dataJson = {
				id: row.id
			};
			this.$confirm('确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await saleRequestCancelService(dataJson);
					if (res) {
						this.$message.success('操作成功');
					} else {
						this.$message.warning('请联系管理员');
					}
					this.saleApplicationList();
				})
				.catch(error => {
					console.log(error);
				});
		},

		setBig(n) {
			if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
				return '数据非法'; // 判断数据是否大于0
			}
			let unit = '千百拾亿千百拾万千百拾元角分';
			let str = '';
			n += '00';
			const indexpoint = n.indexOf('.'); // 如果是小数，截取小数点前面的位数
			if (indexpoint >= 0) {
				n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2); // 若为小数，截取需要使用的unit单位
			}
			unit = unit.substr(unit.length - n.length); // 若为整数，截取需要使用的unit单位
			for (let i = 0; i < n.length; i++) {
				str +=
					'零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i); // 遍历转化为大写的数字
			}

			return str
				.replace(/零(千|百|拾|角)/g, '零')
				.replace(/(零)+/g, '零')
				.replace(/零(万|亿|元)/g, '$1')
				.replace(/(亿)万|壹(拾)/g, '$1$2')
				.replace(/^元零?|零分/g, '')
				.replace(/元$/g, '元整'); // 替换掉数字里面的零字符，得到结果
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.even-row {
}
/deep/.odd-row {
	background-color: rgba(64, 158, 255, 0.2);
}
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* 表格初始化 bootstrap */
table {
	border-collapse: collapse;
	width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse !important;
}

.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}

.table-bordered th,
.table-bordered td {
	/* border: 1px solid #dee2e6 !important; */
}

.table-dark {
	color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #dee2e6;
}

.table .thead-dark th {
	color: inherit;
	border-color: #dee2e6;
}

.print-data {
	/* border: 1px solid #333; */
	max-height: 600px;
	overflow-y: auto;
}

.print-data .print-row:last-child {
	border-bottom: none;
}

.tTitle th {
	text-align: left;
	font-size: 18px;
	line-height: 40rpx;
}

/* 表格打印 保留表头底部 */
.table thead {
	display: table-header-group;
}

.table tfoot {
	display: table-footer-group;
}

.table tbody {
	display: table-row-group;
}

#printData {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.myPrintTable {
	width: 97%;
}

.h10 {
	width: 100%;
	height: 10px;
}

.tpHeadTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpHeadTr th {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}

.tpBodyTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpBodyTr td {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}

// A5打印 表格颜色
// .noborder {
// 	border-left: 1px solid #ffffff;
// 	border-top: 1px solid #ffffff;
// }

// .noborder th {
// 	border-right: 1px solid #ffffff !important;
// 	border-bottom: 1px solid #ffffff !important;
// }

// .noborder {
// 	border-left: 1px solid #ffffff;
// 	border-top: 1px solid #ffffff;
// }

// .noborder td {
// 	border-right: 1px solid #ffffff !important;
// 	border-bottom: 1px solid #ffffff !important;
// }

// .noborder th,
// .noborder tr,
// .noborder td{border:0 solid #ffffff !important;}
</style>
