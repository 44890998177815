import { render, staticRenderFns } from "./Transfering.vue?vue&type=template&id=4ffebffe&scoped=true"
import script from "./Transfering.vue?vue&type=script&lang=js"
export * from "./Transfering.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffebffe",
  null
  
)

export default component.exports