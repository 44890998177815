import Vue from 'vue';
import { hgetStorage } from '../util/htools.web';

Vue.directive('authorize', {
	inserted(el, binding) {
		const { name, type, id } = binding.value;
		// TODELETE 设置一个不存在的权限 为了临时显示新增
		if (type === 'supervip') {
			return false;
		}
		el.style.display = 'none';
		setTimeout(() => {
			const btnPowers = hgetStorage('btnPowers');
			const powers = hgetStorage('powers');
			// console.log('btnPowers');
			// console.log(powers);
			// console.log(`${type}:${name}`);
			if (powers && powers.indexOf(`${type}:${name}`) > -1) {
				el.style.display = 'inline-block';
			} else {
				document.getElementById(id).remove();
			}
			// if (btnPowers && btnPowers.indexOf(`${type}:${name}`) > -1) {
			// 	el.style.display = 'inline-block';
			// } else {
			// 	document.getElementById(id).remove();
			// }
		}, 20);
	}
});
