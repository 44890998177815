<!--
 *@Description: 
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-07-13 15:53:26
-->
<template>
	<div class="app-container">
		<repair :repairObj="repairObj"></repair>
	</div>
</template>

<script>
import Repair from '../../repair/Repair';

export default {
	name: 'CompanyRepair',
	data() {
		return {
			repairObj: { type: 'company' }
		};
	},

	components: { Repair },
	destroyed() {}
};
</script>
<style lang="less" scoped></style>
