<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<CheckStockSearchform @searchForm="searchFormHandler"></CheckStockSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips"
					:authorize_p="'checkStock'"></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="状态" prop="isDone" width="100">
				<template slot-scope="scope">
					<span style="color:#67C23A;" v-if="scope.row.isDone === 1">已完成</span>
					<span style="color:#F56C6C;" v-else-if="scope.row.isDone === 0">
						未完成
					</span>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<!-- 管理员没有操作 -->
				<template slot-scope="scope">
					<el-button id="btn-update-row" type="primary"
						v-authorize="{ name: 'update', type: 'checkStock', id: 'btn-update-row' }"
						@click="setStateHandler(scope.row)">
						设置盘点状态
					</el-button>
					<el-button id="btn-update-row" type="primary"
						v-authorize="{ name: 'update', type: 'checkStock', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)">
						编辑
					</el-button>
					<el-button id="btn-remove-row" type="danger"
						v-authorize="{ name: 'remove', type: 'checkStock', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)">
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<CheckStockAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList" :isRefreshListAll_p.sync="isRefreshListAll" :selectData_p="selectData"
				:editId_p="editId"></CheckStockAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { checkStockDeleteService, checkStockListService, checkStockDetailService, checkStocksetdoneService } from '@s/spareParts/CheckStockService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import CheckStockAddForm from '@f/spareParts/checkStock/CheckStockAdd.form';
import CheckStockSearchform from '@f/spareParts/checkStock/CheckStockSearch.form';
import ListMixin from '@m/List.mixin';

export default {
	name: 'CheckStock',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		CheckStockAddForm,
		CheckStockSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '盘点时间',
					field: 'requestTime'
				},
				{
					label: '库房',
					field: 'warehouseName'
				}
			],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增盘点' : '编辑盘点';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.checkStockList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.checkStockList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.checkStockList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.checkStockList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.checkStockList();
	},
	methods: {
		async checkStockList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await checkStockListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await checkStockDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = [...ids];
			await checkStockDeleteService(dataJson);
			this.isRefreshList = true;
		},
		// 设置判断状态
		setStateHandler(row) {
			let done = 0;
			if (row.isDone === 1) {
				done = 0;
			} else {
				done = 1;
			}
			const dataJson = {
				id: row.id,
				done: done
			};
			this.$confirm('确认修改吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					await checkStocksetdoneService(dataJson);
					this.$message.success('操作成功！');
					this.checkStockList();
				})
				.catch(error => {
					console.log(error);
				});
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await checkStockDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.checkStockList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
