<template>
	<div class="app-container">
		<el-row :gutter="10">
			<el-col :span="4">
				<!-- <el-tree :data="typeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
				<ul>
					<li v-for="(item, index) in typeData" :key="index" @click="typeItemClickHandler(item)" class="typeItem"
						:class="{ 'is-current': item.id === typeid }">
						{{ item.name }}
					</li>
				</ul>
			</el-col>
			<el-col :span="20">
				<div class="head-container">
					<!-- 搜索区域 -->
					<DocSearchform @searchForm="searchFormHandler"></DocSearchform>
					<div>
						<!-- 功能按钮 -->
						<Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler"
							@showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips"
							:authorize_p="'doc'" :editDisabled_p="editDisabled" :isHide_p="{ removeBtn: true }"></Button>
					</div>
				</div>
				<!--表格渲染-->
				<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
					<el-table-column label="上传时间" prop="createTime">
						<template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
					</el-table-column>
					<el-table-column label="操作" align="left" fixed="right" width="300">
						<template slot-scope="scope">
							<el-button id="btn-update-row" type="primary"
								v-authorize="{ name: 'update', type: 'doc', id: 'btn-update-row' }"
								@click="editSingleHandler(scope.row)">
								编辑
							</el-button>
							<el-button id="btn-remove-row" type="danger"
								v-authorize="{ name: 'remove', type: 'doc', id: 'btn-remove-row' }"
								@click="deleteSingleHandler(scope.row)">
								删除
							</el-button>
							<!-- <a :href="`${fileUrl}/${scope.row.files}`" target="_blank" style="padding-left: 10px"> -->
							<el-button id="btn-download-row" type="success"
								v-authorize="{ name: 'download', type: 'doc', id: 'btn-download-row' }" @click="downloadFile(scope.row)">
								下载文件
							</el-button>
							<!-- </a> -->
						</template>
					</el-table-column>
				</Table>
				<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
				<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
					<DocAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog"
						:isRefreshList_p.sync="isRefreshList" :selectData_p="selectData" :editId_p="editId"
						:typeid_p="typeid"></DocAddForm>
				</Dialog>
			</el-col>
		</el-row>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { docDeleteService, docListService, docDetailService } from '@s/doc/DocService';
// eslint-disable-next-line import/no-cycle
import { typeListAllService } from '@s/doc/TypeService';
import { hgetStorage } from '@u/htools.web';

import DocAddForm from '@f/doc/doc/DocAdd.form';
import DocSearchform from '@f/doc/doc/DocSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		DocAddForm,
		DocSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '标题',
					field: 'title'
				},
				{
					label: '上传人',
					field: 'creatorName'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true,
			fileUrl: '',
			typeData: null,
			typeid: null,
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			token: localStorage.getItem('token')
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增文档' : '编辑文档';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.docList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.docList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.docList();
			}
		}
	},
	mounted() {
		this.typeList();
		setTimeout(() => {
			this.fileUrl = `${this.$envConfig.imgHttpAdd}${this.$envConfig.docs}${hgetStorage('organization')}`;
		}, 1000);
	},
	methods: {
		downloadFile(row) {
			console.log(this.token);
			console.log(`${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`);
			window.location.href = `${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`;
		},
		async typeList() {
			const dataJson = {};
			const res = await typeListAllService(dataJson);
			this.typeData = res;
			this.typeid = res[0].id;
			this.docList();
		},
		async docList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				title: '',
				typeid: this.typeid,
				...this.searchForm
			};
			const res = await docListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await docDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await docDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await docDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.docList();
		},
		handleNodeClick(data) {
			if (data.pid !== 0) {
				this.typeid = data.id;
				this.docList();
			} else {
				return false;
			}
		},
		typeItemClickHandler(data) {
			this.typeid = data.id;
			this.docList();
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}

/deep/.el-image__error {
	line-height: 14px;
}

.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.app-container /deep/.el-tree-node:focus>.el-tree-node__content {
	background: transparent;
}

.app-container /deep/.el-tree-node__content:hover {
	background: #409eff;
	color: #fff;
}

.app-container /deep/.is-current {
	background: #409eff;
	color: #fff;
}

.typeItem {
	padding-left: 10px;
	cursor: pointer;
	line-height: 30px;
	height: 30px;
	font-size: 14px;
}

.typeItem:hover {
	background: #409eff;
	color: #fff;
}

.typeItem.is-current {
	background: #409eff;
	color: #fff;
}
</style>
