<template>
	<div>
		<AllHospitalDevearning
			:queryP="{ orgid: orgid }"
		></AllHospitalDevearning>
	</div>
</template>

<script>
import AllHospitalDevearning from './components/AllHospitalDevearning';

export default {
	components: {
		AllHospitalDevearning
	},
	data() {
		return {
			orgid: localStorage.getItem('organization')
		};
	},
	mounted() {},
	methods: {}
};
</script>

<style lang="less" scoped></style>
