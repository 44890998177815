import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

import { changeTreeDataToChildren } from '../../../util/htools.tree';
/*
 *@Description: 医院科室列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const departmentListDefaultService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_1/department/departmentAllQuery', dataJson);
			res.records = changeTreeDataToChildren(res.records);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const departmentListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_1/department/departmentAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院科室添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const departmentAddService = data => {
	if (data.parentIdArr && data.parentIdArr.length) {
		data.parentId = data.parentIdArr[data.parentIdArr.length - 1];
		data.devInfoDepartmentId = data.parentIdArr[data.parentIdArr.length - 1];
	}
	return new Promise(async resolve => {
		try {
			const dataJson = {
				affiliatedHospitalId: 0,
				devInfoState: 0,
				...data
			};
			const res = await hpostRequest('hlzlong_1/department/departmentAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院科室修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const departmentEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				consumptionNatural: 0,
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				naturalAfter: '',
				naturalFront: '',
				powerConsumption: '',
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hlzlong_1/department/departmentUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院科室删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const departmentDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hpostRequest('hlzlong_1/department/departmentDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院科室详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const departmentDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentId: '',
				...data
			};
			const res = await hgetRequest('hlzlong_1/department/departmentByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 根据id查询部门科室
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const selectPoByOrgIdService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				orgId: '',
				...data
			};
			const res = await hgetRequest('hlzlong_1/department/selectPoByOrgId', dataJson);
			// resolve(res);
			resolve(changeTreeDataToChildren(res));
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院科室 查询部门负责人 TODELETE
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-05-27 13:03:00
 */
// export const systemUserAllQueryService = data => {
// 	return new Promise(async resolve => {
// 		try {
// 			const dataJson = {
// 				...data
// 			};
// 			const res = await hpostRequest('hlzlong_1/user/systemUserAllQuery', dataJson);
// 			resolve(res);
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	});
// };

export default departmentListDefaultService;
