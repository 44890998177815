<template>
	<el-submenu :index="'' + menu_p.id">
		<template slot="title">
			<i class="el-icon-message"></i>
			{{ menu_p.menuName }}
		</template>
		<template v-for="($menu, $index) in menu_p.children">
			<span :key="$index">
				<li v-if="$menu.targetProperty == '_blank'" class="el-menu-item">
					<a :href="bigScreenUrl" target="_blank" style="color: #bfcbd9; display: inline-block; width: 100%" @click="hospitalBigScreen($menu.url)">
						<i class="el-icon-message"></i>
						{{ $menu.menuName }}
					</a>
				</li>
				<template v-else>
					<el-menu-item :index="`${$menu.id}`" :route="{ path: `/${$menu.frontendRoute.path}` }" @click.native="clickMenu($menu)">
						{{ $menu.menuName }}
					</el-menu-item>
				</template>
				<Submenu v-if="$menu.children && $menu.children.length !== 0" :menu_p="$menu"></Submenu>
			</span>
		</template>
	</el-submenu>
</template>

<script>
import { hsetStorage } from '@/util/htools.web';

export default {
	name: 'Submenu',
	props: ['menu_p'],
	data() {
		return {
			bigScreenUrl: null
		};
	},
	methods: {
		clickMenu(menu) {
			hsetStorage('btnPowers', menu.powers);
		},
		hospitalBigScreen(menu) {
			this.bigScreenUrl = `${menu}?hospitalId=${hgetStorage('organization')}`;
		}
	}
};
</script>

<style>
</style>
