import { render, staticRenderFns } from "./VideoAdd.form.vue?vue&type=template&id=16939e62&scoped=true"
import script from "./VideoAdd.form.vue?vue&type=script&lang=js"
export * from "./VideoAdd.form.vue?vue&type=script&lang=js"
import style0 from "./VideoAdd.form.vue?vue&type=style&index=0&id=16939e62&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16939e62",
  null
  
)

export default component.exports