<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="79px" :disabled="isShowDetail_p">
          <el-link type="warning" :underline="false" style="margin-top:-30px;margin-bottom:10px;">视频内容应包含：品名、厂家、型号、报修故障、检查思路、检查经过、检查结果、维修过程、维修结果、参数检测等步骤</el-link>
          <el-form-item label="品名" prop="pinming">
            <el-input v-model="formData.pinming" placeholder="请输入品名"></el-input>
          </el-form-item>
          <el-form-item label="厂家" prop="changjia">
            <el-input v-model="formData.changjia" placeholder="请输入厂家"></el-input>
          </el-form-item>
          <el-form-item label="型号" prop="xinghao">
            <el-input v-model="formData.xinghao" placeholder="请输入型号"></el-input>
          </el-form-item>
          <el-form-item label="故障" prop="guzhang">
            <el-input v-model="formData.guzhang" placeholder="请输入故障"></el-input>
          </el-form-item>
          <el-form-item label="上传人" prop="owner">
            <el-select ref="multiSelect" v-model="formData.owner" placeholder="请选择上传人" filterable>
              <el-option v-for="(user, index) in userData" :key="index" :label="user.nickName" :value="user.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传时间" prop="createDate">
            <el-date-picker v-model="formData.createDate" type="datetime" placeholder="请选择上传时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="视频地址" prop="path">
            <el-input v-model="formData.path" placeholder="请输入视频地址"></el-input>
          </el-form-item>
          <el-form-item label="提问">
            <el-input type="textarea" v-model="formData.ask"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="24" style="text-align: right; margin-top: 10px">
        <span class="dialog-footer" style="text-align: right">
          <el-button @click="formCancel">取 消</el-button>
          <el-button type="primary" @click="formSubmit">确 定</el-button>
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	videoAddService,
	videoUpdateService,
	videoGetbykeyService
} from '@s/spareParts/VideoService';
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
// eslint-disable-next-line import/named
import {
	transferMaterialAddService,
	transferMaterialDeleteService,
	transferMaterialEditService
} from '@s/spareParts/TransferService';
// eslint-disable-next-line import/named
import { materialAllListService, materialDetailService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService } from '@s/spareParts/SupplierService';
import { htimeFormat, hremoveStorage, hgetStorage } from '@u/htools.web';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';
import ListMixin from '@m/List.mixin';

export default {
	props: ['selectData_p', 'isShowDetail_p', 'isShowAEDialog_p', 'selectDataFastvideo_p'],
	mixins: [ListMixin],
	data() {
		return {
			// 申请人下拉
			userData: [],
			isRefreshDetail: false,
			selectData: [],
			selectData1: [],
			isFastAddGood: true,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			// 弹窗
			formData: {
				pinming: null,
				changjia: null,
				xinghao: null,
				guzhang: null,
				owner: null,
				createDate: new Date(),
				path: null
			},
			formRules: {
				pinming: [
					{
						required: true,
						message: '请输入品名',
						trigger: 'blur'
					}
				],
				changjia: [
					{
						required: true,
						message: '请输入厂家',
						trigger: 'blur'
					}
				],
				xinghao: [
					{
						required: true,
						message: '请输入型号',
						trigger: 'blur'
					}
				],
				guzhang: [
					{
						required: true,
						message: '请输入故障',
						trigger: 'blur'
					}
				],
				createDate: [
					{
						required: true,
						message: '请选择申请时间',
						trigger: 'blur'
					}
				],
				path: [
					{
						required: true,
						message: '请输入视频地址',
						trigger: 'blur'
					}
				]
			},
			supplierData: [],
			materialData: [],
			warehouseData: [],
			materialDetailData: '',
			isShowReduceStockListDialog: false,
			selectDatainOutRecord: null
		};
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		},
		dialogTitleFastOut() {
			return '一键出库';
		}
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					console.log(newValue[0]);
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshDetail: {
			handler(newValue) {
				if (newValue) {
					this.showDialogEditHandler();
				}
			},
			deep: true
		}
	},
	created() {
		/* this.supplierList(); */
		this.materialList();
		this.warehouseList();
		this.userList();
	},
	methods: {
		// 一键出库后 刷新详情 TODO
		async showDialogEditHandler() {
			const dataJson = {
				id: this.formData.id
			};
			const res = await videoGetbykeyService(dataJson);
			const arr = res.items;
			for (let index = 0; index < arr.length; index++) {
				this.tableData.forEach((item1, index1, arr1) => {
					if (arr[index].id === item1.id) {
						item1.indonecnt = arr[index].indonecnt;
						item1.outdonecnt = arr[index].outdonecnt;
						item1.cnt = arr[index].cnt;
					}
				});
			}
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				requestId: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			/* const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name; */
		},
		saveDetailHandler(row) {
			if (!row.goodsId || row.cnt === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt < 0) {
				this.$message.warning('请输入正确的数量');
				return false;
			}
			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						this.formData.goodsId = item.id;
					}
				});
			// goodsName &&
			// 	this.tableData.forEach(item => {
			// 		if (item.isAddDetail) {
			// 			item.goodsId = goodsId;
			// 		}
			// 	});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async supplierList() {
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await transferMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await transferMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await transferMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		// 没有您想要的物料，点此快速添加
		showFastAddGood() {
			this.dialogAddHandlerMixin();
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || ''
			});
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						this.formData.createDate = htimeFormat(this.formData.createDate);
						await videoAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData,
							createDate: htimeFormat(this.formData.createDate)
						};
						await videoUpdateService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
			hremoveStorage('fastSaleOrPurchase');
		},
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
			if (!this.formData.owner) {
				this.formData.owner = hgetStorage('id');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}
.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
