import { hpostRequest } from '@u/htools.axios.js';

/*
 *@Description: 计量提醒
 *@ClassAuthor: zxh
 *@Date: 2022-02-17
 */
export const meteringRemindListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                pageNum: 1,
                pageSize: 10,
                devInfoName: '',
                orgId: '',
                devType: '',
                did: 0,
                measureAlert: true,
                ...data
            };
            const res = await hpostRequest('hbtyong_5/devBase/queryPaging', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

export default meteringRemindListService;
