import { hpostRequest } from '@u/htools.axios.js';

/*
 *@Description: 计量实施列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const inspectionImplementListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				state: '',
				devId: '',
				dutyPeople: '',
				hospitalId: '',
				hospitalOfficeId: '',
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/measureImplementAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 查询计量附件
 *@ClassAuthor: myw
 *@Date: 2021-06-09 15:21:04
 */
export const measureFileListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devId: '',
				measureId: '',
				...data
			};
			const res = await hpostRequest('hlzlong_3/measurePicture/getPicture', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除巡检附件
 *@ClassAuthor: myw
 *@Date: 2021-06-10 09:55:10
 */
export const inspectionFileDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong_3/measurePicture/delMeasurePicture', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 修改计量单状态
 *@ClassAuthor: myw
 *@Date: 2021-06-10 10:28:29
 */
export const inspectionImplementStatusService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				measureId: '',
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/updateEndStateById', dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default measureFileListService;
