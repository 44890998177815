import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

// 报修列表
export const queryDevRepairInfoPageALLService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				includePrice: true,
				...data
			};
			delete dataJson.page;
			delete dataJson.pagesize;
			delete dataJson.searchType;
			const res = await hpostRequest(
				'hbtyong_5/devRepairInfoService/queryDevRepairInfoPageALL',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const querySearchrepairService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				includePrice: true,
				...data
			};
			delete dataJson.pageNum;
			delete dataJson.pageSize;
			delete dataJson.searchType;
			delete dataJson.startTime;
			delete dataJson.endTime;
			delete dataJson.begin;
			delete dataJson.end;

			if (dataJson.hid) {
				console.log(1);
				dataJson.hids = [dataJson.hid] || [];
			} else {
				console.log(2);
				dataJson.hids = dataJson.hidsTemp;
			}
			console.log('dataJson.hids');
			console.log(dataJson.hid);
			console.log(dataJson.hidsTemp);
			delete dataJson.hid;

			const res = await hpostRequest(
				'hbtyong_5/devRepairInfoService/searchrepair',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 报修详情
export const queryDevRepairInfoByIdProcessService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				repairInfoId: 10,
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/devRepairInfoService/queryDevRepairInfoByIdProcess',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 保修费用
export const repairMoneyListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/repairprice/queryDevRepairPriceByIdProcess',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 添加保修费用
export const repairMoneyAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/repairprice/addrepairprice',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 删除保修费用
export const repairMoneyDelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/repairprice/deleteRepairPrice',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 报修费用详情
export const repairMoneyDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/repairprice/queryRepairPriceById',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 报修费用编辑
export const repairMoneyUpdateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/repairprice/updateRepairPrice',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 故障现象
export const repairProblemQueryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_2/problem/problemAllQuery',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 添加报修记录
export const repairAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/devRepairInfoService/addRepairHistory',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 修改签单时间
export const repairupdatesigntimeService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/devRepairInfoService/updatesigntime',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 删除报修信息
export const repairDelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/devRepairInfoService/deleteDevRepairInfo',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 获取设备所在部门报修人列表
export const repairDepartmentUserService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departid: '',
				...data
			};
			const res = await hgetRequest('user/selectByDeptId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 报修详情 设置维修实收
export const setRepairrealPriceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};

			const res = await hpostRequest(
				`hbtyong_5/devRepairInfoService/setrepairrealprice?cost=${dataJson.realprice}&repairId=${dataJson.devRepairId}`,
				dataJson.realpricememo || '""'
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 报修详情 设置成本
export const repairSetCostService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devRepairInfoService/setrepaircost?cost=${dataJson.cost}&repairId=${dataJson.devRepairId}`,
				dataJson.costinfo
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 报修详情 设置工作量
export const repairSetPercentsService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devRepairInfoService/updateworkpercents?repairid=${dataJson.devRepairId}`,
				dataJson.percents
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 获取 报修人列表 提取历史相关人员
export const gethosrepairuserlistService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				type: '',
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/devRepairInfoService/gethosrepairuserlist',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 维修费用 销售 关联 报修
export const updatewmsoutidService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devRepairInfoService/updatewmsoutid?repairid=${dataJson.repairid}&wmssellid=${dataJson.wmssellid}`,
				dataJson.percents
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 维修费用 采购 关联 报修
export const updatewmsinidService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devRepairInfoService/updatewmsinid?repairid=${dataJson.repairid}&wmsbuyid=${dataJson.wmsbuyid}`,
				dataJson.percents
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 添加维修过程
export const addRepairProcessInfoService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/repairProcessInfoService/addRepairProcessInfo',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 修改时间 维修过程
export const updaterepairtime1Service = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/devRepairInfoService/updaterepairtime1',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 更新 已到场 维修中 维修完成 维修过程
export const updateRepairProcessInfoService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/repairProcessInfoService/updateRepairProcessInfo',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default queryDevRepairInfoPageALLService;
