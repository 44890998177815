<template>
	<div class="app-container">
		<div class="head-container">
			<el-button id="btn-remove" class="filter-item" :type="isEdit ? 'success' : 'primary'"
				v-authorize="{ name: 'remove', type: 'supervip', id: 'btn-remove' }" @click="editHandler">
				{{ isEdit ? '提交' : '编辑' }}
			</el-button>
		</div>
		<!--表格渲染-->

		<el-form ref="tableForm" :model="tableDataObj" size="small" label-width="0">
			<Table ref="tableDom" :data.sync="tableDataObj.tableData" :tableColumn_p="tableColumn"
				:selectData_p.sync="selectData" highlight-current-row row-key="id" isHideCheckbox_p="false"
				:tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
				<!-- <el-table-column label="添加人" prop="createUserId"></el-table-column>
			<el-table-column label="负责人姓名" prop="orgId"></el-table-column>-->
				<el-table-column label="设备名称" prop="pname">
					<template slot-scope="scope">{{ scope.row.pname }}</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="typename">
					<template slot-scope="scope">{{ scope.row.typename }}</template>
				</el-table-column>
				<el-table-column label="巡检价格" prop="xjprice">
					<template slot-scope="scope">
						<el-form-item v-if="isEdit" :prop="'tableData.' + scope.$index + '.xjprice'"
							:rules="tableDataObj.tableRules.xjprice">
							<el-input v-model="scope.row.xjprice" placeholder="请输入巡检价格" type="number">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<span v-else>
							{{ scope.row.xjprice }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="保养价格" prop="byprice">
					<template slot-scope="scope">
						<el-form-item v-if="isEdit" :prop="'tableData.' + scope.$index + '.byprice'"
							:rules="tableDataObj.tableRules.byprice">
							<el-input v-model="scope.row.byprice" placeholder="请输入保养价格" type="number">
								<template slot="append">元</template>
							</el-input>
						</el-form-item>
						<span v-else>
							{{ scope.row.byprice }}
						</span>
					</template>
				</el-table-column>
			</Table>
		</el-form>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { xjbypricelistService, xjbypriceupdateService } from '@s/base/XjbyDevpriceService';
import Table from '@c/ui/Table';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'Xjbydevprice',
	mixins: [ListMixin],
	components: {
		Table
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			tableDataObj: {
				tableData: [],
				tableRules: {
					// xjprice: [
					// 	{ required: true, message: "请输入成员名称", trigger: "blur" }
					// ]
				}
			},
			isEdit: false
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.getList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async getList() {
			const res = await xjbypricelistService();
			this.tableDataObj.tableData = res;
		},
		async editHandler() {
			if (this.isEdit) {
				const dataJson = this.tableDataObj.tableData;
				dataJson.forEach(item => {
					if (item.xjprice) {
						item.xjprice = Number(item.xjprice);
					}
					if (item.byprice) {
						item.byprice = Number(item.byprice);
					}
				});
				const res = await xjbypriceupdateService(dataJson);
				console.log(res);
				if (res) {
					this.getList();
					this.$message.success('操作成功');
				}
			}
			this.isEdit = !this.isEdit;
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		}
	}
};

</script>

<style lang="less" scoped>
.head-container {
	margin: 12px 0;
	position: sticky;
	top: 10px;
	z-index: 9;
	text-align: right;
}

.head-container .filter-item {
	display: inline-block;
	vertical-align: middle;
	margin: 0 3px 0 0;
}

/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
	margin-bottom: 0;
}
</style>
