<template>
  <div class="app-container">
    <el-row :gutter="10">
      <el-col :span="2">
        <!-- <el-tree :data="typeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
        <ul>
          <li v-for="(item, index) in typeData" :key="index" @click="typeItemClickHandler(item)" class="typeItem" :class="{ 'is-current': item.id === typeid }">
            {{ item.name }}
          </li>
        </ul>
      </el-col>
      <el-col :span="22">
        <div class="head-container">
          <!-- 搜索区域 -->
          <ContractSearchform @searchForm="searchFormHandler"></ContractSearchform>
          <div>
            <!-- 功能按钮 -->
            <Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler" @showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips" :authorize_p="'contract'" :editDisabled_p="editDisabled" :isHide_p="{ removeBtn: true, updateBtn:true }"></Button>
          </div>
        </div>
        <!--表格渲染-->
        <Table ref="tableDom" @expand-change="expandChange" @row-click="handleRowClick" row-key="id" :expand-row-keys="expands" :data.sync="tableData" :tableColumn_p="tableColumn" isHideCheckbox_p="false" :header-row-class-name="'tableTheadTr'" :row-class-name="'tableTbodyTr'" :selectData_p.sync="selectData">

          <el-table-column type="expand">
            <template slot-scope="props" v-if="props.row.payments.length">
              <!-- <el-table :data="props.row.payments" style="width: 100%" border :size="'mini'">
			<el-table-column label="付款日期" prop="paydate">
				<template slot-scope="scope">{{ scope.row.paydate |  parseTime('{y}-{m}-{d}') }}</template>
			</el-table-column>
			<el-table-column prop="money" label="金额"></el-table-column>
			<el-table-column label="是否付款" prop="payed">
				<template slot-scope="scope">{{ scope.row.payed?'是':'否' }}</template>
			</el-table-column>
			<el-table-column label="发票" prop="fp">
				<template slot-scope="scope">{{ scope.row.fp?'是':'否' }}</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="390">
				<template slot-scope="scope">
				<el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'contract', id: 'btn-update-row' }" @click="editPaySingleHandler(scope.row)">
					编辑
				</el-button>
				<el-button id="btn-update-row" type="danger" v-authorize="{ name: 'remove', type: 'contract', id: 'btn-update-row' }" @click="deletePaySingleHandler(scope.row)">
					删除
				</el-button>
				</template>
			</el-table-column>
			</el-table> -->
              <table class="table">
                <tr>
                  <th>付款日期</th>
                  <th>金额</th>
                  <th>是否付款</th>
                  <th>发票</th>
                  <th>操作</th>
                </tr>
                <tr v-for="item in props.row.payments" :key="item.id">
                  <td>{{ item.paydate |  parseTime('{y}-{m}-{d}') }}</td>
                  <td>{{ item.money }}</td>
                  <td>{{ item.payed?'是':'否' }}</td>
                  <td>{{ item.fp?'是':'否' }}</td>
                  <td>
                    <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'contract', id: 'btn-update-row' }" @click="editPaySingleHandler(item,props.row.id)" size="mini">
                      编辑
                    </el-button>
                    <el-button id="btn-update-row" type="danger" v-authorize="{ name: 'remove', type: 'contract', id: 'btn-update-row' }" @click="deletePaySingleHandler(item)" size="mini">
                      删除
                    </el-button>
                  </td>
                </tr>
              </table>
            </template>
          </el-table-column>
          <el-table-column label="id" prop="id" width="50"></el-table-column>
          <el-table-column label="合同名称" prop="name"></el-table-column>
          <el-table-column label="甲方" prop="leftName"></el-table-column>
          <el-table-column label="乙方" prop="rightName"></el-table-column>
          <el-table-column label="开始日期" prop="beginDate" width="90">
            <template slot-scope="scope">{{ scope.row.beginDate |  parseTime('{y}-{m}-{d}') }}</template>
          </el-table-column>
          <el-table-column label="结束时间" prop="endDate" width="90">
            <template slot-scope="scope">{{ scope.row.endDate | parseTime('{y}-{m}-{d}') }}</template>
          </el-table-column>
          <el-table-column label="付款次数" prop="payCnt" width="80"></el-table-column>
          <el-table-column label="金额" prop="allPrice"></el-table-column>
          <el-table-column label="下次付款" prop="allPrice">
            <template slot-scope="scope">
              <span v-if="scope.row.nextPay.length > 0" style="text-align:right; width: 100%; display: inline-block;">
                <span v-if="scope.row.nextPay[0].isThen30 < 0" style="color:#F56C6C"> {{scope.row.nextPay[0].money}}￥ [{{scope.row.nextPay[0].paydate |  parseTime('{y}-{m}-{d}')}}]</span>
                <span v-else-if="scope.row.nextPay[0].isThen30 <= 30" style="color:#E6A23C"> {{scope.row.nextPay[0].money}} ￥ [{{scope.row.nextPay[0].paydate |  parseTime('{y}-{m}-{d}')}}]</span>
                <span v-else style="color:#303133"> {{scope.row.nextPay[0].money}}￥ [ {{scope.row.nextPay[0].paydate |  parseTime('{y}-{m}-{d}')}}]</span>
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="allPrice" width="90">
            <template slot-scope="scope">
              <span v-if="scope.row.state == '未开始'" style="color: #333333">未开始</span>
              <span v-else-if="scope.row.state == '执行中'" style="color: #95F204">执行中</span>
              <span v-else-if="scope.row.state == '即将到期'" style="color: #F59A23">即将到期</span>
              <span v-else-if="scope.row.state == '已过期'" style="color: #D9001B">已过期</span>
              <span v-else-if="scope.row.state == '已结束'" style="color: #7F7F7F">已结束</span>
              <span v-else style="color: #333333">其他</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left" fixed="right" width="330">
            <template slot-scope="scope">
              <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'contract', id: 'btn-update-row' }" @click.stop="editSingleHandler(scope.row)">
                编辑
              </el-button>
              <el-button id="btn-remove-row" type="danger" v-authorize="{ name: 'remove', type: 'contract', id: 'btn-remove-row' }" @click.stop="deleteSingleHandler(scope.row)">
                删除
              </el-button>
              <el-button id="btn-remove-row" type="warning" v-authorize="{ name: 'add', type: 'contract', id: 'btn-remove-row' }" @click.stop="addPayHandler(scope.row)">
                添加支付
              </el-button>
              <a :href="`${fileUrl}/${scope.row.files}`" target="_blank" style="padding-left: 10px">
                <el-button id="btn-remove-row" type="success">
                  下载文件
                </el-button>
              </a>
            </template>
          </el-table-column>
        </Table>
        <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
        <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
          <ContractAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectData" :editId_p="editId" :typeid_p="typeid"></ContractAddForm>
        </Dialog>
        <Dialog :title="dialogPayTitle" :visible.sync="isShowPayAEDialog">
          <ContractPayAddForm v-if="isShowPayAEDialog" :isShowAEDialog_p.sync="isShowPayAEDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectPayData" :editId_p="editPayId" :contractId_p="contractId"></ContractPayAddForm>
        </Dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	searchListService,
	typesListService,
	contractDetailService,
	contractDeleteService,
	contractDeletePaymentService
} from '@s/spareParts/ContractService';
import { hgetStorage } from '@u/htools.web';

import ContractAddForm from '@f/spareParts/contract/ContractAdd.form';
import ContractPayAddForm from '@f/spareParts/contract/ContractPayAdd.form';
import ContractSearchform from '@f/spareParts/contract/ContractSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		ContractAddForm,
		ContractPayAddForm,
		ContractSearchform
	},
	data() {
		return {
			// 获取row的key值
			getRowKeys(row) {
				return row.id;
			},
			// 要展开的行，数值的元素是row的key值
			expands: [],
			editPayId: -1,
			selectPayData: [],
			// 表格
			tableColumn: [],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true,
			fileUrl: '',
			typeData: null,
			typeid: null,
			contractId: null,
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			isShowPayAEDialog: false
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增合同' : '编辑合同';
		},
		dialogPayTitle() {
			return this.editPayId === -1 ? '新增支付' : '编辑支付';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.contractList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.contractList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.selectData = [];
				this.selectPayData = [];
				this.contractList();
			}
		},
		expands: {
			handler(newValue) {
				console.log(newValue);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.typeList();
		setTimeout(() => {
			this.fileUrl = `${this.$envConfig.imgHttpAdd}contract`;
		}, 1000);
	},
	methods: {
		async typeList() {
			const dataJson = {};
			const res = await typesListService(dataJson);
			this.typeData = res;
			this.typeid = res[0].id;
			this.contractList();
		},
		async contractList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				typeId: this.typeid,
				...this.searchForm
			};
			const res = await searchListService(dataJson);
			// 下次付款时间排序
			res.records &&
				res.records.forEach((item, index, arr) => {
					item.nextPay = [];
				});
			res.records &&
				res.records.forEach((item, index, arr) => {
					item.payments &&
						item.payments.forEach(item1 => {
							// 是否大于30
							item1.paydateTime = new Date(item1.paydate).getTime();
							item1.isThen30 =
								(new Date(item1.paydate).getTime() - new Date().getTime()) /
								1000 /
								60 /
								60 /
								24;
							if (!item1.payed) {
								item.nextPay.push(item1);
							}
						});
				});
			res.records &&
				res.records.forEach((item, index, arr) => {
					if (item.nextPay) {
						// 按日期排序
						item.nextPay.sort((a, b) => {
							return a.paydateTime - b.paydateTime;
						});
					}
				});
			console.log('res list');
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
			console.log(this.typeid);
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await contractDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await contractDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await contractDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async editPaySingleHandler(row, pid) {
			this.selectPayData = [row];
			this.isShowPayAEDialog = true;
			this.contractId = pid;
			setTimeout(() => {
				this.$refs.tableDom.clearSelection();
			});
		},
		// 添加支付
		addPayHandler(row) {
			console.log(this.expands);
			this.editPayId = -1;
			const dataJson = {
				id: row.id
			};
			// this.selectPayData = [row];
			this.contractId = row.id;
			console.log(this.expands);
			this.isShowPayAEDialog = true;
			console.log(this.expands);
		},
		handleRowClick(row, column, event) {
			if (this.expands.includes(row.id)) {
				this.expands = this.expands.filter(val => val !== row.id);
			} else {
				this.expands.push(row.id);
			}
		},
		expandChange(row, expandedRows) {
			console.log(row);
			console.log(expandedRows);

			const ids = [];
			expandedRows &&
				expandedRows.forEach(item => {
					ids.push(item.id);
				});

			this.expands = ids;
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		deletePaySingleHandler(row) {
			this.$confirm('确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await contractDeletePaymentService({ id: row.id });
					this.contractList();
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.contractList();
		},
		handleNodeClick(data) {
			if (data.pid !== 0) {
				this.typeid = data.id;
				this.contractList();
			} else {
				return false;
			}
		},
		typeItemClickHandler(data) {
			this.typeid = data.id;
			this.contractList();
		}
	}
};
</script>

<style lang="less" scoped>
/* 表格初始化 bootstrap */
table {
	border-collapse: collapse;
	width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse !important;
	// border-left: 1px solid #dee2e6;
	// border-top: 1px solid #dee2e6;
	border: 1px solid #dee2e6;
}
.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}
.table-bordered th,
.table-bordered td {
	/* border: 1px solid #dee2e6 !important; */
}
.table-dark {
	color: inherit;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #dee2e6;
}
.table .thead-dark th {
	color: inherit;
	border-color: #dee2e6;
}
// /deep/.tableTbodyTr td:first-child,
// /deep/.tableTheadTr th:first-child {
// 	border-right: 1px solid transparent;
// }
/deep/.tableTbodyTr {
	cursor: pointer;
}
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
/deep/.el-image__error {
	line-height: 14px;
}
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.app-container /deep/.el-tree-node:focus > .el-tree-node__content {
	background: transparent;
}
.app-container /deep/.el-tree-node__content:hover {
	background: #409eff;
	color: #fff;
}
.app-container /deep/.is-current {
	background: #409eff;
	color: #fff;
}
.typeItem {
	padding-left: 10px;
	cursor: pointer;
	line-height: 30px;
	height: 30px;
	font-size: 14px;
}
.typeItem:hover {
	background: #409eff;
	color: #fff;
}
.typeItem.is-current {
	background: #409eff;
	color: #fff;
}
</style>
