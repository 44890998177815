<template>
  <el-form ref="devBaseForm" :model="devBaseForm" :rules="devBaseRules" size="small" label-width="100px">
    <el-collapse v-model="activeName" @change="handleChangeActiveName">
      <el-collapse-item title="基本属性" name="1">
        <el-form-item label="设备类别" prop="devInfoType">
          <!-- <el-select
						clearable
						filterable
						v-model="devBaseForm.devInfoType"
						placeholder="请选择设备类别"
						style="width: 100%"
						@change="devInfoChange($event)"
						:disabled="disabled4"
					>
						<el-option
							v-for="item in deviceTypeData"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>-->

          <!-- <el-select v-model="devInfoTypeName" class="select-item" placeholder="请选择" collapse-tags clearable filterable>
            <el-option :value="devBaseForm.devInfoType" style="height: auto">
              <el-tree :data="deviceTypeData" node-key="id" ref="tree" highlight-current :props="defaultTypeProps" @node-click="nodeTypeClickHandler"></el-tree>
            </el-option>
          </el-select> -->

          <treeselect v-model="devBaseForm.devInfoType" :multiple="false" :options="deviceTypeData" @open="clearValiddevInfoType" class="select-item" style="width:190px; height:32px;" :disable-branch-nodes="true" :normalizer="normalizer" placeholder="请选择" noResultsText="暂无结果" :close-on-select="true" :show-count="true" />

        </el-form-item>
        <el-form-item label="标准型号" prop="devInfoModel">
          <el-select clearable filterable v-model="devBaseForm.devInfoModel" placeholder="请选择标准型号" style="width: 100%" @change="devInfoModelChange($event)">
            <el-option v-for="item in normData" :key="item.id" :label="item.deviceModel" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编码" prop="devInfoNumber">
          <el-input v-model="devBaseForm.devInfoNumber" placeholder="请输入设备编码" />
        </el-form-item>
        <el-form-item label="设备名称" prop="devInfoName">
          <el-input v-model="devBaseForm.devInfoName" placeholder="请输入设备名称" :disabled="disabled4" />
        </el-form-item>
        <el-form-item label="规格型号" prop="devInfoNns1">
          <el-input v-model="devBaseForm.devInfoNns1" placeholder="请输入规格型号" :disabled="disabled4" />
        </el-form-item>
        <el-form-item label="计量单位" prop="devInfoNns2">
          <el-select clearable filterable v-model="devBaseForm.devInfoNns2" placeholder="请选择计量单位" style="width: 100%" @change="selectStatus" :disabled="disabled4">
            <el-option v-for="item in unitData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生产厂家" prop="devInfoFactory">
          <el-select clearable filterable v-model="devBaseForm.devInfoFactory" placeholder="请选择生产厂家" style="width: 100%">
            <el-option v-for="item in factoryData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商">
          <el-select clearable filterable v-model="devBaseForm.supplierId" placeholder="请选择供应商" style="width: 100%">
            <el-option v-for="item in supplierSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备品牌" prop="devInfoBrand">
          <el-input v-model="devBaseForm.devInfoBrand" placeholder="请输入设备品牌" />
        </el-form-item>
        <el-form-item label="生产序列号" prop="devInfoSerialNo">
          <el-input v-model="devBaseForm.devInfoSerialNo" placeholder="请输入生产序列号" />
        </el-form-item>
        <el-form-item label="购入日期" prop="devInfoEnableTime">
          <!-- <el-input v-model="devBaseForm.devInfoEnableTime" placeholder="请输入购入日期" /> -->

          <el-date-picker v-model="devBaseForm.devInfoEnableTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
          <!-- value-format="yyyy-MM-dd HH:mm:ss" -->
        </el-form-item>
        <el-form-item label="设备金额" prop="devInfoNns3">
          <el-input v-model="devBaseForm.devInfoNns3" placeholder="请输入设备金额" />
        </el-form-item>
        <el-form-item label="使用科室" prop="devInfoDepartmentId">
          <!-- <SelectTree v-model="devBaseForm.devInfoDepartmentId" :data="optionsDept" node-key="id" :isLastLeaf="true" @nodeInfo="selectDevInfo"></SelectTree> -->

          <treeselect v-model="devBaseForm.devInfoDepartmentId" :multiple="false" :options="optionsDept" @open="clearValiddevInfoDepartmentId" class="select-item" style="width:190px; height:32px;" :disable-branch-nodes="true" placeholder="请选择" noResultsText="暂无结果" :close-on-select="true" :show-count="true" />

        </el-form-item>
        <el-form-item label="68分类-大类" prop="type68Big">
          <el-select v-model="devBaseForm.type68Big" placeholder="请选择大类" clearable filterable @change="changeType68Big($event)">
            <el-option v-for="item in optionsBigType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="68分类-小类" prop="type68Small">
          <el-select v-model="devBaseForm.type68Small" placeholder="请选择小类" clearable filterable @change="selectStatus">
            <el-option v-for="item in optionsSmallType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用房间" prop="devPosition">
          <el-input v-model="devBaseForm.devPosition" placeholder="请输入使用房间" />
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="备注" prop="devInfoRemarks" style="width: 59%; height: 160px">
          <el-input type="textarea" v-model="devBaseForm.devInfoRemarks" placeholder="请输入备注" class="textAreaRemakes" style />
        </el-form-item>
        <el-form-item label="设备图片" prop="devInfoImgUrl" style="height: 200px; margin-left: 28px">
          <!-- <el-upload action="#" list-type="picture-card" :auto-upload="false">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
					</el-upload>-->

          <Upload :action="uploadLogoAction" :limit="1" list-type="picture-card" :file-list="logoFile" :on-success="handlerLogoSuccess" :on-remove="handlerLogoRemove" :isShowTip_p="false" class="isShowUpload">
            <p>设备最多上传1张图片</p>
          </Upload>
          <!-- <el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt />
					</el-dialog>-->
        </el-form-item>
      </el-collapse-item>

      <el-collapse-item title="扩展字段" name="4">
        <el-form-item label="医院资产编码" prop="hospitalAssetCode">
          <el-input v-model="devBaseForm.hospitalAssetCode" placeholder="请输入医院资产编码" />
        </el-form-item>
        <!-- 厂家维保到期时间 -->
        <el-form-item label="维保到期时间" prop="maintainDate">
          <el-date-picker v-model="devBaseForm.maintainDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="在账状态">
          <el-select v-model="devBaseForm.useType" placeholder="请选择在账状态" clearable filterable>
            <el-option label="在账在用" :value="1"></el-option>
            <el-option label="不在账在用" :value="2"></el-option>
            <el-option label="在账不在用" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用年限">
          <el-input-number style="width:190px;" v-model="devBaseForm.useyear" controls-position="right" :min="1" :max="1000" :precision="0"></el-input-number>
        </el-form-item>
        <el-form-item label="残值">
          <el-input-number style="width:190px;" v-model="devBaseForm.residual" controls-position="right" :min="0" :max="9999999" :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="到货时间">
          <el-date-picker v-model="devBaseForm.reciveGoodsDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="拆箱时间">
          <el-date-picker v-model="devBaseForm.openboxDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="安装调试时间">
          <el-date-picker v-model="devBaseForm.installDebugDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="验收时间">
          <el-date-picker v-model="devBaseForm.checkInstallDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="入库时间">
          <el-date-picker v-model="devBaseForm.inWarehouseDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="出库时间">
          <el-date-picker v-model="devBaseForm.outWarehouseDate" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <div style="clear: both"></div>
        <el-form-item label="设备相关证照" style="float: none; min-height: 125px; height: auto; width: 100%">
          <Upload :action="uploadLogoAction" list-type="picture-card" :file-list="extFile" :on-success="handlerExtSuccess" :on-remove="handlerExtRemove" :isShowTip_p="false"></Upload>
        </el-form-item>
        <div style="clear: both"></div>
        <el-form-item label="包含配件" style="width: 100%; height: 160px">
          <el-input type="textarea" v-model="devBaseForm.partsList" placeholder="请输入备注" class="textAreaRemakes" style />
        </el-form-item>

      </el-collapse-item>

      <el-collapse-item title="属性信息" name="2">
        <el-form-item label="是否特种设备" prop="isSpecialTypeDev">
          <el-select v-model="devBaseForm.isSpecialTypeDev" placeholder="请选择" clearable filterable>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否计量设备" prop="isMeteringDev">
          <el-select v-model="devBaseForm.isMeteringDev" placeholder="请选择" clearable filterable>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="巡检周期(月)" prop="inspectPeriod">
          <el-input v-model.number="devBaseForm.inspectPeriod" type="number" placeholder="请输入巡检周期" />
        </el-form-item>
        <el-form-item label="下次巡检时间" prop="nextInspectTime">
          <el-date-picker v-model="devBaseForm.nextInspectTime" type="date" placeholder="选择下次巡检时间" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="巡检提前通知天数" prop="inspectRemindDays" label-width="130px">
          <el-input v-model.number="devBaseForm.inspectRemindDays" type="number" placeholder="请输入巡检提前通知天数" />
        </el-form-item>
        <el-form-item label="保养周期(月)" prop="upkeepPeriod">
          <el-input v-model.number="devBaseForm.upkeepPeriod" type="number" placeholder="请输入保养周期" />
        </el-form-item>
        <el-form-item label="下次保养时间" prop="nextUpkeepTime">
          <el-date-picker v-model="devBaseForm.nextUpkeepTime" type="date" placeholder="选择下次保养时间" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="保养提前通知天数" prop="upkeepRemindDays" label-width="130px">
          <el-input v-model.number="devBaseForm.upkeepRemindDays" type="number" placeholder="请输入保养提前通知天数" />
        </el-form-item>
        <template v-if="devBaseForm.isMeteringDev === 1">
          <el-form-item label="计量周期(月)" prop="testCycle">
            <el-input v-model="devBaseForm.testCycle" type="number" min="0" placeholder="请输入计量周期">
              <!-- <template slot="append"></template> -->
            </el-input>
          </el-form-item>
          <el-form-item label="下次待检日期" prop="nextTestTime">
            <el-date-picker v-model="devBaseForm.nextTestTime" type="date" placeholder="请选择下次待检日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%"></el-date-picker>
          </el-form-item>
          <el-form-item label="提前提醒天数" prop="checkCountDown" label-width="130px">
            <el-input v-model="devBaseForm.checkCountDown" type="number" min="0" placeholder="请输入提前提醒天数" />
          </el-form-item>
        </template>
      </el-collapse-item>
      <el-collapse-item title="维保信息" name="3">
        <el-form-item label="维修公司" prop="serviceOrganizationId">
          <el-select v-model="devBaseForm.serviceOrganizationId" placeholder="请选择维修公司名称" clearable filterable style="width: 100%">
            <el-option v-for="item in optionsCompany" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维修时间" prop="timeArr">
          <el-date-picker v-model="devBaseForm.timeArr" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="报修期限" prop="transferBatchNumber">
          <el-input v-model="devBaseForm.transferBatchNumber" disabled>
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="维保说明" prop="remarks" class="textArea" style="width: 60%">
          <el-input type="textarea" v-model="devBaseForm.remarks" class="textAreaRemakes" placeholder="请输入维保说明"></el-input>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>

    <div style="text-align: right; margin-top: 20px">
      <span class="dialog-footer">
        <el-button @click="devBaseFormCancel">取 消</el-button>
        <el-button type="primary" @click="devBaseFormSubmit">确 定</el-button>
      </span>
    </div>
  </el-form>
</template>

<script>
import {
	devBaseTypeListService,
	devBaseAddService,
	normListService,
	devBaseEditService,
	unitListService,
	deviceNameAllQueryService,
	sizeClassesBigAllQueryService,
	sizeClassesByBigIdQueryService
} from '@s/hospital/devBase/DevBaseService';
import { supplierListService } from '@s/spareParts/SupplierService';
// import { organizationAllQueryListService } from '@s/hospital/contract/ContractService';
import { getcompanysignListService } from '@s/hospital/contract/ContractService';

import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import { factoryListService } from '@s/hospital/factory/FactoryService';
import Upload from '@c/ui/Upload.vue';
import { getTreeNodeById } from '@u/htools.tree';
import { arrayIncludeItem } from '@u/index';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';
import { hgetStorage, htimeFormat, hdateInMonth } from '@/util/htools.web';

export default {
	props: ['selectData_p', 'editId_p'],
	components: {
		Upload,
		// SelectTree,
		Treeselect
	},
	data() {
		const moneyValid = (rule, value, callback) => {
			const reg =
				/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的设备金额'));
			} else {
				callback();
			}
		};
		return {
			// 添加用户 部门下拉
			optionsDept: [],
			supplierSelectList: [],
			optionsBigType: null,
			optionsSmallType: null,
			disabled4: false,
			optionsCompany: [],
			activeName: ['1', '2', '3', '4'],
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			editId: -1,
			editIdFlag: 1,
			unitData: [],
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			devInfoTypeName: '',
			defaultTypeProps: {
				children: 'children',
				label: 'deviceType'
			},
			devBaseForm: {
				devInfoType: null,
				devInfoName: null,
				devInfoNns1: null,
				devInfoNns2: null,
				devInfoNns3: null,
				devInfoDepartmentId: null, // 科室
				description: 36,
				isSpecialTypeDev: 0,
				isMeteringDev: 0,
				name: '',
				isDel: 0,
				parentIdArr: '',
				devInfoImgUrlArr: [],
				devInfoImgUrlTempArr: [],
				devExtImgUrls: '',
				inspectPeriod: '',
				nextInspectTime: '',
				inspectRemindDays: '',
				upkeepPeriod: '',
				nextUpkeepTime: '',
				upkeepRemindDays: '',
				useType: '',
				useyear: undefined,
				residual: undefined,
				reciveGoodsDate: null, // 到货时间
				openboxDate: null, // 拆箱时间
				installDebugDate: null, // 安装调试时间
				checkInstallDate: null, // 验收时间
				inWarehouseDate: null, // 入库时间
				outWarehouseDate: null, // 出库时间
				partsList: null, // 包含配件
				supplierId: null // 供应商id
			},
			// 上传图片
			uploadLogoAction: `${this.$envConfig.btyong_5}devBase/${this.$envConfig.uploadCompanyDevImgList}`,
			logoFile: [],
			extFile: [],
			devBaseRules: {
				devInfoType: [
					{
						required: true,
						message: '请选择设备类别',
						trigger: 'change'
					}
				],
				devInfoModel: [
					// {
					// 	required: true,
					// 	message: '请现在标准型号',
					// 	trigger: 'change'
					// }
				],
				// devInfoNumber: [
				// 	{
				// 		required: true,
				// 		message: '请输入设备编号',
				// 		trigger: 'blur'
				// 	}
				// ],
				devInfoName: [
					{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}
				],
				devInfoFactory: [
					// {
					// 	required: true,
					// 	message: '请选择生产厂家',
					// 	trigger: 'change'
					// }
				],
				devInfoDepartmentId: [
					{
						required: true,
						message: '请选择使用科室',
						trigger: 'change'
					}
				],
				devInfoEnableTime: [
					{
						required: true,
						message: '请选择购入日期',
						trigger: 'change'
					}
				],
				devInfoNns3: [
					{
						required: true,
						validator: moneyValid,
						trigger: 'blur'
					}
				]
			},
			deviceTypeData: [],
			normData: [],
			factoryData: [],
			devExtImgUrlArr: [],
			oldParams: {},
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0 && this.editIdFlag === 1) {
					// 图片上传辅助数组
					newValue[0].devInfoImgUrlArr = [];
					newValue[0].devInfoImgUrlTempArr = [];
					// 不知道为什么会执行2次
					this.editIdFlag = 0;
					this.logoFile = [];
					this.extFile = [];
					// 添加一个标识   如果是编辑  给id  如果是复制  不给id默认-1新增
					if (newValue[0].addType === 'editadd') {
						this.editId = newValue[0].devInfoId;
					}
					this.optionsDept === null && (await this.departmentList());
					this.deviceTypeData === null && (await this.deviceTypeList());
					// this.devInfoChange(newValue[0].devInfoType);

					newValue[0].devInfoType = Number(newValue[0].devInfoType);
					newValue[0].type68Big = Number(newValue[0].type68Big);

					this.getOptionsSmallType(Number(newValue[0].type68Big));

					this.type68Small = Number(newValue[0].type68Small);

					newValue[0].type68Small = Number(newValue[0].type68Small);

					// newValue[0].devInfoModel = Number(newValue[0].devInfoModel);
					newValue[0].devInfoFactory = Number(newValue[0].devInfoFactory);
					newValue[0].supplierId = Number(newValue[0].supplierId);
					
					newValue[0].reciveGoodsDate = htimeFormat(newValue[0].reciveGoodsDate);
					newValue[0].openboxDate = htimeFormat(newValue[0].openboxDate);
					newValue[0].installDebugDate = htimeFormat(newValue[0].installDebugDate);
					newValue[0].checkInstallDate = htimeFormat(newValue[0].checkInstallDate);
					newValue[0].inWarehouseDate = htimeFormat(newValue[0].inWarehouseDate);
					newValue[0].outWarehouseDate = htimeFormat(newValue[0].outWarehouseDate);
					newValue[0].service = Number(newValue[0].serviceOrganizationId);
					newValue[0].devInfoNns2 = Number(newValue[0].devInfoNns2);
					newValue[0].useType === 0
						? (newValue[0].useType = null)
						: (newValue[0].useType = Number(newValue[0].useType));
					newValue[0].useyear === 0 || newValue[0].useyear === null
						? (newValue[0].useyear = undefined)
						: newValue[0].useyear;
					newValue[0].residual === 0 || newValue[0].residual === null
						? (newValue[0].residual = undefined)
						: newValue[0].residual;

					newValue[0].timeArr = newValue[0].signBeginTime
						? [newValue[0].signBeginTime, newValue[0].signEndTime]
						: '';
					newValue[0].serviceOrganizationId =
						newValue[0].serviceOrganizationId === 0
							? null
							: newValue[0].serviceOrganizationId;
					newValue[0].devInfoType && this.normList(newValue[0].devInfoType);

					// this.devBaseForm.devInfoModel = Number(this.devBaseForm.devInfoModel);
					const result = arrayIncludeItem(this.normData, newValue[0].devInfoModel);
					if (!result) {
						this.devBaseForm.devInfoModel = null;
					}

					/* if (this.optionsDept.length !== 0) { */
					getTreeNodeById(
						this.optionsDept,
						'id',
						newValue[0].devInfoDepartmentId,
						node => {
							this.parentName = node.department;
						}
					);
					getTreeNodeById(this.deviceTypeData, 'id', newValue[0].devInfoType, node => {
						this.devInfoTypeName = node.deviceType;
					});
					/* } */
					this.devBaseForm = { ...newValue[0] };
					if (!this.isEmpty(this.devBaseForm)) {
						this.oldParams = { ...this.devBaseForm };
					}
					if (
						newValue[0].devInfoImgUrl &&
						newValue[0].devInfoImgUrl.indexOf(',') === -1
					) {
						this.logoFile = [
							{
								name: '一个图片',
								path: newValue[0].devInfoImgUrl,
								url:
									this.$envConfig.imgHttpAdd +
									this.$envConfig.devbase +
									newValue[0].devInfoImgUrl
							}
						];
						newValue[0].devInfoImgUrlArr.push(newValue[0].devInfoImgUrl);
						this.devBaseForm.devInfoImgUrlTempArr = newValue[0].devInfoImgUrl;
					}
					if (
						newValue[0].devInfoImgUrl &&
						newValue[0].devInfoImgUrl.indexOf(',') !== -1
					) {
						newValue[0].devInfoImgUrl = newValue[0].devInfoImgUrl.split(',');
						// 上传图片回响
						newValue[0].devInfoImgUrl.forEach((item, index) => {
							this.logoFile.push({
								name: `图片${index}`,
								path: `${item}`,
								url: `${this.$envConfig.imgHttpAdd}${this.$envConfig.devbase}${item}`
							});
							newValue[0].devInfoImgUrlArr.push(item);
						});
						this.devBaseForm.devInfoImgUrlTempArr = newValue[0].devInfoImgUrl;
					}
					// 设备相关证照
					if (newValue[0].devExtImgUrls) {
						this.devExtImgUrlArr = newValue[0].devExtImgUrls.split(',');
						this.devExtImgUrlArr.forEach((img, index) => {
							this.extFile.push({
								name: `图片${index}`,
								path: `${img}`,
								url: `${this.$envConfig.imgHttpAdd}${this.$envConfig.devbase}${img}`
							});
						});
					}
				}
				// else {
				// this.devBaseForm.isSpecialTypeDev = 0;
				// this.devBaseForm.isMeteringDev = 0;
				// }
				this.isShowUpload();
			},
			deep: true,
			immediate: true
		},
		// 'devBaseForm.devInfoType'() {
		// 	this.devBaseForm.devInfoModel = '';
		// },

		'devBaseForm.timeArr'(newValue) {
			if (newValue && newValue.length !== 2) {
				return false;
			}
			if (newValue) {
				// eslint-disable-next-line radix
				const day = Math.ceil(
					(new Date(newValue[1]).getTime() - new Date(newValue[0]).getTime()) /
						1000 /
						3600 /
						24
				);
				this.devBaseForm.transferBatchNumber = day;
			}
		},
		'devBaseForm.devInfoDepartmentId'(curValue, oldValue) {
			if (this.editId === -1) {
				const value1 = this.findItemById(curValue, this.optionsDept);
				this.assignment(value1);
			} else {
				const value2 = this.findItemById(curValue, this.optionsDept);
				if (oldValue) {
					const value3 = this.findItemById(oldValue, this.optionsDept);
					if (this.isEmpty(this.devBaseForm)) {
						this.assignment(value2);
					} else if (this.isSameDev(value3, this.oldParams)) {
						this.assignment(value2);
					} else if (!this.isSameDev(value3, this.oldParams)) {
						this.assignment(this.oldParams);
					}
				} else if (this.isEmpty(this.devBaseForm) && this.isEmpty(value2)) {
					this.devBaseForm.inspectPeriod = '';
					this.devBaseForm.nextInspectTime = '';
					this.devBaseForm.inspectRemindDays = '';
					this.devBaseForm.upkeepPeriod = '';
					this.devBaseForm.nextUpkeepTime = '';
					this.devBaseForm.upkeepRemindDays = '';
				} else if (this.isEmpty(this.devBaseForm) && !this.isEmpty(value2)) {
					this.assignment(value2);
				}
			}
		}
		// 'devBaseForm.type68Big'() {
		// 	this.selectStatus();
		// }
		// 'devBaseForm.devInfoType'(n, o) {
		// if (o !== undefined) {
		// 	this.devBaseForm.devInfoModel = '';
		// 	this.devBaseForm.devInfoNns1 = null;
		// 	this.devBaseForm.devInfoNns2 = null;
		// }
		// }
	},
	created() {},
	mounted() {
		//  *维修公司 下拉
		this.getCompanyList();
		this.deviceTypeList();

		// 添加用户部门下拉
		this.departmentList();
		this.factoryList();
		this.unitList();
		// 大小类下拉
		this.getOptionsBigType();
		// 供应商下拉
		this.supplierList();
	},
	methods: {
		selectStatus(val) {
			console.log(val);
			this.$forceUpdate();
		},
		// 供应商下拉
		async supplierList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			const res = await supplierListService(dataJson);
			console.log('供应商下拉');
			console.log(res);
			this.supplierSelectList = res.records;
		},
		// 大类小类下拉
		async getOptionsBigType() {
			const res = await sizeClassesBigAllQueryService();
			this.optionsBigType = res.records;
		},
		changeType68Big(val) {
			this.devBaseForm.type68Small = '';
			if (val) {
				this.getOptionsSmallType(val);
			}
		},
		async getOptionsSmallType(bigId) {
			const jons = { sizeClassesId: bigId };
			const res = await sizeClassesByBigIdQueryService(jons);
			this.optionsSmallType = res;
		},
		// 展开折叠面板
		handleChangeActiveName() {
			this.activeName = ['1', '2', '3', '4'];
		},
		async unitList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000
			};
			const res = await unitListService(dataJson);
			this.unitData = [...res.records];
		},
		nodeTypeClickHandler(data) {
			if (Number(data.pid) === 0) {
				return false;
			}
			this.devBaseForm.devInfoType = data.id;
			this.devInfoTypeName = data.deviceType;

			this.devInfoChange(data.id);
		},
		async factoryList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await factoryListService(dataJson);
			this.factoryData = [...res.records];
		},
		isShowUpload() {
			this.$nextTick(() => {
				const isShowUpload = document.querySelector(
					'.isShowUpload .el-upload--picture-card'
				);
				if (this.devBaseForm.devInfoImgUrlTempArr.length === 0) {
					isShowUpload.style.display = 'block';
				} else {
					isShowUpload.style.display = 'none';
				}
			});
		},
		// 上传图片成功
		handlerLogoSuccess(response, file, fileList) {
			console.log('设备 图片上传成功');
			console.log(this.devBaseForm.devInfoImgUrlArr);
			console.log(fileList);
			this.devBaseForm.devInfoImgUrlArr.push(response.result.path);
			this.devBaseForm.devInfoImgUrlTempArr = [...fileList];
			this.devBaseForm.devInfoImgUrl = this.devBaseForm.devInfoImgUrlArr.join();
			this.isShowUpload();
			// this.devBaseForm.devInfoImgUrlTempArr = response.result;
		},
		handlerLogoRemove(file, fileList) {
			// this.devBaseForm.devInfoImgUrlTempArr.forEach((item, index) => {
			// 	if (item.path === file.path || item.path === file?.response?.result?.path) {
			// 		console.log(index);
			// 		this.devBaseForm.devInfoImgUrlArr.splice(index, 1);
			// 	}
			// });
			this.devBaseForm.devInfoImgUrlArr = [];
			fileList.forEach(item => {
				this.devBaseForm.devInfoImgUrlArr.push(item.path);
			});
			this.devBaseForm.devInfoImgUrlTempArr = [...fileList];
			this.devBaseForm.devInfoImgUrl = this.devBaseForm.devInfoImgUrlArr.join();
			console.log('移除一个图片');
			console.log(file);
			console.log(fileList);
			this.isShowUpload();
		},
		handlerExtSuccess(response) {
			this.devExtImgUrlArr.push(response.result.path);
			this.devBaseForm.devExtImgUrls = this.devExtImgUrlArr.join();
		},
		handlerExtRemove(file, fileList) {
			console.log(fileList);
			this.devExtImgUrlArr = [];
			fileList.forEach(item => {
				this.devExtImgUrlArr.push(item.path || item.response.result.path);
			});
			console.log(this.devExtImgUrlArr);
			this.devBaseForm.devExtImgUrls = this.devExtImgUrlArr.join();
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},

		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		// 选择部门
		selectDevInfo(data) {
			if (data && this.editId === -1) {
				this.assignment(data);
			}
		},
		// 赋值
		assignment(data) {
			this.devBaseForm.inspectPeriod = data.inspectPeriod;
			this.devBaseForm.nextInspectTime = data.nextInspectTime;
			this.devBaseForm.inspectRemindDays = data.inspectRemindDays;
			this.devBaseForm.upkeepPeriod = data.upkeepPeriod;
			this.devBaseForm.nextUpkeepTime = data.nextUpkeepTime;
			this.devBaseForm.upkeepRemindDays = data.upkeepRemindDays;
		},
		// 通过id找node节点
		findItemById(id, list) {
			let res = list.find(item => {
				return item.id === id;
			});
			if (res) {
				return res;
			}
			for (let i = 0; i < list.length; i++) {
				if (list[i].children instanceof Array && list[i].children.length > 0) {
					res = this.findItemById(id, list[i].children);
					if (res) {
						return res;
					}
				}
			}
			return null;
		},
		// 判断六个属性值是否相同
		isSameDev(oldData, newData) {
			return (
				newData.inspectPeriod === oldData.inspectPeriod &&
				newData.nextInspectTime === oldData.nextInspectTime &&
				newData.inspectRemindDays === oldData.inspectRemindDays &&
				newData.upkeepPeriod === oldData.upkeepPeriod &&
				newData.nextUpkeepTime === oldData.nextUpkeepTime &&
				newData.upkeepRemindDays === oldData.upkeepRemindDays
			);
		},
		// 判断属性是否为空
		isEmpty(data) {
			return (
				(!data.inspectPeriod || data.inspectPeriod === '') &&
				(!data.nextInspectTime ||
					data.nextInspectTime === '' ||
					data.nextInspectTime === 0) &&
				(!data.inspectRemindDays || data.inspectRemindDays === '') &&
				(!data.upkeepPeriod || data.upkeepPeriod === '') &&
				(!data.nextUpkeepTime || data.nextUpkeepTime === '') &&
				(!data.upkeepRemindDays ||
					data.upkeepRemindDays === '' ||
					data.upkeepRemindDays === 0)
			);
		},
		//  *维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});
			this.optionsCompany = res.records;
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
			console.log(this.deviceTypeList);
		},
		async normList(id) {
			const dataJson = {
				typeId: id
			};
			const res = await normListService(dataJson);
			this.normData = [...res];
		},
		devInfoChange(e) {
			// this.devBaseForm.devInfoModel = '请选择';
			this.devBaseForm.devInfoModel = null;
			this.devBaseForm.devInfoName = null;
			this.devBaseForm.devInfoNns1 = null;
			this.devBaseForm.devInfoNns2 = null;
			this.normList(e);
		},
		async devInfoModelChange(e) {
			if (!e) {
				this.disabled4 = false;
				return false;
			}
			this.disabled4 = true;
			this.selectStatus();
			const res = await deviceNameAllQueryService();

			res.records.forEach(item => {
				if (Number(item.id) === Number(e)) {
					this.devBaseForm.devInfoName = item.deviceName;
					this.devBaseForm.devInfoNns1 = item.deviceModel;
					this.devBaseForm.devInfoNns2 = Number(item.computeUnit);
				}
			});
		},
		handleRemove() {},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleDownload() {},

		// 清除校验 设备类别和科室
		clearValiddevInfoType() {
			this.$refs.devBaseForm.clearValidate(['devInfoType']);
		},
		clearValiddevInfoDepartmentId() {
			this.$refs.devBaseForm.clearValidate(['devInfoDepartmentId']);
		},

		devBaseFormSubmit() {
			console.log('设备提交');
			console.log(this.devBaseForm);
			this.devBaseForm.residual === undefined
				? (this.devBaseForm.residual = null)
				: this.devBaseForm.residual;
			this.devBaseForm.useyear === undefined
				? (this.devBaseForm.useyear = null)
				: this.devBaseForm.useyear;
			this.$refs.devBaseForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						this.devBaseForm.signBeginTime =
							this.devBaseForm.timeArr && this.devBaseForm.timeArr[0];
						this.devBaseForm.signEndTime =
							this.devBaseForm.timeArr && this.devBaseForm.timeArr[1];
						// this.devBaseForm.devInfoIds = [this.devBaseForm.devInfoId];
						// delete this.devBaseForm.timeArr;
						await devBaseAddService(this.devBaseForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						this.devBaseForm.signBeginTime =
							this.devBaseForm.timeArr && this.devBaseForm.timeArr[0];
						this.devBaseForm.signEndTime =
							this.devBaseForm.timeArr && this.devBaseForm.timeArr[1];
						const dataJson = {
							id: this.editId,
							...this.devBaseForm
						};
						await devBaseEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.devBaseFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		devBaseFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
/deep/ .el-collapse-item__header {
	font-weight: bold;
	font-size: 20px;
}
/deep/.el-select > .el-input {
	width: 190px;
}
/deep/ .el-input--small {
	width: 190px;
}
/deep/.textArea {
	height: 140px !important;
}
/deep/.textAreaRemakes {
	width: 100%;
}
/deep/.textAreaRemakes textarea {
	min-height: 148px !important;
}
/deep/ .el-collapse-item__arrow {
	display: none;
}
</style>
