<template>
	<div>
		<div class="search_box">
			<el-form ref="form" :model="form" label-width="80px" :inline="true">
				<el-form-item label="科室" v-if="form.type">
					<treeselect
						v-model="form.departid"
						:multiple="false"
						:options="optionsDept"
						style="width:200px;"
						:disable-branch-nodes="false"
						:clearable="false"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
					/>
				</el-form-item>
				<el-form-item label="日期">
					<el-date-picker
						v-model="form.date"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:clearable="false"
					></el-date-picker>
				</el-form-item>
				<el-button
					type="success"
					class="search_button"
					@click="searchSubmit"
					size="mini"
				>
					搜索
				</el-button>
			</el-form>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>基础数据</span>
						<el-button
							style="float: right; padding: 3px 0;opacity: 0"
							type="text"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div
						class="echart_item  textDes"
						style="width:100%; !important"
					>
						<div class="echart_box">
							<h2>
								<span class="title">设备价格:</span>
								<span class="value">
									{{ basePriceCount.devprice | addCommas }}
								</span>
							</h2>
							<h2>
								<span class="title">设备收入:</span>
								<span class="value">
									{{ basePriceCount.price | addCommas }}
								</span>
							</h2>
							<h2>
								<span class="title">利润:</span>
								<span class="value">
									{{ basePriceCount.profit | addCommas }}
								</span>
							</h2>
							<h2>
								<span class="title">维护费用:</span>
								<span class="value">
									{{ basePriceCount.repairprice | addCommas }}
								</span>
							</h2>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>维修费用、利润占比</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts1"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备收入</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExceldevearning({
									index: 2,
									title: '设备收入、设备维修费用',
									options: options2
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(2)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts2"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备维修费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExceldevearning({
									index: 3,
									title: '设备收入、设备维修费用',
									options: options3
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(3)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts3"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
// eslint-disable-next-line import/no-cycle
import {
	allHospitalRepairService,
	workerpriceService,
	allHospitalDevicePriceService,
	repairCntPriceByMonthService,
	xjbypriceService,
	systemBasePriceService
} from '@s/hospital/StatisticsService';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
import { allDevearningMonthService } from '@s/hospital/DevearningService';

import Dialog from '@c/ui/Dialog';
// eslint-disable-next-line import/no-cycle
import Treeselect from '@riophae/vue-treeselect';
import { htimeFormat, hgetStorage, hdateInMonth, add } from '@u/htools.web';
import * as math from 'mathjs';

export default {
	props: ['queryP'],
	components: {
		Dialog,
		Treeselect
	},
	data() {
		return {
			dialogTitle: '',
			basePriceCount: {
				devprice: 0,
				price: 0,
				repairprice: 0,
				profit: 0
			},
			optionsCompany: [],
			form: {
				date: null,
				hosid: null,
				orgid: null
			},
			optionsDept: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			},
			dateType: 2, // 月
			dateEndType: 2, // 月
			dateEndTypeSigntime: 2, // 月
			options1: {
				// title: {
				// 	text: '整体服务费1'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}元`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						label: {
							formatter: '{b}: {@2012} ({d}%)'
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options2: {
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						// return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
						arg.forEach(item => {
							item.data = item.data.toFixed(2);
							item.dataArr = item.data.split('.');
						});
						let str0 = `<div class="ownTooltip">${arg[0].axisValueLabel}<br/>`;
						arg.forEach(item => {
							str0 += `<p class="itemWrap">
								<span class="legendRound" style="background:${item.color}"></span>
								<span class="key">${item.seriesName}</span>
								<span class="value"><span class="dataArr0">${item.dataArr[0]}</span>.<span class="dataArr1">${item.dataArr[1]}</span></span></p>`;
						});
						str0 += '</div>';
						return str0;
					},
					axisPointer: {
						// Use axis to trigger tooltip
						type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					}
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '设备收入',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [150, 212, 201, 154, 190, 330, 410]
					}
				]
			},
			options3: {
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						// return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
						arg.forEach(item => {
							item.data = item.data.toFixed(2);
							item.dataArr = item.data.split('.');
						});
						let str0 = `<div class="ownTooltip">${arg[0].axisValueLabel}<br/>`;
						arg.forEach(item => {
							str0 += `<p class="itemWrap">
								<span class="legendRound" style="background:${item.color}"></span>
								<span class="key">${item.seriesName}</span>
								<span class="value"><span class="dataArr0">${item.dataArr[0]}</span>.<span class="dataArr1">${item.dataArr[1]}</span></span></p>`;
						});
						str0 += '</div>';
						return str0;
					},
					axisPointer: {
						// Use axis to trigger tooltip
						type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					}
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '维修费用',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [150, 212, 201, 154, 190, 330, 410]
					}
				]
			},
			isShowBigEcharts: false
		};
	},
	async mounted() {
		await this.departmentList();
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.form.date = [
			`${curYear}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];
		this.form = { ...this.form, ...this.queryP };

		console.log('this.form');
		console.log(this.form);

		if (this.form.type == 'dept' && this.form.departid) {
			// 效益分析
			this.getDevearningData();
		}
		if (this.form.type != 'dept') {
			// 效益分析
			this.getDevearningData();
		}
	},
	methods: {
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 科室下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		initData() {
			this.basePriceCount = {
				devprice: 0,
				price: 0,
				repairprice: 0,
				profit: 0
			};
		},
		// 效益分析
		async getDevearningData() {
			this.initData();
			const dataJson = {
				...this.form,
				begin: htimeFormat(this.form.date[0]),
				end: htimeFormat(this.form.date[1]),
				...this.queryP
			};
			const res = await allDevearningMonthService(dataJson);
			
			res.sort((a, b) => {
				return a.bd > b.bd ? 1 : -1;
			});
			this.tableDownDatadevearning = res || [];
			this.getBaseData(res);
			this.echartsInit2(res);
			this.echartsInit3(res);
		},
		// 计算基础数据
		getBaseData(res) {
			let devprice = 0;
			let price = 0;
			let repairprice = 0;
			if (res && res.length > 0) {
				devprice = res[0].devprice || 0;
				price = res.reduce((prev, cur) => {
					return prev + cur.price;
				}, 0);
				repairprice = res.reduce((prev, cur) => {
					return prev + cur.repairprice;
				}, 0);
				this.basePriceCount.devprice = devprice.toFixed(2);
				this.basePriceCount.price = price.toFixed(2);
				this.basePriceCount.repairprice = repairprice.toFixed(2);
				this.basePriceCount.profit = (price - repairprice).toFixed(2);
			}
			this.echartsInit1();
		},
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '维修费用、利润占比';
					this.myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '设备收入';
					// delete this.options2.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '设备维修费用';
					// delete this.options2.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options3);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
		},
		async searchSubmit() {
			// 效益分析
			this.getDevearningData();
		},

		echartsInit1() {
			this.options1.series[0].data = [];

			const repairprice = this.basePriceCount.repairprice || 0;
			const profit = this.basePriceCount.profit || 0;

			this.options1.series[0].data.push(
				{ value: Number(repairprice), name: '维修费用' },
				{ value: Number(profit), name: '利润' }
			);
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			const xAxisArr = [];
			// 设备价格
			const devprice = [];
			// 收入
			const price = [];
			// 维修费用
			const repairprice = [];

			res.forEach(item => {
				xAxisArr.push(item.bd);
				devprice.push(item.devprice);
				price.push(item.price);
				repairprice.push(item.repairprice);
			});

			this.options2.xAxis.data = xAxisArr;
			this.options2.series[0].data = price;

			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			const xAxisArr = [];
			// 设备价格
			const devprice = [];
			// 收入
			const price = [];
			// 维修费用
			const repairprice = [];

			res.forEach(item => {
				xAxisArr.push(item.bd);
				devprice.push(item.devprice);
				price.push(item.price);
				repairprice.push(item.repairprice);
			});

			this.options3.xAxis.data = xAxisArr;
			this.options3.series[0].data = repairprice;

			const chartDom = document.getElementById('echarts3');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExceldevearning(obj) {
			const tHeader = ['日期', '设备收入', '维修费用'];
			const filterVal = ['bd', 'price', 'repairprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDatadevearning
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备收入、设备维修费用${htimeFormat(
						new Date()
					)}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	},
	filters: {
		addCommas(value) {
			const parts = value.toString().split('.');
			parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
			return parts.join('.');
		}
	}
};
</script>

<style scoped>
.textDes {
}
.textDes .echart_box {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}
.textDes .echart_box h2 {
	color: rgb(2, 167, 240);
	width: 50%;
	display: flex;
	flex-direction: column;
	text-align: center;
}
.textDes .echart_box h2 .title {
	text-align: right;
	color: #999999;
}
.textDes .echart_box h2 .value {
	font-size: 0.52rem;
	text-align: right;
}

.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}

.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}

.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 80%;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	width: 100%;
	height: 360px;
}

.search_button {
	margin-left: 10px;
}

.el-col {
	margin-bottom: 20px;
}

/deep/.ownTooltip {
}
/deep/.ownTooltip .itemWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/deep/.ownTooltip .itemWrap .legendRound {
	width: 0.1rem;
	height: 0.1rem;
	border-radius: 50%;
	display: inline-block;
}
/deep/.ownTooltip .itemWrap .key {
	margin-left: 0.02rem;
	margin-right: 10px;
}
/deep/.ownTooltip .itemWrap .value {
	flex: 1;
	text-align: right;
}
/deep/.ownTooltip .itemWrap .dataArr0 {
	font-size: 0.18rem;
}
/deep/.ownTooltip .itemWrap .dataArr2 {
	font-size: 0.16rem;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
