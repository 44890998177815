<template>
	<div>
		<AllHospitalDevearning :queryP="{ devid: $route.query.devid }"></AllHospitalDevearning>
	</div>
</template>

<script>
import AllHospitalDevearning from './components/AllHospitalDevearning';

export default {
	components: {
		AllHospitalDevearning
	},
	data() {
		return {};
	},
	mounted() {},
	methods: {}
};
</script>

<style lang="less" scoped></style>
