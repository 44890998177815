<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form ref="formEle" :inline="true" :model="formData" :rules="formRules" size="small" label-width="80px" :disabled="isShowDetail_p">
          <el-form-item label="库房" prop="warehouseId">
            <el-select v-model="formData.warehouseId" filterable clearable @change="warehouseChangHandler">
              <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间" prop="requestTime">
            <el-date-picker v-model="formData.requestTime" type="datetime" placeholder="请选择申请时间" @change="requestTimeChangHandler" value="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="单号" prop="code">
            <el-input v-model="formData.code" placeholder="请输入单号" clearable />
          </el-form-item>
          <br />
          <el-form-item label="是否开票">
            <el-switch v-model="formData.kaipiao"></el-switch>
          </el-form-item>
          <el-form-item label="是否付款">
            <el-switch v-model="formData.fukuan"></el-switch>
          </el-form-item>
          <el-form-item label="是否申请调拨" label-width="120px">
            <el-switch v-model="formData.doDB"></el-switch>
          </el-form-item>
          <el-form-item label="是否申请销售" label-width="120px">
            <el-switch v-model="formData.doSell"></el-switch>
          </el-form-item>
          <el-form-item v-if="!isShowDetail_p">
            <el-button :disabled="isAddMetarial" type="primary" @click="addDetailHandler">
              新增物料
            </el-button>
          </el-form-item>
          <el-form-item v-if="state === 2">
            <span style="color: #f56c6c">拒绝原因：{{ reason }}</span>
          </el-form-item>
          <el-table ref="tableDom" :data="tableData" border>
            <el-table-column label="物料" prop="goodsId" width="200">
              <template slot-scope="scope">
                <div v-if="scope.row.isAddDetail" style="display:flex;">
                  <el-select v-model="scope.row.goodsId" filterable class="fastChooseGood" @keyup.native="getGoodsVal($event)" @change="changeGood">
                    <el-option v-for="(item, index) in materialData" :key="index" :label="item.name" :value="item.id"></el-option>
                    <!-- <p slot="empty" class="faseAddGood">
                      没有您想要的物料，
                      <span @click="showFastAddGood">点此快速添加</span>
                    </p> -->
                  </el-select>
                  <!-- v-if="isShowGoodBtn" 地址栏flag=1显示-->
                  <el-button type="default" @click="showGoodDialog(scope.row)">
                    更多
                  </el-button>
                </div>
                <MaterialTooltip v-else :materialObjP="{
										goodsId: scope.row.goodsId,
										goodsName: scope.row.goodsName
									}"></MaterialTooltip>
              </template>
            </el-table-column>
            <el-table-column label="供应商" prop="supplierId">
              <template slot-scope="scope">
                <el-select v-if="scope.row.isAddDetail" v-model="scope.row.supplierId" filterable class="fastChooseSupplier" @keyup.native="getSupplierVal($event)">
                  <el-option v-for="(item, index) in supplierData" :key="index" :label="item.name" :value="item.id"></el-option>
                  <p slot="empty" class="faseAddGood">
                    <span @click="showFastAddSupplier">快速添加供应商</span>
                  </p>
                </el-select>
                <el-popover placement="top-start" title="供应商详情" width="200" trigger="click" @show="supplierDetail(scope.row)" v-else>
                  <ul>
                    <li>
                      <span class="supplierKey">名称</span>
                      ：{{ supplierDetailData.name }}
                    </li>
                    <li>
                      <span class="supplierKey">电话</span>
                      ：{{ supplierDetailData.telphone }}
                    </li>
                    <li>
                      <span class="supplierKey">联系人</span>
                      ：{{ supplierDetailData.contract }}
                    </li>
                    <li>
                      <span class="supplierKey">银行账号</span>
                      ：{{ supplierDetailData.bankcode }}
                    </li>
                    <li>
                      <span class="supplierKey">开户行</span>
                      ：{{ supplierDetailData.bank }}
                    </li>
                    <li>
                      <span class="supplierKey">地址</span>
                      ：{{ supplierDetailData.address }}
                    </li>
                  </ul>
                  <span slot="reference">{{ scope.row.supplierName }}</span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="科室" prop="departname">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.departname" placeholder="请输入科室"></el-input>
                <span v-else>{{ scope.row.departname }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="cnt">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.cnt" @blur="priceAndcntHandler(scope.row)" type="number" placeholder="请输入数量"></el-input>
                <span v-else>
                  <span v-if="scope.row.donecnt == scope.row.cnt" style="color:#67C23A; cursor:pointer;">
                    <span title="完成数量">{{ scope.row.donecnt || 0 }}</span>
                    /
                    <span title="申请数量">{{ scope.row.cnt }}</span>
                  </span>
                  <span v-else style="color:#E6A23C;cursor:pointer;">
                    <span title="完成数量">{{ scope.row.donecnt || 0 }}</span>
                    /
                    <span title="申请数量">{{ scope.row.cnt }}</span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="单价" prop="price">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.price" @blur="priceAndcntHandler(scope.row)" type="number" placeholder="请输入单价"></el-input>
                <span v-else>{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总价" prop="totalMoney" width="90"></el-table-column>
            <el-table-column label="预计销售单价" prop="willsell">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.willsell" type="number" placeholder="请输入预计销售单价"></el-input>
                <span v-else>{{ scope.row.willsell }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="memo">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.memo" placeholder="请输入备注"></el-input>
                <span v-else>{{ scope.row.memo }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="!isShowDetail_p" label="操作" align="left" fixed="right" width="150">
              <template slot-scope="scope">
                <el-button id="btn-update-row" type="primary" size="mini" @click="saveDetailHandler(scope.row)" v-if="scope.row.isAddDetail">
                  保存
                </el-button>
                <el-button id="btn-update-row" type="primary" size="mini" @click="editDetailHandler(scope.row)" v-if="!scope.row.isAddDetail">
                  编辑
                </el-button>
                <el-button id="btn-remove-row" type="danger" size="mini" @click="removeDetailHandler(scope)" :disabled="scope.row.donecnt > 0" :title="scope.row.donecnt > 0 ? '已执行入库的物料不能删除' : ''">
                  删除
                </el-button>
              </template>
              <!-- <template slot-scope="scope">
                <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'purchaseApplication', id: 'btn-update-row' }" @click="saveDetailHandler(scope.row)" v-if="scope.row.isAddDetail">保存</el-button>
                <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'purchaseApplication', id: 'btn-update-row' }" @click="editDetailHandler(scope.row)" v-if="!scope.row.isAddDetail">编辑</el-button>
                <el-button id="btn-remove-row" type="danger" size="mini" v-authorize="{ name: 'remove', type: 'purchaseApplication', id: 'btn-remove-row' }" @click="removeDetailHandler(scope)">删除</el-button>
              </template> -->
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
      <el-col :span="24" style="text-align: right; margin-top: 10px">
        <span class="dialog-footer" style="text-align: right">
          <template v-if="editId !== -1">
            <el-button @click="ShowAEDialogFastcurrentStock" type="primary" :disabled="currentStockDisabled">
              一键入库
            </el-button>
            <el-button @click="ShowAEDialogFasttransfer" type="warning">
              一键生成调拨单
            </el-button>
            <el-button @click="ShowAEDialogFastsale" type="success">
              一键生成销售单
            </el-button>
            <!-- <el-button type="success" :disabled="isAddMetarial" @click="formSubmit({type:'sale'})">一键生成销售单</el-button> -->
          </template>
          <el-button @click="formCancel">取 消</el-button>
          <el-button type="primary" :disabled="isAddMetarial" @click="formSubmit({ type: 'purchase' })">
            确 定
          </el-button>
        </span>
      </el-col>
    </el-row>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
      <MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" @isToParentGoodsNmae_p="materialList" :selectData_p="selectData" :isFastAddGood_p.sync="isFastAddGood"></MaterialAddForm>
    </Dialog>
    <!-- 快速添加供应商 -->
    <Dialog :title="dialogTitleFastAddSupplierTitle" :visible.sync="isShowFastAddSupplier">
      <SupplierAddForm v-if="isShowFastAddSupplier" :isShowAEDialog_p.sync="isShowFastAddSupplier" :isRefreshFastAddSupplier_p.sync="isRefreshFastAddSupplier" @isToParentSupplierNmae_p="supplierList" :selectData_p="selectData"></SupplierAddForm>
    </Dialog>
    <!-- TODELETE 一键快速调拨单 -->
    <Dialog :title="dialogTitleFasttransfer" :visible.sync="isShowAEDialogFasttransfer" width="70%">
      <TransferAddForm1 v-if="isShowAEDialogFasttransfer" :isShowAEDialog_p.sync="isShowAEDialogFasttransfer" :selectDataFasttransfer_p="selectDataFasttransfer" :isShowDetail_p="false"></TransferAddForm1>
    </Dialog>
    <!-- TODELETE 一键快速销售单 -->
    <Dialog :title="dialogTitleFastsale" :visible.sync="isShowAEDialogFastsale" width="70%">
      <SaleApplicationAddFormPurchase v-if="isShowAEDialogFastsale" :isShowAEDialog_p.sync="isShowAEDialogFastsale" :selectDataFastsale_p="selectDataFastsale" :isShowDetail_p="false"></SaleApplicationAddFormPurchase>
    </Dialog>
    <Dialog title="一键入库" :visible.sync="isShowAEDialogFastcurrentStock" width="85%">
      <CurrentStockAddForm v-if="isShowAEDialogFastcurrentStock" :isShowAEDialog_p.sync="isShowAEDialogFastcurrentStock" :isRefreshListPurchase_p.sync="isRefreshListPurchase" :selectDataFastcurrentStock_p="selectDataFastcurrentStock"></CurrentStockAddForm>
    </Dialog>

    <!-- // 关联文档 文档所有列表数据 -->
    <Dialog title="物料选择" :visible.sync="isShowGoodDialog" width="70%" custom-class="dialogDoc dialogCom3" :modal-append-to-body="false">
      <MaterialChoose v-if="isShowGoodDialog" @getChooseGood="getChooseGood" :tableDataP="tableData" :queryInfoGoodP="{enable:true}"></MaterialChoose>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	purchaseApplicationAddService,
	purchaseApplicationEditService,
	purchaseApplicationMaterialAddService,
	purchaseApplicationMaterialDeleteService,
	purchaseApplicationMaterialEditService,
	purchaseGetRequestCntService
} from '@s/spareParts/PurchaseApplicationService';
// eslint-disable-next-line import/no-cycle
import {
	materialAllListService,
	materialDetailService,
	materialListService as goodListService
} from '@s/spareParts/MaterialService';
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService, supplierDetailService } from '@s/spareParts/SupplierService';
// eslint-disable-next-line import/no-cycle
import { htimeFormat } from '@u/htools.web';
import MaterialChoose from '@/components/MaterialChoose';
import MaterialTooltip from '@/components//MaterialTooltip';
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import SupplierAddForm from '@f/spareParts/supplier/SupplierAdd.form';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import TransferAddForm1 from '@f/spareParts/transfer/TransferAdd.form';
import SaleApplicationAddFormPurchase from '@f/spareParts/saleApplication/SaleApplicationAdd.form';
import CurrentStockAddForm from '@f/spareParts/currentStock/CurrentStockAdd.form';
import * as math from 'mathjs';

export default {
	props: ['selectData_p', 'isShowDetail_p', 'selectDataFastpurchase_p'],
	mixins: [ListMixin],
	components: {
		Dialog,
		MaterialAddForm,
		SupplierAddForm,
		TransferAddForm1,
		SaleApplicationAddFormPurchase,
		CurrentStockAddForm,
		MaterialChoose,
		MaterialTooltip
	},
	data() {
		return {
			selectData: [],
			selectData1: [],
			// TODELETE
			isFastAddGood: true,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			warehouseData: [],
			// 弹窗
			formData: {
				warehouseId: '',
				requestTime: '',
				code: '',
				kaipiao: false,
				fukuan: false,
				doDB: false,
				doSell: false
			},
			currentStockDisabled: false,
			formDataOld: {},
			formRules: {
				warehouseId: [
					{
						required: true,
						message: '请选择库房',
						trigger: 'blur'
					}
				],
				requestTime: [
					{
						required: true,
						message: '请选择申请时间',
						trigger: 'blur'
					}
				],
				code: [
					{
						required: true,
						message: '请输入单号',
						trigger: 'blur'
					}
				]
			},
			supplierData: [],
			materialData: [],
			reason: '',
			state: '',
			materialDetailData: {},
			supplierDetailData: {},

			selectDataFasttransfer: [],
			selectDataFastsale: [],
			// <!-- TODELETE 一键快速调拨单 -->
			isShowAEDialogFasttransfer: false,
			// 一键入库 快速
			selectDataFastcurrentStock: [],
			isShowAEDialogFastcurrentStock: false,
			isShowAEDialogFastsale: false,
			isRefreshListPurchase: false,
			// 快速添加供应商
			isShowFastAddSupplier: false,
			isRefreshFastAddSupplier: false,
			// 新的物料添加
			isShowGoodDialog: false,
			isShowGoodBtn: false
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.selectDataFasttransfer = [JSON.parse(JSON.stringify(newValue[0]))];
					this.selectDataFastsale = [JSON.parse(JSON.stringify(newValue[0]))];
					this.selectDataFastcurrentStock = [JSON.parse(JSON.stringify(newValue[0]))];
					this.currentStockDisabled = this.selectDataFastcurrentStock[0]?.items.every(
						item => {
							return item.donecnt == item.cnt;
						}
					);
					this.editId = newValue[0].id;
					this.supplierData.length === 0 && (await this.supplierList());
					this.materialData.length === 0 && (await this.materialList());
					this.reason = newValue[0].auditorReason;
					this.state = newValue[0].state;
					this.tableData = [];
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0];
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name || item.goodsName,
							cnt: item.cnt,
							donecnt: item.donecnt,
							id: item.id,
							memo: item.memo,
							price: item.price,
							departname: item.departname,
							willsell: item.willsell,
							totalMoney: math.format(
								math.multiply(math.bignumber(item.cnt), math.bignumber(item.price))
							),
							requestId: '',
							supplierId: item.supplierId,
							supplierName: supplierItem?.name,
							isAddDetail: false
						});
					});
					newValue[0].requestTime = htimeFormat(newValue[0].requestTime);
					this.formData = { ...newValue[0] };

					this.formDataOld = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		selectDataFastpurchase_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.selectDataFasttransfer = [JSON.parse(JSON.stringify(newValue[0]))];
					this.selectDataFastsale = [JSON.parse(JSON.stringify(newValue[0]))];
					this.selectDataFastcurrentStock = [JSON.parse(JSON.stringify(newValue[0]))];
					this.currentStockDisabled = this.selectDataFastcurrentStock[0]?.items.every(
						item => {
							return item.donecnt == item.cnt;
						}
					);
					this.supplierData.length === 0 && (await this.supplierList());
					this.materialData.length === 0 && (await this.materialList());
					this.reason = newValue[0].auditorReason;
					this.state = newValue[0].state;
					this.tableData = [];
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0];
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name || item.goodsName,
							cnt: item.cnt,
							id: item.id,
							memo: item.memo,
							price: item.price,
							departname: item.departname,
							willsell: item.willsell,
							totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
							requestId: '',
							supplierId: item.supplierId,
							supplierName: supplierItem?.name,
							isAddDetail: false
						});
					});
					newValue[0].requestTime = htimeFormat(newValue[0].requestTime);
					// this.formData = { ...newValue[0] };
					// this.formDataOld = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshListPurchase(newValue) {
			if (newValue) {
				this.$emit('update:isShowAEDialog_p', false);
				this.$emit('update:isRefreshList_p', true);
			}
		},
		isRefreshFastAddSupplier(newValue) {
			if (newValue) {
				this.supplierList();
			}
		}
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		},
		// <!-- TODELETE 一键快速调拨单 -->
		dialogTitleFasttransfer() {
			return '添加调拨';
		},
		dialogTitleFastsale() {
			return '添加销售';
		},
		dialogTitleFastAddSupplierTitle() {
			return '添加供应商';
		}
	},
	created() {
		if (this.$route.query.flag == 1) {
			this.isShowGoodBtn = true;
		} else {
			this.isShowGoodBtn = false;
		}
		this.supplierList();
		this.materialList();
		this.warehouseonlymyself();
	},
	methods: {
		// 快速生成调拨单
		ShowAEDialogFasttransfer() {
			// const items = [];
			// this.selectDataFasttransfer[0].items?.forEach(item => {
			// 	items.push({
			// 		goodsId: item.goodsId,
			// 		cnt: item.cnt,
			// 		id: item.id,
			// 		memo: item.memo,
			// 		price: item.price,
			// 		totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
			// 		requestId: '',
			// 		isAddDetail: false
			// 	});
			// });
			// const dataJson = {
			// 	fromWarehouseId: this.selectDataFasttransfer[0].warehouseId,
			// 	items: items
			// };
			// hremoveStorage('fastSaleOrPurchase');
			// hsetStorage('fastSaleOrPurchase', dataJson);
			// this.formCancel();
			// this.$router.push({
			// 	path: '/spareParts/transfer',
			// 	query: { type: 'fastSaleOrPurchase' }
			// });
			this.isShowAEDialogFasttransfer = true;
		},
		ShowAEDialogFastcurrentStock() {
			this.isShowAEDialogFastcurrentStock = true;
		},
		// 快速生成调拨单
		ShowAEDialogFastsale() {
			this.isShowAEDialogFastsale = true;
		},

		// 获取仓库
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 改变仓库时间 设置相关单号
		warehouseChangHandler() {
			this.getRequestCnt();
		},
		// 改变仓库时间 设置相关单号
		requestTimeChangHandler() {
			this.getRequestCnt();
		},
		// 设置相关单号
		async getRequestCnt() {
			const { warehouseId, requestTime } = this.formData;
			const json = {
				warehouseId,
				date: requestTime
			};
			if (warehouseId && requestTime) {
				const res = await purchaseGetRequestCntService(json);
				this.setformDataCode(res);
			} else {
				this.formData.code = null;
			}
		},
		// 设置相关单号 B-code-yyyymm-0005  S-code-yyyymm-0012
		setformDataCode(cnt) {
			let code = 0;

			this.warehouseData.forEach(item => {
				if (Number(item.id) === Number(this.formData.warehouseId)) {
					code = item.code;
				}
			});
			// 编辑时修改后改回原来的仓库日期  数量不加一 赋默认初始修改值
			if (
				this.formData.warehouseId === this.formDataOld.warehouseId &&
				this.formData.requestTime === this.formDataOld.requestTime
			) {
				this.formData.code = this.formDataOld.code;
				return;
			}
			// 旧的相关单号不做操作
			if (this.formData.code && this.formData.code.indexOf('-20') === -1) {
				return;
			}

			// 不足4位补0
			if (cnt < 9) {
				cnt = `000${cnt + 1}`;
			} else if (cnt < 99) {
				cnt = `00${cnt + 1}`;
			} else if (cnt < 999) {
				cnt = `0${cnt + 1}`;
			}
			// 截取日期的年月
			const date = htimeFormat(this.formData.requestTime, '{y}{m}');
			// console.log(cnt, date, code);

			this.formData.code = `B-${code}-${date}-${cnt}`;
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				price: '',
				willsell: '',
				totalMoney: '',
				departname: '',
				requestId: '',
				supplierId: '',
				supplierName: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			console.log('row, goodsId');
			console.log(row, goodsId);
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name;
		},
		saveDetailHandler(row) {
			if (!row.goodsId || !row.supplierId || row.cnt === '' || row.price === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt < 0 || row.price < 0) {
				this.$message.warning('请输入正确的数量和价格');
				return false;
			}
			if (Number(row.donecnt) > Number(row.cnt)) {
				this.$message.warning(`已入库${row.donecnt}个，不能修改数量小于已入库数量`);
				return false;
			}

			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						price: Number(row.price),
						departname: row.departname,
						willsell: Number(row.willsell),
						requestId: this.editId,
						supplierId: Number(row.supplierId)
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						price: Number(row.price),
						departname: row.departname,
						willsell: Number(row.willsell),
						requestId: this.editId,
						supplierId: Number(row.supplierId)
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		priceAndcntHandler(row) {
			if (row.cnt) {
				row.totalMoney = math.format(
					math.multiply(math.bignumber(row.cnt), math.bignumber(row.price || 0))
				);
			} else {
				row.totalMoney = 0;
			}
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			let goodsId = null;
			const dataJson = {
				enable: true
			};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						goodsId = item.id;
					}
				});
			goodsName &&
				this.tableData.forEach(item => {
					if (item.isAddDetail) {
						item.goodsId = goodsId;
					}
				});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			console.log(res);
			res.pic &&
				(res.picStr = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${res.pic}`);
			if (res.tags != null && res.tags?.length > 0) {
				res.tagsArr = res.tags.split(',');
			} else {
				res.tagsArr = [];
			}

			console.log(res);
			this.materialDetailData = res;
		},
		async supplierDetail(row) {
			console.log(row);
			const dataJson = {
				id: row.supplierId
			};
			const res = await supplierDetailService(dataJson);
			console.log(res);
			this.supplierDetailData = res;
		},
		async supplierList(supplierName) {
			// supplierName 子组件新加的文字label
			let supplierId = null;
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
			supplierName &&
				this.supplierData.forEach(item => {
					if (item.name === supplierName) {
						supplierId = item.id;
					}
				});
			supplierName &&
				this.tableData.forEach(item => {
					if (item.isAddDetail) {
						item.supplierId = supplierId;
					}
				});
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await purchaseApplicationMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await purchaseApplicationMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await purchaseApplicationMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		formSubmit(e) {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (!this.isShowDetail_p) {
						// 采购销售 俩边快速一键切换新增
						const { type } = e;
						this.formData.items = [];
						this.formData.requestTime = htimeFormat(this.formData.requestTime);
						this.tableData.forEach(item => {
							this.formData.items.push({
								cnt: item.cnt,
								goodsId: item.goodsId,
								memo: item.memo,
								price: Number(item.price),
								departname: item.departname,
								willsell: Number(item.willsell),
								supplierId: item.supplierId
							});
						});
						if (this.editId === -1) {
							await purchaseApplicationAddService(this.formData);
							this.$emit('update:isRefreshList_p', true);
						} else {
							const dataJson = {
								id: this.editId,
								warehouseId: this.formData.warehouseId,
								requestTime: htimeFormat(this.formData.requestTime),
								code: this.formData.code,
								...this.formData
							};
							console.log(dataJson);
							await purchaseApplicationEditService(dataJson);
							this.$emit('update:isRefreshList_p', true);
						}
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		},
		// 快速添加物料
		showFastAddGood() {
			this.isShowAEDialog = true;
			this.selectData = [];
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || '',
				id: -1
			});
		},
		// 新增物料 时 不能选择已经选择过的
		changeGood(e) {
			let flag = false;
			this.tableData.forEach(item => {
				if (item.goodsId == e && item.isAddDetail == false) {
					flag = true;
				}
			});
			this.tableData.forEach(item => {
				if (item.isAddDetail && flag) {
					this.$message.warning('不能选择已经添加过的物料！');
					item.goodsId = null;
					flag = false;
				}
			});
		},
		// 快速添加供应商
		showFastAddSupplier() {
			this.isShowFastAddSupplier = true;
			this.selectData = [];
			this.selectData = this.selectData1;
		},
		getSupplierVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseSupplier input')?.value || '',
				id: -1
			});
		},
		showGoodDialog(row) {
			console.log('row');
			console.log(row);
			this.isShowGoodDialog = true;
		},
		getChooseGood(row) {
			console.log(row);
			console.log(this.tableData);
			this.isShowGoodDialog = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					item.goodsId = row.id;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}

.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}

.supplierKey {
	min-width: 60px;
	display: inline-block;
	text-align: justify;
	text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
	text-align-last: justify;
}
/deep/ .dialogCom3 {
	min-height: 800px !important;
	height: 800px !important;
	position: relative !important;
}
/deep/ .el-table__body-wrapper {
	max-height: 500px !important;
	overflow-y: auto !important;
}
</style>
