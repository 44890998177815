import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 文档列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54
 */
export const docListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				title: '',
				typeid: '',
				...data
			};
			const res = await hpostRequest('hbtyong_2/docs/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const docAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				files: '',
				title: '',
				typeid: 0,
				filename: '',
				...data
			};
			const res = await hpostRequest('hbtyong_2/docs/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const docEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				files: '',
				filename: '',
				title: '',
				typeid: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_2/docs/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const docDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hbtyong_2/docs/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const docDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hgetRequest('hbtyong_2/docs/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 查询 文档 设备 关联
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const selectfromdocdevService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest(
				`hbtyong_5/devdoc/selectfromdev?&devid=${dataJson.devid}`,
				{}
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 添加 文档 设备 关联
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const adddoc2devService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devdoc/add2dev?docid=${dataJson.id}&devid=${dataJson.devid}`,
				{}
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除 文档 设备 关联
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const deletefromdevdevdocService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/devdoc/deletefromdev?docid=${dataJson.id}&devid=${dataJson.devid}`,
				{}
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default docListService;
