<template>
	<el-row :gutter="30">
		<el-col :span="24">
			<el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px" :inline="true">
				<el-form-item label="出入库类型" prop="inoutType">
					<el-select v-model="formData.inoutType" @change="issueReceiptChangeHandler" clearable
						placeholder="请选择出入库类型" style="width:100%">
						<el-option v-for="(item, index) in issueReceiptTypeData" :key="index" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="相关单号" prop="aboutId">
					<el-select v-model="formData.aboutId" @change="aboutIdChange" clearable placeholder="请选择相关单号"
						style="width:100%">
						<el-option v-for="(item, index) in absoutNumberData" :key="index" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="批次号" prop="batchCode">
					<el-input v-model="formData.batchCode" clearable placeholder="请输入批次号" />
				</el-form-item>
				<el-form-item label="库房" prop="warehouseId">
					<el-select v-model="formData.warehouseId" @change="warehouseChangeHandler" clearable placeholder="请选择库房"
						style="width:100%">
						<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button v-show="formData.inoutType == 1" type="primary" @click="autoFillIn">自动填充</el-button>
				</el-form-item>
				<el-form-item>
					<el-button v-show="formData.inoutType == 10" type="primary" @click="autoFillInBorrow">一键添加</el-button>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="12">
			<el-divider content-position="left">待入库物料</el-divider>
			<div style="margin-bottom: 20px;height:400px;overflow-y:auto;">
				<el-form ref="tableForm" :model="tableFormData" size="small" label-width="0">
					<el-row v-for="(item, index) in tableFormData.tableData" :key="index" :gutter="10" style="margin:0">
						<el-col :span="5">
							<el-form-item prop="goodsName">
								<el-popover placement="top-start" title="物料详情" width="200" trigger="click"
									@show="materialDetail(item.goodsId)">
									<ul>
										<li>编码：{{ materialDetailData.code }}</li>
										<li>名称：{{ materialDetailData.name }}</li>
										<li>分类：{{ materialDetailData.typeName }}</li>
										<li>厂家：{{ materialDetailData.factory }}</li>
										<li>规格：{{ materialDetailData.spec }}</li>
										<li>型号：{{ materialDetailData.goodsVer }}</li>
										<li>标签：{{ materialDetailData.tags }}</li>
									</ul>
									<el-input slot="reference" v-model="item.goodsName" disabled />
								</el-popover>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item :prop="'tableData.' + index + '.cnt'"
								:rules="{ required: true, message: '数量不能为空' }">
								<el-input v-model.number="item.cnt" placeholder="请输入数量">
									<template slot="append">个</template>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item :prop="'tableData.' + index + '.price'"
								:rules="{ required: true, message: '价格不能为空' }">
								<el-input v-model.number="item.price" placeholder="请输入价格">
									<template slot="append">元</template>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item :prop="'tableData.' + index + '.positionId'" :rules="positionIdRules">
								<el-select v-model="item.positionId" filterable
									@visible-change="changePosition($event, item)">
									<el-option v-for="(item, index) in wareareaData" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item>
								<!-- <el-button id="btn-remove-row" type="danger" size="mini" v-authorize="{ name: 'remove', type: 'currentStock', id: 'btn-remove-row' }" @click="removeDetailHandler(item, index)"> -->
								<el-button id="btn-remove-row" type="danger" size="mini"
									@click="removeDetailHandler(item, index)">
									删除
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</el-col>
		<el-col :span="12">
			<el-divider content-position="left">物料查询</el-divider>
			<el-row>
				<el-form ref="materialFormRef" :model="materialForm" size="small" label-width="0" :inline="true"
					@submit.native.prevent @keyup.enter.native="getMaterialListPage('search')">
					<el-form-item>
						<el-input v-model="materialForm.name" clearable placeholder="请输入物料名称" />
					</el-form-item>
					<el-form-item>
						<el-button type="success" @click="showFastAddGood"
							v-if="tableDataPage.length == 0">快速添加物料</el-button>
						<el-button type="primary" @click="getMaterialListPage('search')">搜 索</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<div style="margin-bottom: 20px">
				<el-table ref="tableDom" :data="tableDataPage" border height="390">
					<el-table-column label="物料名称" prop="goodsName" />
					<el-table-column label="图片">
						<template slot-scope="scope">
							<el-image style="width: 50px; height: 40px" :src="scope.row.newPic"
								:preview-src-list="scope.row.picList">
								<div slot="error">
									<span>暂无图片</span>
								</div>
							</el-image>
						</template>
					</el-table-column>
					<el-table-column label="管理方式" prop="manageTypeName" />
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" @click="selectMaterialFun(scope.row)">
								选 择
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-row style="text-align:right;margin-top: 8px">
					<el-pagination @current-change="currentChangeHandlerMaterial" :current-page="pageIndex"
						:page-size="pageSize" :total="total" layout="total, prev, pager, next, jumper"></el-pagination>
				</el-row>
			</div>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<el-divider></el-divider>
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>

		<Dialog title="快速添加物料" :visible.sync="isShowAEDialog">
			<MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" @isToParentGoodsNmae_p="getMaterialListPage"
				:selectData_p="selectData1" :isFastAddGood_p.sync="isFastAddGood"></MaterialAddForm>
		</Dialog>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { materialListService, materialDetailService } from '@s/spareParts/MaterialService';
import { borrowListService } from '@s/spareParts/BorrowService';
import {
	issueReceiptTypeListService,
	currentStockAddService
} from '@s/spareParts/CurrentStockService';
import {
	checkStockSelectListService,
	listundoneInListService
} from '@s/spareParts/CheckStockService';
import {
	warehouseAllListService,
	warehouseSelfListService,
	warehouseonlymyselfService
} from '@s/spareParts/WarehouseService';
import { wareareaListService } from '@s/spareParts/WareareaService';
import {
	// purchaseApplicationListService,
	purchaseApplicationListundoneService,
	purchaseApplicationDetailService
} from '@s/spareParts/PurchaseApplicationService';
import ListMixin from '@m/List.mixin';
// eslint-disable-next-line import/named
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import Dialog from '@c/ui/Dialog';
import { hgetStorage, htimeFormat } from '../../../util/htools.web';

export default {
	props: ['selectData_p', 'selectDataFastcurrentStock_p'],
	mixins: [ListMixin],
	components: { MaterialAddForm, Dialog },
	data() {
		const checkPositionId = (rule, val, callback) => {
			if (!val || val === '') {
				return callback(new Error('请选择库房'));
			}
			if (this.curMaterialId) {
				const sameItems = this.tableFormData.tableData.filter(item => {
					return item.goodsId === this.curMaterialId;
				});
				const positionIds = sameItems.map(item => {
					return item.positionId;
				});
				const samePositionIds = positionIds.filter(item => {
					return positionIds.indexOf(item) !== positionIds.lastIndexOf(item);
				});
				if (samePositionIds.indexOf(val) >= 0) {
					callback(new Error('同一物料不能选择同一个库位'));
				} else {
					callback();
				}
			} else {
				callback();
			}
		};
		return {
			editId: -1,
			isShowAEDialog: false,
			isAddMetarial: false,
			isFastAddGood: true,
			selectData1: [],
			// 最新的选择物料
			tableFormData: {
				tableData: []
			},
			tableDataPage: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			positionIdRules: {
				validator: checkPositionId
			},
			curMaterialId: null,
			purchaseMaterialData: [],
			borrowListData: [],
			issueReceiptTypeData: [],
			absoutNumberData: [],
			warehouseData: [],
			materialDetailData: '',
			wareareaData: [],
			// 弹窗
			formData: {
				inoutType: '',
				aboutId: '',
				batchCode: '',
				warehouseId: ''
			},
			formRules: {
				inoutType: [
					{
						required: true,
						message: '请选择出入库',
						trigger: 'change'
					}
				],
				aboutId: [
					{
						required: true,
						message: '请选择相关单号',
						trigger: 'change'
					}
				]
			},
			isShowMaterialList: false,
			isHasCntPrice: true,
			curTableRow: null,
			selectedMaterialIds: [],
			materialForm: {
				name: ''
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (!newValue) {
					return false;
				}
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		selectDataFastcurrentStock_p: {
			async handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.warehouseId = newValue[0].warehouseId;
				this.formData.warehouseId && this.wareareaList();
				this.formData.inoutType = 1;
				await this.purchaseApplicationList();
				this.absoutNumberData.forEach(item => {
					if (item.code == newValue[0].code) {
						this.formData.aboutId = item.id;
					}
				});
				await this.aboutIdChange();
				const arr = JSON.parse(JSON.stringify(newValue[0].items));
				arr.forEach(item => {
					item.cnt1 = item.cnt;
					item.cnt = item.cnt1 - item.donecnt;
				});
				const arr1 = arr.filter(item => {
					return item.cnt != 0;
				});
				this.tableFormData.tableData = arr1;
				// this.tableFormData.tableData.forEach(item => {
				// 	item.cnt1 = item.cnt;
				// 	item.cnt = item.cnt1 - item.donecnt;
				// });
			},
			deep: true,
			immediate: true
		},
		// isRefreshList(newValue) {
		// 	if (newValue) {
		// 		// this.materialList();
		// 		this.getMaterialListPage();
		// 	}
		// },
		'formData.warehouseId': {
			async handler(newValue) {
				this.warehouseChangeHandler();
			},
			deep: true
		}
	},
	created() {
		this.getMaterialListPage();
		this.issueReceiptTypeList();
		const roles = hgetStorage('roleIds');
		const factoryRole = this.$envConfig.factoryAdminRoleId;
		const hospitalRole = this.$envConfig.hospitalAdminRoleId;
		// TODELETE
		// if (roles.indexOf(factoryRole) > -1 || roles.indexOf(hospitalRole) > -1) {
		// 	this.warehouseAllList();
		// } else {
		// 	this.warehouseSelfList();
		// }
		this.warehouseonlymyself();
	},
	methods: {
		aboutIdChange(val) {
			this.absoutNumberData.forEach(item => {
				if (item.id === val) {
					this.formData.warehouseId = item.warehouseId;
				}
			});
			if (val === '') {
				this.formData.warehouseId = '';
			}
			this.formData.batchCode = null;
			if (this.formData.inoutType === 4) {
				// 盘盈入库
				this.formData.aboutId && (this.formData.batchCode = `pd${this.formData.aboutId}`);
			}
			if (this.formData.inoutType === 1) {
				// 采购入库
				const item = this.absoutNumberData.filter(item => {
					return item.id === this.formData.aboutId;
				})[0];
				this.purchaseApplicationDetail();
				item && (this.formData.batchCode = item?.code);
			}
			// 归还入库
			if (this.formData.inoutType === 10) {
				this.formData.aboutId && (this.formData.batchCode = `RT${this.formData.aboutId}`);
				this.borrowList({ warehouseId: this.formData.warehouseId });
			}
		},
		showFastAddGood() {
			this.isShowAEDialog = true;
			this.editId = -1;
			this.selectData1 = [];
			this.selectData1 = [{ name: this.materialForm.name }];
		},
		// 获取物料列表 分页
		async getMaterialListPage(type) {
			if (type === 'search') {
				this.pageIndex = 1;
			}
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.materialForm
			};
			const res = await materialListService(dataJson);
			res.records.forEach(item => {
				item.goodsId = item.id;
				item.goodsName = item.name;
				if (item.pic && item.pic.length > 0) {
					item.picList = [
						`${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`
					];
					item.newPic = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`;
				} else {
					item.newPic = item.pic;
					item.picList = [];
				}
			});
			this.tableDataPage = res.records;
			this.total = res.total;
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async issueReceiptTypeList() {
			const dataJson = {
				type: 1,
				...this.searchForm
			};
			const res = await issueReceiptTypeListService(dataJson);
			this.issueReceiptTypeData = res.filter(item => {
				return item.id !== 2;
			});
		},
		issueReceiptChangeHandler() {
			console.log('this.formData.inoutType');
			console.log(this.formData.inoutType);
			this.warehouseChangeHandler();
			this.formData.aboutId = '';
			this.formData.batchCode = '';
			this.formData.warehouseId = '';
			this.absoutNumberData = [];
			if (this.formData.inoutType === 4) {
				// 盘盈入库
				this.checkStockSelectList();
			}
			if (this.formData.inoutType === 1) {
				// 采购入库
				this.purchaseApplicationList();
			}
			if (this.formData.inoutType === 10) {
				// 采购入库
				this.listundoneInList();
			}
		},
		warehouseChangeHandler() {
			this.wareareaList();
		},
		async listundoneInList() {
			const dataJson = {
				goodsid: null
			};
			const res = await listundoneInListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		async checkStockSelectList() {
			const dataJson = {};
			const res = await checkStockSelectListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		async purchaseApplicationList() {
			const dataJson = {
				// pageNum: 1,
				// pageSize: 100000
			};
			const res = await purchaseApplicationListundoneService(dataJson);
			res.forEach(item => {
				item.name = `${item.code}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		// 归还入库 领用借用列表
		async borrowList(obj) {
			const dataJson = {
				pageNum: 1,
				pageSize: 999999,
				...obj
			};
			const res = await borrowListService(dataJson);
			this.borrowListData = res.records;
		},
		async purchaseApplicationDetail() {
			const dataJson = {
				id: this.formData.aboutId
			};
			const res = await purchaseApplicationDetailService(dataJson);
			// res.items.forEach(item => {
			// 	item.goodsId = item.id;
			// });
			this.purchaseMaterialData = res.items;
		},
		// 自动填充方法
		autoFillIn() {
			if (!this.formData.aboutId || this.formData.aboutId === '') {
				this.$message.warning('请选择相关单号');
			} else {
				this.purchaseMaterialData.forEach(item => {
					if (item.cnt - item.donecnt) {
						this.tableFormData.tableData.push({
							goodsId: item.goodsId,
							goodsName: item.goodsName,
							cnt: item.cnt - item.donecnt,
							id: '',
							price: item.price,
							positionId: ''
						});
					} else {
						this.$message.warning('完成数量等于申请数量！');
					}
				});
			}
		},
		// 自动填充方法 领用借用
		autoFillInBorrow() {
			console.log('this.borrowListData');
			console.log(this.absoutNumberData);
			console.log(this.formData.aboutId);
			if (!this.formData.aboutId || this.formData.aboutId === '') {
				this.$message.warning('请选择相关单号');
			} else {
				this.absoutNumberData.forEach(item => {
					// if (item.doneCnt - item.returnCnt > 0 && item.id == this.formData.aboutId) {
					if (item.id == this.formData.aboutId) {
						this.tableFormData.tableData.push({
							goodsId: item.goodsId,
							goodsName: item.goodsName,
							cnt: item.doneCnt - item.returnCnt,
							id: '',
							price: item.price || 0,
							positionId: ''
						});
					}
				});
			}
		},
		// 选择库位的时候把当前条物料ID赋值
		changePosition(e, data) {
			if (e) {
				this.curMaterialId = data.goodsId;
			}
		},
		// TODELETE
		async warehouseAllList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseAllListService(dataJson);
			this.warehouseData = res.records;
		},
		// TODELETE
		async warehouseSelfList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseSelfListService(dataJson);
			this.warehouseData = res.records;
		},

		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		async wareareaList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				warehouseId: this.formData.warehouseId
			};
			const res = await wareareaListService(dataJson);
			this.wareareaData = res.records;
		},
		removeDetailHandler(scope, itemIndex) {
			console.log(scope);

			const index = itemIndex;
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					if (this.editId === -1) {
						// 新增
						this.tableFormData.tableData.splice(index, 1);
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		formSubmit() {
			if (this.tableFormData.tableData.length <= 0) {
				this.$message.error('请选择物料');
				return false;
			}
			this.$refs.formEle.validate(valid => {
				if (valid) {
					this.$refs.tableForm.validate(async valid => {
						if (valid) {
							if (this.editId === -1) {
								this.formData.items = [];
								this.tableFormData.tableData.forEach(item => {
									this.formData.items.push({
										cnt: item.cnt,
										goodsId: item.goodsId,
										price: item.price,
										positionId: item.positionId
									});
								});
								console.log(this.formData);
								await currentStockAddService(this.formData);
								this.$emit('update:isRefreshList_p', true);
								this.$emit('update:isRefreshListPurchase_p', true);
							}
							this.formCancel();
						} else {
							this.$emit('update:isRefreshList_p', false);
						}
					});
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		selectMaterialFun(data) {
			// this.wareareaList();
			this.warehouseChangeHandler();
			this.tableFormData.tableData.push({
				id: '',
				goodsId: data.id,
				goodsName: data.name,
				cnt: '',
				price: '',
				positionId: ''
			});
		},
		currentChangeHandlerMaterial(val) {
			this.pageIndex = val;
			this.getMaterialListPage();
		}
	}
};
</script>

<style lang="less" scoped></style>
