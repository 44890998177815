import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 列表
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const listService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data,
				page: data.pageNum,
				pagesize: data.pageSize
			};
			delete dataJson.pageNum;
			delete dataJson.pageSize;
			delete dataJson.devname;
			delete dataJson.departname;
			const res = await hpostRequest('hbtyong_5/devearning/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 添加
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const addService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			delete dataJson.devname;
			delete dataJson.departname;
			const res = await hpostRequest('hbtyong_5/devearning/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 编辑
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const editService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			delete dataJson.devname;
			delete dataJson.departname;
			const res = await hpostRequest('hbtyong_5/devearning/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const deleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/devearning/del?apjson=apjson',
				JSON.stringify(dataJson.ids)
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 详情
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const detailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong_5/devearning/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default listService;
