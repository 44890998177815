/* *@Description: 物料流向 *@ClassAuthor: Happy ZXH *@Date: 2022-05-21 18:14:21 */
<template>
	<div>
		<el-form :inline="true" :model="searchForm" class="demo-form-inline" size="mini">
			<el-form-item label="出入库时间">
				<el-date-picker v-model="searchForm.dateArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
					start-placeholder="开始日期" end-placeholder="结束日期"
					:default-time="['12:00:00', '08:00:00']"></el-date-picker>
			</el-form-item>
			<el-form-item label="物料">
				<el-select v-model="searchForm.goodsid" filterable clearable size="mini" placeholder="请选择库房">
					<el-option v-for="(item, index) in goodsList" :key="index" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="materialFlowTrace">查询</el-button>
			</el-form-item>
		</el-form>
		<div class="echarts">
			<div id="echarts1" class="echart_box" style="width:100%; min-height:800px;"></div>
		</div>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialFlowTraceService } from '@s/spareParts/MaterialFlowService';
// eslint-disable-next-line import/no-cycle
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { materialListService, materialAllListService } from '@s/spareParts/MaterialService';

export default {
	name: 'MaterialFlow',
	components: {},
	data() {
		return {
			// 库房下拉
			warehouseData: [],
			// 物料下拉
			goodsList: [],
			searchForm: {
				begin: '',
				end: '',
				dateArr: [],
				valueType: 'cnt'
			},
			options1: {
				// title: {
				// 	text: '物料流向'
				// },
				tooltip: {
					trigger: 'item',
					triggerOn: 'mousemove',
					formatter(params) {
						let tooltop = '';
						if (params.dataType === 'node') {
							if (params.data.name === '采购' || params.data.name === '盘盈') {
								tooltop = `${params.data.name}<br />数量 出：${params.data.allFromCnt}<br />金额 出：${params.data.allFromPrice}`;
							} else if (params.data.name === '销售' || params.data.name === '盘亏') {
								tooltop = `${params.data.name}<br />数量 入：${params.data.allToCnt}<br />金额 入：${params.data.allToPrice}`;
							} else {
								tooltop = `${params.data.name}<br />数量 入：<span style="min-width:50px; display:inline-block;">${params.data.allToCnt}</span> 出：${params.data.allFromCnt}<br />金额 入：<span style="min-width:50px; display:inline-block;">${params.data.allToPrice}</span> 出：${params.data.allFromPrice}`;
							}
							return tooltop;
						}
						if (params.dataType === 'edge') {
							tooltop = `${params.data.source} > ${params.data.target}<br />物料名称：${params.data.goodsName}<br />数量：${params.data.cnt}<br />金额：${params.data.price}`;
							return tooltop;
						}
					}
				},
				series: {
					type: 'sankey',
					layout: 'none',
					emphasis: {
						focus: 'adjacency'
					},
					data: [],
					links: []
				}
			}
		};
	},

	watch: {},

	computed: {},

	mounted() {
		// 获取 桑基图 数据
		this.materialFlowTrace();
		// 库房下拉
		this.warehouseonlymyself();
		// 获取物料列表
		this.getMaterialListPage();
	},

	methods: {
		// 获取 桑基图 数据
		async materialFlowTrace() {
			if (this.searchForm.dateArr) {
				[this.searchForm.begin, this.searchForm.end] = this.searchForm.dateArr;
				// this.searchForm.begin = this.searchForm.dateArr[0];
				// this.searchForm.end = this.searchForm.dateArr[1];
			}
			const dataJson = {
				...this.searchForm
			};
			const res = await materialFlowTraceService(dataJson);
			this.echartsInit1(res);
		},
		// 初始化图表
		echartsInit1(res) {
			let data = [];
			let links = new Array(res.links.length).fill({});
			res.links.forEach(item => {
				item.source = item.fromName;
				item.target = item.toName;
				if (this.searchForm.valueType === 'cnt') {
					item.value = item.cnt;
				}
				if (this.searchForm.valueType === 'price') {
					item.value = item.price;
				}
			});

			res.nodes.forEach(item => {
				delete item.id;
				item.depth = item.lev;
			});

			links = res.links;
			data = res.nodes;
			this.options1.series.data = data;
			this.options1.series.links = links;
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		// 库房下拉
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 获取物料列表 分页
		async getMaterialListPage() {
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			res.forEach(item => {
				item.goodsId = item.id;
				item.goodsName = item.name;
				if (item.pic && item.pic.length > 0) {
					item.picList = [
						`${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`
					];
					item.newPic = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`;
				} else {
					item.newPic = item.pic;
					item.picList = [];
				}
			});
			this.goodsList = res;
		}
	}
};
</script>
<style lang="less" scoped></style>
