import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 设备型号列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:27:44
 */
export const equipmentModelListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				equipment: '',
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备型号添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:06
 */
export const equipmentModelAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				equipment: '',
				codeId: '', // 设备型号
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备型号编辑
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:21
 */
export const equipmentModelEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				consumptionNatural: 0,
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				equipment: '',
				naturalAfter: '',
				naturalFront: '',
				powerConsumption: '',
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备型号详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:35
 */
export const equipmentModelDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: '',
				...data
			};
			const res = await hgetRequest('hbase/equipment/equipmentByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备型号删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:49
 */
export const equipmentModelDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: [],
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default equipmentModelListService;
