<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="79px" :disabled="isShowDetail_p">
          <el-form-item label="物料" prop="goodsId">
            <el-select class="fastChooseGood" v-model="formData.goodsId" filterable @keyup.native="getGoodsVal($event)">
              <el-option v-for="(item, index) in materialData" :key="index" :label="item.name" :value="item.id"></el-option>
              <p slot="empty" class="faseAddGood">没有您想要的物料，<span @click="showFastAddGood">点此快速添加</span></p>
            </el-select>
          </el-form-item>
          <el-form-item label="源库房" prop="warehouseId">
            <el-select v-model="formData.warehouseId" clearable placeholder="请选择">
              <el-option v-for="(item, index) in warehouseData" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="目标库房" prop="towarehouseId">
            <el-select v-model="formData.towarehouseId" clearable placeholder="请选择">
              <el-option v-for="(item, index) in warehouseData" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数量" prop="cnt">
            <el-input-number v-model="formData.cnt" placeholder="请输入数量" controls-position="right" :min="1" :max="999"></el-input-number>

          </el-form-item>
          <el-form-item label="申请人" prop="requestUser">
            <el-select ref="multiSelect" v-model="formData.requestUser" placeholder="请选择申请人" filterable>
              <el-option v-for="(user, index) in userData" :key="index" :label="user.nickName" :value="user.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间" prop="requestTime">
            <el-date-picker v-model="formData.requestTime" type="datetime" placeholder="请选择申请时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="formData.type">
              <el-radio :label="0">借用</el-radio>
              <el-radio :label="1">领用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="24" style="text-align: right; margin-top: 10px">
        <span class="dialog-footer" style="text-align: right">
          <el-button type="success" @click="reduceStockHandler(formData)" v-if="editId != -1 && formData.doneCnt < formData.cnt">出库</el-button>
          <el-button @click="formCancel">取 消</el-button>
          <el-button type="primary" @click="formSubmit">确 定</el-button>
        </span>
      </el-col>
    </el-row>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
      <MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" @isToParentGoodsNmae_p="materialList" :selectData_p="selectData" :isFastAddGood_p.sync="isFastAddGood"></MaterialAddForm>
    </Dialog>
    <Dialog title="物料出库" :visible.sync="isShowReduceStockDialog" width="54%">
      <CurrentStockReduceForm v-if="isShowReduceStockDialog" :isShowReduceStockDialog_p.sync="isShowReduceStockDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectData"></CurrentStockReduceForm>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	borrowAddService,
	borrowEditService,
	borrowDetailService
} from '@s/spareParts/BorrowService';
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
// eslint-disable-next-line import/named
import {
	transferMaterialAddService,
	transferMaterialDeleteService,
	transferMaterialEditService
} from '@s/spareParts/TransferService';
// eslint-disable-next-line import/named
import { materialAllListService, materialDetailService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService } from '@s/spareParts/SupplierService';
import { htimeFormat, hremoveStorage, hgetStorage } from '@u/htools.web';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';
import ListMixin from '@m/List.mixin';
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import CurrentStockReduceForm from '@f/spareParts/currentStock/CurrentStockReduce.form';
import Dialog from '@c/ui/Dialog';

export default {
	props: [
		'selectData_p',
		'isShowDetail_p',
		'isShowAEDialog_p',
		'selectDataFastborrow_p',
		'selectNativesData_p'
	],
	mixins: [ListMixin],
	components: { MaterialAddForm, Dialog, CurrentStockReduceForm },
	data() {
		return {
			// 申请人下拉
			userData: [],
			isRefreshDetail: false,
			selectData: [],
			selectData1: [],
			isFastAddGood: true,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			isShowReduceStockDialog: false,
			// 弹窗
			formData: {
				goodsId: null,
				warehouseId: '',
				towarehouseId: '',
				cnt: null,
				requestUser: null,
				requestTime: new Date(),
				type: null
			},
			formRules: {
				goodsId: [
					{
						required: true,
						message: '请选择物料',
						trigger: 'change'
					}
				],
				warehouseId: [
					{
						required: true,
						message: '请选择源库房',
						trigger: 'change'
					}
				],
				towarehouseId: [
					{
						required: true,
						message: '请选择目标库房',
						trigger: 'change'
					}
				],
				requestTime: [
					{
						required: true,
						message: '请选择申请时间',
						trigger: 'blur'
					}
				],
				cnt: [
					{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}
				],
				type: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				]
			},
			supplierData: [],
			materialData: [],
			warehouseData: [],
			materialDetailData: '',
			isShowReduceStockListDialog: false,
			selectDatainOutRecord: null
		};
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		},
		dialogTitleFastOut() {
			return '一键出库';
		}
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					console.log(newValue[0]);
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		// 当前库存列表 备选来的申请领用借用
		selectNativesData_p: {
			handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.goodsId = newValue[0].goodsid;
				this.formData.warehouseId = newValue[0].warehouseId;
				this.formData.cnt = newValue[0].cnt;
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshDetail: {
			handler(newValue) {
				if (newValue) {
					this.showDialogEditHandler();
				}
			},
			deep: true
		}
	},
	created() {
		/* this.supplierList(); */
		this.materialList();
		this.warehouseList();
		this.userList();
	},
	methods: {
		// 一键出库后 刷新详情 TODO
		async showDialogEditHandler() {
			const dataJson = {
				id: this.formData.id
			};
			const res = await borrowDetailService(dataJson);
			const arr = res.items;
			for (let index = 0; index < arr.length; index++) {
				this.tableData.forEach((item1, index1, arr1) => {
					if (arr[index].id === item1.id) {
						item1.indonecnt = arr[index].indonecnt;
						item1.outdonecnt = arr[index].outdonecnt;
						item1.cnt = arr[index].cnt;
					}
				});
			}
		},
		// 一键出库
		reduceStockHandler(row) {
			row.goodsid = row.goodsId;
			row.from = 'borrowAdd';
			this.selectData = [row];
			this.isShowReduceStockDialog = true;
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				requestId: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			/* const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name; */
		},
		saveDetailHandler(row) {
			if (!row.goodsId || row.cnt === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt < 0) {
				this.$message.warning('请输入正确的数量');
				return false;
			}
			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						this.formData.goodsId = item.id;
					}
				});
			// goodsName &&
			// 	this.tableData.forEach(item => {
			// 		if (item.isAddDetail) {
			// 			item.goodsId = goodsId;
			// 		}
			// 	});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async supplierList() {
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await transferMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await transferMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await transferMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		// 没有您想要的物料，点此快速添加
		showFastAddGood() {
			this.dialogAddHandlerMixin();
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || ''
			});
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						this.formData.requestTime = htimeFormat(this.formData.requestTime);
						await borrowAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData,
							requestTime: htimeFormat(this.formData.requestTime)
						};
						await borrowEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
			hremoveStorage('fastSaleOrPurchase');
		},
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
			if (!this.formData.requestUser) {
				this.formData.requestUser = hgetStorage('id');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}
.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
