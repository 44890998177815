import { hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 省市县查询  查询所有省级
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const regionService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/region/regionAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 省市县查询  根据省查看所有市
 *@ClassAuthor: myw
 *@Date: 2021-05-24 16:33:15
 */
export const cityService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				regionNum: '',
				...data
			};
			const res = await hgetRequest('hbtyong/region/regionAllByNumQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 省市县查询  根据省查看所有区
 *@ClassAuthor: myw
 *@Date: 2021-05-24 16:41:59
*/
export const grountService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				regionNum: '',
				...data
			};
			const res = await hgetRequest('hbtyong/region/regionByNumQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default regionService;
