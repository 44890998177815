<template>
	<div>
		<div class="search_box">
			<el-form class="form" ref="searchForm" :model="searchForm" label-width="100px" @submit.native.prevent
				@keyup.enter.native="searchSubmit">
				<h2 style="margin-right:20px;">{{ nickname }}</h2>
				<el-form-item label="日期" label-width="80" style="display:flex;">
					<el-date-picker v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :clearable="false"></el-date-picker>
				</el-form-item>
				<el-form-item label="医院" label-width="50" style="display:flex; margin-left:30px;">
					<el-select v-model="searchForm.hosid" placeholder="请选择" clearable filterable>
						<el-option v-for="item in hospitalData" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" label-width="0">
					<el-button type="success" class="search_button" @click="searchSubmit" size="mini">
						搜索
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月修复量</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(1)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts1" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月平均响应时间</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(2)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts2" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月平均到场时间</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(3)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts3" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月平均修复时间</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(4)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts4" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月巡检量</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(5)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts5" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月巡检设备台数</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(6)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts6" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月保养量</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(7)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts7" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>月保养设备台数</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(8)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts8" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>维修种类</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="showBigEchartsHandler(9)">
							<i class="el-icon-top-right" style="font-size: 20px"></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts9" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog :visible.sync="isShowBigEcharts" width="90%" @before-close="beforeCloseHandler">
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	empinspectcntService,
	emprepaircntandtimebymonthService,
	empupkeepcntService,
	emprepairdevtypecntService
} from '@s/company/empsummary/empsummaryService';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/company/contract/ContractService';
import Dialog from '@c/ui/Dialog';
import { htimeFormat, hgetStorage, hdateInMonth, hmonthRange } from '@u/htools.web';

const posList = [
	'left',
	'right',
	'top',
	'bottom',
	'inside',
	'insideTop',
	'insideLeft',
	'insideRight',
	'insideBottom',
	'insideTopLeft',
	'insideTopRight',
	'insideBottomLeft',
	'insideBottomRight'
];
app.configParameters = {
	rotate: {
		min: -90,
		max: 90
	},
	align: {
		options: {
			left: 'left',
			center: 'center',
			right: 'right'
		}
	},
	verticalAlign: {
		options: {
			top: 'top',
			middle: 'middle',
			bottom: 'bottom'
		}
	},
	position: {
		options: posList.reduce((map, pos) => {
			map[pos] = pos;
			return map;
		}, {})
	},
	distance: {
		min: 0,
		max: 100
	}
};
app.config = {
	rotate: 90,
	align: 'left',
	verticalAlign: 'middle',
	position: 'insideBottom',
	distance: 15,
	onChange: () => {
		const labelOption = {
			rotate: app.config.rotate,
			align: app.config.align,
			verticalAlign: app.config.verticalAlign,
			position: app.config.position,
			distance: app.config.distance
		};
		myChart.setOption({
			series: [
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				},
				{
					label: labelOption
				}
			]
		});
	}
};
const labelOption = {
	show: true,
	position: app.config.position,
	distance: app.config.distance,
	align: app.config.align,
	verticalAlign: app.config.verticalAlign,
	rotate: app.config.rotate,
	// formatter: '{c}  {name|{a}}',
	formatter: '{a}',
	fontSize: 16,
	rich: {
		name: {}
	}
};
export default {
	components: {
		Dialog
	},
	data() {
		return {
			nickname: null,
			date: null,
			hosid: null,
			searchForm: {
				hosid: null
			},
			dateType: 2, // 月
			dateEndType: 2, // 月
			hospitalData: [],
			options1: {
				// title: {
				// 	text: '月修复量'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						barMaxWidth: 30,
						type: 'bar'
					}
				]
			},
			options2: {
				// title: {
				// 	text: '月平均响应时间'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						type: 'line'
					}
				]
			},
			options3: {
				// title: {
				// 	text: '月平均到场时间'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						type: 'line'
					}
				]
			},
			options4: {
				// title: {
				// 	text: '月平均修复时间'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						type: 'line'
					}
				]
			},
			options5: {
				// title: {
				// 	text: '月巡检量'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						barMaxWidth: 30,
						type: 'bar'
					}
				]
			},
			options6: {
				// title: {
				// 	text: '月巡检设备台数'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						barMaxWidth: 30,
						type: 'bar'
					}
				]
			},
			options7: {
				// title: {
				// 	text: '月保养量'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						barMaxWidth: 30,
						type: 'bar'
					}
				]
			},
			options8: {
				// title: {
				// 	text: '月保养设备台数'
				// },
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				legend: {
					left: 0
				},
				toolbox: {
					show: false,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				grid: {
					left: '1%',
					right: '6%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						boundaryGap: true,
						type: 'category',
						axisTick: { show: false },
						data: []
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						data: [],
						barMaxWidth: 30,
						type: 'bar'
					}
				]
			},
			options9: {
				title: {
					// text: '维修种类',
					// left: 'center'
				},
				tooltip: {
					trigger: 'item'
				},
				legend: {
					orient: 'vertical',
					top: '5%',
					left: 'lfet',
					show: false
				},
				series: [
					{
						name: '维修种类',
						type: 'pie',
						radius: '50%',
						// minAngle: 100,
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			isShowBigEcharts: false
		};
	},
	mounted() {
		this.contractList();

		const search = this.$route.query;
		const myDate = new Date();
		const curMonth = myDate.getMonth() + 1;
		const curYear = myDate.getFullYear();
		const curYM = `${curYear}-${curMonth}`;
		const prevMonthes = hmonthRange(curYM, 7, 1);
		prevMonthes.shift();
		prevMonthes.push(curYM);
		const beginMonth = prevMonthes[1];
		const beginTime = `${beginMonth}-01 00:00:00`;
		// const endTime = curYM + "-" + hdateInMonth(beginMonth) + " 23:59:59";
		let endTime = htimeFormat(new Date().getTime(), `{y}-${curMonth + 1}-01 23:59:59`);
		endTime = new Date(endTime).getTime() - 1000 * 60 * 60 * 24;
		endTime = htimeFormat(endTime, '{y}-{m}-{d} 23:59:59');
		if (search.begin) {
			this.date = [`${search.begin}`, `${search.end}`];
		} else {
			this.date = [`${beginTime}`, `${endTime}`];
		}
		if (search.dutyPeople) {
			this.searchForm.uid = search.dutyPeople;
		}
		if (search.hosid) {
			this.searchForm.hosid = search.hosid;
		}
		if (search.nickname) {
			this.nickname = search.nickname;
		}

		this.searchForm.orgid = hgetStorage('organization');

		this.emprepaircntandtimebymonthService();
		this.empinspectcntService();
		this.empupkeepcntService();
		this.emprepairdevtypecntService();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index == 1) {
					this.myChart.setOption(this.options1);
				} else if (index == 2) {
					this.myChart.setOption(this.options2);
				} else if (index == 3) {
					this.myChart.setOption(this.options3);
				} else if (index == 4) {
					this.myChart.setOption(this.options4);
				} else if (index == 5) {
					this.myChart.setOption(this.options5);
				} else if (index == 6) {
					this.myChart.setOption(this.options6);
				} else if (index == 7) {
					this.myChart.setOption(this.options7);
				} else if (index == 8) {
					this.myChart.setOption(this.options8);
				} else if (index == 9) {
					this.myChart.setOption(this.options9);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		searchSubmit() {
			this.emprepaircntandtimebymonthService();
			this.empinspectcntService();
			this.empupkeepcntService();
			this.emprepairdevtypecntService();
		},
		// 1、处理横坐标 时间
		// 2、去重
		// 3、排序
		detaEchartsTime(res) {
			// console.log('res ygAdmin 进销统计');
			// console.log(res);
		},
		// 维修统计
		async emprepaircntandtimebymonthService() {
			// 横坐标 时间
			const xAxis = [];
			const YAxisCnt = [];
			const YAxisxysj = [];
			const YAxisdcsj = [];
			const YAxiswcsj = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				...this.searchForm
			};
			const res = await emprepaircntandtimebymonthService(dataJson);
			// console.log('维修统计');
			// console.log(dataJson);
			// console.log(res);
			res.forEach(item => {
				xAxis.push(item.dt);
				YAxisCnt.push(item.cnt);
				YAxisxysj.push(item.xysj);
				YAxisdcsj.push(item.dcsj);
				YAxiswcsj.push(item.wcsj);
			});

			this.echartsInit1({ xAxis: xAxis, YAxis: YAxisCnt });
			this.echartsInit2({ xAxis: xAxis, YAxis: YAxisxysj });
			this.echartsInit3({ xAxis: xAxis, YAxis: YAxisdcsj });
			this.echartsInit4({ xAxis: xAxis, YAxis: YAxiswcsj });
		},
		// 巡检统计
		async empinspectcntService() {
			// 横坐标 时间
			const xAxis = [];
			const YAxisCnt = [];
			const YAxisdevcnt = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				...this.searchForm
			};
			const res = await empinspectcntService(dataJson);
			// console.log('巡检统计');
			// console.log(dataJson);
			// console.log(res);
			res.forEach(item => {
				xAxis.push(item.dt);
				YAxisCnt.push(item.cnt);
				YAxisdevcnt.push(item.devcnt);
			});

			this.echartsInit5({ xAxis: xAxis, YAxis: YAxisCnt });
			this.echartsInit6({ xAxis: xAxis, YAxis: YAxisdevcnt });
		},
		// 保养统计
		async empupkeepcntService() {
			// 横坐标 时间
			const xAxis = [];
			const YAxisCnt = [];
			const YAxisdevcnt = [];

			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				...this.searchForm
			};
			const res = await empupkeepcntService(dataJson);
			// console.log('保养统计');
			// console.log(dataJson);
			// console.log(res);
			res.forEach(item => {
				xAxis.push(item.dt);
				YAxisCnt.push(item.cnt);
				YAxisdevcnt.push(item.devcnt);
			});

			this.echartsInit7({ xAxis: xAxis, YAxis: YAxisCnt });
			this.echartsInit8({ xAxis: xAxis, YAxis: YAxisdevcnt });
		},
		// 维修种类
		async emprepairdevtypecntService() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				...this.searchForm
			};
			const res = await emprepairdevtypecntService(dataJson);
			console.log('维修种类');
			console.log(res);

			res?.forEach(item => {
				item.value = item.cnt;
				item.name = item.devicetype;
			});
			this.echartsInit9(res);
		},

		echartsInit1(res) {
			this.options1.xAxis[0].data = res.xAxis;
			this.options1.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts1');
			this.$echarts.init(document.getElementById('echarts1')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			this.options2.xAxis[0].data = res.xAxis;
			this.options2.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts2');
			this.$echarts.init(document.getElementById('echarts2')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			this.options3.xAxis[0].data = res.xAxis;
			this.options3.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts3');
			this.$echarts.init(document.getElementById('echarts3')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit4(res) {
			this.options4.xAxis[0].data = res.xAxis;
			this.options4.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts4');
			this.$echarts.init(document.getElementById('echarts4')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			this.options5.xAxis[0].data = res.xAxis;
			this.options5.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts5');
			this.$echarts.init(document.getElementById('echarts5')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit6(res) {
			this.options6.xAxis[0].data = res.xAxis;
			this.options6.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts6');
			this.$echarts.init(document.getElementById('echarts6')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		echartsInit7(res) {
			this.options7.xAxis[0].data = res.xAxis;
			this.options7.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts7');
			this.$echarts.init(document.getElementById('echarts7')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		echartsInit8(res) {
			this.options8.xAxis[0].data = res.xAxis;
			this.options8.series[0].data = res.YAxis;
			const chartDom = document.getElementById('echarts8');
			this.$echarts.init(document.getElementById('echarts8')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
			// console.log(this.options8);
		},
		echartsInit9(res) {
			this.options9.series[0].data = res;
			const chartDom = document.getElementById('echarts9');
			this.$echarts.init(document.getElementById('echarts9')).dispose();
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options9);
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
		}
	}
};
</script>

<style scoped>
.el-col {
	margin-bottom: 20px;
}

.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}

.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}

.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 500px !important;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	height: 360px;
}

.search_button {
	margin-left: 10px;
}
</style>
