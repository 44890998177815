<template>
  <div class="head-serch">
    <el-form class="form" ref="formEle" :model="formData" label-width="77px" @submit.native.prevent @keyup.enter.native="searchHandler">
      <el-form-item label="物料">
        <el-select v-model="formData.goods" multiple filterable clearable reserve-keyword>
          <el-option v-for="(item, index) in materialData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库房">
        <el-select v-model="formData.warehouse" filterable clearable @change="warehouseChangHandler">
          <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库位">
        <el-select v-model="formData.position" filterable multiple clearable reserve-keyword>
          <el-option v-for="(item, index) in wareareaData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <span style="padding-left: 10px; margin-top: 4px">
        <el-checkbox v-model="formData.include0">含0库存</el-checkbox>
      </span>
    </el-form>
    <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
  </div>
</template>

<script>
import { materialAllListService } from '@s/spareParts/MaterialService';
import {
	warehouseSelectListService,
	warehouseonlymyselfService
} from '@s/spareParts/WarehouseService';
import { wareareaListService } from '@s/spareParts/WareareaService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				goods: [],
				warehouse: [],
				position: '',
				include0: false
			},
			materialData: [],
			warehouseData: [],
			wareareaData: []
		};
	},
	created() {
		this.materialList();
		// TODELETE
		// this.warehouseList();
		this.wareareaList();
		this.warehouseonlymyself();
	},
	methods: {
		warehouseChangHandler() {
			this.wareareaList();
		},
		async materialList() {
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			res.forEach(item => {
				item.label = item.name;
				item.value = item.id;
			});
			this.materialData = res;
		},
		// TODELETE
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			console.log(res);
			this.warehouseData = res;
		},
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		async wareareaList() {
			this.formData.position = null;
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				warehouseId: this.formData.warehouse
			};
			const res = await wareareaListService(dataJson);
			console.log(res);
			this.wareareaData = res.records;
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped></style>
