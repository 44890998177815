<template>
  <div class="app-container">
    <div class="head-container">
      <!-- 搜索区域 -->
      <TransferSearchform @searchForm="searchFormHandler"></TransferSearchform>
      <div>
        <!-- 功能按钮 -->
        <Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler" @showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips" :authorize_p="'transfer'">
          <template v-slot:lastBtn>
            <el-button type="primary" @click="exportExcel">导出</el-button>
          </template>
        </Button>

      </div>
    </div>
    <!--表格渲染-->
    <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
      <el-table-column label="数量">
        <template slot-scope="scope">
          <span class="numberp10">
            <span v-if="scope.row.inCnt == scope.row.outCnt &&scope.row.inCnt == scope.row.allCnt && scope.row.outCnt == scope.row.allCnt" style="color:#67C23A; cursor:pointer;">
              <span title="已入库">{{scope.row.inCnt }}</span> / <span title="已出库">{{scope.row.outCnt }}</span> / <span title="总数">{{scope.row.allCnt }}</span>
            </span>
            <span v-else style="color:#E6A23C; cursor:pointer;">
              <span title="已入库">{{scope.row.inCnt }}</span> / <span title="已出库">{{scope.row.outCnt }}</span> / <span title="总数">{{scope.row.allCnt }}</span>
            </span>
          </span>

        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="150">
        <template slot-scope="scope">
          {{ scope.row.requestTime | parseTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" fixed="right" width="280">
        <template slot-scope="scope">
          <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'transfer', id: 'btn-update-row' }" @click="editSingleHandler(scope.row)">
            编辑
          </el-button>
          <el-button :disabled="scope.row.outCnt > 0" :title="scope.row.outCnt > 0?'已执行出库的调拨申请不能删除':''" id="btn-remove-row" type="danger" v-authorize="{ name: 'remove', type: 'transfer', id: 'btn-remove-row' }" @click="deleteSingleHandler(scope.row)">
            删除
          </el-button>
          <el-button id="btn-remove-row" type="success" v-authorize="{ name: 'remove', type: 'transfer', id: 'btn-remove-row' }" @click="changeStateHandler(1, scope.row)" v-if="scope.row.state === 0">
            通过
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
      <TransferAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectData"></TransferAddForm>
    </Dialog>
    <Dialog title="备注" :visible.sync="isShowMemoDialog" width="40%">
      <el-input type="textarea" :rows="5" placeholder="请输入备注" v-model="textarea"></el-input>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="changeStateCancel">取消</el-button>
        <el-button type="primary" @click="changeStateSubmit">确定</el-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	transferDeleteService,
	transferListService,
	transferDetailService,
	saleApplicationChangeStateService
} from '@s/spareParts/TransferService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import TransferAddForm from '@f/spareParts/transfer/TransferAdd.form';
import TransferSearchform from '@f/spareParts/transfer/TransferSearch.form';
import { hgetStorage, htimeFormat } from '@u/htools.web';

export default {
	name: 'Transfer',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		TransferAddForm,
		TransferSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '序号',
					field: 'id',
					width: '55'
				},
				{
					label: '源库房',
					field: 'fromWareHouseName'
				},
				{
					label: '目标库房',
					field: 'toWarehouseName'
				}
			],
			delTips: '',
			isShowMemoDialog: false,
			state: '',
			textarea: '',
			rowId: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增调拨申请' : '编辑调拨申请';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.transferList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.transferList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.transferList();
			}
		}
	},
	created() {},
	mounted() {
		this.transferList();
		console.log('this.$route.query');
		console.log(this.$route.query);
		const { type } = this.$route.query || '';
		const data = hgetStorage('fastSaleOrPurchase');
		if (type === 'fastSaleOrPurchase' && !!data) {
			this.isShowAEDialog = true;
			this.selectData = [data];
		}
	},
	methods: {
		async transferList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await transferListService(dataJson);
			this.listMixin(res);
		},

		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		async exportExcel() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: 999,
				...this.searchForm
			};
			const res = await transferListService(dataJson);

			res.records.forEach(item => {
				item.requestTime = htimeFormat(item.requestTime);
			});

			const tHeader = ['序号', '源库房', '目标库房', '已入库', '已出库', '总数', '申请时间'];
			const filterVal = [
				'id',
				'fromWareHouseName',
				'toWarehouseName',
				'inCnt',
				'outCnt',
				'allCnt',
				'requestTime'
			];
			const data = this.formatJson(filterVal, res.records);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `调拨${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await transferDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await transferDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await transferDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.transferList();
		},
		changeStateHandler(state, row) {
			this.isShowMemoDialog = true;
			this.state = state;
			this.rowId = row.id;
		},
		async changeStateSubmit() {
			const dataJson = {
				id: this.rowId,
				memo: this.textarea,
				newState: this.state
			};
			await saleApplicationChangeStateService(dataJson);
			this.changeStateCancel();
			this.transferList();
		},
		changeStateCancel() {
			this.state = '';
			this.textarea = '';
			this.isShowMemoDialog = false;
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
