<!--
 *@Description: 
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-07-13 15:53:26
-->
<template>
	<div class="app-container">
		<div>
			<RepairSearch
				@searchForm="searchFormHandler"
				:lbegin="$route.query.begin"
				:lend="$route.query.end"
				:dateCharts="$route.query.date"
				:dateType="$route.query.dateType"
				:deptId="$route.query.deptId"
			></RepairSearch>
		</div>
		<div style="margin-top: 20px">
			<el-button type="danger" @click="allDel">删除</el-button>
			<el-button type="primary" @click="exportExcel">
				导出查询结果
			</el-button>
		</div>
		<div style="height: 15px"></div>
		<el-table
			:data="tableData"
			highlight-current-row
			style="width: 100%"
			:header-cell-style="{ background: '#F4F4F4' }"
			@selection-change="handleSelectionChange"
			@sort-change="sortTableHandle"
		>
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column
				property="code"
				label="报修单号"
				width="200"
			>
			<template slot-scope="scope">
					<p style="font-size: 14px">{{ scope.row.code }}</p>
					<p style="font-size: 14px" v-if="scope.row.paperNO">
						纸质工单:{{ scope.row.paperNO }}
					</p>
				</template>
			</el-table-column>
			<el-table-column property="price" label="费用" sortable="custom">
				<template slot-scope="scope">
					<p style="font-size: 14px">应收:{{ scope.row.allPrice }}</p>
					<p style="font-size: 14px">
						实收:{{ scope.row.realprice }}
					</p>
				</template>
			</el-table-column>
			<el-table-column width="240" label="设备名称">
				<template slot-scope="scope">
					<el-tooltip
						:content="
							`${
								!scope.row.devInfo.hospitalAssetCode
									? '未知'
									: scope.row.devInfo.hospitalAssetCode
							}: ${scope.row.devInfo.devInfoName}`
						"
						placement="top"
						effect="light"
					>
						<span>{{ scope.row.devInfo.devInfoName }}</span>
					</el-tooltip>
					<p>{{ scope.row.devInfoDepartmentName }}</p>
				</template>
			</el-table-column>
			<el-table-column
				label="报修人"
				width="140"
				sortable="custom"
				property="repair_time"
			>
				<template slot-scope="scope">
					<b style="font-size: 16px">
						{{ scope.row.repairUserName }}
					</b>
					<p style="font-size: 14px">
						{{ scope.row.repairUserPhone }}
					</p>
					<p style="color: #bbb; font-size: 12px">
						{{
							scope.row.repairTime
								| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
						}}
					</p>
				</template>
			</el-table-column>
			<el-table-column
				:property="
					repairObj.type === 'hospital'
						? 'serviceName'
						: 'affiliatedHospitalHdName'
				"
				:label="repairObj.type === 'hospital' ? '维修公司' : '医院'"
			></el-table-column>
			<!-- <el-table-column property="actionCompleteName" label="完成工程师"></el-table-column> -->
			<el-table-column
				label="接单人"
				width="140"
				sortable="custom"
				property="undertake_time"
			>
				<template slot-scope="scope">
					<b style="font-size: 16px">{{ scope.row.engineerName }}</b>
					<p style="font-size: 14px">{{ scope.row.engineerPhone }}</p>
					<p style="color: #bbb; font-size: 12px">
						{{
							scope.row.undertakeTime
								| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
						}}
					</p>
				</template>
			</el-table-column>
			<el-table-column
				property="faultTypeName"
				label="故障名称"
			></el-table-column>
			<el-table-column
				property="faultDescription"
				label="故障描述"
			></el-table-column>
			<el-table-column property="urgentLv" label="紧急等级">
				<template slot-scope="scope">
					<el-link
						style="cursor: initial"
						type="danger"
						:underline="false"
						v-if="Number(scope.row.urgentLv) === 0"
					>
						非常紧急
					</el-link>
					<el-link
						style="cursor: initial"
						type="warning"
						:underline="false"
						v-else-if="Number(scope.row.urgentLv) === 1"
					>
						比较紧急
					</el-link>
					<el-link
						style="cursor: initial"
						type="primary"
						:underline="false"
						v-else
					>
						一般紧急
					</el-link>
				</template>
			</el-table-column>
			<el-table-column property="actionState" label="维修状态">
				<template slot-scope="scope">
					<span
						class="equip_state"
						v-if="Number(scope.row.actionState) === 0"
					>
						待接单
					</span>
					<span
						class="equip_state equip_state1"
						v-else-if="Number(scope.row.actionState) === 1"
					>
						已响应
					</span>
					<span
						class="equip_state equip_state2"
						v-else-if="Number(scope.row.actionState) === 2"
					>
						已到场
					</span>
					<span
						class="equip_state equip_state3"
						v-else-if="Number(scope.row.actionState) === 3"
					>
						维修中
					</span>
					<span
						class="equip_state equip_state4"
						v-else-if="Number(scope.row.actionState) === 4"
					>
						待评价
					</span>
					<span
						class="equip_state equip_state5"
						v-else-if="Number(scope.row.actionState) === 5"
					>
						已完成
					</span>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column
				label="报修完成时间"
				width="150"
				sortable="custom"
				property="action_complete_time"
			>
				<template slot-scope="scope">
					<p>
						{{
							scope.row.actionCompleteTime
								| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
						}}
					</p>
					<template slot-scope="scope">
						<i
							class="el-icon-star-on yellow f12"
							v-for="(item, index) in Number(
								scope.row.repairEvaluationLv
							)"
							:key="index"
						></i>
					</template>
					<p v-if="scope.row.signTime">
						签单:{{ scope.row.signTime | parseTime('{y}-{m}-{d}') }}
					</p>
				</template>
				<!-- <template slot-scope="scope">{{ scope.row.actionCompleteTime | parseTime('{y}-{m}-{d}') }}</template> -->
			</el-table-column>
			<el-table-column
				label="操作"
				align="left"
				fixed="right"
				width="160"
			>
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="success"
						@click="showDetail(scope.row)"
					>
						查看
					</el-button>
					<el-button
						type="danger"
						id="btn-remove-row"
						v-authorize="{
							name: 'remove',
							type: 'hospitalRepair',
							id: 'btn-remove-row'
						}"
						@click="singleDelRow(scope.row)"
					>
						删除
					</el-button>
					<br />
					<el-button
						type="primary"
						id="btn-editSign-row"
						v-authorize="{
							name: 'editSign',
							type: 'hospitalRepair',
							id: 'btn-editSign-row'
						}"
						@click="showdialogupdatesignTime(scope.row)"
						style="margin-top:10px;"
					>
						修改签单时间
					</el-button>
				</template>
			</el-table-column>
			<!-- <el-table-column property="faultType" label="故障类型"></el-table-column> -->
		</el-table>

		<el-pagination
			style="margin-top: 20px; margin-bottom: 20px; text-align: right"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="queryInfo.pageNum"
			:page-sizes="[10, 20, 30, 50]"
			:page-size="queryInfo.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="total"
		></el-pagination>
		<el-dialog
			title="修改签单时间"
			:visible.sync="dialogupdatesignTime"
			width="30%"
			append-to-body
			:close-on-click-modal="false"
		>
			<el-form
				ref="formDataRepair"
				:model="formDataRepair"
				label-width="80px"
				:rules="formRules"
			>
				<el-form-item label="签单时间" prop="signDate">
					<el-date-picker
						v-model="formDataRepair.signDate"
						type="datetime"
						value-format="yyyy-MM-dd HH:mm:ss"
						placeholder="选择日期时间"
						style="width: 100%"
					></el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogupdatesignTime = false">
					取 消
				</el-button>
				<el-button type="primary" @click="updatesignTime">
					确 定
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	queryDevRepairInfoPageALLService,
	querySearchrepairService,
	repairDelService,
	repairupdatesigntimeService
} from '@s/repair/RepairService';
import RepairSearch from '@f/repair/RepairSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage, htimeFormat } from '@u/htools.web';

export default {
	props: ['repairObj'],
	mixins: [ListMixin],
	name: '',
	data() {
		return {
			tableData: null,
			total: 0,
			queryInfo: {
				pageNum: 1,
				pageSize: 10
			},
			formDataRepair: { signDate: null },
			selectedIds: [],
			dialogupdatesignTime: false,
			formRules: {
				signDate: [
					{
						required: true,
						message: '请选择签单时间',
						trigger: 'change'
					}
				]
			}
		};
	},

	components: { RepairSearch },

	watch: {},

	computed: {},

	mounted() {
		// this.getList();
		// this.searchFormHandler();
	},

	methods: {
		// 排序表格数据
		sortTableHandle({ column, prop, order }) {
			if (order == 'ascending') {
				this.searchForm.pageNum = 1;
				this.searchForm.orderby = prop;
			} else if (order == 'descending') {
				this.searchForm.pageNum = 1;
				this.searchForm.orderby = `${prop} desc`;
			} else {
				this.searchForm.orderby = null;
			}
			this.getList();
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		async exportExcel() {
			const dataJson = {
				pageNum: 1,
				pageSize: 9999,
				page: 1,
				pagesize: 9999,
				...this.searchForm
			};

			const res = await querySearchrepairService(dataJson);

			res.records.forEach(item => {
				item.repairTime &&
					(item.repairTime = htimeFormat(
						item.repairTime,
						'{y}-{m}-{d} {h}:{i}:{s}'
					));
				item.undertakeTime &&
					(item.undertakeTime = htimeFormat(
						item.undertakeTime,
						'{y}-{m}-{d} {h}:{i}:{s}'
					));
				item.actionCompleteTime &&
					(item.actionCompleteTime = htimeFormat(
						item.actionCompleteTime,
						'{y}-{m}-{d} {h}:{i}:{s}'
					));
				item.signTime &&
					(item.signTime = htimeFormat(
						item.signTime,
						'{y}-{m}-{d} {h}:{i}:{s}'
					));
				item.percentsName = '';
				if (item.percents) {
					item.percents.forEach(item1 => {
						if (item1.id) {
							item.percentsName += `${item1.username}:${item1.pct}%; `;
						}
					});
				}
				item.devInfoFactoryName = item.devInfo.devInfoFactoryName;
				item.devInfoBrand = item.devInfo.devInfoBrand;
				item.devInfoNns1 = item.devInfo.devInfoNns1;
			});
			console.log(res);
			const tHeader = [
				'医院',
				'科室',
				'工单号',
				'设备名称',
				'设备厂家',
				'设备品牌',
				'设备型号',
				'故障类型',
				'报修日期',
				'接单日期',
				'完成日期',
				'签单日期',
				'配件名称',
				'配件型号',
				'配件单价',
				'配件数量',
				'应收',
				'实收',
				'实收说明',
				'成本',
				'成本说明',
				'工程师比例'
			];

			// eslint-disable-next-line no-unused-vars
			const filterVal = [
				'affiliatedHospitalHdName',
				'devInfoDepartmentName',
				'paperNO',
				'devInfoName',
				'devInfoFactoryName',
				'devInfoBrand',
				'devInfoNns1',
				'faultTypeName',
				'repairTime',
				'undertakeTime',
				'actionCompleteTime',
				'signTime',
				'allPriceResone',
				'allPriceGoodsInfo',
				'allPriceSinglePrice',
				'allPriceGoodsCnt',
				'allPrice',
				'realprice',
				'realpricememo',
				'cost',
				'costinfo',
				'percentsName'
			];

			const data = this.formatJson(filterVal, res.records);

			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: '报修记录', // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		async getList() {
			const dataJson = {
				pageNum: this.queryInfo.pageNum,
				page: this.queryInfo.pageNum,
				pageSize: this.queryInfo.pageSize,
				pagesize: this.queryInfo.pageSize,
				...this.searchForm
			};
			let res = null;
			if (this.searchForm.searchType == 'hospital') {
				res = await queryDevRepairInfoPageALLService(dataJson);
			} else {
				res = await querySearchrepairService(dataJson);
			}
			this.tableData = res.records;
			this.total = res.total;
			this.listMixin(res);
		},
		// 修改签单时间
		async updatesignTime() {
			this.$refs.formDataRepair.validate(async valid => {
				if (valid) {
					console.log(this.formDataRepair);
					const res = await repairupdatesigntimeService(
						this.formDataRepair
					);
					this.getList();
					this.dialogupdatesignTime = false;
				}
			});
		},
		showdialogupdatesignTime(row) {
			this.formDataRepair.id = row.devRepairId;
			this.formDataRepair.signDate = null;
			this.dialogupdatesignTime = true;
		},
		async showDetail(row) {
			this.$router.push({
				name: 'RepairDetail',
				query: {
					type: this.repairObj.type,
					repairInfoId: row.devRepairId
				}
			});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.queryInfo.pageNum = 1;
			this.getList();
		},

		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.queryInfo.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.queryInfo.pageNum = val;
			this.getList();
		},
		singleDelRow(item) {
			this.$confirm('确定要删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					console.log(item);
					const dataJson = {
						ids: [item.devRepairId]
					};
					const res = await repairDelService(dataJson);
					console.log(res);
					this.getList();
				})
				.catch(() => {});
		},
		handleSelectionChange(val) {
			console.log(val);
			const ids = val.map(item => {
				return item.devRepairId;
			});
			this.selectedIds = ids;
		},
		allDel() {
			this.$confirm('确定要删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					if (this.selectedIds.length === 0) {
						this.$message.warning({
							message: '请选择要删除的内容',
							type: 'warning'
						});
						return false;
					}
					const dataJson = {
						ids: this.selectedIds
					};
					const res = await repairDelService(dataJson);
					console.log(res);
					this.getList();
				})
				.catch(() => {});
		}
	},

	destroyed() {}
};
</script>
<style lang="less" scoped>
/* 维修单状态 */
/* 待接单 */
.equip_state {
	//   width: 101rpx;
	//   height: 42rpx;
	//   border: 1px solid #FF8431;
	color: #ff8431;
	//   text-align: center;
	//   line-height: 40rpx;
	//   border-radius: 6rpx;
}

/* 已响应 */
.equip_state1 {
	color: #0d69f5;
}

/* 已到场 */
.equip_state2 {
	color: #760cd2;
}

/* 维修中 */
.equip_state3 {
	color: #0ccbd2;
}

/* 待评价 */
.equip_state4 {
	color: #c1e986;
}

/* 已完成 */
.equip_state5 {
	color: #15bc84;
}

.yellow {
	color: #ffd21e;
}

.f14 {
	font-size: 20px;
}
</style>
