<template>
	<div>
		<el-row :gutter="30" style="margin-bottom: 20px">
			<el-col :span="4">
				<el-card class="box-card" :body-style="{ background: '#47BBBB', color: '#fff',padding:'10px' }">
					<div style="display:flex;align-items:center;">
						<i class="el-icon-eleme" style="font-size:46px; margin-right:10px;"></i>
						<div style>
							<div>设备总数</div>
							<h3 style="font-size:28px;">{{ countData.equipmentNumber }}</h3>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" :body-style="{ background: '#FA752A', color: '#fff',padding:'10px' }">
					<div style="display:flex;align-items:center;">
						<i class="el-icon-eleme" style="font-size:46px; margin-right:10px;"></i>
						<div style>
							<div>工程师人数</div>
							<h3 style="font-size:28px;">{{ countData.staffNumber }}</h3>
						</div>
					</div>
				</el-card>
			</el-col>
			<!-- <el-col :span="6">
				<el-card class="box-card" :body-style="{ background: '#47BBBB', color: '#fff' }">
					<p>
						<i class="el-icon-eleme"></i>
						设备总数
					</p>
					<p>{{ countData.equipmentNumber }}</p>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" :body-style="{ background: '#FA752A', color: '#fff' }">
					<p>
						<i class="el-icon-s-custom"></i>
						工程师人数
					</p>
					<p>{{ countData.staffNumber }}</p>
				</el-card>
			</el-col>-->
		</el-row>

		<el-card class="box-card">
			<div slot="header" class="clearfix comTitle">
				<span>医院信息</span>
			</div>

			<div class="comBaseInfo">
				<el-image
					class="devBaseFormLogoStr"
					style=" width: 120px; height: 120px; border:1px solid #cccccc;padding:5px;"
					:src="logo"
					:preview-src-list="[logo]"
					:fit="'contain'"
				>
					<div slot="error" class="image-slot">暂无图片</div>
				</el-image>
				<ul>
					<!-- <li>
						<span class="title">医院LOGO</span>
						<span class="content">
							<el-image
								class="devBaseFormLogoStr"
								style="width:120px;height:120px; border:1px solid #cccccc;padding:5px;"
								:src="logo"
								:preview-src-list="[logo]"
							></el-image>
						</span>
					</li>-->
					<li>
						<span class="title">医院名称</span>
						<span class="content">{{ hospitalDetail.name }}</span>
					</li>
					<li>
						<span class="title">联系电话</span>
						<span class="content">{{ hospitalDetail.phone }}</span>
					</li>
					<li>
						<span class="title">地址</span>
						<span class="content">
							{{ '' + (hospitalDetail.provinceName || '') +(hospitalDetail.cityName || '')
							+(hospitalDetail.countyName || '') + hospitalDetail.address }}
						</span>
					</li>
					<li>
						<span class="title">官方网址</span>
						<span class="content">{{ hospitalDetail.website }}</span>
					</li>
					<li>
						<span class="title">医院营业执照</span>
						<span class="content">
							<el-image
								style="position:fixed;top:-9999px;left:-9999px; z-index:9999999999;"
								:src="businessLicense"
								:preview-src-list="[businessLicense]"
								ref="previewbusinessImg"
					:fit="'contain'"
							></el-image>
						</span>
						<el-button plain @click="previewbusinessLicense">查看原图</el-button>
					
					</li>
					<!-- <li>
						<span class="title">添加人</span>
						<span class="content">{{ hospitalDetail.createUserId }}</span>
					</li>-->
					<!-- <li>
						<span class="title">维修范围</span>
						<span class="content">{{ hospitalDetail.scopeMaintenanceId }}</span>
					</li>-->
					<li style="border-bottom:initial;"></li>
				</ul>
			</div>
		</el-card>

		<div style="height:20px;"></div>

		<el-card class="box-card">
			<el-tabs v-model="activeName">
				<el-tab-pane label="设备信息" name="0">
					<Table :data="hospitalDevList" :isHideCheckbox_p="true">
						<el-table-column type="index" width="50"></el-table-column>

						<el-table-column property="devInfoName" label="设备名称"></el-table-column>
						<el-table-column property="affiliatedHospitalName" label="医院名称"></el-table-column>
						<el-table-column property="devInfoModelName" label="设备型号"></el-table-column>
						<el-table-column property="devInfoDepartmentName" label="部门"></el-table-column>
						<el-table-column property="devInfoNns1" label="规格型号"></el-table-column>
						<el-table-column property="devInfoNns2" label="计量单位"></el-table-column>
						<el-table-column property="devInfoNns3" label="设备金额"></el-table-column>
						<el-table-column property="devInfoNumber" label="设备编码"></el-table-column>
						<el-table-column property="devInfoSerialNo" label="生产序列号"></el-table-column>
						<el-table-column property="devInfoTypeName" label="设备类型"></el-table-column>
						<el-table-column property="testCycle" label="计量周期"></el-table-column>
						<el-table-column property="transferBatchNumber" label="保修时间(天)"></el-table-column>
					</Table>

					<el-pagination
						style="margin-top:20px; margin-bottom:20px; text-align:right;"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="deviceInfo.pageNum"
						:page-sizes="[10, 20, 30, 50]"
						:page-size="deviceInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</el-tab-pane>
				<el-tab-pane label="工程师信息" name="1">
					<el-button style="margin:0 0 10px;" type="primary" @click="isShow = true">添加工程师</el-button>

					<Table :tableColumn_p="tableColumn" :data="hospitalEngineer" :isHideCheckbox_p="true">
						<el-table-column label="操作" align="left" fixed="right">
							<template slot-scope="scope">
								<el-button id="btn-update-row" type="primary" @click="deleteSingleHandler(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</Table>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<div style="height:20px;"></div>

		<Dialog :visible.sync="isShow">
			<AddEngineerForm
				title="添加工程师"
				v-if="isShow"
				:isShow_p.sync="isShow"
				:isRefreshList_p.sync="isRefreshList"
				:hospital_p="hospitalDetail"
			></AddEngineerForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	ContractDetailService,
	contractHospitalEngineerService,
	contractDeleteEngineerService,
	contractCountService
} from '@s/company/contract/ContractDetailService';
// eslint-disable-next-line import/no-cycle
import { deviceListService } from '@s/company/device/DeviceService';
import Table from '@c/ui/Table';
import Dialog from '@c/ui/Dialog';
import AddEngineerForm from '@f/company/contractDetail/AddEngineer.form';

export default {
	components: {
		Table,
		Dialog,
		AddEngineerForm
	},
	data() {
		return {
			activeName: 0,
			tableColumn: [
				{
					field: 'nickName',
					label: '人员姓名'
				},
				{
					field: 'telphone',
					label: '联系电话'
				},
				{
					field: 'deptName',
					label: '部门'
				}
			],
			logo: null,
			businessLicense: null,
			total: 0,
			hospitalDetail: {},
			hospitalEngineer: null,
			hospitalDevList: null,
			isShow: false,
			isRefreshList: false,
			countData: {
				equipmentNumber: 0,
				staffNumber: 0
			},
			roleType: 0,
			deviceInfo: {
				pageNum: 1,
				pageSize: 10
			}
		};
	},
	watch: {
		isRefreshList(newValue) {
			if (newValue) {
				this.contractHospitalEngineer(this.$route.query.id);
				this.contractCount(this.$route.query.id);
				this.isRefreshList = false;
			}
		},
		activeName(newValue) {
			this.deviceInfo.pageNum = 1;
			this.deviceInfo.pageSize = 10;
			if (Number(newValue) === 0) {
				this.deviceList();
			} else {
				this.contractHospitalEngineer(this.$route.query.id);
			}
		}
	},
	created() {
		const hospitalId = this.$route.query.id;
		this.contractDetail(hospitalId);
		this.contractHospitalEngineer(hospitalId);
		this.contractCount();
		// 获取设备列表
		this.deviceList();
	},
	methods: {
		previewbusinessLicense() {
			this.$refs.previewbusinessImg.showViewer = true;
		},
		async contractCount() {
			const dataJson = {
				hospitalId: this.$route.query.id
			};
			const res = await contractCountService(dataJson);
			this.countData = res;
		},
		async contractDetail(hospitalId) {
			const dataJson = {
				hospitalId: hospitalId
			};
			const res = await ContractDetailService(dataJson);
			this.hospitalDetail = res;

			this.logo = `${this.$envConfig.imgHttpAdd}orglogo/${res.logo}`;
			this.businessLicense = `${this.$envConfig.imgHttpAdd}orgbusinesslicense/${res.businessLicense}`;
		},
		async contractHospitalEngineer(hospitalId) {
			const dataJson = {
				hospitalId: hospitalId
			};
			const res = await contractHospitalEngineerService(dataJson);
			this.hospitalEngineer = [...res];
		},
		async deleteSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			await contractDeleteEngineerService(dataJson);
			this.contractHospitalEngineer(this.$route.query.id);
			this.contractCount(this.$route.query.id);
		},
		// 设备列表
		async deviceList() {
			const dataJson = {
				hid: this.$route.query.id,
				pageNum: this.deviceInfo.pageNum,
				pageSize: this.deviceInfo.pageSize
			};
			const res = await deviceListService(dataJson);
			console.log('this.hospitalDevList');
			console.log(res);
			this.total = res.total;
			this.hospitalDevList = [...res.records];
			console.log('this.hospitalDevList');
			console.log(this.hospitalDevList);
			console.log(res);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.deviceInfo.pageSize = val;
			this.deviceList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.deviceInfo.pageNum = val;
			this.deviceList();
		}
	}
};
</script>
<style>
.image-slot {
	line-height: 118px;
	text-align: center;
	font-weight: normal;
}
</style>
<style lang="less" scoped>
/deep/ .row-style {
	padding: 10px 0;
}
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
.comBaseInfo {
	display: flex;
	padding: 20px 0;
}
.comBaseInfo ul {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex: 1;
	margin-top: -24px;
}
.comBaseInfo ul li {
	min-height: 30px;
	line-height: 30px;
	margin-top: 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 46%;
}
// 公用
.comTitle {
	color: #373737;
	font-weight: bold;
}
.comBaseInfo .title {
	color: #676968;
	font-weight: bold;
}
.comBaseInfo .content {
	color: #afaeb3;
}
</style>
