<template>
	<el-row>
		<el-col :span="10">
			<el-card class="box-card">
				<div slot="header" class="clearfix comTitle">
					<span>医院信息修改</span>
				</div>
				<el-form
					ref="hospitalForm"
					:model="hospitalForm"
					:rules="hospitalRules"
					size="small"
					label-width="100px"
				>
					<el-form-item label="医院名称" prop="name">
						<el-input v-model="hospitalForm.name" placeholder="请输入医院名称" />
					</el-form-item>
					<el-form-item label="医院等级" prop="levelId">
						<el-select v-model="hospitalForm.levelId" placeholder="请选择医院等级" style="width: 100%">
							<el-option v-for="item in levelIds" :key="item.id" :label="item.grade" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="官方网址" prop="website">
						<el-input v-model="hospitalForm.website" placeholder="请输入官方网址" />
					</el-form-item>
					<el-form-item label="联系电话" prop="phone">
						<el-input v-model="hospitalForm.phone" maxlength="11" placeholder="请输入联系电话" />
					</el-form-item>

					<!-- <el-row>
						<el-col :span="12"> -->
							<el-form-item label="公司LOGO" prop="logo">
								<Upload
									:action="uploadLogoAction"
									class="isShowUpload"
									:limit="1"
									list-type="picture-card"
									:file-list="logoFile"
									:on-success="handlerLogoSuccess"
									:on-remove="handleLogoRemove"
									:isShowTip_p="true"
								>
									<!-- <p>公司logo只能上传一张图片</p> -->
								</Upload>
							</el-form-item>
						<!-- </el-col>
						<el-col :span="12"> -->
							<el-form-item label="营业执照" prop="businessLicense">
								<Upload
									:action="uploadLicenseAction"
									:limit="1"
									class="isShowUpload1"
									list-type="picture-card"
									:file-list="licenseFile"
									:on-success="handlerLicenseSuccess"
									:on-remove="handleLicenseRemove"
									:isShowTip_p="true"
								>
									<!-- <p>营业执照只能上传一张图片</p> -->
								</Upload>
							</el-form-item>
						<!-- </el-col>
					</el-row> -->

					<!-- <el-row> -->
					<!-- <el-col :span="9"> -->
					<el-form-item label="省" prop="province" style="width:25%">
						<el-select
							v-model="hospitalForm.province"
							placeholder="请选择省"
							@change="handlerProvince($event)"
						>
							<el-option
								v-for="item in provinceList"
								:key="item.proCode"
								:label="item.proName"
								:value="item.proCode"
							></el-option>
						</el-select>
					</el-form-item>
					<!-- </el-col> -->
					<!-- <el-col :span="8"> -->
					<el-form-item label="市" prop="city" style="width:25%">
						<el-select v-model="hospitalForm.city" placeholder="请选择市" @change="handlerCity($event)">
							<el-option
								v-for="item in cityList"
								:key="item.proCode"
								:label="item.proName"
								:value="item.proCode"
							></el-option>
						</el-select>
					</el-form-item>
					<!-- </el-col> -->
					<!-- <el-col :span="7"> -->
					<el-form-item label="县" prop="county" style="width:30%">
						<el-select v-model="hospitalForm.county" placeholder="请选择县" style="width: 70%">
							<el-option
								v-for="item in countyList"
								:key="item.proCode"
								:label="item.proName"
								:value="item.proCode"
							></el-option>
						</el-select>
					</el-form-item>
					<!-- </el-col> -->
					<!-- </el-row> -->
					<el-form-item label="地址详情" prop="address">
						<div class="address">
							<el-input v-model="hospitalForm.address" placeholder="请输入地址详情" style="width: 200px" />
							<el-button type="primary" @click="addressHandler" style="margin-left: 20px">查询</el-button>
						</div>
					</el-form-item>
					<div style="width:100%; float:left; clear:both;"></div>
					<el-form-item label="纬度" style="display:none;">
						<el-input readonly type="number" v-model="hospitalForm.latitude" placeholder="请输入纬度" />
					</el-form-item>
					<el-form-item label="经度" style="display:none;">
						<el-input readonly type="number" v-model="hospitalForm.longitude" placeholder="请输入经度" />
					</el-form-item>
					<div style="width:100%; height:320px; display: flex;position:relative;">
						<div class="map" id="Map" style="width: 100%; height: 100%"></div>
						<div
							v-show="hospitalForm.latitude"
							style="position:absolute;right:0;bottom:0;z-index:9999;background:rgba(0,0,0,0.2)"
						>
							纬度:{{hospitalForm.latitude}}
							<br />
							经度:{{hospitalForm.longitude}}
						</div>
						<div
							id="r-result"
							ref="mapResult"
							style="width: 44%; height: 100%;position:absolute;left:0;top:0;z-index:9999"
						></div>
					</div>
					<el-form-item style="text-align: right;width:100%; margin-top:15px;">
						<span class="dialog-footer">
							<!-- <el-button @click="hospitalFormCancel">取 消</el-button> -->
							<el-button type="primary" @click="hospitalFormSubmit">确 定</el-button>
						</span>
					</el-form-item>
				</el-form>
			</el-card>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { hospitalDetailService, hospitalLevelListService, hospitalEditService } from '@s/system/EditService';
// eslint-disable-next-line import/no-cycle
import { regionService, cityService, grountService } from '@s/region/regionService';
import Upload from '@c/ui/Upload.vue';
import { hgetStorage } from '@u/htools.web.js';
// eslint-disable-next-line import/no-cycle
import { arrayIncludeItem } from '@u/index';

export default {
	props: ['selectData_p', 'editId_p'],
	components: { Upload },
	data() {
		return {
			map: null,
			selectedOptions: [],
			provinceList: [],
			cityList: [],
			countyList: [],
			editId: -1,
			hospitalForm: {
				name: '',
				levelId: '',
				website: '',
				phone: '',
				province: '',
				city: '',
				county: '',
				address: '',
				userName: '',
				password: '',
				logo: '',
				businessLicense: '',
				latitude: '',
				longitude: ''
			},
			levelIds: [],
			hospitalRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				]
			},
			uploadLogoAction: '/',
			uploadLicenseAction: '/',
			logoFile: [],
			licenseFile: []
		};
	},
	watch: {},
	created() {
		this.getRegion();
		this.hospitalLevelListService();
	},
	mounted() {
		setTimeout(() => {
			this.uploadLogoAction = `${this.$envConfig.btyong_3}uploadPic/uploadLogo`;
			this.uploadLicenseAction = `${this.$envConfig.btyong_3}uploadPic/uploadBusinessLicense`;
			console.log('this.$envConfig.btyong_1');
			console.log(this.$envConfig.btyong_1);
			console.log(this.uploadLogoAction);
		}, 200);
		this.hospitalDetailService();
		this.editId = hgetStorage('organization');
		this.baiduMap();
	},
	methods: {
		async hospitalDetailService() {
			console.log(123123);
			const json = {
				hospitalId: hgetStorage('organization')
			};
			const res = await hospitalDetailService({ ...json });
			await this.handlerProvince(res.province);
			await this.handlerCity(res.city);

			this.logoFile = [];
			this.licenseFile = [];
			res.levelId = Number(res.levelId);
			res.province = Number(res.province);
			res.city = Number(res.city);
			res.county = Number(res.county);
			console.log('this.$envConfig.imgHttp');
			console.log(this.$envConfig.imgHttpAdd);
			this.logoFile.push({
				name: res.logo,
				url: `${this.$envConfig.imgHttpAdd}orglogo/${res.logo}`
			});
			this.licenseFile.push({
				name: res.businessLicense,
				url: `${this.$envConfig.imgHttpAdd}orgbusinesslicense/${res.businessLicense}`
			});
			this.hospitalForm = { ...res };
			if (this.hospitalForm.latitude) {
				setTimeout(() => {
					// 创建地图实例
					const point = new BMapGL.Point(this.hospitalForm.longitude, this.hospitalForm.latitude);
					// 创建点坐标
					this.map.centerAndZoom(point, 16);

					const marker = new BMapGL.Marker(point);
					this.map.addOverlay(marker);
				}, 1000);
			}
			setTimeout(() => {
				this.isShowUpload();
				this.isShowUpload1();
			}, 10);
			const result = arrayIncludeItem(this.levelIds, res.levelId);
			if (!result) {
				this.hospitalForm.levelId = null;
			}
		},
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--picture-card');
			if (this.hospitalForm.logo === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		isShowUpload1() {
			const isShowUpload = document.querySelector('.isShowUpload1 .el-upload--picture-card');
			if (this.hospitalForm.businessLicense === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handlerLogoSuccess(res) {
			this.hospitalForm.logo = res.result;
			this.isShowUpload();
		},
		handleLogoRemove() {
			this.hospitalForm.logo = '';
			setTimeout(() => {
				this.isShowUpload();
			}, 1000);
		},
		handlerLicenseSuccess(res) {
			this.hospitalForm.businessLicense = res.result;
			this.isShowUpload1();
		},
		handleLicenseRemove() {
			this.hospitalForm.businessLicense = '';
			setTimeout(() => {
				this.isShowUpload1();
			}, 1000);
		},
		// 获取医院等级列表
		async hospitalLevelListService() {
			const res = await hospitalLevelListService({ pageNum: 1, pageSize: 999 });
			this.levelIds = res.records;
		},
		// 获取省市县数据
		async getRegion() {
			const res = await regionService();
			this.provinceList = res;
		},
		// 省选择玩显示市list
		async handlerProvince(e) {
			const dataJson = {
				regionNum: e
			};
			const res = await cityService(dataJson);
			this.cityList = res;

			this.provinceList.forEach(item => {
				if (Number(item.proCode) === Number(e)) {
					this.addressProvince = item.proName;
				}
			});
			this.hospitalForm.city = null;
			this.hospitalForm.county = null;
		},
		// 市选择玩显示区list
		async handlerCity(e) {
			const dataJson = {
				regionNum: e
			};
			const res = await grountService(dataJson);
			this.countyList = res;
			this.hospitalForm.county = null;
		},
		hospitalFormSubmit() {
			this.$refs.hospitalForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await hospitalAddService(this.hospitalForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.hospitalForm
						};
						await hospitalEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.hospitalFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		hospitalFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		// 地图经纬度问题 -----------S--------------
		baiduMap() {
			this.map = new BMapGL.Map('Map');
			// 创建地图实例
			const point = new BMapGL.Point(116.41338678224898, 39.91270163195107);
			// 创建点坐标
			this.map.centerAndZoom(point, 10);
			// 开启鼠标滚轮缩放
			this.map.enableScrollWheelZoom(true);
		},
		addressHandler() {
			this.map.clearOverlays();
			const local = new BMapGL.LocalSearch(this.map, {
				renderOptions: { map: this.map, panel: 'r-result' },
				pageCapacity: 5
			});
			setTimeout(() => {
				console.log('this.addressProvince');
				console.log(this.addressProvince);
			}, 1000);
			if (this.addressProvince) {
				local.search(this.addressProvince + this.hospitalForm.address);
			} else {
				local.search(this.hospitalForm.address);
			}
			local.setInfoHtmlSetCallback(e => {
				// 创建地图实例
				const point = new BMapGL.Point(Number(e.point.lng) - 0.0008, e.point.lat);
				// 创建点坐标
				setTimeout(() => {
					this.map.centerAndZoom(point, 19);
					this.hospitalForm.latitude = e.point.lat;
					this.hospitalForm.longitude = e.point.lng;
				}, 400);
			});
		}
		// 地图经纬度问题 -----------E--------------
	}
};
</script>

<style lang='less' scoped>
/deep/ .box-card {
	width: 1000px;
	overflow: hidden;
}
/deep/.box-card .el-form-item {
	width: 45%;
	margin-right: 15px;
	float: left;
}
</style>
