<template>
	<div class="app-container">
		<EmpSummarySearchForm @searchForm="searchFormHandler" @download="downloadExlcel"></EmpSummarySearchForm>
		<div class="class20" style="width:100%; height:15px;"></div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData"
			highlight-current-row row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
			<el-table-column label="日期" prop="date">
				<template slot-scope="scope">{{ scope.row.date }}</template>
			</el-table-column>
			<el-table-column label="姓名" prop="nickname" width="90px"></el-table-column>
			<!-- <el-table-column label="电话" prop="telphone"></el-table-column> -->
			<el-table-column label="维修" prop="repaircnt">
				<template slot-scope="scope">
					接单:<b>{{ scope.row.jdCnt || 0 }}</b>次 / 到场:<b>{{ scope.row.dcCnt }}</b>次 / 参与:<b>{{ scope.row.cyCnt
					}}</b>次 / 完成:<b>{{ scope.row.wcCnt }}</b>次
					<!-- {{ scope.row.repaircnt || 0 }}次 -->
				</template>
			</el-table-column>
			<!-- <el-table-column label="接单次数" prop="jdCnt">
				<template slot-scope="scope">{{ scope.row.jdCnt || 0 }}次</template>
			</el-table-column>
			<el-table-column label="到场次数" prop="dcCnt">
				<template slot-scope="scope">{{ scope.row.dcCnt || 0 }}次</template>
			</el-table-column>
			<el-table-column label="参与次数" prop="cyCnt">
				<template slot-scope="scope">{{ scope.row.cyCnt || 0 }}次</template>
			</el-table-column>
			<el-table-column label="完成次数" prop="wcCnt">
				<template slot-scope="scope">{{ scope.row.wcCnt || 0 }}次</template>
			</el-table-column> -->
			<el-table-column label="巡检">
				<template slot-scope="scope">
					{{ scope.row.inspectcnt || 0 }}次 / {{ scope.row.inspectdevcnt || 0 }}台
				</template>
			</el-table-column>
			<el-table-column label="保养">
				<template slot-scope="scope">
					{{ scope.row.upkeepcnt || 0 }}次 / {{ scope.row.upkeepdevcnt || 0 }}台
				</template>
			</el-table-column>
			<el-table-column label="计量">
				<template slot-scope="scope">
					{{ scope.row.measurecnt || 0 }}次 / {{ scope.row.measuredevcnt || 0 }}台
				</template>
			</el-table-column>
			<!-- <el-table-column label="修改时间" prop="createDate">
				<template slot-scope="scope">{{ scope.row.updateDate | formatDate }}</template>
			</el-table-column> -->

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<!-- <el-button id="btn-update-row" type="text" @click="showDetail(scope.row)">
						详情
					</el-button>
					<el-button type="text" @click="toEmpSummaryEcharts(scope.row)">
						维修分析
					</el-button> -->
					<el-link type="primary" @click="showDetail(scope.row)" style="margin-right:20px;">详情</el-link>
					<el-link type="success" @click="toEmpSummaryEcharts(scope.row)" style="margin-right:20px;">维修分析</el-link>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageNum" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="'工作统计详情'" :visible.sync="isShowEmpSummaryDetailDialog" width="80%"
			@close="hideEmpSummaryDetailDialog">
			<EmpSummaryDetail :pSearchForm="searchForm" v-if="isShowEmpSummaryDetailDialog"></EmpSummaryDetail>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { empsmmarytabService } from '@s/company/empsummary/empsummaryService';
import Dialog from '@c/ui/Dialog';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import EmpSummarySearchForm from '@f/company/EmpSummary/EmpSummarySearch.form';
import EmpSummaryDetail from '@f/company/EmpSummary/EmpSummaryDetail';
import ListMixin from '@m/List.mixin';
import { hgetStorage, hsetStorage, hremoveStorage, htimeFormat } from '@/util/htools.web';

export default {
	name: 'EmpSummary',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		EmpSummarySearchForm,
		EmpSummaryDetail
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			tableDownData: [],
			currentUserOrganization: null,
			downLoading: false,
			isShowEmpSummaryDetailDialog: false,
			dialogName: null,
			// 分页
			total: 0,
			pageNum: 1,
			pageSize: 10,
			// 表格
			tableData: [],
			selectData: [],
			// 搜索
			searchForm: {},
			editId: -1
		};
	},
	computed: {},
	watch: {
		pageNum(newValue) {
			this.pageNum = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageNum === 1) {
					this.getList();
				} else {
					this.pageNum = 1;
				}
			}
		}
	},
	mounted() {
		// this.getList();
		this.currentUserOrganization = hgetStorage('organization');
		// console.log('hgetStorage EmpSummarySearchForm');
		const EmpSummarySearchForm = hgetStorage('EmpSummarySearchForm');
		if (EmpSummarySearchForm) {
			const { row } = EmpSummarySearchForm;
			this.showDetail(row);
			hremoveStorage('EmpSummarySearchForm');
		}
	},
	methods: {
		async getList() {
			const dataJson = {
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await empsmmarytabService(dataJson);
			if (res.records.length > 0) {
				res.records.forEach(item => {
					item.date = `${htimeFormat(
						this.searchForm.begin,
						'{y}-{m}-{d}'
					)} - ${htimeFormat(this.searchForm.end, '{y}-{m}-{d}')}`;
				});
			}
			// this.listMixin(res);
			this.tableData = res.records;
			this.total = res.total || 0;
		},
		async getDwonList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 9999,
				...this.searchForm
			};
			const res = await empsmmarytabService(dataJson);
			if (res.records.length > 0) {
				res.records.forEach(item => {
					item.date = `${htimeFormat(
						this.searchForm.begin,
						'{y}-{m}-{d}'
					)} - ${htimeFormat(this.searchForm.end, '{y}-{m}-{d}')}`;
				});
			}
			// this.listMixin(res);
			this.tableDownData = res.records;
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			delete this.searchForm?.row;
			delete this.searchForm?.pSearch;
			this.getList();
		},
		showDetail(row) {
			this.searchForm.pSearch = { ...this.searchForm };
			this.searchForm.row = { ...row };
			delete this.searchForm.pSearch?.row;
			this.isShowEmpSummaryDetailDialog = false;
			this.isShowEmpSummaryDetailDialog = true;
			this.editId = row.id;
		},

		toEmpSummaryEcharts(row) {
			const searchForm = { ...this.searchForm };
			if (searchForm.hosid) {
				searchForm.hids = [searchForm.hosid];
			}
			if (row.id) {
				searchForm.dutyPeople = row.id;
				searchForm.engineers = [row.id];
			}
			this.$router.push({
				name: 'EmpSummaryEcharts',
				query: { ...searchForm, nickname: row.nickname }
			});
		},
		hideEmpSummaryDetailDialog() {
			hremoveStorage('EmpSummarySearchForm');
		},
		async downloadExlcel(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.downLoading = true;
			await this.getDwonList();
			this.getList();

			// const tHeader = ['日期', '姓名', '维修', '巡检次数', '巡检台数', '保养次数', '保养台数', '计量次数', '计量台数'];
			const tHeader = [
				'日期',
				'姓名',
				// '维修',
				'接单次数',
				'到场次数',
				'参与次数',
				'完成次数',
				'巡检次数',
				'巡检台数',
				'保养次数',
				'保养台数'
			];

			this.tableDownData.forEach(item => {
				item.excelwx = `${item.repaircnt || 0}`;
				item.exceljd = `${item.jdCnt || 0}`;
				item.exceldc = `${item.dcCnt || 0}`;
				item.excelcy = `${item.cyCnt || 0}`;
				item.excelwc = `${item.wcCnt || 0}`;
				item.excelxj = `${item.inspectcnt || 0}`;
				item.excelxjts = `${item.inspectdevcnt || 0}`;
				item.excelby = `${item.upkeepcnt || 0}`;
				item.excelbyts = `${item.upkeepdevcnt || 0}`;
				item.exceljl = `${item.measurecnt || 0}`;
				item.exceljlts = `${item.measuredevcnt || 0}`;
			});

			// eslint-disable-next-line no-unused-vars
			// const filterVal = ['date', 'nickname', 'excelwx', 'excelxj', 'excelxjts', 'excelby', 'excelbyts', 'exceljl', 'exceljlts'];
			const filterVal = [
				'date',
				'nickname',
				// 'excelwx',
				'exceljd',
				'exceldc',
				'excelcy',
				'excelwc',
				'excelxj',
				'excelxjts',
				'excelby',
				'excelbyts'
			];
			const data = this.formatJson(filterVal, this.tableDownData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;
			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `员工工作统计${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.downLoading = false;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 500px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 100%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .el-form-item__content {
	// width: 100%;
}

/deep/ .el-select {
	width: 100%;
}
</style>
