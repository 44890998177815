<template>
	<div class="QRCode">
		<!-- <div style="display:flex; align-items: center; margin-bottom: 10px;">
      <span style="width:40px;">大小</span>
      <el-input-number style="width:150px;" v-model="qrSize" :step="2"></el-input-number>
    </div>
    <div style="display:flex; align-items: center; margin-bottom: 10px;">
      <span style="width:40px;">顶部</span>
      <el-input-number style="width:150px;" v-model="qrMtTop" :step="2"></el-input-number>
    </div> -->
		<div class="print_container">
			<div id="printMe1">
				<div v-for="item in selectData_p" :key="item.id"
					style="page-break-after: always; margin-bottom: 20px; padding-top:10px; padding-left:10px; padding-right:4px;">
					<div class="printItem">
						<!-- <qriously :size="qrSize" class="qrCanvas" :level="'L'" :padding="0"
							:value="`${$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}&test=aaaaaaaaaaaaaaaaaaaaaaaa`" /> -->
						<qriously :size="qrSize" class="qrCanvas" :level="'L'" :padding="0"
							:value="item.valueUrl" />
						<ul :style="{ marginTop: qrMtTop + 'px' }" style="position:relative;">
							<li><b class="key">科室: </b><b class="value">{{ item.devInfoDepartmentName }}</b></li>
							<li>
								<span v-if="item.devInfoNns1"><b class="key">型号: </b><b class="value">{{ item.devInfoNns1
								}}</b></span>
							</li>
							<li>
								<span v-if="item.devInfoBrand"><b class="key">品牌: </b><b class="value">{{ item.devInfoBrand
								}}</b></span>
							</li>
							<li style="position:absolute;bottom: 5px; font-size:12px;height:initial;"><pre>{{ valueCom }}</pre></li>
						</ul>
					</div>
					<p style="line-height: 20px;font-size: 17px;font-weight: 700;">{{ item.devInfoName }}</p>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="dialog-footer">
				<el-button @click="printCancel">取 消</el-button>
				<span v-print="'#printMe1'">
					<el-button type="primary" class="pringQR">确 定</el-button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['selectData_p', 'valueCom'],
	data() {
		return {
			qrSize: 120,
			qrMtTop: 0
		};
	},
	mounted() { },
	watch: {
		selectData_p: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					newVal.forEach(item => {
						let tempStr = '&test=aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

						let valueUrl = `${this.$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}`;
						tempStr = tempStr.substring(0, 80 - valueUrl.length);
						valueUrl = `${valueUrl}${tempStr}`;
						// {{ $envConfig.OfficialWebsite + 'bx?deviceId=' + item.devInfoId + '&hospitalId=' + item.affiliatedHospitalId }}
						item.valueUrl = valueUrl;
					});
				}
			},
			immediate: true
		}
	},
	methods: {
		printCancel() {
			this.$emit('update:isShowPrintDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
// 二维码
.QRCode {
	position: relative;

	.print_container {
		max-height: 500px;
		overflow-y: auto;
		background: #eeeeee;
		display: flex;
		padding: 10px;
		align-items: center;
		flex-direction: column;
	}

	#printMe1 {
		width: 70mm;
		// height: 50mm;
		padding: 2mm;
		background: #ffffff;
	}

	.footer {
		width: 100%;
		text-align: right;
		margin-top: 10px;
		z-index: 10;

		span {
			padding-left: 10px;
		}
	}
}

.printItem {
	display: flex;
}

.printItem ul {
	margin-left: 10px;
	flex: 1;
	max-height: 130px;
	overflow: hidden;
}

.printItem ul li {
	// display: flex;
	// flex-direction: row;
	margin-bottom: 2px;
	line-height: 16px;
	font-size: 13px;
	// height: 18px;
	white-space: nowrap;
}

.printItem ul li .key {
	// width: 40px;
}

.printItem ul li .value {
	flex: 1;
	white-space: pre-wrap;
}
</style>
