<template>
	<div class="app-container">
		<DevearningSearchForm
			@searchForm="searchFormHandler"
			:elForm_P="searchForm"
		></DevearningSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devearning'"
		>
			<template v-slot:lastBtn>
				<el-button
					:disabled="tableData.length == 0 || !searchForm.devid"
					type="primary"
					@click="toAllHospitalDevearningDev"
				>
					设备效益
				</el-button>
				<el-button
					type="primary"
					@click="importDev"
					:disabled="disabledUpload || !searchForm.devid"
					:title="disabledUpload || !searchForm.devid ? '请选择设备' : ''"
				>
					导入导出
				</el-button>
			</template>
		</Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			highlight-current-row
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="科室" prop="departname"></el-table-column>
			<el-table-column label="设备" prop="devname"></el-table-column>

			<el-table-column label="日期" prop="actionTime">
				<template slot-scope="scope">
					{{ scope.row.actionTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
				</template>
			</el-table-column>
			<el-table-column label="操作人" prop="operator"></el-table-column>
			<el-table-column label="价格" prop="price"></el-table-column>
			<el-table-column label="所作项目" prop="title"></el-table-column>
			<!-- <el-table-column label="所作操作" prop="body"></el-table-column> -->

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devearning', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'devearning', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DevearningAdd
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
				:elForm_P="searchForm"
			></DevearningAdd>
		</Dialog>

		<Dialog
			title="导出导入"
			:visible.sync="isShowimportDevDialog"
			width="50%"
			class="uploadExcelDialog"
		>
			<div class="uploadDes">
				<h3>说明</h3>
				<ul>
					<li>1、此导入为具体某一个设备的效益数据导入</li>
					<li>2、按excel模板填写每列数据</li>
					<li>3、导入后会新增excel中的内容，注意请勿重复导入</li>
					<li>4、执行日期格式请使用 yyyy-MM-dd</li>
					<li>5、价格必须为数字</li>
				</ul>
			</div>
			<el-upload
				class="upload-demo my-10"
				:before-upload="importBeforeUploadFile"
				:on-success="importDevSuccess"
				:on-exceed="importDevExceedFile"
				:on-remove="importDevRemove"
				accept=".xlsx"
				:headers="headers"
				:action="uploadLogoAction"
				multiple
				:limit="limitNum"
				:file-list="fileList"
			>
				<el-button size="small" type="success" :disabled="disabledUpload">
					点击上传
				</el-button>
				<el-button
					size="small"
					type="primary"
					@click="exportDev"
					:disabled="disabledUpload"
				>
					点此下载导入模板及现有数据
				</el-button>
				<div slot="tip" class="el-upload__tip">只能上传单个.xlsx文件，且不超过10M</div>
			</el-upload>
			<!-- <div style="color: #f56c6c;" class="uploadErr">
				<template v-if="departErrors">
					<p class="errTitle">科室表错误：</p>
					<p v-for="(item, index) in departErrors" :key="index">{{ item }}</p>
				</template>
				<template v-if="devErrors">
					<p class="errTitle">设备表错误：</p>
					<p v-for="(item, index) in devErrors" :key="index">{{ item }}</p>
				</template>
				<template v-if="facErrors">
					<p class="errTitle">厂家表错误：</p>
					<p v-for="(item, index) in facErrors" :key="index">{{ item }}</p>
				</template>
			</div> -->
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { listService, deleteService, detailService } from '@s/hospital/devBase/DevearningService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import DevearningAdd from '@f/hospital/devBase/DevearningAdd.form';
import DevearningSearchForm from '@f/hospital/devBase/DevearningSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'CompanyDept',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		DevearningAdd,
		DevearningSearchForm
	},
	data() {
		return {
			// 导入
			isShowimportDevDialog: false,
			uploadLogoAction: '',
			timerUploadUrl: null,
			disabledUpload: true,
			headers: { Authorization: 1, token: 2 },
			limitNum: 1,
			// uploadLogoAction: `${this.$envConfig.btyong_5}devBase/${this.$envConfig.uploadCompanyDevImgList}`,
			fileList: [],
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
				this.selectData = [];
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.getList();
					this.selectData = [];
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	created() {
		const { devid, departid, devname, departname } = this.$route.query;
		this.searchForm.devid = devid;
		this.searchForm.departid = departid;
		this.searchForm.devname = devname;
		this.searchForm.departname = departname;
	},
	mounted() {
		this.getList();
		this.currentUserOrganization = hgetStorage('organization');
		// setTimeout(() => {
		// 	console.log(this.searchForm);
		// }, 1000 * 5);

		this.headers.Authorization = hgetStorage('token');
		this.disabledUploadHandler();
	},
	methods: {
		disabledUploadHandler() {
			console.log(this.searchForm);
			this.disabledUpload = true;
			if (this.searchForm.devid && this.searchForm.departid) {
				this.disabledUpload = false;
			}
		},
		// 导出
		exportDev() {
			window.open(
				'https://ygongyun.com/web/comp/api/export/downloadImportXyTemp'
				// `https://ygongyun.com/web/comp/api/export/exportDev?token=${encodeURIComponent(
				// 	hgetStorage('token')
				// )}`
			);
		},
		// 导入
		importDev() {
			this.isShowimportDevDialog = true;
			this.timerUploadUrl = setInterval(() => {
				if (this.$envConfig) {
					clearInterval(this.timerUploadUrl);
					this.disabledUpload = false;
					const { departid, devid } = this.searchForm;
					this.uploadLogoAction = `${this.$envConfig.OfficialWebsite}web/comp/api/export/importDevXy?departid=${departid}&devid=${devid}`;

					console.log('this.$envConfig');
					console.log(this.searchForm);
					console.log(
						`${this.$envConfig.OfficialWebsite}web/comp/api/export/importDevXy?departid=${departid}&devid=${devid}`
					);
				}
			}, 100);
		},
		importDevSuccess(res, file) {
			console.log(res);
			if (res.code !== 200) {
				this.$message.warning('导入有误，请联系管理员!');
				this.fileList = [];
			} else {
				this.$alert('导入成功!', '', {
					confirmButtonText: '确定',
					type: 'success',
					dangerouslyUseHTMLString: true,
					showClose: false,
					customClass: 'uploadExcelSuccess',
					callback: action => {
						this.getList();
						this.isShowimportDevDialog = false;
						this.fileList = [];
					}
				});
			}
		},
		importDevRemove(file, fileList) {
			// this.departErrors = null;
			// this.devErrors = null;
			// this.facErrors = null;
		},
		importDevExceedFile(files, fileList) {
			this.$message.warning(`只能选择 ${this.limitNum} 个文件`);
		},
		importBeforeUploadFile(file) {
			console.log('before upload');
			console.log(file);
			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const size = file.size / 1024 / 1024;
			if (extension !== 'xlsx') {
				this.$message.warning('只能上传后缀是.xlsx的文件');
			}
			if (size > 10) {
				this.$message.warning('文件大小不得超过10M');
			}
		},
		importDevhandleRemove(file, fileList) {
			console.log(file, fileList);
		},

		importDevbeforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		// 去设备效益
		toAllHospitalDevearningDev() {
			const { devid } = this.searchForm;
			this.$router.push({
				name: 'AllHospitalDevearningDev',
				query: { devid: devid }
			});
		},
		async getList() {
			this.disabledUploadHandler();
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await listService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await detailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await deleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await detailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await deleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			// setTimeout(() => {
			// 	console.log(this.searchForm);
			// }, 1000 * 5);
			this.getList();
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 500px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 100%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .el-form-item__content {
	// width: 100%;
}

/deep/ .el-select {
	width: 100%;
}
.uploadExcelDialog {
}

/deep/ .uploadExcelDialog .el-dialog .el-dialog__body {
	padding-top: 0 !important;
}
.uploadDes {
}

.uploadDes h3 {
}

.uploadDes ul li {
	line-height: 26px;
}
</style>
