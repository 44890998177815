<template>
	<el-select
		ref="selectTreeEle"
		:value="nodeName"
		v-bind="$attrs"
		v-on="$listeners"
		class="select-item"
		:filterable="filterable_p"
		:clearable="clearable_p"
		:size="size_p"
		@clear="clearHandler"
		:filter-method="filterHandler"
		@blur="blurHandler"
	>
		<el-option :value="nodeValue" style="height: auto">
			<el-tree
				ref="tree"
				v-bind="$attrs"
				v-on="$listeners"
				highlight-current
				accordion
				@node-click="nodeClickHandler"
				:filter-node-method="filterNode"
				:default-expanded-keys="defaultExpandedKeys"
			></el-tree>
		</el-option>
	</el-select>
</template>

<script>
import { getTreeNodeById } from '@u/htools.tree';

export default {
	inheritAttrs: false,
	props: {
		nodeValue: {
			type: [Number, String]
		},
		/* 是否可搜索 */
		filterable_p: {
			type: Boolean,
			default: true
		},
		/* 是否可以清空选项 */
		clearable_p: {
			type: Boolean,
			default: true
		},
		/* 输入框尺寸 */
		size_p: {
			type: String,
			default: '' // medium/small/mini
		},
		isLastLeaf: {
			type: Boolean,
			default: false
		},
		// 是否多选
		isMultiple: {
			type: Boolean,
			default: false
		}
	},
	model: {
		prop: 'nodeValue',
		event: 'input'
	},
	data() {
		return {
			nodeName: null,
			defaultExpandedKeys: []
		};
	},
	watch: {
		nodeValue(newValue) {
			if (newValue) {
				this.defaultExpandedKeys = [newValue];
				getTreeNodeById(this.$attrs.data, this.$attrs['node-key'], newValue, node => {
					this.nodeName = node.label;
				});
			}
		},
		immediate: true
	},
	methods: {
		blurHandler() {
			if (!this.value) {
				this.filterHandler('');
			}
		},
		nodeClickHandler(node) {
			if (this.isLastLeaf) {
				if (!node.children) {
					this.nodeName = node.label;
					/* this.$emit('update:nodeValue_p', node[this.$attrs['node-key']]); */
					this.$emit('input', node[this.$attrs['node-key']]);
					this.$emit('nodeInfo', node);
					this.$refs.selectTreeEle.blur();
					this.$listeners.change && this.$listeners.change();
				}
			} else {
				/* this.$emit('update:nodeValue_p', node[this.$attrs['node-key']]); */
				this.nodeName = node.label;
				this.$emit('input', node[this.$attrs['node-key']]);
				this.$emit('nodeInfo', node);
				this.$refs.selectTreeEle.blur();
				this.$listeners.change && this.$listeners.change();
			}
		},
		clearHandler() {
			this.nodeName = null;
			/* this.$emit('update:nodeValue_p', 0); */
			this.$emit('input', 0);
			this.$listeners.change && this.$listeners.change();
		},
		filterHandler(searchKey) {
			this.$refs.tree.filter(searchKey);
		},
		filterNode(value, data) {
			console.log(value);
			console.log(data);
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		}
	}
};
</script>

<style scoped lang="less">
.el-select-dropdown__item {
	background-color: #fff;
	padding: 0 !important;
}
/deep/ .el-tree-node__label {
	font-weight: normal;
}
</style>
