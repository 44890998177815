<template>
	<div class="company">
		<h3>企业多选</h3>
		<el-tree :data="data" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current :props="defaultProps" @check="treeCheck"></el-tree>
		<h3>企业单选</h3>
		<el-tree
			:data="singleData"
			show-checkbox
			default-expand-all
			node-key="id"
			ref="tree"
			highlight-current
			:props="defaultProps"
			@check-change="handleClick"
			@check="singleTreeCheck"
		></el-tree>
		<h3>企业主列表</h3>
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="操作" align="center" fixed="right" width="350">
				<template slot-scope="scope">
					<el-button type="primary" @click="editSingleHandler(scope.row)">编辑</el-button>
					<el-button type="danger" @click="deleteSingleHandler(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</Table>
		<h3>企业子列表</h3>
		<Table ref="tableDom" :data.sync="tableDataChild" :tableColumn_p="tableColumnChild" :selectData_p.sync="selectDataChild">
			<el-table-column label="操作" align="center" fixed="right" width="350">
				<template slot-scope="scope">
					<el-button type="primary" @click="editSingleHandler(scope.row)">编辑</el-button>
					<el-button type="danger" @click="deleteSingleHandler(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</Table>
		<h3>图表</h3>
		<h5>饼图</h5>
		<div style="width: 500px; height: 500px">
			<div id="pieChart"></div>
		</div>
		<h5>折线图</h5>
		<div>
			<div id="chartLine111"></div>
		</div>
		<h3>监控</h3>
		<iframe src="" frameborder="0"></iframe>
	</div>
</template>

<script>
import Table from '@c/ui/Table';
import { Chart } from '@antv/g2';

export default {
	data() {
		return {
			amount: 0,
			chartPieObj: null, // 饼图
			chartPieData: [
				{ type: '耗电', value: 600 },
				{ type: '耗气', value: 400 }
			], // 饼图数据
			chartLineObj: null, // 折线图
			chartLinedata: [
				{ time: '02', tem: 11, gas: 1.2 },
				{ time: '04', tem: 35, gas: 2.3 },
				{ time: '06', tem: 26, gas: 0.5 },
				{ time: '08', tem: 15, gas: 1.9 },
				{ time: '10', tem: 26, gas: 3.2 },
				{ time: '12', tem: 28, gas: 5.2 },
				{ time: '14', tem: 8, gas: 7.3 },
				{ time: '16', tem: 18, gas: 6.6 },
				{ time: '18', tem: 0, gas: 4.7 },
				{ time: '20', tem: 20, gas: 1.6 },
				{ time: '22', tem: 25, gas: 0.9 },
				{ time: '24', tem: 40, gas: 1.2 },
				{ time: '02', tem: 11, gas: 1.2 }
			], // 折线图数据
			data: [
				{
					id: '1',
					label: '一级 1',
					children: [
						{
							id: '4',
							label: '二级 1-1',
							children: [
								{
									id: '9',
									label: '三级 1-1-1'
								},
								{
									id: '10',
									label: '三级 1-1-2'
								}
							]
						}
					]
				},
				{
					id: '2',
					label: '一级 2',
					children: [
						{
							id: '5',
							label: '二级 2-1'
						},
						{
							id: '6',
							label: '二级 2-2'
						}
					]
				},
				{
					id: '3',
					label: '一级 3',
					children: [
						{
							id: '7',
							label: '二级 3-1'
						},
						{
							id: 8,
							label: '二级 3-2'
						}
					]
				}
			],
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			singleData: [
				{
					id: 1,
					label: '一级 1',
					children: [
						{
							id: 4,
							label: '二级 1-1',
							children: [
								{
									id: 9,
									label: '三级 1-1-1'
								},
								{
									id: 10,
									label: '三级 1-1-2'
								}
							]
						}
					]
				},
				{
					id: 2,
					label: '一级 2',
					children: [
						{
							id: 5,
							label: '二级 2-1'
						},
						{
							id: 6,
							label: '二级 2-2'
						}
					]
				},
				{
					id: 3,
					label: '一级 3',
					children: [
						{
							id: 7,
							label: '二级 3-1'
						},
						{
							id: 8,
							label: '二级 3-2'
						}
					]
				}
			],
			tableData: [],
			tableColumn: [
				{
					label: 'id',
					field: 'id'
				},
				{
					label: '企业名称',
					field: 'name'
				},
				{
					label: '设备名',
					field: 'id'
				},
				{
					label: '用能',
					field: 'name'
				},
				{
					label: '生产每KG用能',
					field: 'id'
				},
				{
					label: '日期',
					field: 'name'
				},
				{
					label: '类型',
					field: 'id'
				},
				{
					label: '内容',
					field: 'name'
				},
				{
					label: '电表名',
					field: 'id'
				},
				{
					label: '监控设备',
					field: 'name'
				},
				{
					label: '倍率',
					field: 'name'
				},
				{
					label: '期初',
					field: 'id'
				},
				{
					label: '耗电',
					field: 'name'
				},
				{
					label: '耗气',
					field: 'name'
				}
			],
			selectData: [],
			tableDataChild: [],
			tableColumnChild: [
				{
					label: '日期时间',
					field: 'date'
				},
				{
					label: '状态',
					field: 'state'
				}
			],
			selectDataChild: []
		};
	},
	components: {
		// eslint-disable-next-line vue/no-unused-components
		Table
	},
	mounted() {
		// 饼图
		this.chartPieObj = new Chart({
			container: 'pieChart',
			autoFit: true,
			width: 200, // 指定图表宽度
			height: 300 // 指定图表高度
		});
		this.chartPieObj.data(this.chartPieData);

		this.chartPieObj.coordinate('theta', {
			radius: 0.75
		});
		this.chartPieObj.tooltip({
			showMarkers: false
		});

		this.chartPieObj
			.interval()
			.position('value')
			.color('type')
			.label('value', {
				content: data => {
					console.log(data);
					return `${data.type}:${data.value / 10}%`;
				},
				style: {
					fill: '#000'
				}
			})
			.adjust('stack');

		this.chartPieObj.legend({
			itemName: {
				style: {
					fill: '#000'
				}
			}
		});
		this.chartPieObj.interaction('element-single-selected');
		this.chartPieObj.render();

		// 折线图
		this.chartLineObj = new Chart({
			container: 'chartLine111', // 指定图表容器id
			width: 750, // 指定图表宽度
			height: 350, // 指定图表高度
			padding: [50, 50, 50, 50]
		});
		this.chartLineObj.scale('gas', {
			tickInterval: 2.5,
			min: 0,
			max: 10,
			alias: '燃气用量(立方)',
			tickCount: 5
		});
		this.chartLineObj.scale('tem', {
			tickInterval: 10,
			min: 0,
			max: 40,
			alias: '电用量(度)',
			tickCount: 5
		});
		// 设置title,label
		this.chartLineObj.axis('gas', {
			title: {
				style: {
					fill: '#000' // 文本的颜色
				}
			},
			label: {
				style: {
					fill: '#000' // 文本的颜色
				}
			},
			grid: {
				line: {
					style: {
						stroke: '#426180'
					}
				}
			}
		});
		this.chartLineObj.axis('tem', {
			title: {
				style: {
					fill: '#000' // 文本的颜色
				}
			},
			label: {
				style: {
					fill: '#000' // 文本的颜色
				}
			},
			grid: {
				type: 'line',
				lineStyle: {
					stroke: '#426385',
					lineWidth: 1
				}
			}
		});
		// 横坐标字体颜色
		this.chartLineObj.axis('time', {
			label: {
				style: {
					fill: '#fff' // 文本的颜色
				}
			}
		});
		// 设置辅助线
		this.chartLineObj.tooltip({
			showCrosshairs: true, // 展示 Tooltip 辅助线
			shared: true
		});
		// 设置图例
		this.chartLineObj.legend({
			custom: true,
			position: 'top-right',
			flipPage: false,
			items: [
				{
					name: '电用量(度)',
					marker: {
						symbol: 'circle',
						style: {
							fill: '#5e9df1'
						},
						clickable: false
					}
				},
				{
					name: '燃气用量(立方)',
					marker: {
						symbol: 'circle',
						style: {
							fill: '#5dd6bb'
						},
						clickable: false
					}
				}
			],
			itemName: {
				style: {
					fill: '#f'
				}
			}
		});
		// Step2: 载入数据源
		this.chartLineObj.data(this.chartLinedata); // Step3: 创建图形语法，绘制折线图
		this.chartLineObj.line().position('time*tem').color('#61a1f7');
		this.chartLineObj.point().position('time*tem').color('#ffffff').shape('circle')
		.style({
			stroke: '#61a1f7',
			lineWidth: 1
		});
		this.chartLineObj.line().position('time*gas').color('#62e0c3').shape('smooth');
		this.chartLineObj.point().position('time*gas').color('#62e0c3').shape('circle')
		.style({
			stroke: '#ffffff',
			lineWidth: 1
		});
		// Step4: 渲染图表
		this.chartLineObj.render();
	},
	methods: {
		// 树结构单选
		handleClick(data, checked, node) {
			console.log(data, checked, node);
			if (checked) {
				this.$refs.tree.setCheckedNodes([data]);
			}
		},
		// 企业多选
		treeCheck(data, obj) {
			console.log(data, obj);
		},
		// 企业单选
		singleTreeCheck(data, obj) {
			console.log(data, obj);
		},
		// 企业主列表操作
		editSingleHandler(row) {
			console.log(row);
		},
		deleteSingleHandler(row) {
			console.log(row);
		}
		
	}
};
</script>

<style>
</style>
