<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="100px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="开始日期">
				<el-date-picker v-model="formData.begin" type="datetime" placeholder="请选择开始日期"></el-date-picker>
			</el-form-item>
			<el-form-item label="结束日期">
                <el-date-picker v-model="formData.end" type="datetime" placeholder="请选择结束日期"></el-date-picker>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// 搜索
			formData: {
				begin: '',
				end: ''
			}
		};
	},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
