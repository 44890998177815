import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 列表
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const listService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data,
				page: data.pageNum,
				pagesize: data.pageSize
			};
			dataJson.page = data.pageNum;
			dataJson.pagesize = data.pageSize;
			delete dataJson.pageNum;
			delete dataJson.pageSize;
			const url = `hbtyong_5/apply/search?page=${dataJson.page}&pagesize=${dataJson.pagesize}`;
			// dataJson.applicantName && (url += `&applicantName=${dataJson.applicantName}`);
			// dataJson.applyDatebegin && (url += `&applyDatebegin=${dataJson.applyDatebegin}`);
			// dataJson.applyDateend && (url += `&applyDateend=${dataJson.applyDateend}`);
			// dataJson.approverName && (url += `&approverName=${dataJson.approverName}`);
			// dataJson.createTime && (url += `&createTime=${dataJson.createTime}`);
			// dataJson.devid && (url += `&devid=${dataJson.devid}`);
			// dataJson.lastupdateTime && (url += `&lastupdateTime=${dataJson.lastupdateTime}`);
			// dataJson.orgid && (url += `&orgid=${dataJson.orgid}`);
			// dataJson.title && (url += `&title=${dataJson.title}`);
			// dataJson.typeid && (url += `&typeid=${dataJson.typeid}`);
			// dataJson.typeName && (url += `&typeName=${dataJson.typeName}`);

			const res = await hpostRequest(url, { ...dataJson });
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 添加
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const addService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/apply/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 编辑
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const editService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/apply/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const deleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_5/apply/deletes', dataJson.ids);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 详情
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const detailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(`hbtyong_5/apply/get1?id=${dataJson.id}`, {});
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 添加 审批 设备 关联
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const addapply2devService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/apply/addapply2dev?applyid=${dataJson.id}&devid=${dataJson.devid}`,
				{}
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 添加 审批 设备 关联
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const delapplydevService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				`hbtyong_5/apply/delapplydev?applyid=${dataJson.id}&devid=${dataJson.devid}`,
				{}
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default listService;
