import { hgetRequest, hpostRequest } from '../../util/htools.axios';
// 新的库位图 S---------------------------------------------------------
// POST	​/web​/wms​/api​/positonGraph​/add			添加
export const positonGraphAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/positonGraph/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// GET		​/web​/wms​/api​/positonGraph​/delete		删除
export const positonGraphDelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/positonGraph/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// GET		​/web​/wms​/api​/positonGraph​/getbykey		id获取详情
export const positonGraphGetbykeyService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/positonGraph/getbykey', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// GET		​/web​/wms​/api​/positonGraph​/getbyposition 通过库位id获取库位图
export const positonGraphGetbypositionService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/positonGraph/getbyposition', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// POST	​/web​/wms​/api​/positonGraph​/search		翻页
export const positonGraphSearchService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/positonGraph/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// POST	​/web​/wms​/api​/positonGraph​/update		更新
export const positonGraphUpdateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/positonGraph/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 新的库位图 E---------------------------------------------------------

/*
 *@Description: 移库
 *@MethodAuthor:  zxh
 *@Date: 2020-12-17 09:56:54   issueReceiptType
 */
 export const stockMoveService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				posId: '',
				...data
			};
			const res = await hgetRequest('hwms/stock/move', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 批量移库
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:10
 */
export const stockMoveListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest(`hwms/stock/move?posId=${dataJson.posId}&setct=apjson`, JSON.stringify(dataJson.ids));
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 当前库存列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const currentStockListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				goods: [],
				include0: false,
				position: [],
				warehouse: '',
				...data
			};
			if (dataJson.warehouse) {
				dataJson.warehouse = [dataJson.warehouse];
			} else {
				dataJson.warehouse = [];
			}
			const res = await hpostRequest('hwms/stock/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 出入库类型列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   issueReceiptType
 */
export const issueReceiptTypeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				type: '',
				...data
			};
			const res = await hgetRequest('hwms/inouttype/onlyenable', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const supplierAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/supplier/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 添加入库
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const currentStockAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				aboutId: '',
				batchCode: '',
				inoutType: '',
				items: [],
				warehouseId: '',
				...data
			};
			const res = await hpostRequest('hwms/stock/addin', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 出库
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const currentStockReduceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				aboutId: 0,
				inoutType: 0,
				items: [
					{
						batchCode: '',
						cnt: 0,
						goodsId: 0,
						positionId: 0,
						price: 0,
						singleCode: ''
					}
				],
				warehouseId: 0,
				...data
			};
			const res = await hpostRequest('hwms/stock/addout', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 出库
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const currentStockReduceService1 = data => {
	return new Promise(async (resolve, reject) => {
		try {
			const dataJson = {
				aboutId: 0,
				inoutType: 0,
				items: [
					{
						batchCode: '',
						cnt: 0,
						goodsId: 0,
						positionId: 0,
						price: 0,
						singleCode: ''
					}
				],
				...JSON.parse(JSON.stringify(data))
			};
			
			dataJson.warehouseId = dataJson.items[0].warehouseId;
			delete dataJson?.code;
			dataJson?.items.forEach(item => {
				item.goodsId = item.goodsid;
				item.price = Number(item.price1);
				item.cnt = Number(item.cnt1);
				delete item.price1;
				delete item.cnt1;
				delete item.avgPrice;
				delete item.goodsCode;
				delete item.goodsid;
				delete item.goodsName;
				delete item.id;
				delete item.imanage;
				delete item.isShowWarn;
				delete item.manageType;
				delete item.orgid;
				delete item.positionName;
				delete item.positonGraphId;
				delete item.stockCode;
				delete item.warehouseCode;
				delete item.warehouseId;
				delete item.warehouseName;
			});

			const res = await hpostRequest('hwms/stock/addout', dataJson);
			resolve(res);
		} catch (error) {
			console.log('res2-----------------------');
			console.log(error);
			return reject(error);
		}
	});
};
/*
 *@Description: 库存历史
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const currentStockHistoryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				desc: true,
				...data
			};
			const res = await hgetRequest('hwms/supplier/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default currentStockListService;
