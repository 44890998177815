import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 设备类型列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:27:44
 */
export const equipmentCateListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				equipment: '',
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:06
 */
export const equipmentCateAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				equipment: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型编辑
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:21
 */
export const equipmentCateEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				consumptionNatural: 0,
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				equipment: '',
				naturalAfter: '',
				naturalFront: '',
				powerConsumption: '',
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:35
 */
export const equipmentCateDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: '',
				...data
			};
			const res = await hgetRequest('hbase/equipment/equipmentByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 15:28:49
 */
export const equipmentCateDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: [],
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default equipmentCateListService;
