import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 库位列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const wareareaListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				warehouseId: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouseposition/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库位添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const wareareaAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				warehouseId: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouseposition/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库位编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const wareareaEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				warehouseId: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouseposition/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库位删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const wareareaDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = [...data];
			const res = await hpostRequest('hwms/warehouseposition/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库位详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const wareareaDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/warehouseposition/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default wareareaListService;
