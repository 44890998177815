<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchFormEle"
			:model="searchFormData"
			label-width="70px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="保养单号">
				<el-input v-model="searchFormData.listNum" placeholder="请输入保养单号" />
			</el-form-item>
			<el-form-item label="负责人">
				<el-select
					v-model="searchFormData.dutyPeople"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
					@change="selectDutyPeople"
				>
					<el-option
						v-for="item in inspectionUserData"
						:key="item.id"
						:label="item.nickName"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="医院">
				<el-select
					v-model="searchFormData.hospitalId"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
					@change="handlerHospital()"
				>
					<el-option v-for="item in hospitalData" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="科室" v-if="searchFormData.hospitalId">
				<el-select
					v-model="parentName"
					class="select-item"
					placeholder="请选择"
					collapse-tags
					clearable
					@change="getHospitalId"
				>
					<el-option :value="searchFormData.hospitalOfficeId" style="height: auto">
						<el-tree
							:data="departmentData"
							node-key="id"
							ref="tree"
							highlight-current
							:props="defaultProps"
							@node-click="nodeClickHandler"
						></el-tree>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状态">
				<el-select
					v-model="searchFormData.state"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
				>
					<el-option label="未接单" :value="0"></el-option>
					<el-option label="已接单" :value="1"></el-option>
					<el-option label="已完成" :value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="日期">
				<el-date-picker
					v-model="date"
					type="daterange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd"
				></el-date-picker>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>搜索</el-button>
	</div>
</template>

<script>
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { selectPoByOrgIdService } from '@s/hospital/department/DepartmentService';
import { inspectionUserListService } from '@s/company/keep/InspectionService';
import { userListService } from '@s/system/UserService';

export default {
	data() {
		return {
			date: '',
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			searchFormData: {
				devId: '',
				state: '',
				dutyPeople: '',
				hospitalId: '',
				hospitalOfficeId: '',
				startTime: '',
				endTime: ''
			},
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			hospitalData: [],
			departmentData: [],
			inspectionUserData: []
		};
	},
	computed: {
		startDate() {
			return this.date ? this.date[0] : '';
		},
		endDate() {
			return this.date ? this.date[1] : '';
		}
	},
	created() {
		this.contractList();
		this.inspectionUserList();
	},
	methods: {
		// 计划负责人 清除失败
		selectDutyPeople(val) {
			// this.inspectionForm.dutyPeople = val;
			console.log(val);
			this.$forceUpdate();
		},
		// 科室清除问题
		getHospitalId(val) {
			console.log('val');
			if (!val) {
				this.searchFormData.hospitalOfficeId = null;
			}
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: this.searchFormData.hospitalId,
				pageNum: 1,
				pageSize: 10000
			};
			// const res = await inspectionUserListService(dataJson);
			const res = await userListService(dataJson);
			console.log(res);
			// this.inspectionUserData = [...res];
			this.inspectionUserData = [...res.records];
		},
		async selectPoByOrgIdService() {
			const json = {
				orgId: this.searchFormData.hospitalId
			};
			const res = await selectPoByOrgIdService(json);
			this.departmentData = [...res];
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
		},
		handlerHospital() {
			this.searchFormData.dutyPeople = null;
			this.searchFormData.hospitalOfficeId = null;
			this.parentName = '';
			if (!this.searchFormData.hospitalId) {
				// this.searchFormData.hospitalOfficeId = '';
				// this.parentName = '';
				this.departmentData = [];
				this.inspectionUserData = [];
			} else {
				// 获取部门
				this.selectPoByOrgIdService();
				// 获取负责人
				// this.inspectionUserList();
			}
		},
		nodeClickHandler(data) {
			this.searchFormData.hospitalOfficeId = data.id;
			this.parentName = data.department;
		},
		searchHandler() {
			console.log(this.startDate);
			console.log(this.endDate);
			this.searchFormData.startTime = this.startDate;
			this.searchFormData.endTime = this.endDate;
			this.$emit('searchForm', this.searchFormData);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
