<template>
	<div class="app-container">
		<div class="head-container" style="margin-bottom:15px">
			<InOutRecordSearchForm
				@searchForm="searchFormHandler"
				@searchData="getSearchData"
				@resetSelectionData="resetSelectionData"
				@download="downloadExlcel"
				:downLoading="downLoading"
				@showPrintData="showPrintData"
			/>
		</div>
		<el-table
			ref="table"
			style="width: 100%"
			:data="tableData"
			:row-key="getRowKey"
			@selection-change="selectionChangeHandler"
			border
			:header-cell-style="{ background: '#F4F4F4' }"
		>
			<el-table-column type="selection" width="55" :reserve-selection="true" />
			<el-table-column label="物料名称" prop="goodsName">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsId,
							goodsName: scope.row.goodsName
						}"
					></MaterialTooltip>
				</template>
			</el-table-column>
			<el-table-column label="出入库类型" prop="inoutTypeName" />
			<el-table-column label="库房" prop="warehouseName">
				<template slot-scope="scope">
					<span>{{ scope.row.warehouseName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="科室" prop="departName" v-if="inoutType == 5">
				<template slot-scope="scope">
					<span>{{ scope.row.departName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="数量" prop="cnt">
				<template slot-scope="scope">
					<span>{{ scope.row.cnt }}</span>
				</template>
			</el-table-column>
			<el-table-column label="单价" prop="price" v-if="inoutType == 5">
				<template slot-scope="scope">
					<span>{{ scope.row.price }}</span>
				</template>
			</el-table-column>
			<el-table-column label="总价" prop="price" v-if="inoutType == 5">
				<template slot-scope="scope">
					<span>{{ scope.row.cnt * scope.row.price }}</span>
				</template>
			</el-table-column>
			<el-table-column label="时间" prop="createDate" />
		</el-table>
		<el-row v-if="tableData.length > 0" style="text-align:right">
			<el-col :span="24" class="pagination-container">
				<el-pagination
					style="margin-top: 8px"
					@size-change="sizeChangeHandler"
					@current-change="currentChangeHandler"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
					layout="total, sizes, prev, pager, next, jumper"
				></el-pagination>
			</el-col>
		</el-row>
		<el-dialog
			title="打印"
			:visible.sync="printData"
			width="40%"
			:close-on-click-modal="false"
			append-to-body
		>
			<div class="print-data">
				<div id="printData">
					<table id="myTable" class="table" border="1">
						<thead>
							<tr class="tTitle">
								<th colspan="2">{{ warehouseName }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in [...printDataList]" :key="index">
								<td>{{ item.goodsName }}</td>
								<td>{{ item.cnt }}个</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>申请人：</td>
								<td>经办人：</td>
							</tr>
						</tfoot>
					</table>
					<div class="h10"></div>

					<!-- <el-row class="warehouse-name">{{ warehouseName }}</el-row>
        <el-row v-for="(item, index) in [...printDataList]" :key="index" class="print-row">
          <el-col :span="12" class="right-line padding-10">{{ item.goodsName }}</el-col>
          <el-col :span="12" class="padding-10">{{ item.cnt }}个</el-col>
        </el-row>
        <el-row class="print-row">
          <el-col :span="12" class="right-line padding-10">申请人：</el-col>
          <el-col :span="12" class="padding-10">经办人：</el-col>
        </el-row> -->
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printData = false">取 消</el-button>
				<el-button v-print="printObj" type="primary">打印</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { inOutStockRecordService } from '@s/spareParts/InOutRecordService';
import InOutRecordSearchForm from '@f/spareParts/inOutRecord/InOutRecordSearch.form';
import MaterialTooltip from '@/components//MaterialTooltip';
import XLSX from 'xlsx-style';

export default {
	name: 'InOutRecord',
	components: { InOutRecordSearchForm, MaterialTooltip },
	data() {
		return {
			tableData: [],
			total: 0,
			pageIndex: 1,
			pageSize: 10,
			getRowKey(row) {
				return row.id;
			},
			selectData: [],
			searchForm: {},
			searchData: null,
			downLoading: false,
			printData: false,
			printDataList: [],
			printObj: {
				id: 'printData',
				popTitle: '',
				popFooter: '12121'
			},
			warehouseName: '',
			// 搜索条件传过来的出入库类型 判断如果是销售的话做逻辑
			inoutType: null
		};
	},
	created() {},
	methods: {
		async getList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await inOutStockRecordService(dataJson);
			this.total = res.total;
			this.tableData = res.records;
			this.inoutType = this.searchForm.inoutType;
		},
		sizeChangeHandler(val) {
			this.pageSize = val;
			this.getList();
		},
		currentChangeHandler(val) {
			this.pageIndex = val;
			this.getList();
		},
		searchFormHandler(data) {
			this.pageIndex = 1;
			this.searchForm = data;
			this.getList();
		},
		getSearchData(data) {
			this.searchData = data;
		},
		// 重置选中的数据 通知父组件表格
		resetSelectionData() {
			this.$refs.table.clearSelection(); // 清空之前选择的数据
		},
		selectionChangeHandler(data) {
			this.selectData = data;
		},
		showPrintData() {
			if (this.selectData.length === 0) {
				this.$message.warning('请选择打印的数据');
				return false;
			}
			this.printDataList = this.selectData;
			this.printObj.popTitle = this.searchData.curType.name;
			this.warehouseName = this.searchData.curWarehouse.name;
			this.printData = true;
		},
		downloadExlcel() {
			if (this.selectData.length === 0) {
				this.$message.warning('请选择导出的数据');
				return false;
			}
			this.downLoading = true;

			let tHeader = [];
			let filterVal = [];

			if (this.inoutType == 5) {
				this.selectData.forEach(item => {
					item.zzTotalPrice = item.cnt * item.price;
				});
				tHeader = [
					'物料名称',
					'出入库类型',
					'库房',
					'科室',
					'数量',
					'单价',
					'总价',
					'时间'
				];
				// eslint-disable-next-line no-unused-vars
				filterVal = [
					'goodsName',
					'inoutTypeName',
					'warehouseName',
					'departName',
					'cnt',
					'price',
					'zzTotalPrice',
					'createDate'
				];
			} else {
				tHeader = ['物料名称', '出入库类型', '库房', '数量', '时间'];
				// eslint-disable-next-line no-unused-vars
				filterVal = ['goodsName', 'inoutTypeName', 'warehouseName', 'cnt', 'createDate'];
			}

			const data = this.formatJson(filterVal, this.selectData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;
			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `${this.searchData.curWarehouse.name} ${this.searchData.curType.name} ${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.downLoading = false;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		downloadData() {
			if (this.selectData.length === 0) {
				this.$message.warning('请选择导出的数据');
				return false;
			}
			this.downLoading = true;
			const arr = this.selectData;
			const newArr = [];
			for (let i = 0; i < arr.length; i++) {
				const val = [];
				for (const j in arr[i]) {
					if (j === 'cnt' || j === 'goodsName') val.push(arr[i][j]);
				}
				newArr.push(val);
			}
			newArr.forEach(item => {
				[item[0]] = item.splice(1, 1, item[0]);
				item[1] += '个';
			});
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;
			for (let i = 0; i < newArr.length; i++) {
				if (i % 10 === 0) {
					newArr.splice(
						i,
						0,
						['申请人', '经办人'],
						[this.searchData.curType.name, ''],
						[this.searchData.curWarehouse.name, nowDate]
					);
				}
			}
			newArr.splice(0, 1);
			if (newArr.length < 13) {
				newArr.push(['申请人', '经办人']);
			}
			this.downloadExl(newArr, `${this.searchData.curType.name} ${nowDate}`);
		},
		downloadExl(value_list, filenameParam) {
			// 编码单元格
			const tmp_data = {};
			value_list.forEach((value, r) => {
				value.forEach((v, c) => {
					const cell_obj = { c: c, r: r };
					const cell_text = XLSX.utils.encode_cell(cell_obj);
					tmp_data[cell_text] = {
						v: v
					};
				});
			});
			const borderAll = {
				// 单元格外侧框线
				top: {
					style: 'thin'
				},
				bottom: {
					style: 'thin'
				},
				left: {
					style: 'thin'
				},
				right: {
					style: 'thin'
				}
			};
			// 第一行样式
			const first_line_style = {
				font: {
					sz: 18
				},
				alignment: {
					horizontal: 'center'
				},
				border: borderAll
			};
			// 设置A1单元格样式
			const titleList = []; // 标题单元格 加样式用
			const rList = []; // 标题行 合并单元格用
			const pageList = []; // 分页用
			for (let i = 0; i < value_list.length; i++) {
				if (i % 13 === 0 && i !== value_list.length - 1) {
					titleList.push(i + 1);
					rList.push({
						s: { c: 0, r: i },
						e: { c: 1, r: i }
					});
					pageList.push(i);
				}
			}

			// 获取所有单元格编码,比如["A1", "B1", "C1", "D1", "E1", "F1"]
			const output_pos = Object.keys(tmp_data);
			console.log(output_pos);
			const workbook = {
				SheetNames: ['sheet1'], // 保存工作表的名称
				Sheets: {
					sheet1: {
						...tmp_data, // 单元格内容
						'!ref': `${output_pos[0]}:${output_pos[output_pos.length - 1]}` // 工作表范围
					}
				}
			};
			// 标题一行都合并
			workbook.Sheets.sheet1['!merges'] = rList;

			// 设置列宽
			workbook.Sheets.sheet1['!cols'] = [{ wpx: 260 }, { wpx: 260 }];

			// 设置分页
			workbook.Sheets.sheet1['!rowBreaks'] = pageList;

			// 给所有单元格加上边框
			const dataInfo = workbook.Sheets.sheet1;
			for (const i in dataInfo) {
				if (i === '!ref' || i === '!cols') {
					console.log('!ref');
				} else {
					dataInfo[i].s = {
						border: borderAll
					};
				}
			}
			// 给标题加样式
			for (let i = 0; i < titleList.length; i++) {
				tmp_data[`A${titleList[i]}`].s = first_line_style;
			}
			// 导出文件
			const type = 'xlsx';
			let filename = filenameParam || '文件名';
			filename += `.${type}`;
			const wopts = {
				bookType: type,
				type: 'binary'
			};
			const wbout = XLSX.write(workbook, wopts);
			function s2ab(s) {
				const buf = new ArrayBuffer(s.length);
				const view = new Uint8Array(buf);
				for (let i = 0; i !== s.length; ++i) {
					// eslint-disable-next-line no-bitwise
					view[i] = s.charCodeAt(i) & 0xff;
				}
				return buf;
			}
			const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
			function saveAs(obj, filename) {
				const link = document.createElement('a');
				link.download = filename;
				link.href = URL.createObjectURL(obj);
				link.click();
				URL.revokeObjectURL(obj);
			}
			saveAs(blob, filename);
			this.downLoading = false;
			this.$message.success('导出成功');
		}
	}
};
</script>

<style scoep>
/* 表格初始化 bootstrap */
table {
	border-collapse: collapse;
	width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse !important;
}

.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}

.table-bordered th,
.table-bordered td {
	/* border: 1px solid #dee2e6 !important; */
}

.table-dark {
	color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #dee2e6;
}

.table .thead-dark th {
	color: inherit;
	border-color: #dee2e6;
}

.print-data {
	/* border: 1px solid #333; */
	max-height: 600px;
	overflow-y: auto;
}

.print-row {
	border-bottom: 1px solid #333;
	line-height: 2.5;
	font-size: 14px;
}

.print-data .print-row:last-child {
	border-bottom: none;
}

.right-line {
	border-right: 1px solid #333;
}

.padding-10 {
	padding-left: 10px;
}

.warehouse-name {
	font-size: 18px;
	line-height: 2.5;
	padding-left: 10px;
	border-bottom: 1px solid #333;
}

.tTitle th {
	text-align: left;
	font-size: 18px;
	line-height: 40rpx;
}

/* 表格打印 保留表头底部 */
.table thead {
	display: table-header-group;
}

.table tfoot {
	display: table-footer-group;
}

.table tbody {
	display: table-row-group;
}

#printData {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#myTable {
	width: 97%;
}

.h10 {
	width: 100%;
	height: 10px;
}
</style>
