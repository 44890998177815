<template>
	<div class="head-serch" style="width:100%;">
		<el-row :gutter="10">
			<el-col :span="24">
				<h2 style="float:left; margin-right:20px; margin-bottom:20px;">
					{{ pSearchForm.row.nickname || '' }}
				</h2>
				<el-button type="primary" @click="toEmpSummaryEcharts">维修分析</el-button>
			</el-col>
			<el-col :span="24">
				<el-form class="form" ref="searchForm" :model="searchForm" label-width="100px" @submit.native.prevent
					@keyup.enter.native="searchHandler">
					<el-form-item label="日期">
						<el-date-picker v-model="date" type="daterange" :picker-options="pickerOptions" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" align="right"
							value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="医院">
						<el-select v-model="searchForm.hosid" placeholder="请选择" class="select-item" clearable filterable>
							<el-option v-for="item in hospitalData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">
						搜索
					</el-button>
				</el-form>
			</el-col>
			<el-col :span="24">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="维修" name="first">
						<el-table :data="tableData1" stripe style="width: 100%" border
							:header-cell-style="{ background: '#F4F4F4' }">
							<el-table-column prop="devInfo.devInfoName" label="设备名称">
								<template slot-scope="scope">
									<b style="font-size: 16px">{{ scope.row.devInfoName }}</b>
									<p>{{ scope.row.code }}</p>
								</template>
							</el-table-column>
							<el-table-column prop="affiliatedHospitalHdName" label="医院">
								<template slot-scope="scope">
									<p style="font-size: 16px">
										{{ scope.row.affiliatedHospitalHdName }}
									</p>
									<p>{{ scope.row.devInfoDepartmentName }}</p>
								</template>
							</el-table-column>
							<el-table-column prop="faultTypeName" label="故障"></el-table-column>
							<el-table-column property="actionState" label="维修状态">
								<template slot-scope="scope">
									<span class="equip_state" v-if="Number(scope.row.actionState) === 0">
										待接单
									</span>
									<span class="equip_state equip_state1" v-else-if="Number(scope.row.actionState) === 1">
										已响应
									</span>
									<span class="equip_state equip_state2" v-else-if="Number(scope.row.actionState) === 2">
										已到场
									</span>
									<span class="equip_state equip_state3" v-else-if="Number(scope.row.actionState) === 3">
										维修中
									</span>
									<span class="equip_state equip_state4" v-else-if="Number(scope.row.actionState) === 4">
										待评价
									</span>
									<span class="equip_state equip_state5" v-else-if="Number(scope.row.actionState) === 5">
										已完成
									</span>
									<span v-else></span>
								</template>
							</el-table-column>
							<el-table-column prop="repairUserName" label="报修人">
								<template slot-scope="scope">
									<p style="font-size: 16px">{{ scope.row.repairUserName }}</p>
									<p>
										{{
											scope.row.repairTime
											| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
										}}
									</p>
								</template>
							</el-table-column>
							<el-table-column property="engineerName" label="接单人">
								<template slot-scope="scope">
									<p style="font-size: 16px">{{ scope.row.engineerName }}</p>
									<p>
										{{
											scope.row.undertakeTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
										}}
									</p>
								</template>
							</el-table-column>
							<el-table-column label="参与人">
								<template slot-scope="scope">
									<p style="font-size: 16px">{{ scope.row.cyName }}</p>
									<p v-if="scope.row.cyDate">
										{{
											scope.row.cyDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
										}}
									</p>
								</template>
							</el-table-column>
							<el-table-column property="actionCompleteName" label="完成人">
								<template slot-scope="scope">
									<p style="font-size: 16px">
										{{ scope.row.actionCompleteName }}
									</p>
									<p>
										{{
											scope.row.actionCompleteTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
										}}
									</p>
								</template>
							</el-table-column>
							<el-table-column label="操作" align="left" fixed="right">
								<template slot-scope="scope">
									<el-button id="btn-update-row" type="text" @click="showDetail1(scope.row)">
										详情
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>

					<el-tab-pane label="巡检" name="second">
						<el-table :data="tableData2" stripe style="width: 100%" border
							:header-cell-style="{ background: '#F4F4F4' }">
							<el-table-column prop="hospitalName" label="医院"></el-table-column>
							<el-table-column prop="hospitalOfficeName" label="科室"></el-table-column>
							<el-table-column prop="executionTime" label="日期">
								<template slot-scope="scope">
									{{ scope.row.executionTime | formatDate }}
								</template>
							</el-table-column>
							<el-table-column prop="devNumber" label="台数"></el-table-column>
							<el-table-column prop="dutyPeopleName" label="负责人"></el-table-column>
							<el-table-column label="操作" align="left" fixed="right">
								<template slot-scope="scope">
									<el-button id="btn-update-row" type="text" @click="showDetail2(scope.row)">
										详情
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>

					<el-tab-pane label="保养" name="third">
						<el-table :data="tableData3" stripe style="width: 100%" border
							:header-cell-style="{ background: '#F4F4F4' }">
							<el-table-column prop="hospitalName" label="医院"></el-table-column>
							<el-table-column prop="hospitalOfficeName" label="科室"></el-table-column>
							<el-table-column prop="executionTime" label="日期">
								<template slot-scope="scope">
									{{ scope.row.executionTime | formatDate }}
								</template>
							</el-table-column>
							<el-table-column prop="devNumber" label="台数"></el-table-column>
							<el-table-column prop="dutyPeopleName" label="负责人"></el-table-column>
							<el-table-column label="操作" align="left" fixed="right">
								<template slot-scope="scope">
									<el-button id="btn-update-row" type="text" @click="showDetail2(scope.row)">
										详情
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>

					<el-tab-pane label="计量" name="fourth">
						<el-table :data="tableData4" stripe style="width: 100%" border
							:header-cell-style="{ background: '#F4F4F4' }"></el-table>
					</el-tab-pane>
				</el-tabs>

				<el-pagination v-if="activeName != 'fourth'" style="margin-top:20px; margin-bottom:20px; text-align:right;"
					@size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="searchForm.page"
					:page-sizes="[10, 20, 30, 50]" :page-size="searchForm.pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
			</el-col>
		</el-row>
		<Dialog title="巡检单信息" :visible.sync="isShowAEDialog2" width="60%">
			<InspectionImplementAdd v-if="isShowAEDialog2" :isShowAEDialog_p.sync="isShowAEDialog2" :editId_p="editId"
				:status_p="status" :pHideUploadBen="true"></InspectionImplementAdd>
		</Dialog>
		<Dialog title="保养单信息" :visible.sync="isShowAEDialog3" width="60%">
			<InspectionImplementAddKeep v-if="isShowAEDialog3" :isShowAEDialog_p.sync="isShowAEDialog3" :editId_p="editId"
				:status_p="status" :pHideUploadBen="true"></InspectionImplementAddKeep>
		</Dialog>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { inspectionImplementListService } from '@s/company/inspectionTemp/InspectionImplementService';
import { inspectionImplementListService as inspectionImplementListServiceKeep } from '@s/company/keep/InspectionImplementService';
import { userListService } from '@s/system/UserService';
import {
	querySearchrepairService,
	queryDevRepairInfoPageALLService
} from '@s/repair/RepairService';
import { hgetStorage, hsetStorage, htimeFormat } from '@/util/htools.web';
import Dialog from '@c/ui/Dialog';
import InspectionImplementAdd from '@f/company/inspectionTemp/InspectionImplementAdd.form';
import InspectionImplementAddKeep from '@f/company/keep/InspectionImplementAdd.form';

export default {
	props: ['downLoading', 'pSearchForm'],
	components: {
		InspectionImplementAdd,
		InspectionImplementAddKeep,
		Dialog
	},
	data() {
		return {
			activeName: 'first',
			tableData1: [],
			tableData2: [],
			tableData3: [],
			tableData4: [],
			searchForm: {
				hosid: null,
				orgid: null,
				pageNum: 1,
				pageSize: 10,
				page: 1,
				pagesize: 10,
				uname: null,
				begin: null,
				end: null
			},
			total1: 0,
			date: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
			hospitalData: [],
			inspectionUserData: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			isShowAEDialog2: false,
			isShowAEDialog3: false,
			isShowAEDialog4: false,
			currentUserOrganization: '',
			enterpriseData: []
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		this.contractList();
		// this.inspectionUserList();
		// this.enterpriseList();
		// console.log('this.searchForm');
		// console.log(this.pSearchForm);
		// console.log(this.pSearchForm.repairBegin);
		if (this.pSearchForm.begin) {
			// this.date[0] = [this.pSearchForm.begin, this.pSearchForm.end];
			this.date[0] = this.pSearchForm.begin;
			this.date[1] = this.pSearchForm.end;
		}
		if (this.pSearchForm.hosid) {
			this.searchForm.hosid = this.pSearchForm.hosid;
			this.searchForm.hids = [this.pSearchForm.hosid];
		}
		if (this.pSearchForm.row.id) {
			this.searchForm.dutyPeople = this.pSearchForm.row.id;
			this.searchForm.engineers = [this.pSearchForm.row.id];
		}
		// console.log(this.date);
		this.getList();
	},
	computed: {},
	methods: {
		getList() {
			this.setDate();
			if (this.activeName == 'first') {
				this.getList1();
			} else if (this.activeName == 'second') {
				this.getList2();
			} else if (this.activeName == 'third') {
				this.getList3();
			} else if (this.activeName == 'fourth') {
				this.getList4();
			}
		},
		async getList1() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 10,
				page: 1,
				pagesize: 10
			};
			let res = null;
			res = await querySearchrepairService(dataJson);
			// Request URL: https://ygongyun.com/web/dev/api/devRepairInfoService/searchrepair

			res.records.forEach(item => {
				if (item.processInfos?.length == 0) {
					item.cyName = '';
					item.cyDate = null;
				} else if (item.processInfos?.length == 1) {
					item.cyName = item.processInfos[0].engineerName;
					item.cyDate = item.processInfos[0].actionTime;
				} else if (item.processInfos?.length == 2) {
					item.cyName = `${item.processInfos[0].engineerName}、${item.processInfos[1].engineerName}`;
					item.cyDate = null;
				} else if (item.processInfos?.length >= 2) {
					item.cyName = `${item.processInfos[0].engineerName}、${item.processInfos[1].engineerName}等`;
					item.cyDate = null;
				} else {
					item.cyName = null;
					item.cyDate = null;
				}
			});

			this.tableData1 = res.records;

			this.total1 = res.total;
		},
		async getList2() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 10,
				page: 1,
				pagesize: 10
			};
			let res = null;
			res = await inspectionImplementListService(dataJson);
			this.tableData2 = res.records;
			this.total1 = res.total;
		},
		async getList3() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 10,
				page: 1,
				pagesize: 10
			};
			let res = null;
			res = await inspectionImplementListServiceKeep(dataJson);
			this.tableData3 = res.records;
			this.total1 = res.total;
		},
		async getList4() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 10,
				page: 1,
				pagesize: 10
			};
			this.total4 = [];
		},
		async showDetail1(row) {
			this.$router.push({
				name: 'RepairDetail',
				query: { type: 'company', repairInfoId: row.devRepairId }
			});

			hsetStorage('EmpSummarySearchForm', this.pSearchForm);
		},
		showDetail2(row) {
			this.editId = row.id;
			this.status = row.state;
			this.isShowAEDialog2 = true;
		},
		showDetail3(row) {
			this.editId = row.id;
			this.status = row.state;
			this.isShowAEDialog3 = true;
		},
		toEmpSummaryEcharts() {
			this.$router.push({
				name: 'EmpSummaryEcharts',
				query: { ...this.searchForm, nickname: this.pSearchForm.row.nickname }
			});
		},
		handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this.searchForm.pagesize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.searchForm.page = val;
			this.searchForm.pageNum = val;
			this.getList();
		},
		handleClick(tab, event) {
			this.searchForm.page = 1;
			this.searchForm.pageNum = 1;
			this.searchForm.pageSize = 10;
			this.searchForm.pagesize = 10;

			console.log(tab, event);
			this.getList();
		},
		// async enterpriseList() {
		// 	const dataJson = {
		// 		pageNum: 1,
		// 		pageSize: 100000
		// 	};
		// 	const res = await enterpriseListService(dataJson);
		// 	this.enterpriseData = res.records;
		// },
		setDate() {
			this.searchForm.repairBegin = htimeFormat(this.date[0], '{y}-{m}-{d} {h}:{i}:{s}');
			this.searchForm.startTime = htimeFormat(this.date[0], '{y}-{m}-{d} {h}:{i}:{s}');
			this.searchForm.begin = htimeFormat(this.date[0], '{y}-{m}-{d} {h}:{i}:{s}');
			this.searchForm.end = htimeFormat(this.date[1], '{y}-{m}-{d} {h}:{i}:{s}');
			this.searchForm.endTime = htimeFormat(this.date[1], '{y}-{m}-{d} {h}:{i}:{s}');
			this.searchForm.repairEnd = htimeFormat(this.date[1], '{y}-{m}-{d} {h}:{i}:{s}');
		},
		searchHandler() {
			this.setDate();
			console.log(this.searchForm);
			// this.$emit('searchForm', this.searchForm);
			this.getList();
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: this.searchForm.hospitalId,
				pageNum: 1,
				pageSize: 10000
			};
			// const res = await inspectionUserListService(dataJson);
			const res = await userListService(dataJson);
			console.log(res);
			// this.inspectionUserData = [...res];
			this.inspectionUserData = [...res.records];
		}
	}
};
</script>

<style lang="less" scoped>
.form {
	display: flex;
}

.el-dialog .el-form-item {
	width: initial !important;
}

/* 维修单状态 */
/* 待接单 */
.equip_state {
	//   width: 101rpx;
	//   height: 42rpx;
	//   border: 1px solid #FF8431;
	color: #ff8431;
	//   text-align: center;
	//   line-height: 40rpx;
	//   border-radius: 6rpx;
}

/* 已响应 */
.equip_state1 {
	color: #0d69f5;
}

/* 已到场 */
.equip_state2 {
	color: #760cd2;
}

/* 维修中 */
.equip_state3 {
	color: #0ccbd2;
}

/* 待评价 */
.equip_state4 {
	color: #c1e986;
}

/* 已完成 */
.equip_state5 {
	color: #15bc84;
}

.yellow {
	color: #ffd21e;
}

.f14 {
	font-size: 20px;
}
</style>
