<template>
	<div class="head-serch">
		<el-form class="form" ref="userForm" :model="userSearchForm" label-width="100px" @submit.native.prevent
			@keyup.enter.native="searchHandler">
			<el-form-item label="用户名称">
				<el-input v-model="userSearchForm.userName" size="mini" placeholder="请输入用户名称"></el-input>
			</el-form-item>
			<el-form-item label="昵称">
				<el-input v-model="userSearchForm.nickName" size="mini" placeholder="请输入昵称"></el-input>
			</el-form-item>
			<el-form-item label="企业" prop="name" v-if="currentUserOrganization === 0">
				<el-select v-model="userSearchForm.organizationId" placeholder="请选择" class="select-item" clearable
					filterable>
					<el-option v-for="(enterprise, index) in enterpriseData" :key="index"
						:label="enterprise.organizationName" :value="enterprise.id"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="科室" prop="name">
				<treeselect v-model="userSearchForm.deptId" :multiple="false" :options="optionsDept" style="width:220px;"
					:disable-branch-nodes="false" placeholder="请选择" noResultsText="暂无结果" :close-on-select="true"
					:show-count="true" />
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
			@click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage } from '@/util/htools.web';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import Treeselect from '@riophae/vue-treeselect';

export default {
	components: {
		Treeselect
	},
	data() {
		return {
			// 搜索
			userSearchForm: {
				deptId: null,
				userName: '',
				nickName: '',
				organizationId: ''
			},
			currentUserOrganization: '',
			enterpriseData: [],
			optionsDept: []
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		// this.enterpriseList();
		this.departmentList();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records || [];
		},
		async enterpriseList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await enterpriseListService(dataJson);
			this.enterpriseData = res.records;
		},
		searchHandler() {
			this.$emit('searchForm', this.userSearchForm);
		}
	}
};
</script>

<style lang="less" scoped></style>
