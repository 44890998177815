import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 医院列表 合同 同过医院id获取所有绑定企业
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const getcompanysignListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/getcompanysignpage', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院添加 合同绑定维修企业 医院和维修公司签订合同(绑定)
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const signAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/sign', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院详情 查看维修公司信息 查询被绑定到医院的员工
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const getHospitalUserDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/getHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 企业 同过企业id获取所有绑定医院
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const gethospitalsignListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devBaseId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3_3/contract/gethospitalsign', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 企业 将企业员工绑定到医院
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */

export const addHospitalUserService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/addHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 查询医院绑定维修公司的列表
export const organizationAllQueryListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/company/organizationAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 查看设备绑定情况
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-07-01 17:28:14
*/

export const queryPagingArbitrarilyService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				
				...data
			};
			const res = await hpostRequest('hbtyong_3/devBase/queryPagingArbitrarily', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 没用的东西
/*
 *@Description: 医院解绑维修公司
 *@ClassAuthor: myw
 *@Date: 2021-05-28 11:36:42
 */
export const contractDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				companyId: [],
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/untiesign', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export const contractDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				...data
			};
			const res = await hpostRequest('hbtyong_5/devBase/queryPagingArbitrarily', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 维修公司查看详情
export const contractDetailService1 = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong_3/company/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default getcompanysignListService;
