<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="elForm" :model="elForm" :rules="elFormRules" label-width="100px">
				<el-form-item label="医院" prop="hosid">
					<el-select v-model="elForm.hosid" placeholder="请选择医院" clearable filterable style="width: 100%">
						<el-option v-for="item in contractData" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="工程师" prop="uid">
					<el-select v-model="elForm.uid" placeholder="请选择工程师" clearable filterable style="width: 100%">
						<el-option v-for="item in engineerData" :key="item.id" :label="item.nickName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="开始时间" prop="beginTime">
					<el-date-picker v-model="elForm.beginTime" type="date" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width:150px;"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="endTime">
					<el-date-picker v-model="elForm.endTime" type="date" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width:150px;"></el-date-picker>
				</el-form-item>

			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { addService, editService, detailService } from '@s/base/UserInHosHistoryService';
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { userListService } from '@s/system/UserService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			elForm: {
				hosid: null,
				uid: null,
				beginTime: null,
				endTime: null
			},
			elFormRules: {
				hosid: [
					{
						required: true,
						message: '请选择医院',
						trigger: 'change'
					}
				],
				uid: [
					{
						required: true,
						message: '请选择工程师',
						trigger: 'change'
					}
				],
				beginTime: [
					{
						required: true,
						message: '请选择开始日期',
						trigger: 'change'
					}
				]
			},
			contractData: [],
			engineerData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					// console.log(this.parentName, 'this.parentNname');
					this.elForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		this.engineerList();
		this.contractList();
		// if (this.editId !== -1) {
		// 	this.getDetail();
		// }
	},
	methods: {
		async engineerList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: 1000000000,
				userName: '',
				state: 0,
				...this.searchForm
			};
			const res = await userListService(dataJson);
			this.engineerData = [...res.records];
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await getcompanysignListService(dataJson);
			this.contractData = [...res.records];
		},
		async getDetail() {
			const dataJson = {
				id: this.editId
			};
			const res = await detailService(dataJson);
			this.elForm = { ...res };
			this.$emit('powers', res.powers);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await addService(this.elForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.elForm
						};
						await editService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style></style>
