<template>
  <div>
    <InspectionImplementSearch @searchForm="searchFormHandler"></InspectionImplementSearch>
    <div style="height:15px;"></div>
    <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn">
      <el-table-column label="设备数量">
        <template slot-scope="scope">
			{{ scope.row.devNumber }}({{ scope.row.allprice }}￥)
		</template>
      </el-table-column>
      <el-table-column label="医院">
        <template slot-scope="scope">{{ scope.row.hospitalName }}</template>
      </el-table-column>
      <el-table-column label="计划实施日期">
        <template slot-scope="scope">{{ scope.row.executionTime | parseTime('{y}-{m}-{d}') }}</template>
      </el-table-column>
      <el-table-column label="是否上传附件" prop="state">
        <template slot-scope="scope">
          <el-link :underline="false">{{ Number(scope.row.state) === 2 ? '已上传' : '未上传' }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" fixed="right" width="200">
        <template slot-scope="scope">
          <!-- <el-button id="btn-update-row" type="text" @click="showDetail(scope.row)">详情</el-button> -->

          <el-link type="primary" @click="showDetail(scope.row)" :underline="false" style="margin-right:10px;">详情</el-link>
          <el-link type="success" :href="'https://ygongyun.com/web/comp/api/export/exportxunjian?id=' + scope.row.id" :underline="false">巡检报告下载</el-link>

        </template>
      </el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
    <Dialog title="巡检单信息" :visible.sync="isShowAEDialog" width="60%">
      <InspectionImplementAdd v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" :isRefreshListAll_p.sync="isRefreshListAll" :editId_p="editId" :status_p="status"></InspectionImplementAdd>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { inspectionImplementListService } from '@s/company/inspectionTemp/InspectionImplementService';

import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import InspectionImplementSearch from '@f/company/inspectionTemp/InspectionImplementSearch.form';
import InspectionImplementAdd from '@f/company/inspectionTemp/InspectionImplementAdd.form';
import ListMixin from '@m/List.mixin';

export default {
	name: 'InspectionImplement',
	mixins: [ListMixin],
	components: {
		Table,
		InspectionImplementSearch,
		InspectionImplementAdd,
		Pagination,
		Dialog
	},
	data() {
		return {
			editId: -1,
			tableData: [],
			status: '',
			tableColumn: [
				{
					label: '巡检编号',
					field: 'listNum'
				},
				{
					label: '计划负责人',
					field: 'dutyPeopleName'
				}
			]
		};
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.inspectionList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.inspectionList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.inspectionList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.inspectionList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	created() {
		this.inspectionList();
	},
	methods: {
		async inspectionList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await inspectionImplementListService(dataJson);
			this.listMixin(res);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.inspectionList();
		},
		showDetail(row) {
			this.isShowAEDialog = true;
			this.editId = row.id;
			this.status = row.state;
		}
	}
};
</script>

<style></style>
