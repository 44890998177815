<template>
	<div class="app-container">
		<el-row :gutter="10">
			<el-col :span="6">
				<template v-if="materialDetail.name">
					<h2>{{ materialDetail.name }}</h2>
					<div style="display:flex;">
						<el-image v-if="materialDetail.pic" class="devBaseFormLogoStr p5b1"
							style="width: 105px; height: 105px; margin-right:20px;" :src="materialDetail.picStr"
							:preview-src-list="[materialDetail.picStr]" :fit="'contain'">
							<div slot="error" class="image-slot">暂无图片</div>
						</el-image>
						<div style="display:flex;flex-direction:column; margin:20px 0 0 0;">
							<span class="valueCom">{{ materialDetail.code }}</span>
							<span class="valueCom">{{ materialDetail.manageTypeName }}</span>
							<span class="valueCom">{{ materialDetail.typeName }}</span>
						</div>
					</div>
					<span class="valueCom">{{ materialDetail.factory }}</span>
					<span class="valueCom">{{ materialDetail.goodsVer }}</span>
					<span class="valueCom">{{ materialDetail.spec }}</span>
					<p class="valueCom" v-if="materialDetail.tagsArr.length > 0">
						<span class="valueCom" v-for="(item, index) in materialDetail.tagsArr" :key="index">{{ item
						}}</span>
					</p>
				</template>

				<el-divider content-position="left"></el-divider>

				<h2>建议采购渠道
					<el-button type="primary" @click="showSetMain1">
						设置
					</el-button>
				</h2>
				<div class="buySellList">
					<p>
						<span class="TitleCom">供应商</span>
						<span class="valueCom">：{{ materialDetail.commentSupplierPo.name || '-' }}</span>
					</p>
					<p>
						<span class="TitleCom">联系人</span>
						<span class="valueCom">：{{ materialDetail.commentSupplierPo.contract || '-' }}</span>
					</p>
					<p>
						<span class="TitleCom">电话</span>
						<span class="valueCom">：{{ materialDetail.commentSupplierPo.telphone || '-' }}</span>
					</p>
					<p>
						<span class="TitleCom">价格</span>
						<span class="valueCom">：{{ materialDetail.commentBuyPrice || '-' }}￥</span>
					</p>
				</div>

				<el-divider content-position="left"></el-divider>

				<h2>建议销售价格
					<el-button type="primary" @click="showSetMain2">
						设置
					</el-button>
				</h2>
				<div class="buySellList">
					<p>
						<span>建议销售价格</span>
						<span class="valueCom">：{{ materialDetail.commentSellPrice || '-' }}￥</span>
					</p>
				</div>
			</el-col>
			<el-col :span="18">
				<h2 class="mb-10">历史采购</h2>
				<el-table :data="tableData1" highlight-current-row style="width: 100%"
					:header-cell-style="{ background: '#F4F4F4' }">
					<el-table-column type="index" width="50"></el-table-column>
					<el-table-column property="warehouseName" label="项目组"></el-table-column>
					<el-table-column property="departname" label="科室"></el-table-column>
					<el-table-column property="price" label="单价"></el-table-column>
					<el-table-column property="cnt" label="数量"></el-table-column>
					<el-table-column property="suppliername" label="供应商"></el-table-column>
					<el-table-column property="contract" label="联系人"></el-table-column>
					<el-table-column property="telphone" label="电话"></el-table-column>
					<el-table-column property="createDate" label="日期"></el-table-column>
					<el-table-column label="操作" width="160px">
						<template slot-scope="scope">
							<el-button type="primary" @click="setMain1(scope.row)">
								设置为推荐
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top: 20px; margin-bottom: 20px" @size-change="handleSizeChange1"
					@current-change="handleCurrentChange1" :current-page="queryInfo1.pageNum"
					:page-sizes="[5, 10, 20, 30, 50]" :page-size="queryInfo1.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
				<h2 class="mb-10">历史销售</h2>
				<el-table :data="tableData2" highlight-current-row style="width: 100%"
					:header-cell-style="{ background: '#F4F4F4' }">
					<el-table-column type="index" width="50"></el-table-column>
					<el-table-column property="warehouseName" label="项目组"></el-table-column>
					<el-table-column property="departname" label="科室"></el-table-column>
					<el-table-column property="price" label="单价"></el-table-column>
					<el-table-column property="cnt" label="数量"></el-table-column>
					<!-- <el-table-column property="suppliername" label="供应商"></el-table-column>
						<el-table-column property="contract" label="联系人"></el-table-column>
						<el-table-column property="telphone" label="电话"></el-table-column> -->
					<el-table-column property="createDate" label="日期"></el-table-column>
					<el-table-column label="操作" width="160px">
						<template slot-scope="scope">
							<el-button type="primary" @click="setMain2(scope.row)">
								设置为推荐
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top: 20px; margin-bottom: 20px" @size-change="handleSizeChange2"
					@current-change="handleCurrentChange2" :current-page="queryInfo2.pageNum"
					:page-sizes="[5, 10, 20, 30, 50]" :page-size="queryInfo2.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total2"></el-pagination>
			</el-col>
		</el-row>

		<Dialog title="设置推荐采购渠道" :visible.sync="isShowUpdatecommentbuy" width="50%">
			<el-form ref="updatecommentbuy" :model="formData1" :rules="formRules1" size="small" label-width="80px">
				<el-form-item label="供应商" prop="supplierId">
					<el-select v-model="formData1.supplierId" clearable filterable class="fastChooseSupplier"
						@keyup.native="getSupplierVal($event)">
						<el-option v-for="(item, index) in supplierData" :key="index" :label="item.name"
							:value="item.id"></el-option>
						<p slot="empty" class="faseAddGood"><span @click="showFastAddSupplier">快速添加供应商</span></p>
					</el-select>
				</el-form-item>
				<el-form-item label="价格" prop="buyprice">
					<el-input v-model="formData1.buyprice" type="number" placeholder="请输入名称" clearable />
				</el-form-item>
				<el-form-item style="text-align:right;">
					<el-button type="primary" @click="setMain1Dialog">确认</el-button>
					<el-button @click="closeSetMain1">取消</el-button>
				</el-form-item>
			</el-form>
		</Dialog>

		<Dialog title="设置推荐销售价格" :visible.sync="isShowUpdatecommentsell" width="50%">
			<el-form ref="updatecommentsell" :model="formData2" :rules="formRules2" size="small" label-width="80px">
				<el-form-item label="价格" prop="sellprice">
					<el-input v-model="formData2.sellprice" type="number" placeholder="请输入名称" clearable />
				</el-form-item>
				<el-form-item style="text-align:right;">
					<el-button type="primary" @click="setMain2Dialog">确认</el-button>
					<el-button @click="closeSetMain2">取消</el-button>
				</el-form-item>
			</el-form>
		</Dialog>
		<!-- 快速添加供应商 -->
		<Dialog :title="dialogTitleFastAddSupplierTitle" :visible.sync="isShowFastAddSupplier">
			<SupplierAddForm v-if="isShowFastAddSupplier" :isShowAEDialog_p.sync="isShowFastAddSupplier"
				:isRefreshFastAddSupplier_p.sync="isRefreshFastAddSupplier" @isToParentSupplierNmae_p="supplierList"
				:selectData_p="selectData"></SupplierAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	materialDetailService, materialbuyRequestDetailService, materialsellRequestDetailService,
	materialUpdatecommentbuyService, materialUpdatecommentsellService
} from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService } from '@s/spareParts/SupplierService';
import Dialog from '@c/ui/Dialog';
import SupplierAddForm from '@f/spareParts/supplier/SupplierAdd.form';
import ListMixin from '@m/List.mixin';

export default {
	name: 'MaterialDetail',
	mixins: [ListMixin],
	components: {
		Dialog,
		SupplierAddForm
	},
	data() {
		return {
			id: 0,
			materialDetail: {
				tagsArr: [],
				commentSupplierPo: {}
			},
			tableData1: [],
			queryInfo1: {
				pageNum: 1,
				pageSize: 5
			},
			total1: 0,
			isShowUpdatecommentbuy: false,
			formData1: {
				buyprice: null,
				goodsid: null,
				supplierId: null
			},

			formRules1: {
				buyprice: [
					{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}
				],
				supplierId: [
					{
						required: true,
						message: '请选择供应商',
						trigger: 'change'
					}
				]
			},
			supplierData: [],
			tableData2: [],
			queryInfo2: {
				pageNum: 1,
				pageSize: 5
			},
			total2: 0,
			isShowUpdatecommentsell: false,
			formData2: {
				sellprice: null,
				goodsid: null
			},
			formRules2: {
				sellprice: [
					{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}
				]
			},
			// 快速添加供应商
			isShowFastAddSupplier: false,
			isRefreshFastAddSupplier: false,
			// 快速添加供应商时 [{id:-1,name:输入的物料名称}]
			selectData1: []
		};
	},
	computed: {
		dialogTitleFastAddSupplierTitle() {
			return '添加供应商';
		}
	},
	watch: {
		isRefreshFastAddSupplier(newValue) {
			if (newValue) {
				this.supplierList();
			}
		}
	},
	mounted() {
		this.id = Number(this.$route.query.id);
		this.getDetail(this.id);
		this.getList1();
		this.getList2();
		this.supplierList();
	},
	methods: {
		async getDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			res.picStr = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${res.pic}`;
			if (res.tags != null && res.tags?.length > 0) {
				res.tagsArr = res.tags.split(',');
			} else {
				res.tagsArr = [];
			}
			if (!res.commentSupplierPo) {
				res.commentSupplierPo = {};
			}
			this.materialDetail = res;
		},
		async getList1() {
			const dataJson = {
				goodsId: this.id,
				...this.queryInfo1
			};
			const res = await materialbuyRequestDetailService(dataJson);
			console.log('getlist1');
			console.log(res.records);
			this.tableData1 = res.records || [];
			this.total1 = res.total;
		},
		handleSizeChange1(val) {
			this.queryInfo1.pageSize = val;
			this.getList1();
		},
		handleCurrentChange1(val) {
			this.queryInfo1.pageNum = val;
			this.getList1();
		},
		async setMain1(row) {
			this.formData1.buyprice = row.price;
			this.formData1.goodsid = this.id;
			this.formData1.supplierId = row.supplierId;

			// this.$refs.updatecommentbuy.validate(async valid => {
			// 	if (valid) {
			// 		console.log(valid);
			// 	}
			// });

			this.$confirm('确认设置为推荐吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await materialUpdatecommentbuyService(this.formData1);
					this.getDetail(this.id);
					this.$message.success('操作成功');
				})
				.catch(error => {
					console.log(error);
				});
		},
		async setMain1Dialog() {
			this.formData1.goodsid = this.id;
			this.$refs.updatecommentbuy.validate(async valid => {
				if (valid) {
					const res = await materialUpdatecommentbuyService(this.formData1);
					this.getDetail(this.id);
					this.closeSetMain1();
					this.$message.success('操作成功');
				}
			});
		},

		showSetMain1() {
			this.formData1 = {
				buyprice: null,
				goodsid: null,
				supplierId: null
			};
			if (this.materialDetail.commentSupplierPo.id) {
				this.formData1.supplierId = this.materialDetail.commentSupplierPo.id;
			}
			if (this.materialDetail.commentBuyPrice) {
				this.formData1.buyprice = this.materialDetail.commentBuyPrice;
			}
			this.isShowUpdatecommentbuy = true;
		},
		closeSetMain1() {
			this.isShowUpdatecommentbuy = false;
		},
		async supplierList(supplierName) {
			// supplierName 子组件新加的文字label
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;

			supplierName &&
				this.supplierData.forEach(item => {
					if (item.name === supplierName) {
						this.formData1.supplierId = item.id;
					}
				});
			// supplierName &&
			// 	this.tableData.forEach(item => {
			// 		if (item.isAddDetail) {
			// 			item.supplierId = supplierId;
			// 		}
			// 	});
		},
		// 快速添加供应商
		showFastAddSupplier() {
			this.isShowFastAddSupplier = true;
			this.selectData = [];
			this.selectData = this.selectData1;
		},
		getSupplierVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseSupplier input')?.value || '',
				id: -1
			});
		},
		async getList2() {
			const dataJson = {
				goodsId: this.id,
				...this.queryInfo2
			};
			const res = await materialsellRequestDetailService(dataJson);
			this.tableData2 = res.records || [];
			console.log('getlist2');
			this.total2 = res.total;
			console.log(res);
		},
		handleSizeChange2(val) {
			this.queryInfo2.pageSize = val;
			this.getList2();
		},
		handleCurrentChange2(val) {
			this.queryInfo2.pageNum = val;
			this.getList2();
		},
		setMain2(row) {
			this.formData2.sellprice = row.price;
			this.formData2.goodsid = this.id;

			this.$confirm('确认设置为推荐吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await materialUpdatecommentsellService(this.formData2);
					this.getDetail(this.id);
					this.$message.success('操作成功');
				})
				.catch(error => {
					console.log(error);
				});
		},
		async setMain2Dialog() {
			this.formData2.goodsid = this.id;
			this.$refs.updatecommentsell.validate(async valid => {
				if (valid) {
					const res = await materialUpdatecommentsellService(this.formData2);
					this.getDetail(this.id);
					this.closeSetMain2();
					this.$message.success('操作成功');
				}
			});
		},
		showSetMain2() {
			this.formData2 = {
				sellprice: null,
				goodsid: null
			};
			if (this.materialDetail.commentSellPrice) {
				this.formData2.sellprice = this.materialDetail.commentSellPrice;
			}
			this.isShowUpdatecommentsell = true;
		},
		closeSetMain2() {
			this.isShowUpdatecommentsell = false;
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.valueCom {
	margin-right: 20px;
}

.buySellList {}

.buySellList p {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.buySellList p .TitleCom {
	display: inline-block;
	width: 60px;
	text-align: justify;
	height: 40px;
	line-height: 40px;
}

.buySellList p .TitleCom:after {
	content: " ";
	display: inline-block;
	width: 100%;
}

// 快速添加供应商 
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}

.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
// 物料详情
</style>
