<template>
	<el-form
		ref="formEle"
		:model="formData"
		size="small"
		label-width="130px"
		v-loading="loadingImgUpload"
		element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<el-form-item label="医院名称" prop="name">
			<el-input :value="baseInfo.hospitalName" disabled />
		</el-form-item>
		<el-form-item label="科室">
			<el-input v-model="baseInfo.hospitalOfficeName" disabled />
		</el-form-item>
		<el-form-item label="负责人">
			<el-input v-model="baseInfo.dutyPeopleName" disabled />
		</el-form-item>
		<Table
			ref="tableDom"
			:data.sync="baseInfo.devList"
			:tableColumn_p="tableColumn_device"
			isHideCheckbox_p="true"
			v-loading="loadingDetail"
		>
			<el-table-column label="操作" align="left" fixed="right" width="100">
				<template slot-scope="scope">
					<el-button id="btn-update-row" type="text" @click="handlerEdit(scope.row)">
						{{ !isHideAction_p ? '编辑' : '查看' }}
					</el-button>
					共{{ scope.row.devImageNumber }}张
				</template>
			</el-table-column>
		</Table>
		<Dialog title="附件列表" :visible.sync="isShowFileList">
			<el-upload
				class="upload-demo"
				:headers="headers"
				:data="uploadData"
				:on-progress="handlerProgress"
				:on-success="handlerSuccess"
				:action="uploadAction"
				:show-file-list="false"
				style="margin-bottom: 10px"
				v-if="!isHideAction_p"
			>
				<el-button size="small" type="primary" :disabled="status_p === 2">
					点击上传
				</el-button>
			</el-upload>
			<Table
				ref="tableDom"
				:data.sync="fileData"
				:tableColumn_p="tableColumn"
				isHideCheckbox_p="true"
			>
				<el-table-column label="文件名" align="left" fixed="right">
					<template slot-scope="scope">
						<!-- <el-link :href="$envConfig.imgHttpAdd + 'measure/' + scope.row.newName" type="primary" target="_blank">
							{{ scope.row.oldName }}
						</el-link>-->

						<el-image
							class="devBaseFormLogoStr"
							style="width:105px; height:105px;"
							:src="$envConfig.imgHttpAdd + 'measure/' + scope.row.newName"
							:preview-src-list="[
								$envConfig.imgHttpAdd + 'measure/' + scope.row.newName
							]"
							:fit="'contain'"
						></el-image>
					</template>
				</el-table-column>
				<el-table-column label="上传时间" align="left" fixed="right">
					<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
				</el-table-column>
				<el-table-column
					label="操作"
					align="left"
					fixed="right"
					width="100"
					v-if="!isHideAction_p"
				>
					<template slot-scope="scope" v-if="status_p !== 2">
						<el-button id="btn-update-row" type="text" @click="deleteFile(scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</Table>
			<el-form-item>
				<div style="text-align: right; margin-top: 20px">
					<span class="dialog-footer">
						<el-button type="primary" @click="saveFile">
							{{ !isHideAction_p ? '保存' : '确定' }}
						</el-button>
					</span>
				</div>
			</el-form-item>
		</Dialog>
		<el-form-item v-if="baseInfo.state === 1">
			<div style="text-align: right; margin-top: 20px">
				<span class="dialog-footer">
					<el-button type="primary" @click="formSubmit">检测报告全部上传完成</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { measureDetailService } from '@s/company/measure/MeasureService';
import {
	measureFileListService,
	inspectionFileDeleteService,
	inspectionImplementStatusService
} from '@s/company/measure/MeasureImplementService';
import Table from '@c/ui/Table';
import Dialog from '@c/ui/Dialog';
import { hgetStorage } from '../../../util/htools.web';

export default {
	props: ['editId_p', 'status_p', 'isHideAction_p'],
	components: {
		Table,
		Dialog
	},
	data() {
		return {
			loadingDetail: true,
			loadingImgUpload: false,
			editId: -1,
			isShowFileList: false,
			uploadData: {},
			formData: {
				inspectionTypeId: null,
				name: '',
				isDel: 0
			},
			uploadAction: this.$envConfig.measureFileUploadUrl,
			tableColumn: [
				// {
				// 	label: '文件名',
				// 	field: 'oldName'
				// }
				/* {
					label: '提交人',
					field: 'listNum'
				}, */
			],
			fileData: [],
			baseInfo: {},
			tableColumn_device: [
				/* {
					label: '科室名称',
					field: 'devInfoDepartmentName'
				}, */
				{
					label: '设备编号',
					field: 'devInfoNumber'
				},
				{
					label: '设备名称',
					field: 'devInfoName'
				},
				{
					label: '设备型号',
					field: 'devInfoModelName'
				}
			],
			headers: {},
			devId: ''
		};
	},
	created() {
		this.headers = {
			Authorization: hgetStorage('token')
		};
	},
	mounted() {
		this.inspectionDetail();
	},
	methods: {
		saveFile() {
			this.isShowFileList = false;
			this.inspectionDetail();
		},
		handlerProgress() {
			this.loadingImgUpload = true;
		},
		handlerSuccess(res) {
			console.log(res);
			this.fileList();
		},
		async deleteFile(row) {
			const dataJson = {
				ids: [row.id]
			};
			const res = await inspectionFileDeleteService(dataJson);
			console.log(res);
			this.fileList();
		},
		async fileList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000,
				measureId: this.editId_p,
				devId: this.devId
			};
			const res = await measureFileListService(dataJson);
			console.log(res);
			this.fileData = res;
			this.loadingImgUpload = false;
		},
		async inspectionDetail() {
			const dataJson = {
				measureId: this.editId_p
			};
			const res = await measureDetailService(dataJson);
			console.log(res);
			this.loadingDetail = false;
			this.baseInfo = res;
		},
		async formSubmit() {
			this.$confirm(this.delTips || '检测报告是否全部上传完成？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					if (this.status_p !== 2) {
						const dataJson = {
							measureId: this.editId_p
						};
						await inspectionImplementStatusService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.$emit('update:isShowAEDialog_p', false);
				})
				.catch(error => {
					console.log(error);
				});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		handlerEdit(row) {
			console.log(row);
			this.isShowFileList = true;
			this.devId = row.devInfoId;
			this.fileList();
			this.uploadData = {
				measureId: this.editId_p,
				devId: this.devId
			};
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
/deep/.el-table__fixed-right::before,
/deep/ .el-table__fixed::before {
	background: none;
}
</style>
