<template>
	<div class="head-serch">
		<el-form class="form" ref="ruleForm" :model="empSummarySearchForm" label-width="100px" @submit.native.prevent
			@keyup.enter.native="searchHandler">
			<el-form-item label="日期">
				<el-date-picker v-model="date" type="daterange" :picker-options="pickerOptions" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"
					:clearable="false"></el-date-picker>
			</el-form-item>
			<!-- <el-form-item label="医院" prop="hosid">
				<el-select v-model="empSummarySearchForm.hosid" placeholder="请选择" class="select-item" multiple collapse-tags
					clearable filterable @change="selectFlowChange" reserve-keyword>
					<el-checkbox v-model="checkOptions.checkAll" :style="{ paddingLeft: '20px' }"
						:indeterminate="checkOptions.isIndeterminate" @change="handleCheckAllChange">
						全选
					</el-checkbox>
					<el-option v-for="(item, index) in hospitalData" :key="index" :label="item.name" :value="item.id">{{
						item.name }}</el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="医院分组" prop="hosid">
				<el-select v-model="empSummarySearchForm.hosid" placeholder="请选择" multiple collapse-tags clearable
					filterable>
					<el-option-group v-for="group in hospitalDataGrop" :key="group.label" :label="group.label">
						<el-option v-for="item in group.options" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-option-group>
				</el-select>
			</el-form-item>

		</el-form>

		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">
			搜索
		</el-button>
		<el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downLoading"
			@click.enter="download">
			导出
		</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { userListService } from '@s/system/UserService';
import { hgetStorage, htimeFormat } from '@/util/htools.web';

export default {
	props: ['downLoading'],

	data() {
		return {
			empSummarySearchForm: {
				hosid: [],
				begin: null,
				end: null
			},
			checkOptions: {
				isIndeterminate: false,
				checkAll: false
			},
			date: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), new Date()],
			hospitalData: [],
			hospitalDataGrop: [{
				serviceState: 1,
				label: '托管服务',
				options: []
			}, {
				serviceState: 2,
				label: '临时服务',
				options: []
			}, {
				serviceState: 0,
				label: '不服务',
				options: []
			}],
			inspectionUserData: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			currentUserOrganization: '',
			enterpriseData: [],
			isShowHosid: false
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		this.contractList();
		// this.inspectionUserList();
		// this.enterpriseList();
		// this.searchHandler();
	},
	computed: {
		startDate() {
			return this.date ? this.date[0] : '';
		},
		endDate() {
			return this.date ? this.date[1] : '';
		}
	},
	methods: {
		// async enterpriseList() {
		// 	const dataJson = {
		// 		pageNum: 1,
		// 		pageSize: 100000
		// 	};
		// 	const res = await enterpriseListService(dataJson);
		// 	this.enterpriseData = res.records;
		// },
		// 流向全选
		handleCheckAllChange(e) {
			console.log(e);
			this.empSummarySearchForm.hosid = e ? this.hospitalData.map(item => item.id) : [];
			this.checkOptions.isIndeterminate = false;
		},
		// 流向选择
		selectFlowChange(e) {
			const nowLength = this.empSummarySearchForm.hosid.length;
			this.checkOptions.isIndeterminate =
				nowLength > 0 && nowLength < this.hospitalData.length;
			this.checkOptions.checkAll = nowLength == this.hospitalData.length;
		},
		searchHandler() {
			this.empSummarySearchForm.begin = `${htimeFormat(
				this.startDate,
				'{y}-{m}-{d}'
			)} 00:00:00`;
			this.empSummarySearchForm.end = `${htimeFormat(this.endDate, '{y}-{m}-{d}')} 23:59:59`;
			console.log('this.empSummarySearchForm');
			console.log(this.empSummarySearchForm);
			if (this.empSummarySearchForm.hosid.length == 0) {
				this.$message.warning('请选择医院');
			} else {
				this.$emit('searchForm', this.empSummarySearchForm);
			}
		},
		download() {
			this.empSummarySearchForm.begin = htimeFormat(
				this.startDate,
				'{y}-{m}-{d} {h}:{i}:{s}'
			);
			this.empSummarySearchForm.end = htimeFormat(this.endDate, '{y}-{m}-{d} {h}:{i}:{s}');
			console.log('this.empSummarySearchForm');
			console.log(this.empSummarySearchForm);
			if (this.empSummarySearchForm.hosid.length == 0) {
				this.$message.warning('请选择医院');
			} else {
				this.$emit('download', this.empSummarySearchForm);
			}
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await getcompanysignListService(dataJson);
			this.hospitalData = [...res.records];
			console.log(res);
			this.hospitalDataGrop.forEach(item1 => {
				res?.records?.forEach(item2 => {
					if (item1.serviceState == item2.serviceState) {
						item1.options.push(item2);
					}
				});
			});
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: this.empSummarySearchForm.hospitalId,
				pageNum: 1,
				pageSize: 10000
			};
			// const res = await inspectionUserListService(dataJson);
			const res = await userListService(dataJson);
			console.log(res);
			// this.inspectionUserData = [...res];
			this.inspectionUserData = [...res.records];
		}
	}
};
</script>

<style lang="less" scoped>
.form {
	display: flex;
}
</style>
