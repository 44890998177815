<template>
	<div class="app-container">
		<ApplySearch @searchForm="searchFormHandler"></ApplySearch>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'applydev'"
		></Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="标题" prop="title"></el-table-column>
			<el-table-column label="分类" prop="typeName"></el-table-column>
			<el-table-column label="内容" prop="body"></el-table-column>
			<el-table-column label="申请人" prop="applicantName"></el-table-column>
			<el-table-column label="审批人" prop="approverName"></el-table-column>

			<el-table-column label="时间" prop="applyDate">
				<template slot-scope="scope">{{ scope.row.applyDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'applydev', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'applydev', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
					<el-button
						id="btn-download-row"
						type="success"
						v-authorize="{ name: 'download', type: 'applydev', id: 'btn-download-row' }"
						@click="downloadFile(scope.row)"
						:disabled="!scope.row.filepath"
					>
						下载文件
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<ApplyAdd
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></ApplyAdd>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { listService, deleteService, detailService } from '@s/doc/ApplyService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ApplyAdd from '@f/doc/apply/ApplyAdd.form';
import ApplySearch from '@f/doc/apply/ApplySearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'CompanyDept',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		ApplyAdd,
		ApplySearch
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			token: localStorage.getItem('token')
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
				this.selectData = [];
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.getList();
					this.selectData = [];
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.getList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		downloadFile(row) {
			console.log(row);
			console.log(this.$envConfig.OfficialWebsite);
			// http://img.ygongyun.com/apply/d8122932-884a-43cc-be6f-c50f24d39405.docx
			console.log(this.$envConfig);
			console.log(`${this.$envConfig.OfficialWebsite}apply/${row.filepath}`);
			window.location.href = `${this.$envConfig.imgHttpAdd}apply/${row.filepath}`;
			// window.location.href = `${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`;
		},
		async getList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await listService(dataJson);
			console.log('res');
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await detailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await deleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await detailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await deleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		}
	}
};
</script>
<style lang="less" scoped>
// /deep/ .el-dialog {
// 	width: 500px;
// 	overflow: hidden;
// }

// /deep/.el-dialog .el-form-item {
// 	width: 100%;
// 	margin-right: 15px;
// 	float: left;
// 	height: 33px;
// }

// /deep/ .el-form-item__content {
// 	// width: 100%;
// }

// /deep/ .el-select {
// 	width: 100%;
// }
</style>
