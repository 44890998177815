<template>
	<el-form ref="formEle" :model="formData" :rules="factoryRules" size="small" label-width="130px">
		<el-form-item label="巡检模板" prop="inspectionTypeId">
			<el-select v-model="formData.inspectionTypeId" placeholder="请选择巡检模板" clearable filterable style="width: 100%">
				<el-option v-for="item in templateData" :key="item.id" :label="item.name" :value="item.id"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="项目名称" prop="name">
			<el-input v-model="formData.name" placeholder="请输入项目名称" />
		</el-form-item>
		<el-form-item label="选项一" prop="itemOne">
			<el-input v-model="formData.itemOne" placeholder="请输入选项一" />
		</el-form-item>
		<el-form-item label="选项二" prop="itemTwo">
			<el-input v-model="formData.itemTwo" placeholder="请输入选项二" />
		</el-form-item>
		<el-form-item label="选项三" prop="itemThree">
			<el-input v-model="formData.itemThree" placeholder="请输入选项三" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="formCancel">取 消</el-button>
					<el-button type="primary" @click="formSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { inspectionTempListService, inspectionItemAddService, inspectionItemEditService } from '@s/company/inspectionTemp/inspectionTempService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			formData: {
				inspectionTypeId: null,
				name: '',
				isDel: 0
			},
			factoryRules: {},
			templateData: []
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {
		this.inspectionTempList();
	},
	methods: {
		async inspectionTempList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000
			};
			const res = await inspectionTempListService(dataJson);
			this.templateData = [...res.records];
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await inspectionItemAddService(this.formData);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await inspectionItemEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
