import { hpostRequest } from '../../util/htools.axios';

// 出入库历史查询
export const inOutStockRecordService = data => {
	return new Promise(async resolve => {
		try {
			const res = await hpostRequest('hwms/stock/detailSearch', data);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 物料情况统计 表格数据 三级展开
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const inOutStockSummaryService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				warehouseId: '',
				...data
			};
			const res = await hpostRequest('hwms/stock/summary', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default inOutStockRecordService;
