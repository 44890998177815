import {
    hpostRequest
} from '@u/htools.axios';

/*
 *@Description: 物料类型添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:19
 */
export const materialFlowTraceService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                begin: '',
                end: '',
                // 物料id?
                goodsid: 0,
                // 组织id
                orgid: 0,
                // 仓库id
                warehouseid: 0,
                ...data
            };
            const res = await hpostRequest('hwms/stock/trace', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

export default materialFlowTraceService;
