<template>
	<div class="app-container">
		<div class="head-container">
			<!-- <AuthorizeSearchForm @searchForm="searchFormHandler"></AuthorizeSearchForm> -->
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'materialType'"
					:isHide_p="{ removeBtn: true }"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<!-- icon="el-icon-edit" -->
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'materialType', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<!-- icon="el-icon-delete" -->
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'materialType', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<AuthorizeAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></AuthorizeAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialTypeListService, materialTypeDetailService, materialTypeDeleteService } from '@s/spareParts/MaterialTypeService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Dialog from '@c/ui/Dialog';
import AuthorizeAddForm from '@f/spareParts/materialType/MaterialTypeAdd.form';
/* import AuthorizeSearchForm from '@f/spareParts/materialType/MaterialTypeSearch.form'; */
import ListMixin from '@m/List.mixin';

export default {
	name: 'MaterialType',
	mixins: [ListMixin],
	components: {
		Table,
		Button,
		Dialog,
		AuthorizeAddForm
		/* AuthorizeSearchForm */
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'name'
				}
			],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增物料类型' : '编辑物料类型';
		}
	},
	watch: {
		isRefreshList(newValue) {
			if (newValue) {
				this.materialTypeList();
				/* this.pageIndex = 1; */
			}
		}
	},
	mounted() {
		this.materialTypeList();
	},
	methods: {
		async materialTypeList() {
			const dataJson = {
				/* ...this.searchForm */
			};
			const res = await materialTypeListService(dataJson);
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
			this.$refs.tableDom.clearSelection();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await materialTypeDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler(isDeleteLeaf) {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0],
				autoDelChild: isDeleteLeaf
			};
			await materialTypeDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await materialTypeDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.$confirm('是否删除子级？', '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '不删除',
				distinguishCancelAndClose: true,
				type: 'warning'
			})
				.then(async () => {
					this.selectData = [row];
					await this.showDeleteHandler(true);
					this.$refs.tableDom.clearSelection();
				})
				.catch(async e => {
					if (e === 'cancel') {
						this.selectData = [row];
						await this.showDeleteHandler(false);
						this.$refs.tableDom.clearSelection();
					}
				});
		}
		/* searchFormHandler(searchForm) {
			this.pageIndex = 1;
			this.searchForm = searchForm;
			this.materialTypeList();
		} */
	}
};
</script>

<style scoped></style>
