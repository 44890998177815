<template>
	<el-form ref="departmentForm" :model="departmentForm" :rules="departmentRules" size="small" label-width="130px">
		<el-form-item label="名称" prop="department">
			<el-input v-model="departmentForm.department" placeholder="请输入名称" />
		</el-form-item>
		<el-form-item label="父级" prop="parentId">
			<!-- <el-cascader v-model="departmentForm.parentIdArr" :options="optionsDept" clearable filterable></el-cascader> -->

			<el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable @change="handlerDeptChange">
				<el-option :value="departmentForm.parentId" style="height: auto">
					<el-tree :data="optionsDept" node-key="id" ref="tree" highlight-current :props="defaultProps" @node-click="nodeClickHandler"></el-tree>
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="负责人姓名" prop="dutyPeople">
			<el-select v-model="departmentForm.dutyPeople" placeholder="请选择负责人姓名" clearable filterable @change="getName">
				<el-option v-for="item in optionsPeople" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="巡检周期(月)" prop="inspectPeriod">
			<el-input v-model.number="departmentForm.inspectPeriod" type="number" placeholder="请输入巡检周期" />
		</el-form-item>
		<el-form-item label="下次巡检时间" prop="nextInspectTime">
			<el-date-picker
				v-model="departmentForm.nextInspectTime"
				type="date"
				placeholder="选择下次巡检时间"
				value-format="yyyy-MM-dd HH:mm:ss"
				style="width:100%"
			></el-date-picker>
		</el-form-item>
		<el-form-item label="巡检提前通知天数" prop="inspectRemindDays">
			<el-input v-model.number="departmentForm.inspectRemindDays" type="number" placeholder="请输入巡检提前通知天数" />
		</el-form-item>
		<el-form-item label="保养周期(月)" prop="upkeepPeriod">
			<el-input v-model.number="departmentForm.upkeepPeriod" type="number" placeholder="请输入保养周期" />
		</el-form-item>
		<el-form-item label="下次保养时间" prop="nextUpkeepTime">
			<el-date-picker
				v-model="departmentForm.nextUpkeepTime"
				type="date"
				placeholder="选择下次保养时间"
				value-format="yyyy-MM-dd HH:mm:ss"
				style="width:100%"
			></el-date-picker>
		</el-form-item>
		<el-form-item label="保养提前通知天数" prop="upkeepRemindDays">
			<el-input v-model.number="departmentForm.upkeepRemindDays" type="number" placeholder="请输入保养提前通知天数" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="departmentFormCancel">取 消</el-button>
					<el-button type="primary" @click="departmentFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { departmentListDefaultService, departmentAddService, departmentEditService } from '@s/hospital/department/DepartmentService';
import { userListService } from '@s/system/UserService';
import { getTreeNodeById, deleteTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			optionsDept: null,
			optionsPeople: [],
			editId: -1,
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			departmentForm: {},
			departmentRules: {
				department: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					newValue[0].parentId = Number(newValue[0].parentId);
					newValue[0].dutyPeople = Number(newValue[0].dutyPeople);
					this.editId = newValue[0].id;
					this.departmentForm = { ...newValue[0] };
					this.optionsDept === null && (await this.departmentListDefaultService());
					console.log('this.optionsDept');
					console.log(this.optionsDept);
					console.log(newValue);
					getTreeNodeById(this.optionsDept, 'id', newValue[0].parentId, node => {
						this.parentName = node.department;
					});
					if (newValue[0].dutyPeople === 0) {
						this.departmentForm.dutyPeople = null;
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {
		this.departmentListDefaultService();
		this.userListService();
	},
	methods: {
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 查询所有部门列表
		async departmentListDefaultService() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			const r = this.addTreeKey(res.records, 0);
			// res.forEach(item => {
			// 	item.value = item.id;
			// 	item.label = item.department;
			// 	// TO OPTIMIZATION
			// 	if (item.children && item.children.length > 0) {
			// 		item.children.forEach(item1 => {
			// 			item1.value = item1.id;
			// 			item1.label = item1.department;
			// 			if (item1.children && item1.children.length > 0) {
			// 				item1.children.forEach(item2 => {
			// 					item2.value = item2.id;
			// 					item2.label = item2.department;
			// 				});
			// 			}
			// 		});
			// 	}
			// });
			this.optionsDept = r;

			if (this.editId === -1) {
				this.optionsDept = r;
			} else {
				this.optionsDept = deleteTreeNodeById(r, this.editId);
			}
		},
		handlerDeptChange(val) {
			console.log(val);
			if (!val) {
				this.departmentForm.parentId = null;
			}
		},
		nodeClickHandler(data) {
			this.departmentForm.parentId = data.id;
			this.parentName = data.department;
		},
		// 查询所有负责人列表
		async userListService() {
			const res = await userListService({
				pageNum: 1,
				pageSize: 999
			});
			this.optionsPeople = res.records;
			console.log('负责人下拉');
			console.log(res);
		},
		getName(e) {
			console.log(e);
			this.optionsPeople.forEach(item => {
				if (item.id === e) {
					this.departmentForm.dutyPeopleName = item.nickName;
				} else {
					this.departmentForm.dutyPeopleName = '';
				}
			});
		},
		departmentFormSubmit() {
			this.$refs.departmentForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await departmentAddService(this.departmentForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						console.log(this.editId);
						const dataJson = {
							id: this.editId,
							...this.departmentForm
						};
						await departmentEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.departmentFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		departmentFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style></style>
