<template>
  <div>
    <el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="100px">
      <el-form-item label="合同名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入合同名称" clearable :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item label="甲方" prop="leftName">
        <el-autocomplete v-model="formData.leftName" placeholder="请输入甲方" clearable :style="{ width: '100%' }" :fetch-suggestions="querySearch"></el-autocomplete>
      </el-form-item>
      <el-form-item label="乙方" prop="rightName">
        <el-autocomplete v-model="formData.rightName" placeholder="请输入乙方" clearable :style="{ width: '100%' }" :fetch-suggestions="querySearch"></el-autocomplete>
      </el-form-item>
      <el-form-item label="分类" prop="typeid">
        <el-select v-model="formData.typeid" placeholder="请选择分类" class="select-item" filterable clearable>
          <el-option v-for="(item, index) in typeData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期" prop="dataArr">
        <el-date-picker v-model="formData.dataArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="文档" prop="files">
        <Upload :action="uploadAction" class="isShowUpload" :limit="1" :file-list="fileList" :on-success="handleSuccess" :on-remove="handleRemove" :isShowTip_p="true">
          <el-alert title="如有多个文件，请上传压缩包" style="padding: 0" type="warning" :closable="false"></el-alert>
          <!-- <p>图片只能上传一张图片</p> -->
        </Upload>
      </el-form-item>
      <el-form-item label="备注" prop="info">
        <el-input v-model="formData.info" placeholder="请输入备注" clearable :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="text-align: right">
          <span class="dialog-footer">
            <el-button @click="formCancel">取 消</el-button>
            <el-button type="primary" @click="formSubmit">确 定</el-button>
          </span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import {
	contractAddService,
	contractUpdateService,
	typesListService,
	contractLeftAndRightService
} from '@s/spareParts/ContractService';
import Upload from '@c/ui/Upload.vue';
import { hgetStorage } from '@u/htools.web';

export default {
	components: {
		Upload
	},
	props: ['selectData_p', 'isRefreshList_p', 'typeid_p'],
	data() {
		return {
			pickerOptions: {
				disabledDate() {
					return false;
				}
			},
			editId: -1,
			currentOrgid: '',
			formData: {
				name: null, // 合同名称
				leftName: null, // 甲方
				rightName: null, // 乙方
				typeid: null, // 分类id
				beginDate: null, // 开始时间
				endDate: null, // 结束时间
				files: null, // 附件
				info: null // 备注
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入合同名称',
						trigger: 'blur'
					}
				],
				leftName: [
					{
						required: true,
						message: '请输入甲方',
						trigger: 'blur'
					}
				],
				rightName: [
					{
						required: true,
						message: '请输入乙方',
						trigger: 'blur'
					}
				],
				dataArr: [
					{
						required: true,
						message: '请选择日期',
						trigger: 'blur'
					}
				],
				typeid: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				files: [
					{
						required: true,
						message: '请上传文件',
						trigger: 'change'
					}
				]
			},
			parentName: '',
			typeData: null,
			leftNamerightNameData: null,
			fileList: [],
			fileTempList: [],
			uploadAction: `${this.$envConfig.imgHttpWms}contract/ImgUpdate`
		};
	},
	created() {
		this.currentOrgid = hgetStorage('organization');
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				console.log(newValue);
				this.formData.typeid = this.formData.typeid || this.typeid_p;
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.fileList = [];
					this.fileList.push({
						name: newValue[0].filename || '上传文件名',
						url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.norm}/${newValue[0].picture}`
					});
					console.log(this.fileList);
					this.formData = { ...newValue[0] };
					this.formData.dataArr = [newValue[0].beginDate, newValue[0].endDate];
					console.log(this.formData);
					setTimeout(() => {
						this.isShowUpload();
					}, 30);
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.typeList();
		this.contractLeftAndRightService();
	},
	methods: {
		querySearch(queryString, cb) {
			const [leftNamerightNameData] = [this.leftNamerightNameData];
			const results = queryString
				? leftNamerightNameData.filter(this.createFilter(queryString))
				: leftNamerightNameData;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return restaurant => {
				return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
			};
		},
		async contractLeftAndRightService() {
			const dataJson = {};
			const res = await contractLeftAndRightService(dataJson);
			const arr = [];
			res.forEach(item => {
				arr.push({ value: item });
			});
			this.leftNamerightNameData = arr;
		},
		async typeList() {
			const dataJson = {};
			const res = await typesListService(dataJson);
			this.typeData = res;
		},
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--text');
			if (this.formData.files === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handleSuccess(res) {
			this.formData.files = res.result.path;
			if (!this.formData.title) {
				this.formData.title = res.result.oldName;
			}
			this.formData.filename = res.result.oldName;
			this.isShowUpload();
		},
		handleRemove() {
			this.formData.files = '';
			this.isShowUpload();
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					// this.formData.typeid = this.formData.typeid || this.typeid_p;
					console.log(this.formData.dataArr);
					if (this.formData?.dataArr?.length === 2) {
						[this.formData.beginDate, this.formData.endDate] = this.formData.dataArr;
					}
					if (this.editId === -1) {
						await contractAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await contractUpdateService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
