<template>
  <el-row>
    <el-col :span="24">
      <el-form ref="formEle" class="baseInfo" :model="formData" :rules="formRules" size="small" label-width="96px" :inline="true">
        <el-form-item label="物料名称">
          <span>{{ materialDetailData.goodsName }}</span>
        </el-form-item>
        <el-form-item label="在途">
          <span>{{ materialDetailData.cnt }}</span>
        </el-form-item>
        <el-form-item label="源库房">
          <span>{{ materialDetailData.fromwName }}</span>
        </el-form-item>
        <el-form-item label="目标库房">
          <span>{{ materialDetailData.towName1 }}</span>
        </el-form-item>
      </el-form>
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px" :inline="true">
        <el-form-item label="出入库类型" prop="inoutType">
          <el-select v-model="formData.inoutType" @change="issueReceiptChangeHandler" clearable disabled placeholder="请选择出入库类型">
            <el-option v-for="(item, index) in issueReceiptTypeData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关单号" prop="aboutId">
          <el-select v-model="formData.aboutId" @change="aboutIdChange" clearable placeholder="请选择相关单号">
            <el-option v-for="(item, index) in absoutNumberData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="cnt">
          <el-input v-model="formData.cnt" clearable placeholder="请输入数量" />
        </el-form-item>
        <el-form-item label="库房" prop="warehouseId">
          <el-select v-model="formData.warehouseId" filterable @change="warehouseChangeHandler" placeholder="请选择库房">
            <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="库位" prop="positionId">
          <el-select v-model="formData.positionId" filterable clearable>
            <el-option v-for="(item, index) in wareareaData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { issueReceiptTypeListService } from '@s/spareParts/CurrentStockService';
import { transferingAddService } from '@s/spareParts/TransferingService';
import { checkStockSelectListService } from '@s/spareParts/CheckStockService';
import { warehouseAllListService, warehouseSelfListService } from '@s/spareParts/WarehouseService';
import { wareareaListService } from '@s/spareParts/WareareaService';
import { transferListService } from '@s/spareParts/TransferService';
import { hgetStorage, htimeFormat } from '../../../util/htools.web';

export default {
	props: ['selectData_p'],
	components: {},
	data() {
		const validCnt = (rule, value, callback) => {
			if (value > this.materialDetailData.cnt) {
				callback(new Error('数量不能大于库存'));
			}
			callback();
		};
		return {
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			materialData: [],
			issueReceiptTypeData: [],
			absoutNumberData: [],
			warehouseData: [],
			materialDetailData: null,
			wareareaData: [],
			// 弹窗
			formData: {
				inoutType: 2,
				aboutId: '',
				cnt: '',
				warehouseId: '',
				positionId: ''
			},
			formRules: {
				inoutType: [
					{
						required: true,
						message: '请选择出入库',
						trigger: 'change'
					}
				],
				aboutId: [
					{
						required: true,
						message: '请选择相关单号',
						trigger: 'change'
					}
				],
				cnt: [
					{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					},
					{
						validator: validCnt,
						trigger: 'blur'
					}
				],
				positionId: [
					{
						required: true,
						message: '请选择库位',
						trigger: 'change'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					const temp = newValue[0];
					this.materialDetailData = temp;
					this.materialDetailData.towName1 = temp.towName;
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.issueReceiptTypeList();
		const roles = hgetStorage('roleIds');
		const factoryRole = this.$envConfig.factoryAdminRoleId;
		const hospitalRole = this.$envConfig.hospitalAdminRoleId;
		if (roles.indexOf(factoryRole) > -1 || roles.indexOf(hospitalRole) > -1) {
			this.warehouseAllList();
		} else {
			this.warehouseSelfList();
		}
		this.transferList();
		this.formData.aboutId = this.materialDetailData.aboutId;
		this.formData.cnt = this.materialDetailData.cnt;
		this.formData.warehouseId = this.materialDetailData.towid;
		// this.warehouseData.forEach(item => {
		// 	if (Number(item.id) === Number(this.formData.warehouseId)) {
		// 		this.materialDetailData.towName = item.name;
		// 	}
		// });
		this.formData.warehouseId && this.wareareaList();
	},
	methods: {
		aboutIdChange() {
			if (this.formData.inoutType === 4) {
				// 盘盈入库
				this.formData.batchCode = `pd${this.formData.aboutId}`;
			}
		},
		async issueReceiptTypeList() {
			const dataJson = {
				type: 1,
				...this.searchForm
			};
			const res = await issueReceiptTypeListService(dataJson);
			this.issueReceiptTypeData = res;
		},
		issueReceiptChangeHandler() {
			this.formData.aboutId = '';
			this.absoutNumberData = [];
			if (this.formData.inoutType === 2) {
				// 调拨入库
				this.transferList();
			}
		},
		warehouseChangeHandler(value) {
			this.wareareaData = [];
			this.warehouseData.forEach(item => {
				if (Number(item.id) === Number(value)) {
					this.materialDetailData.towName1 = item.name;
				}
			});
			this.formData.positionId = null;
			this.formData.warehouseId && this.wareareaList();
		},
		async checkStockSelectList() {
			const dataJson = {};
			const res = await checkStockSelectListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${item.requestTime})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		async transferList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				fromWarehouseId: this.materialDetailData.fromwid, // fromwName	fromwid
				toWarehouseId: this.materialDetailData.towid, // towName	towid
				goodsId: this.materialDetailData.goodsId,
				filterType: 2
			};
			const res = await transferListService(dataJson);
			res.records.forEach(item => {
				item.name = `${item.fromWareHouseName}-${item.toWarehouseName}(${htimeFormat(
					item.requestTime
				)})`;
			});
			// res.records.forEach(item => {
			// 	item.name = `${item.fromWareHouseName}-${item.toWarehouseName}(${item.requestTime})`;
			// });
			this.absoutNumberData = [];
			this.absoutNumberData = res.records;
		},
		async warehouseAllList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseAllListService(dataJson);
			console.log(res);
			this.warehouseData = res.records;
		},
		async warehouseSelfList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseSelfListService(dataJson);
			console.log(res);
			this.warehouseData = res.records;
		},
		async wareareaList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				warehouseId: this.formData.warehouseId
			};
			const res = await wareareaListService(dataJson);
			this.wareareaData = res.records;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						const dataJson = {
							cnt: this.formData.cnt,
							position: this.formData.positionId,
							waitid: this.materialDetailData.id
						};
						await transferingAddService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.baseInfo .el-form-item__label {
	color: #666666;
}
</style>
