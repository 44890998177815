/* *@Description: 物料提示框 接收 goodsId goodsName *@ClassAuthor: Happy ZXH *@Date: 2024-01-02
15:30:37 */
<template>
  <div>
    <el-popover placement="right-start" title="物料详情" trigger="click" @show="materialDetail(goodsId)" popper-class="materDetailWrap">
      <div class="">
        <div class="baseInfo" style="display:flex;">
          <img v-if="materialDetailData.pic" :src="materialDetailData.picStr" alt="" style="margin-right:10px;width:100px;" />
          <div class="titleWrap">
            <p>{{ materialDetailData.code }}</p>
            <h3 style="font-size:20px; font-weight:bole; color:rgba(0,0,0,0.9)">
              {{ materialDetailData.name }}
            </h3>
            <p>{{ materialDetailData.typeName }}</p>
            <p>{{ materialDetailData.forDev }}</p>
            <p>{{ materialDetailData.forDevSpec }}</p>
          </div>
        </div>
        <table border class="table" style="margin-top:10px;">
          <tr>
            <td style="width:28px;">规格</td>
            <td>{{ materialDetailData.spec }}</td>
            <td style="width:28px;">型号</td>
            <td>{{ materialDetailData.goodsVer }}</td>
          </tr>
          <tr>
            <td style="width:28px;">厂家</td>
            <td colspan="3">
              {{ materialDetailData.factory }}
            </td>
          </tr>
          <tr>
            <td style="width:28px;">标签</td>
            <td colspan="3">{{ materialDetailData.tags }}</td>
          </tr>
        </table>
      </div>
      <span slot="reference" style="cursor:pointer;">{{ goodsName }}</span>
    </el-popover>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialDetailService } from '@s/spareParts/MaterialService';

export default {
	props: ['materialObjP'],
	components: {},

	data() {
		return {
			materialDetailData: {},
			goodsId: null,
			goodsName: null
		};
	},

	watch: {},

	computed: {},

	mounted() {
		this.$nextTick(() => {
			this.goodsId = this.materialObjP.goodsId;
			this.goodsName = this.materialObjP.goodsName;
		});
		console.log('this.goodsId, this.goodsName');
		console.log(this.materialObjP);
	},

	methods: {
		// #region  S===============================
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			res.pic &&
				(res.picStr = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${res.pic}`);
			if (res.tags != null && res.tags?.length > 0) {
				res.tagsArr = res.tags.split(',');
			} else {
				res.tagsArr = [];
			}

			this.materialDetailData = res;
		}
		// #endregion
	}
};
</script>
<style lang="less" scoped></style>
