<!--
 *@Description: 
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-06-30 17:52:04
-->
<template>
	<div>
		<el-row :gutter="30" style="margin-bottom: 20px">
			<el-col :span="4">
				<el-card class="box-card" :body-style="{ background: '#47BBBB', color: '#fff',padding:'10px' }">
					<div style="display:flex;align-items:center;">
						<i class="el-icon-eleme" style="font-size:46px; margin-right:10px;"></i>
						<div style>
							<div>设备总数</div>
							<h3 style="font-size:28px;">{{ countData.equipmentNumber }}</h3>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" :body-style="{ background: '#FA752A', color: '#fff',padding:'10px' }">
					<div style="display:flex;align-items:center;">
						<i class="el-icon-eleme" style="font-size:46px; margin-right:10px;"></i>
						<div style>
							<div>工程师人数</div>
							<h3 style="font-size:28px;">{{ countData.staffNumber }}</h3>
						</div>
					</div>
				</el-card>
			</el-col>
			<!-- <el-col :span="6">
				<el-card class="box-card" :body-style="{ background: '#FA752A', color: '#fff' }">
					<p>
						<i class="el-icon-s-custom"></i>
						工程师人数
					</p>
					<p>{{ countData.staffNumber }}</p>
				</el-card>
			</el-col>-->
		</el-row>

		<el-card class="box-card">
			<div slot="header" class="clearfix comTitle">
				<span>维修公司信息</span>
			</div>
			<div class="comBaseInfo">
				<el-image
					class="devBaseFormLogoStr"
					style=" width: 100px; height: 100px; border:1px solid #cccccc;padding:5px;"
					:src="logo"
					:preview-src-list="[logo]"
					:fit="'contain'"
				></el-image>
				<ul>
					<li>
						<span class="title">维修公司名称</span>
						<span class="content">{{ companyDetail.name }}</span>
					</li>
					<li>
						<span class="title">联系电话</span>
						<span class="content">{{ companyDetail.phone }}</span>
					</li>
					<li>
						<span class="title">官方网址</span>
						<span class="content">{{ companyDetail.website }}</span>
					</li>
					<!-- <li>
						<span class="title">添加人</span>
						<span class="content">{{ companyDetail.createUserId }}</span>
					</li>-->
					<li>
						<span class="title">维修范围</span>
						<span class="content">{{ companyDetail.scopeMaintenanceId }}</span>
					</li>
					<li>
						<span class="title">地址</span>
						<span class="content">
							{{ '' + (companyDetail.provinceName || '') +(companyDetail.cityName || '')
							+(companyDetail.countyName || '') + companyDetail.address }}
						</span>
					</li>
					<li>
						<span class="title">维修公司营业执照</span>
						<span class="content">
							<el-image
								style="position:fixed;top:-9999px;left:-9999px; z-index:9999999999;"
								:src="businessLicense"
								:preview-src-list="[businessLicense]"
								ref="previewbusinessImg"
					:fit="'contain'"
							></el-image>
						</span>
						<el-button plain @click="previewbusinessLicense">查看原图</el-button>
					</li>
				</ul>
			</div>
		</el-card>

		<div style="height:20px;"></div>

		<el-card class="box-card">
			<el-tabs v-model="activeName">
				<el-tab-pane label="设备信息" name="0">
					<el-table
						:data="hospitalDevList"
						style="width: 100%"
						border
						:header-cell-style="{ background: '#F4F4F4' }"
					>
						<!-- <el-table-column type="index" width="50"></el-table-column> -->

						<!-- <el-table-column property="devInfoName" label="设备名称"></el-table-column>
					<el-table-column property="affiliatedHospitalName" label="医院名称"></el-table-column>
					<el-table-column property="devInfoModelName" label="设备型号"></el-table-column>
					<el-table-column property="devInfoDepartmentName" label="部门"></el-table-column>
					<el-table-column property="devInfoNns1" label="规格型号"></el-table-column>
					<el-table-column property="devInfoNns2" label="计量单位"></el-table-column>
					<el-table-column property="devInfoNns3" label="设备金额"></el-table-column>
					<el-table-column property="devInfoNumber" label="设备编码"></el-table-column>
					<el-table-column property="devInfoSerialNo" label="生产序列号"></el-table-column>
					<el-table-column property="devInfoTypeName" label="设备类型"></el-table-column>
					<el-table-column property="testCycle" label="计量周期"></el-table-column>
						<el-table-column property="transferBatchNumber" label="保修时间(天)"></el-table-column>-->
						<el-table-column type="index" width="50"></el-table-column>
						<el-table-column label="ID" prop="devInfoId"></el-table-column>
						<el-table-column label="设备编号" prop="devInfoNumber"></el-table-column>
						<el-table-column label="设备名称" prop="devInfoName"></el-table-column>
						<el-table-column label="设备类型" prop="devInfoTypeName"></el-table-column>
						<el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
						<el-table-column label="所在科室" prop="devInfoDepartmentName"></el-table-column>
						<el-table-column label="维保公司" prop="orgName"></el-table-column>
					</el-table>

					<el-pagination
						style="margin-top:20px; margin-bottom:20px; text-align:right;"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="deviceInfo.pageNum"
						:page-sizes="[10, 20, 30, 50]"
						:page-size="deviceInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</el-tab-pane>
				<el-tab-pane label="工程师信息" name="1">
					<el-table
						:data="hospitalEngineer"
						style="width: 100%"
						border
						:header-cell-style="{ background: '#F4F4F4' }"
					>
						<el-table-column type="index" width="50"></el-table-column>

						<el-table-column property="nickName" label="人员姓名"></el-table-column>
						<el-table-column property="telphone" label="联系电话"></el-table-column>
						<el-table-column property="deptName" label="部门"></el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<div style="height:20px;"></div>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { contractDetailService1 } from '@s/hospital/contract/ContractService';
// eslint-disable-next-line import/no-cycle
import { devBaseListService } from '@s/hospital/devBase/DevBaseService';
import ListMixin from '@m/List.mixin';
// eslint-disable-next-line import/no-cycle
import { contractHospitalEngineerService, contractCountService } from '@s/company/contract/ContractDetailService';
// import { hgetStorage } from '../../../util/htools.web';

export default {
	mixins: [ListMixin],
	name: '',
	data() {
		return {
			activeName: '0',
			id: null,
			companyDetail: {},
			logo: '',
			businessLicense: '',
			hospitalDevList: null,
			hospitalEngineer: null,

			deviceInfo: {
				pageNum: 1,
				pageSize: 10
			},

			countData: {
				equipmentNumber: 0,
				staffNumber: 0
			}
		};
	},
	components: {},

	watch: {
		activeName(newValue) {
			this.deviceInfo.pageNum = 1;
			this.deviceInfo.pageSize = 10;
			if (Number(newValue) === 0) {
				this.deviceList();
			} else {
				this.contractHospitalEngineer(this.$route.query.id);
			}
		}
	},

	computed: {},

	mounted() {
		// const powers = hgetStorage('powers');
		// console.log(powers);
		this.id = this.$route.query.id;
		this.getList();
		this.deviceList();
		this.contractCount();
		this.contractHospitalEngineer(this.$route.query.id);
	},

	methods: {
		previewbusinessLicense() {
			this.$refs.previewbusinessImg.showViewer = true;
		},
		// 获取工程师设备数量
		async contractCount() {
			const dataJson = {
				enterpriseId: this.$route.query.id
			};
			const res = await contractCountService(dataJson);
			this.countData = res;
		},
		async getList() {
			const res = await contractDetailService1({ companyId: this.id });
			this.companyDetail = res;
			this.logo = `${this.$envConfig.imgHttpAdd}orglogo/${res.logo}`;
			this.businessLicense = `${this.$envConfig.imgHttpAdd}orgbusinesslicense/${res.businessLicense}`;
		},

		async contractHospitalEngineer(companyId) {
			const dataJson = {
				companyId: companyId
			};
			const res = await contractHospitalEngineerService(dataJson);
			this.hospitalEngineer = [...res];
		},
		// 设备列表
		async deviceList() {
			const dataJson = {
				orgId: this.$route.query.id,
				pageNum: this.deviceInfo.pageNum,
				pageSize: this.deviceInfo.pageSize
			};
			const res = await devBaseListService(dataJson);
			this.total = res.total;
			this.hospitalDevList = [...res.records];
		},

		handleSizeChange(val) {
			this.deviceInfo.pageSize = val;
			this.deviceList();
		},
		handleCurrentChange(val) {
			this.deviceInfo.pageNum = val;
			this.deviceList();
		}
	},

	destroyed() {}
};
</script>
<style lang='less' scoped>
/deep/ .row-style {
	padding: 10px 0;
}
.h150 {
	height: 120px;
}
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
.comBaseInfo {
	display: flex;
	padding: 20px 0;
}
.comBaseInfo ul {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex: 1;
	margin-top: -24px;
}
.comBaseInfo ul li {
	min-height: 30px;
	line-height: 30px;
	margin-top: 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 46%;
}

// 公用
.comTitle {
	color: #373737;
	font-weight: bold;
}
.comBaseInfo .title {
	color: #676968;
	font-weight: bold;
}
.comBaseInfo .content {
	color: #afaeb3;
}
</style>
