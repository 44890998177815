<template>
	<div class="app-container">
		<DeptSearchForm @searchForm="searchFormHandler"></DeptSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'hospitaldept'"
		></Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			highlight-current-row
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="部门" prop="department"></el-table-column>
			<el-table-column label="ID" prop="id"></el-table-column>
			<el-table-column label="负责人姓名" prop="dutyPeopleName"></el-table-column>
			<!-- <el-table-column label="添加人" prop="createUserId"></el-table-column>
			<el-table-column label="负责人姓名" prop="orgId"></el-table-column>-->
			<el-table-column label="添加时间" prop="createDate">
				<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
			</el-table-column>
			<el-table-column label="修改时间" prop="createDate">
				<template slot-scope="scope">{{ scope.row.updateDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'hospitaldept', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'hospitaldept', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DeptAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></DeptAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { departmentListDefaultService, departmentDeleteService, departmentDetailService } from '@s/hospital/department/DepartmentService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import DeptAddForm from '@f/hospital/department/DepartmentAdd.form';
import DeptSearchForm from '@f/hospital/department/DepartmentSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'HospitaDept',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		DeptAddForm,
		DeptSearchForm
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.departmentList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.departmentList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.departmentList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.departmentList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.departmentList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async departmentList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await departmentListDefaultService(dataJson);
			console.log('=====================');
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				departmentId: editId
			};
			const res = await departmentDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await departmentDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				departmentId: row.id
			};
			const res = await departmentDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await departmentDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.departmentList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 500px;
	overflow: hidden;
}
/deep/.el-dialog .el-form-item {
	width: 100%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .el-form-item__content {
	// width: 100%;
}
/deep/ .el-select {
	width: 100%;
}
</style>
