import {
    hgetRequest
} from '@u/htools.axios.js';
/*
 *@Description: 全院维修统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const getCompRepairCntService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {};
            const res = await hgetRequest(
                `hcz/compsummary/getCompRepairCnt?begin=${data.begin}&end=${data.end}`,
                dataJson
            );
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 采购申请 -> 按月分仓库统计
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 14:40:48
 */
export const purchasebywarehousemonthService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/bywarehousemonth', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 销售申请 -> 按月分仓库统计
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 14:40:48
 */
 export const saleApplicationbywarehousemonthService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                id: '',
                ...data
            };
            const res = await hgetRequest('hwms/sellRequest/bywarehousemonth', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
export default getCompRepairCntService;
