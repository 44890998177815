import { hgetRequest, hpostRequest } from '@u/htools.axios';

/*
 *@Description: 文档类型列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:06
 */
export const typeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			// const res = await hgetRequest('hbtyong/jurisdiction/jurisdictionAllQuery', dataJson);
			const res = await hgetRequest('hbtyong_2/doctypes/allmyself', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/* 所有文档类型列表 */
export const typeListAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong_2/doctypes/allicansee', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档类型添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:19
 */
export const typeAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hbtyong_2/doctypes/add', dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档类型编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:39:49
 */
export const typeEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				...data
			};
			const res = await hpostRequest('hbtyong_2/doctypes/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档类型删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 13:22:39
 */
export const typeDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest(`hbtyong_2/doctypes/delete?id=${dataJson.id}`);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 文档类型详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-11 10:36:01
 */
export const typeDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hbtyong_2/doctypes/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default typeListService;
