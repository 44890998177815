import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 巡检保养 设备分类 设置价格
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const xjbypriceupdateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = data;
			console.log('dataJson');
			console.log(JSON.stringify(dataJson));
			const res = await hpostRequest('hbtyong_2/xjbyprice/update?apjson=apjson', JSON.stringify(dataJson));
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 巡检保养价格 列表不分页
 *@ClassAuthor: Happy zxh
 *@Date: 2023-09-12 09:57:52
 */
export const xjbypricelistService = data => {
	return new Promise(async resolve => {
		try {
			const res = await hgetRequest('hbtyong_2/xjbyprice/list');
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default xjbypricelistService;
