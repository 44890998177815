<template>
  <div class="head-serch">
    <el-form class="form" ref="factorySearchForm" :model="factorySearchForm" label-width="80px" @submit.native.prevent @keyup.enter.native="searchHandler">
      <el-form-item label="设备名称">
        <el-input v-model="factorySearchForm.devInfoName" placeholder="请输入设备名称" />
      </el-form-item>
      <el-form-item label="维保公司">
        <el-select v-model="factorySearchForm.orgId" placeholder="请选择" class="select-item" clearable filterable @clear="clearOrg">
          <el-option v-for="(contract, index) in contractData" :key="index" :label="contract.name" :value="contract.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="name">
        <!-- <el-select v-model="devInfoTypeName" class="select-item" placeholder="请选择" collapse-tags clearable @clear="clearDevInfo">
          <el-option :value="factorySearchForm.devType" style="height: auto">
            <el-tree :data="deviceTypeData" node-key="id" ref="tree" highlight-current :props="defaultTypeProps" @node-click="nodeTypeClickHandler"></el-tree>
          </el-option>
        </el-select> -->

        <treeselect v-model="factorySearchForm.devType" :multiple="false" :options="deviceTypeData" style="width:300px;" :disable-branch-nodes="true" :normalizer="normalizer" placeholder="请选择" noResultsText="暂无结果" :close-on-select="true" :show-count="true" />

      </el-form-item>
      <el-form-item label="科室" prop="name">
        <!-- <el-select v-model="parentName" class="select-item" placeholder="请选择" clearable @clear="clearDid">
					<el-option :value="factorySearchForm.did" style="height: auto">
						<el-tree :data="optionsDept" node-key="id" ref="tree" highlight-current :props="defaultProps" @node-click="nodeClickHandler"></el-tree>
					</el-option>
				</el-select> -->

        <treeselect v-model="factorySearchForm.did" :multiple="false" :options="optionsDept" style="width:300px;" :disable-branch-nodes="false" placeholder="请选择" noResultsText="暂无结果" :close-on-select="true" :show-count="true" />

      </el-form-item>
    </el-form>
    <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">搜索</el-button>
  </div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage } from '@/util/htools.web';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
import { devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import Treeselect from '@riophae/vue-treeselect';

export default {
	components: {
		Treeselect
	},
	data() {
		return {
			factorySearchForm: {
				devInfoName: null,
				orgId: null,
				devType: null,
				did: null,
				measureAlert: true
			},
			currentUserOrganization: '',
			contractData: [],
			devInfoTypeName: null,
			parentName: null,
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			defaultTypeProps: {
				children: 'children',
				label: 'deviceType'
			},
			optionsDept: null,
			deviceTypeData: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			}
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		this.contractList();
		this.deviceTypeList();
		this.departmentList();
	},
	methods: {
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await getcompanysignListService(dataJson);
			const { records } = res;
			this.contractData = records;
		},
		clearOrg() {
			this.factorySearchForm.orgId = null;
		},
		clearDevInfo() {
			this.factorySearchForm.devType = null;
		},
		clearDid() {
			this.factorySearchForm.did = 0;
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		nodeTypeClickHandler(data) {
			if (Number(data.pid) === 0) {
				return false;
			}
			this.factorySearchForm.devType = data.id;
			this.devInfoTypeName = data.deviceType;
		},
		searchHandler() {
			this.$emit('searchForm', this.factorySearchForm);
		},
		nodeClickHandler(data) {
			this.factorySearchForm.did = data.id;
			this.parentName = data.department;
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
