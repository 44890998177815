<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="devBaseSearchForm"
			:model="devBaseSearchForm"
			label-width="60px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="状态" prop="name">
				<el-select
					v-model="devBaseSearchForm.states"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
				>
					<el-option label="全部" :value="[0, 1, 2]"></el-option>
					<el-option label="未开始" :value="[0]"></el-option>
					<el-option label="进行中" :value="[1]"></el-option>
					<el-option label="已完成" :value="[2]"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="科室" prop="name">
				<treeselect
					v-model="devBaseSearchForm.hospitalOfficeId"
					:multiple="false"
					:options="optionsDept"
					style="width:220px;"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>
			</el-form-item>
			<el-form-item label="日期">
				<el-date-picker
					v-model="date"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width: 333px"
				></el-date-picker>
			</el-form-item>
			<el-form-item label-width="0" style="margin-left:20px;">
				<el-button
					class="filter-item"
					size="mini"
					type="success"
					icon="el-icon-search"
					@click="searchHandler"
				>
					搜索
				</el-button>
				<el-button
					class="filter-item"
					size="mini"
					type="default"
					@click="resetForm('devBaseSearchForm')"
				>
					重置
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage, htimeFormat } from '@/util/htools.web';
// 选择科室 下拉
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import Treeselect from '@riophae/vue-treeselect';

export default {
	data() {
		return {
			devBaseSearchForm: {
				devBase: '',
				organizationId: ''
			},
			currentUserOrganization: '',
			enterpriseData: [],
			date: null,
			optionsDept: []
		};
	},
	components: {
		// SelectTree,
		Treeselect
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		// this.enterpriseList();
		this.departmentList();
	},
	methods: {
		// async enterpriseList() {
		// 	const dataJson = {
		// 		pageNum: 1,
		// 		pageSize: 100000
		// 	};
		// 	const res = await enterpriseListService(dataJson);
		// 	this.enterpriseData = res.records;
		// },
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		resetForm(formName) {
			this.date = null;
			this.devBaseSearchForm.states = null;
			this.devBaseSearchForm.hospitalOfficeId = null;
			this.devBaseSearchForm.startTime = null;
			this.devBaseSearchForm.endTime = null;
			delete this.devBaseSearchForm.startTime;
			delete this.devBaseSearchForm.endTime;
			delete this.devBaseSearchForm.hospitalOfficeId;
			delete this.devBaseSearchForm.states;

			// this.$refs[formName].resetFields();
			this.searchHandler();
			// this.$emit('searchForm', this.devBaseSearchForm);
		},
		searchHandler() {
			this.devBaseSearchForm.startTime = this.date
				? `${htimeFormat(this.date[0], '{y}-{m}-{d}')} 00:00:00`
				: '';
			this.devBaseSearchForm.endTime = this.date
				? `${htimeFormat(this.date[1], '{y}-{m}-{d}')} 23:59:59`
				: '';
			this.$emit('searchForm', this.devBaseSearchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
