<template>
	<div>
		<div class="search_box">
			<el-date-picker
				v-model="date"
				type="datetimerange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button
				type="success"
				class="search_button"
				@click="searchSubmit"
				size="mini"
			>
				搜索 
			</el-button>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>全院设备数量(台)</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelBuyDevices({
									index: 1,
									title: '全院设备数量(台)',
									options: options1
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts1" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>全院设备金额(元)</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelBuyDevices({
									index: 2,
									title: '全院设备金额(元)',
									options: options2
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(2)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts2" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>全院价值区间统计</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDevicePriceRange({
									index: 3,
									title: '全院价值区间统计',
									options: options3
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(3)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts3"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>全院价值区间统计（折旧后）</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDevicePriceRangeAfterzj({
									index: 31,
									title: '全院价值区间统计（折旧后）',
									options: options31
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(31)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%;">
						<div
							id="echarts31"
							class="echart_box"
							style="width:100%;"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备分类数量</span>

						<el-button
							size="mini"
							round
							@click="
								downloadExcelDevCntPriceByType({
									index: 5,
									title: '设备分类数量',
									options: options5
								})
							"
						>
							导出
						</el-button>
						<div
							style="float: right; display:inline-block;margin-left:10px;"
						>
							<el-button size="mini" round @click="xuriHandler">
								旭日图
							</el-button>
							<el-button
								size="mini"
								round
								@click="rectangleHandler"
							>
								矩形树图
							</el-button>
							<el-button size="mini" round @click="treeHandler">
								树状图
							</el-button>
							<el-button
								style="padding: 3px 0;"
								type="text"
								@click="showBigEchartsHandler(5)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
					</div>
					<div class="echart_item" v-show="echartsIndex === 0">
						<div id="echarts5" class="echart_box"></div>
					</div>
					<div class="echart_item" v-show="echartsIndex === 1">
						<div id="echarts5_1" class="echart_box"></div>
					</div>
					<div class="echart_item" v-show="echartsIndex === 2">
						<div id="echarts5_2" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备分类价值</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDevCntPriceByType({
									index: 6,
									title: '设备分类价值',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<div
							style="float: right; display:inline-block;margin-left:10px;"
						>
							<el-button size="mini" round @click="xuriHandler1">
								旭日图
							</el-button>
							<el-button
								size="mini"
								round
								@click="rectangleHandler1"
							>
								矩形树图
							</el-button>
							<el-button size="mini" round @click="treeHandler1">
								树状图
							</el-button>
							<el-button
								style="padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(6)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
					</div>
					<div class="echart_item">
						<div class="echart_item" v-show="echartsIndex1 === 0">
							<div id="echarts6" class="echart_box"></div>
						</div>
						<div class="echart_item" v-show="echartsIndex1 === 1">
							<div id="echarts6_1" class="echart_box"></div>
						</div>
						<div class="echart_item" v-show="echartsIndex1 === 2">
							<div id="echarts6_2" class="echart_box"></div>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>科室统计台数和价格</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDeviceCntPriceByDepartment({
									index: 4,
									title: '科室统计台数和价格',
									options: options4
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(4)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts4"
							class="echart_box"
							style="height: 424px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备使用年限</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDeviceUsedYearRange({
									index: 10,
									title: '设备使用年限',
									options: options10
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(10)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts10"
							class="echart_box"
							style="height: 424px"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备使用年限（数量）</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(7)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts7"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备使用年限（价值）</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(8)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts8"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>设备使用年限（折旧价值）</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(9)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts9"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	allHospitalDeviceNumberService,
	allHospitalDevicePriceService,
	allHospitalDevicePriceAfterzjService,
	allDepartmentDeviceNumberService,
	devCntPriceByTypeService,
	allHospitalDeviceUsedYearRangeService
} from '@s/hospital/StatisticsService';
import Dialog from '@c/ui/Dialog';
import { htimeFormat, hgetStorage, hdateInMonth } from '@u/htools.web';

export default {
	components: {
		Dialog
	},
	data() {
		const getLevelOption = () => {
			return [
				{
					itemStyle: {
						borderColor: '#777',
						borderWidth: 0,
						gapWidth: 1
					},
					upperLabel: {
						show: false
					}
				},
				{
					itemStyle: {
						borderColor: '#555',
						borderWidth: 5,
						gapWidth: 1
					},
					emphasis: {
						itemStyle: {
							borderColor: '#ddd'
						}
					}
				},
				{
					colorSaturation: [0.35, 0.5],
					itemStyle: {
						borderWidth: 5,
						gapWidth: 1,
						borderColorSaturation: 0.6
					}
				}
			];
		};
		return {
			date: null,
			dialogTitle: null,
			options1: {
				// title: {
				// 	text: '全院设备数量(台)'
				// },
				legend: {
					data: ['当年累计数量', '当年购买数量'],
					left: '0'
				},
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}台`;
					}
				},
				xAxis: {
					data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					axisLine: { onZero: true },
					splitLine: { show: false },
					splitArea: { show: false }
				},
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				grid: {
					left: 60,
					bottom: 40
				},
				series: [
					{
						name: '当年累计数量',
						type: 'bar',
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '当年购买数量',
						type: 'line',
						yAxisIndex: 1,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					}
				]
			},
			options2: {
				// title: {
				// 	text: '全院设备金额(元)'
				// },
				legend: {
					data: ['当年累计金额', '折旧金额', '当年购买金额'],
					left: '0'
				},
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				xAxis: {
					data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					axisLine: { onZero: true },
					splitLine: { show: false },
					splitArea: { show: false }
				},
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				grid: {
					left: 80,
					bottom: 40,
					right: 80
				},
				series: [
					{
						name: '当年累计金额',
						type: 'bar',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					},
					{
						name: '折旧金额',
						type: 'bar',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					},
					{
						name: '当年购买金额',
						type: 'line',
						yAxisIndex: 1,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					}
				]
			},
			options3: {
				// title: {
				// 	text: '全院价值区间统计'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}台`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options31: {
				// title: {
				// 	text: '全院价值区间统计（折旧后）'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}台`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options4: {
				title: {
					// text: '科室统计台数和价格'
				},
				color: ['#006069', '#039CCD', '#a9df97'],
				grid: {
					right: '18%'
				},
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgba(50,50,50,0.7)',
					borderColor: 'rgba(50,50,50,0.7)',
					textStyle: {
						color: 'rgba(255,255,255)'
					},
					axisPointer: {
						type: 'shadow'
					}
				},
				dataZoom: [
					{
						startValue: '',
						endValue: ''
					},
					{
						type: 'inside'
					}
				],
				xAxis: {
					type: 'category',
					data: [],
					axisTick: {
						show: false // 去除刻度线
					},
					axisLabel: {
						show: true, // 是否显示日期
						interval: 0, // 强制显示全部 // rotate: 40,//倾斜的角度
						textStyle: {
							color: '#41B8D3', // 日期的颜色
							fontSize: 12 // 字体的大小
						}
					},
					axisLine: {
						lineStyle: {
							color: '#15253D' // x轴的颜色
						}
					}
				},
				yAxis: [
					{
						type: 'value',
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#41B8D3' // 数字的颜色
							},
							inside: false // 控制数据是否在内侧还是外侧显示
						},
						splitLine: {
							show: true,
							lineStyle: {
								// color: '#15253D',
								width: 1,
								type: 'solid'
							}
						}
					},
					{
						type: 'value',
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#41B8D3' // 数字的颜色
							},
							inside: false // 控制数据是否在内侧还是外侧显示
						},
						splitLine: {
							show: true,
							lineStyle: {
								// color: '#15253D',
								width: 1,
								type: 'solid'
							}
						}
					}
				],
				legend: {
					right: '5%',
					textStyle: {
						// 图列内容样式
						// color: '#fff' // 字体颜色
						// backgroundColor: "black", // 字体背景色
					}
				},
				series: [
					{
						name: '在用台数',
						type: 'bar',
						data: [23, 33, 5, 56, 8, 45],
						barWidth: 20
					},
					{
						name: '价格',
						type: 'line',
						smooth: true,
						yAxisIndex: '1',
						data: [23, 33, 5, 6, 78, 56],
						barWidth: 20
					},
					{
						name: '折旧现值',
						type: 'line',
						smooth: true,
						yAxisIndex: '1',
						data: [23, 33, 5, 6, 78, 56],
						barWidth: 20
					}
				]
			},
			options5: {
				series: {
					type: 'sunburst',
					// emphasis: {
					//     focus: 'ancestor'
					// },
					data: [
						{
							name: 'Grandpa',
							children: [
								{
									name: 'Uncle Leo',
									value: 15,
									children: [
										{
											name: 'Cousin Jack',
											value: 2
										},
										{
											name: 'Cousin Mary',
											value: 5
										},
										{
											name: 'Cousin Ben',
											value: 4
										}
									]
								},
								{
									name: 'Father',
									value: 10,
									children: [
										{
											name: 'Me',
											value: 5
										},
										{
											name: 'Brother Peter',
											value: 1
										}
									]
								}
							]
						}
					],
					radius: [0, '50%'],
					label: {
						rotate: 'radial',
						formatter: params => {
							let aaa = '';
							if (params.value < 60) {
								aaa = ' ';
							} else {
								aaa = params.name;
							}
							return aaa;
						}
						// width: '100',
						// overflow: 'truncate',
						// ellipsis: '...'
					}
				}
			},
			options5_1: {
				tooltip: {
					formatter: info => {
						const { value, treePathInfo } = info;
						const treePath = [];
						for (let i = 1; i < treePathInfo.length; i++) {
							treePath.push(treePathInfo[i].name);
						}
						return [
							`<div class="tooltip-title">${echarts.format.encodeHTML(
								treePath.join('/')
							)}</div>Disk Usage: ${echarts.format.addCommas(
								value
							)} KB`
						].join('');
					}
				},
				series: [
					{
						name: '设备分类数量',
						type: 'treemap',
						visibleMin: 300,
						label: {
							show: true,
							formatter: '{b}'
						},
						upperLabel: {
							show: true,
							height: 30
						},
						itemStyle: {
							borderColor: '#fff'
						},
						levels: getLevelOption(),
						data: []
					}
				]
			},
			options5_2: {
				tooltip: {
					trigger: 'item',
					triggerOn: 'mousemove'
				},
				series: [
					{
						type: 'tree',
						data: [],
						top: '1%',
						left: '7%',
						bottom: '1%',
						right: '20%',
						symbolSize: 7,
						label: {
							position: 'left',
							verticalAlign: 'middle',
							align: 'right',
							fontSize: 9
						},
						leaves: {
							label: {
								position: 'right',
								verticalAlign: 'middle',
								align: 'left'
							}
						},
						emphasis: {
							focus: 'descendant'
						},
						expandAndCollapse: true,
						animationDuration: 550,
						animationDurationUpdate: 750
					}
				]
			},
			options6: {
				series: {
					type: 'sunburst',
					// emphasis: {
					//     focus: 'ancestor'
					// },
					data: [
						{
							name: 'Grandpa',
							children: [
								{
									name: 'Uncle Leo',
									value: 15,
									children: [
										{
											name: 'Cousin Jack',
											value: 2
										},
										{
											name: 'Cousin Mary',
											value: 5
										},
										{
											name: 'Cousin Ben',
											value: 4
										}
									]
								},
								{
									name: 'Father',
									value: 10,
									children: [
										{
											name: 'Me',
											value: 5
										},
										{
											name: 'Brother Peter',
											value: 1
										}
									]
								}
							]
						}
					],
					radius: [0, '50%'],
					label: {
						rotate: 'radial',
						formatter: params => {
							let aaa = '';
							if (params.value < 6000000) {
								aaa = ' ';
							} else {
								aaa = params.name;
							}
							return aaa;
						}
						// width: '100',
						// overflow: 'truncate',
						// ellipsis: '...'
					}
				}
			},
			options6_1: {
				tooltip: {
					formatter: info => {
						const { value, treePathInfo } = info;
						const treePath = [];
						for (let i = 1; i < treePathInfo.length; i++) {
							treePath.push(treePathInfo[i].name);
						}
						return [
							`<div class="tooltip-title">${echarts.format.encodeHTML(
								treePath.join('/')
							)}</div>Disk Usage: ${echarts.format.addCommas(
								value
							)} KB`
						].join('');
					}
				},
				series: [
					{
						name: '设备分类价值',
						type: 'treemap',
						visibleMin: 300,
						label: {
							show: true,
							formatter: '{b}'
						},
						upperLabel: {
							show: true,
							height: 30
						},
						itemStyle: {
							borderColor: '#fff'
						},
						levels: getLevelOption(),
						data: []
					}
				]
			},
			options6_2: {
				tooltip: {
					trigger: 'item',
					triggerOn: 'mousemove'
				},
				series: [
					{
						type: 'tree',
						data: [],
						top: '1%',
						left: '7%',
						bottom: '1%',
						right: '20%',
						symbolSize: 7,
						label: {
							position: 'left',
							verticalAlign: 'middle',
							align: 'right',
							fontSize: 9
						},
						leaves: {
							label: {
								position: 'right',
								verticalAlign: 'middle',
								align: 'left'
							}
						},
						emphasis: {
							focus: 'descendant'
						},
						expandAndCollapse: true,
						animationDuration: 550,
						animationDurationUpdate: 750
					}
				]
			},
			options7: {
				// title: {
				// 	text: '设备使用年限（数量）'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}年: ${arg.value}台`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options8: {
				// title: {
				// 	text: '设备使用年限（价值）'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}年: ${arg.value}元`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options9: {
				// title: {
				// 	text: '设备使用年限（折旧价值）'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options10: {
				title: {
					// text: '设备使用年限'
				},
				// color: ['#006069', '#039CCD', '#a9df97'],
				grid: {
					right: '18%'
				},
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgba(50,50,50,0.7)',
					borderColor: 'rgba(50,50,50,0.7)',
					textStyle: {
						color: 'rgba(255,255,255)'
					},
					axisPointer: {
						type: 'shadow'
					}
				},
				xAxis: {
					type: 'category',
					data: [],
					axisTick: {
						show: false // 去除刻度线
					},
					axisLabel: {
						show: true, // 是否显示日期
						interval: 0, // 强制显示全部 // rotate: 40,//倾斜的角度
						textStyle: {
							color: '#41B8D3', // 日期的颜色
							fontSize: 12 // 字体的大小
						}
					},
					axisLine: {
						lineStyle: {
							color: '#15253D' // x轴的颜色
						}
					}
				},
				yAxis: [
					{
						type: 'value',
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#41B8D3' // 数字的颜色
							},
							inside: false // 控制数据是否在内侧还是外侧显示
						},
						splitLine: {
							show: true,
							lineStyle: {
								// color: '#15253D',
								width: 1,
								type: 'solid'
							}
						}
					},
					{
						type: 'value',
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: '#41B8D3' // 数字的颜色
							},
							inside: false // 控制数据是否在内侧还是外侧显示
						},
						splitLine: {
							show: true,
							lineStyle: {
								// color: '#15253D',
								width: 1,
								type: 'solid'
							}
						}
					}
				],
				legend: {
					right: '5%',
					textStyle: {
						// 图列内容样式
						// color: '#fff' // 字体颜色
						// backgroundColor: "black", // 字体背景色
					}
				},
				series: [
					{
						name: '原价',
						type: 'bar',
						data: [23, 33, 5, 56, 8, 45]
					},
					{
						name: '折旧现值',
						type: 'bar',
						data: [23, 33, 5, 56, 8, 45]
					},
					{
						name: '数量',
						type: 'line',
						// smooth: true,
						yAxisIndex: '1',
						data: [23, 33, 5, 6, 78, 56]
					}
				]
			},
			isShowBigEcharts: false,
			tempData: null,
			myChart: null,
			echartsIndex: 0,
			echartsIndex1: 0
		};
	},
	mounted() {
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.date = [
			`${curYear - 10}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];
		this.allHospitalDeviceList();
		this.allHospitalRepairList();
		this.allHospitalDevicePriceAfterzjList();
		this.allDepartmentDeviceList();
		this.devCntPriceByTypeList();
		this.allHospitalDeviceUsedYearRange();
	},
	methods: {
		// 旭日图
		xuriHandler() {
			this.echartsIndex = 0;
		},
		// 矩形图
		rectangleHandler() {
			this.echartsIndex = 1;
		},
		// 树状图
		treeHandler() {
			this.echartsIndex = 2;
		},
		// 旭日图
		xuriHandler1() {
			this.echartsIndex1 = 0;
		},
		// 矩形图
		rectangleHandler1() {
			this.echartsIndex1 = 1;
		},
		// 树状图
		treeHandler1() {
			this.echartsIndex1 = 2;
		},
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '全院设备数量(台)';
					this.myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '全院设备金额(元)';
					this.myChart.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '全院价值区间统计';
					this.myChart.setOption(this.options3);
				}
				if (index === 31) {
					this.dialogTitle = '全院价值区间统计（折旧后）';
					this.myChart.setOption(this.options31);
				}
				if (index === 4) {
					this.dialogTitle = '科室统计台数和价格';
					this.myChart.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '设备分类数量';
					if (this.echartsIndex === 0) {
						delete this.options5.series.label.formatter;
						this.options5.series.radius = [0, '90%'];
						const plist = [];
						this.tempData.forEach(item => {
							let isHas = false;
							item.value = item.cnt;
							plist.forEach($item => {
								if (item.pid === $item.id) {
									$item.value += item.cnt;
									isHas = true;
									$item.children.push(item);
								}
							});
							if (!isHas) {
								plist.push({
									id: item.pid,
									value: item.cnt,
									name: item.pname,
									children: [item]
								});
							}
						});
						this.options5.series.data = plist;
						this.options5.tooltip = {
							formatter: info => {
								const { value, treePathInfo } = info;
								const treePath = [];
								for (let i = 1; i < treePathInfo.length; i++) {
									treePath.push(treePathInfo[i].name);
								}
								return [
									`<div class='tooltip-title' > ${treePath.join(
										'/'
									)} </div>`,
									`数量: ${value} 个`
								].join('');
							}
						};
						this.myChart.setOption(this.options5);
					}
					if (this.echartsIndex === 1) {
						this.myChart.setOption(this.options5_1);
					}
					if (this.echartsIndex === 2) {
						this.myChart.setOption(this.options5_2);
					}
				}
				if (index === 6) {
					this.dialogTitle = '设备分类价值';
					if (this.echartsIndex1 === 0) {
						delete this.options6.series.label.formatter;
						this.options6.series.radius = [0, '90%'];
						const plist = [];
						this.tempData.forEach(item => {
							let isHas = false;
							item.value = item.price;
							plist.forEach($item => {
								if (item.pid === $item.id) {
									$item.value += item.price;
									isHas = true;
									$item.children.push(item);
								}
							});
							if (!isHas) {
								plist.push({
									id: item.pid,
									value: item.price,
									name: item.pname,
									children: [item]
								});
							}
						});
						this.options6.series.data = plist;
						this.myChart.setOption(this.options6);
					}
					if (this.echartsIndex1 === 1) {
						this.myChart.setOption(this.options6_1);
					}
					if (this.echartsIndex1 === 2) {
						this.myChart.setOption(this.options6_2);
					}
				}
				if (index === 7) {
					this.dialogTitle = '设备使用年限（数量）';
					this.myChart.setOption(this.options7);
				}
				if (index === 8) {
					this.dialogTitle = '设备使用年限（价值）';
					this.myChart.setOption(this.options8);
				}
				if (index === 9) {
					this.dialogTitle = '设备使用年限（折旧价值）';
					this.myChart.setOption(this.options9);
				}
				if (index === 10) {
					this.dialogTitle = '设备使用年限';
					this.myChart.setOption(this.options10);
				}
			}, 500);
		},
		searchSubmit() {
			this.allHospitalDeviceList();
			this.allHospitalRepairList();
			this.allHospitalDevicePriceAfterzjList();
		},
		async allDepartmentDeviceList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allDepartmentDeviceNumberService(dataJson);
			this.tableDownDataDeviceCntPriceByDepartment = res;
			this.echartsInit4(res);
		},
		async allHospitalDeviceList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDeviceNumberService(dataJson);
			this.tableDownDataBuyDevices = res;
			this.echartsInit1(res);
			this.echartsInit2(res);
		},
		async allHospitalRepairList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDevicePriceService(dataJson);
			this.tableDownDataDevicePriceRange = res;
			this.echartsInit3(res);
		},
		async allHospitalDevicePriceAfterzjList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDevicePriceAfterzjService(dataJson);
			this.tableDownDataDevicePriceRangeAfterzj = res;
			this.echartsInit31(res);
		},
		async devCntPriceByTypeList() {
			const dataJson = {
				departid: 0,
				hosid: hgetStorage('organization')
			};
			const res = await devCntPriceByTypeService(dataJson);
			this.tableDownDataDevCntPriceByType = res;
			this.tempData = res;
			const tempData = res;
			this.echartsInit5(res);
			this.echartsInit5_1(res);
			this.echartsInit5_2(tempData);
			this.echartsInit6(res);
			this.echartsInit6_1(res);
			this.echartsInit6_2(res);
		},
		async allHospitalDeviceUsedYearRange() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDeviceUsedYearRangeService(dataJson);
			this.tableDownDataDeviceUsedYearRange = res;
			this.echartsInit7(res);
			this.echartsInit8(res);
			this.echartsInit9(res);
			this.echartsInit10(res);
		},
		echartsInit1(res) {
			const buyYearArr = [];
			const buycntArr = [];
			const hascntArr = [];
			res.forEach(item => {
				buyYearArr.push(item.buyyear);
				buycntArr.push(item.buycnt);
				hascntArr.push(item.hascnt);
			});
			this.options1.xAxis.data = buyYearArr;

			this.options1.series[0].data = hascntArr;
			this.options1.series[1].data = buycntArr;
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			const buyYearArr = [];
			const buypriceArr = [];
			const afterzjArr = [];
			const haspriceArr = [];
			res.forEach(item => {
				buyYearArr.push(item.buyyear);
				buypriceArr.push(item.buyprice);
				haspriceArr.push(item.hasprice);
				afterzjArr.push(item.afterzj.toFixed(2));
			});
			this.options2.xAxis.data = buyYearArr;
			this.options2.series[0].data = haspriceArr;
			this.options2.series[1].data = afterzjArr;
			this.options2.series[2].data = buypriceArr;
			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			this.options3.series[0].data = [];
			res.forEach(item => {
				this.options3.series[0].data.push({
					value: item.cnt,
					name: item.ranges
				});
			});
			const chartDom = document.getElementById('echarts3');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit31(res) {
			this.options31.series[0].data = [];
			res.forEach(item => {
				this.options31.series[0].data.push({
					value: item.cnt,
					name: item.ranges
				});
			});
			const chartDom = document.getElementById('echarts31');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options31);
		},
		echartsInit4(res) {
			const departmentArr = [];
			const cntArr = [];
			const priceArr = [];
			const afterzjArr = [];
			res.forEach(item => {
				departmentArr.push(item.department);
				cntArr.push(item.cnt);
				priceArr.push(item.price);
				afterzjArr.push(item.afterzj.toFixed(2));
			});
			this.options4.xAxis.data = departmentArr;
			this.options4.series[0].data = cntArr;
			this.options4.series[1].data = priceArr;
			this.options4.series[2].data = afterzjArr;
			const firstItem = departmentArr[0];
			const endItem = departmentArr[6]
				? departmentArr[6]
				: departmentArr[departmentArr.length - 1];
			this.options4.dataZoom[0].startValue = firstItem;
			this.options4.dataZoom[0].endValue = endItem;
			// 获取数据渲染
			const chartDom = document.getElementById('echarts4');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.cnt;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.cnt;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.cnt,
						name: item.pname,
						children: [item]
					});
				}
			});
			this.options5.series.data = plist;
			this.options5.series.radius = [0, '50%'];
			this.options5.tooltip = {
				formatter: info => {
					const { value, treePathInfo } = info;
					const treePath = [];
					for (let i = 1; i < treePathInfo.length; i++) {
						treePath.push(treePathInfo[i].name);
					}
					return [
						`<div class='tooltip-title' > ${treePath.join(
							'/'
						)} </div>`,
						`数量: ${value} 个`
					].join('');
				}
			};
			const chartDom = document.getElementById('echarts5');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit5_1(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.cnt;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.cnt;
						$item.path = $item.name;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.cnt,
						name: item.pname,
						path: item.pname,
						children: [item]
					});
				}
			});

			this.options5_1.series[0].data = plist;
			const chartDom = document.getElementById('echarts5_1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5_1);
		},
		echartsInit5_2(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.cnt;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.cnt;
						isHas = true;
						$item.children.push({ ...item, value: item.cnt });
					}
				});
				if (!isHas) {
					item.value = item.cnt;
					plist.push({
						id: item.pid,
						value: item.cnt,
						name: item.pname,
						children: [{ ...item, value: item.cnt }]
					});
				}
			});
			const data = {
				name: '设备分类',
				children: plist
			};
			data.children.forEach((datum, index) => {
				index % 2 === 0 && (datum.collapsed = true);
			});
			this.options5_2.series[0].data = [data];
			const chartDom = document.getElementById('echarts5_2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5_2);
		},
		echartsInit6(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.price;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.price;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.price,
						name: item.pname,
						children: [item]
					});
				}
			});
			this.options6.series.data = plist;
			this.options6.series.radius = [0, '50%'];
			this.options6.tooltip = {
				formatter: info => {
					const { value, treePathInfo } = info;
					const treePath = [];
					for (let i = 1; i < treePathInfo.length; i++) {
						treePath.push(treePathInfo[i].name);
					}
					return [
						`<div class='tooltip-title' > ${treePath.join(
							'/'
						)} </div>`,
						`价值: ${value} ￥`
					].join('');
				}
			};
			const chartDom = document.getElementById('echarts6');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		echartsInit6_1(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.price;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.price;
						$item.path = $item.name;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.price,
						name: item.pname,
						path: item.pname,
						children: [item]
					});
				}
			});
			this.options6_1.series[0].data = plist;
			const chartDom = document.getElementById('echarts6_1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6_1);
		},
		echartsInit6_2(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.price;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.price;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.price,
						name: item.pname,
						children: [item]
					});
				}
			});
			const data = {
				name: '设备分类价值',
				children: plist
			};
			data.children.forEach((datum, index) => {
				index % 2 === 0 && (datum.collapsed = true);
			});
			this.options6_2.series[0].data = [data];
			const chartDom = document.getElementById('echarts6_2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6_2);
		},
		echartsInit7(res) {
			this.options7.series[0].data = [];
			res.forEach(item => {
				this.options7.series[0].data.push({
					value: item.cnt,
					name: item.usedyear
				});
			});
			const chartDom = document.getElementById('echarts7');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		echartsInit8(res) {
			this.options8.series[0].data = [];
			res.forEach(item => {
				this.options8.series[0].data.push({
					value: item.price,
					name: item.usedyear
				});
			});
			const chartDom = document.getElementById('echarts8');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
		},
		echartsInit9(res) {
			this.options9.series[0].data = [];
			res.forEach(item => {
				this.options9.series[0].data.push({
					value: item.afterzj.toFixed(2),
					name: item.usedyear
				});
			});
			const chartDom = document.getElementById('echarts9');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options9);
		},
		echartsInit10(res) {
			const usedyearArr = [];
			const cntArr = [];
			const priceArr = [];
			const afterzjArr = [];
			res.forEach(item => {
				usedyearArr.push(item.usedyear);
				cntArr.push(item.cnt);
				priceArr.push(item.price);
				afterzjArr.push(item.afterzj.toFixed(2));
			});
			this.options10.xAxis.data = [...usedyearArr];
			this.options10.series[0].data = priceArr;
			this.options10.series[1].data = afterzjArr;
			this.options10.series[2].data = cntArr;
			// const firstItem = usedyearArr[0];
			// const endItem = usedyearArr[6] ? usedyearArr[6] : usedyearArr[usedyearArr.length - 1];
			// this.options10.dataZoom[0].startValue = firstItem;
			// this.options10.dataZoom[0].endValue = endItem;
			// 获取数据渲染
			const chartDom = document.getElementById('echarts10');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options10);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		downloadExcelBuyDevices(obj) {
			const tHeader = [
				'日期',
				'累计数量',
				'购买数量',
				'累计金额',
				'折旧金额',
				'购买金额'
			];
			const filterVal = [
				'buyyear',
				'hascnt',
				'buycnt',
				'hasprice',
				'afterzj',
				'buyprice'
			];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataBuyDevices
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `全院设备数量、价格${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelDevicePriceRange(obj) {
			const tHeader = ['价格区间', '数量'];
			const filterVal = ['ranges', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevicePriceRange
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `全院价值区间统计${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelDevicePriceRangeAfterzj(obj) {
			const tHeader = ['价格区间', '数量'];
			const filterVal = ['ranges', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevicePriceRangeAfterzj
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `全院价值区间统计（折旧后）${htimeFormat(
						new Date()
					)}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelDeviceCntPriceByDepartment(obj) {
			const tHeader = ['部门', '在用台数', '价格', '折旧现值'];
			const filterVal = ['department', 'cnt', 'price', 'afterzj'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDeviceCntPriceByDepartment
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `科室统计台数和价格${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelDeviceUsedYearRange(obj) {
			const tHeader = ['年限', '原价', '折旧现值', '数量'];
			const filterVal = ['usedyear', 'price', 'afterzj', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDeviceUsedYearRange
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备使用年限${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelDevCntPriceByType(obj) {
			const tHeader = ['一级', '二级', '数量', '价值'];
			const filterVal = ['pname', 'name', 'cnt', 'price'];
			this.tableDownDataDevCntPriceByType.forEach(item => {
				if (item.pname) {
					item.asc = parseInt(item.name, 10);
				} else {
					item.asc = 999;
				}
			});
			this.tableDownDataDevCntPriceByType.sort((a, b) => a.asc - b.asc);

			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevCntPriceByType
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备分类数量、价值${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style lang="less" scoped>
.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 460px;
	// width:100% !important;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	// width:100% !important;
	height: 360px;
}

.search_button {
	margin-left: 10px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
