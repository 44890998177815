<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" size="mini" :model="formData" label-width="80px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="开始时间">
				<el-date-picker v-model="formData.begin" type="datetime" placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			<el-form-item label="结束时间">
				<el-date-picker v-model="formData.end" type="datetime" placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			<el-form-item label="源库房">
				<el-select v-model="formData.fromWarehouseId" clearable placeholder="请选择">
					<el-option label="请选择" value=""></el-option>
					<el-option v-for="(item, index) in warehouseData" :label="item.name" :value="item.id" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="目标库房">
				<el-select v-model="formData.toWarehouseId" clearable placeholder="请选择">
					<el-option label="请选择" value=""></el-option>
					<el-option v-for="(item, index) in warehouseData" :label="item.name" :value="item.id" :key="index"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				begin: '',
				end: '',
				fromWarehouseId: '',
				toWarehouseId: ''
			},
            warehouseData: []
		};
	},
	created() {
		this.warehouseList();
	},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.formData);
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
            this.warehouseData = res;
		}
	}
};
</script>

<style lang="less" scoped>
</style>
