<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="repairSearchForm"
			:model="repairSearchForm"
			label-width="80px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="报修时间">
				<el-date-picker
					v-model="date"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width: 333px"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="完成时间">
				<el-date-picker
					v-model="finishDate"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width: 333px"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="签单时间">
				<el-date-picker
					v-model="signDate"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width: 333px"
				></el-date-picker>
			</el-form-item>

			<!-- 医院 organizationType == 1
			三方 organizationType == 2 -->
			<template v-if="organizationType == 1">
				<el-form-item label="科室" prop="name">
					<treeselect
						v-model="repairSearchForm.did"
						:multiple="false"
						:options="optionsDept"
						style="width:220px;"
						:disable-branch-nodes="false"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
					/>
				</el-form-item>
				<el-form-item label="状态" prop="type">
					<el-select
						v-model="repairSearchForm.type"
						placeholder="请选择"
						filterable
						clearable
					>
						<el-option
							v-for="item in typeListCompany"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>

				<template v-if="searchHandlerBigFlag">
					<el-form-item label="报修人">
						<el-select
							v-model="repairSearchForm.repairUserIds"
							multiple
							placeholder="请选择"
							class="select-item"
							clearable
							filterable
						>
							<el-option
								v-for="item in repairuserlist0"
								:key="item.id"
								:label="item.nickName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="接单人">
						<el-select
							v-model="repairSearchForm.enginerrIds"
							multiple
							placeholder="请选择"
							class="select-item"
							clearable
							filterable
						>
							<el-option
								v-for="item in repairuserlist1"
								:key="item.id"
								:label="item.nickName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="完成人">
						<el-select
							v-model="repairSearchForm.completeIds"
							multiple
							placeholder="请选择"
							class="select-item"
							clearable
							filterable
						>
							<el-option
								v-for="item in repairuserlist2"
								:key="item.id"
								:label="item.nickName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="应收价格范围" style="width:4rem" label-width="150px">
						<div style="display:flex;width:100%;">
							<el-input
								v-model="repairSearchForm.priceBegin"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
							<span style="width:30px; text-align:center;">-</span>
							<el-input
								v-model="repairSearchForm.priceEnd"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
						</div>
					</el-form-item>
					<el-form-item label="实收价格范围" style="width:4rem" label-width="150px">
						<div style="display:flex;width:100%;">
							<el-input
								v-model="repairSearchForm.realpriceBegin"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
							<span style="width:30px; text-align:center;">-</span>
							<el-input
								v-model="repairSearchForm.realpriceEnd"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
						</div>
					</el-form-item>

					<el-form-item label="是否签单" label-width="110px">
						<el-select
							v-model="repairSearchForm.isSign"
							placeholder="是否签单"
							class="select-item"
							clearable
							filterable
						>
							<el-option
								:label="item.name"
								:value="item.id"
								v-for="item in isSignData"
								:key="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备名称" prop="devName ">
						<el-input v-model="repairSearchForm.devName" placeholder="请输入设备名称" />
					</el-form-item>
					<el-form-item label="纸质工单号" label-width="100px">
						<el-input
							v-model="repairSearchForm.paperNO"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
				</template>
			</template>
			<template v-if="organizationType == 2">
				<!-- <el-form-item label="签单时间">
					<el-date-picker v-model="signDate" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" style="width: 333px"></el-date-picker>
				</el-form-item> -->
				<el-form-item label="医院">
					<el-select
						v-model="repairSearchForm.hid"
						placeholder="请选择医院"
						clearable
						filterable
						style="width: 100%"
						@change="selectPoByOrgIdService"
					>
						<el-option
							v-for="item in optionsCompany"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="科室" v-if="repairSearchForm.hid">
					<treeselect
						v-model="repairSearchForm.did"
						:multiple="false"
						:options="optionsDept"
						style="width:220px;"
						:disable-branch-nodes="false"
						placeholder="请选择"
						noResultsText="暂无结果"
						:close-on-select="true"
						:show-count="true"
					/>

					<!-- <el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable
						@change="getHospitalId">
						<el-option :value="repairSearchForm.did" style="height: auto">
							<el-tree :data="optionsDept" node-key="id" ref="tree" highlight-current :props="defaultProps"
								@node-click="nodeClickHandler"></el-tree>
						</el-option>
					</el-select> -->
				</el-form-item>
				<el-form-item label="负责人">
					<el-select
						v-model="repairSearchForm.engineers"
						multiple
						placeholder="请选择"
						class="select-item"
						clearable
						filterable
					>
						<el-option
							v-for="item in inspectionUserData"
							:key="item.id"
							:label="item.nickName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<template v-if="searchHandlerBigFlag">
					<el-form-item label="状态" prop="type">
						<el-select
							v-model="repairSearchForm.states"
							placeholder="请选择"
							filterable
							clearable
						>
							<el-option
								v-for="item in typeListCompany"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="维修费用范围" style="width:4rem" label-width="150px">
						<div style="display:flex;width:100%;">
							<el-input
								v-model="repairSearchForm.repairpricebegin"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
							<span style="width:30px; text-align:center;">-</span>
							<el-input
								v-model="repairSearchForm.repairpriceend"
								placeholder="请输入"
								clearable
								type="number"
							></el-input>
						</div>
					</el-form-item>
					<el-form-item label="维修关键字" label-width="150px">
						<el-input
							v-model="repairSearchForm.keyword"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
					<el-form-item label="设备名称">
						<el-input
							v-model="repairSearchForm.devname"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
					<el-form-item label="设备类型" prop="name">
						<treeselect
							v-model="repairSearchForm.devtypes"
							:multiple="true"
							:options="deviceTypeData"
							style="width:300px;"
							:disable-branch-nodes="false"
							:normalizer="normalizer"
							placeholder="请选择"
							noResultsText="暂无结果"
							:close-on-select="true"
							:show-count="true"
						/>
					</el-form-item>
					<el-form-item label="厂家">
						<el-input
							v-model="repairSearchForm.devfac"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
					<el-form-item label="设备型号">
						<el-input
							v-model="repairSearchForm.devxinghao"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
					<el-form-item label="纸质工单号" label-width="100px">
						<el-input
							v-model="repairSearchForm.paperNO"
							placeholder="请输入"
							clearable
						></el-input>
					</el-form-item>
				</template>
				<span style="padding-left: 10px; margin-top: 4px">
					<el-checkbox v-model="repairSearchForm.includeprice">是否包含费用</el-checkbox>
				</span>
			</template>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>

		<template>
			<el-button
				class="filter-item"
				size="mini"
				type="primary"
				icon="el-icon-search"
				@click="searchHandlerBig"
			>
				{{ searchHandlerBigFlag ? '普通搜索' : '高级搜索' }}
			</el-button>
		</template>
		<el-button
			class="filter-item"
			size="mini"
			type="default"
			@click="resetForm('devBaseSearchForm')"
		>
			重置
		</el-button>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { gethosrepairuserlistService } from '@s/repair/RepairService';
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage, htimeFormat, hdateInMonth } from '@/util/htools.web';
// 选择医院 下拉
import {
	getcompanysignListService,
	getHospitalByUserIdListService
} from '@s/company/contract/ContractService';
// 选择科室 下拉
import {
	departmentListDefaultService,
	selectPoByOrgIdService
} from '@s/hospital/department/DepartmentService';
// import SelectTree from '@c/ui/SelectTree';
import Treeselect from '@riophae/vue-treeselect';
import { userListService } from '@s/system/UserService';
import { devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';

export default {
	props: ['dateCharts', 'dateType', 'deptId', 'lbegin', 'lend'],
	components: {
		// SelectTree,
		Treeselect
	},
	data() {
		return {
			// 状态搜索 公司
			typeListCompany: [
				{
					value: '0',
					label: '未开始'
				},
				{
					value: '1,2,3',
					label: '开始'
				},
				{
					value: '4,5',
					label: '结束'
				}
			],
			// 报修人
			repairuserlist0: [],
			// 接单人
			repairuserlist1: [],
			// 完成人
			repairuserlist2: [],
			// 是否签单 下拉搜索
			isSignData: [
				{ id: null, name: '全部' },
				{ id: true, name: '已签单' },
				{ id: false, name: '未签单' }
			],
			// 设备类型
			deviceTypeData: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			},
			// 状态搜索
			typeList: [
				{
					value: '0',
					label: '待接单'
				},
				{
					value: '1',
					label: '已响应'
				},
				{
					value: '2',
					label: '已到场'
				},
				{
					value: '3',
					label: '维修中'
				},
				{
					value: '4',
					label: '待评价'
				},
				{
					value: '5',
					label: '已完成'
				}
			],
			date: null,
			finishDate: null,
			signDate: null,
			repairSearchForm: {
				hid: null,
				hidsTemp: [0],
				did: null,
				begin: '',
				end: '',
				doneBegin: '',
				doneEnd: '',
				type: [],
				// 是否包含费用
				includeprice: true
			},
			searchHandlerBigFlag: false,
			currentUserOrganization: '',
			contractData: [],
			optionsCompany: [],
			optionsDept: [],
			// 三方人员下拉，多选
			inspectionUserData: [],
			// 科室
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			options: [
				{
					id: 217,
					label: 'a设备科',
					parentId: 0,
					dutyPeople: null,
					dutyPeopleName: null,
					orgId: 1123,
					createUserId: 10319,
					createDate: '2021-11-05T11:26:29.29',
					updateUserId: null,
					updateDate: null,
					isDel: 0,
					inspectPeriod: null,
					nextInspectTime: null,
					inspectRemindDays: 0,
					upkeepPeriod: null,
					nextUpkeepTime: null,
					upkeepRemindDays: 0,
					children: [
						{
							id: '111',
							label: 'aa'
						},
						{
							id: 'ab',
							label: '222'
						}
					]
				},
				{
					id: 216,
					label: 'A综检科',
					parentId: 0,
					dutyPeople: null,
					dutyPeopleName: null,
					orgId: 1123,
					createUserId: 10319,
					createDate: '2021-10-19T08:39:56.647',
					updateUserId: null,
					updateDate: null,
					isDel: 0,
					inspectPeriod: null,
					nextInspectTime: null,
					inspectRemindDays: 0,
					upkeepPeriod: null,
					nextUpkeepTime: null,
					upkeepRemindDays: 0
				}
			],
			organizationType: null
		};
	},
	async created() {
		if (this.dateType === '0') {
			// 年
			const dateChartsArr = this.dateCharts.split('-');
			const yearIndex = dateChartsArr[1];
			this.date = [`${yearIndex}-01-01 00:00:00`, `${yearIndex}-12-31 23:59:59`];
			this.finishDate = [`${yearIndex}-01-01 00:00:00`, `${yearIndex}-12-31 23:59:59`];
		}
		if (this.dateType === '1') {
			// 季
			const dateChartsArr = this.dateCharts.split('-');
			const jdIndex = dateChartsArr[1];
			const jdMonthArr = this.jiduMonth(jdIndex);
			const allDate = hdateInMonth(`${dateChartsArr[0]}-${jdMonthArr[1]}`);
			this.date = [
				`${dateChartsArr[0]}-${jdMonthArr[0]}-01 00:00:00`,
				`${dateChartsArr[0]}-${jdMonthArr[1]}-${allDate} 23:59:59`
			];
			this.finishDate = [
				`${dateChartsArr[0]}-${jdMonthArr[0]}-01 00:00:00`,
				`${dateChartsArr[0]}-${jdMonthArr[1]}-${allDate} 23:59:59`
			];
		}
		if (this.dateType === '2') {
			// 月
			const allDate = hdateInMonth(this.dateCharts);
			this.date = [
				`${this.dateCharts}-01 00:00:00`,
				`${this.dateCharts}-${allDate} 23:59:59`
			];
			this.finishDate = [
				`${this.dateCharts}-01 00:00:00`,
				`${this.dateCharts}-${allDate} 23:59:59`
			];
			console.log(this.date);
		}
		if (this.dateType === '3') {
			this.date = [`${this.lbegin}`, `${this.lend}`];
		}
		this.currentUserOrganization = hgetStorage('organization');

		this.organizationType = hgetStorage('organizationType');
		if (this.organizationType == 2) {
			await this.getCompanyList();
			this.inspectionUserList();
			this.deviceTypeList();
		}
		if (this.organizationType == 1) {
			this.repairSearchForm.hid = this.currentUserOrganization;
			this.departmentList();

			this.gethosrepairuserlist0();
			this.gethosrepairuserlist1();
			this.gethosrepairuserlist2();
		}

		setTimeout(() => {
			if (this.deptId) {
				this.repairSearchForm.did = Number(this.deptId);
			}
			this.searchHandler();
		}, 400);
	},
	methods: {
		initSearchForm() {
			this.repairSearchForm = {
				hid: null,
				did: null,
				begin: '',
				end: '',
				doneBegin: '',
				doneEnd: '',
				signbegin: '',
				signend: '',
				type: null,
				// 是否包含费用
				includeprice: true
			};
		},
		// 报修人
		async gethosrepairuserlist0() {
			const res = await gethosrepairuserlistService({ type: 0 });
			this.repairuserlist0 = res || [];
		},
		// 接单人
		async gethosrepairuserlist1() {
			const res = await gethosrepairuserlistService({ type: 1 });
			this.repairuserlist1 = res || [];
		},
		// 完成人
		async gethosrepairuserlist2() {
			const res = await gethosrepairuserlistService({ type: 2 });
			this.repairuserlist2 = res || [];
		},
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 设备类型
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
		},
		async inspectionUserList() {
			const dataJson = {
				hospitalId: '',
				pageNum: 1,
				pageSize: 10000
			};
			// const res = await inspectionUserListService(dataJson);
			const res = await userListService(dataJson);
			console.log(res);
			this.inspectionUserData = [...res.records];
		},
		jiduMonth(index) {
			return [3 * (index - 1) + 1, 3 * index];
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},

		//  * 医院 维修公司 下拉
		async getCompanyList() {
			// 是否是三方管理员
			const isAdmin = hgetStorage('roleIds').includes(2);
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			// const res = await getcompanysignListService(dataJson);
			if (isAdmin) {
				const res = await getcompanysignListService(dataJson);
				this.optionsCompany = [...res.records];
			} else {
				const res = await getHospitalByUserIdListService(dataJson);
				this.optionsCompany = [...res];
			}
			if (this.optionsCompany.length > 0) {
				this.optionsCompany.hidsTemp = [];
			}
			this.optionsCompany.forEach(item => {
				this.repairSearchForm.hidsTemp.push(item.id);
			});
			// this.optionsCompany = res.records;
		},

		async inspectionDeptList() {
			const json = {
				orgId: this.repairSearchForm.hid
			};
			const res = await selectPoByOrgIdService(json);

			const r = this.addTreeKey(res, 0);
			// 获取部门
			this.optionsDept = r;
		},
		async selectPoByOrgIdService(val) {
			this.repairSearchForm.deptId = null;
			this.parentName = '';
			this.optionsDept = [];
			// this.inspectionUserData = [];
			if (val) {
				// 获取部门
				this.inspectionDeptList();
			}
		},

		getHospitalId(val) {
			console.log('val');
			if (!val) {
				this.repairSearchForm.deptId = null;
			}
		},
		// 科室问题
		nodeClickHandler(data) {
			this.repairSearchForm.deptId = data.id;
			this.parentName = data.department;
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
			console.log(this.optionsDept);
		},
		// async contractList() {
		// 	const dataJson = {
		// 		pageNum: 1,
		// 		pageSize: 100000
		// 	};
		// 	const res = await getcompanysignListService(dataJson);
		// 	this.contractData = [...res.records];
		// },

		resetForm(formName) {
			this.date = null;
			this.finishDate = null;
			this.signDate = null;
			this.initSearchForm();
			// this.$refs[formName].resetFields();
			this.searchHandler();
			// this.$emit('searchForm', this.devBaseSearchForm);
		},
		searchHandler() {
			this.repairSearchForm.begin = this.date
				? `${htimeFormat(this.date[0], '{y}-{m}-{d}')} 00:00:00`
				: '';
			this.repairSearchForm.end = this.date
				? `${htimeFormat(this.date[1], '{y}-{m}-{d}')} 23:59:59`
				: '';
			this.repairSearchForm.doneBegin = this.finishDate
				? `${htimeFormat(this.finishDate[0], '{y}-{m}-{d}')} 00:00:00`
				: '';
			this.repairSearchForm.doneEnd = this.finishDate
				? `${htimeFormat(this.finishDate[1], '{y}-{m}-{d}')} 23:59:59`
				: '';
			this.repairSearchForm.signbegin = this.signDate
				? `${htimeFormat(this.signDate[0], '{y}-{m}-{d}')} 00:00:00`
				: '';
			this.repairSearchForm.signend = this.signDate
				? `${htimeFormat(this.signDate[1], '{y}-{m}-{d}')} 23:59:59`
				: '';

			const json = {};

			if (this.organizationType == 1) {
				json.begin = this.repairSearchForm.begin;
				json.end = this.repairSearchForm.end;
				json.doneBegin = this.repairSearchForm.doneBegin;
				json.doneEnd = this.repairSearchForm.doneEnd;
				json.signbegin = this.repairSearchForm.signbegin;
				json.signend = this.repairSearchForm.signend;
				json.signBegin = this.repairSearchForm.signbegin;
				json.signEnd = this.repairSearchForm.signend;
				// json.type = this.repairSearchForm.type;
				json.type =
					this.repairSearchForm.type?.length > 0
						? this.repairSearchForm.type?.split(',')
						: null;
				json.begin = this.repairSearchForm.begin;
				// json.includeprice = this.repairSearchForm.includeprice;
				json.did = this.repairSearchForm.did;
				json.searchType = 'hospital';
				json.priceBegin = this.repairSearchForm.priceBegin;
				json.priceEnd = this.repairSearchForm.priceEnd;
				json.realpriceBegin = this.repairSearchForm.realpriceBegin;
				json.realpriceEnd = this.repairSearchForm.realpriceEnd;
				json.isSign = this.repairSearchForm.isSign;
				json.devName = this.repairSearchForm.devName;
				json.repairUserIds = this.repairSearchForm.repairUserIds || null;
				json.enginerrIds = this.repairSearchForm.enginerrIds || null;
				json.completeIds = this.repairSearchForm.completeIds || null;
			} else if (this.organizationType == 2) {
				json.hosids = this.repairSearchForm.hid ? [this.repairSearchForm.hid] : [];
				json.hosdeparts = this.repairSearchForm.did ? [this.repairSearchForm.did] : [];
				json.engineers = this.repairSearchForm.engineers;
				json.states =
					this.repairSearchForm.states?.length > 0
						? this.repairSearchForm.states?.split(',')
						: null;
				json.repairBegin = this.repairSearchForm.begin;
				json.repairEnd = this.repairSearchForm.end;
				json.doneBegin = this.repairSearchForm.doneBegin;
				json.doneEnd = this.repairSearchForm.doneEnd;
				json.signbegin = this.repairSearchForm.signbegin;
				json.signend = this.repairSearchForm.signend;
				json.includePrice = this.repairSearchForm.includeprice;
				json.priceBegin = this.repairSearchForm.repairpricebegin;
				json.priceEnd = this.repairSearchForm.repairpriceend;
				json.keyword = this.repairSearchForm.keyword;
				json.devName = this.repairSearchForm.devname;
				json.devTypes = this.repairSearchForm.devtypes;
				json.devFac = this.repairSearchForm.devfac;
				json.devXinghao = this.repairSearchForm.devxinghao;
				json.searchType = 'company';
			} else {
				console.log('_json');
				console.log(this.repairSearchForm);
			}

			console.log('_json1');
			console.log(json);
			this.repairSearchForm = { ...this.repairSearchForm, ...json };
			this.$emit('searchForm', this.repairSearchForm);
			// this.$emit('searchForm', json);/
		},
		searchHandlerBig() {
			this.searchHandlerBigFlag = !this.searchHandlerBigFlag;
		}
	}
};
</script>

<style scoped>
.form {
	display: flex;
	flex-wrap: wrap;
}
</style>
