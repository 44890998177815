import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 获取统计数
 *@ClassAuthor: myw
 *@Date: 2021-05-31 17:25:50
 */
export const contractCountService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				...data
			};
			const res = await hpostRequest('hbtyong_5/transfer/getEquipmentStaffNumber', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院详情
 *@ClassAuthor: myw
 *@Date: 2021-05-31 14:25:55
 */
export const ContractDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3/hospital/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司获取某医院工程师
 *@ClassAuthor: myw
 *@Date: 2021-05-31 14:26:05
 */
export const contractHospitalEngineerService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/getHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 给医院分配工程师
 *@ClassAuthor: myw
 *@Date: 2021-05-31 15:53:48
 */
export const contractAddEngineerService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				userId: [],
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/addHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除工程师
 *@ClassAuthor: myw
 *@Date: 2021-06-04 13:24:48
 */
export const contractDeleteEngineerService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3/contract/delHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default ContractDetailService;
