<template>
	<el-form
		ref="contractForm"
		:model="contractForm"
		:rules="contractRules"
		size="small"
		label-width="130px"
	>
		<el-form-item label="维修公司名称" prop="companyId">
			<!-- <el-input v-model="contractForm.companyId" placeholder="请选择维修公司名称" /> -->
			<el-select
				v-model="contractForm.companyId"
				placeholder="请选择维修公司名称"
				clearable
				filterable
				@change="getDes"
				style="width:100%"
			>
				<el-option v-for="item in optionsCompany" :key="item.id" :label="item.name" :value="item.id"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="公司电话" prop="phone">
			<el-input v-model="contractForm.phone" disabled />
		</el-form-item>
		<el-form-item label="联系人" prop="name">
			<el-input v-model="contractForm.name" disabled />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="contractFormCancel">取 消</el-button>
					<el-button type="primary" @click="contractFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { signAddService, organizationAllQueryListService } from '@s/hospital/contract/ContractService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			optionsCompany: [],
			editId: -1,
			contractForm: {
				signTime: 1
			},
			contractRules: {
				companyId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.contractForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {
		this.getCompanyList();
	},
	methods: {
		async getCompanyList() {
			const res = await organizationAllQueryListService({
				pageNum: 1,
				pageSize: 999
			});
			this.optionsCompany = res.records;
		},
		getDes(e) {
			console.log(e);
			this.optionsCompany.forEach(item => {
				if (item.id === e) {
					this.contractForm.phone = item.phone;
					this.contractForm.name = item.name;
				}
			});
		},
		contractFormSubmit() {
			this.$refs.contractForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await signAddService(this.contractForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.contractForm
						};
						await contractEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.contractFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		contractFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
