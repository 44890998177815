import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 参数列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:33
 */
export const parameterListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 参数添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:41
 */
export const parameterAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				parameterKey: '',
				parameterValue: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 参数编辑
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:48
 */
export const parameterEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				parameterKey: '',
				parameterValue: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 参数详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:57
 */
export const parameterDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				parameterId: '',
				...data
			};
			const res = await hgetRequest('hbase/parameter/parameterByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 参数删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:51:08
 */
export const parameterDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				parameterId: [],
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default parameterListService;
