<template>
  <div>
    <el-row :gutter="40">
      <el-col :span="24">
        <h2 style="margin-bottom:20px;">{{form.name}}</h2>
        <div v-for="row in list" :key="row.id" class="tr">
          <div v-for="item in row.items" :key="item.id" :class="'td default ' + (item.id == stockInId ? ' active' : ' sctockBox')" :style="'width:'+(1/row.items.length)*100+'% '">{{ item.name }}</div>
        </div>

      </el-col>
      <el-col :span="12" style="display:none;">
        <div class="clear width-100">
          <div v-for="item in leftStock[0]" :key="item.id" class="box boxA">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
          <div v-for="item in leftStock[1]" :key="item.id" class="box boxB">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
          <div v-for="item in leftStock[2]" :key="item.id" class="box boxC">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12" style="display:none;">
        <div class="clear width-100">
          <div v-for="item in rightStock[0]" :key="item.id" class="box boxA">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
          <div v-for="item in rightStock[1]" :key="item.id" class="box boxB">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
          <div v-for="item in rightStock[2]" :key="item.id" class="box boxC">
            <div :class="item.id == stockInId ? 'default active' : 'default sctockBox'">{{ item.name }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="text-align: right;margin-top:10px">
      <span class="dialog-footer">
        <el-button @click="dialogCancel">关 闭</el-button>
      </span>
    </el-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { positonGraphGetbykeyService } from '@s/spareParts/CurrentStockService';

export default {
	props: ['stockInId', 'positonGraphId'],
	data() {
		return {
			list: [],
			form: {},
			leftStock: [
				[
					{
						id: 398,
						name: 'A1'
					},
					{
						id: 399,
						name: 'A2'
					},
					{
						id: 400,
						name: 'A3'
					},
					{
						id: 401,
						name: 'A4'
					},
					{
						id: 402,
						name: 'A5'
					},
					{
						id: 403,
						name: 'A6'
					},
					{
						id: 404,
						name: 'A7'
					},
					{
						id: 405,
						name: 'A8'
					},
					{
						id: 406,
						name: 'A9'
					},
					{
						id: 407,
						name: 'A10'
					},
					{
						id: 408,
						name: 'A11'
					},
					{
						id: 409,
						name: 'A12'
					},
					{
						id: 410,
						name: 'A13'
					},
					{
						id: 411,
						name: 'A14'
					},
					{
						id: 412,
						name: 'B1'
					},
					{
						id: 413,
						name: 'B2'
					},
					{
						id: 414,
						name: 'B3'
					},
					{
						id: 415,
						name: 'B4'
					},
					{
						id: 416,
						name: 'B5'
					},
					{
						id: 417,
						name: 'B6'
					},
					{
						id: 418,
						name: 'B7'
					},
					{
						id: 419,
						name: 'B8'
					},
					{
						id: 420,
						name: 'B9'
					},
					{
						id: 421,
						name: 'B10'
					},
					{
						id: 422,
						name: 'B11'
					},
					{
						id: 423,
						name: 'B12'
					},
					{
						id: 424,
						name: 'B13'
					},
					{
						id: 425,
						name: 'B14'
					},
					{
						id: 426,
						name: 'C1'
					},
					{
						id: 427,
						name: 'C2'
					},
					{
						id: 428,
						name: 'C3'
					},
					{
						id: 429,
						name: 'C4'
					},
					{
						id: 430,
						name: 'C5'
					},
					{
						id: 431,
						name: 'C6'
					},
					{
						id: 432,
						name: 'C7'
					},
					{
						id: 433,
						name: 'C8'
					},
					{
						id: 434,
						name: 'C9'
					},
					{
						id: 435,
						name: 'C10'
					},
					{
						id: 436,
						name: 'C11'
					},
					{
						id: 437,
						name: 'C12'
					},
					{
						id: 438,
						name: 'C13'
					},
					{
						id: 439,
						name: 'C14'
					}
				],
				[
					{
						id: 440,
						name: 'D1'
					},
					{
						id: 441,
						name: 'D2'
					},
					{
						id: 442,
						name: 'D3'
					},
					{
						id: 443,
						name: 'D4'
					},
					{
						id: 444,
						name: 'D5'
					},
					{
						id: 445,
						name: 'D6'
					},
					{
						id: 446,
						name: 'D7'
					},
					{
						id: 447,
						name: 'D8'
					},
					{
						id: 448,
						name: 'D9'
					},
					{
						id: 449,
						name: 'E1'
					},
					{
						id: 450,
						name: 'E2'
					},
					{
						id: 451,
						name: 'E3'
					},
					{
						id: 452,
						name: 'E4'
					},
					{
						id: 453,
						name: 'E5'
					},
					{
						id: 454,
						name: 'E6'
					},
					{
						id: 455,
						name: 'E7'
					},
					{
						id: 456,
						name: 'E8'
					},
					{
						id: 457,
						name: 'E9'
					},
					{
						id: 458,
						name: 'F1'
					},
					{
						id: 459,
						name: 'F2'
					},
					{
						id: 460,
						name: 'F3'
					},
					{
						id: 461,
						name: 'F4'
					},
					{
						id: 462,
						name: 'F5'
					},
					{
						id: 463,
						name: 'F6'
					},
					{
						id: 464,
						name: 'F7'
					},
					{
						id: 465,
						name: 'F8'
					},
					{
						id: 466,
						name: 'F9'
					}
				],
				[
					{
						id: 467,
						name: 'G1'
					},
					{
						id: 468,
						name: 'G2'
					},
					{
						id: 469,
						name: 'G3'
					},
					{
						id: 470,
						name: 'G4'
					},
					{
						id: 471,
						name: 'G5'
					},
					{
						id: 472,
						name: 'G6'
					},
					{
						id: 473,
						name: 'G7'
					},
					{
						id: 476,
						name: 'H1'
					},
					{
						id: 477,
						name: 'H2'
					},
					{
						id: 478,
						name: 'H3'
					},
					{
						id: 479,
						name: 'H4'
					},
					{
						id: 480,
						name: 'H5'
					},
					{
						id: 481,
						name: 'H6'
					},
					{
						id: 482,
						name: 'H7'
					},
					{
						id: 485,
						name: 'I1'
					},
					{
						id: 486,
						name: 'I2'
					},
					{
						id: 487,
						name: 'I3'
					},
					{
						id: 488,
						name: 'I4'
					},
					{
						id: 489,
						name: 'I5'
					},
					{
						id: 490,
						name: 'I6'
					},
					{
						id: 491,
						name: 'I7'
					}
				]
			],
			rightStock: [
				[
					{
						id: 494,
						name: 'A1'
					},
					{
						id: 495,
						name: 'A2'
					},
					{
						id: 496,
						name: 'A3'
					},
					{
						id: 497,
						name: 'A4'
					},
					{
						id: 498,
						name: 'A5'
					},
					{
						id: 499,
						name: 'A6'
					},
					{
						id: 500,
						name: 'A7'
					},
					{
						id: 501,
						name: 'A8'
					},
					{
						id: 502,
						name: 'A9'
					},
					{
						id: 503,
						name: 'A10'
					},
					{
						id: 504,
						name: 'A11'
					},
					{
						id: 505,
						name: 'A12'
					},
					{
						id: 506,
						name: 'A13'
					},
					{
						id: 507,
						name: 'A14'
					},
					{
						id: 508,
						name: 'B1'
					},
					{
						id: 509,
						name: 'B2'
					},
					{
						id: 510,
						name: 'B3'
					},
					{
						id: 511,
						name: 'B4'
					},
					{
						id: 512,
						name: 'B5'
					},
					{
						id: 513,
						name: 'B6'
					},
					{
						id: 514,
						name: 'B7'
					},
					{
						id: 515,
						name: 'B8'
					},
					{
						id: 516,
						name: 'B9'
					},
					{
						id: 517,
						name: 'B10'
					},
					{
						id: 518,
						name: 'B11'
					},
					{
						id: 519,
						name: 'B12'
					},
					{
						id: 520,
						name: 'B13'
					},
					{
						id: 521,
						name: 'B14'
					},
					{
						id: 522,
						name: 'C1'
					},
					{
						id: 523,
						name: 'C2'
					},
					{
						id: 524,
						name: 'C3'
					},
					{
						id: 525,
						name: 'C4'
					},
					{
						id: 526,
						name: 'C5'
					},
					{
						id: 527,
						name: 'C6'
					},
					{
						id: 528,
						name: 'C7'
					},
					{
						id: 529,
						name: 'C8'
					},
					{
						id: 530,
						name: 'C9'
					},
					{
						id: 531,
						name: 'C10'
					},
					{
						id: 532,
						name: 'C11'
					},
					{
						id: 533,
						name: 'C12'
					},
					{
						id: 534,
						name: 'C13'
					},
					{
						id: 535,
						name: 'C14'
					}
				],
				[
					{
						id: 536,
						name: 'D1'
					},
					{
						id: 537,
						name: 'D2'
					},
					{
						id: 538,
						name: 'D3'
					},
					{
						id: 539,
						name: 'D4'
					},
					{
						id: 540,
						name: 'D5'
					},
					{
						id: 541,
						name: 'D6'
					},
					{
						id: 542,
						name: 'D7'
					},
					{
						id: 543,
						name: 'D8'
					},
					{
						id: 544,
						name: 'D9'
					},
					{
						id: 545,
						name: 'E1'
					},
					{
						id: 546,
						name: 'E2'
					},
					{
						id: 547,
						name: 'E3'
					},
					{
						id: 548,
						name: 'E4'
					},
					{
						id: 549,
						name: 'E5'
					},
					{
						id: 550,
						name: 'E6'
					},
					{
						id: 551,
						name: 'E7'
					},
					{
						id: 552,
						name: 'E8'
					},
					{
						id: 553,
						name: 'E9'
					},
					{
						id: 554,
						name: 'F1'
					},
					{
						id: 555,
						name: 'F2'
					},
					{
						id: 556,
						name: 'F3'
					},
					{
						id: 557,
						name: 'F4'
					},
					{
						id: 558,
						name: 'F5'
					},
					{
						id: 559,
						name: 'F6'
					},
					{
						id: 560,
						name: 'F7'
					},
					{
						id: 561,
						name: 'F8'
					},
					{
						id: 562,
						name: 'F9'
					}
				],
				[
					{
						id: 563,
						name: 'G1'
					},
					{
						id: 564,
						name: 'G2'
					},
					{
						id: 565,
						name: 'G3'
					},
					{
						id: 566,
						name: 'G4'
					},
					{
						id: 567,
						name: 'G5'
					},
					{
						id: 568,
						name: 'G6'
					},
					{
						id: 569,
						name: 'G7'
					},
					{
						id: 572,
						name: 'H1'
					},
					{
						id: 573,
						name: 'H2'
					},
					{
						id: 574,
						name: 'H3'
					},
					{
						id: 575,
						name: 'H4'
					},
					{
						id: 576,
						name: 'H5'
					},
					{
						id: 577,
						name: 'H6'
					},
					{
						id: 578,
						name: 'H7'
					},
					{
						id: 581,
						name: 'I1'
					},
					{
						id: 582,
						name: 'I2'
					},
					{
						id: 583,
						name: 'I3'
					},
					{
						id: 584,
						name: 'I4'
					},
					{
						id: 585,
						name: 'I5'
					},
					{
						id: 586,
						name: 'I6'
					},
					{
						id: 587,
						name: 'I7'
					}
				]
			]
		};
	},
	watch: {
		positonGraphId: {
			handler(newName, oldName) {
				this.getbykey();
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {},
	methods: {
		// id获取详情
		async getbykey(id) {
			const res = await positonGraphGetbykeyService({ id: this.positonGraphId });

			this.form.id = res.id;
			this.form.name = res.name;
			this.list = JSON.parse(res.graph);
		},
		dialogCancel() {
			this.$emit('update:isShowStockDetailDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
.box {
	float: left;
	height: 28px;
	line-height: 28px;
	margin: 10px 0;
}
.boxA {
	width: 7.1%;
}
.boxB {
	width: 11.1%;
}
.boxC {
	width: 14.28%;
}
.default {
	width: 80%;
	height: 100%;
	margin: 0 auto;
	text-align: center;
	border: 1px solid #999;
	border-radius: 5px;
	box-shadow: 0 0 5px #999;
	position: relative;
}
.sctockBox {
	border-color: #999;
	box-shadow: 0 0 5px #999;
}
.active {
	border-color: red;
	box-shadow: 0 0 5px red;
}
.active::after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: red;
	animation: twinkle 1s infinite alternate;
}
@keyframes twinkle {
	0% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}
.clear {
	content: '';
	display: table;
	clear: both;
}
.width-100 {
	width: 100%;
}
.tr {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}
.tr .td {
	margin-right: 10px;
	height: 30px;
	line-height: 30px;
}
.tr .td:last-child {
	margin-right: 0;
}
</style>
