<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="88px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="库位名称">
				<el-input v-model="formData.name" size="mini" clearable placeholder="请输入库位名称"></el-input>
			</el-form-item>
			<el-form-item label="库房">
				<el-select  v-model="formData.warehouseId" filterable clearable size="mini" placeholder="请选择库房">
					<el-option label="请选择" value=""></el-option>
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
import {
	warehouseSelectListService,
	warehouseonlymyselfService
} from '@s/spareParts/WarehouseService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				name: '',
				warehouseId: ''
			},
			warehouseData: []
		};
	},
	created() {
		// this.warehouseList();
		this.warehouseonlymyself();
	},
	methods: {
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
