<template>
	<div class="app-container">
		<!--表格渲染-->
		<!-- <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="操作" align="left" fixed="right" width="200">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'currentStock', id: 'btn-update-row' }"
						@click="reduceStockHandler(scope.row)"
						size="mini"
					>
						出库
					</el-button>
					<el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{ name: 'remove', type: 'currentStock', id: 'btn-remove-row' }"
						@click="historyHandler(scope.row)"
						size="mini"
					>
						历史
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination> -->
		<el-timeline>
			<el-timeline-item timestamp="2018/4/12" placement="top">
				<el-card>
					<h4>更新 Github 模板</h4>
					<p>王小虎 提交于 2018/4/12 20:46</p>
				</el-card>
			</el-timeline-item>
			<el-timeline-item timestamp="2018/4/3" placement="top">
				<el-card>
					<h4>更新 Github 模板</h4>
					<p>王小虎 提交于 2018/4/3 20:46</p>
				</el-card>
			</el-timeline-item>
			<el-timeline-item timestamp="2018/4/2" placement="top">
				<el-card>
					<h4>更新 Github 模板</h4>
					<p>王小虎 提交于 2018/4/2 20:46</p>
				</el-card>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { currentStockListService } from '@s/spareParts/CurrentStockService';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {},
	data() {
		return {};
	},
	computed: {},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.currentStockList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.currentStockList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.currentStockList();
			}
		}
	},
	mounted() {
		this.currentStockList();
	},
	methods: {
		async currentStockList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await currentStockListService(dataJson);
			this.listMixin(res);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
