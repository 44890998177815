<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="77px" @submit.native.prevent
			@keyup.enter.native="searchHandler">
			<el-form-item label="开始时间">
				<el-date-picker v-model="formData.begin" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			<el-form-item label="结束时间">
				<el-date-picker v-model="formData.end" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"></el-date-picker>
			</el-form-item>
			<el-form-item label="库房">
				<el-select v-model="formData.warehouseid" filterable clearable>
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="物料名称" prop="goodsname ">
				<el-input v-model="formData.goodsname" placeholder="请输入物料名称" />
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
			@click.enter="searchHandler">搜索</el-button>
		<el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downLoading" @click.enter="download">导出</el-button>
	</div>
</template>

<script>
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';

export default {
	props: ['downLoading'],
	data() {
		return {
			// 搜索
			formData: {
				begin: null,
				end: null,
				warehouseid: null
			},
			warehouseData: []
		};
	},
	created() {
		this.warehouseList();
	},
	methods: {
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		download() {
			this.$emit('download', this.formData);
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped></style>
