<template>
	<div class="app-container">
		<ContractSearchForm @searchForm="searchFormHandler"></ContractSearchForm>
		<div style="height: 15px"></div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="ID" prop="id"></el-table-column>
			<el-table-column label="机构名称" prop="name"></el-table-column>

			<el-table-column label="官方网址" prop="website">
				<template slot-scope="scope">
					{{ scope.row.website }}
					<!-- <a :href="scope.row.website" target="_blank"></a> -->
				</template>
			</el-table-column>
			<el-table-column label="联系电话" prop="phone"></el-table-column>
			<el-table-column label="医院等级" prop="levelName"></el-table-column>
			<!-- <el-table-column label="医院等级" prop="levelId"></el-table-column> -->
			<!-- <el-table-column label="省" prop="province"></el-table-column>
			<el-table-column label="市" prop="city"></el-table-column>
			<el-table-column label="县" prop="county"></el-table-column>-->
			<el-table-column label="地址详情" prop="address"></el-table-column>
			<el-table-column label="添加时间" width="150">
				<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
			</el-table-column>
			<el-table-column label="修改时间" width="150">
				<template slot-scope="scope">{{ scope.row.updateDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right" width="350">
				<template slot-scope="scope">
					<router-link :to="`/company/contractDetail?id=${scope.row.id}`">
						<!-- <el-link type="primary" :underline="false">详情</el-link> -->
						<el-button id="btn-view-row" type="success">查看</el-button>
					</router-link>
					<span style="padding-left: 10px">
						<el-button
							id="btn-view-row"
							type="success"
							@click="exportHandler(scope.row.id)"
						>
							导出
						</el-button>
					</span>
					<span style="padding-left: 10px">
						<el-dropdown @command="handleServicesClick">
							<el-button type="primary">
								{{
									scope.row.serviceState == 2
										? '临时服务'
										: scope.row.serviceState == 1
										? '托管服务'
										: '不服务'
								}}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-for="item in servicestateList"
									:key="item.serviceState"
									:command="{ serviceStateNew: item.serviceState, ...scope.row }"
								>
									{{ item.name }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

						<!-- <el-button id="btn-view-row" type="success" @click="exportHandler(scope.row.id)">导出</el-button> -->
					</span>
					<span style="padding-left: 10px">
						<el-button
							id="btn-view-row"
							type="primary"
							@click="hospitalBigScreen(scope.row)"
						>
							大屏
						</el-button>
					</span>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<ContractAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></ContractAddForm>
		</Dialog>
		<Dialog title="导出" :visible.sync="isShowExportDialog">
			<el-form ref="formEle" :model="formData" :rules="formRules" :inline="true">
				<el-form-item label="" prop="beginDateD">
					<el-date-picker
						v-model="formData.beginDateD"
						type="month"
						placeholder="请选择开始时间"
					></el-date-picker>
					-
				</el-form-item>
				<el-form-item label="" prop="endDateD">
					<el-date-picker
						v-model="formData.endDateD"
						type="month"
						placeholder="请选择结束时间"
					></el-date-picker>
				</el-form-item>
				<span style="padding-left: 10px">
					<el-button @click="exportFn" id="btn-view-row" type="success">导出</el-button>
				</span>
			</el-form>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	getcompanysignListService,
	changeservicestateService
} from '@s/company/contract/ContractService';
import Dialog from '@c/ui/Dialog';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ContractAddForm from '@f/company/contract/ContractAdd.form';
import ContractSearchForm from '@f/company/contract/ContractSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage, htimeFormat, hdateInMonth } from '@/util/htools.web';

export default {
	name: 'CompanyContract',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		ContractAddForm,
		ContractSearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			isShowExportDialog: false,
			exportDate: '',
			hospId: '',
			formData: {
				beginDateD: '',
				endDateD: ''
			},
			formRules: {
				beginDateD: [
					{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}
				],
				endDateD: [
					{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}
				]
			},
			servicestateList: [
				{ serviceState: 0, name: '不服务' },
				{ serviceState: 1, name: '托管服务' },
				{ serviceState: 2, name: '临时服务' }
			]
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		},
		beginDate() {
			return htimeFormat(this.formData.beginDateD);
		},
		endDate() {
			return htimeFormat(this.formData.endDateD);
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.contractList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.contractList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.contractList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.contractList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.contractList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		exportHandler(hospId) {
			this.isShowExportDialog = true;
			this.hospId = hospId;
		},
		hospitalBigScreen(row) {
			const bigScreenUrl = `https://bigscreen.ygongyun.com/#/?hospitalId=${row.id}`;
			// window.location.href = bigScreenUrl;
			window.open(bigScreenUrl, '_blank');
		},
		exportFn() {
			this.$refs.formEle.validate(valid => {
				console.log(valid);
				console.log(this.endDate);
				const endMonth = new Date(this.endDate).getMonth() + 1;
				const endYear = new Date(this.endDate).getFullYear();
				const endDate = hdateInMonth(this.endDate);
				const baseUrl = `https://ygongyun.com/web/comp/api/export/yearSummary?begin=${this.beginDate}&compid=${this.currentUserOrganization}`;
				const url = `${baseUrl}&end=${endYear}-${endMonth}-${endDate} 00:00:00&hosId=${this.hospId}`;
				if (valid) {
					window.open(url);
				}
			});
		},
		async contractList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await getcompanysignListService(dataJson);
			this.listMixin(res);
		},
		// 修改服务状态
		handleServicesClick(row) {
			const dataJson = {
				hosid: row.id,
				state: row.serviceStateNew
			};
			this.$confirm('确认修改服务状态？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await changeservicestateService(dataJson);
					if (res) {
						this.contractList();
						this.$message.success('操作成功！');
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.contractList();
		}
	}
};
</script>
<style lang="less" scoped></style>
