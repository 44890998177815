<template>
	<div>
		<el-row>
			<el-col :span="24">
				<el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="79px"
					:disabled="isShowDetail_p">

					<el-form-item label="源库房" prop="warehouseId">
						<el-select v-model="formData.warehouseId" clearable placeholder="请选择">
							<el-option v-for="(item, index) in warehouseData" :label="item.name" :value="item.id"
								:key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="科室" prop="hosdepart">
						<el-input v-model="formData.hosdepart" placeholder="请输入科室"></el-input>
					</el-form-item>
					<el-form-item label="设备名称" prop="title">
						<el-input v-model="formData.title" placeholder="请输入设备名称"></el-input>
					</el-form-item>
					<el-form-item label="设备型号" prop="devXH">
						<el-input v-model="formData.devXH" placeholder="请输入设备名称"></el-input>
					</el-form-item>
					<el-form-item label="设备SN" prop="devSN">
						<el-input v-model="formData.devSN" placeholder="请输入设备名称"></el-input>
					</el-form-item>
					<el-form-item label="配件" prop="goodsId">
						<el-select class="fastChooseGood" v-model="formData.goodsId" filterable
							@keyup.native="getGoodsVal($event)">
							<el-option v-for="(item, index) in materialData" :key="index" :label="item.name"
								:value="item.id"></el-option>
							<p slot="empty" class="faseAddGood">没有您想要的物料，<span @click="showFastAddGood">点此快速添加</span></p>
						</el-select>
					</el-form-item>
					<el-form-item label="配件要求" prop="goodsMemo">
						<el-input type="textarea" :rows="4" v-model="formData.goodsMemo" placeholder="请输入配件要求" />
					</el-form-item>
					<el-form-item label="报价人" prop="inviteArr">
						<el-select ref="multiSelect" v-model="formData.inviteArr" placeholder="请选择报价人" multiple clearable
							filterable>
							<el-option v-for="(user, index) in userData" :key="index" :label="user.nickName"
								:value="user.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注" prop="memo">
						<el-input type="textarea" :rows="4" v-model="formData.memo" placeholder="请输入备注" />
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="24" style="text-align: right; margin-top: 10px">
				<span class="dialog-footer" style="text-align: right">
					<el-button @click="formCancel">取 消</el-button>
					<el-button type="primary" @click="formSubmit">确 定</el-button>
				</span>
			</el-col>
		</el-row>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList" @isToParentGoodsNmae_p="materialList" :selectData_p="selectData"
				:isFastAddGood_p.sync="isFastAddGood"></MaterialAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	addpriceaskService,
	updateService,
	defaultInviteService
} from '@s/spareParts/PriceAskService';
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
// eslint-disable-next-line import/named
import {
	transferMaterialAddService,
	transferMaterialDeleteService,
	transferMaterialEditService
} from '@s/spareParts/TransferService';
// eslint-disable-next-line import/named
import { materialAllListService, materialDetailService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService } from '@s/spareParts/SupplierService';
import { hremoveStorage, hgetStorage } from '@u/htools.web';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';
import ListMixin from '@m/List.mixin';
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import Dialog from '@c/ui/Dialog';

export default {
	props: [
		'selectData_p',
		'isShowDetail_p',
		'isShowAEDialog_p',
		'selectDataFastborrow_p',
		'selectNativesData_p'
	],
	mixins: [ListMixin],
	components: { MaterialAddForm, Dialog },
	data() {
		return {
			// 申请人下拉
			userData: [],
			isRefreshDetail: false,
			selectData: [],
			selectData1: [],
			isFastAddGood: true,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			// 弹窗
			formData: {
				warehouseId: '',
				inviteArr: []
			},
			formRules: {
				goodsId: [
					{
						required: true,
						message: '请选择物料',
						trigger: 'change'
					}
				],
				warehouseId: [
					{
						required: true,
						message: '请选择源库房',
						trigger: 'change'
					}
				],
				towarehouseId: [
					{
						required: true,
						message: '请选择目标库房',
						trigger: 'change'
					}
				],
				inviteArr: [
					{
						required: true,
						message: '请选择报价人',
						trigger: 'change'
					}
				]
			},
			supplierData: [],
			materialData: [],
			warehouseData: [],
			materialDetailData: '',
			isShowReduceStockListDialog: false,
			selectDatainOutRecord: null,
			defaultInviteData: []
		};
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		}
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					this.userData.length === 0 && (await this.userList());
					this.formData = { ...newValue[0] };
					console.log(this.formData);
				}
			},
			deep: true,
			immediate: true
		},
		// 当前库存列表 备选来的申请领用借用
		selectNativesData_p: {
			handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.goodsId = newValue[0].goodsid;
				this.formData.warehouseId = newValue[0].warehouseId;
				this.formData.cnt = newValue[0].cnt;
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshDetail: {
			handler(newValue) {
			},
			deep: true
		}
	},
	created() {
		this.defaultInviteList();
		/* this.supplierList(); */
		this.materialList();
		this.warehouseList();
		this.userList();
	},
	methods: {
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		async defaultInviteList() {
			const dataJson = {};
			const res = await defaultInviteService(dataJson);
			this.defaultInviteData = res;
			console.log('this.defaultInviteList');
			console.log(this.defaultInviteData);
			if (this.defaultInviteData && this.defaultInviteData.length > 0) {
				this.formData.inviteArr = this.defaultInviteData;
			}
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				requestId: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			/* const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name; */
		},
		saveDetailHandler(row) {
			if (!row.goodsId || row.cnt === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt < 0) {
				this.$message.warning('请输入正确的数量');
				return false;
			}
			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						requestId: this.editId
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						this.formData.goodsId = item.id;
					}
				});
			// goodsName &&
			// 	this.tableData.forEach(item => {
			// 		if (item.isAddDetail) {
			// 			item.goodsId = goodsId;
			// 		}
			// 	});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async supplierList() {
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await transferMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await transferMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await transferMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		// 没有您想要的物料，点此快速添加
		showFastAddGood() {
			this.dialogAddHandlerMixin();
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || ''
			});
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					this.formData.invite = this.formData.inviteArr?.join(',') || '';
					if (this.editId === -1) {
						this.formData.invite = this.formData.inviteArr.join(',');
						await addpriceaskService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await updateService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
			hremoveStorage('fastSaleOrPurchase');
		},
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
			if (!this.formData.requestUser) {
				this.formData.requestUser = hgetStorage('id');
			}
		}
	}
};
</script>

<style lang="less" scoped>
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}

.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}

.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
