<template>
	<el-form ref="parameterForm" :model="parameterForm" :rules="parameterRules" size="small" label-width="99px">
		<el-form-item label="字段名称" prop="parameterKey">
			<el-input :disabled="editId !== -1" v-model="parameterForm.parameterKey" placeholder="请输入字段名称" />
		</el-form-item>
		<el-form-item label="名称" prop="name">
			<el-input v-model="parameterForm.name" placeholder="请输入名称" />
		</el-form-item>
		<el-form-item label="字段值" prop="parameterValue">
			<el-input v-model="parameterForm.parameterValue" placeholder="请输入字段值" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="parameterFormCancel">取 消</el-button>
					<el-button type="primary" @click="parameterFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
import { parameterAddService, parameterEditService, parameterDetailService } from '@s/base/ParameterService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			parameterForm: {
				name: '',
				parameterKey: '',
				parameterValue: ''
			},
			parameterRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				parameterKey: [
					{
						required: true,
						message: '请输入字段名称',
						trigger: 'blur'
					}
				],
				parameterValue: [
					{
						required: true,
						message: '请输入字段值',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.parameterForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		if (this.editId_p !== -1) {
			this.getDetail();
		}
	},
	methods: {
		async getDetail() {
			const dataJson = {
				parameterId: this.editId_p
			};
			const res = await parameterDetailService(dataJson);
			this.parameterForm = { ...res };
		},
		parameterFormSubmit() {
			this.$refs.parameterForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await parameterAddService(this.parameterForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.parameterForm
						};
						await parameterEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.parameterFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		parameterFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
