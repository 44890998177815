<template>
  <div class="table-container">
    <el-table ref="table" style="width: 100%" v-bind="$attrs" v-on="$listeners" @selection-change="selectionChangeHandler" border :header-cell-style="{ background: '#F4F4F4' }" :empty-text="emptyText">

      <slot name="tableLeftTree"></slot>
      <el-table-column v-if="!isHideCheckbox_p" type="selection" width="55" />
      <slot name="tableLeftData"></slot>
      <el-table-column v-for="(item, index) in tableColumn_p" :width="item.width ? item.width : 'auto'" :key="index" :prop="item.field" :label="item.label"></el-table-column>
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: ['tableColumn_p', 'selectData_p', 'isHideCheckbox_p'],
	data() {
		return {
			emptyText: '加载中...'
		};
	},
	mounted() {
		setTimeout(() => {
			this.emptyText = '暂无数据';
		}, 1000 * 10);
	},
	methods: {
		selectionChangeHandler(data) {
			this.$emit('update:selectData_p', data);
		},
		clearSelection() {
			this.$refs.table.clearSelection();
		}
	}
};
</script>

<style></style>
