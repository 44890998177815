import { hpostRequest } from '@u/htools.axios.js';

/*
 *@Description: 设备列表
 *@ClassAuthor: MYW
 *@Date: 2021-05-10 14:29:02
 */
export const deviceListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hid: '',
				hids: [],
				...data
			};
			if (
				dataJson.hid != null &&
				dataJson.hid != '' &&
				dataJson.hid != undefined
			) {
				dataJson.hids = [dataJson.hid];
			} else {
				dataJson.hids = [];
			}
			if (dataJson.hids.length == 0) {
				dataJson.hids = dataJson.hidsTemp;
			}
			console.log('dataJson.hids');
			console.log(dataJson);
			console.log(dataJson.hidsTemp);
			delete dataJson.hid;
			const res = await hpostRequest(
				'hbtyong_5/devBase/queryPagingAll',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default deviceListService;
