<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<BorrowSearchform @searchForm="searchFormHandler"></BorrowSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'borrow'"
					:isHide_p="{ removeBtn: true, updateBtn: true }"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			isHideCheckbox_p="false"
		>
			<!-- <el-table-column label="数量">
        <template slot-scope="scope">
          <span class="numberp10">
            <span v-if="scope.row.inCnt == scope.row.outCnt &&scope.row.inCnt == scope.row.allCnt && scope.row.outCnt == scope.row.allCnt" style="color:#67C23A; cursor:pointer;">
              <span title="已入库">{{scope.row.inCnt }}</span> / <span title="已出库">{{scope.row.outCnt }}</span> / <span title="总数">{{scope.row.allCnt }}</span>
            </span>
            <span v-else style="color:#E6A23C; cursor:pointer;">
              <span title="已入库">{{scope.row.inCnt }}</span> / <span title="已出库">{{scope.row.outCnt }}</span> / <span title="总数">{{scope.row.allCnt }}</span>
            </span>
          </span>
        </template>
      </el-table-column> -->
			<el-table-column label="序号" type="index" width="50"></el-table-column>
			<el-table-column property="goodsName" label="物料名称">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsId,
							goodsName: scope.row.goodsName
						}"
						:key="scope.row.id"
					></MaterialTooltip>
				</template>
			</el-table-column>
			<el-table-column property="cnt" label="数量">
				<template slot-scope="scope">
					<template v-if="scope.row.type == 0">
						<span
							v-if="
								scope.row.returnCnt == scope.row.doneCnt && scope.row.returnCnt != 0
							"
							style="color:#67C23A; cursor:pointer;"
						>
							<span title="归还数量">{{ scope.row.returnCnt }}</span>
							/
							<span title="出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="申请">{{ scope.row.cnt }}</span>
						</span>
						<span v-else style="color:#E6A23C;cursor:pointer;">
							<span title="归还数量">{{ scope.row.returnCnt }}</span>
							/
							<span title="出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="申请">{{ scope.row.cnt }}</span>
						</span>
					</template>
					<template v-if="scope.row.type == 1">
						<span
							v-if="scope.row.doneCnt == scope.row.cnt && scope.row.doneCnt != 0"
							style="color:#67C23A; cursor:pointer;"
						>
							<span title="出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="申请">{{ scope.row.cnt }}</span>
						</span>
						<span v-else style="color:#E6A23C;cursor:pointer;">
							<span title="出库">{{ scope.row.doneCnt }}</span>
							/
							<span title="申请">{{ scope.row.cnt }}</span>
						</span>
					</template>
				</template>
			</el-table-column>
			<el-table-column property="warehouseName" label="仓库">
				<template slot-scope="scope">
					{{ scope.row.warehouseName }}
					<i class="el-icon-arrow-right"></i>
					{{ scope.row.towarehouseName }}
				</template>
			</el-table-column>
			<el-table-column property="requestUserName" label="申请人"></el-table-column>
			<el-table-column label="日期" width="150">
				<template slot-scope="scope">
					{{ scope.row.requestTime | parseTime }}
				</template>
			</el-table-column>
			<!-- 类型 0借用 1领用 -->
			<el-table-column property="type" label="类型">
				<template slot-scope="scope">
					{{ scope.row.type == 1 ? '领用' : '借用' }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="280">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'borrow', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'borrow', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="30%">
			<BorrowAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></BorrowAddForm>
		</Dialog>
		<Dialog title="备注" :visible.sync="isShowMemoDialog" width="40%">
			<el-input
				type="textarea"
				:rows="5"
				placeholder="请输入备注"
				v-model="textarea"
			></el-input>
			<div style="text-align: right; margin-top: 20px">
				<el-button @click="changeStateCancel">取消</el-button>
				<el-button type="primary" @click="changeStateSubmit">确定</el-button>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	borrowDeleteService,
	borrowListService,
	borrowDetailService
} from '@s/spareParts/BorrowService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import BorrowAddForm from '@f/spareParts/borrow/BorrowAdd.form';
import BorrowSearchform from '@f/spareParts/borrow/BorrowSearch.form';
import MaterialTooltip from '@/components//MaterialTooltip';
import { hgetStorage } from '@u/htools.web';

export default {
	name: 'Borrow',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		BorrowAddForm,
		BorrowSearchform,
		MaterialTooltip
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			isShowMemoDialog: false,
			state: '',
			textarea: '',
			rowId: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增领用借用申请' : '编辑领用借用申请';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.borrowList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.borrowList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.borrowList();
			}
		}
	},
	created() {},
	mounted() {
		this.borrowList();
		console.log('this.$route.query');
		console.log(this.$route.query);
		const { type } = this.$route.query || '';
		const data = hgetStorage('fastSaleOrPurchase');
		if (type === 'fastSaleOrPurchase' && !!data) {
			this.isShowAEDialog = true;
			this.selectData = [data];
		}
	},
	methods: {
		async borrowList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await borrowListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await borrowDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			await borrowDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await borrowDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.borrowList();
		},
		async changeStateSubmit() {
			const dataJson = {
				id: this.rowId,
				memo: this.textarea,
				newState: this.state
			};
			// await saleApplicationChangeStateService(dataJson);
			// this.changeStateCancel();
			// this.transferList();
		},
		changeStateCancel() {
			this.state = '';
			this.textarea = '';
			this.isShowMemoDialog = false;
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}
</style>
