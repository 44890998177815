import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 医院详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const hospitalDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3/hospital/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   hospitalLevel
 */
export const hospitalLevelListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				grade: '',
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// export const hospitalLevelEditService = data => {
// 	return new Promise(async resolve => {
// 		try {
// 			const dataJson = {
// 				id: '',
// 				grade: 0,
// 				...data
// 			};
// 			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeUpdate', dataJson);
// 			resolve(res);
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	});
// };

/*
 *@Description: 医院修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
 export const hospitalEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				address: '',
				city: '',
				county: '',
				name: '',
				levelId: '', 
				phone: '', 
				province: 0,
				website: '',
				...data
			};
			const res = await hpostRequest('hbtyong_3/hospital/organizationUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 维修公司修改

/*
 *@Description: 维修公司详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
 export const companyDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				companyId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3/company/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */

 export const companyEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				phone: '',
				userName: '',
				password: '',
				scopeMaintenanceId: '',
				website: '', 
				address: '',
				...data
			};
			const res = await hpostRequest('hbtyong_3/company/organizationUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default hospitalLevelListService;
