<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="88px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="名称">
				<el-input v-model="formData.name" size="mini" clearable placeholder="请输入名称"></el-input>
			</el-form-item>
			<el-form-item label="联系人">
				<el-input v-model="formData.contract" size="mini" clearable placeholder="请输入联系人"></el-input>
			</el-form-item>
			<el-form-item label="电话">
				<el-input v-model="formData.phone" size="mini" clearable placeholder="请输入电话"></el-input>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>

export default {
	data() {
		return {
			// 搜索
			formData: {
				name: '',
				contract: '',
				phone: ''
			}
		};
	},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
