<template>
	<div class="app-container">
		<GroupSummarySearchForm
			@searchForm="searchFormHandler"
			@download="downloadExlcel"
		></GroupSummarySearchForm>
		<div class="class20" style="width:100%; height:15px;"></div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			highlight-current-row
			isHideCheckbox_p="false"
			row-class-name="borderBoldtd"
			:cell-class-name="cellClassName"
		>
			<el-table-column
				label="序号"
				type="index"
				width="50"
			></el-table-column>
			<el-table-column
				label="医院名称"
				prop="hosname"
				class-name="borderBoldth"
			></el-table-column>
			<el-table-column
				label="设备"
				align="center"
				class-name="borderBoldth"
			>
				<el-table-column label="数量" prop="devCnt" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.devCnt }}</span>
						<br />
						<span>无价格:{{ scope.row.devNoPriceCnt || '0' }}</span>
					</template>
				</el-table-column>
				<el-table-column
					label="金额"
					prop="devPrice"
					class-name="borderBoldth"
				></el-table-column>
			</el-table-column>

			<el-table-column
				label="报修"
				align="center"
				class-name="borderBoldth"
			>
				<el-table-column
					label="报修数量"
					prop="repairCnt"
					min-width="120px"
				>
					<template slot-scope="scope">
						<span style="margin-right:10px;" title="按报修时间查询">
							报修:{{ scope.row.repairCnt }}
						</span>
						<span title="按报修时间查询">
							补录:{{ scope.row.repairFromPcCnt }}
						</span>
						<br />
						<span title="按签单时间查询">
							成本:{{ scope.row.repairCost || '0' }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="完成数量" prop="finishCnt">
					<template slot-scope="scope">
						<span title="按完成时间查询">
							{{ scope.row.finishCnt }}
						</span>
						<br />
						<span title="按签单时间查询">
							应收:{{ scope.row.repairPrice || '0' }}
						</span>
						<br />
						<span>实收:{{ scope.row.realPrice || '0' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="签单数量" prop="signCnt" width="100">
					<template slot-scope="scope">
						<span title="按签单时间查询" style="margin-right:10px;">
							{{ scope.row.signCnt }}
						</span>
						<span
							title="免单数量"
							:class="scope.row.freeCount > 0 ? 'dangerText' : ''"
						>
							免单:{{ scope.row.freeCount }}
						</span>
						<br />

						<span
							title="按完成时间查询"
							:class="scope.row.noSignCnt > 0 ? 'dangerText' : ''"
						>
							未签单数量:{{ scope.row.noSignCnt || '0' }}
						</span>
						<br />

						<span
							title="按签单时间查询"
							:class="
								scope.row.nopirceCnt > 0 ? 'dangerText' : ''
							"
						>
							未报价:{{ scope.row.nopirceCnt || '0' }}
						</span>
					</template>
				</el-table-column>
				<el-table-column
					label="时间"
					prop="lastRepairTime"
					width="190px"
					class-name="borderBoldth"
				>
					<template slot-scope="scope">
						<span>最后报修:{{ scope.row.lastRepairTime }}</span>
						<br />
						<span>最后完成:{{ scope.row.lastFinishTime }}</span>
					</template>
				</el-table-column>
			</el-table-column>

			<el-table-column
				label="巡检"
				align="center"
				class-name="borderBoldth"
			>
				<el-table-column
					label="总数"
					prop="inspectCnt"
					width="50px"
				></el-table-column>
				<el-table-column
					label="未开始"
					prop="inspectUndoCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="进行中"
					prop="inspectDoingCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="已完成"
					prop="inspectDoneCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="超时"
					prop="inspectOutimeStartCnt"
					width="50px"
					class-name="borderBoldth"
				>
					<template slot-scope="scope">
						<span>{{ scope.row.inspectOutimeStartCnt }}</span>
					</template>
				</el-table-column>
			</el-table-column>

			<el-table-column
				label="保养"
				align="center"
				class-name="borderBoldth"
			>
				<el-table-column
					label="总数"
					prop="upkeepCnt"
					width="50px"
				></el-table-column>
				<el-table-column
					label="未开始"
					prop="upkeepUndoCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="进行中"
					prop="upkeepDoingCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="已完成"
					prop="upkeepDoneCnt"
					width="60px"
				></el-table-column>
				<el-table-column
					label="超时"
					prop="upkeepOutimeStartCnt"
					width="50px"
					class-name="borderBoldth"
				>
					<template slot-scope="scope">
						<span>{{ scope.row.upkeepOutimeStartCnt }}</span>
					</template>
				</el-table-column>
			</el-table-column>
			<el-table-column label="库存" align="center">
				<el-table-column label="采购">
					<template slot-scope="scope">
						<span>{{ scope.row.wmsBuyCnt || '0' }}单</span>
						<br />
						<span>{{ scope.row.wmsBuyItemPrice || '0' }}元</span>
					</template>
				</el-table-column>
				<el-table-column label="入库">
					<template slot-scope="scope">
						<span>物料:{{ scope.row.wmsBuyItemCnt || '0' }}</span>
						<br />
						<span>入库:{{ scope.row.wmsBuyInItemCnt || '0' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="销售">
					<template slot-scope="scope">
						<span>{{ scope.row.wmsSellCnt || '0' }}单</span>
						<br />
						<span>{{ scope.row.wmsSellItemPrice || '0' }}元</span>
					</template>
				</el-table-column>
				<el-table-column label="出库">
					<template slot-scope="scope">
						<span>物料:{{ scope.row.wmsSellItemCnt || '0' }}</span>
						<br />
						<span>
							出库:{{ scope.row.wmsSellInItemCnt || '0' }}
						</span>
					</template>
				</el-table-column>
			</el-table-column>

			<!-- <el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button id="btn-update-row" type="text" @click="showDetail(scope.row)">
						详情
					</el-button>
				</template>
			</el-table-column> -->
		</Table>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { compworksService } from '@s/company/groupsummary/groupsummarysummaryService';
import Table from '@c/ui/Table';
import GroupSummarySearchForm from '@f/company/GroupSummary/GroupSummarySearch.form';
import ListMixin from '@m/List.mixin';
import {
	hgetStorage,
	hsetStorage,
	hremoveStorage,
	htimeFormat
} from '@/util/htools.web';

export default {
	name: 'GroupSummary',
	mixins: [ListMixin],
	components: {
		Table,
		GroupSummarySearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			tableDownData: [],
			currentUserOrganization: null,
			downLoading: false,
			isShowEmpSummaryDetailDialog: false,
			dialogName: null,
			// 表格
			tableData: [],
			selectData: [],
			// 搜索
			searchForm: {},
			editId: -1
		};
	},
	computed: {},
	watch: {
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
			}
		}
	},
	mounted() {
		// this.getList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async getList() {
			const dataJson = {
				...this.searchForm
			};
			const res = await compworksService(dataJson);
			// if (res.records.length > 0) {
			// 	res.records.forEach(item => {
			// 		item.date = `${htimeFormat(
			// 			this.searchForm.begin,
			// 			'{y}-{m}-{d}'
			// 		)} - ${htimeFormat(this.searchForm.end, '{y}-{m}-{d}')}`;
			// 	});
			// }
			// this.listMixin(res);
			this.tableData = res;
		},
		async getDwonList() {
			const dataJson = {
				...this.searchForm
			};
			const res = await compworksService(dataJson);
			res.forEach(item => {
				if (item.repairCost == null) {
					item.repairCost = 0;
				}
				if (item.repairPrice == null) {
					item.repairPrice = 0;
				}
				if (item.wmsBuyCnt == null) {
					item.wmsBuyCnt = 0;
				}
				if (item.wmsBuyItemPrice == null) {
					item.wmsBuyItemPrice = 0;
				}
				if (item.wmsBuyItemCnt == null) {
					item.wmsBuyItemCnt = 0;
				}
				if (item.wmsBuyInItemCnt == null) {
					item.wmsBuyInItemCnt = 0;
				}
				if (item.wmsSellCnt == null) {
					item.wmsSellCnt = 0;
				}
				if (item.wmsSellItemPrice == null) {
					item.wmsSellItemPrice = 0;
				}
				if (item.wmsSellItemCnt == null) {
					item.wmsSellItemCnt = 0;
				}
				if (item.wmsSellInItemCnt == null) {
					item.wmsSellInItemCnt = 0;
				}
			});
			// this.listMixin(res);
			this.tableDownData = res;
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		},
		showDetail(row) {
			this.searchForm.row = { ...row };
			this.isShowEmpSummaryDetailDialog = false;
			this.isShowEmpSummaryDetailDialog = true;
			this.editId = row.id;
		},
		cellClassName({ row, column, rowIndex, columnIndex }) {
			if (row.repairFromPcCnt == row.repairCnt && columnIndex == 4) {
				return 'warningBg';
			}
			if (row.inspectOutimeStartCnt > 0 && columnIndex == 12) {
				return 'dangerBg';
			}
			if (row.upkeepOutimeStartCnt > 0 && columnIndex == 17) {
				return 'dangerBg';
			}
			if (
				row.wmsBuyItemCnt &&
				row.wmsBuyItemCnt != row.wmsBuyInItemCnt &&
				columnIndex == 19
			) {
				return 'warningBg';
			}
			if (
				row.wmsSellItemCnt &&
				row.wmsSellItemCnt != row.wmsSellInItemCnt &&
				columnIndex == 21
			) {
				return 'warningBg';
			}
		},
		async downloadExlcel(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.downLoading = true;
			await this.getDwonList();
			this.getList();

			const tHeader = [
				'医院名称',
				'设备数量',
				'设备无价格数量',
				'设备金额',
				'报修数量 (按报修时间查询)',
				'报修补录数量 (按报修时间查询)',
				'报修成本 (按签单时间查询)',
				'报修完成数量 (按完成时间查询)',
				'应收 (按签单时间查询)',
				'实收',
				'报修签单数量 (按签单时间查询)',
				'免单数量',
				'未签单数量 (按完成时间查询)',
				'未报价 (按签单时间查询)',
				'最后报修时间',
				'最后完成时间',
				'巡检总数',
				'巡检未开始',
				'巡检进行中',
				'巡检已完成',
				'巡检超时',
				'保养总数',
				'保养未开始',
				'保养进行中',
				'保养已完成',
				'保养超时',
				'采购单数量',
				'采购单物料价格',
				'采购单物料数量',
				'采购单入库物料数量',
				'销售单数量',
				'销售单物料价格',
				'销售单物料数量',
				'销售单出库物料数量'
			];
			// eslint-disable-next-line no-unused-vars
			const filterVal = [
				'hosname',
				'devCnt',
				'devNoPriceCnt',
				'devPrice',
				'repairCnt',
				'repairFromPcCnt',
				'repairCost',
				'finishCnt',
				'repairPrice',
				'realPrice',
				'signCnt',
				'freeCount',
				'noSignCnt',
				'nopirceCnt',
				'lastRepairTime',
				'lastFinishTime',
				'inspectCnt',
				'inspectUndoCnt',
				'inspectDoingCnt',
				'inspectDoneCnt',
				'inspectOutimeStartCnt',
				'upkeepCnt',
				'upkeepUndoCnt',
				'upkeepDoingCnt',
				'upkeepDoneCnt',
				'upkeepOutimeStartCnt',
				'wmsBuyCnt',
				'wmsBuyItemPrice',
				'wmsBuyItemCnt',
				'wmsBuyInItemCnt',
				'wmsSellCnt',
				'wmsSellItemPrice',
				'wmsSellItemCnt',
				'wmsSellInItemCnt'
			];
			const data = this.formatJson(filterVal, this.tableDownData);
			// const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
			const begin = htimeFormat(this.searchForm.begin, '{y}-{m}-{d}');
			const end = htimeFormat(this.searchForm.end, '{y}-{m}-{d}');
			const nowDate = `${begin}至${end}`;
			console.log('this.searchForm');
			console.log(this.searchForm);
			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `项目组工作统计${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.downLoading = false;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 500px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 100%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .el-form-item__content {
	// width: 100%;
}

/deep/ .el-select {
	width: 100%;
}

/deep/ .el-table--border th.borderBoldth,
/deep/ .el-table tr.borderBoldtd td:nth-child(2),
/deep/ .el-table tr.borderBoldtd td:nth-child(4),
/deep/ .el-table tr.borderBoldtd td:nth-child(8),
/deep/ .el-table tr.borderBoldtd td:nth-child(13),
/deep/ .el-table tr.borderBoldtd td:nth-child(18) {
	border-right: 1px solid #999999;
}

/deep/ .el-table__body td.warningBg {
	background: rgba(230, 162, 60, 0.8);
}

/deep/ .el-table__body tr:hover td.warningBg {
	background: rgba(230, 162, 60, 1);
	color: #ffffff;
}

/deep/ .el-table td.dangerBg {
	background: rgba(245, 108, 108, 0.8);
}

/deep/ .el-table tr:hover td.dangerBg {
	background: rgba(245, 108, 108, 1);
	color: #ffffff;
}
.dangerText {
	color: rgba(245, 108, 108, 1);
	font-weight: bold;
}
</style>
