/*
 *@Description: 库位图
 *@ClassAuthor: Happy ZXH
 *@Date: 2022-06-20 10:19:55
*/
<template>
  <div>
    <el-button type="primary" @click="showAddGraph" style="margin-bottom:20px;">新增</el-button>
    <!-- <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="名称" width="180">
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <el-button type="succss">编辑 {{scope.row.id}}</el-button>
          <el-button type="danger">删除 {{scope.row.id}}</el-button>
        </template>
      </el-table-column>
    </el-table> -->

    <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
      <el-table-column label="操作" align="left" fixed="right" width="300">
        <!-- 管理员没有操作 -->
        <template slot-scope="scope">
          <el-button type="primary" @click="showAddGraph(scope.row)">编辑</el-button>
          <el-button type="danger" @click="deleteById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>

    <grid-layout style="display:none;" :layout.sync="layout" :col-num="12" :row-height="30" :is-draggable="true" :is-resizable="true" :is-mirrored="false" :vertical-compact="true" :margin="[10, 10]" :use-css-transforms="true">

      <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i" style="background:rgba(0,0,0,0.2)">
        {{item.i}}
        <grid-layout :layout.sync="item.children" :col-num="12" :row-height="30" :is-draggable="true" :is-resizable="true" :is-mirrored="false" :vertical-compact="true" :margin="[10, 10]" :use-css-transforms="true">

          <grid-item v-for="item in item.children" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i" style="background:rgba(0,0,255,0.2)">
            {{item.i}}
          </grid-item>
        </grid-layout>
      </grid-item>
    </grid-layout>

    <div class="row" style="display:none;">
      <div class="col-8">
        <h3>Draggable</h3>
        <draggable v-model="rows" style="background: #e06a76;">
          <div v-for="row in rows" :key="row.index" class="sortable" xs12 my-2 style="background: #f99283">
            <draggable :list="row.items" :group="{ name: 'row' }" class="row wrap justify-space-around" style="display:flex; background:#6dab9c; margin:20px 0; justify-content:space-between;min-height:100px;padding:20px;">
              <div v-for="item in row.items" :key="item.title + Math.random()" class="" :style="'background:#5873de; margin:20px; width:'+(1/row.items.length)*100+'%'">
                <div style="height: 100px;">{{ item.title }}</div>
              </div>
              <div>
                <el-button type="primary">编辑</el-button><br /><br />
                <el-button type="danger">删除</el-button>
              </div>
            </draggable>
          </div>
        </draggable>
      </div>
    </div>

    <Dialog title="详情" :visible.sync="isShowAddGraph" width="70%">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="库位图名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button type="primary" @click="showAddOne(-1)">加一行</el-button>
          <el-button type="success" @click="formSubmit('form')">保存</el-button>
        </el-col>
        <el-col :span="24">

          <draggable v-model="list" style="">
            <div v-for="row in list" :key="row.index" class="sortable" xs12 my-2 style="">
              <draggable :list="row.items" :group="{ name: 'row' }" class="row wrap justify-space-around" ghost-class="ghost" chosen-class="chosen" filter=".forbid" style="display:flex; justify-content:space-between;max-height:66px;min-height:30px;padding:10px 0;" :move="onMove">
                <div v-for="item in row.items" :key="item.name + Math.random()" class="" :style="' margin:0 15px 0 0; width:'+(1/row.items.length)*100+'%'">
                  <div class="smallBlock" @click="test">{{ item.warehouseName }}<br />{{item.name}}</div>
                </div>
                <div class="dotDrag forbid" style="">
                  <div>
                    <el-button type="primary" @click="showAddOne(row.index)" style="margin-bottom:5px;">编辑</el-button>
                  </div>
                  <div>
                    <el-button type="danger" @click="delOneLine(row.index)">删除</el-button>
                  </div>
                </div>
              </draggable>
            </div>
          </draggable>
        </el-col>
      </el-row>

    </Dialog>
    <Dialog title="库位选择" :visible.sync="isShowAddOne" width="50%">
      <el-form ref="formData" :model="formData" label-width="100px">
        <el-form-item label="库房" prop="warehouseId">
          <el-select v-model="formData.warehouseId" :disabled="warehouseDisabled" @change="warehouseChangeHandler" clearable placeholder="请选择库房" style="width:100%">
            <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="warehouseId">
          <el-transfer v-model="formData.wareareaDataSelectIds" :data="wareareaData" :props="{
      key: 'id',
      label: 'name'
    }" filterable @change="getwareareaDataSelectList"></el-transfer>
        </el-form-item>
        <el-form-item>

          <el-button type="primary" @click="addOneLine">添加一行</el-button>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { wareareaListService } from '@s/spareParts/WareareaService';
// eslint-disable-next-line import/no-cycle
import {
	positonGraphAddService,
	positonGraphDelService,
	stockMoveListService,
	positonGraphGetbykeyService,
	positonGraphSearchService,
	positonGraphUpdateService
} from '@s/spareParts/CurrentStockService';
import Table from '@c/ui/Table';
import VueGridLayout from 'vue-grid-layout';
import Dialog from '@c/ui/Dialog';
import draggable from 'vuedraggable';
import ListMixin from '@m/List.mixin';
import Pagination from '@c/ui/Pagination';

const { GridLayout, GridItem } = VueGridLayout;
// 添加 点击增加，提交的数据是名称 ids 库位图
// 加一行 现有穿梭数据清空
// 编辑时查询库房id 选中编辑列表
// 第二次加一行是下拉框禁用 重新获取穿梭框数据
export default {
	mixins: [ListMixin],
	components: {
		GridLayout,
		GridItem,
		Dialog,
		draggable,
		Table,
		Pagination
	},

	data() {
		return {
			searchForm: {},
			flagEdit: null,
			// 最后提交的数据
			list: [],
			// 穿梭框选中的数据
			listTemp: [],
			rows: [
				{
					index: 1,
					items: [
						{
							title: 'item 11'
						},
						{
							title: 'item 12'
						}
					]
				},
				{
					index: 2,
					items: [
						{
							title: 'item 21'
						},
						{
							title: 'item 22'
						}
					]
				},
				{
					index: 3,
					items: [
						{
							title: 'item 31'
						},
						{
							title: 'item 32'
						}
					]
				}
			],
			isShowAddGraph: false,
			isShowAddOne: false,
			// 库房
			warehouseData: [],
			warehouseDisabled: false,
			// 库位
			wareareaData: [],
			// 库位图提交表单
			form: {
				id: null,
				// 库位图sting
				graph: 'string',
				name: null,
				// 库位图里库位ids
				positions: null
			},
			formData: {
				// 选中的值
				wareareaDataSelectIds: [],
				// 禁止点击的值 穿梭框
				wareareaDataDisabledIds: [],
				// 穿梭框列表 查询id
				warehouseId: null
			},
			rules: { name: [{ required: true, message: '请输入库位图名称', trigger: 'blur' }] },
			tableData: [
				{
					id: 0,
					name: 'A库位'
				}
			],
			tableColumn: [
				{
					label: '名称',
					field: 'name'
				}
			],
			selectData: [],
			// TODELETE
			index: 0,
			newX: 0,
			newY: 0,
			curBox: '',
			layout: [
				{
					x: 0,
					y: 0,
					w: 12,
					h: 3,
					i: '0',
					children: [{ x: 0, y: 0, w: 2, h: 2, i: '0' }]
				},
				{
					x: 0,
					y: 4,
					w: 12,
					h: 3,
					i: '1',
					children: [{ x: 0, y: 0, w: 2, h: 2, i: '0' }]
				}
			]
		};
	},

	watch: {
		// 'formData.wareareaDataSelectIds': {
		// 	handler(newVal, oldVal) {
		// 		if (newVal.length > 0 && newVal.length !== null) {
		// 			this.warehouseDisabled = true;
		// 		}
		// 	},
		// 	// immediate: true, // 立刻处理,进入页面就触发
		// 	deep: true // 需要深度监听 属性的变化
		// }
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.equipmentList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.equipmentList();
		}
	},

	computed: {},

	mounted() {
		// 阻止火狐浏览器默认的拖拽搜索行为
		document.body.ondrop = event => {
			event.preventDefault();
			event.stopPropagation();
		};
		// 搜索 获取列表
		this.search();
	},

	methods: {
		// deal 库位图里库位id
		dealpositions() {
			const arr = this.list;
			const arrSub = [];
			const positions = [];
			if (arr.length > 0) {
				arr.forEach(item => {
					arrSub.push(...item.items);
				});
				arrSub.forEach(item => {
					positions.push(item.id);
				});

				this.form.positions = positions.join();
				this.form.graph = JSON.stringify(arr);
			} else {
				this.$message.warning('请联系管理员');
			}
		},
		// 添加
		formSubmit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					// 调接口提交
					this.dealpositions();
					if (this.form.id) {
						this.update();
					} else {
						this.add();
					}
				} else {
					// 调接口提交
				}
			});
		},
		// 添加
		async add() {
			const res = await positonGraphAddService(this.form);
			this.$message.success('添加成功');
			this.isShowAddGraph = false;
			this.list = [];

			this.formData = {
				// 选中的值
				wareareaDataSelectIds: [],
				// 禁止点击的值 穿梭框
				wareareaDataDisabledIds: [],
				// 穿梭框列表 查询id
				warehouseId: null
			};
			this.search();
		},
		// 删除
		deleteById(row) {
			this.$confirm('此操删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await positonGraphDelService({ id: row.id });
					this.$message({ type: 'success', message: '删除成功!' });
					this.search();
				})
				.catch(() => {
					this.$message({ type: 'info', message: '已取消删除' });
				});
		},
		// id获取详情
		async getbykey(id) {
			const res = await positonGraphGetbykeyService({ id: id });
			if (res.id) {
				this.form.id = res.id;
				this.form.name = res.name;
				this.list = JSON.parse(res.graph);
			}
		},
		// 通过库位id获取库位图
		getbyposition() {},
		// 翻页
		async search() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await positonGraphSearchService(dataJson);
			this.listMixin(res);
		},
		// 更新
		async update() {
			const res = await positonGraphUpdateService(this.form);
			console.log(res);
			this.$message.success('添加成功');
			this.isShowAddGraph = false;
			this.list = [];

			this.formData = {
				// 选中的值
				wareareaDataSelectIds: [],
				// 禁止点击的值 穿梭框
				wareareaDataDisabledIds: [],
				// 穿梭框列表 查询id
				warehouseId: null
			};

			this.search();
		},
		test() {
			console.log(this.list);
		},
		// move回调方法
		onMove(e) {
			// 不允许拖拽
			console.log(e);
			if (!e.draggedContext.element) return false;
			return true;
		},
		showAddGraph(row) {
			this.formData = {
				// 选中的值
				wareareaDataSelectIds: [],
				// 禁止点击的值 穿梭框
				wareareaDataDisabledIds: [],
				// 穿梭框列表 查询id
				warehouseId: null
			};
			this.list = [];
			this.flagEdit = null;
			this.formData.wareareaDataSelectIds = [];
			if (row.id) {
				this.form.id = row.id;
				this.form.name = row.name;
				this.list = JSON.parse(row.graph);
				this.getbykey(row.id);
			}
			this.isShowAddGraph = true;
		},
		showAddOne(index) {
			const disabledArr = [];
			const disabledArrIds = [];
			// TODELETE
			this.wareareaData.forEach(item => {
				item.disabled = false;
			});
			this.formData.wareareaDataDisabledIds.length > 0 &&
				this.wareareaData.forEach(item => {
					this.formData.wareareaDataDisabledIds.forEach(item1 => {
						if (item.id == item1.id) {
							item.disabled = true;
						}
					});
				});
			this.flagEdit = index;
			// index 编辑时的数组索引
			if (this.flagEdit >= 0) {
				const ids = [];
				let arr = [];
				this.list.forEach(item => {
					if (item.index == this.flagEdit) {
						arr = item.items;
					}
				});
				arr.forEach(item => {
					ids.push(item.id);
				});
				this.formData.wareareaDataSelectIds = ids;
			} else {
				this.formData.warehouseId = null;
				this.wareareaData = [];
				console.log('**************************************');
			}
			// 禁止已经选择过的
			this.list &&
				this.list.forEach(item => {
					disabledArr.push(...item.items);
					this.formData.warehouseId = item.warehouseId;
					return false;
				});
			this.list &&
				disabledArr.forEach(item => {
					disabledArrIds.push(item.id);
				});
			this.formData.wareareaDataDisabledIds = disabledArr;
			this.warehouseonlymyself();
			// 只能从一个仓库中选择
			this.list.length > 0 &&
				this.list[0].items.forEach(item => {
					this.formData.warehouseId = item.warehouseId;
					return false;
				});
			if (this.formData.warehouseId) {
				this.warehouseChangeHandler();
			}
			this.isShowAddOne = true;
		},
		warehouseChangeHandler() {
			this.wareareaList();
			this.warehouseDisabled = true;
		},
		getwareareaDataSelectList() {
			this.listTemp = [];
			console.log(this.formData);
			const list = [];
			const ids = this.formData.wareareaDataSelectIds;
			for (let index = 0; index < ids.length; index++) {
				this.wareareaData.forEach(item => {
					if (ids[index] === item.id) {
						list.push(item);
					}
				});
			}
			this.listTemp = list;
			console.log(list);
		},
		addOneLine() {
			this.isShowAddOne = false;
			if (this.listTemp == 0) {
				return false;
			}
			const arr = this.list;
			// 编辑 // 新增
			if (this.flagEdit >= 0) {
				arr.forEach((item, index) => {
					if (item.index === this.flagEdit) {
						arr[index].items = this.listTemp;
						// arr[index] = { index: this.flagEdit, items: this.listTemp };
					}
				});
			} else {
				arr.push({
					index: Math.ceil(Math.random() * 10000),
					warehouseId: this.formData.warehouseId,
					items: this.listTemp
				});
			}
			this.list = arr;
			// TODELETE
			this.formData.wareareaDataSelectIds = [];
			this.flagEdit = null;
			this.listTemp = [];
		},
		delOneLine(i) {
			this.$confirm('此操删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const arr = this.list;
					console.log(arr);
					arr.forEach((item, index) => {
						if (item.index === i) {
							arr.splice(index, 1);
						}
					});

					console.log(arr);
					this.list = arr;
					this.$message({ type: 'success', message: '删除成功!' });
				})
				.catch(() => {
					this.$message({ type: 'info', message: '已取消删除' });
				});
		},
		async wareareaList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				warehouseId: this.formData.warehouseId
			};
			const res = await wareareaListService(dataJson);
			this.wareareaData = res.records;
			this.wareareaData.forEach(item => {
				item.disabled = false;
			});
			this.formData.wareareaDataDisabledIds.length > 0 &&
				this.wareareaData.forEach(item => {
					this.formData.wareareaDataDisabledIds.forEach(item1 => {
						if (item.id == item1.id) {
							item.disabled = true;
						}
					});
				});

			this.formData.wareareaDataSelectIds &&
				this.formData.wareareaDataSelectIds.forEach(item1 => {
					this.wareareaData.forEach(item => {
						if (item.id == item1) {
							item.disabled = false;
						}
					});
				});
		},
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		}
	}
};
</script>
<style lang='less' scoped>
.chosen {
	background-color: rgba(0, 0, 0, 0.5) !important;
	color: #000000;
	font-weight: bold;
	border-radius: 5px;
}

.ghost {
	background-color: rgba(0, 0, 0, 0.1) !important;
	color: #000000;
	font-weight: bold;
	border-radius: 5px;
}
.smallBlock {
	cursor: pointer;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #999;
	border-radius: 5px;
	box-shadow: 0 0 5px #999;
	text-align: center;
}
.smallBlock:hover {
	background-color: rgba(0, 0, 0, 0.1) !important;
	color: #000000;
	font-weight: bold;
}
</style>
