<template>
	<div class="QRCode">
		<div class="print_container">
			<div style="width: 160px;">
				<div id="printMe1" style="width: 240px">
					<div v-for="item in selectData_p" :key="item.id"
						style=" width:100%; overflow: hidden; padding:30px 12px 12px 20px; clear: both; position: relative; page-break-after: always;">
						<!-- <qriously :value="`yg:deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}`" :size="90" /> -->
						<!-- <qriously
							:value="`${$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}&test=aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa`"
							:size="140"
							:level="'L'"
							style="margin-top: -30px; margin-left: -10px; padding-top: 12px;"
						/> -->
						<qriously :value="item.valueUrl" :size="140" :level="'L'"
							style="margin-top: -30px; margin-left: -10px; padding-top: 12px;" />

						<ul style="position: absolute; left: 150px; top: 14px; width: 180px; font-size: 14px">
							<!-- <li style="clear: both; padding-top: 0px; overflow:hidden;line-height: 1.5em;">
								<span style="float: left; width: 100%; font-size: 17px;font-weight: bold;">{{ item.devInfoName || '微波治疗仪' }}</span>
							</li> -->
							<li style="clear: both;line-height: 1.5em;">
								<span style="float: left; width: 100%; font-size: 13px">{{ item.devInfoDepartmentName
								}}</span>
							</li>
							<li style="clear: both; padding-top: 0px; overflow:hidden;line-height: 1.5em;">
								<span style="float: left; width: 100%; font-size: 14px">{{ item.devInfoNns1 }}</span>
							</li>
							<li style="position:absolute; bottom:-90px;left:0; font-size:12px;width:120px;"><pre>{{valueCom}}</pre></li>
						</ul>
						<p style="
							width: 100%;
							line-height: 20px;
							font-size: 17px;
							overflow: hidden ;
							font-weight: 700;
							padding-left: 0px;
							margin-left:-8px;
						">
							<!-- <b
							style="
								background: rgba(0,0,0,1);
								color: #ffffff !important;;
								padding: 0 5px;
								border-radius: 2px;
								display: inline-block;
								margin-right: 4px;
								vertical-align: middle;
								font-size: 14px;
							"
						>N</b>-->
							{{ item.devInfoName }}
						</p>
						<!-- <b>
							<i style="font-size: 10px; padding-left: 0px">YGONGYUN.COM</i>
						</b> -->
					</div>
				</div>
			</div>
			<div class="footer">
				<span class="dialog-footer">
					<el-button @click="printCancel">取 消</el-button>
					<span v-print="'#printMe1'">
						<el-button type="primary" class="pringQR">确 定</el-button>
					</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['selectData_p', 'valueCom'],
	data() {
		return {};
	},
	mounted() { },
	watch: {
		selectData_p: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					newVal.forEach(item => {
						let tempStr = '&test=aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';

						let valueUrl = `${this.$envConfig.OfficialWebsite}bx?deviceId=${item.devInfoId}&hospitalId=${item.affiliatedHospitalId}`;
						tempStr = tempStr.substring(0, 78 - valueUrl.length);
						valueUrl = `${valueUrl}${tempStr}`;
						// {{ $envConfig.OfficialWebsite + 'bx?deviceId=' + item.devInfoId + '&hospitalId=' + item.affiliatedHospitalId }}
						item.valueUrl = valueUrl;
					});
				}
			},
			immediate: true
		}
	},
	methods: {
		printCancel() {
			this.$emit('update:isShowPrintDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
// 二维码
.QRCode {
	position: relative;
	margin-left: -20px;
	margin-top: -16px;

	.print_container {
		max-height: 500px;
		overflow-y: auto;
	}

	.footer {
		position: absolute;
		bottom: 0;
		right: 0;
		// height: 52px;
		width: 100%;
		text-align: right;
		// line-height: 52px;
		z-index: 10;

		span {
			padding-left: 10px;
		}
	}
}
</style>
