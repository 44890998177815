<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchForm"
			:model="searchForm"
			label-width="60px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="标题">
				<el-input v-model="searchForm.title" placeholder="请输入标题" />
			</el-form-item>
			<el-form-item label="申请人">
				<el-input v-model="searchForm.applicantName" placeholder="请输入申请人" />
			</el-form-item>
			<el-form-item label="审批人">
				<el-input v-model="searchForm.approverName" placeholder="请输审批人" />
			</el-form-item>
			<el-form-item label="日期">
				<el-date-picker
					v-model="dataArr"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>
		<el-button
			class="filter-item"
			size="mini"
			icon="el-icon-refresh-left"
			@click="resetForm('searchForm')"
		>
			重置
		</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage } from '@/util/htools.web';

export default {
	data() {
		return {
			searchForm: {},
			dataArr: []
		};
	},
	created() {},
	watch: {
		// dataArr: {
		// 	handler(newValue, o) {
		// 		if (newValue) {
		// 			console.log(newValue);
		// 			console.log(o);
		// 			[this.searchForm.applyDatebegin, this.searchForm.applyDateend] = newValue;
		// 			// this.searchForm.applyDateend = newValue[1];
		// 		}
		// 	}
		// }
	},
	computed: {},
	methods: {
		initSearchForm() {
			this.dataArr = [];
			this.factorySearchForm = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetForm(formName) {
			this.initSearchForm();
			this.searchHandler();
		},
		searchHandler() {
			if (this.dataArr) {
				[this.searchForm.applyDatebegin, this.searchForm.applyDateend] = this.dataArr;
			} else {
				[this.searchForm.applyDatebegin, this.searchForm.applyDateend] = [null, null];
			}
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
