<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="88px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="库房名称">
				<el-input v-model="formData.name" size="mini" clearable placeholder="请输入库房名称"></el-input>
			</el-form-item>
            <el-form-item label="编码">
				<el-input v-model="formData.code" size="mini" clearable placeholder="请输入编码"></el-input>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>

export default {
	data() {
		return {
			// 搜索
			formData: {
				name: '',
				code: ''
			}
		};
	},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
