<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<CurrentStockSearchForm
				@searchForm="searchFormHandler"
				ref="refCurrentStockSearchForm"
			></CurrentStockSearchForm>
			<div style="margin-bottom: 10px; margin-top: 10px; display:flex; ">
				<!-- 功能按钮 -->
				<el-button type="success" @click="addStockHandler">添加入库</el-button>
				<el-button
					type="primary"
					@click="checkRemoveStock(selectData, true)"
					v-authorize="{ name: 'update', type: 'currentStock', id: 'btn-update-row' }"
					:disabled="!selectData.length"
				>
					批量移库
				</el-button>
				<el-button
					type="primary"
					@click="reduceStockListHandler(selectData, true)"
					:disabled="!selectData.length"
				>
					批量出库
				</el-button>
				<el-button type="primary" @click="showPrintData">打印</el-button>
				<el-button type="primary" @click="download">导出</el-button>
				<el-popover
					placement="bottom"
					trigger="click"
					v-model="showPopover"
					style="flex:1;text-align:right;"
				>
					<div class="nativesList" v-if="formnatives.alternativesList.length > 0">
						<el-form ref="formnatives" :label-position="'left'" :model="formnatives">
							<div
								v-for="(item, index) in formnatives.alternativesList"
								:key="item.id"
								class="baseInfo"
							>
								<div class="leftCheck">
									<el-form-item
										:prop="'alternativesList.' + index + '.checked'"
										style="margin-bottom:0;"
									>
										<el-checkbox
											v-model="item.checked"
											@change="chooseNatives(item)"
										></el-checkbox>
									</el-form-item>
								</div>
								<div class="rightData">
									<h2 class="code">
										[{{ item.stockCode }}] {{ item.goodsName }}
									</h2>
									<table class="nativesTable">
										<!-- <tr>
                    <th>库房</th>
                    <th>库位</th>
                    <th>数量</th>
                    <th></th>
                  </tr> -->
										<tr>
											<td>{{ item.warehouseName }}</td>
											<td>{{ item.positionName }}</td>
											<td>
												<el-form-item
													:prop="'alternativesList.' + index + '.cnt'"
													style="margin-bottom:0;"
												>
													<el-input-number
														size="mini"
														v-model="item.cnt"
														:precision="0"
														:min="1"
														:max="
															formnatives.alternativesList[index]
																.cntAll
														"
														clearable
														placeholder="请输入数量"
														style="width:100px;"
													></el-input-number>
												</el-form-item>
											</td>
											<td>
												<el-button
													type="danger"
													icon="el-icon-delete"
													circle
													@click="deleteNativesItem(item)"
												></el-button>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</el-form>
						<div style="width:100%; height:50px;"></div>
						<div class="doSome">
							<el-button
								type="primary"
								@click="showTransferDialog"
								:disabled="selectNativesData.length <= 0"
							>
								申请调拨出库
							</el-button>
							<el-button
								type="success"
								@click="showSaleDialog"
								:disabled="selectNativesData.length <= 0"
							>
								申请销售出库
							</el-button>
							<el-button
								type="success"
								@click="showBorrowDialog"
								:disabled="selectNativesData.length != 1"
							>
								领用借用
							</el-button>
							<el-button type="danger" @click="clearNatives">清空</el-button>
						</div>
					</div>
					<div v-else style="text-align:center;">暂无数据</div>
					<el-badge
						:slot="'reference'"
						:value="formnatives.alternativesList.length"
						type="primary"
					>
						<el-button>备选</el-button>
					</el-badge>
				</el-popover>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			@select="handleSelect"
			@select-all="handleSelectAll"
			@selection-change="handleSelectionChange"
		>
			<el-table-column label="库存编码">
				<template slot-scope="scope">
					{{ scope.row.stockCode }}
				</template>
			</el-table-column>
			<el-table-column label="物料">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsid,
							goodsName: scope.row.goodsName
						}"
						:key="scope.row.id"
					></MaterialTooltip>
				</template>
			</el-table-column>
			<el-table-column label="仓库">
				<template slot-scope="scope">
					{{ scope.row.warehouseName }}
				</template>
			</el-table-column>
			<el-table-column label="库位">
				<template slot-scope="scope">
					{{ scope.row.positionName }}
				</template>
			</el-table-column>
			<el-table-column label="数量">
				<template slot-scope="scope">
					{{ scope.row.cnt }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="360">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'currentStock', id: 'btn-update-row' }"
						@click="addAlternatives(scope.row)"
						size="mini"
						v-if="scope.row.cnt !== 0 && scope.row.imanage"
					>
						加入备选
					</el-button>
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'currentStock', id: 'btn-update-row' }"
						@click="reduceStockHandler(scope.row)"
						size="mini"
						v-if="scope.row.cnt !== 0 && scope.row.imanage"
					>
						出库
					</el-button>
					<el-button
						type="primary"
						v-authorize="{ name: 'update', type: 'currentStock', id: 'btn-update-row' }"
						size="mini"
						@click="checkRemoveStock(scope.row, false)"
						v-if="scope.row.cnt !== 0 && scope.row.imanage"
					>
						移动
					</el-button>
					<el-button
						:disabled="!scope.row.positonGraphId"
						type="primary"
						size="mini"
						@click="checkStockDetail(scope.row)"
					>
						库位详情
					</el-button>
					<!-- <el-button
						id="btn-remove-row"
						type="success"
						v-authorize="{ name: 'remove', type: 'currentStock', id: 'btn-remove-row' }"
						@click="historyHandler(scope.row)"
						size="mini"
					>
						历史
					</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="85%">
			<CurrentStockAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></CurrentStockAddForm>
		</Dialog>
		<Dialog title="物料出库" :visible.sync="isShowReduceStockDialog" width="54%">
			<CurrentStockReduceForm
				v-if="isShowReduceStockDialog"
				:isShowReduceStockDialog_p.sync="isShowReduceStockDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></CurrentStockReduceForm>
		</Dialog>
		<Dialog title="物料批量出库" :visible.sync="isShowReduceStockListDialog" width="54%">
			<CurrentStockReduceListForm
				v-if="isShowReduceStockListDialog"
				:isShowReduceStockDialog_p.sync="isShowReduceStockListDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></CurrentStockReduceListForm>
		</Dialog>
		<Dialog title="移动" :visible.sync="isShowRemoveStockDialog" width="54%">
			<CurrentStockRemoveForm
				v-if="isShowRemoveStockDialog"
				:isShowRemoveStockDialog_p.sync="isShowRemoveStockDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:isList_p.sync="isList"
			></CurrentStockRemoveForm>
		</Dialog>
		<Dialog title="库存历史" :visible.sync="isShowStockHistoryDialog" width="60%">
			<CurrentStockHistoryForm></CurrentStockHistoryForm>
		</Dialog>
		<Dialog title="库位详情" :visible.sync="isShowStockDetail" width="80%">
			<CurrentStockDetail
				:isShowStockDetailDialog_p.sync="isShowStockDetail"
				:stockInId="stockInId"
				:positonGraphId="positonGraphId"
			/>
		</Dialog>
		<Dialog title="申请调拨出库" :visible.sync="isShowTransferialog" width="70%">
			<TransferAddForm
				v-if="isShowTransferialog"
				:isShowAEDialog_p.sync="isShowTransferialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectNativesData_p="selectNativesData"
			></TransferAddForm>
		</Dialog>
		<Dialog title="申请销售出库" :visible.sync="isShowSaleDialog" width="70%">
			<SaleApplicationAddForm
				v-if="isShowSaleDialog"
				:isShowAEDialog_p.sync="isShowSaleDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectNativesData_p="selectNativesData"
			></SaleApplicationAddForm>
		</Dialog>
		<Dialog title="领用借用" :visible.sync="isShowBorrowDialog" width="50%">
			<BorrowAddForm
				v-if="isShowBorrowDialog"
				:isShowAEDialog_p.sync="isShowBorrowDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectNativesData_p="selectNativesData"
			></BorrowAddForm>
		</Dialog>

		<el-dialog
			title="打印"
			:visible.sync="printData"
			width="40%"
			:close-on-click-modal="false"
			append-to-body
		>
			<div class="print-data">
				<div id="printData">
					<table id="myTable" class="table" border="0">
						<thead>
							<tr class="tTitle" style="border:0px solid #ffffff;">
								<th colspan="6" style=" text-align: center;">当前库存列表</th>
							</tr>
							<tr class="tpHeadTr">
								<th>库存编码</th>
								<th>物料</th>
								<th>仓库</th>
								<th>库位</th>
								<th>数量</th>
							</tr>
						</thead>
						<tbody>
							<tr
								class="tpBodyTr"
								v-for="(item, index) in [...printDataList]"
								:key="index"
							>
								<td>{{ item.stockCode }}</td>
								<td>{{ item.goodsName }}</td>
								<td>{{ item.warehouseName }}</td>
								<td>{{ item.positionName }}</td>
								<td>{{ item.cnt }}</td>
							</tr>
						</tbody>
						<!-- <tfoot>
              <tr style="border:0px solid #ffffff; border-top:1px solid #333333;">
                <td colspan="6" style="padding-left:0;padding-right:0;">
                </td>
              </tr>
            </tfoot> -->
					</table>
					<div class="h10"></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printData = false">取 消</el-button>
				<el-button v-print="printObj" type="primary">打印</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { currentStockListService } from '@s/spareParts/CurrentStockService';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import CurrentStockAddForm from '@f/spareParts/currentStock/CurrentStockAdd.form';
import CurrentStockReduceForm from '@f/spareParts/currentStock/CurrentStockReduce.form';
import CurrentStockReduceListForm from '@f/spareParts/currentStock/CurrentStockReduceList.form';
import CurrentStockRemoveForm from '@f/spareParts/currentStock/CurrentStockRemove.form';
import CurrentStockSearchForm from '@f/spareParts/currentStock/CurrentStockSearch.form';
import CurrentStockHistoryForm from '@f/spareParts/currentStock/CurrentStockHistory.form';
import CurrentStockDetail from '@f/spareParts/currentStock/CurrentStockDetail';
import TransferAddForm from '@f/spareParts/transfer/TransferAdd.form';
import SaleApplicationAddForm from '@f/spareParts/saleApplication/SaleApplicationAdd.form';
import BorrowAddForm from '@f/spareParts/borrow/BorrowAdd.form';
import MaterialTooltip from '@/components//MaterialTooltip';

export default {
	name: 'CurrentStock',
	mixins: [ListMixin],
	components: {
		Table,
		Pagination,
		Dialog,
		CurrentStockAddForm,
		CurrentStockSearchForm,
		CurrentStockReduceForm,
		CurrentStockReduceListForm,
		CurrentStockRemoveForm,
		CurrentStockHistoryForm,
		CurrentStockDetail,
		TransferAddForm,
		SaleApplicationAddForm,
		BorrowAddForm,
		MaterialTooltip
	},
	data() {
		const cntValidat = (rule, value, callback) => {
			// this.formData1?.items.forEach(item => {
			// 	if (item.id === this.cntItem.id) {
			// 		if (value && value > item.cnt) {
			// 			callback(new Error('数量不能大于库存'));
			// 		}
			// 	}
			// });
			callback();
		};
		return {
			// 打印
			printData: false,
			printDataList: [],
			printObj: {
				id: 'printData',
				popTitle: '',
				popFooter: ''
			},
			tableDownData: [],
			selectNativesData: [],
			isShowTransferialog: false,
			isShowSaleDialog: false,
			isShowBorrowDialog: false,
			// 表格
			tableColumn: [],
			formnatives: { alternativesList: [] },
			showPopover: false,
			formnativesRules: {
				cnt: [
					{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					},
					{
						validator: cntValidat,
						trigger: 'blur'
					}
				]
			},
			delTips: '',
			isShowReduceStockDialog: false,
			isShowReduceStockListDialog: false,
			isShowRemoveStockDialog: false,
			isShowStockHistoryDialog: false,
			historyData: [],
			isShowStockDetail: false,
			stockInId: null,
			positonGraphId: null,
			// 是否批量移库
			isList: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '添加入库' : '';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.currentStockList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.currentStockList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.currentStockList();
			}
		},
		isShowReduceStockDialog() {
			if (!this.isShowReduceStockDialog) {
				this.selectData = [];
			}
		},
		isShowRemoveStockDialog() {
			if (!this.isShowRemoveStockDialog && !this.isList) {
				this.selectData = [];
			}
		}
	},
	mounted() {
		this.currentStockList();
	},
	methods: {
		async currentStockList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await currentStockListService(dataJson);
			this.listMixin(res);
		},
		async getDownList() {
			this.searchForm = { ...this.$refs.refCurrentStockSearchForm.formData };
			const dataJson = {
				pageNum: 1,
				pageSize: 9999,
				...this.searchForm
			};
			const res = await currentStockListService(dataJson);
			this.tableDownData = res?.records || [];
		},
		async currentStockListPrint() {
			this.printDataList = [];
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 999
			};
			const res = await currentStockListService(dataJson);
			this.printDataList = res.records;

			if (this.printDataList.length === 0) {
				this.$message.warning('没有数据！');
				return false;
			}
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.currentStockList();
		},
		addStockHandler() {
			this.isShowAEDialog = true;
		},
		// 加入备选
		addAlternatives(row) {
			row.cntAll = row.cnt;
			let flag = true;
			this.$message.closeAll();
			this.$message.success('数量 +1');
			this.formnatives.alternativesList.forEach((item, index, arr) => {
				if (item.id == row.id) {
					// item.cnt = item.cnt + 1;
					arr[index].cnt = item.cnt + 1;
					// console.log(item.cnt);
					// console.log(item.cntAll);
					// console.log(item.cnt <= item.cntAll);
					if (item.cnt <= item.cntAll) {
						this.$message.closeAll();
						this.$message.success('数量 +1');
					} else {
						this.$message.closeAll();
						this.$message.warning('已存在');
					}
					flag = false;
					return flag;
				}
			});
			flag && this.formnatives.alternativesList.unshift({ ...row, cnt: 1 });
		},
		// 选择备选数据
		chooseNatives(row) {
			let flag = true;
			let arr = [];
			arr = this.formnatives.alternativesList.filter(item => {
				return item.checked;
			});
			flag = arr.every(item => {
				return item.warehouseName == row.warehouseName;
			});
			if (!flag) {
				this.$message.warning('不能选择不同库房!');
				row.checked = false;
				arr = this.formnatives.alternativesList.filter(item => {
					return item.checked;
				});
			}
			this.selectNativesData = arr;
		},
		deleteNativesItem(row) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.formnatives.alternativesList.forEach((item, index, arr) => {
						if (item.id == row.id) {
							arr.splice(index, 1);
						}
					});
					this.showPopover = true;
				})
				.catch(error => {
					console.log(error);
					this.showPopover = true;
				});
		},
		// 申请调拨出库
		showTransferDialog() {
			this.isShowTransferialog = true;
		},
		// 申请销售出库
		showSaleDialog() {
			this.isShowSaleDialog = true;
		},
		// 领用借用
		showBorrowDialog() {
			this.isShowBorrowDialog = true;
		},
		// 清空备选
		clearNatives() {
			this.$confirm('确认清空吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.formnatives.alternativesList = [];
				})
				.catch(error => {
					console.log(error);
					this.showPopover = true;
				});
		},
		reduceStockHandler(row) {
			this.selectData = [row];
			this.isShowReduceStockDialog = true;
		},
		reduceStockListHandler() {
			let isAddSameWarehouse = false;
			const flag = this.selectData[0];
			this.selectData.forEach(item => {
				if (item.warehouseName !== flag.warehouseName) {
					isAddSameWarehouse = true;
				}
			});
			if (isAddSameWarehouse) {
				this.$message.closeAll();
				this.$message.warning('不可以选择不在同一仓库的！');
				return;
			}
			this.isShowReduceStockListDialog = true;
		},
		// 打印
		async showPrintData() {
			this.currentStockListPrint();
			this.printData = true;
		},
		async download() {
			await this.getDownList();

			if (this.tableDownData.length == 0 || !this.tableDownData) {
				this.$message.warning('没有需要导出的数据');
				return false;
			}

			const tHeader = ['库存编码', '物料', '仓库', '库位', '数量', '均价'];

			const filterVal = ['stockCode', 'goodsName', 'warehouseName', 'positionName', 'cnt', 'avgPrice'];
			const data = this.formatJson(filterVal, this.tableDownData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;

			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `当前库存${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.currentStockList();
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		// 移库 批量移库
		checkRemoveStock(row, isList) {
			let isAddSameWarehouse = false;
			this.isList = isList;
			if (isList) {
				this.selectData = [...row];
				const flag = this.selectData[0];
				this.selectData.forEach(item => {
					if (item.warehouseName !== flag.warehouseName) {
						isAddSameWarehouse = true;
					}
				});
			} else {
				this.selectData = [row];
			}
			if (isAddSameWarehouse) {
				this.$message.closeAll();
				this.$message.warning('不可以选择不在同一仓库的！');
				return;
			}
			this.isShowRemoveStockDialog = true;
		},
		historyHandler() {
			this.isShowStockHistoryDialog = true;
		},
		checkStockDetail(data) {
			this.stockInId = data.positionId;
			this.positonGraphId = data.positonGraphId;
			this.isShowStockDetail = true;
		},
		// 表格切换选中问题 不能选不在一个仓库的 暂时没用
		handleSelect(selection, row) {
			// const flag = selection[0];
			// selection.forEach(row => {
			// 	if (row.warehouseName !== flag.warehouseName) {
			// 		this.$nextTick(() => {
			// 			this.$message.warning('不可以选择不在同一仓库的！');
			// 			this.$refs.tableDom.$children[0].toggleRowSelection(row);
			// 		});
			// 	}
			// });
		},
		handleSelectAll(selection) {
			// const flag = selection[0];
			// selection.forEach(row => {
			// 	if (row.warehouseName !== flag.warehouseName) {
			// 		this.$nextTick(() => {
			// 			this.$message.closeAll();
			// 			this.$message.warning('不可以选择不在同一仓库的！');
			// 			this.$refs.tableDom.$children[0].clearSelection();
			// 		});
			// 	}
			// });
		},
		handleSelectionChange(a) {
			// console.log('-------------------');
			// console.log(a);
			// return;
			// this.multipleSelection = [];
		}
	}
};
</script>

<style lang="less" scoped>
// --------------------------------Print E--------------------------------
/* 表格初始化 bootstrap */
table {
	// border-collapse: collapse;
	// width: 100%;
	/* border: 1px solid #333333; */
}

.table {
	border-collapse: collapse;
	width: 100%;
	border-collapse: collapse !important;
}

.table td,
.table th {
	background-color: #fff !important;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}

.table-bordered th,
.table-bordered td {
	/* border: 1px solid #dee2e6 !important; */
}

.table-dark {
	color: inherit;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
	border-color: #dee2e6;
}

.table .thead-dark th {
	color: inherit;
	border-color: #dee2e6;
}

.print-data {
	/* border: 1px solid #333; */
	max-height: 600px;
	overflow-y: auto;
}

.print-data .print-row:last-child {
	border-bottom: none;
}

.tTitle th {
	text-align: left;
	font-size: 18px;
	line-height: 40rpx;
}

/* 表格打印 保留表头底部 */
.table thead {
	display: table-header-group;
}

.table tfoot {
	display: table-footer-group;
}

.table tbody {
	display: table-row-group;
}

#printData {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#myTable {
	width: 97%;
}

.h10 {
	width: 100%;
	height: 10px;
}

.tpHeadTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpHeadTr th {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}

.tpBodyTr {
	border-left: 1px solid #333333;
	border-top: 1px solid #333333;
}

.tpBodyTr td {
	border-right: 1px solid #333333 !important;
	border-bottom: 1px solid #333333 !important;
}

// --------------------------------Print E--------------------------------
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.baseInfo {
	border-bottom: 2px solid #cccccc;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}

.baseInfo .leftCheck {
	width: 30px;
}

.nativesList {
	max-height: 500px;
	overflow-y: auto;
	// position: relative;
}

.doSome {
	width: 90%;
	margin-left: 20px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 20px;
	left: 0;
	z-index: 9999;
}

.doSome .el-button {
	padding-left: 30px;
	padding-right: 30px;
}

.nativesTable {
}

.nativesTable tr th,
.nativesTable tr td {
	min-width: 100px;
	padding: 5px 10px;
}
</style>
