<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" :rules="formRules" label-width="120px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="出入库类型" prop="inoutType">
				<el-select v-model="formData.inoutType" placeholder="请选择出入库类型">
					<el-option v-for="(item, index) in issueReceiptTypeData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="时间">
				<el-date-picker
					v-model="dateRange"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					clearable
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="库房" prop="warehouseid">
				<el-select v-model="formData.warehouseid" placeholder="请选择库房">
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
		<el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downLoading" @click.enter="download">导出</el-button>
		<el-button class="filter-item" size="mini" type="primary" icon="el-icon-printer" @click.enter="printData">打印</el-button>
	</div>
</template>

<script>
import { issueReceiptTypeListService } from '@s/spareParts/CurrentStockService';
import { warehouseAllListService, warehouseSelfListService } from '@s/spareParts/WarehouseService';
import { hgetStorage } from '../../../util/htools.web';

export default {
	props: ['downLoading'],
	data() {
		return {
			// 搜索
			formData: {
				inoutType: '',
				begin: '',
				end: '',
				warehouseid: ''
			},
			formRules: {
				inoutType: [{ required: true, message: '请选择出入库类型', trigger: 'change' }],
				warehouseid: [{ required: true, message: '请选择库房', trigger: 'change' }]
			},
			dateRange: [],
			issueReceiptTypeData: [],
			warehouseData: []
		};
	},
	computed: {
		begin() {
			return this.dateRange ? this.dateRange[0] : '';
		},
		end() {
			return this.dateRange ? this.dateRange[1] : '';
		}
	},
	created() {
		this.issueReceiptTypeList();
		const roles = hgetStorage('roleIds');
		setTimeout(() => {
			const factoryRole = this.$envConfig.factoryAdminRoleId;
			const hospitalRole = this.$envConfig.hospitalAdminRoleId;
			if (roles.indexOf(factoryRole) > -1 || roles.indexOf(hospitalRole) > -1) {
				this.warehouseAllList();
			} else {
				this.warehouseSelfList();
			}
		}, 3000);
	},
	methods: {
		async issueReceiptTypeList() {
			const dataJson = {};
			const res = await issueReceiptTypeListService(dataJson);
			this.issueReceiptTypeData = res;
		},
		async warehouseAllList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseAllListService(dataJson);
			this.warehouseData = res.records;
		},
		async warehouseSelfList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseSelfListService(dataJson);
			this.warehouseData = res.records;
		},
		searchHandler() {
			this.$refs.formEle.validate(valid => {
				if (valid) {
					this.formData.begin = this.begin;
					this.formData.end = this.end;
					this.$emit('searchForm', this.formData);
					const curType = this.issueReceiptTypeData.filter(i => {
						return i.id === this.formData.inoutType;
					})[0];
					const curWarehouse = this.warehouseData.filter(i => {
						return i.id === this.formData.warehouseid;
					})[0];
					const data = {
						curType: curType,
						curWarehouse: curWarehouse
					};
					this.$emit('searchData', data);
					// 重置选中的数据 通知父组件表格
					this.$emit('resetSelectionData', data);
				}
			});
		},
		download() {
			this.$emit('download');
		},
		printData() {
			this.$emit('showPrintData');
		}
	}
};
</script>

<style lang="less" scoped></style>
