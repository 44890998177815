import { hgetRequest, hpostRequest } from '@u/htools.axios';
import { changeTreeDataToChildren } from '@u/htools.tree';

/*
 *@Description: 物料类型列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:06
 */
export const materialTypeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goodstype/all', dataJson);
			resolve(changeTreeDataToChildren(res));
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料类型添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:19
 */
export const materialTypeAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				pid: '',
				...data
			};
			const res = await hpostRequest('hwms/goodstype/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料类型编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:39:49
 */
export const materialTypeEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				pid: '',
				...data
			};
			const res = await hpostRequest('hwms/goodstype/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料类型删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 13:22:39
 */
export const materialTypeDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hgetRequest('hwms/goodstype/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 物料类型详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-11 10:36:01
 */
export const materialTypeDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/goodstype/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default materialTypeListService;
