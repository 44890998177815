import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 医院列表 合同 同过企业id获取所有绑定医院(分页查询)
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const getcompanysignListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/gethospitalsignpage', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 企业 同过企业id获取所有绑定医院
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const getHospitalByUserIdListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest('hbtyong_3/hospital/hospitalByUserId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院添加 合同绑定维修企业 医院和维修公司签订合同(绑定)
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const signAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/sign', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院详情 查看维修公司信息 查询被绑定到医院的员工
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const getHospitalUserDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/getHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 企业 同过企业id获取所有绑定医院
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const gethospitalsignListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devBaseId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3_3/contract/gethospitalsign', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 企业 将企业员工绑定到医院
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */

export const addHospitalUserService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/addHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 查询医院绑定维修公司的列表
export const organizationAllQueryListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/company/organizationAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 没用的东西
export const contractDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/addHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const contractDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong_3/contract/addHospitalUser', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 三方下医院列表修改服务状态
 *@ClassAuthor: Happy zxh
 *@Date: 2023-07-24 14:30:17
 */
export const changeservicestateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hosid: '',
				state: '',
				...data
			};
			const res = await hgetRequest('hbtyong_3/contract/changeservicestate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default getcompanysignListService;
