<template>
  <!-- 保养提醒 -->
  <div class="app-container">
    <MeteringRemindSearchForm @searchForm="searchFormHandler"></MeteringRemindSearchForm>
    <div style="height:12px"></div>
    <!--表格渲染-->
    <Table ref="tableDom" :data.sync="tableData" :isHideCheckbox_p="true">
      <!-- <el-table-column label="医院名称" prop="affiliatedHospitalName"></el-table-column>
      <el-table-column label="设备名称" prop="devInfoName"></el-table-column>
      <el-table-column label="设备类型" prop="devInfoTypeName"></el-table-column>
      <el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
      <el-table-column label="所在科室" prop="devInfoDepartmentName"></el-table-column>
      <el-table-column label="保养周期(月)" prop="upkeepPeriod"></el-table-column>
      <el-table-column label="下次保养时间" prop="nextUpkeepTime"></el-table-column> -->
      <el-table-column label="ID" prop="devInfoId"></el-table-column>
      <el-table-column label="设备编号" prop="devInfoNumber"></el-table-column>
      <el-table-column label="设备名称" prop="devInfoName"></el-table-column>
      <el-table-column label="设备类型" prop="devInfoTypeName"></el-table-column>
      <el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
      <el-table-column label="所在科室" prop="devInfoDepartmentName"></el-table-column>
      <el-table-column label="检验周期" prop="upkeepPeriod"></el-table-column>
      <el-table-column label="下次检验时间" prop="nextUpkeepTime"></el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { patrolRemindListService } from '@s/hospital/patrolRemind/patrolRemindService';
import MeteringRemindSearchForm from '@f/hospital/patrolRemind/PatrolRemindSearch.form';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ListMixin from '@m/List.mixin';

export default {
	name: 'MeteringRemind',
	mixins: [ListMixin],
	components: {
		MeteringRemindSearchForm,
		Table,
		Pagination
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.patrolRemindList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.patrolRemindList();
		}
	},
	data() {
		return {};
	},
	methods: {
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.patrolRemindList();
		},
		async patrolRemindList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				searchType: 2,
				// hid: localStorage.getItem('organization'),
				...this.searchForm
			};
			const res = await patrolRemindListService(dataJson);
			this.listMixin(res);
		}
	},
	mounted() {
		this.patrolRemindList();
	}
};
</script>

<style></style>
