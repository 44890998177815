<template>
  <div class="head-serch">
    <el-form class="form" ref="searchFormDom" :model="searchForm" label-width="70px" @submit.native.prevent @keyup.enter.native="searchHandler">
      <el-form-item label="合同名称">
        <el-input v-model="searchForm.name" placeholder="请输入合同名称" :clearable="true" />
      </el-form-item>
      <el-form-item label="甲方">
        <el-input v-model="searchForm.leftName" placeholder="请输入甲方" :clearable="true" />
      </el-form-item>
      <el-form-item label="乙方">
        <el-input v-model="searchForm.rightName" placeholder="请输入乙方" :clearable="true" />
      </el-form-item>
      <el-form-item label="日期" prop="dataArr">
        <el-date-picker v-model="searchForm.dataArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-button class="filter-item" type="success" icon="el-icon-search" @click="searchHandler">搜索</el-button>
  </div>
</template>

<script>
export default {
	data() {
		return {
			searchForm: {
				name: null, // 合同名称
				leftName: null, // 甲方
				rightName: null, // 乙方
				beginDate: null, // 开始时间
				endDate: null // 结束时间
			}
		};
	},
	created() {},
	methods: {
		searchHandler() {
			if (this.searchForm?.dataArr?.length === 2) {
				[this.searchForm.beginDate, this.searchForm.endDate] = this.searchForm.dataArr;
			}
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
