<template>
  <el-row>
    <el-col :span="24">
      <!-- <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px"> -->
      <!-- </el-form> -->
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="70px" :inline="true">
        <el-form-item label="出入库类型" prop="inoutType" label-width="100px">
          <el-select v-model="formData.inoutType" @change="issueReceiptChangeHandler" clearable placeholder="请选择出入库类型">
            <el-option :disabled="item.disabled" v-for="(item, index) in issueReceiptTypeData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关单号" prop="aboutId" label-width="100px">
          <el-select v-model="formData.aboutId" @change="aboutIdChange" clearable placeholder="请选择相关单号">
            <el-option v-for="(item, index) in absoutNumberData" :key="index" :label="item.name" :value="item.id">
            </el-option>
            <p slot="empty" class="faseAddGood">没有符合条件的申请单，请检查物料是否正确。</p>
          </el-select>
        </el-form-item>
        <div></div>
        <div v-for="(item, index) in formData.items" :key="item.id">
          <el-form-item :label="'名称' + (index+1)">
            <el-input v-model="item.goodsName" :disabled="true" />
          </el-form-item>
          <el-form-item label="数量" :rules="formRules.cnt" :prop="'items.' + index + '.cnt1'">
            <el-input-number v-model="item.cnt1" @blur="getCntItme($event,item)" controls-position="right" :precision="0" :min="1" :max="formData1.items[index].cnt" clearable placeholder="请输入数量"></el-input-number>
          </el-form-item>

          <el-form-item label="价格" v-if="formData.inoutType !== 6 && formData.inoutType !== 9" :rules="formRules.price" :prop="'items.' + index + '.price1'">
            <el-input-number v-model="item.price1" controls-position="right" :precision="2" :min="0" clearable placeholder="请输入价格"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-link type="danger" @click="delItem(item)" style="padding:0 10px; font-weight:bold;" :underline="false"><i class="el-icon-close"></i></el-link>
            <el-link type="warning" v-if="item.isShowWarn" :title="item.isShowWarn" style="padding:0 10px; font-weight:bold;" :underline="false"><i class="el-icon-warning-outline"></i></el-link>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="danger" @click="delItem(item)"><i class="el-icon-error"></i></el-button>
            <el-button type="warning" v-if="item.isShowWarn" :title="item.isShowWarn"><i class="el-icon-warning-outline"></i></el-button>
          </el-form-item> -->
        </div>
      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import {
	issueReceiptTypeListService,
	currentStockReduceService1
} from '@s/spareParts/CurrentStockService';
import {
	checkStockSelectListService,
	listundoneListService
} from '@s/spareParts/CheckStockService';
import {
	transferListService,
	transferfindForOutService,
	transferDetailService
} from '@s/spareParts/TransferService';
// import { saleApplicationListService } from '@s/spareParts/SaleApplicationService';
import {
	saleApplicationFindRequestByGoodsService,
	saleApplicationDetailService,
	findSellRequestForOutService
} from '@s/spareParts/SaleApplicationService';
import { materialDetailService } from '@s/spareParts/MaterialService';
import { htimeFormat } from '../../../util/htools.web';

export default {
	props: ['selectData_p', 'selectSearchData_p'],
	components: {},
	data() {
		const cntValidat = (rule, value, callback) => {
			this.formData1?.items.forEach(item => {
				if (item.id === this.cntItem.id) {
					if (value && value > item.cnt) {
						callback(new Error('出库数量不能大于库存'));
					}
				}
			});
			callback();
		};
		return {
			editId: -1,
			issueReceiptTypeData: [],
			absoutNumberData: [],
			// 弹窗
			formData: {
				inoutType: '',
				aboutId: '',
				items: []
			},
			formData1: {
				inoutType: '',
				aboutId: '',
				items: []
			},
			cntItem: {},
			goodsId: [],
			flagOnly: true,
			formRules: {
				inoutType: [
					{
						required: true,
						message: '请选择出入库',
						trigger: 'change'
					}
				],
				aboutId: [
					{
						required: true,
						message: '请选择相关单号',
						trigger: 'change'
					}
				],
				cnt: [
					{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					},
					{
						validator: cntValidat,
						trigger: 'blur'
					}
				],
				price: [
					{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}
				]
			},

			materialDetailData: '',
			apiMaterialDetailData: ''
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.formData.items = JSON.parse(JSON.stringify([...newValue]));
					this.formData.items.forEach(item => {
						item.cntGood = item.cnt;
						item.cnt = undefined;
						item.price = undefined;
					});
					// this.formData.items = Object.assign(newValue);
					if (this.flagOnly) {
						this.formData1.items = JSON.parse(JSON.stringify([...newValue]));
						this.flagOnly = false;
					}
				}
				if (this.formData.items.length === 0) {
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				}
			},
			deep: true,
			immediate: true
		},
		selectSearchData_p: {
			handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.inoutType = newValue.inoutType;
				this.formData.code = newValue.code;
				this.issueReceiptChangeHandler();

				setTimeout(() => {
					this.absoutNumberData.forEach(item => {
						if (item.name.indexOf(newValue.code) !== -1) {
							this.formData.aboutId = item.id;
						}
					});
					console.log('this.formData 调拨的相关单号。。。');
					console.log(this.formData);
					this.formData.aboutId && this.aboutIdChange();
				}, 500);
			},
			deep: true,
			immediate: true
		}
		// 'formData.items': {
		// 	async handler(newValue) {
		// 		if (newValue.length === 0)
		// 	},
		// 	deep: true,
		// 	immediate: true
		// }
	},
	created() {
		this.issueReceiptTypeList();
		// this.formData.items.forEach((item, index, arr) => {
		// 	this.$set(arr, index, { cnt1: undefined, ...item });
		// 	this.$set(arr, index, { price1: undefined, ...item });
		// });
	},
	methods: {
		// 获取当前的数量 验证用
		getCntItme(e, item) {
			this.cntItem = item;
		},
		delItem(row) {
			this.$confirm(
				`确认删除<span style="color:#F56C6C;">${row.goodsName}</span>吗？`,
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					dangerouslyUseHTMLString: true,
					type: 'warning'
				}
			)
				.then(() => {
					this.formData.items.forEach((item, index, arr) => {
						if (item.goodsid === row.goodsid) {
							arr.splice(index, 1);
						}
					});

					if (this.formData.items.length != 1) {
						this.issueReceiptTypeData.forEach(item => {
							if (item.id == 9) {
								item.disabled = true;
							}
						});
					} else {
						this.issueReceiptTypeData.forEach(item => {
							item.disabled = false;
						});
					}
				})
				.catch(error => {
					console.log(error);
				});
		},

		aboutIdChange() {
			if (this.formData.inoutType === 8) {
				// 盘盈出库
				this.formData.batchCode = `pd${this.formData.aboutId}`;
			}
			if (this.formData.inoutType === 6) {
				this.showDialogEditHandler2({ aboutId: this.formData.aboutId });
				// let row = {};
				// 调拨出库
				this.formData.batchCode = `db${this.formData.aboutId}`;
				// this.absoutNumberData?.forEach((item, index, arr) => {
				// 	if (Number(item.id) === Number(this.formData.aboutId)) {
				// 		row = arr[index];
				// 	}
				// });
				// // 两者取最小值，自动填入
				// const data2 = row.goodsAllCnt - row.goodsOutCnt;
				// if (data2 >= 1) {
				// 	this.formData.items.forEach(item => {
				// 		item.cnt1 = item.cnt > data2 ? data2 : item.cnt;
				// 		item.price1 = item.price > data2 ? data2 : item.price;
				// 	});
				// }
				// const item = this.absoutNumberData.filter(item => {
				// 	return item.id === this.formData.aboutId;
				// })[0];
				// console.log(item);
				// this.formData.cnt = item.cnt;
			}

			if (this.formData.inoutType === 5) {
				this.showDialogEditHandler5({ aboutId: this.formData.aboutId });
				// 销售出库
				const item = this.absoutNumberData.filter(item => {
					return item.id === this.formData.aboutId;
				})[0];
				this.formData.batchCode = item.code;
			}
			if (this.formData.inoutType === 9) {
				let row = {};
				// 调拨出库
				this.formData.batchCode = `db${this.formData.aboutId}`;
				this.absoutNumberData.forEach((item, index, arr) => {
					if (Number(item.id) === Number(this.formData.aboutId)) {
						row = arr[index];
					}
				});
				// 两者取最小值，自动填入 TODO
				const data1 = this.formData.items[0]?.cntGood;
				const data2 = row.cnt - row.doneCnt;
				const data3 = data1 > data2 ? data2 : data1;
				this.formData.items.forEach((item, index, arr) => {
					this.$set(arr, index, { ...item, cnt1: data3 });
				});
			}
		},
		// 销售出库详情
		async showDialogEditHandler5(obj) {
			const dataJson = {
				id: obj.aboutId,
				withdel: true
			};
			const res = await saleApplicationDetailService(dataJson);
			const itemsTempArr = res.items;
			for (let index = 0; index < itemsTempArr.length; index++) {
				this.formData.items.forEach((item1, index1, arr1) => {
					if (item1.goodsid === itemsTempArr[index].goodsId) {
						const a1 = itemsTempArr[index].cnt - itemsTempArr[index].donecnt;
						const a2 = itemsTempArr[index].price;
						this.$set(arr1, index1, { ...item1, cnt1: a1, price1: a2 });
					}
				});
			}

			// this.formData.items = Object.assign(this.formData.items);
		},

		// 根据id获取调拨单详情数据
		async showDialogEditHandler2(obj) {
			const dataJson = {
				id: obj.aboutId,
				withdel: true
			};
			const res = await transferDetailService(dataJson);
			const itemsTempArr = res.items;

			for (let index = 0; index < itemsTempArr.length; index++) {
				this.formData.items.forEach((item1, index1, arr1) => {
					if (item1.goodsid === itemsTempArr[index].goodsId) {
						// item1.cnt1 =
						// 	itemsTempArr[index].cnt > item1.cnt
						// 		? item1.cnt
						// 		: itemsTempArr[index].cnt;
						const a1 = itemsTempArr[index].cnt - itemsTempArr[index].outdonecnt;
						this.$set(arr1, index1, { ...item1, cnt1: a1 });
					}
				});
			}
		},
		// 出入库类型 默认下拉
		async issueReceiptTypeList() {
			const dataJson = {
				type: 2,
				...this.searchForm
			};
			const res = await issueReceiptTypeListService(dataJson);
			console.log('res 批量领用借用');
			console.log(res);
			console.log(this.formData);
			this.issueReceiptTypeData = res;
			if (this.formData.items.length != 1) {
				this.issueReceiptTypeData.forEach(item => {
					if (item.id == 9) {
						item.disabled = true;
					}
				});
			} else {
				this.issueReceiptTypeData.forEach(item => {
					item.disabled = false;
				});
			}
		},
		issueReceiptChangeHandler() {
			console.log('this.formData.inoutType');
			console.log(this.formData.inoutType);

			this.formData.aboutId = null;
			this.absoutNumberData = [];
			this.formData.items.forEach(item => {
				item.cnt1 = undefined;
				item.price1 = undefined;
			});
			if (this.formData.inoutType === 8) {
				// 盘盈出库 不变
				this.checkStockSelectList();
			}
			if (this.formData.inoutType === 6) {
				// 调拨出库
				this.transferList();
			}
			if (this.formData.inoutType === 5) {
				// 销售出库
				this.saleApplicationList();
			}
			if (this.formData.inoutType === 9) {
				// 借用领用出库
				this.listundoneList();
			}
		},
		async listundoneList() {
			const dataJson = {
				goodsid: this.formData.items[0].goodsid
			};
			const res = await listundoneListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		async checkStockSelectList() {
			const dataJson = {};
			const res = await checkStockSelectListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = res;
		},
		async transferList() {
			this.goodsId = [];
			this.formData?.items.forEach(item => {
				this.goodsId.push(item.goodsid);
			});
			const res = await transferfindForOutService({ goodsId: this.goodsId });
			console.log('res调拨change ++++++++');
			console.log(res);
			res.forEach(item => {
				item.name = `${item.fromWareHouseName}-${item.toWarehouseName}(${htimeFormat(
					item.requestTime
				)})`;
			});
			this.absoutNumberData = res;
		},
		async saleApplicationList() {
			this.goodsId = [];
			this.formData?.items.forEach(item => {
				this.goodsId.push(item.goodsid);
			});
			const res = await findSellRequestForOutService({ goodsId: this.goodsId });
			res.forEach(item => {
				item.name = `${item.code}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = res;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					// TODELETE
					if (this.editId === -1) {
						// const res1 = await currentStockReduceService1(this.formData);
						await currentStockReduceService1(this.formData)
							.then(res => {
								this.$emit('update:isRefreshList_p', true);
								this.$emit('update:isRefreshDetail_p', true);
								this.formCancel();
							})
							.catch(err => {
								let id = err.msg;
								id = id.replace(')', '');
								id = id.split('(');
								id = id[id.length - 1];
								this.formData?.items.forEach(item => {
									if (item.goodsid == id) {
										item.isShowWarn = err.msg;
									}
								});
								this.$forceUpdate();
								console.log(this.formData?.items);
							});
					}
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowReduceStockDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
.contentText {
	padding-left: 10px;
}
.contentText li {
	line-height: 24px;
}

.faseAddGood {
	padding: 10px;
	color: #cccccc;
}
</style>
