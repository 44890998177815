<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="typeEle" :model="typeForm" :rules="typeRules" label-width="66px">
				<!-- <el-form-item label="类型" prop="orgid">
					<el-select v-model="typeForm.orgid" class="select-item" placeholder="请选择" collapse-tags clearable>
						<el-option :value="0" label="预设分类"></el-option>
                        <el-option :value="0" label="组织分类"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="名称" prop="name">
					<el-input v-model="typeForm.name" placeholder="请输入名称" />
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="typeFormCancel">取 消</el-button>
				<el-button type="primary" @click="typeFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { typeAddService, typeEditService } from '@s/doc/TypeService';

export default {
	props: ['selectData_p'],
	data() {
		return {
			editId: -1,
			typeForm: {
				name: ''
			},
			typeRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				orgid: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.typeForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		typeFormSubmit() {
			this.$refs.typeEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await typeAddService(this.typeForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.typeForm
						};
						await typeEditService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.typeFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		typeFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
