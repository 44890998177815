import { render, staticRenderFns } from "./DevBaseSearch.form.vue?vue&type=template&id=5aefb43c&scoped=true"
import script from "./DevBaseSearch.form.vue?vue&type=script&lang=js"
export * from "./DevBaseSearch.form.vue?vue&type=script&lang=js"
import style0 from "./DevBaseSearch.form.vue?vue&type=style&index=0&id=5aefb43c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aefb43c",
  null
  
)

export default component.exports