<template>
	<div>
		<div class="search_box">
			<el-date-picker
				v-model="date"
				type="datetimerange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button
				type="success"
				class="search_button"
				@click="searchSubmit"
				size="mini"
			>
				搜索
			</el-button>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>报修数量和维修费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairCntAndPriceByMonth({
									index: 1,
									title: '报修数量和维修费用',
									options: options1
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts1" class="echart_box"></div>
					</div>
					<div class="date_tag">
						<el-button
							:class="{ activeDate: dateType == 0 }"
							size="mini"
							round
							@click="dateSwitch(0)"
						>
							年
						</el-button>
						<el-button
							:class="{ activeDate: dateType == 1 }"
							size="mini"
							round
							@click="dateSwitch(1)"
						>
							季
						</el-button>
						<el-button
							:class="{ activeDate: dateType == 2 }"
							size="mini"
							round
							@click="dateSwitch(2)"
						>
							月
						</el-button>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>维修完成数量和费用</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDoneCntAndPriceByMonth({
									index: 8,
									title: '维修完成数量和费用',
									options: options8
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(8)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts8" class="echart_box"></div>
					</div>
					<div class="date_tag">
						<el-button
							:class="{ activeDate: dateEndType == 0 }"
							size="mini"
							round
							@click="dateEndSwitch(0)"
						>
							年
						</el-button>
						<el-button
							:class="{ activeDate: dateEndType == 1 }"
							size="mini"
							round
							@click="dateEndSwitch(1)"
						>
							季
						</el-button>
						<el-button
							:class="{ activeDate: dateEndType == 2 }"
							size="mini"
							round
							@click="dateEndSwitch(2)"
						>
							月
						</el-button>
					</div>
				</el-card>
			</el-col>
			<!-- </el-row> -->

			<!-- 20220915 签单时间图表 -->
			<!-- <el-row :gutter="24" style="margin-bottom: 20px"> -->
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>维修完成数量和费用（签单时间）</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairSignCntAndPriceByMonth({
									index: 9,
									title: '维修完成数量和费用（签单时间）',
									options: options9
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(9)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts9" class="echart_box"></div>
					</div>
					<div class="date_tag">
						<el-button
							:class="{ activeDate: dateEndTypeSigntime == 0 }"
							size="mini"
							round
							@click="dateEndSwitchSigntime(0)"
						>
							年
						</el-button>
						<el-button
							:class="{ activeDate: dateEndTypeSigntime == 1 }"
							size="mini"
							round
							@click="dateEndSwitchSigntime(1)"
						>
							季
						</el-button>
						<el-button
							:class="{ activeDate: dateEndTypeSigntime == 2 }"
							size="mini"
							round
							@click="dateEndSwitchSigntime(2)"
						>
							月
						</el-button>
					</div>
				</el-card>
			</el-col>
			<!-- </el-row> -->

			<!-- <el-row :gutter="24" style="margin-bottom: 20px"> -->
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>报修次数(次)</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDeviceCntTop({
									index: 2,
									title: '报修次数(次)',
									options: options2
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(2)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts2" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>报修费用(元)</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDevicePriceTop({
									index: 3,
									title: '报修费用(元)',
									options: options3
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(3)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts3" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<!-- </el-row> -->
			<!-- <el-row :gutter="24" style="margin-bottom: 20px"> -->
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>类型报修次数(次)</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDeviceTypeCntTop({
									index: 4,
									title: '类型报修次数(次)',
									options: options4
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(4)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts4" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>类型报修费用(元)</span>

						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDeviceTypePriceTop({
									index: 5,
									title: '类型报修费用(元)',
									options: options5
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(5)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts5" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<!-- </el-row> -->
			<!-- <el-row :gutter="24"> -->
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>报修次数及占比</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairCnt({
									index: 6,
									title: '报修次数及占比',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(6)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts6"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>费用及占比</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelDevPrice({
									index: 7,
									title: '费用及占比',
									options: options7
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(7)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts7"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>科室维修次数与金额</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelCntPrice({
									index: 10,
									title: '科室维修次数与金额',
									options: options10
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(10)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts10"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>巡检月度</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelallXJBYcnt({
									index: 11,
									title: '巡检月度',
									options: options11
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(11)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts11"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>保养月度</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelallXJBYcnt({
									index: 12,
									title: '保养月度',
									options: options12
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(12)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts12"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>当月巡检完成率</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(13)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts13"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>当月保养完成率</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(14)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div
							id="echarts14"
							class="echart_box"
							style="height: 430px"
						></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	allHospitalRepairService,
	repairDeviceCntTopService,
	repairDevicePriceTopService,
	repairDeviceTypeCntTopService,
	repairDeviceTypePriceTopService,
	repairCntPriceByDepartmentService,
	repairDevCntbyDepartmentService,
	allDepartmentDeviceNumberService,
	repairCntPriceByMonthService,
	RepairSignCntAndPriceByMonthService,
	// 巡检保养月度
	allcntService
} from '@s/hospital/StatisticsService';
import Dialog from '@c/ui/Dialog';
import { htimeFormat, hgetStorage, hdateInMonth } from '@u/htools.web';

export default {
	components: {
		Dialog
	},
	data() {
		return {
			date: '',
			dateType: 2, // 月
			dateEndType: 2, // 月
			dateEndTypeSigntime: 2, // 月
			dialogTitle: '',
			options1: {
				// title: {
				// 	text: '报修数量和维修费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '报修数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修应收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options2: {
				// title: {
				// 	text: '报修次数(次)'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}: ${arg[0].value[0]}次`;
					}
				},
				dataset: {
					source: [
						['amount', 'product'],
						[58212, 'Matcha Latte'],
						[78254, 'Milk Tea'],
						[41032, 'Cheese Cocoa'],
						[12755, 'Cheese Brownie'],
						[20145, 'Matcha Cocoa'],
						[79146, 'Tea'],
						[91852, 'Orange Juice'],
						[101852, 'Lemon Juice'],
						[20112, 'Walnut Brownie']
					]
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#FFC655'
							}
						}
					}
				]
			},
			options3: {
				// title: {
				// 	text: '报修费用(元)'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}: ${arg[0].value[0]}元`;
					}
				},
				dataset: {
					source: [
						['amount', 'product'],
						[58212, 'Matcha Latte'],
						[78254, 'Milk Tea'],
						[41032, 'Cheese Cocoa'],
						[12755, 'Cheese Brownie'],
						[20145, 'Matcha Cocoa'],
						[79146, 'Tea'],
						[91852, 'Orange Juice'],
						[101852, 'Lemon Juice'],
						[20112, 'Walnut Brownie']
					]
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#00BC88'
							}
						}
					}
				]
			},
			options4: {
				// title: {
				// 	text: '类型报修次数(次)'
				// },
				tooltip: {
					trigger: 'axis',
					position: pos => {
						// 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
						return pos;
					},
					formatter(arg) {
						const a = `${arg[0].name}: ${arg[0].value[0]}次`;
						let b = a.split(''); // 输出为["1","2","3","4","5","6"]
						const c = 26;
						if (b.length > c * 3) {
							b.splice(c * 3, 0, '<br />');
						}
						if (b.length > c * 2) {
							b.splice(c * 2, 0, '<br />');
						}
						if (b.length > c) {
							b.splice(c, 0, '<br />');
						}
						b = b.join('');

						return b;
					}
				},
				dataset: {
					source: [
						['amount', 'product'],
						[58212, 'Matcha Latte'],
						[78254, 'Milk Tea'],
						[41032, 'Cheese Cocoa'],
						[12755, 'Cheese Brownie'],
						[20145, 'Matcha Cocoa'],
						[79146, 'Tea'],
						[91852, 'Orange Juice'],
						[101852, 'Lemon Juice'],
						[20112, 'Walnut Brownie']
					]
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#22A4FF'
							}
						}
					}
				]
			},
			options5: {
				// title: {
				// 	text: '类型报修费用(元)'
				// },
				tooltip: {
					trigger: 'axis',
					position: pos => {
						// 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
						return pos;
					},
					formatter(arg) {
						const a = `${arg[0].name}: ${arg[0].value[0]}元`;
						let b = a.split(''); // 输出为["1","2","3","4","5","6"]
						const c = 26;
						if (b.length > c * 3) {
							b.splice(c * 3, 0, '<br />');
						}
						if (b.length > c * 2) {
							b.splice(c * 2, 0, '<br />');
						}
						if (b.length > c) {
							b.splice(c, 0, '<br />');
						}
						b = b.join('');

						return b;
					}
				},

				dataset: {
					source: [
						['amount', 'product'],
						[58212, 'Matcha Latte'],
						[78254, 'Milk Tea'],
						[41032, 'Cheese Cocoa'],
						[12755, 'Cheese Brownie'],
						[20145, 'Matcha Cocoa'],
						[79146, 'Tea'],
						[91852, 'Orange Juice'],
						[101852, 'Lemon Juice'],
						[20112, 'Walnut Brownie']
					]
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							// x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#FF5C5C'
							}
						}
					}
				]
			},
			options6: {
				// title: {
				// 	text: '报修次数及占比'
				// },
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					formatter(arg) {
						const label = `${arg[0].axisValueLabel}<br>${arg[0].seriesName}: ${arg[0].value}<br>`;
						return `${label}${arg[1].seriesName}: ${arg[1].value}<br>${arg[2].seriesName}: ${arg[2].value}%`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '13%',
					containLabel: true
				},

				dataZoom: [
					{
						startValue: '',
						endValue: ''
					},
					{
						type: 'inside'
					}
				],
				xAxis: [
					{
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisLabel: {
							interval: 0, // 坐标轴刻度标签的显示间隔
							rotate: 0 // 标签倾斜的角度
						}
					}
				],
				yAxis: [
					{
						type: 'value'
					},
					{
						type: 'value',
						max: '100'
					}
				],
				series: [
					{
						name: '未报修数量',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: [220, 182, 191, 234, 290, 330, 310]
					},
					{
						name: '已维修数量',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: [120, 132, 101, 134, 90, 230, 210]
					},
					{
						name: '已报修比例',
						type: 'line',
						emphasis: {
							focus: 'series'
						},
						yAxisIndex: '1',
						data: [220, 182, 191, 234, 290, 330, 310]
					}
				]
			},
			options7: {
				// title: {
				// 	text: '费用及占比'
				// },
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					formatter(arg) {
						const label = `${arg[0].axisValueLabel}<br>${arg[0].seriesName}: ${arg[0].value}<br>`;
						return `${label}${arg[1].seriesName}: ${arg[1].value}<br>${arg[2]?.seriesName}: ${arg[2]?.value}%`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '13%',
					containLabel: true
				},
				dataZoom: [
					{
						startValue: '',
						endValue: ''
					},
					{
						type: 'inside'
					}
				],
				xAxis: [
					{
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisLabel: {
							interval: 0, // 坐标轴刻度标签的显示间隔
							rotate: 0 // 标签倾斜的角度
						}
					}
				],
				yAxis: [
					{
						type: 'value'
					},
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '设备价值',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: [220, 182, 191, 234, 290, 330, 310]
					},
					{
						name: '维修费用',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: [120, 132, 101, 134, 90, 230, 210]
					},
					{
						name: '维修费用占比',
						type: 'line',
						emphasis: {
							focus: 'series'
						},
						yAxisIndex: '1',
						data: [220, 182, 191, 234, 290, 330, 310]
					}
				]
			},
			options8: {
				// title: {
				// 	text: '维修完成数量和费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '维修完成数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修完成费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options9: {
				// title: {
				// 	text: '维修完成数量和费用（签单时间）'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '维修完成数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修完成费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options10: {
				// title: {
				// 	text: '科室维修次数与金额'
				// },
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '13%',
					containLabel: true
				},
				dataZoom: [
					{
						startValue: '',
						endValue: ''
					},
					{
						type: 'inside'
					}
				],
				xAxis: [
					{
						type: 'category',
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						axisLabel: {
							interval: 0, // 坐标轴刻度标签的显示间隔
							rotate: 0 // 标签倾斜的角度
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '维修次数',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19]
						// itemStyle: {
						// 	normal: {
						// 		color: '#4BC2DF'
						// 	}
						// }
					},
					{
						name: '维修金额',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19]
						// itemStyle: {
						// 	normal: {
						// 		color: '#C51CDD'
						// 	}
						// }
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options11: {
				// title: {
				// 	text: '月度巡检'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}次`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '13%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '月度巡检',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								// color: '#4BC2DF'
							}
						}
					}
				]
			},
			options12: {
				// title: {
				// 	text: '月度保养'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}次`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '13%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '月度保养',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								// color: '#4BC2DF'
							}
						}
					}
				]
			},
			options13: {
				// title: {
				// 	text: '当月巡检完成率'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}次`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						type: 'pie',
						radius: '50%',
						// left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options14: {
				// title: {
				// 	text: '当月保养完成率'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}次`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						type: 'pie',
						radius: '50%',
						// left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			isShowBigEcharts: false
		};
	},
	async mounted() {
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.date = [
			`${curYear - 10}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];

		await this.allDepartmentDeviceList();

		this.allHospitalRepairList(this.dateType);
		this.repairDoneCntPriceByMonthList(this.dateEndType);
		this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
		this.repairDeviceCntTop();
		this.repairDevicePriceTop();
		this.repairDeviceTypeCntTop();
		this.repairDeviceTypePriceTop();

		this.repairCntPriceByDepartmentList();
		// 巡检保养月度
		this.allcunList();
		// // 科室维修次数与金额
		// this.repairCntPriceByDepartment();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '报修数量和维修费用';
					this.myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '报修次数(次)';
					delete this.options2.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '报修费用(元)';
					delete this.options3.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options3);
				}
				if (index === 4) {
					this.dialogTitle = '类型报修次数(次)';
					delete this.options4.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '类型报修费用(元)';
					delete this.options5.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options5);
				}
				if (index === 6) {
					this.dialogTitle = '报修次数及占比';
					this.myChart.setOption(this.options6);
				}
				if (index === 7) {
					this.dialogTitle = '费用及占比';
					this.myChart.setOption(this.options7);
				}
				if (index === 8) {
					this.dialogTitle = '维修完成数量和费用';
					this.myChart.setOption(this.options8);
				}
				if (index === 9) {
					this.dialogTitle = '维修完成数量和费用（签单时间）';
					this.myChart.setOption(this.options9);
				}
				if (index === 10) {
					this.dialogTitle = '科室维修次数与金额';
					this.myChart.setOption(this.options10);
				}
				if (index === 11) {
					this.dialogTitle = '巡检月度';
					this.myChart.setOption(this.options11);
				}
				if (index === 12) {
					this.dialogTitle = '保养月度';
					this.myChart.setOption(this.options12);
				}
				if (index === 13) {
					this.dialogTitle = '当月巡检完成率';
					this.myChart.setOption(this.options13);
				}
				if (index === 14) {
					this.dialogTitle = '当月保养完成率';
					this.myChart.setOption(this.options14);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		dateEndSwitchSigntime(dateType) {
			this.dateEndTypeSigntime = dateType;
			this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
		},
		async searchSubmit() {
			await this.allDepartmentDeviceList();
			this.allHospitalRepairList(this.dateType);
			this.repairDoneCntPriceByMonthList(this.dateEndType);
			this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
			this.repairDeviceCntTop();
			this.repairDevicePriceTop();
			this.repairDeviceTypeCntTop();
			this.repairDeviceTypePriceTop();

			this.repairCntPriceByDepartmentList();
			// 巡检保养月度
			this.allcunList();
			// // 科室维修次数与金额
			// this.repairCntPriceByDepartment();
		},
		async allDepartmentDeviceList() {
			const dataJson = {
				// begin: htimeFormat(this.date[0]),
				begin: '1900-01-01 00:00:00',
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allDepartmentDeviceNumberService(dataJson);
			this.repairCntPriceByDepartmentList(res, true);
		},
		async repairDevCntbyDepartmentList(res) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res1 = await repairDevCntbyDepartmentService(dataJson);
			res.forEach(item1 => {
				res1.forEach(item2 => {
					if (item1.dev_info_department_id == item2.departid) {
						item1.doneCnt = item2.cnt;
						item1.ingCnt = item1.cnt - item2.cnt;
						item1.completionRate = `${(
							(item1.doneCnt / item1.cnt) *
							100
						).toFixed(2)} %`;
					}
				});
			});
			this.tableDownDataRepairCnt = res || [];
			this.echartsInit6(res, res1);
		},
		async repairCntPriceByDepartmentList(res1, isRepair) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await repairCntPriceByDepartmentService(dataJson);

			res.forEach(item1 => {
				res1?.forEach(item2 => {
					if (item1.dev_info_department_id == item2.departid) {
						item1.devPrice = item2.price;
					}
				});
			});
			console.log('res');
			console.log(res);
			this.tableDownDataDevPrice = res || [];
			console.log(res1);
			if (isRepair) {
				this.echartsInit7(res1, res);
				this.echartsInit10(res);
			}
			if (!isRepair) {
				this.repairDevCntbyDepartmentList(res);
			}
		},
		async allHospitalRepairList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = (await allHospitalRepairService(dataJson)) || [];

			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairCntAndPriceByMonth = res;
			this.echartsInit1(res);
		},
		async repairDoneCntPriceByMonthList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = (await repairCntPriceByMonthService(dataJson)) || [];

			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairDoneCntAndPriceByMonth = res;
			this.echartsInit8(res);
		},
		async RepairSignCntAndPriceByMonthService(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res =
				(await RepairSignCntAndPriceByMonthService(dataJson)) || [];
			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairSignCntAndPriceByMonth = res;
			this.echartsInit9(res);
		},
		async repairDeviceCntTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceCntTopService(dataJson);
			this.tableDownDataRepairDeviceCntTop = res || [];
			this.echartsInit2(res);
		},
		// async repairCntPriceByDepartment() {
		// 	const dataJson = {
		// 		begin: htimeFormat(this.date[0]),
		// 		departid: 0,
		// 		end: htimeFormat(this.date[1]),
		// 		hosid: hgetStorage('organization'),
		// 		top: 10
		// 	};
		// 	const res = await repairCntPriceByDepartmentService(dataJson);
		// 	this.echartsInit10(res);
		// },
		async repairDevicePriceTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDevicePriceTopService(dataJson);

			this.tableDownDataRepairDevicePriceTop = res;
			this.echartsInit3(res);
		},
		async repairDeviceTypeCntTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceTypeCntTopService(dataJson);
			this.tableDownDataRepairDeviceTypeCntTop = res || [];

			this.echartsInit4(res);
		},
		async repairDeviceTypePriceTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceTypePriceTopService(dataJson);
			this.tableDownDataRepairDeviceTypePriceTop = res || [];
			this.echartsInit5(res);
		},
		async allcunList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await allcntService(dataJson);
			this.tableDownDataallXJBYcnt = res.list || [];
			this.echartsInit11(res);
			this.echartsInit12(res);
			this.echartsInit13(res);
			this.echartsInit14(res);
		},
		echartsInit1(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options1.xAxis[0].data = xAxisArr;
			this.options1.series[0].data = barArr;
			this.options1.series[1].data = lineArr;
			this.options1.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);

			myChart.on('click', params => {
				this.$router.push(
					`/hospital/repair?date=${
						xAxisArr[params.dataIndex]
					}&dateType=${this.dateType}`
				);
			});
		},
		echartsInit2(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.cnt, item.deviceName]);
			});
			this.options2.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.repairPrice, item.deviceName]);
			});
			this.options3.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts3');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit4(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.cnt, item.deviceType]);
			});
			this.options4.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts4');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.repairPrice, item.deviceType]);
			});
			this.options5.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts5');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit6(res, res1) {
			const xAxisArr = [];
			const departmentId = [];
			const lineArr = [];
			const barAllArr = [];
			const barHavedArr = [];
			// 全部报修
			res.forEach(item => {
				xAxisArr.push(item.department);
				departmentId.push(item.dev_info_department_id);
				barAllArr.push(item.cnt);
				// lineArr.push(item.price);
			});
			// 报修过的
			departmentId.forEach(departid => {
				res1.forEach(item => {
					if (departid === item.departid) {
						barHavedArr.push(item.cnt);
					}
				});
			});
			const noRepairArr = [];
			barAllArr.forEach((item, index) => {
				noRepairArr.push(item - barHavedArr[index]);
				lineArr.push(((barHavedArr[index] / item) * 100).toFixed(2));
			});
			this.options6.xAxis[0].data = xAxisArr;
			this.options6.series[0].data = noRepairArr;
			this.options6.series[1].data = barHavedArr;
			this.options6.series[2].data = lineArr;
			const firstItem = xAxisArr[0];
			const endItem = xAxisArr[6]
				? xAxisArr[6]
				: xAxisArr[xAxisArr.length - 1];
			this.options6.dataZoom[0].startValue = firstItem;
			this.options6.dataZoom[0].endValue = endItem;
			const chartDom = document.getElementById('echarts6');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		echartsInit7(res, res1) {
			const xAxisArr = [];
			const departmentId = [];
			const lineArr = [];
			const barAllArr = [];
			const barHavedArr = [];
			// 设备价值
			res1.forEach(item => {
				xAxisArr.push(item.department);
				departmentId.push(item.dev_info_department_id);
				barAllArr.push(item.price);
				// lineArr.push(item.price);
				barHavedArr.push(0);
			});
			// 维修价值
			departmentId.forEach((departid, departindex) => {
				res1.forEach((item, index) => {
					if (departid === item.dev_info_department_id) {
						// barHavedArr.push(item.price);
						barHavedArr[departindex] = item.realprice;
					}
				});
			});
			barAllArr.forEach((item, index) => {
				if (item == 0) {
					lineArr.push(0);
				} else {
					lineArr.push(
						((barHavedArr[index] / item) * 100).toFixed(2)
					);
				}
			});
			this.options7.xAxis[0].data = xAxisArr;
			this.options7.series[0].data = barAllArr;
			this.options7.series[1].data = barHavedArr;
			this.options7.series[2].data = lineArr;
			const firstItem = xAxisArr[0];
			// const endItem = xAxisArr[6]
			// 	? xAxisArr[6]
			// 	: xAxisArr[xAxisArr.length - 1];
			const endItem = xAxisArr[xAxisArr.length - 1];
			this.options7.dataZoom[0].startValue = firstItem;
			this.options7.dataZoom[0].endValue = endItem;
			const chartDom = document.getElementById('echarts7');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		echartsInit8(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options8.xAxis[0].data = xAxisArr;
			this.options8.series[0].data = barArr;
			this.options8.series[1].data = lineArr;
			this.options8.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts8');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
		},
		echartsInit9(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options9.xAxis[0].data = xAxisArr;
			this.options9.series[0].data = barArr;
			this.options9.series[1].data = lineArr;
			this.options9.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts9');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options9);
		},
		echartsInit10(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			const dev_info_department_id = [];
			res.forEach(item => {
				xAxisArr.push(item.department);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
				dev_info_department_id.push(item.dev_info_department_id);
			});
			this.options10.xAxis[0].data = xAxisArr;
			this.options10.series[0].data = barArr;
			this.options10.series[1].data = lineArr;
			this.options10.series[2].data = lineArr1;
			const firstItem = xAxisArr[0];
			const endItem = xAxisArr[6]
				? xAxisArr[6]
				: xAxisArr[xAxisArr.length - 1];
			this.options10.dataZoom[0].startValue = firstItem;
			this.options10.dataZoom[0].endValue = endItem;
			const chartDom = document.getElementById('echarts10');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options10);
			myChart.on('click', params => {
				// console.log(params);
				// console.log(dev_info_department_id[params.dataIndex]);
				// console.log(htimeFormat(this.date[0], '{y}-{m}-{d}'));
				// return;

				this.$router.push(
					`/hospital/repair?begin=${htimeFormat(
						this.date[0]
					)}&end=${htimeFormat(this.date[1])}&dateType=3&deptId=${
						dev_info_department_id[params.dataIndex]
					}`
				);
			});
		},
		echartsInit11(res) {
			const xAxisArr = [];
			const barArr = [];
			res?.list?.forEach(item => {
				xAxisArr.push(item.mth);
				barArr.push(item.xjcnt);
			});
			this.options11.xAxis[0].data = xAxisArr;
			this.options11.series[0].data = barArr;
			const chartDom = document.getElementById('echarts11');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options11);
		},
		echartsInit12(res) {
			const xAxisArr = [];
			const barArr = [];
			res?.list?.forEach(item => {
				xAxisArr.push(item.mth);
				barArr.push(item.bycnt);
			});
			this.options12.xAxis[0].data = xAxisArr;
			this.options12.series[0].data = barArr;
			const chartDom = document.getElementById('echarts12');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options12);
		},
		echartsInit13(res) {
			this.options13.series[0].data = [];
			const obj = res.list[res.list.length - 1];
			const xj = obj.xjcnt;
			const xjNo = obj.xjcnt - obj.xjdonecnt;

			this.options13.series[0].data.push(
				{ value: Number(xjNo), name: '未完成' },
				{ value: Number(xj), name: '完成' }
			);
			const chartDom = document.getElementById('echarts13');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options13);
		},
		echartsInit14(res) {
			this.options14.series[0].data = [];
			// let xj = 0;
			const obj = res.list[res.list.length - 1];
			const by = obj.bycnt;
			const byNo = obj.bycnt - obj.bydonecnt;

			this.options14.series[0].data.push(
				{ value: Number(byNo), name: '未完成' },
				{ value: Number(by), name: '完成' }
			);
			const chartDom = document.getElementById('echarts14');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options14);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExcelRepairCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'报修数量',
				'维修应收费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairCntAndPriceByMonth
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修数量和维修费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDoneCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'维修完成数量',
				'维修完成费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDoneCntAndPriceByMonth
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修完成数量和费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairSignCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'维修完成数量',
				'维修完成费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairSignCntAndPriceByMonth
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修完成数量和费用（签单时间）${htimeFormat(
						new Date()
					)}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDeviceCntTop(obj) {
			const tHeader = ['设备名称', '次数'];
			const filterVal = ['deviceName', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDeviceCntTop
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修次数${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelRepairDevicePriceTop(obj) {
			const tHeader = ['设备名称', '价格'];
			const filterVal = ['deviceName', 'repairPrice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDevicePriceTop
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDeviceTypeCntTop(obj) {
			const tHeader = ['类型', '数量'];
			const filterVal = ['deviceType', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDeviceTypeCntTop
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `类型报修次数${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		downloadExcelRepairDeviceTypePriceTop(obj) {
			const tHeader = ['类型', '价格'];
			const filterVal = ['deviceType', 'repairPrice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDeviceTypePriceTop
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `类型报修费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairCnt(obj) {
			const tHeader = ['科室', '未报修数量', '已维修数量', '已报修比例'];
			const filterVal = ['department', 'ingCnt', 'doneCnt', 'zb'];
			this.tableDownDataRepairCnt.forEach(item => {
				item.zb = `${((item.doneCnt / item.cnt) * 100).toFixed(2)}%`;
			});
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairCnt
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修次数及占比${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},
		// todo
		downloadExcelDevPrice(obj) {
			const tHeader = ['科室', '设备价值', '维修费用', '维修费用占比'];
			const filterVal = ['department', 'price', 'realprice', 'zb'];
			this.tableDownDataDevPrice.forEach(item => {
				item.zb = `${((item.realprice / item.price) * 100).toFixed(
					2
				)}%`;
			});
			const data = this.formatJson(filterVal, this.tableDownDataDevPrice);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `费用及占比${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelCntPrice(obj) {
			const tHeader = ['科室', '维修次数', '维修金额', '维修实收金额'];
			const filterVal = ['department', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(filterVal, this.tableDownDataDevPrice);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `科室维修次数与金额${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelallXJBYcnt(obj) {
			const tHeader = [
				'日期',
				'报修次数',
				'报修完成次数',
				'巡检次数',
				'巡检完成次数',
				'保养次数',
				'保养完成次数'
			];
			const filterVal = [
				'mth',
				'repaircnt',
				'repairdonecnt',
				'xjcnt',
				'xjdonecnt',
				'bycnt',
				'bydonecnt'
			];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataallXJBYcnt
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `巡检、保养月度${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}
.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}
.search_box {
	margin-bottom: 20px;
}
.echart_item {
	width: 500px !important;
	height: 360px;
	margin: 0 auto;
}
.echart_box {
	width: 460px;
	height: 360px;
}
.search_button {
	margin-left: 10px;
}
.el-col {
	margin-bottom: 20px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
