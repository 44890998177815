/* *@Description: 物料流向 *@ClassAuthor: Happy ZXH *@Date: 2022-05-21 18:14:21 */
<template>
	<div>
		<el-form :inline="true" :model="searchForm" class="demo-form-inline" size="mini">
			<el-form-item label="出入库时间">
				<el-date-picker
					v-model="searchForm.dateArr"
					type="datetimerange"
					value-format="yyyy-MM-dd HH:mm:ss"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:default-time="['12:00:00', '08:00:00']"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="库房">
				<el-select
					v-model="searchForm.warehouseId"
					filterable
					clearable
					size="mini"
					placeholder="请选择库房"
				>
					<el-option
						v-for="(item, index) in warehouseData"
						:key="index"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="物料">
				<el-select
					v-model="searchForm.goodsId"
					filterable
					clearable
					size="mini"
					placeholder="请选择库房"
				>
					<el-option
						v-for="(item, index) in goodsList"
						:key="index"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getStockSummary">查询</el-button>
			</el-form-item>
		</el-form>

		<!-- 新的测试表格 -->

		<div>
			<el-collapse v-model="activeST" accordion class="collapseST">
				<div
					v-if="list.length"
					style="width:100%; height:60px; line-height:60px;background:#e5e9f2; font-size: 20px;"
				>
					<span class="mr10" style=" padding-left:20px;">合计</span>
					<div style="display:inline-block;">
						<span class="mr10">入库</span>
						<span class="w100">{{ allInCnt }}个 /</span>
						<span class="w100">{{ allInPrice }}元</span>
					</div>
					<div style="display:inline-block; width:49.2%; float:right ">
						<span class="mr10">出库</span>
						<span class="w100">{{ allOutCnt }}个 /</span>
						<span class="w100">{{ allOutPrice }}元</span>
					</div>
				</div>
				<el-collapse-item
					v-for="(item, index) in list"
					:key="index"
					:name="item.warehouseId"
					class="collapseTitleST"
				>
					<template slot="title">
						<div class="collTitle" style="width:100%;">
							<span class="mr20">
								<b>{{ item.warehouesName }}</b>
							</span>
							<div style="display:inline-block;">
								<span class="mr10">入库</span>
								<span class="w100">{{ item.allInCnt }}个 /</span>
								<span class="w100">{{ item.allInPrice }}元</span>
							</div>
							<div style="display:inline-block; width:49%; float:right ">
								<span class="mr10">出库</span>
								<span class="w100">{{ item.allOutCnt }}个 /</span>
								<span class="w100">{{ item.allOutPrice }}元</span>
							</div>
							<!-- <div style="display:inline-block;">
                <span>入库</span>
                <span class="w100">数量:{{item.allInCnt}}</span>
                <span class="w100">价格:{{item.allInPrice}}</span>
              </div>
              <div style="display:inline-block; width:46%; float:right ">
                <span>出库</span>
                <span class="w100">数量:{{item.allOutCnt}}</span>
                <span class="w100">价格:{{item.allOutPrice}}</span>
              </div> -->
						</div>
					</template>
					<div class="contentST">
						<div class="left">
							<div class="contentND">
								<el-table
									@row-click="clickTable1"
									:ref="'refTable1' + index"
									header-cell-class-name="tableHeaderBgGreen"
									:data="item.ins"
									row-key="id"
									:tree-props="{
										children: 'details',
										hasChildren: 'hasChildren'
									}"
								>
									style="width: 100%">
									<el-table-column label="日期" prop="date" width="180">
										<template slot-scope="scope">{{ scope.row.date }}</template>
									</el-table-column>
									<el-table-column label="物料名称" prop="goodsName">
										<template slot-scope="scope">
											<MaterialTooltip
												:materialObjP="{
													goodsId: scope.row.goodsId,
													goodsName: scope.row.goodsName
												}"
											></MaterialTooltip>
										</template>
									</el-table-column>
									<el-table-column label="个数" prop="cnt" width="80">
										<template slot-scope="scope">
											{{ scope.row.cnt }} 个
										</template>
									</el-table-column>
									<el-table-column label="价格" prop="price" width="80">
										<template slot-scope="scope">
											{{ scope.row.price }} 元
										</template>
									</el-table-column>
									<el-table-column
										label="来源库房"
										prop="transferOtherWarehouseName"
									></el-table-column>
									<el-table-column label="操作" prop="">
										<template slot-scope="scope" v-if="scope.row.aboutId">
											<el-link
												type="primary"
												:underline="false"
												@click.stop="showDialogDetail(scope.row)"
											>
												查看{{ scope.row.inoutTypeName }}单
												<!-- -- {{scope.row.aboutId}} -->
											</el-link>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						<div class="right">
							<div class="contentND">
								<el-table
									@row-click="clickTable2"
									:ref="'refTable2' + index"
									header-cell-class-name="tableHeaderBgWarn"
									:data="item.outs"
									row-key="id"
									:tree-props="{
										children: 'details',
										hasChildren: 'hasChildren'
									}"
								>
									<el-table-column label="日期" prop="date" width="180">
										<template slot-scope="scope">{{ scope.row.date }}</template>
									</el-table-column>
									<el-table-column label="物料名称" prop="goodsName">
										<template slot-scope="scope">
											<MaterialTooltip
												:materialObjP="{
													goodsId: scope.row.goodsId,
													goodsName: scope.row.goodsName
												}"
											></MaterialTooltip>
										</template>
									</el-table-column>
									<el-table-column label="个数" prop="cnt" width="80">
										<template slot-scope="scope">
											{{ scope.row.cnt }} 个
										</template>
									</el-table-column>
									<el-table-column label="价格" prop="price" width="80">
										<template slot-scope="scope">
											{{ scope.row.price }} 元
										</template>
									</el-table-column>
									<el-table-column
										label="目标库房"
										prop="transferOtherWarehouseName"
									></el-table-column>
									<el-table-column label="操作" prop="">
										<template slot-scope="scope" v-if="scope.row.aboutId">
											<el-link
												type="primary"
												:underline="false"
												@click.stop="showDialogDetail(scope.row)"
											>
												查看{{ scope.row.inoutTypeName }}单
												<!-- -- {{scope.row.aboutId}} -->
											</el-link>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</el-collapse-item>
				<template v-if="!list.length">
					<div
						style="display: flex;
				justify-content: center;
				color: #aaaaaa;"
					>
						暂无数据
					</div>
				</template>
			</el-collapse>
		</div>
		<!-- 
	1=采购入库
	2=调拨入库
	4=盘盈入库
	5=销售出库
	6=调拨出库
	8=盘亏出库 -->

		<Dialog :title="'详情'" :visible.sync="isShowDetailDialog" :width="dialogWidth">
			<template v-if="detailContent.inoutType === 1">
				<PurchaseApplicationAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></PurchaseApplicationAddForm>
			</template>
			<template v-if="detailContent.inoutType === 2">
				<TransferAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></TransferAddForm>
			</template>
			<template v-if="detailContent.inoutType === 4">
				<CheckStockAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></CheckStockAddForm>
			</template>
			<template v-if="detailContent.inoutType === 5">
				<SaleApplicationAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></SaleApplicationAddForm>
			</template>
			<template v-if="detailContent.inoutType === 6">
				<TransferAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></TransferAddForm>
			</template>
			<template v-if="detailContent.inoutType === 8">
				<CheckStockAddForm
					v-if="isShowDetailDialog"
					:isShowAEDialog_p.sync="isShowDetailDialog"
					:selectData_p="selectData"
					:isShowDetail_p="true"
				></CheckStockAddForm>
			</template>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialFlowTraceService } from '@s/spareParts/MaterialFlowService';
// eslint-disable-next-line import/no-cycle
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { materialListService, materialAllListService } from '@s/spareParts/MaterialService';
// eslint-disable-next-line import/no-cycle
import { inOutStockSummaryService } from '@s/spareParts/InOutRecordService';
// eslint-disable-next-line import/no-cycle
import PurchaseApplicationAddForm from '@f/spareParts/purchaseApplication/PurchaseApplicationAdd.form';
// eslint-disable-next-line import/no-cycle
import { purchaseApplicationDetailService } from '@s/spareParts/PurchaseApplicationService';
// eslint-disable-next-line import/no-cycle
import SaleApplicationAddForm from '@f/spareParts/saleApplication/SaleApplicationAdd.form';
// eslint-disable-next-line import/no-cycle
import { saleApplicationDetailService } from '@s/spareParts/SaleApplicationService';
import CheckStockAddForm from '@f/spareParts/checkStock/CheckStockAdd.form';
// eslint-disable-next-line import/no-cycle
import { checkStockDetailService } from '@s/spareParts/CheckStockService';
import TransferAddForm from '@f/spareParts/transfer/TransferAdd.form';
// eslint-disable-next-line import/no-cycle
import { transferDetailService } from '@s/spareParts/TransferService';
import Dialog from '@c/ui/Dialog';
import MaterialTooltip from '@/components//MaterialTooltip';
import { htimeFormat } from '../../util/htools.web';

export default {
	name: 'MaterialTable',
	// 采购入库
	components: {
		Dialog,
		PurchaseApplicationAddForm,
		SaleApplicationAddForm,
		CheckStockAddForm,
		TransferAddForm,
		MaterialTooltip
	},

	data() {
		return {
			isShowDetailDialog: false,
			detailContent: {},
			dialogTitle: '详情',
			dialogWidth: '60%',
			activeST: 0,
			activeNd1: 0,
			activeNd2: 0,
			allInCnt: 0,
			allInPrice: 0,
			allOutCnt: 0,
			allOutPrice: 0,
			list: [{ ins: [], outs: [] }],
			// 库房下拉
			warehouseData: [],
			// 物料下拉
			goodsList: [],
			searchForm: {
				begin: '',
				end: '',
				dateArr: []
			},
			selectData: []
		};
	},

	watch: {},

	computed: {},

	mounted() {
		// 获取 桑基图 数据
		// this.materialFlowTrace();
		// 库房下拉
		this.warehouseonlymyself();
		// 获取物料列表
		this.getMaterialListPage();
		// 物料数据统计
		this.getStockSummary();
	},

	methods: {
		// 采购入库详情
		async showDialogEditHandler1() {
			const dataJson = {
				id: this.detailContent.aboutId,
				withdel: true
			};
			const res = await purchaseApplicationDetailService(dataJson);
			this.selectData = [res];
		},
		// 销售出库详情
		async showDialogEditHandler5() {
			const dataJson = {
				id: this.detailContent.aboutId,
				withdel: true
			};
			const res = await saleApplicationDetailService(dataJson);
			this.selectData = [res];
		},
		// 盘盈 盘点单详情
		async showDialogEditHandler4() {
			const dataJson = {
				id: this.detailContent.aboutId,
				withdel: true
			};
			const res = await checkStockDetailService(dataJson);
			this.selectData = [res];
		},
		async showDialogEditHandler2() {
			const dataJson = {
				id: this.detailContent.aboutId,
				withdel: true
			};
			const res = await transferDetailService(dataJson);
			this.selectData = [res];
		},
		showDialogDetail(row) {
			this.selectData = [];
			this.detailContent = row;
			console.log(row);
			if (this.detailContent.inoutType === 1) {
				this.showDialogEditHandler1();
				// this.dialogTitle = '采购详情'
				this.dialogWidth = '60%';
			}
			if (this.detailContent.inoutType === 5) {
				this.showDialogEditHandler5();
				// this.dialogTitle = '销售详情'
				this.dialogWidth = '60%';
			}
			if (this.detailContent.inoutType === 4 || this.detailContent.inoutType === 8) {
				this.showDialogEditHandler4();
				// this.dialogTitle = '盘点详情'
				this.dialogWidth = '30%';
			}
			if (this.detailContent.inoutType === 2 || this.detailContent.inoutType === 6) {
				this.showDialogEditHandler2();
				// this.dialogTitle = '调拨详情'
				this.dialogWidth = '60%';
			}
			this.isShowDetailDialog = true;
		},
		// 根据类型展示查看对应详情
		getInoutTypeName(inoutType) {
			switch (inoutType) {
				case 1:
					return '采购';
				case 2:
					return '调拨';
				case 4:
					return '盘点';
				case 5:
					return '销售';
				case 6:
					return '调拨';
				case 8:
					return '盘点';
				default:
					return '';
			}
		},
		// 物料数据统计
		async getStockSummary() {
			if (this.searchForm.dateArr) {
				[this.searchForm.begin, this.searchForm.end] = this.searchForm.dateArr;
			} else {
				this.searchForm.begin = null;
				this.searchForm.end = null;
			}
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				...this.searchForm
			};
			const res = await inOutStockSummaryService(dataJson);

			this.allInCnt = 0;
			this.allInPrice = 0;
			this.allOutCnt = 0;
			this.allOutPrice = 0;

			if (res.length === 0) {
				this.$message({
					message: '暂无数据',
					type: 'warning'
				});
				this.list = [];
				return;
			}
			res.forEach((item, index) => {
				item?.ins.forEach((item1, index1) => {
					item1.cnt = item1.sumCnt;
					item1.price = item1.sumPrice;
					item1.id = Math.ceil(Math.random() * 1000 * 1000);
					item1.date = item1.inoutTypeName;
					item1.expanded = false;
					item1.index = index;
					item1?.details.forEach(item2 => {
						item2.date = htimeFormat(item2.date);
						item2.id = Math.ceil(Math.random() * 1000 * 1000);
						item2.inoutTypeName = this.getInoutTypeName(item2.inoutType);
					});
					this.allInCnt += item1.sumCnt;
					this.allInPrice += item1.sumPrice;
				});
				item?.outs.forEach((item1, index1) => {
					item1.cnt = item1.sumCnt;
					item1.price = item1.sumPrice;
					item1.id = Math.ceil(Math.random() * 1000 * 1000);
					item1.date = item1.inoutTypeName;
					item1.expanded = false;
					item1.index = index;
					item1?.details.forEach(item2 => {
						item2.date = htimeFormat(item2.date);
						item2.id = Math.ceil(Math.random() * 1000 * 1000);
						item2.inoutTypeName = this.getInoutTypeName(item2.inoutType);
					});
					this.allOutCnt += item1.sumCnt;
					this.allOutPrice += item1.sumPrice;
				});
			});

			console.log('res 物料数据统计 三级表格数据~~~~~~~~~~~~~~~');
			console.log(res);

			this.list = res;
		},
		getSummaries(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}
				const values = data.map(item => Number(item[column.property]));
				if (!values.every(value => isNaN(value))) {
					sums[index] = values.reduce((prev, curr) => {
						const value = Number(curr);
						if (!isNaN(value)) {
							return prev + curr;
						} else {
							return prev;
						}
					}, 0);
					sums[index] += ' 个';
				} else {
					sums[index] = '-';
				}
			});

			return sums;
		},
		// 获取 桑基图 数据
		async materialFlowTrace() {
			if (this.searchForm.dateArr) {
				[this.searchForm.begin, this.searchForm.end] = this.searchForm.dateArr;
			} else {
				this.searchForm.begin = null;
				this.searchForm.end = null;
			}
			const dataJson = {
				...this.searchForm
			};
			const res = await materialFlowTraceService(dataJson);
			console.log(res);
		},
		// 库房下拉
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 获取物料列表 分页
		async getMaterialListPage() {
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			res.forEach(item => {
				item.goodsId = item.id;
				item.goodsName = item.name;
				if (item.pic && item.pic.length > 0) {
					item.picList = [
						`${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`
					];
					item.newPic = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`;
				} else {
					item.newPic = item.pic;
					item.picList = [];
				}
			});
			this.goodsList = res;
		},
		clickTable1(row, index, e) {
			row.expanded = !row.expanded;
			this.$nextTick(() => {
				this.$refs[`refTable1${row.index}`] &&
					this.$refs[`refTable1${row.index}`][0].toggleRowExpansion(row, row.expanded);
			});
		},
		clickTable2(row, index, e) {
			row.expanded = !row.expanded;
			this.$nextTick(() => {
				this.$refs[`refTable2${row.index}`] &&
					this.$refs[`refTable2${row.index}`][0].toggleRowExpansion(row, row.expanded);
			});
		}
	}
};
</script>
<style lang="less" scoped>
/deep/.collapseTitleST .el-collapse-item__header {
	padding-left: 20px;
}
.collTitle span {
	display: inline-block;
	// margin-right: 20px;
}
.collTitle span.mr20 {
	margin-right: 20px;
}
.collTitle span.mr10 {
	margin-right: 10px;
}
.mr20 {
	margin-right: 20px;
}
.mr10 {
	margin-right: 10px;
}
.collTitle span.w100 {
	// min-width: 80px;
}
.demo-table-expand {
	font-size: 0;
}
.demo-table-expand label {
	width: 90px;
	color: #99a9bf;
}
.demo-table-expand .el-form-item {
	margin-right: 0;
	margin-bottom: 0;
	width: 50%;
}

.collapseST {
	border: 1px solid #ebeef5;
	padding: 10px;
}
.contentST {
	display: flex;
	justify-content: space-between;
}
.contentST .left,
.contentST .right {
	width: 49%;
	max-height: 600px;
	overflow-y: auto;
}

/deep/ .collapseTitleST .el-collapse-item__header {
	background: #d9ecff;
	color: #409eff;
	border-bottom: 1px solid #409eff;
}
/deep/ .collapseTitleND .el-collapse-item__header {
	background-color: #faecd8;
	color: #e6a23c;
	border-bottom: 1px solid #e6a23c;
}
/deep/.el-table__row--level-0 {
	font-weight: bold;
	cursor: pointer;
}
/deep/.tableHeaderBgGreen {
	background: #e1f3d8;
	color: #67c23a;
	border-bottom: 1px solid #67c23a;
}
/deep/.tableHeaderBgWarn {
	background: #faecd8;
	color: #e6a23c;
	border-bottom: 1px solid #e6a23c;
}
/deep/.tableHeaderBg {
	background: #e1f3d8;
	color: #67c23a;
	border-bottom: 1px solid #67c23a;
}
/deep/ .el-collapse-item__wrap {
	border-bottom: 0;
}
.openTable {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.openTable .item {
	width: 49%;
	display: flex;
	height: 40px;
	line-height: 40px;
}
</style>
