<template>
	<div class="app-container">
		<DevBaseSearchForm @searchForm="searchFormHandler"></DevBaseSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devBase'"
		>
			<template v-slot:lastBtn>
				<!-- v-authorize="{ name: 'add', type: 'devBase', id: 'btn-add' }" -->
				<!-- <el-button type="success" @click="exportDev">导出</el-button> -->
				<el-button type="warning" @click="handleNoSelection">取消所有选择</el-button>
				<el-button
					type="primary"
					@click="importDev"
					id="btn-import-row"
					v-authorize="{ name: 'import', type: 'devBase', id: 'btn-import-row' }"
				>
					导入导出
				</el-button>
				<el-button
					type="default"
					@click="showBatchMaintenanceDialog"
					:disabled="selectData.length < 1"
					id="btn-update-row"
					v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
				>
					批量修改维保信息
				</el-button>
				<el-select
					filterable
					v-model="printType"
					placeholder="请选择切换打印机"
					:disabled="selectData.length < 1"
					style="margin-left:20px;"
				>
					<el-option
						v-for="item in printList"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
				<el-button
					type="default"
					@click="showPrintDialog"
					:disabled="selectData.length < 1"
				>
					批量打印设备二维码
				</el-button>
			</template>
		</Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			class="tableCopy"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			:isHideCheckbox_p="true"
			:row-key="getRowKey"
			@selection-change="handleSelectionChange"
		>
			<!-- @select="selectDevBaseData" -->
			<el-table-column type="selection" :reserve-selection="true" width="55" />
			<el-table-column label="ID" prop="devInfoId" width="100"></el-table-column>
			<el-table-column label="设备编号" prop="devInfoNumber"></el-table-column>
			<el-table-column label="设备名称" prop="devInfoName"></el-table-column>
			<el-table-column label="设备类型" prop="devInfoTypeName"></el-table-column>
			<el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
			<el-table-column label="所在科室" prop="devInfoDepartmentName">
				<template slot-scope="scope">
					{{ scope.row.devInfoDepartmentName }}
					<span style="color:#67C23A;" v-if="scope.row.useType === 1">(在账在用)</span>
					<span style="color:#F56C6C;" v-else-if="scope.row.useType === 2">
						(不在账在用)
					</span>
					<span style="color:#E6A23C;" v-else-if="scope.row.useType === 3">
						(在账不在用)
					</span>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column label="维保公司" prop="orgName" width="100"></el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="220">
				<template slot-scope="scope">
					<el-button type="success" @click="viewSingleHandler(scope.row)" size="mini">
						查看
					</el-button>
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row, 'copyadd')"
						size="mini"
					>
						复制
					</el-button>
					<br />
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row, 'editadd')"
						size="mini"
						:disabled="scope.row.stoped"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'devBase', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
						size="mini"
					>
						删除
					</el-button>
					<!-- <el-button
						id="btn-repair-row"
						type="primary"
						v-authorize="{ name: 'repair', type: 'devBase', id: 'btn-repair-row' }"
						@click="addRepairHandler(scope.row)"
						size="mini"
						:disabled="scope.row.stoped"
					>
						报修
					</el-button> -->
					<br />
					<el-button
						type="primary"
						@click="selectCheckHandler({ ...scope.row, zzType: 'up' })"
						size="mini"
						title="选择上面的"
					>
						<i class="iconfont icon-run-above" style="font-size:12px;"></i>
					</el-button>
					<el-button
						type="success"
						@click="selectCheckHandler({ ...scope.row, zzType: 'down' })"
						size="mini"
						title="选择下面的"
					>
						<i class="iconfont icon-run-below" style="font-size:12px;"></i>
					</el-button>
					<el-button
						id="btn-devearning-row"
						type="primary"
						v-authorize="{ name: 'devearning', type: 'devearning', id: 'btn-devearning-row' }"
						@click="toDevearning(scope.row)"
						size="mini"
						:disabled="scope.row.stoped"
					>
						效益
					</el-button>
					<!-- <i
						class="iconfont icon-run-below"
						@click="selectCheckHandler({ ...scope.row, zzType: 'down' })"
						style="margin-right:20px; cursor:pointer"
					></i> -->
					<!-- <el-button
						type="primary"
						@click="selectCheckHandler({ ...scope.row, zzType: 'up' })"
						size="mini"
					>
						选择上面的
					</el-button> -->
					<!-- <el-button
						type="success"
						@click="selectCheckHandler({ ...scope.row, zzType: 'down' })"
						size="mini"
					>
						选择下面的
					</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
			:pager-count="5"
		>
			<template v-slot:devBase>
				<div
					style="flex: 1;display: flex;align-items: start;justify-content: space-between;margin-top: 10px;"
				>
					<div>
						<el-button type="primary" @click="handleSelectionChange1" size="mini">
							全选
						</el-button>
						<el-button type="warning" @click="handleNoSelection" size="mini">
							取消选择
							<!-- 取消所有选择 -->
						</el-button>
						<el-button
							@click="showPrintDialog"
							:disabled="selectData.length < 1"
							size="mini"
						>
							批量打印二维码
						</el-button>
					</div>
					<div
						style="display:flex; align-items:center;margin-left:10px;margin-right:10px;min-width:244px;"
					>
						<span style="color:#999999;">每页显示条数：</span>
						<el-input-number
							v-model="pageSize"
							@change="devBaseList"
							:precision="0"
							:min="1"
							:max="9999"
							:step="50"
							size="mini"
						></el-input-number>
					</div>
				</div>
			</template>
		</Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DevBaseAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></DevBaseAddForm>
		</Dialog>
		<!-- 批量修改维保信息 -->
		<el-dialog
			title="维保信息"
			:visible.sync="dialogVisibleBatchMaintenance"
			width="30%"
			:append-to-body="true"
			custom-class="dialogVisibleBatchMaintenanceClass"
		>
			<el-form
				:model="batchMaintenanceForm"
				:rules="batchMaintenanceRules"
				ref="batchMaintenanceForm"
				label-width="130px"
				:isHideCheckbox_p="false"
				class="batchMaintenanceForm"
			>
				<el-table-column :reserve-selection="true" type="selection" width="55" />
				<el-form-item label="维修公司" prop="serviceOrganizationId">
					<el-select
						v-model="batchMaintenanceForm.serviceOrganizationId"
						placeholder="请选择维修公司名称"
						clearable
						filterable
						style="width: 100%"
					>
						<el-option
							v-for="item in optionsCompany"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="维修时间" prop="timeArr">
					<el-date-picker
						v-model="batchMaintenanceForm.timeArr"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="报修期限（天）" prop="transferBatchNumber">
					<el-input
						v-model="batchMaintenanceForm.transferBatchNumber"
						disabled
					></el-input>
				</el-form-item>
				<el-form-item label="维保说明" prop="remarks" class="textArea">
					<el-input type="textarea" v-model="batchMaintenanceForm.remarks"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleBatchMaintenance = false">取 消</el-button>
				<el-button type="primary" @click="submitBatchMaintenance">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 批量打印设备二维码 -->
		<Dialog :visible.sync="isShowPrintDialog5040" width="320px">
			<div slot="title" class="dialog-title-pring">
				<span>打印二维码</span>
				<el-input
					v-model="valueCom"
					type="textarea"
					autosize
					placeholder="服务公司"
					style="width: 1.5rem; margin-left:10px"
				></el-input>
			</div>
			<PrintDev
				v-if="isShowPrintDialog5040"
				:isShowPrintDialog_p.sync="isShowPrintDialog5040"
				:selectData_p="selectData"
				:valueCom="valueCom"
			></PrintDev>
		</Dialog>
		<Dialog title="打印二维码" :visible.sync="isShowPrintDialog7050" width="400px">
			<div slot="title" class="dialog-title-pring">
				<span>打印二维码</span>
				<el-input
					v-model="valueCom"
					type="textarea"
					autosize
					placeholder="服务公司"
					style="width: 1.5rem; margin-left:10px"
				></el-input>
			</div>
			<PrintDev7050
				v-if="isShowPrintDialog7050"
				:isShowPrintDialog_p.sync="isShowPrintDialog7050"
				:selectData_p="selectData"
				:valueCom="valueCom"
			></PrintDev7050>
		</Dialog>
		<Dialog title="添加" :visible.sync="isShowAERepairDialog" width="72%">
			<RepairAdd
				:repairDevId="repairDevId"
				:repairDevTypeId="repairDevTypeId"
				:repairDepartmentId="repairDepartmentId"
				v-if="isShowAERepairDialog"
				:isShowAERepairDialog_p.sync="isShowAERepairDialog"
			></RepairAdd>
		</Dialog>
		<Dialog
			title="导出导入"
			:visible.sync="isShowimportDevDialog"
			width="50%"
			class="uploadExcelDialog"
		>
			<div class="uploadDes">
				<h3>说明</h3>
				<ul>
					<li>1、请下载导入模板并使用导入模板导入设备数据</li>
					<li>2、模板中[科室]与[厂家]表格可自行添加,[设备分类]与[单位]表格请勿修改</li>
					<li>
						3、为保证数据完整有效，[设备]表格中的“设备名称”、“科室”、“单位”、“厂家”列关联了其他对应表格，采用下拉框选择方式，请正确填写数据，选择后“分类id”、“科室id”、“单位id”、“厂家id”会自动生成，无需自己填写
					</li>
					<li>4、“设备名称”、“分类名称”、“科室”、“购入日期”、“价格”为必填，其他选填</li>
					<li>5、日期字段格式请使用 yyyy-MM-dd格式</li>
					<li>
						6、请勿使用同一个excel文件多次导入，如需导入新的数据，请重新下载现有数据并在新excel文档中编辑修改
					</li>
					<li>7、导出的现有设备，请勿删除修改id</li>
					<li>8、可在现有设备后增加新设备，增加时，id无需填写</li>
				</ul>
			</div>
			<el-upload
				class="upload-demo my-10"
				:before-upload="importBeforeUploadFile"
				:on-success="importDevSuccess"
				:on-exceed="importDevExceedFile"
				:on-remove="importDevRemove"
				accept=".xlsx"
				:headers="headers"
				:action="uploadLogoAction"
				multiple
				:limit="limitNum"
				:file-list="fileList"
			>
				<el-button size="small" type="success" :disabled="disabledUpload">
					点击上传
				</el-button>
				<el-button
					size="small"
					type="primary"
					:disabled="disabledUpload"
					@click="exportDev"
				>
					点此下载导入模板及现有数据
				</el-button>
				<div slot="tip" class="el-upload__tip">只能上传单个.xlsx文件，且不超过10M</div>
			</el-upload>
			<div style="color: #f56c6c;" class="uploadErr">
				<template v-if="departErrors">
					<p class="errTitle">科室表错误：</p>
					<p v-for="(item, index) in departErrors" :key="index">{{ item }}</p>
				</template>
				<template v-if="devErrors">
					<p class="errTitle">设备表错误：</p>
					<p v-for="(item, index) in devErrors" :key="index">{{ item }}</p>
				</template>
				<template v-if="facErrors">
					<p class="errTitle">厂家表错误：</p>
					<p v-for="(item, index) in facErrors" :key="index">{{ item }}</p>
				</template>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	devBaseListService,
	devBaseDeleteService,
	devBaseDetailService,
	allocatedEquipmentService
} from '@s/hospital/devBase/DevBaseService';

// 获取维修列表
// import { organizationAllQueryListService } from '@s/hospital/contract/ContractService';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import DevBaseAddForm from '@f/hospital/devBase/DevBaseAdd.form';
import DevBaseSearchForm from '@f/hospital/devBase/DevBaseSearch.form';
import PrintDev from '@f/hospital/devBase/PrintDev';
import PrintDev7050 from '@f/hospital/devBase/PrintDev7050';
import RepairAdd from '@f/repair/RepairAdd.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'DevBase',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		DevBaseAddForm,
		DevBaseSearchForm,
		PrintDev,
		PrintDev7050,
		RepairAdd
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			valueCom: 'ygongyun.com',
			// 打印机类型
			printType: 0,
			printList: [
				{ id: 0, name: 'Gprinter GP-3120TU (50*40)' },
				{ id: 1, name: 'Gprinter GP-1134T (70*50)' }
			],
			isShowPrintDialog5040: false,
			isShowPrintDialog7050: false,
			// 批量修改维保信息 弹框
			dialogVisibleBatchMaintenance: false,
			// 批量修改维保信息 表单
			batchMaintenanceForm: {
				timeArr: [],
				transferBatchNumber: 0
			},
			batchMaintenanceRules: {},
			optionsCompany: [],
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			isShowAERepairDialog: false,
			// 导入
			isShowimportDevDialog: false,
			uploadLogoAction: '',
			timerUploadUrl: null,
			disabledUpload: true,
			headers: { Authorization: 1, token: 2 },
			// uploadLogoAction: `${this.$envConfig.btyong_5}devBase/${this.$envConfig.uploadCompanyDevImgList}`,
			fileList: [],
			departErrors: null,
			devErrors: null,
			facErrors: null,
			limitNum: 1,

			repairDevId: '',
			repairDevTypeId: '',
			repairDepartmentId: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		// selectData(newValue) {
		// },
		'batchMaintenanceForm.timeArr'(newValue) {
			if (newValue && newValue.length !== 2) {
				return false;
			}
			// eslint-disable-next-line radix
			const day = Math.ceil(
				(new Date(newValue[1]).getTime() - new Date(newValue[0]).getTime()) /
					1000 /
					3600 /
					24
			);
			this.batchMaintenanceForm.transferBatchNumber = day;
		},
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.devBaseList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.devBaseList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.devBaseList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.devBaseList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	created() {
		// this.isShowDevearning = false;
		// this.$route.query.flag == 1 && (this.isShowDevearning = true);
	},
	mounted() {
		this.devBaseList();
		//  *维修公司 下拉
		this.getCompanyList();
		this.currentUserOrganization = hgetStorage('organization');
		// this.selectData = localStorage.getItem('selectDataAll');

		// this.$nextTick(() => {
		// 	const aaa = {
		// 		affiliatedHospitalId: 35,
		// 		devInfoId: 99,
		// 		devInfoName: '123',
		// 		devInfoState: 0,
		// 		devInfoType: '14'
		// 	};

		// 	// console.log(this.$refs.tableDom);
		// 	this.$refs.tableDom.toggleRowSelection(aaa, true);
		// 	this.tableData.forEach(row => {
		// 		if (this.selectData.indexOf(row.devInfoId) >= 0) {
		// 			this.$refs.tableDom.toggleRowSelection(row, true);
		// 		}
		// 	});
		// });

		this.timerUploadUrl = setInterval(() => {
			if (this.$envConfig) {
				clearInterval(this.timerUploadUrl);
				this.uploadLogoAction = `${this.$envConfig.cz}export/importDev`;
				this.disabledUpload = false;
			}
		}, 100);

		this.headers.Authorization = hgetStorage('token');
	},
	methods: {
		addRepairHandler(item) {
			this.repairDevId = item.devInfoId;
			this.repairDevTypeId = item.devInfoType;
			this.repairDepartmentId = item.devInfoDepartmentId;
			console.log('this.headers');
			console.log(this.headers);
			this.isShowAERepairDialog = true;
		},
		// 导出
		exportDev() {
			window.open(
				`https://ygongyun.com/web/comp/api/export/exportDev?token=${encodeURIComponent(
					hgetStorage('token')
				)}`
			);
		},
		// 导入
		importDev() {
			this.isShowimportDevDialog = true;
		},
		importDevSuccess(res, file) {
			if (res.code !== 200) {
				this.$message.warning('导入有误，请联系管理员!');
				this.fileList = [];
				return;
			}
			if (res?.result) {
				this.departErrors = res.result.departErrors;
				this.devErrors = res.result.devErrors;
				this.facErrors = res.result.facErrors;
			}
			if (
				res.result.departErrors === null &&
				res.result.devErrors === null &&
				res.result.facErrors === null
			) {
				this.$alert('导入成功!', '', {
					confirmButtonText: '确定',
					type: 'success',
					dangerouslyUseHTMLString: true,
					showClose: false,
					customClass: 'uploadExcelSuccess',
					callback: action => {
						this.devBaseList();
						this.isShowimportDevDialog = false;
						this.fileList = [];
					}
				});
			} else {
				this.fileList = [];
			}
		},
		importDevRemove(file, fileList) {
			this.departErrors = null;
			this.devErrors = null;
			this.facErrors = null;
		},
		importDevExceedFile(files, fileList) {
			this.$message.warning(`只能选择 ${this.limitNum} 个文件`);
		},
		importBeforeUploadFile(file) {
			console.log('before upload');
			console.log(file);
			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const size = file.size / 1024 / 1024;
			if (extension !== 'xlsx') {
				this.$message.warning('只能上传后缀是.xlsx的文件');
			}
			if (size > 10) {
				this.$message.warning('文件大小不得超过10M');
			}
		},
		importDevhandleRemove(file, fileList) {
			console.log(file, fileList);
		},

		importDevbeforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		// 批量修改维保信息
		showBatchMaintenanceDialog() {
			this.dialogVisibleBatchMaintenance = true;
		},
		//  *维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});
			// const res = await organizationAllQueryListService({
			// 	pageNum: 1,
			// 	pageSize: 999
			// });
			this.optionsCompany = res.records;
		},
		// 表格分页多选
		handleSelectionChange(val) {
			this.selectData = val;
		},
		handleSelectionChange1() {
			console.log(this.$refs.tableDom);
			if (this.tableData) {
				this.tableData.forEach(row => {
					this.$refs.tableDom.$children[0].toggleRowSelection(row);
				});
			}
		},
		handleNoSelection() {
			this.$refs.tableDom.clearSelection();
		},
		getRowKey(row) {
			// console.log(row);
			return row.devInfoId;
		},
		// 提交批量维保信息
		async submitBatchMaintenance() {
			const devInfoIdsTemp = [];
			this.selectData.forEach(item => {
				devInfoIdsTemp.push(item.devInfoId);
			});

			this.batchMaintenanceForm.signBeginTime =
				this.batchMaintenanceForm.timeArr && this.batchMaintenanceForm.timeArr[0];
			this.batchMaintenanceForm.signEndTime =
				this.batchMaintenanceForm.timeArr && this.batchMaintenanceForm.timeArr[1];
			this.batchMaintenanceForm.devInfoIds = devInfoIdsTemp;
			delete this.batchMaintenanceForm.timeArr;
			await allocatedEquipmentService(this.batchMaintenanceForm);

			this.$message.success('批量修改成功！');
			this.dialogVisibleBatchMaintenance = false;
			this.devBaseList();
		},
		async devBaseList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await devBaseListService(dataJson);
			this.listMixin(res);
			// setTimeout(() => {
			// 	const elH = window.document
			// 		.querySelector('.tableCopy .el-table__header-wrapper')
			// 		.cloneNode(true);
			// 	const elB = window.document.querySelector('.tableCopy .el-table__body-wrapper');
			// 	console.log('-------------------------');
			// 	console.log(elH);
			// 	elH.querySelector('.el-checkbox').click = this.handleSelectionChange();
			// 	console.log(elB);
			// 	elB.after(elH);
			// }, 1000);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async viewSingleHandler(row) {
			// this.$router.push({ name: 'DevBaseDetai', query: { devId: row.devInfoId } });
			this.$router.push({
				name: 'DevBaseDetai',
				query: { type: 'hospital', devId: row.devInfoId }
			});
		},
		async editSingleHandler(row, type) {
			const dataJson = {
				devId: row.devInfoId
			};
			const res = await devBaseDetailService(dataJson);
			res.addType = type;
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// console.log(row);
			// return false;
			this.deleteSingleHandlerMixin(row);
			// this.$confirm(this.delTips || '确认此操作吗？', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(async () => {
			// 		const ids = [];
			// 		ids.push(row.devInfoId);
			// 		const dataJson = {
			// 			ids: ids
			// 		};
			// 		await devBaseDeleteService(dataJson);
			// 		this.isRefreshList = true;
			// 	})
			// 	.catch(error => {
			// 		console.log(error);
			// 	});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.devBaseList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.devInfoId;
		},
		showPrintDialog() {
			this.valueCom = 'ygongyun.com';
			if (this.printType === 0) {
				this.isShowPrintDialog5040 = true;
			} else if (this.printType === 1) {
				this.isShowPrintDialog7050 = true;
			} else {
				this.$message.warning('请联系管理员！');
			}
		},
		selectCheckHandler(row) {
			let arr = [];
			const tableData = [...this.tableData];
			const index = this.tableData.findIndex(item => item.devInfoId == row.devInfoId);
			if (row.zzType == 'up') {
				console.log('选中前面的');
				arr = tableData.splice(0, index + 1);
			} else if (row.zzType == 'down') {
				console.log('选中后面的');
				arr = tableData.splice(index);
			}
			this.selectCheckboxByArr(arr);
		},
		// 给我一个数组，我给你选中表格复选框
		selectCheckboxByArr(arrData1) {
			const arr = arrData1;
			this.$nextTick(() => {
				arr.forEach(row => {
					// this.$refs.tableDom.toggleRowSelection(row, true);
					this.$refs.tableDom.$children[0].toggleRowSelection(row, true);
				});
			});
		},
		// 效益
		toDevearning(row) {
			this.$router.push({
				name: 'Devearning',
				query: {
					type: 'hospital',
					devid: row.devInfoId,
					devname: row.devInfoName,
					departid: row.devInfoDepartmentId,
					departname: row.devInfoDepartmentName
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 1100px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 30%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-form-item {
	width: initial;
}

/deep/ .dialogVisibleBatchMaintenanceClass .textArea {
	width: 100%;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-dialog__body {
	overflow: hidden;
}

.uploadExcelDialog {
}

/deep/ .uploadExcelDialog .el-dialog .el-dialog__body {
	padding-top: 0 !important;
}

.uploadErr .errTitle {
	font-size: 18px;
	font-weight: bold;
}

.uploadErr p {
	line-height: 26px;
}

.my-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.uploadDes {
}

.uploadDes h3 {
}

.uploadDes ul li {
	line-height: 26px;
}

/deep/ .pagination-container {
	display: flex;
	justify-content: space-between;
}
</style>
<style>
.uploadExcelSuccess .el-message-box__content .el-message-box__container {
	padding: 10px 5px;
	background: #f0f9eb !important;
	color: #67c23a;
}

.dialog-title-pring {
	display: flex;
	align-items: center;
}
</style>
