<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<BuyRequestDetailSearch
				@searchForm="searchFormHandler"
				@download="downloadExlcel"
				:downLoading="downLoading"
			></BuyRequestDetailSearch>
			<div style="margin-bottom: 10px; margin-top: 10px">
				<!-- 功能按钮 -->
				<!-- <el-button type="success" @click="addStockHandler">添加入库</el-button> -->
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column
				label="项目组"
				prop="warehouseName"
			></el-table-column>
			<el-table-column label="科室" prop="departname"></el-table-column>
			<el-table-column label="物料" prop="goodsname">
				<template slot-scope="scope">
					<MaterialTooltip
						:materialObjP="{
							goodsId: scope.row.goodsId,
							goodsName: scope.row.goodsname
						}"
						v-if="isShowMaterialTip"
					></MaterialTooltip>
				</template>
			</el-table-column>

			<el-table-column label="单价" prop="price"></el-table-column>
			<el-table-column label="采购数量" prop="cnt"></el-table-column>
			<el-table-column label="入库数量" prop="donecnt"></el-table-column>
			<el-table-column label="总价" prop="aboutId">
				<template slot-scope="scope">
					<span>
						{{
							(scope.row.price * 1000 * (scope.row.cnt * 1000)) /
								1000000
						}}
					</span>
				</template>
			</el-table-column>
			<el-table-column
				label="供应商"
				prop="suppliername"
			></el-table-column>
			<el-table-column label="联系人" prop="contract"></el-table-column>
			<el-table-column label="电话" prop="telphone"></el-table-column>
			<el-table-column label="日期" prop="createDate">
				<template slot-scope="scope">
					{{ scope.row.createDate }}
					<!-- {{ scope.row.createDate | parseTime('{y}-{m}-{d}') }} -->
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="70%">
			<TransferingAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></TransferingAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { detailSearchService } from '@s/spareParts/PurchaseApplicationService';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import TransferingAddForm from '@f/spareParts/transfering/TransferingAdd.form';
import BuyRequestDetailSearch from '@f/spareParts/purchaseApplication/BuyRequestDetailSearch';
import MaterialTooltip from '@/components//MaterialTooltip';

export default {
	name: 'Transfering',
	mixins: [ListMixin],
	components: {
		Table,
		Pagination,
		Dialog,
		TransferingAddForm,
		BuyRequestDetailSearch,
		MaterialTooltip
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			downLoading: false,
			delTips: '',
			tableDownData: [],
			searchForm: {},
			isShowMaterialTip: false
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '调拨入库' : '';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
			}
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		async getList() {
			this.isShowMaterialTip = false;
			const dataJson = {
				...this.searchForm,
				pageNum: this.pageIndex,
				pageSize: this.pageSize
			};
			const res = await detailSearchService(dataJson);
			this.isShowMaterialTip = true;
			this.listMixin(res);
		},
		async getDwonList() {
			const dataJson = {
				...this.searchForm,
				pageNum: 1,
				pageSize: 99999
			};
			const res = await detailSearchService(dataJson);
			res.records.forEach((item, index) => {
				item.indexId = index + 1;
				item.priceCnt = item.price * item.cnt;
				item.dfactory = item.goodsDetail.factory;
				item.dgoodsVer = item.goodsDetail.goodsVer;
				item.dforDev = item.goodsDetail.forDev;
				item.dforDevSpec = item.goodsDetail.forDevSpec;
			});
			this.tableDownData = res.records || [];
		},
		async downloadExlcel(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.downLoading = true;
			await this.getDwonList();
			this.getList();

			let tHeader = [];
			let filterVal = [];

			tHeader = [
				'序号',
				'项目组',
				'科室',
				'物料',
				'单价',
				'采购数量',
				'入库数量',
				'总价',
				'供应商',
				'联系人',
				'电话',
				'厂家',
				'型号',
				'适用设备',
				'适用设备型号',
				'日期'
			];
			// eslint-disable-next-line no-unused-vars
			filterVal = [
				'indexId',
				'warehouseName',
				'departname',
				'goodsname',
				'price',
				'cnt',
				'donecnt',
				'priceCnt',
				'suppliername',
				'contract',
				'telphone',
				'dfactory',
				'dgoodsVer',
				'dforDev',
				'dforDevSpec',
				'createDate'
			];

			const data = this.formatJson(filterVal, this.tableDownData);
			const nowDate = `${new Date().getFullYear()}-${new Date().getMonth() +
				1}-${new Date().getDate()}`;
			// 注 /vendor/Export2Excel.js 路径对应
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `采购详情 ${nowDate}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
			this.downLoading = false;
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		}
	}
};
</script>

<style lang="less" scoped></style>
