<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchFormDom"
			:model="searchForm"
			label-width="60px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="标题">
				<el-input
					v-model="searchForm.title"
					placeholder="请输入标题"
					:clearable="true"
				/>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchForm: {
				title: ''
			}
		};
	},
	created() {},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
