<template>
	<div class="app-container">
		<EquipmentTypeSearchForm @searchForm="searchFormHandler"></EquipmentTypeSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'equipment'"
		></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<template slot="tableLeftData">
				<el-table-column label="企业" width="200" v-if="currentUserOrganization === 0">
					<template slot-scope="scope">
						{{ scope.row.organizationName }}
					</template>
				</el-table-column>
			</template>
			<el-table-column label="创建时间" width="150">
				<template slot-scope="scope">
					{{ scope.row.createDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="状态" width="60px;">
				<template slot-scope="scope">
					{{ scope.row.isDel === 0 ? '正常' : '禁用' }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'equipment', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'equipment', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<EquipmentTypeAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></EquipmentTypeAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { equipmentTypeListService, equipmentTypeDeleteService } from '@s/base/EquipmentTypeService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import EquipmentTypeAddForm from '@f/base/equipmentType/EquipmentTypeAdd.form';
import EquipmentTypeSearchForm from '@f/base/equipmentType/EquipmentTypeSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'EquipmentType',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		EquipmentTypeAddForm,
		EquipmentTypeSearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'equipment',
					width: '200px'
				},
				{
					label: '主要耗能',
					field: 'energyName'
				},
				{
					label: '开机耗能',
					field: 'electricityAfter'
				},
				{
					label: '待机耗能',
					field: 'electricityFront'
				},
				{
					label: '设备类型',
					field: 'codeName'
				},
				{
					label: '生产工艺',
					field: 'processName'
				}
			],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增设备' : '编辑设备';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.equipmentTypeList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.equipmentTypeList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.equipmentTypeList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.equipmentTypeList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.equipmentTypeList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async equipmentTypeList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await equipmentTypeListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			this.editId = this.dialogEditHandlerMixin();
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				equipmentId: ids
			};
			await equipmentTypeDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			this.editId = row.id;
			this.isShowAEDialog = true;
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						equipmentId: ids
					};
					await equipmentTypeDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.equipmentTypeList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped></style>
