import { htimeFormat } from '@u/htools.web';
import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 盘点列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const checkStockListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: '',
				end: '',
				pageNum: 1,
				pageSize: 10,
				...data
			};
			if (dataJson.begin) {
				dataJson.begin = htimeFormat(dataJson.begin);
			}
			if (dataJson.end) {
				dataJson.end = htimeFormat(dataJson.end);
			}
			const res = await hpostRequest('hwms/checkStock/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 盘点列表 （下拉不分页）
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
 export const checkStockSelectListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/checkStock/allUnDone', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 领用借用出库 （下拉不分页）
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:01
 */
 export const listundoneListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/borrow/listundone', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 领用借用入库 （下拉不分页）
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:01
 */
 export const listundoneInListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/borrow/listundoneIn', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 盘点添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const checkStockAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				requestTime: '',
				warehouseId: '',
				...data
			};
			dataJson.requestTime = htimeFormat(dataJson.requestTime);
			const res = await hpostRequest('hwms/checkStock/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 盘点编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const checkStockEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
                id: '',
                requestTime: '',
				warehouseId: '',
				...data
			};
            dataJson.requestTime = htimeFormat(dataJson.requestTime);
			const res = await hpostRequest('hwms/checkStock/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 盘点删除
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 14:40:48
 */
export const checkStockDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = [
				...data
            ];
			const res = await hpostRequest('hwms/checkStock/deletes', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 盘点详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const checkStockDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
                id: '',
				...data
			};
			const res = await hgetRequest('hwms/checkStock/getbykey', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 盘点状态修改
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
 export const checkStocksetdoneService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
                id: '',
				done: null,
				...data
			};
			const res = await hgetRequest('hwms/checkStock/setdone', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default checkStockListService;
