<template>
	<el-form
		ref="formEle"
		:model="formData"
		size="small"
		label-width="130px"
		v-loading="loadingImgUpload"
		element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<el-tabs v-model="activeName">
			<el-tab-pane label="基本信息" name="second">
				<el-form-item label="医院名称" prop="name">
					<el-input :value="baseInfo.hospitalName" disabled />
				</el-form-item>

				<el-form-item label="科室" v-if="baseInfo.inspectionType == 0">
					<el-input v-model="baseInfo.hospitalOfficeName" disabled />
				</el-form-item>
				<el-form-item label="设备分类" v-else-if="baseInfo.inspectionType == 1">
					<el-input v-model="baseInfo.devTypesName" disabled />
				</el-form-item>

				<el-form-item label="负责人">
					<el-input v-model="baseInfo.dutyPeopleName" disabled />
				</el-form-item>
				<Table
					ref="tableDom"
					:data.sync="baseInfo.devList"
					:tableColumn_p="tableColumn_device"
					isHideCheckbox_p="true"
					v-loading="loadingDetail"
				>
					<el-table-column label="设备名称" prop="devCode" width="220">
						<template slot-scope="scope">
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoNumber }}
							</p>
							<p style="color:#333333;font-size:16px;font-weight:bold;">
								{{ scope.row.devDetail.devInfoName }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoSerialNo }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoDepartmentName }}
							</p>
						</template>
					</el-table-column>
					<el-table-column label="品牌型号" prop="devName" width="180">
						<template slot-scope="scope">
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.hospitalOfficeName }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoFactoryName }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoNns1 }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoBrand }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoTypeName }}
							</p>
							<p style="color:#606266;font-size:14px;">
								{{ scope.row.devDetail.devInfoNns3 }}￥
								{{
									scope.row.devDetail.devInfoEnableTime
										| parseTime('{y}-{m}-{d} ')
								}}
							</p>
						</template>
					</el-table-column>
					<el-table-column label="巡检结果" prop="price">
						<template slot-scope="scope">
							<p
								style="color:#606266;font-size:14px;"
								v-for="(item1, index1) in scope.row.checkResults"
								:key="index1"
							>
								{{ item1.itemname }}:{{ item1.checkresultTxt || '-' }}
							</p>
						</template>
					</el-table-column>
					<el-table-column label="费用" prop="price">
						<template slot-scope="scope">{{ scope.row.price }}￥</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							<div v-if="scope.row.isEdit">
								<el-input
									v-model="scope.row.remark"
									type="textarea"
									:rows="3"
								></el-input>
								<el-button type="text" @click="saveRemark(scope.row)">
									保存
								</el-button>
							</div>
							<div v-else>
								<span style="display:block">{{ scope.row.remark }}</span>
								<el-button type="text" @click="editRemarkHandler(scope.row)">
									编辑
								</el-button>
							</div>
						</template>
					</el-table-column>
				</Table>
			</el-tab-pane>
			<el-tab-pane label="附件列表" name="first">
				<el-upload
					v-show="!pHideUploadBen"
					class="upload-demo"
					:headers="headers"
					:data="uploadData"
					:on-progress="handlerProgress"
					:on-success="handlerSuccess"
					:action="uploadAction"
					:limit="3"
					:show-file-list="false"
					style="margin-bottom: 10px"
					v-if="!isHideAction_p"
				>
					<el-button size="small" type="primary" :disabled="status_p === 2">
						点击上传
					</el-button>
				</el-upload>
				<Table
					ref="tableDom"
					:data.sync="fileData"
					:tableColumn_p="tableColumn"
					isHideCheckbox_p="true"
				>
					<el-table-column label="文件名" align="left" fixed="right">
						<template slot-scope="scope">
							<!-- <el-link
								:href="$envConfig.imgHttpAdd + 'Inspection/'+scope.row.newName"
								type="primary"
								target="_blank"
							>{{ scope.row.oldName }}</el-link>-->

							<el-image
								class="devBaseFormLogoStr"
								style="width: 105px; height: 105px"
								:src="$envConfig.imgHttpAdd + 'Inspection/' + scope.row.newName"
								:preview-src-list="[
									$envConfig.imgHttpAdd + 'Inspection/' + scope.row.newName
								]"
								:fit="'contain'"
							></el-image>
						</template>
					</el-table-column>
					<el-table-column label="上传时间" align="left" fixed="right">
						<template slot-scope="scope">
							{{ scope.row.createDate | formatDate }}
						</template>
					</el-table-column>
					<el-table-column
						label="操作"
						align="left"
						fixed="right"
						width="200"
						v-if="!isHideAction_p"
					>
						<template slot-scope="scope">
							<el-button type="primary" @click="showDialogUpdateFileDate(scope.row)">
								修改上传时间
							</el-button>
							<el-button
								v-if="status_p !== 2"
								id="btn-update-row"
								type="danger"
								@click="deleteFile(scope.row)"
							>
								删除
							</el-button>
						</template>
					</el-table-column>
				</Table>
				<el-form-item>
					<div style="text-align: right; margin-top: 20px">
						<span class="dialog-footer">
							<el-button type="primary" @click="formSubmit">确 定</el-button>
						</span>
					</div>
				</el-form-item>
			</el-tab-pane>
		</el-tabs>
		<Dialog title="修改时间" :visible.sync="isShowDialogUpdateFileDate" width="20%" top="30vh">
			<el-form :model="itemForm" :rules="itemFormRules" ref="itemForm" label-width="100px">
				<el-form-item label="时间">
					<el-date-picker
						v-model="itemForm.date"
						type="datetime"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
			</el-form>

			<el-form-item style="text-align: right; margin-top: 20px">
				<el-button type="primary" @click="submitItemForm('itemForm')">提交</el-button>
			</el-form-item>
		</Dialog>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { inspectionDetailService } from '@s/company/inspectionTemp/InspectionService';
import {
	inspectionFileListService,
	inspectionFileDeleteService,
	inspectionUpdatepicdateService,
	inspectionImplementStatusService,
	saveRemarkService
} from '@s/company/inspectionTemp/InspectionImplementService';
import Table from '@c/ui/Table';
import Dialog from '@c/ui/Dialog.vue';
import { hgetStorage, htimeFormat } from '../../../util/htools.web';

export default {
	props: ['editId_p', 'status_p', 'isHideAction_p', 'pHideUploadBen'],
	components: {
		Table,
		Dialog
	},
	data() {
		return {
			loadingDetail: true,
			isShowDialogUpdateFileDate: false,
			itemForm: { id: null, date: new Date() },
			itemFormRules: {
				date: [{ required: true, message: '请选择日期', trigger: 'change' }]
			},
			loadingImgUpload: false,
			editId: -1,
			formData: {
				inspectionTypeId: null,
				name: '',
				isDel: 0
			},
			uploadAction: this.$envConfig.inspectionFileUploadUrl,
			activeName: 'second',
			tableColumn: [
				// {
				// 	label: '文件名',
				// 	field: 'oldName'
				// }
				/* {
					label: '提交人',
					field: 'listNum'
				}, */
			],
			fileData: [],
			baseInfo: {},
			tableColumn_device: [
				/* {
					label: '科室名称',
					field: 'devInfoDepartmentName'
				}, */
				// {
				// 	label: '设备编号',
				// 	field: 'devCode'
				// },
				// {
				// 	label: '设备名称',
				// 	field: 'devName'
				// }
			],
			headers: {}
		};
	},
	created() {
		this.headers = {
			Authorization: hgetStorage('token')
		};
		this.uploadData = {
			id: this.editId_p
		};
	},
	mounted() {
		console.log(this.editId_p);
		this.fileList();
		this.inspectionDetail();
	},
	methods: {
		editRemarkHandler(row) {
			row.isEdit = true;
		},
		handlerProgress() {
			this.loadingImgUpload = true;
		},
		handlerSuccess(res) {
			console.log(res);
			this.fileList();
		},
		async showDialogUpdateFileDate(row) {
			row.date = row.createDate;
			this.itemForm = { ...row };
			this.isShowDialogUpdateFileDate = true;
		},
		async submitItemForm() {
			console.log(this.itemForm);
			const dataJson = {
				id: this.itemForm.id,
				date: htimeFormat(new Date(this.itemForm.date), '{y}-{m}-{d} {h}:{i}:{s}')
			};
			const res = await inspectionUpdatepicdateService(dataJson);
			console.log(res);
			this.fileList();
			this.isShowDialogUpdateFileDate = false;
		},
		async deleteFile(row) {
			const dataJson = {
				ids: [row.id]
			};
			const res = await inspectionFileDeleteService(dataJson);
			console.log(res);
			this.fileList();
		},
		async fileList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000,
				inspectionId: this.editId_p
			};
			const res = await inspectionFileListService(dataJson);
			console.log(res);
			this.fileData = res;
			this.loadingImgUpload = false;
		},
		async inspectionDetail() {
			const dataJson = {
				inspectionId: this.editId_p
			};
			const res = await inspectionDetailService(dataJson);
			console.log(res);
			res.devList.forEach(item => {
				item.isEdit = false;
			});
			this.loadingDetail = false;
			this.baseInfo = res;
		},
		async saveRemark(row) {
			const dataJson = {
				id: row.id,
				memo: row.remark
			};
			const res = await saveRemarkService(dataJson);
			console.log(res);
			row.isEdit = false;
		},
		async formSubmit() {
			if (this.status_p !== 2) {
				const dataJson = {
					inspectionId: this.editId_p
				};
				await inspectionImplementStatusService(dataJson);
			}
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}

/deep/.el-table__fixed-right::before,
/deep/ .el-table__fixed::before {
	background: none;
}
</style>
