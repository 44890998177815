<template>
	<div class="app-container">
		<InspectionSearchForm @searchForm="searchFormHandler"></InspectionSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'inspection'"
		></Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
		>
			<el-table-column label="巡检编号" prop="listNum">
				<template slot-scope="scope">
					<p>{{ scope.row.listNum }}</p>
				</template>
			</el-table-column>
			<el-table-column label="计划负责人" prop="dutyPeopleName"></el-table-column>
			<el-table-column label="计划实施日期" prop="executionTime">
				<template slot-scope="scope">
					{{ scope.row.executionTime | parseTime('{y}-{m}-{d}') }}
				</template>
			</el-table-column>
			<el-table-column label="设备数量" prop="devNumber">
				<template slot-scope="scope">
					{{ scope.row.devNumber }}({{ scope.row.allprice }}￥)
				</template>
			</el-table-column>
			<el-table-column label="医院" prop="hospitalName"></el-table-column>
			<el-table-column label="科室/分类" prop="hospitalOfficeName" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<template v-if="scope.row.inspectionType == 0">
							{{ scope.row.hospitalOfficeName }}
					</template>
					<template v-else-if="scope.row.inspectionType == 1">
						{{ scope.row.devTypesName }}
					</template>
				</template>
			</el-table-column>
			<el-table-column label="状态" prop="state">
				<template slot-scope="scope">
					<el-link v-if="Number(scope.row.state) === 0" type="warning" :underline="false">
						未接单
					</el-link>
					<el-link
						v-else-if="Number(scope.row.state) === 1"
						type="primary"
						:underline="false"
					>
						进行中
					</el-link>
					<el-link
						v-else-if="Number(scope.row.state) === 2"
						type="success"
						:underline="false"
					>
						已完成
					</el-link>
					<el-link v-else type="success" :underline="false"></el-link>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="220">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'inspection', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
						:disabled="Number(scope.row.state) === 2"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'inspection', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>

					<!-- <el-button type="success" @click="downloadExcelService(scope.row)">导出</el-button> -->
					<el-button type="success">
						<el-link
							style="color:#ffffff;"
							:underline="false"
							:href="
								`${$envConfig.lzlong_2}inspection/downloadExcel?inspectionId=${
									scope.row.id
								}&token=${encodeURIComponent(token)}`
							"
							target="_blank"
						>
							导出
						</el-link>
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<InspectionAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></InspectionAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	inspectionListService,
	inspectionDeleteService,
	inspectionDetailService
} from '@s/company/inspectionTemp/InspectionService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import InspectionAddForm from '@f/company/inspectionTemp/InspectionAdd.form';
import InspectionSearchForm from '@f/company/inspectionTemp/InspectionSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'Inspection',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		InspectionAddForm,
		InspectionSearchForm
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.inspectionList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.inspectionList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.inspectionList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.inspectionList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.token = hgetStorage('token');
		this.inspectionList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		// 导出表格
		// async downloadExcelService(row) {
		// 	console.log(row.id);
		// 	downloadExcelService({ inspectionId: row.id });
		// },
		async inspectionList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await inspectionListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				inspectionId: editId
			};
			const res = await inspectionDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await inspectionDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				inspectionId: row.id
			};
			const res = await inspectionDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.inspectionList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 1440px;
	overflow: hidden;
}
</style>
