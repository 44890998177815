<template>
  <el-container class="layout-container">
    <Aside :isCollapse_p="isCollapse"></Aside>
    <el-container style="margin-left: -17px; z-index: 1111">
      <el-header>
        <MHeader :isCollapse_p.sync="isCollapse"></MHeader>
      </el-header>
      <Tab @currentClose="getCloseTag"></Tab>
      <el-main style="position:relative; background:#fff" id="windowElMain">
        <keep-alive :exclude="tempNoKeepAlive">
          <router-view v-if="this.$route.meta.keepAlive" ref="routerView"></router-view>
        </keep-alive>
        <router-view v-if="!this.$route.meta.keepAlive"></router-view>
        <!-- TODO
				<keep-alive :include="cachedViews">
					<router-view :key="key"></router-view>
				</keep-alive> 
				-->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Aside from '../factory/Aside';
import Tab from '../factory/Tab';
import MHeader from '../factory/Header';

export default {
	name: 'layout',
	components: {
		Aside,
		Tab,
		MHeader
	},
	computed: {
		cachedViews() {
			return this.$store.state.tagsView.cachedViews;
		},
		key() {
			return this.$route.path;
		}
	},
	data() {
		return {
			isCollapse: false,
			tempNoKeepAlive: []
		};
	},
	methods: {
		getCloseTag(tagArr) {
			if (tagArr.length) {
				tagArr.forEach(item => {
					this.tempNoKeepAlive.push(item.name);
				});
			}
			setTimeout(() => {
				this.tempNoKeepAlive = [];
			}, 1000);
		}
	},
	updated() {}
};
</script>

<style scoped lang="less">
.layout-container {
	height: calc(100%);
}

.el-header {
	background-color: #304156;
	color: #bfcbd9;
	line-height: 60px;
}
</style>
