import {
    hpostRequest,
    hgetRequest
} from '../../util/htools.axios';

/*
 *@Description: 合同全部分类
 *@MethodAuthor:  zxh
 *@Date: 20220814
 */
export const typesListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/contract/types', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

/*
 *@Description: 合同搜索 列表
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const searchListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                pageNum: 1,
                pageSize: 10,
                ...data
            };
            const res = await hpostRequest('hwms/contract/search', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 添加合同
export const contractAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/contract/add', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 更新合同
export const contractUpdateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/contract/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 删除合同
export const contractDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/contract/delete', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 详情
export const contractDetailService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/contract/detail', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 常用甲方乙方
export const contractLeftAndRightService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/contract/leftAndRight', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

// 添加支付
export const contractAddPaymentService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/contract/addPayment', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 更新付款
export const contractUpdatePaymentService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/contract/updatePayment', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
// 删除支付
export const contractDeletePaymentService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/contract/deletePayment', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
export default typesListService;
