import * as moment from 'moment';
import { hgetStorage, hmonthRange, htimeFormat } from '@u/htools.web';
import { hgetRequest } from '../util/htools.axios';

/* 数据统计 ---- 顶部  医院 */
export const getCountService = () => {
	return new Promise(async (resolve, reject) => {
		const dataJson = {};
		try {
			const res = await hgetRequest('hcz/HosHome/getnumbers', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 数据统计 ---- 顶部  公司 */
export const getCountCompanyService = () => {
	return new Promise(async (resolve, reject) => {
		const dataJson = {};
		try {
			const res = await hgetRequest('hcz/CompHome/getnumbers', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};

/* 设备状态 --- 医院 */
export const deviceTotalListService = data => {
	const dataJson = {
		orgId: '',
		...data
	};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/hospitalBigScreen/NumberEquipment', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
}; /* 设备状态 --- 公司 */
export const deviceTotalListCompanyService = () => {
	const hosId = hgetStorage('organization');
	const myDate = new Date();
	// console.log();
	const curMonth = myDate.getMonth() + 1;
	const curYear = myDate.getFullYear();
	const curYM = `${curYear}-${curMonth}`;
	// console.log(curYM);
	const prevMonthes = hmonthRange(curYM, 5, 1);
	// console.log('prevMonthes');
	// console.log(prevMonthes);
	prevMonthes.shift();
	prevMonthes.push(curYM);
	const beginMonth = prevMonthes[1];
	let beginTime = `${beginMonth}-01 00:00:00`;
	// const endTime = `${curYM}-${hdateInMonth(beginMonth)} 23:59:59`;
	let endTime = htimeFormat(new Date().getTime(), `{y}-${curMonth + 1}-01 23:59:59`);
	endTime = new Date(endTime).getTime() - 1000 * 60 * 60 * 24;
	endTime = htimeFormat(endTime, '{y}-{m}-{d} 23:59:59');

	// 上面的都没用
	beginTime = moment(new Date())
		.subtract(3, 'months')
		.format('YYYY-MM-01 00:00:00');

	endTime = moment()
		.endOf('months')
		.format('YYYY-MM-DD HH:mm:ss');
	const dataJson = {
		begin: beginTime,
		end: endTime,
		compid: hosId
	};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/compBigScreen/CountSummary', dataJson);
			// console.log(res);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 设备分布占比 --- 医院 */
export const deviceDistributeService = () => {
	const dataJson = {};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/HosHome/devCntTop?topcnt=5', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 设备分布占比 --- 公司 */
export const deviceDistributeCompanyService = () => {
	const dataJson = {
		compid: hgetStorage('organization')
	};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/compBigScreen/CompHosDevCnt', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 设备状态记录 --- 医院 */
export const deviceStatusService = () => {
	const dataJson = {};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/HosHome/repairCnt', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 设备状态记录 --- 公司 */
export const deviceStatusCompanyService = () => {
	const dataJson = {};
	return new Promise(async (resolve, reject) => {
		try {
			const res = await hgetRequest('hcz/CompHome/repairCnt', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 底部表格数据 --- 医院 */
export const getHosTableListService = data => {
	return new Promise(async (resolve, reject) => {
		try {
			const dataJson = {
				pagenum: 1,
				pagesize: 10,
				...data
			};
			const res = await hgetRequest('hcz/HosHome/getHomeRepairList', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
/* 底部表格数据 --- 公司 */
export const getCompTableListService = data => {
	return new Promise(async (resolve, reject) => {
		try {
			const dataJson = {
				pagenum: 1,
				pagesize: 10,
				...data
			};
			const res = await hgetRequest('hcz/CompHome/getHomeRepairList', dataJson);
			resolve(res);
		} catch (error) {
			reject(error);
		}
	});
};
export default getCountService;
