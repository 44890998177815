<template>
  <div class="head-serch">
    <el-form class="form" ref="formEle" size="mini" :model="formData" label-width="80px" @submit.native.prevent @keyup.enter.native="searchHandler">
      <el-form-item label="标题">
        <el-input v-model="formData.keyword" size="mini" clearable placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="上传人">
        <el-select ref="multiSelect" v-model="formData.uid" placeholder="请选择申请人" class="select-item" filterable clearable>
          <el-option v-for="(user, index) in userData" :key="index" :label="user.nickName" :value="user.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker v-model="formData.begin" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker v-model="formData.end" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
    </el-form>
    <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';

export default {
	data() {
		return {
			// 搜索
			formData: {},
			userData: []
		};
	},
	created() {
		this.userList();
	},
	methods: {
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
			console.log(this.userData);
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
