<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="66px">
			<el-form-item label="标题" prop="title">
				<el-input v-model="formData.title" placeholder="请输入标准名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item label="文档" prop="files">
				<Upload
					:action="uploadAction"
					class="isShowUpload"
					:limit="1"
					:file-list="fileList"
					:on-success="handleSuccess"
					:on-remove="handleRemove"
					:isShowTip_p="true"
				>
					<el-alert title="如有多个文件，请上传压缩包" style="padding: 0" type="warning" :closable="false"></el-alert>
					<!-- <p>图片只能上传一张图片</p> -->
				</Upload>
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { docAddService, docEditService } from '@s/doc/DocService';
import Upload from '@c/ui/Upload.vue';
import { hgetStorage } from '@u/htools.web';

export default {
	components: {
		Upload
	},
	props: ['selectData_p', 'isRefreshList_p', 'typeid_p'],
	data() {
		return {
			editId: -1,
			currentOrgid: '',
			formData: {
				title: '',
				typeid: '',
                filename: '',
				files: ''
			},
			rules: {
				title: [
					{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}
				],
				orgid: [
					{
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}
				],
				typeid: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				files: [
					{
						required: true,
						message: '请上传文件',
						trigger: 'change'
					}
				]
			},
			parentName: '',
			typeData: null,
			fileList: [],
			fileTempList: [],
			uploadAction: `${this.$envConfig.lzlong}docs/${this.$envConfig.uploadCompanyDevImgList}`
		};
	},
	created() {
		this.currentOrgid = hgetStorage('organization');
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				console.log(newValue);
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.fileList = [];
					this.fileList.push({
						name: newValue[0].filename,
						url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.norm}/${newValue[0].picture}`
					});
					console.log(this.fileList);
					this.formData = { ...newValue[0] };
					setTimeout(() => {
						this.isShowUpload();
					}, 30);
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--text');
			if (this.formData.files === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handleSuccess(res) {
			this.formData.files = res.result.path;
			if (!this.formData.title) {
				this.formData.title = res.result.oldName;
			}
            this.formData.filename = res.result.oldName;
			this.isShowUpload();
		},
		handleRemove() {
			this.formData.files = '';
			this.isShowUpload();
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					this.formData.typeid = this.typeid_p;
					if (this.editId === -1) {
						await docAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await docEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
