<template>
	<el-row>
		<el-col :span="24">
			<el-form
				ref="formEle"
				:model="formData"
				:rules="formRules"
				size="small"
				label-width="120px"
			>
				<el-form-item label="名称" prop="name">
					<el-input
						v-model="formData.name"
						clearable
						placeholder="请输入名称"
					/>
				</el-form-item>
				<el-form-item label="编码" prop="code">
					<el-input
						v-model="formData.code"
						clearable
						placeholder="请输入编码"
					/>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input
						v-model="formData.address"
						clearable
						placeholder="请输入地址"
					/>
				</el-form-item>
				<el-form-item label="负责人" prop="dutys">
					<el-select
						ref="multiSelect"
						v-model="formData.dutys"
						multiple
						placeholder="请选择负责人"
						class="select-item"
						filterable
						clearable
					>
						<el-option
							v-for="(user, index) in userData"
							:key="index"
							:label="user.nickName"
							:value="user.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="医院" prop="hosid">
					<el-select
						v-model="formData.hosid"
						placeholder="请选择医院"
						clearable
						filterable
						style="width: 100%"
					>
						<el-option
							v-for="item in optionsCompany"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import {
	warehouseAddService,
	warehouseEditService
} from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
// 选择医院 下拉
import { getcompanysignListService } from '@s/company/contract/ContractService';

export default {
	props: ['selectData_p'],
	components: {},
	data() {
		return {
			editId: -1,
			// 弹窗
			formData: {
				name: '',
				code: '',
				address: '',
				dutys: []
			},
			formRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				code: [
					{
						required: true,
						message: '请输入编码',
						trigger: 'blur'
					}
				],
				address: [
					{
						required: true,
						message: '请输入地址',
						trigger: 'blur'
					}
				],
				dutys: [
					{
						required: true,
						message: '请选择负责人',
						trigger: 'change'
					}
				],
				hospitalId: [
					{
						required: true,
						message: '请选择医院',
						trigger: 'change'
					}
				]
			},
			optionsCompany: [],
			userData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.userData.length === 0 && (await this.userList());
					this.optionsCompany.length === 0 &&
						(await this.getCompanyList());
					this.editId = newValue[0].id;
					if (typeof newValue[0].dutys === 'string') {
						newValue[0].dutys = newValue[0].dutys.split(',');
						newValue[0].dutys.forEach((id, index) => {
							newValue[0].dutys[index] = Number(id);
						});
					}
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.userList();
		this.getCompanyList();
	},
	methods: {
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				state: 0
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
		},

		//  * 医院 维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});
			this.optionsCompany = res.records;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await warehouseAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await warehouseEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped></style>
