import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 保养保养列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const inspectionListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				state: '',
				devId: '',
				dutyPeople: '',
				hospitalId: '',
				hospitalOfficeId: '',
				...data
			};
			const res = await hpostRequest('hlzlong_4/inspection/inspectionAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 保养计划负责人列表
 *@ClassAuthor: myw
 *@Date: 2021-06-04 13:54:38
 */
export const inspectionUserListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/getUserByCompanyId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const inspectionAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: 0,
				userId: 0,
				...data
			};
			const res = await hpostRequest('hlzlong_4/inspection/inspectionAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const inspectionEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hlzlong_4/inspection/inspectionUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const inspectionDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong_4/inspection/inspectionDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 工厂详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
 export const inspectionDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionId: '1',
				...data
			};
			// const res = await hgetRequest('hlzlong_4/inspection/inspectionByIdQuery', dataJson);
			const res = await hgetRequest('hlzlong_4/inspection/inspectionByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂下载表格
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const downloadExcelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionId: '1',
				...data
			};
			const res = await hgetRequest('hlzlong_4/inspection/downloadExcel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 获取父级科室下所有子科室[],保养设备【保养设备】按钮先调张龙的接口返回list给薄的设备列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
 export const getSonIdsByDepartmentIdService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hlzlong_1/department/getSonIdsByDepartmentId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default inspectionListService;
