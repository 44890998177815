<template>
	<div class="app-container">
		<!-- <DevBaseSearchForm @searchForm="searchFormHandler"></DevBaseSearchForm> -->
		<!-- <Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devBase'"
		>
			<template v-slot:lastBtn>
				<el-button type="warning" @click="handleNoSelection">取消所有选择</el-button>
				<el-button type="primary" @click="importDev">导入导出</el-button>
				<el-button
					type="default"
					@click="showBatchMaintenanceDialog"
					:disabled="selectData.length < 1"
				>
					批量修改维保信息
				</el-button>
				<el-select
					filterable
					v-model="printType"
					placeholder="请选择切换打印机"
					:disabled="selectData.length < 1"
					style="margin-left:20px;"
				>
					<el-option
						v-for="item in printList"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
				<el-button
					type="default"
					@click="showPrintDialog"
					:disabled="selectData.length < 1"
				>
					批量打印设备二维码
				</el-button>
			</template>
		</Button> -->
		<!--表格渲染-->
		<Table ref="tableDom" class="tableCopy" :data.sync="tableData" :tableColumn_p="tableColumn"
			:selectData_p.sync="selectData" :isHideCheckbox_p="true" :row-key="getRowKey"
			@selection-change="handleSelectionChange">
			<!-- @select="selectDevBaseData" -->
			<el-table-column type="selection" :reserve-selection="true" width="55" />
			<el-table-column label="ID" prop="devInfoId" width="100"></el-table-column>
			<el-table-column label="设备编号" prop="devInfoNumber"></el-table-column>
			<el-table-column label="设备名称" prop="devInfoName"></el-table-column>
			<el-table-column label="设备类型" prop="devInfoTypeName"></el-table-column>
			<el-table-column label="规格型号" prop="devInfoNns1"></el-table-column>
			<el-table-column label="所在科室" prop="devInfoDepartmentName">
				<template slot-scope="scope">
					{{ scope.row.devInfoDepartmentName }}
					<span style="color:#67C23A;" v-if="scope.row.useType === 1">(在账在用)</span>
					<span style="color:#F56C6C;" v-else-if="scope.row.useType === 2">
						(不在账在用)
					</span>
					<span style="color:#E6A23C;" v-else-if="scope.row.useType === 3">
						(在账不在用)
					</span>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column label="购入价格" prop="devInfoNns3"></el-table-column>
			<el-table-column label="购入日期" prop="devInfoEnableTime"></el-table-column>
			<el-table-column label="厂家维保到期时间">
				<template slot-scope="scope">
					<span style="color:#F56C6C;" v-if="scope.row.factoryouttime == 3">{{ scope.row.maintainDate }}</span>
					<span style="color:#E6A23C;" v-else>{{ scope.row.maintainDate }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="维保公司" prop="orgName" width="100"></el-table-column> -->

			<el-table-column label="操作" align="left" fixed="right" width="220">
				<template slot-scope="scope">
					<el-button id="btn-view-row" type="success" @click="viewSingleHandler(scope.row)" size="mini">
						查看
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize" :pager-count="5"></Pagination>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	devBaseListService,
	devBaseDeleteService,
	devBaseDetailService,
	allocatedEquipmentService
} from '@s/hospital/devBase/DevBaseService';

// 获取维修列表
// import { organizationAllQueryListService } from '@s/hospital/contract/ContractService';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
// import Dialog from '@c/ui/Dialog';
// import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
// import DevBaseAddForm from '@f/hospital/devBase/DevBaseAdd.form';
// import DevBaseSearchForm from '@f/hospital/devBase/DevBaseSearch.form';
// import PrintDev from '@f/hospital/devBase/PrintDev';
// import PrintDev7050 from '@f/hospital/devBase/PrintDev7050';
// import RepairAdd from '@f/repair/RepairAdd.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'DevBase',
	mixins: [ListMixin],
	components: {
		Table,
		// Dialog,
		Pagination
		// Button,
		// DevBaseAddForm,
		// DevBaseSearchForm,
		// PrintDev,
		// PrintDev7050,
		// RepairAdd
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 打印机类型
			printType: 0,
			printList: [
				{ id: 0, name: 'Gprinter GP-3120TU (50*40)' },
				{ id: 1, name: 'Gprinter GP-1134T (70*50)' }
			],
			isShowPrintDialog5040: false,
			isShowPrintDialog7050: false,
			// 批量修改维保信息 弹框
			dialogVisibleBatchMaintenance: false,
			// 批量修改维保信息 表单
			batchMaintenanceForm: {
				timeArr: [],
				transferBatchNumber: 0
			},
			batchMaintenanceRules: {},
			optionsCompany: [],
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			isShowAERepairDialog: false,
			// 导入
			isShowimportDevDialog: false,
			uploadLogoAction: '',
			timerUploadUrl: null,
			disabledUpload: true,
			headers: { Authorization: 1, token: 2 },
			// uploadLogoAction: `${this.$envConfig.btyong_5}devBase/${this.$envConfig.uploadCompanyDevImgList}`,
			fileList: [],
			departErrors: null,
			devErrors: null,
			facErrors: null,
			limitNum: 1,

			repairDevId: '',
			repairDevTypeId: '',
			repairDepartmentId: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		// selectData(newValue) {
		// },
		'batchMaintenanceForm.timeArr'(newValue) {
			if (newValue && newValue.length !== 2) {
				return false;
			}
			// eslint-disable-next-line radix
			const day = Math.ceil(
				(new Date(newValue[1]).getTime() - new Date(newValue[0]).getTime()) /
				1000 /
				3600 /
				24
			);
			this.batchMaintenanceForm.transferBatchNumber = day;
		},
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.devBaseList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.devBaseList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.devBaseList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.devBaseList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	created() { },
	mounted() {
		this.devBaseList();
		//  *维修公司 下拉
		// this.getCompanyList();
		this.currentUserOrganization = hgetStorage('organization');
		// this.selectData = localStorage.getItem('selectDataAll');

		// this.$nextTick(() => {
		// 	const aaa = {
		// 		affiliatedHospitalId: 35,
		// 		devInfoId: 99,
		// 		devInfoName: '123',
		// 		devInfoState: 0,
		// 		devInfoType: '14'
		// 	};

		// 	// console.log(this.$refs.tableDom);
		// 	this.$refs.tableDom.toggleRowSelection(aaa, true);
		// 	this.tableData.forEach(row => {
		// 		if (this.selectData.indexOf(row.devInfoId) >= 0) {
		// 			this.$refs.tableDom.toggleRowSelection(row, true);
		// 		}
		// 	});
		// });

		this.timerUploadUrl = setInterval(() => {
			if (this.$envConfig) {
				clearInterval(this.timerUploadUrl);
				this.uploadLogoAction = `${this.$envConfig.cz}export/importDev`;
				this.disabledUpload = false;
			}
		}, 100);

		this.headers.Authorization = hgetStorage('token');
	},
	methods: {
		addRepairHandler(item) {
			this.repairDevId = item.devInfoId;
			this.repairDevTypeId = item.devInfoType;
			this.repairDepartmentId = item.devInfoDepartmentId;
			console.log('this.headers');
			console.log(this.headers);
			this.isShowAERepairDialog = true;
		},
		// 导出
		exportDev() {
			window.open(
				`https://ygongyun.com/web/comp/api/export/exportDev?token=${encodeURIComponent(
					hgetStorage('token')
				)}`
			);
		},
		// 导入
		importDev() {
			this.isShowimportDevDialog = true;
		},
		importDevSuccess(res, file) {
			if (res.code !== 200) {
				this.$message.warning('导入有误，请联系管理员!');
				this.fileList = [];
				return;
			}
			if (res?.result) {
				this.departErrors = res.result.departErrors;
				this.devErrors = res.result.devErrors;
				this.facErrors = res.result.facErrors;
			}
			if (
				res.result.departErrors === null &&
				res.result.devErrors === null &&
				res.result.facErrors === null
			) {
				this.$alert('导入成功!', '', {
					confirmButtonText: '确定',
					type: 'success',
					dangerouslyUseHTMLString: true,
					showClose: false,
					customClass: 'uploadExcelSuccess',
					callback: action => {
						this.devBaseList();
						this.isShowimportDevDialog = false;
						this.fileList = [];
					}
				});
			} else {
				this.fileList = [];
			}
		},
		importDevRemove(file, fileList) {
			this.departErrors = null;
			this.devErrors = null;
			this.facErrors = null;
		},
		importDevExceedFile(files, fileList) {
			this.$message.warning(`只能选择 ${this.limitNum} 个文件`);
		},
		importBeforeUploadFile(file) {
			console.log('before upload');
			console.log(file);
			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const size = file.size / 1024 / 1024;
			if (extension !== 'xlsx') {
				this.$message.warning('只能上传后缀是.xlsx的文件');
			}
			if (size > 10) {
				this.$message.warning('文件大小不得超过10M');
			}
		},
		importDevhandleRemove(file, fileList) {
			console.log(file, fileList);
		},

		importDevbeforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		// 批量修改维保信息
		showBatchMaintenanceDialog() {
			this.dialogVisibleBatchMaintenance = true;
		},
		//  *维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});
			// const res = await organizationAllQueryListService({
			// 	pageNum: 1,
			// 	pageSize: 999
			// });
			this.optionsCompany = res.records;
		},
		// 表格分页多选
		handleSelectionChange(val) {
			this.selectData = val;
		},
		handleSelectionChange1() {
			console.log(this.$refs.tableDom);
			if (this.tableData) {
				this.tableData.forEach(row => {
					this.$refs.tableDom.$children[0].toggleRowSelection(row);
				});
			}
		},
		handleNoSelection() {
			this.$refs.tableDom.clearSelection();
		},
		getRowKey(row) {
			// console.log(row);
			return row.devInfoId;
		},
		// 提交批量维保信息
		async submitBatchMaintenance() {
			const devInfoIdsTemp = [];
			this.selectData.forEach(item => {
				devInfoIdsTemp.push(item.devInfoId);
			});

			this.batchMaintenanceForm.signBeginTime =
				this.batchMaintenanceForm.timeArr && this.batchMaintenanceForm.timeArr[0];
			this.batchMaintenanceForm.signEndTime =
				this.batchMaintenanceForm.timeArr && this.batchMaintenanceForm.timeArr[1];
			this.batchMaintenanceForm.devInfoIds = devInfoIdsTemp;
			delete this.batchMaintenanceForm.timeArr;
			await allocatedEquipmentService(this.batchMaintenanceForm);

			this.$message.success('批量修改成功！');
			this.dialogVisibleBatchMaintenance = false;
			this.devBaseList();
		},
		async devBaseList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm,
				factoryouttime: ['2', '3']
			};
			const res = await devBaseListService(dataJson);

			this.listMixin(res);
			console.log(this.tableData);
			console.log(this.tableData);
			this.tableData.forEach(item => {
				console.log(item.maintainDate);
				console.log();
				item.factoryouttime = (new Date(item.maintainDate).getTime() - new Date().getTime()) > 0 ? 2 : 3;
			});
			// setTimeout(() => {
			// 	const elH = window.document
			// 		.querySelector('.tableCopy .el-table__header-wrapper')
			// 		.cloneNode(true);
			// 	const elB = window.document.querySelector('.tableCopy .el-table__body-wrapper');
			// 	console.log('-------------------------');
			// 	console.log(elH);
			// 	elH.querySelector('.el-checkbox').click = this.handleSelectionChange();
			// 	console.log(elB);
			// 	elB.after(elH);
			// }, 1000);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async viewSingleHandler(row) {
			// this.$router.push({ name: 'DevBaseDetai', query: { devId: row.devInfoId } });
			this.$router.push({
				name: 'DevBaseDetai',
				query: { type: 'hospital', devId: row.devInfoId }
			});
		},
		async editSingleHandler(row, type) {
			const dataJson = {
				devId: row.devInfoId
			};
			const res = await devBaseDetailService(dataJson);
			res.addType = type;
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// console.log(row);
			// return false;
			this.deleteSingleHandlerMixin(row);
			// this.$confirm(this.delTips || '确认此操作吗？', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(async () => {
			// 		const ids = [];
			// 		ids.push(row.devInfoId);
			// 		const dataJson = {
			// 			ids: ids
			// 		};
			// 		await devBaseDeleteService(dataJson);
			// 		this.isRefreshList = true;
			// 	})
			// 	.catch(error => {
			// 		console.log(error);
			// 	});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.devBaseList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.devInfoId;
		},
		showPrintDialog() {
			if (this.printType === 0) {
				this.isShowPrintDialog5040 = true;
			} else if (this.printType === 1) {
				this.isShowPrintDialog7050 = true;
			} else {
				this.$message.warning('请联系管理员！');
			}
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 1100px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 30%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-form-item {
	width: initial;
}

/deep/ .dialogVisibleBatchMaintenanceClass .textArea {
	width: 100%;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-dialog__body {
	overflow: hidden;
}

.uploadExcelDialog {}

/deep/ .uploadExcelDialog .el-dialog .el-dialog__body {
	padding-top: 0 !important;
}

.uploadErr .errTitle {
	font-size: 18px;
	font-weight: bold;
}

.uploadErr p {
	line-height: 26px;
}

.my-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.uploadDes {}

.uploadDes h3 {}

.uploadDes ul li {
	line-height: 26px;
}

/deep/ .pagination-container {
	// display: flex;
	// justify-content: space-between;
}
</style>
<style>
.uploadExcelSuccess .el-message-box__content .el-message-box__container {
	padding: 10px 5px;
	background: #f0f9eb !important;
	color: #67c23a;
}
</style>
