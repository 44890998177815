import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 三方项目组工作量统计
 *@ClassAuthor: Happy zxh
 *@Date: 2023-04-03 14:29:02
 */
export const compworksService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				// pageNum: 1,
				// pageSize: 10,
				begin: null,
				end: null,
				hosid: [],
				...data
			};
			delete dataJson.pageNum;
			delete dataJson.pageSize;
			const res = await hpostRequest('hcz/compwork/compworks', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default compworksService;
