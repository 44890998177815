import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 常见故障列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:33
 */
export const problemListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 常见故障添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:41
 */
export const problemAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				parameterKey: '',
				parameterValue: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 常见故障编辑
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:48
 */
export const problemEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				parameterKey: '',
				parameterValue: '',
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 常见故障详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:50:57
 */
export const problemDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				parameterId: '',
				...data
			};
			const res = await hgetRequest('hbase/parameter/parameterByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 常见故障删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-12 10:51:08
 */
export const problemDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				parameterId: [],
				...data
			};
			const res = await hpostRequest('hbase/parameter/parameterDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default problemListService;
