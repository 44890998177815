<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<PriceAskSearchform @searchForm="searchFormHandler"></PriceAskSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips"
					:authorize_p="'priceAsk'" :isHide_p="{ removeBtn: true, updateBtn: true }"></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData"
			isHideCheckbox_p="false">
			<el-table-column property="id" label="ID" width="60"></el-table-column>
			<el-table-column property="goodsName" label="配件"></el-table-column>
			<el-table-column property="goodsMemo" label="配件要求"></el-table-column>
			<el-table-column property="warehouseName" label="库房"></el-table-column>
			<el-table-column property="hosdepart" label="科室"></el-table-column>

			<el-table-column label="日期" width="150">
				<template slot-scope="scope">
					{{ scope.row.createTime | parseTime }}
				</template>
			</el-table-column>
			<el-table-column property="title" label="设备名称"></el-table-column>
			<el-table-column property="devXH" label="型号"></el-table-column>
			<el-table-column property="devSN" label="SN"></el-table-column>

			<el-table-column label="报价人" width="150">
				<template slot-scope="scope">
					<template v-if="scope.row.prices.length > 0 && scope.row.isMainPrice || isAdmin">
						<span v-for="item in scope.row.prices" :key="item.id" class="mr-10" style="margin-right:10px;">
							<span v-if="item.price" class="priceGreen">

								<el-popover placement="top-start" :title="item.supplier.name || ''" width="200"
									trigger="hover">
									<p>
										<span>{{ item.supplier.telphone }}</span>
									</p>
									<span slot="reference">
										{{ item.creatorName }}({{ item.price }})
									</span>
								</el-popover>

								<!-- {{ item.creatorName }}({{ item.price }}) -->
							</span>
							<span v-else class="priceOrange">{{ item.creatorName }}</span>
						</span>
					</template>
					<template v-if="!scope.row.isMainPrice && scope.row.pricesItem">
						<span v-if="scope.row.pricesItem.price" class="priceGreen">
							<el-popover placement="top-start" :title="scope.row.pricesItem.supplier.name || ''" width="200" trigger="hover">
								<p>
									<span>{{ scope.row.pricesItem.supplier.telphone }}</span>
								</p>
								<span slot="reference">
									{{ scope.row.pricesItem.creatorName }}({{ scope.row.pricesItem.price }})
								</span>
							</el-popover>
							<!-- {{scope.row.pricesItem.creatorName }}({{ scope.row.pricesItem.price }}) -->
						</span>
						<span v-else class="priceOrange">{{ scope.row.pricesItem.creatorName }}</span>
					</template>

				</template>
			</el-table-column>

			<!-- <el-table-column label="操作" align="left" fixed="right" width="380"> -->
			<el-table-column label="操作" align="left" fixed="right" width="380">
				<template slot-scope="scope">
					<template v-if="scope.row.isMainPrice || isAdmin">
						<el-button id="btn-update-row" type="primary" @click="toDetail(scope.row)">
							详情
						</el-button>
						<el-button id="btn-update-row" type="primary"
							v-authorize="{ name: 'update', type: 'priceAsk', id: 'btn-update-row' }"
							@click="toFinish(scope.row)" :disabled="scope.row.state == 1">
							完成
						</el-button>
						<el-button id="btn-update-row" type="primary"
							v-authorize="{ name: 'update', type: 'priceAsk', id: 'btn-update-row' }"
							@click="editSingleHandler(scope.row)" :disabled="scope.row.state == 1">
							编辑
						</el-button>
						<el-button id="btn-remove-row" type="danger"
							v-authorize="{ name: 'remove', type: 'priceAsk', id: 'btn-remove-row' }"
							@click="deleteSingleHandler(scope.row)" :disabled="scope.row.state == 1">
							删除
						</el-button>
					</template>
					<template v-if="scope.row.pricesItem">
						<el-button id="btn-update-row" type="success" v-if="!scope.row.pricesItem.price"
							@click="toAddPrice(scope.row)" :disabled="scope.row.state == 1">
							报价
						</el-button>
						<el-button id="btn-update-row" type="success" v-else @click="toAddPrice(scope.row)"
							:disabled="scope.row.state == 1">
							修改报价
						</el-button>
					</template>

				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog" width="30%">
			<PriceAskAddform v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList" :selectData_p="selectData"></PriceAskAddform>
		</Dialog>

		<Dialog title="报价" :visible.sync="isShowPrice" width="50%">
			<el-form ref="formEle" :model="formData1" :rules="formRules1" size="small" label-width="80px">

				<el-form-item label="价格" prop="price">
					<el-input v-model="formData1.price" type="number" placeholder="请输入名称" clearable />
				</el-form-item>

				<el-form-item label="供应商" prop="supplierId">
					<el-select v-model="formData1.supplierId" clearable filterable class="fastChooseSupplier"
						@keyup.native="getSupplierVal($event)">
						<el-option v-for="(item, index) in supplierData" :key="index" :label="item.name"
							:value="item.id"></el-option>
						<p slot="empty" class="faseAddGood"><span @click="showFastAddSupplier">快速添加供应商</span></p>
					</el-select>
				</el-form-item>
				<el-form-item label="发票" prop="fapiao">
					<el-radio-group v-model="formData1.fapiao">
						<el-radio :label="item.value" v-for="item in typeList" :key="item.value">{{ item.name }}</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="备注" prop="memo">
					<el-input type="textarea" :rows="5" placeholder="请输入备注" v-model="formData1.memo"></el-input>
				</el-form-item>

				<el-form-item style="text-align:right;">
					<el-button type="primary" @click="setPriceDialog">确认</el-button>
					<el-button @click="closePriceDialog">取消</el-button>
				</el-form-item>
			</el-form>
		</Dialog>
		<!-- 快速添加供应商 -->
		<Dialog title="添加供应商" :visible.sync="isShowFastAddSupplier">
			<SupplierAddForm v-if="isShowFastAddSupplier" :isShowAEDialog_p.sync="isShowFastAddSupplier"
				:isRefreshFastAddSupplier_p.sync="isRefreshFastAddSupplier" @isToParentSupplierNmae_p="supplierList"
				:selectData_p="selectData"></SupplierAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	deleteService,
	searchService,
	getbyidService,
	changestateService,
	addpriceService,
	updatepriceService
} from '@s/spareParts/PriceAskService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import PriceAskAddform from '@f/spareParts/priceAsk/PriceAskAdd.form';
import PriceAskSearchform from '@f/spareParts/priceAsk/PriceAskSearch.form';
import SupplierAddForm from '@f/spareParts/supplier/SupplierAdd.form';
// eslint-disable-next-line import/no-cycle
import { supplierAllListService } from '@s/spareParts/SupplierService';

export default {
	name: 'PriceAsk',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		PriceAskAddform,
		PriceAskSearchform,
		SupplierAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			supplierData: [],
			// 快速添加供应商时 [{id:-1,name:输入的物料名称}]
			// 快速添加供应商
			isShowFastAddSupplier: false,
			selectData1: [],
			delTips: '',
			formData1: {
				askid: null,
				fapiao: null,
				memo: null,
				price: null,
				supplierId: null
			},
			isShowPrice: false,
			formRules1: {
				price: [
					{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}
				],
				supplierId: [
					{
						required: true,
						message: '请选择供应商',
						trigger: 'change'
					}
				]
			},
			typeList: [{
				value: '0',
				name: '无票',
				checked: true
			},
			{
				value: '1',
				name: '普票',
				checked: false
			},
			{
				value: '2',
				name: '专票',
				checked: false
			}
			],
			isAdmin: false
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增询价' : '编辑询价';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
			}
		},
		isRefreshFastAddSupplier(newValue) {
			if (newValue) {
				this.supplierList();
			}
		}
	},
	created() { },
	mounted() {
		this.getList();
		this.supplierList();

		// 获取roleIds 如果等于1或者2，则是三方或医院管理，显示按钮
		const roleIds = localStorage.getItem('roleIds') || '[]';
		const isAdmin = JSON.parse(roleIds).some(item => { return item == 1 || item == 2; });
		this.isAdmin = isAdmin;
	},
	methods: {
		async getList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await searchService(dataJson);
			this.listMixin(res);
			this.setPriceList(this.tableData);
		},

		// 设置报价人list  设置是否是发起询价人
		setPriceList(arr) {
			const uid = localStorage.getItem('id');
			arr.forEach((item1, index1) => {
				item1.isMainPrice = false;
				if (uid == item1.creator) {
					item1.isMainPrice = true;
				}
				if (!item1.invite) {
					item1.prices = [];
					return false;
				}
				item1.inviteIdArr = item1.invite.split(',');
				item1.inviteNameArr = item1.inviteName.split(',');
				item1.inviteArr = [];
				item1.inviteIdArr.forEach((item, index) => {
					item1.inviteArr.push({
						creator: item1.inviteIdArr[index],
						creatorName: item1.inviteNameArr[index],
						price: null
					});
				});
				item1.inviteArr.forEach((item2, index2) => {
					item1.prices.forEach(item3 => {
						if (item2.creator == item3.creator) {
							item1.inviteArr[index2] = item3;
						}
					});
				});
				item1.prices = [...item1.inviteArr];

				item1.prices.forEach(item2 => {
					if (item2.creator == uid) {
						item1.pricesItem = item2;
					}
				});
			});
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await getbyidService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids[0]
			};
			console.log(dataJson);
			await deleteService(dataJson);
			this.isRefreshList = true;
		},
		toDetail(row) {
			console.log('row');
			console.log(row);
			this.$router.push({
				name: 'PriceAskDetail',
				query: { id: row.id }
			});
		},
		async toFinish(row) {
			const dataJson = {
				id: row.id,
				state: 1
			};
			this.$confirm('确认完成吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const res = await changestateService(dataJson);
					console.log(res);
					this.$message.success('操作成功！');
					this.getList();
				})
				.catch(error => {
					console.log(error);
				});
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await getbyidService(dataJson);
			res.inviteArr = res.invite?.split(',') || [];
			const inviteArr = [];
			res.inviteArr.forEach(item => {
				inviteArr.push(Number(item));
			});
			res.inviteArr = inviteArr;
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		// 报价 修改报价
		toAddPrice(row1) {
			this.initForm1();
			const row = row1;

			this.formData1.askid = row.id;
			this.formData1.fapiao = '1';
			if (row.pricesItem.price) {
				this.formData1.fapiao = row.pricesItem.fapiao.toString();
				this.formData1.memo = row.pricesItem.memo;
				this.formData1.supplierId = row.pricesItem.supplierId;
				this.formData1.price = row.pricesItem.price;
				this.formData1.id = row.pricesItem.id;
			}
			this.isShowPrice = true;
		},
		// 提交报价
		setPriceDialog() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.formData1.id) {
						this.updatePrice();
					} else {
						this.addPrice();
					}
					this.closePriceDialog();
				}
			});
		},

		async addPrice() {
			const dataJson = {
				...this.formData1
			};
			const res = await addpriceService(dataJson);
			res && this.$message.success('操作成功');
			this.getList();
		},
		async updatePrice() {
			const dataJson = {
				...this.formData1
			};
			const res = await updatepriceService(dataJson);
			res && this.$message.success('操作成功');
			this.getList();
		},
		// 初始化报价form
		initForm1() {
			this.formData1 = {
				askid: null,
				fapiao: null,
				memo: null,
				price: null,
				supplierId: null
			};
		},
		closePriceDialog() {
			this.isShowPrice = false;
		},

		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		},

		async supplierList(supplierName) {
			// supplierName 子组件新加的文字label
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;

			supplierName &&
				this.supplierData.forEach(item => {
					if (item.name === supplierName) {
						this.formData1.supplierId = item.id;
					}
				});
			// supplierName &&
			// 	this.tableData.forEach(item => {
			// 		if (item.isAddDetail) {
			// 			item.supplierId = supplierId;
			// 		}
			// 	});
		},
		// 快速添加供应商
		showFastAddSupplier() {
			this.isShowFastAddSupplier = true;
			this.selectData = [];
			this.selectData = this.selectData1;
		},
		getSupplierVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseSupplier input')?.value || '',
				id: -1
			});
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.numberp10 span {
	padding: 0 2px;
	display: inline-block;
}

.priceGreen {
	color: #67C23A;
}

.priceOrange {
	color: #E6A23C;
}

// 快速添加供应商 
.faseAddGood {
	padding: 10px;
	color: #cccccc;
}

.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
</style>
