import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 调拨在途列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const transferingListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,

				aboutids: [],
				froms: [],
				goods: [],
				tos: [],

				...data
			};
			const res = await hpostRequest('hwms/stock/waitmovein', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 调拨在途入库
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
 export const transferingAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				cnt: '',
				position: '',
				waitid: '',
				...data
			};
			const res = await hgetRequest('hwms/stock/addInFromWait', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default transferingListService;
