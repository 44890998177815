<template>
  <div class="app-container">
    <div class="head-container">
      <!-- 搜索区域 -->
      <MaterialSearchform @searchForm="searchFormHandler" ref="refMaterialSearchformSearchForm"></MaterialSearchform>
      <div>
        <!-- 功能按钮 -->
        <Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler" @showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips" :authorize_p="'material'">
          <template v-slot:lastBtn>
            <el-button type="primary" @click="exportExcel">导出</el-button>
          </template>
        </Button>
      </div>
    </div>
    <!--表格渲染-->
    <Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
      <el-table-column label="ID" width="100">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="物料编码" width="150">
        <template slot-scope="scope">
          {{ scope.row.code }}
        </template>
      </el-table-column>
      <el-table-column label="分类名称">
        <template slot-scope="scope">
          {{ scope.row.typeName }}
        </template>
      </el-table-column>
      <el-table-column label="物料名称">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="物料型号">
        <template slot-scope="scope">
          {{ scope.row.goodsVer }}
        </template>
      </el-table-column>
      <el-table-column label="适用设备">
        <template slot-scope="scope">
          {{ scope.row.forDev }}
        </template>
      </el-table-column>
      <el-table-column label="厂家">
        <template slot-scope="scope">
          {{ scope.row.factory }}
        </template>
      </el-table-column>
      <el-table-column label="适用设备规格型号">
        <template slot-scope="scope">
          {{ scope.row.forDevSpec }}
        </template>
      </el-table-column>
      <el-table-column label="管理方式" width="100">
        <template slot-scope="scope">
          {{ scope.row.manageTypeName }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-switch style="display: block" v-model="scope.row.enable" inactive-text="禁用" active-text="正常" @change="setEnable($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'material', id: 'btn-update-row' }" @click="toDetail(scope.row)">
            详情
          </el-button>
          <el-button id="btn-update-row" type="primary" v-authorize="{ name: 'update', type: 'material', id: 'btn-update-row' }" @click="editSingleHandler(scope.row)">
            编辑
          </el-button>
          <el-button id="btn-remove-row" type="danger" v-authorize="{ name: 'remove', type: 'material', id: 'btn-remove-row' }" @click="deleteSingleHandler(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize" :page-sizes="[10, 50, 100, 500]"></Pagination>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
      <MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" :selectData_p="selectData"></MaterialAddForm>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	materialDeleteService,
	materialListService,
	materialDetailService,
	materialsellChangeenableService
} from '@s/spareParts/MaterialService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import MaterialSearchform from '@f/spareParts/material/MaterialSearch.form';
import { hgetStorage, htimeFormat } from '@u/htools.web';

export default {
	name: 'Material',
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		MaterialAddForm,
		MaterialSearchform
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			tableDownData: [],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增物料' : '编辑物料';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.materialList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.materialList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		}
	},
	mounted() {
		this.materialList();
	},
	methods: {
		async materialList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await materialListService(dataJson);
			this.listMixin(res);
		},
		async getDownList() {
			this.searchForm = { ...this.$refs.refMaterialSearchformSearchForm.formData };
			const dataJson = {
				pageNum: 1,
				pageSize: 9999,
				...this.searchForm
			};
			const res = await materialListService(dataJson);
			this.tableDownData = res?.records || [];
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await materialDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = [...ids];
			await materialDeleteService(dataJson);
			this.isRefreshList = true;
		},
		toDetail(row) {
			console.log('row');
			console.log(row);
			this.$router.push({
				name: 'MaterialDetail',
				query: { id: row.id }
			});
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await materialDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.materialList();
		},
		// 是否启用
		async setEnable(e, item) {
			const data = {
				state: e,
				id: item.id
			};

			// this.$confirm('确认此操作？', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(async () => {
			await materialsellChangeenableService(data);
			this.materialList();
			this.$message.success('设置成功');
			// })
			// .catch(error => {
			// 	console.log(error);
			// 	this.materialList();
			// });
		},

		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},
		async exportExcel() {
			await this.getDownList();

			if (this.tableDownData.length == 0 || !this.tableDownData) {
				this.$message.warning('没有需要导出的数据');
				return false;
			}

			console.log('res 导出');
			console.log(this.searchForm);

			this.tableDownData.forEach(item => {
				item.enableName = item.enable ? '启用' : '禁用';
			});

			const tHeader = [
				'ID',
				'物料编码',
				'分类名称',
				'物料名称',
				'物料型号',
				'适用设备',
				'厂家',
				'适用设备规格型号',
				'管理方式',
				'状态'
			];
			const filterVal = [
				'id',
				'code',
				'typeName',
				'name',
				'goodsVer',
				'forDev',
				'factory',
				'forDevSpec',
				'manageTypeName',
				'enableName'
			];
			const data = this.formatJson(filterVal, this.tableDownData);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `物料${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});

			this.materialList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
