import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 列表
 *@ClassAuthor: Happy zxh
 *@Date: 2024-07-18 09:57:52
 */
export const listService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data,
				page: data.pageNum,
				pagesize: data.pageSize
			};
			// delete dataJson.pageNum;
			// delete dataJson.pageSize;

			const res = await hpostRequest(
				'hbtyong_5/badevent/search',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 添加
 *@ClassAuthor: Happy zxh
 *@Date: 2024-07-18 09:57:52
 */
export const addService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			dataJson.patiendsAgeType = Number(dataJson.patiendsAgeType);
			const res = await hpostRequest('hbtyong_5/badevent/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 编辑
 *@ClassAuthor: Happy zxh
 *@Date: 2024-07-18 09:57:52
 */
export const editService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			dataJson.patiendsAgeType = Number(dataJson.patiendsAgeType);
			const res = await hpostRequest(
				'hbtyong_5/badevent/update',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除
 *@ClassAuthor: Happy zxh
 *@Date: 2024-07-18 09:57:52
 */
export const deleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest(
				'hbtyong_5/badevent/deletes?apjson=apjson',
				JSON.stringify(dataJson.ids)
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 详情
 *@ClassAuthor: Happy zxh
 *@Date: 2024-07-18 09:57:52
 */
export const detailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				departmentId: '',
				...data
			};
			const res = await hgetRequest(
				'hbtyong_5/badevent/getbyid',
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default listService;
