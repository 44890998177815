<template>
	<div class="app-container">
		<FactorySearchForm @searchForm="searchFormHandler"></FactorySearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'hospitalfactory'"
		></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<!-- <el-table-column label="医院名称" prop="hospitalName"></el-table-column> -->
			<el-table-column label="厂家名称" prop="name"></el-table-column>
			<el-table-column label="厂家电话" prop="phone"></el-table-column>
			<el-table-column label="联系人名称" prop="contactName"></el-table-column>
			<el-table-column label="联系人电话" prop="contactNamePhone"></el-table-column>
			<!-- <el-table-column label="添加人" prop="createUserId"></el-table-column>
			<el-table-column label="修改人" prop="updateUserId"></el-table-column>-->
			<el-table-column label="添加时间" prop="createDate">
				<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
			</el-table-column>
			<el-table-column label="修改时间" prop="updateDate">
				<template slot-scope="scope">{{ scope.row.updateDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'hospitalfactory', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'hospitalfactory', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<FactoryAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></FactoryAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { factoryListService, factoryDeleteService, factoryDetailService } from '@s/hospital/factory/FactoryService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import FactoryAddForm from '@f/hospital/factory/FactoryAdd.form';
import FactorySearchForm from '@f/hospital/factory/FactorySearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'HospitalFactory',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		FactoryAddForm,
		FactorySearchForm
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.factoryList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.factoryList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.factoryList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.factoryList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.factoryList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async factoryList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await factoryListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				factoryId: editId
			};
			const res = await factoryDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await factoryDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				factoryId: row.id
			};
			const res = await factoryDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.factoryList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped></style>
