import { Message, Loading } from 'element-ui';
import axios from 'axios';
import { hgetStorage, hremoveStorage, huploadConfigJson, huploadApiMsg } from './htools.web';
// eslint-disable-next-line import/no-cycle
import router from '../router/index';

const request = axios.create();

/* 读取接口提示信息 */
export const readyApiMsg = (msgArrData, code, childCode) => {
	if (code !== 200) {
		return msgArrData[code];
	}
	const msgArr_200 = msgArrData[code];
	return childCode ? msgArr_200[childCode] : '出大错了！';
};

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:39:31
 *@Description: axios - 请求拦截器
 */
let envConfig = null;
let loading = null;
let apiMsg = null;

request.interceptors.request.use(
	async config => {
		if (!envConfig) {
			envConfig = await huploadConfigJson();
		}
		if (!apiMsg) {
			apiMsg = await huploadApiMsg(envConfig.imgHttpAdd);
		}
		config.headers = {
			Authorization: hgetStorage('token')
		};
        // console.log(config.url);
		// 设备报废和批量移库的操作
		if (config.url.indexOf('apjson') !== -1) {
            config.headers['Content-Type'] = 'application/json;charset=utf-8';
		}

		if (config.url.indexOf('huser/') > -1) {
			config.url = config.url.replace('huser/', envConfig.userUrl);
		} else if (config.url.indexOf('hlzlong_1/') > -1) {
			config.url = config.url.replace('hlzlong_1/', envConfig.lzlong_1);
		} else if (config.url.indexOf('aaa/') > -1) {
			config.url = config.url.replace('aaa/', envConfig.aaa);
		} else if (config.url.indexOf('aaa1/') > -1) {
			config.url = config.url.replace('aaa1/', envConfig.aaa1);
		} else if (config.url.indexOf('hcz/') > -1) {
			config.url = config.url.replace('hcz/', envConfig.cz);
		} else if (config.url.indexOf('hlzlong_2/') > -1) {
			config.url = config.url.replace('hlzlong_2/', envConfig.lzlong_2);
		} else if (config.url.indexOf('hlzlong_21/') > -1) {
			config.url = config.url.replace('hlzlong_21/', envConfig.lzlong_21);
		} else if (config.url.indexOf('hlzlong_22/') > -1) {
			config.url = config.url.replace('hlzlong_22/', envConfig.lzlong_22);
		} else if (config.url.indexOf('hlzlong_3/') > -1) {
			config.url = config.url.replace('hlzlong_3/', envConfig.lzlong_3);
		} else if (config.url.indexOf('hlzlong_4/') > -1) {
			config.url = config.url.replace('hlzlong_4/', envConfig.lzlong_4);
		} else if (config.url.indexOf('hbtyong/') > -1) {
			config.url = config.url.replace('hbtyong/', envConfig.btyongUrl);
		} else if (config.url.indexOf('hbtyong_0/') > -1) {
			config.url = config.url.replace('hbtyong_0/', envConfig.btyong_0);
		} else if (config.url.indexOf('hbtyong_1/') > -1) {
			config.url = config.url.replace('hbtyong_1/', envConfig.btyong_1);
		} else if (config.url.indexOf('hbtyong_2/') > -1) {
			config.url = config.url.replace('hbtyong_2/', envConfig.btyong_2);
		} else if (config.url.indexOf('hbtyong_3/') > -1) {
			config.url = config.url.replace('hbtyong_3/', envConfig.btyong_3);
		} else if (config.url.indexOf('hbtyong_5/') > -1) {
			config.url = config.url.replace('hbtyong_5/', envConfig.btyong_5);
		} else if (config.url.indexOf('hlzlong/') > -1) {
			config.url = config.url.replace('hlzlong/', envConfig.lzlong);
		} else if (config.url.indexOf('hljwen/') > -1) {
			config.url = config.url.replace('hljwen/', envConfig.ljwen);
		} else if (config.url.indexOf('hrole/') > -1) {
			config.url = config.url.replace('hrole/', envConfig.roleUrl);
		} else if (config.url.indexOf('hauthorize/') > -1) {
			config.url = config.url.replace('hauthorize/', envConfig.authorizeUrl);
		} else if (config.url.indexOf('hmenu/') > -1) {
			config.url = config.url.replace('hmenu/', envConfig.menuUrl);
		} else if (config.url.indexOf('horder/') > -1) {
			config.url = config.url.replace('horder/', envConfig.orderUrl);
		} else if (config.url.indexOf('hbase/') > -1) {
			config.url = config.url.replace('hbase/', envConfig.baseDataUrl);
		} else if (config.url.indexOf('hdevice:') > -1) {
			config.url = config.url.replace('hdevice:', envConfig.deviveUrl);
		} else if (config.url.indexOf('htax/') > -1) {
			config.url = config.url.replace('htax/', envConfig.taxUrl);
		} else if (config.url.indexOf('hsta/') > -1) {
			config.url = config.url.replace('hsta/', envConfig.staUrl);
		} else if (config.url.indexOf('hlog/') > -1) {
			config.url = config.url.replace('hlog/', envConfig.logUrl);
		} else if (config.url.indexOf('hdeli/') > -1) {
			config.url = config.url.replace('hdeli/', envConfig.hdeli);
		} else if (config.url.indexOf('hexport/') > -1) {
			config.url = config.url.replace('hexport/', envConfig.hexport);
		} else if (config.url.indexOf('imgHttp/') > -1) {
			config.url = config.url.replace('imgHttp/', envConfig.imgHttp);
		} else if (config.url.indexOf('imgHttpAdd/') > -1) {
			config.url = config.url.replace('imgHttpAdd/', envConfig.imgHttpAdd);
		} else if (config.url.indexOf('hwms/') > -1) {
			config.url = config.url.replace('hwms/', envConfig.wms);
		} else {
			config.url = `${envConfig.baseUrl}${config.url}`;
		}
		loading = Loading.service({ lock: true, text: '正在加载...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)', fullscreen: true });
		return config;
	},
	error => {
		loading.close();
		return Promise.reject(error);
	}
);

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:41:14
 *@Description: axios - get 方法
 */
export function hgetRequest(url, params = {}) {
	return new Promise((resolve, reject) => {
		// console.log(params);
		request
			.get(url, {
				params: params
			})
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				reject(err);
			});
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:45:53
 *@Description: axios - post 方法
 */
export function hpostRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.post(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:46:20
 *@Description: axios - patch 方法
 */
export function hpatchRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.patch(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:46:38
 *@Description: axios - put 方法
 */
export function hputRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.put(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:40:54
 *@Description: axios - 响应拦截器
 */

request.interceptors.response.use(
	response => {
		loading.close();
		const { code } = response.data;
		if (code === 201) {
			/* const { msg } = response.data; */
			Message.success('操作成功');
			return response.data.result;
		}
		return response.data.result;
	},
	async error => {
		const { status } = error.response;
		const { code } = error.response.data;
		loading.close();
		// Message.closeAll();
		if (status === 401) {
			// 未授权
			router.push({
				path: '/login',
				querry: { redirect: router.currentRoute.fullPath } // 从哪个页面跳转
			});
			hremoveStorage('token');
			return Promise.reject();
		}
		const msg = readyApiMsg(apiMsg, code);
		if (code === 499) {			
			const { msg } = error.response.data;
			Message.error(msg);
			// 20220728 批量出库修改了此处
			// return Promise.reject(error);
			return Promise.reject({ error, msg });
		}
		const msg1 = error.response.data.msg;
		Message.error(msg || msg1);
		return Promise.reject(error);
	}
);
