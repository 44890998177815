import { hpostRequest } from '@u/htools.axios.js';

/*
 *@Description: 计量提醒
 *@ClassAuthor: zxh
 *@Date: 2022-02-17
 */
export const patrolRemindListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devName: '',
				compId: '',
				devTypeId: '',
				departId: '',
				pageNum: 1,
				pageSize: 10,
				...data
			};
			// Request URL: https://ygongyun.com/web/dev/api/devBase/queryPagingAlertList
			const res = await hpostRequest('hbtyong_5/devBase/queryPagingAlertList', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default patrolRemindListService;
