<template>
	<div class="app-container">
		<div class="head-container">
			<!-- <TypeSearchForm @searchForm="searchFormHandler"></TypeSearchForm> -->
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'docType'"
					:isHide_p="{ removeBtn: true }"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
            :isHideCheckbox_p="true"
			row-key="powerCode"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<!-- icon="el-icon-edit" -->
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'docType', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<!-- icon="el-icon-delete" -->
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'docType', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<TypeAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
			></TypeAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { typeListService, typeDeleteService, typeDetailService } from '@s/doc/TypeService';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Dialog from '@c/ui/Dialog';
import TypeAddForm from '@f/doc/type/TypeAdd.form';
/* import TypeSearchForm from '@f/doc/type/TypeSearch.form'; */
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		Table,
		Button,
		Dialog,
		TypeAddForm
		/* TypeSearchForm */
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'name'
				}
			],
			delTips: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增分类' : '编辑分类';
		}
	},
	watch: {
		isRefreshList(newValue) {
			console.log(newValue);
			if (newValue) {
				this.typeList();
				/* this.pageIndex = 1; */
			}
		}
	},
	mounted() {
		this.typeList();
	},
	methods: {
		async typeList() {
			const dataJson = {
				/* ...this.searchForm */
			};
			const res = await typeListService(dataJson);
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
			this.$refs.tableDom.clearSelection();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await typeDetailService(dataJson);
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids
			};
			console.log(dataJson);
			await typeDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await typeDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.pageIndex = 1;
			this.searchForm = searchForm;
			this.typeList();
		}
	}
};
</script>

<style scoped>
</style>
