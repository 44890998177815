import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 供应商列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const supplierListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				contract: '',
				name: '',
				phone: '',
				...data
			};
			const res = await hpostRequest('hwms/supplier/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
 export const supplierAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/supplier/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const supplierAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				telphone: '',
				contract: '',
				bankcode: '',
				bank: '',
				address: '',
				...data
			};
			const res = await hpostRequest('hwms/supplier/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const supplierEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				telphone: '',
				contract: '',
				bankcode: '',
				bank: '',
				address: '',
				...data
			};
			const res = await hpostRequest('hwms/supplier/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const supplierDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = [...data];
			const res = await hpostRequest('hwms/supplier/deletes', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 供应商详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const supplierDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/supplier/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default supplierListService;
