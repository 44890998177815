import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 巡检实施列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
 export const inspectionImplementListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				state: '',
				devId: '',
				dutyPeople: '',
				hospitalId: '',
				hospitalOfficeId: '',
				...data
			};
			delete dataJson.page;
			delete dataJson.pagesize;
			delete dataJson.begin;
			delete dataJson.end;
			const res = await hpostRequest('hlzlong_2/inspection/inspectionAllQueryImplement', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 查询巡检附件
 *@ClassAuthor: myw
 *@Date: 2021-06-09 15:21:04
 */
export const inspectionFileListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionId: '',
				...data
			};
			const res = await hgetRequest('hlzlong_2/picture/selectByInspectionId', dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 删除巡检附件
 *@ClassAuthor: myw
 *@Date: 2021-06-10 09:55:10
 */
export const inspectionFileDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong_2/picture/delPicture', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 修改上传日期
 *@ClassAuthor: zxh
 *@Date: 2023-01-10 09:55:10
 */
export const inspectionUpdatepicdateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hlzlong_2/picture/updatepicdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 修改巡检单状态
 *@ClassAuthor: myw
 *@Date: 2021-06-10 10:28:29
 */
export const inspectionImplementStatusService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionId: '',
				...data
			};
			const res = await hgetRequest('hlzlong_2/inspection/updateStateEndById', dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检实施保存备注
 *@ClassAuthor: myw
 *@Date: 2021-06-10 10:28:29
 */
 export const saveRemarkService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = data.memo;
			const res = await hpostRequest(`hlzlong_2/inspectionDev/updateInsDevMemo?id=${data.id}`, dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default inspectionFileListService;
