<template>
	<el-row>
		<el-col :span="24">
			<el-form
				ref="formEle"
				:model="formData"
				:rules="formRules"
				size="small"
				label-width="88px"
			>
				<el-form-item label="编码" prop="code">
					<el-input
						v-model="formData.code"
						placeholder="请输入编码"
						clearable
					>
						<template slot="append">
							<span @click="genCode" style="cursor:pointer;">
								生成编码
							</span>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input
						v-model="formData.name"
						placeholder="请输入名称"
						clearable
					/>
				</el-form-item>
				<el-form-item label="计量单位" prop="units">
					<el-select
						v-model="formData.units"
						placeholder="请选择计量单位"
						class="select-item"
						filterable
						clearable
					>
						<el-option
							v-for="(item, index) in measureUnitData"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="管理方式" prop="manageType">
					<el-select
						v-model="formData.manageType"
						placeholder="请选择管理方式"
						class="select-item"
						filterable
						clearable
					>
						<el-option
							v-for="(item, index) in managerStyleData"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分类" prop="typeId">
					<SelectTree
						v-model="formData.typeId"
						:data="materialTypeData"
						node-key="id"
						:isLastLeaf="false"
					></SelectTree>
				</el-form-item>
				<el-form-item label="标签" prop="tags">
					<el-select
						v-model="formData.tags"
						@change="tagHandler"
						class="select-item"
						multiple
						filterable
						allow-create
						default-first-option
						placeholder="请选择标签"
					>
						<el-option
							v-for="item in tagsData"
							:key="item.value"
							:label="item"
							:value="item"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="规格" prop="spec">
					<el-input
						v-model="formData.spec"
						placeholder="请输入规格"
						clearable
					/>
				</el-form-item>
				<el-form-item label="型号" prop="goodsVer">
					<el-input
						v-model="formData.goodsVer"
						placeholder="请输入型号"
						clearable
					/>
				</el-form-item>
				<el-form-item label="厂家" prop="factory">
					<el-input
						v-model="formData.factory"
						placeholder="请输入厂家"
						clearable
					/>
				</el-form-item>
				<el-form-item label="适用设备" prop="forDev">
					<el-input
						v-model="formData.forDev"
						placeholder="请输入适用设备"
						clearable
					/>
				</el-form-item>
				<el-form-item label="型号规格" prop="forDevSpec">
					<el-input
						v-model="formData.forDevSpec"
						placeholder="请输入适用设备型号规格"
						clearable
					/>
				</el-form-item>
				<el-form-item label="图片" prop="pic">
					<!-- <el-input v-model="formData.pic" placeholder="请输入图片" /> -->
					<Upload
						:action="uploadAction"
						:limit="1"
						class="isShowUpload"
						list-type="picture-card"
						:file-list="fileList"
						:on-success="handlerSuccess"
						:on-remove="handlerRemove"
						:before-upload="handlerBeforeUpload"
					>
						<p>只能上传一张图片</p>
					</Upload>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit" :disabled="isHaveUploadImgIng">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import {
	materialAddService,
	materialEditService,
	managerStyleListService,
	measureUnitAllListService,
	materialgenCodeService,
	tagsAllListService
} from '@s/spareParts/MaterialService';
import { materialTypeListService } from '@s/spareParts/MaterialTypeService';
import SelectTree from '@c/ui/SelectTree';
import Upload from '@c/ui/Upload';

export default {
	props: ['selectData_p', 'isFastAddGood_p'],
	components: {
		SelectTree,
		Upload
	},
	data() {
		return {
			editId: -1,
			// 弹窗
			formData: {
				code: '',
				name: '',
				units: '',
				manageType: '',
				typeId: '',
				tags: '',
				spec: '',
				goodsVer: '',
				factory: '',
				pic: ''
			},
			// 有上传图片时不能点击提交
			isHaveUploadImgIng: false,
			formRules: {
				code: [
					{
						required: true,
						message: '请输入编码',
						trigger: 'blur'
					}
				],
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				manageType: [
					{
						required: true,
						message: '请选择管理方式',
						trigger: 'change'
					}
				],
				units: [
					{
						required: true,
						message: '请选择计量单位',
						trigger: 'change'
					}
				],
				typeId: [
					{
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}
				]
			},
			// 计量单位
			measureUnitData: [],
			// 管理方式
			managerStyleData: [],
			// 分类
			materialTypeData: [],
			tagsData: [],
			uploadAction: '',
			fileList: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.managerStyleData.length === 0 &&
						(await this.managerStyleList());
					this.measureUnitData.length === 0 &&
						(await this.measureUnitList());
					this.materialTypeData.length === 0 &&
						(await this.materialTypeList());
					this.tagsData.length === 0 && (await this.tagsList());
					if (typeof newValue[0].tags === 'string') {
						if (newValue[0].tags.length > 0) {
							newValue[0].tags = newValue[0].tags.split(',');
						} else {
							newValue[0].tags = [];
						}
					}
					this.fileList = [];
					if (newValue[0].pic) {
						this.fileList.push({
							name: newValue[0].filename,
							url: `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${newValue[0].pic}`
						});
					}
					this.isShowUpload();
					if (this.isFastAddGood_p) {
						this.formData.name = newValue[0].name;
					} else {
						this.formData = { ...newValue[0] };
					}
					this.isFastAddGood_p && this.managerStyleList();
					this.isFastAddGood_p && this.measureUnitList();
					this.isFastAddGood_p && this.materialTypeList();
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId === -1 && this.managerStyleList();
		this.editId === -1 && this.measureUnitList();
		this.editId === -1 && this.materialTypeList();
		this.editId === -1 && this.tagsList();
		setTimeout(() => {
			this.uploadAction = `${this.$envConfig.imgHttpWms}img/upload`;
		}, 200);
		this.isFastAddGood_p && this.genCode();
	},
	methods: {
		// 生成编码
		async genCode() {
			const res = await materialgenCodeService();
			this.$nextTick(() => {
				this.$forceUpdate();
				this.$set(this.formData, 'code', res);
				this.$forceUpdate();
			});
			this.$forceUpdate();
		},
		tagHandler() {
			console.log(this.formData.tags);
			this.formData.tags.forEach((item, index) => {
				const $item = item.trim();
				if ($item === '') {
					this.formData.tags.splice(index, 1);
				}
			});
			console.log(this.formData.tags);
		},
		async measureUnitList() {
			const dataJson = {};
			const res = await measureUnitAllListService(dataJson);
			this.measureUnitData = res;
			this.isFastAddGood_p &&
				this.measureUnitData.forEach(item => {
					if (item.name === '个') {
						this.$set(this.formData, 'units', item.id);
					}
				});
			this.isFastAddGood_p && this.$forceUpdate();
		},
		async managerStyleList() {
			const dataJson = {};
			const res = await managerStyleListService(dataJson);
			this.managerStyleData = res;

			this.isFastAddGood_p &&
				this.managerStyleData.forEach(item => {
					if (item.name === '大宗管理') {
						this.$set(this.formData, 'manageType', item.id);
					}
				});
			this.isFastAddGood_p && this.$forceUpdate();
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.name;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async materialTypeList() {
			const dataJson = {};
			const res = await materialTypeListService(dataJson);
			this.addTreeKey(res, 0);
			this.materialTypeData = res;
			this.isFastAddGood_p &&
				this.materialTypeData[0]?.children?.forEach(item => {
					this.$set(this.formData, 'typeId', item.id);
				});
			if (this.isFastAddGood_p && !this.formData.typeId) {
				this.$set(this.formData, 'typeId', this.materialTypeData[0].id);
			}
			this.isFastAddGood_p && this.$forceUpdate();
			console.log(res);
		},
		async tagsList() {
			const dataJson = {};
			const res = await tagsAllListService(dataJson);
			console.log(res);
			this.tagsData = res;
		},
		isShowUpload() {
			const isShowUpload = document.querySelector(
				'.isShowUpload .el-upload--picture-card'
			);
			if (this.formData.pic === '') {
				isShowUpload.style.display = 'block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handlerSuccess(res) {
			console.log(res);
			this.formData.pic = res.result.path;
			this.isShowUpload();
			this.isHaveUploadImgIng = false;
		},
		handlerBeforeUpload() {
			this.isHaveUploadImgIng = true;
		},
		handlerRemove() {
			this.formData.pic = '';
			this.isShowUpload();
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.formData.tags) {
						this.formData.tags = this.formData.tags.join(',');
					}
					if (this.editId === -1 || this.isFastAddGood_p) {
						await materialAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await materialEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
				this.$emit('isToParentGoodsNmae_p', this.formData.name || '');
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped></style>
