<template>
	<el-row :gutter="30">
		<el-col :span="6">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>模板名称</span>
					<el-button style="float: right" type="text" @click="handlerAddTemp">添加</el-button>
				</div>
				<el-row style="margin-bottom: 20px" v-if="isShowAdd">
					<el-col :span="14">
						<el-input v-model="templateName" placeholder="请输入模板名称"></el-input>
					</el-col>
					<el-col :span="10">
						<el-button
							style="float: right"
							type="text"
							@click="
								isShowAdd = false;
								editId = -1;
							"
						>
							取消
						</el-button>
						<el-button style="float: right; margin-right: 10px" type="text" @click="saveTemplate">保存</el-button>
					</el-col>
				</el-row>
				<div v-for="item in templateData" :key="item.id" class="text item">
					<el-row>
						<el-col :span="12" style="padding-bottom: 10px">
							<span :class="[{ item, active: inspectionTypeId === item.id }]" @click="handlerClickTemp(item)">{{ item.name }}</span>
						</el-col>
						<el-col :span="12" v-if="item.id !== 999">
							<el-button @click="removeInspectionTemp(item.id)" style="float: right" type="text">删除</el-button>
							<el-button @click="editInspectionTemp(item)" style="float: right; margin-right: 10px" type="text">编辑</el-button>
						</el-col>
					</el-row>
				</div>
			</el-card>
		</el-col>
		<el-col :span="18">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<InspectionTempSearch @searchForm="searchFormHandler"></InspectionTempSearch>
					<Button
						@showDialogAdd="showDialogAddHandler"
						@showDialogEdit="showDialogEditHandler"
						@showDelete="showDeleteHandler"
						:selectData_p="selectData"
						:delTips_p="delTips"
						:authorize_p="'keepTemp'"
					></Button>
				</div>
			</el-card>
			<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
				<el-table-column label="操作" align="left" fixed="right">
					<template slot-scope="scope">
						<el-button
							id="btn-update-row"
							type="primary"
							v-authorize="{ name: 'update', type: 'keepTemp', id: 'btn-update-row' }"
							@click="editSingleHandler(scope.row)"
						>
							编辑
						</el-button>
						<el-button
							id="btn-remove-row"
							type="danger"
							v-authorize="{ name: 'remove', type: 'keepTemp', id: 'btn-remove-row' }"
							@click="deleteSingleHandler(scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</Table>
			<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
			<Dialog title="新增保养项目" :visible.sync="isShowAEDialog">
				<InspectionTempAdd
					v-if="isShowAEDialog"
					:isShowAEDialog_p.sync="isShowAEDialog"
					:isRefreshList_p.sync="isRefreshList"
					:isRefreshListAll_p.sync="isRefreshListAll"
					:selectData_p="selectData"
					:editId_p="editId"
				></InspectionTempAdd>
			</Dialog>
		</el-col>
	</el-row>
</template>

<script>
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ListMixin from '@m/List.mixin';
import InspectionTempAdd from '@f/company/keep/InspectionTempAdd.form';
import InspectionTempSearch from '@f/company/keep/InspectionTempSearch.form';
// eslint-disable-next-line import/no-cycle
import {
	inspectionTempListService,
	inspectionTempAddService,
	inspectionTempRemoveService,
	inspectionTempEditService,
	inspectionItemListService,
	inspectionItemDetailService,
	inspectionItemRemoveService
} from '@s/company/keep/inspectionTempService';

export default {
	name: 'KeepTemp',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Button,
		Pagination,
		InspectionTempAdd,
		InspectionTempSearch
	},
	data() {
		return {
			templateName: '',
			templateData: [],
			isShowAdd: false,
			delTips: '',
			editId: -1,
			tableColumn: [
				{
					label: '项目名称',
					field: 'name'
				}
				// {
				// 	label: '项目一',
				// 	field: 'itemOne'
				// },
				// {
				// 	label: '项目二',
				// 	field: 'itemTwo'
				// },
				// {
				// 	label: '项目三',
				// 	field: 'itemThree'
				// }
			],
			tableData: [],
			inspectionTypeId: ''
		};
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.inspectionItemList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.inspectionItemList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.inspectionItemList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.inspectionItemList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.inspectionTempList();
		this.inspectionItemList();
	},
	methods: {
		handlerClickTemp(row) {
			this.inspectionTypeId = row.id;
			this.inspectionItemList();
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				inspectionTypeItemId: editId
			};
			const res = await inspectionItemDetailService(dataJson);
			// res.organizationId = res.organization.id;
			this.selectData = [res];
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await inspectionItemRemoveService(dataJson);
			this.isRefreshList = true;
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.inspectionItemList();
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		async inspectionItemList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				inspectionTypeId: this.inspectionTypeId === 999 ? '' : this.inspectionTypeId,
				...this.searchForm
			};
			const res = await inspectionItemListService(dataJson);
			console.log(res);
			this.listMixin(res);
		},
		handlerAddTemp() {
			this.templateName = '';
			this.isShowAdd = true;
			this.editId = -1;
		},
		async saveTemplate() {
			if (!this.templateName) {
				return false;
			}
			if (this.editId === -1) {
				const dataJson = {
					name: this.templateName
				};
				await inspectionTempAddService(dataJson);
			} else {
				const dataJson = {
					id: this.editId,
					name: this.templateName
				};
				// this.editId = -1;
				await inspectionTempEditService(dataJson);
			}
			this.templateName = '';
			this.isShowAdd = false;
			this.inspectionTempList();
		},
		async inspectionTempList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 10000,
				name: ''
			};
			const res = await inspectionTempListService(dataJson);
			this.templateData = [{ id: 999, name: '全部' }, ...res.records];
		},
		async removeInspectionTemp(id) {
			const dataJson = {
				ids: [id]
			};
			await inspectionTempRemoveService(dataJson);
			this.inspectionTempList();
		},
		editInspectionTemp(item) {
			this.isShowAdd = true;
			this.templateName = item.name;
			this.editId = item.id;
		},
		async editSingleHandler(row) {
			const dataJson = {
				inspectionTypeItemId: row.id
			};
			const res = await inspectionItemDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		}
	}
};
</script>

<style>
.item {
	cursor: pointer;
	display: block;
}
.active {
	color: #409eff;
}
</style>
