<template>
	<div class="app-container">
		<el-row :gutter="20">
			<el-col :span="6" class="baseData">

				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>基本信息</span>
					</div>
					<p class="bigTitle">
						<span>
							<span class="warehouseName">{{ detail.warehouseName }}</span>
							<span class="hosdepart" v-if="detail.hosdepart">{{ detail.hosdepart }}</span>
						</span>
						<span class="createTime" v-if="detail.createTime">{{ detail.createTime }}</span>
					</p>

					<p v-if="detail.title" class="baseItem">
						<span class="title">设备名称:</span>
						<span class="value">{{ detail.title }}</span>
					</p>
					<p v-if="detail.devXH" class="baseItem">
						<span class="title">设备型号:</span>
						<span class="value">{{ detail.devXH }}</span>
					</p>
					<p v-if="detail.devSN" class="baseItem">
						<span class="title">设备SN:</span>
						<span class="value">{{ detail.devSN }}</span>
					</p>
					<p v-if="detail.goodsName" class="goodsName">
						<span class="value">{{ detail.goodsName }}</span>
					</p>
					<p v-if="detail.goodsMemo" class="baseItem">
						<span class="title">配件要求:</span>
						<span class="value">{{ detail.goodsMemo }}</span>
					</p>
					<p v-if="detail.memo" class="baseItem">
						<span class="title">备注:</span>
						<span class="value">{{ detail.memo }}</span>
					</p>
				</el-card>
			</el-col>
			<el-col :span="8" class="priceList">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>报价</span>
					</div>
					<ul>
						<li v-for="item in detail.prices" :key="item.creator" class="mb-20">
							<template v-if="item.price != null">
								<div class="priceItem priceGreen">
									<p>{{ item.creatorName }}<b class="ml-20" style="color:#F56C6C; font-size:20px;">{{
										item.price || '-' }}</b>￥</p>
									<p v-if="item.supplier.name">{{ item.supplier.name }}</p>
									<p v-if="item.supplier.telphone">{{ item.supplier.telphone }}</p>
									<p>
										{{
											item.fapiao == 0 ? '无票' :
											item.fapiao == 1 ? '普票' :
											item.fapiao == 2 ? '专票' : '' }}
									</p>
									<p v-if="item.memo">{{ item.memo }}</p>
								</div>
							</template>
							<template v-else>
								<div class="priceItem priceOrange">
									{{ item.creatorName }}
									<p>暂无报价</p>
								</div>
							</template>

						</li>
					</ul>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	getbyidService
} from '@s/spareParts/PriceAskService';

export default {
	name: 'PriceAsk',
	mixins: [],
	components: {
	},
	data() {
		return {
			id: 0,
			detail: {}
		};
	},
	computed: {
	},
	watch: {
	},
	created() { },
	mounted() {
		this.id = Number(this.$route.query.id);
		this.getDetail({ id: this.id });
	},
	methods: {
		async getDetail(row) {
			const dataJson = {
				id: row.id
			};
			const res = await getbyidService(dataJson);
			this.setPriceList([res]);
			this.detail = res;
		},
		// 设置报价人list  设置是否是发起询价人
		setPriceList(arr) {
			const uid = localStorage.getItem('id');
			arr.forEach((item1, index1) => {
				item1.isMainPrice = false;
				if (uid == item1.creator) {
					item1.isMainPrice = true;
				}
				if (!item1.invite) {
					item1.prices = [];
					return false;
				}
				item1.inviteIdArr = item1.invite.split(',');
				item1.inviteNameArr = item1.inviteName.split(',');
				item1.inviteArr = [];
				item1.inviteIdArr.forEach((item, index) => {
					item1.inviteArr.push({
						creator: item1.inviteIdArr[index],
						creatorName: item1.inviteNameArr[index],
						price: null
					});
				});
				item1.inviteArr.forEach((item2, index2) => {
					item1.prices.forEach(item3 => {
						if (item2.creator == item3.creator) {
							item1.inviteArr[index2] = item3;
						}
					});
				});
				item1.prices = [...item1.inviteArr];

				item1.prices.forEach(item2 => {
					if (item2.creator == uid) {
						item1.pricesItem = item2;
					}
				});
			});
		}
	}
};
</script>

<style lang="less" scoped>
.baseData {}

.baseData p {
	line-height: 40px;
}

.baseData .bigTitle {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.baseData .goodsName {
	font-size: 20px;
	font-weight: bold;
}

.baseData .warehouseName {
	font-size: 20px;
	font-weight: bold;
}

.baseData .baseItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.baseData .baseItem .title {
	display: inline-block;
	width: 100px;
	text-align: justify;
	height: 40px;
	line-height: 40px;
	font-weight: bold;
}

// .baseData .baseItem .title:after {
// 	content: "    ";
// 	display: inline-block;
// 	width: 100%;
// }

.baseData .baseItem .value {}

.priceList {}

.priceList p {
	line-height: 30px;
}

.priceList ul {}

.priceList ul li {}

.priceList ul li .priceItem {
	width: 300px;
	padding: 10px;
	border: 1px solid #cccccc;
}

.priceList ul li .priceGreen {
	border: 1px solid #67C23A;
}

.priceList ul li .priceOrange {
	border: 1px solid #E6A23C;
}
</style>
