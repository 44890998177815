import { hgetRequest } from '@u/htools.axios.js';
/*
 *@Description: 科室设备统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const allDevearningMonthService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			let urlQs = `?begin=${data.begin}&end=${data.end}`;
			data.devid && (urlQs += `&devid=${data.devid}`);
			data.orgid && (urlQs += `&orgid=${data.orgid}`);
			data.departid && (urlQs += `&departid=${data.departid}`);
			const res = await hgetRequest(`hcz/devearning/month${urlQs}`, dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default allDevearningMonthService;
