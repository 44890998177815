<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="elForm" :model="elForm" :rules="elFormRules" label-width="100px">
				<el-form-item label="等级名称" prop="name">
					<el-input v-model="elForm.name" placeholder="请输入等级名称" />
				</el-form-item>
				<el-form-item label="月人工费用" prop="monthprice">
					<el-input v-model="elForm.monthprice" type="number" placeholder="请输入月人工费用" />
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { addService, editService, detailService } from '@s/base/EnginerrlevService';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			elForm: {
			},
			elFormRules: {
				name: [
					{
						required: true,
						message: '请输入等级名称',
						trigger: 'blur'
					}
				],
				monthprice: [
					{
						required: true,
						message: '请输入月人工费用',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					// console.log(this.parentName, 'this.parentNname');
					this.elForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		// if (this.editId !== -1) {
		// 	this.getDetail();
		// }
	},
	methods: {
		async getDetail() {
			const dataJson = {
				id: this.editId
			};
			const res = await detailService(dataJson);
			this.elForm = { ...res };
			this.$emit('powers', res.powers);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await addService(this.elForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.elForm
						};
						await editService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
