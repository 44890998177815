<template>
  <el-row>
    <el-col :span="24">
      <!-- <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px"> -->
      <el-row style="margin-bottom: 20px; margin-top: -20px">
        <el-col :span="4">
          <el-image style="width: 100px; height: 100px" :src="apiMaterialDetailData.img"></el-image>
        </el-col>
        <el-col :span="10">
          <ul class="contentText">
            <li>
              <span>物料名称：</span>
              <span>{{ apiMaterialDetailData.name }}</span>
            </li>
            <li>
              <span>物料编码：</span>
              <span>{{ apiMaterialDetailData.code }}</span>
            </li>
            <li>
              <span>厂家：</span>
              <span>{{ apiMaterialDetailData.factory }}</span>
            </li>
            <li>
              <span>规格：</span>
              <span>{{ apiMaterialDetailData.spec }}</span>
            </li>
            <li>
              <span>型号：</span>
              <span>{{ apiMaterialDetailData.typeName }}</span>
            </li>
            <li>
              <span>标签：</span>
              <span>{{ apiMaterialDetailData.tags }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="10">
          <ul class="contentText">
            <li>
              <span>库存：</span>
              <span>{{ materialDetailData.cnt }}</span>
            </li>
            <li>
              <span>库房：</span>
              <span>{{ materialDetailData.warehouseName }}</span>
            </li>
            <li>
              <span>库位：</span>
              <span>{{ materialDetailData.positionName }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
      <!-- </el-form> -->
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px" :inline="true">
        <el-form-item label="出入库类型" prop="inoutType">
          <el-select v-model="formData.inoutType" @change="issueReceiptChangeHandler" clearable placeholder="请选择出入库类型">
            <el-option v-for="(item, index) in issueReceiptTypeData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相关单号" prop="aboutId">
          <el-select v-model="formData.aboutId" @change="aboutIdChange" clearable placeholder="请选择相关单号">
            <el-option v-for="(item, index) in absoutNumberData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="cnt">
          <el-input v-model="formData.cnt" type="number" clearable placeholder="请输入数量" />
        </el-form-item>
        <el-form-item label="价格" prop="price" v-if="formData.inoutType !== 6 && formData.inoutType !== 9">
          <el-input v-model="formData.price" type="number" clearable placeholder="请输入价格" />
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import {
	issueReceiptTypeListService,
	currentStockReduceService
} from '@s/spareParts/CurrentStockService';
import {
	checkStockSelectListService,
	listundoneListService
} from '@s/spareParts/CheckStockService';
import { transferListService } from '@s/spareParts/TransferService';
// import { saleApplicationListService } from '@s/spareParts/SaleApplicationService';
import { saleApplicationFindRequestByGoodsService } from '@s/spareParts/SaleApplicationService';
import { materialDetailService } from '@s/spareParts/MaterialService';
import { htimeFormat } from '../../../util/htools.web';

export default {
	props: ['selectData_p'],
	components: {},
	data() {
		const cntValidat = (rule, value, callback) => {
			if (value && value > this.materialDetailData.cnt) {
				callback(new Error('出库数量不能大于库存'));
			}
			callback();
		};
		return {
			editId: -1,
			issueReceiptTypeData: [],
			absoutNumberData: [],
			dialogFrom: null,
			// 弹窗
			formData: {
				inoutType: '',
				aboutId: '',
				cnt: null,
				price: null
			},
			formRules: {
				inoutType: [
					{
						required: true,
						message: '请选择出入库',
						trigger: 'change'
					}
				],
				aboutId: [
					{
						required: true,
						message: '请选择相关单号',
						trigger: 'change'
					}
				],
				cnt: [
					{
						validator: cntValidat,
						trigger: 'blur'
					}
				]
			},

			materialDetailData: '',
			apiMaterialDetailData: ''
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					console.log(newValue);
					const temp = newValue[0];
					this.materialDetailData = temp;
					this.materialDetail();
					this.dialogFrom = newValue[0].from;
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.issueReceiptTypeList();
	},
	mounted() {},
	methods: {
		aboutIdChange() {
			this.formData.cnt = null;
			this.formData.price = null;
			if (this.formData.inoutType === 8) {
				// 盘盈出库
				this.formData.batchCode = `pd${this.formData.aboutId}`;
			}
			if (this.formData.inoutType === 6 || this.formData.inoutType === 9) {
				let row = {};
				// 调拨出库
				this.formData.batchCode = `db${this.formData.aboutId}`;
				this.absoutNumberData.forEach((item, index, arr) => {
					if (Number(item.id) === Number(this.formData.aboutId)) {
						row = arr[index];
					}
				});
				// 两者取最小值，自动填入
				const data1 = this.materialDetailData.cnt;
				let data2 = 0;
				if (this.formData.inoutType == 6) {
					data2 = row.goodsAllCnt - row.goodsOutCnt;
				} else if (this.formData.inoutType == 9) {
					data2 = row.cnt - row.doneCnt;
				} else {
					console.log(data2);
				}

				this.formData.cnt = data1 > data2 ? data2 : data1;
				// const item = this.absoutNumberData.filter(item => {
				// 	return item.id === this.formData.aboutId;
				// })[0];
				// console.log(item);
				// this.formData.cnt = item.cnt;
			}
			if (this.formData.inoutType === 5) {
				// 销售出库
				const item = this.absoutNumberData.filter(item => {
					return item.id === this.formData.aboutId;
				})[0];
				this.formData.batchCode = item.code;
			}
		},
		async materialDetail() {
			const dataJson = {
				id: this.materialDetailData.goodsid
			};
			const res = await materialDetailService(dataJson);
			res.img = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${res.pic}`;
			this.apiMaterialDetailData = res;

			// 领用借用过来的一键出库
			if (this.dialogFrom == 'borrowAdd') {
				this.formData.inoutType = 9;
				this.issueReceiptChangeHandler();
			}
		},
		async issueReceiptTypeList() {
			const dataJson = {
				type: 2,
				...this.searchForm
			};
			const res = await issueReceiptTypeListService(dataJson);
			this.issueReceiptTypeData = res;
		},
		issueReceiptChangeHandler() {
			this.formData.aboutId = '';
			this.formData.cnt = null;
			this.formData.price = null;
			this.absoutNumberData = [];
			console.log('this.formData.inoutType');
			console.log(this.formData.inoutType);
			if (this.formData.inoutType === 8) {
				// 盘盈出库
				this.checkStockSelectList();
			}
			if (this.formData.inoutType === 6) {
				// 调拨出库
				this.transferList();
			}
			if (this.formData.inoutType === 5) {
				// 销售出库
				this.saleApplicationList();
			}
			if (this.formData.inoutType === 9) {
				// 借用领用出库
				this.listundoneList();
			}
		},
		async listundoneList() {
			const dataJson = {
				goodsid: this.apiMaterialDetailData.id
			};
			const res = await listundoneListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;

			// 领用借用过来的一键出库
			if (this.dialogFrom == 'borrowAdd') {
				console.log('this.absoutNumberData');
				console.log(this.absoutNumberData);
				this.absoutNumberData.forEach((item, index) => {
					if (htimeFormat(item.requestTime) == this.materialDetailData.requestTime) {
						this.formData.aboutId = this.absoutNumberData[index]?.id || null;
						// 两者取最小值，自动填入
						const data1 = this.materialDetailData.cnt;
						let data2 = 0;
						if (this.formData.inoutType == 6) {
							data2 = row.goodsAllCnt - row.goodsOutCnt;
						} else if (this.formData.inoutType == 9) {
							data2 = this.absoutNumberData[index]?.cnt - this.absoutNumberData[index]?.doneCnt;
						} else {
							console.log(data2);
						}

						this.formData.cnt = data1 > data2 ? data2 : data1;
					}
				});
			}
		},
		async checkStockSelectList() {
			const dataJson = {};
			const res = await checkStockSelectListService(dataJson);
			res.forEach(item => {
				item.name = `${item.warehouseName}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		async transferList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				fromWarehouseId: this.materialDetailData.warehouseId,
				goodsId: this.materialDetailData.goodsid,
				filterType: 1
			};
			const res = await transferListService(dataJson);
			res.records.forEach(item => {
				item.name = `${item.fromWareHouseName}-${item.toWarehouseName}(${htimeFormat(
					item.requestTime
				)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res.records;
		},
		async saleApplicationList() {
			const dataJson = {
				// pageNum: 1,
				// pageSize: 100000
				goodsId: this.materialDetailData.goodsid
			};
			const res = await saleApplicationFindRequestByGoodsService(dataJson);
			res.forEach(item => {
				item.name = `${item.code}(${htimeFormat(item.requestTime)})`;
			});
			this.absoutNumberData = [];
			this.absoutNumberData = res;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						this.formData.warehouseId = this.materialDetailData.warehouseId;
						this.formData.items = [];
						this.formData.items.push({
							cnt: Number(this.formData.cnt),
							goodsId: this.materialDetailData.goodsid,
							price: this.formData.price,
							positionId: this.materialDetailData.positionId,
							batchCode: this.materialDetailData.batchCode,
							singleCode: this.materialDetailData.singleCode
						});
						await currentStockReduceService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowReduceStockDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
.contentText {
	padding-left: 10px;
	li {
		line-height: 24px;
	}
}
</style>
