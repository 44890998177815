<template>
	<div class="app-container">
		<ContractSearchForm @searchForm="searchFormHandler"></ContractSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'hospitalcontract'"
		></Button>

		<!-- <Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'contract'"
		></Button>-->
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<!-- address	地址详情
				city	市
				county	县
				createDate	添加时间
				createUserId	添加人
				id	机构id
				name	机构名称
				phone	联系电话
				province	省
				scopeMaintenanceId	维修范围
				type	机构类型(1:医院 2:第维修公司)
				updateDate	修改时间
				updateUserId	修改人
			website	官方网址-->

			<!-- <el-table-column label="机构id" prop="id"></el-table-column> -->
			<el-table-column label="机构名称" prop="name"></el-table-column>
			<el-table-column label="维修范围" prop="scopeMaintenanceId"></el-table-column>
			<el-table-column label="官方网址" prop="website">
				<template slot-scope="scope">
					{{ scope.row.website }}
					<!-- <a :href="scope.row.website" target="_blank"></a> -->
				</template>
			</el-table-column>
			<el-table-column label="联系电话" prop="phone"></el-table-column>
			<!-- <el-table-column label="省" prop="province"></el-table-column>
			<el-table-column label="市" prop="city"></el-table-column>
			<el-table-column label="县" prop="county"></el-table-column> -->
			<el-table-column label="地址详情" prop="address"></el-table-column>
			<!-- <el-table-column label="添加人" prop="createUserId"></el-table-column>
			<el-table-column label="修改人" prop="updateUserId"></el-table-column>-->

			<el-table-column label="修改时间" width="150">
				<template slot-scope="scope">{{ scope.row.updateDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button id="btn-view-row" type="success" @click="viewSingleHandler(scope.row)">
						查看
					</el-button>
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'hospitalcontract', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'hospitalcontract', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<ContractAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></ContractAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService, contractDeleteService, contractDetailService } from '@s/hospital/contract/ContractService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import ContractAddForm from '@f/hospital/contract/ContractAdd.form';
import ContractSearchForm from '@f/hospital/contract/ContractSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'HospitalContract',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		ContractAddForm,
		ContractSearchForm
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.contractList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.contractList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.contractList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.contractList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.contractList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async contractList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await getcompanysignListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				contractId: editId
			};
			const res = await contractDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				companyId: ids
			};
			await contractDeleteService(dataJson);
			this.isRefreshList = true;
		},

		async viewSingleHandler(row) {
			// 跳过去查传id详情
			this.$router.push({ name: 'HospitalContractDetai', query: { id: row.id } });
		},
		async editSingleHandler(row) {
			const dataJson = {
				contractId: row.id
			};
			const res = await contractDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.contractList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped></style>
