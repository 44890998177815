import {
    hgetRequest,
    hpostRequest
} from '../../util/htools.axios';

/*
 *@Description: 视频时间添加记录
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoAddViewService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/addView', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频时间更新记录
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoUpdateViewService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/updateView', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频时间更新记录
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoListVideoViewsViewService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/listVideoViews', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

/*
 *@Description: 视频列表
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/video/search', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频添加
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoAddService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/video/add', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频更新
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoUpdateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/video/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频删除
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoDeleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/video/deletes?setct=apjson', JSON.stringify(dataJson.ids));
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频详情
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoGetbykeyService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/getbykey', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频评价
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
export const videoStarService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/star', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

/*
 *@Description: 视频播放次数
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoClickService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/click', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频评价历史
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoStarListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/starList', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 视频观看历史
 *@MethodAuthor:  zxh
 *@Date: 2022-12-04 11:36:01
 */
 export const videoClickListService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/video/clickList', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

export default videoListService;
