import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 用户列表
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:01
 */
export const userListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			// const res = await hpostRequest('hbtyong/user/systemUserAllQuery', dataJson);
			const res = await hpostRequest('hbtyong/user/getUserList', dataJson);
			res.records.forEach(item => {
				item.roleIdsList = [];
				item.roleNamesList = [];
				// eslint-disable-next-line no-unused-expressions
				item.roleList?.forEach(role => {
					// const Role = JSON.parse(role);
					item.roleIdsList.push(role.id);
					item.roleNamesList.push(role.roleName);
				});
			});
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const userAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 用户添加
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:10
 */
export const userAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 用户编辑
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 14:01:46
 */
export const userEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			if (dataJson.organizationId === 0) {
				dataJson.organizationId = null;
			} else {
				dataJson.organizationId = data.organizationId;
			}
			const res = await hpostRequest('hbtyong/user/systemUserInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 用户删除
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 14:40:48
 */
export const userDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 根据登录用户查询当前登录人
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-01-07 16:11:56
 */
export const userLoginDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/listUserAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 根据用户id查询用户详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const userDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/systemUserByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 用户菜单权限
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 11:42:03
 */
export const userMenuAuthorizeService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/systemUserByIdJurisdictionQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 用户绑定角色
 *@MethodAuthor:  myw
 *@Date: 2020-12-10 17:11:00
 */
export const userRoleService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				roleList: '',
				id: 0,
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserRoleBind', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 修改用户密码
 *@MethodAuthor:  myw
 *@Date: 2020-12-11 13:48:35
 */
export const userPasswordEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				userId: 0,
				userPwdNew: '',
				userPwdOld: '',
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserPassUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export const userPasswordEditService1 = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				userPwdNew: '',
				userPwdOld: '',
				...data
			};
			const res = await hpostRequest('hbtyong/user/systemUserPassUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// 用户修改资料 新接口
export const updateUserBySelfService1 = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hbtyong/user/updateUserBySelf', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 设置工程师等级
 *@MethodAuthor:  zxh
 *@Date: 2023-09-14 11:42:03
 */
 export const updateenginerrlevService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/updateenginerrlev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default userListService;
