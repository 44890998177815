<template>
	<div class="app-container">
		<UserInHosHistorySearchForm @searchForm="searchFormHandler"></UserInHosHistorySearchForm>
		<Button @showDialogAdd="showDialogAddHandler" @showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler" :selectData_p="selectData" :delTips_p="delTips"
			:authorize_p="'uhhis'"></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData"
			highlight-current-row row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
			<el-table-column label="医院" prop="hosname"></el-table-column>
			<el-table-column label="工程师" prop="uname"></el-table-column>
			<el-table-column label="开始时间" prop="beginTime">
				<template slot-scope="scope">{{ scope.row.beginTime | parseTime('{y}-{m}-{d}') }}</template>
			</el-table-column>
			<el-table-column label="结束时间" prop="endTime">
				<template slot-scope="scope">
					<template v-if="scope.row.endTime">
						{{ scope.row.endTime | parseTime('{y}-{m}-{d}') }}
					</template>
					<template v-else>-</template>
				</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button id="btn-update-row" type="primary"
						v-authorize="{ name: 'update', type: 'uhhis', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)">
						编辑
					</el-button>
					<el-button id="btn-remove-row" type="danger"
						v-authorize="{ name: 'remove', type: 'uhhis', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)">
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<UserInHosHistoryAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList" :isRefreshListAll_p.sync="isRefreshListAll" :selectData_p="selectData"
				:editId_p="editId"></UserInHosHistoryAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { listService, deleteService, detailService } from '@s/base/UserInHosHistoryService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import UserInHosHistoryAddForm from '@f/company/UserInHosHistory/UserInHosHistoryAdd.form';
import UserInHosHistorySearchForm from '@f/company/UserInHosHistory/UserInHosHistorySearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'CompanyDept',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		UserInHosHistoryAddForm,
		UserInHosHistorySearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
				this.selectData = [];
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.getList();
					this.selectData = [];
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.getList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async getList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			console.log(dataJson);
			const res = await listService(dataJson);
			console.log('res');
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await detailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await deleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await detailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await deleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 500px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 100%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .el-form-item__content {
	// width: 100%;
}

/deep/ .el-select {
	width: 100%;
}
</style>
