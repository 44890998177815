<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form ref="formEle" :inline="true" :model="formData" :rules="formRules" size="small" label-width="80px" :disabled="isShowDetail_p">
          <el-form-item label="库房" prop="warehouseId">
            <el-select v-model="formData.warehouseId" filterable clearable @change="warehouseChangHandler">
              <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间" prop="requestTime">
            <el-date-picker v-model="formData.requestTime" type="datetime" placeholder="请选择申请时间" @change="requestTimeChangHandler" value="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="单号" prop="code">
            <el-input v-model="formData.code" placeholder="请输入单号" clearable />
          </el-form-item>
          <el-form-item label="是否开票">
            <el-switch v-model="formData.kaipiao"></el-switch>
          </el-form-item>
          <el-form-item label="是否付款">
            <el-switch v-model="formData.fukuan"></el-switch>
          </el-form-item>
          <el-form-item v-if="!isShowDetail_p">
            <el-button :disabled="isAddMetarial" type="primary" @click="addDetailHandler">
              新增物料
            </el-button>
          </el-form-item>
          <el-form-item v-if="state === 2">
            <span style="color: #f56c6c">拒绝原因：{{ reason }}</span>
          </el-form-item>
          <el-table ref="tableDom" :data="tableData" border>
            <el-table-column label="物料" prop="goodsId">
              <template slot-scope="scope">

                <div v-if="scope.row.isAddDetail" style="display:flex;">
                  <el-select class="fastChooseGood" v-model="scope.row.goodsId" filterable @keyup.native="getGoodsVal($event)" @change="changeGood">
                    <el-option v-for="(item, index) in materialData" :key="index" :label="item.name" :value="item.id"></el-option>
                    <!-- <p slot="empty" class="faseAddGood">
										没有您想要的物料，
										<span @click="showFastAddGood">点此快速添加</span>
									</p> -->
                  </el-select>
                  <el-button type="default" @click="showGoodDialog(scope.row)">
                    更多
                  </el-button>
                </div>

                <MaterialTooltip v-else :materialObjP="{
										goodsId: scope.row.goodsId,
										goodsName: scope.row.goodsName
									}">

                </MaterialTooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column label="供应商" prop="supplierId">
						<template slot-scope="scope">
							<el-select v-if="scope.row.isAddDetail" v-model="scope.row.supplierId">
								<el-option v-for="(item, index) in supplierData" :key="index" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<span v-else>{{ scope.row.supplierName }}</span>
						</template>
					</el-table-column> -->
            <el-table-column label="科室" prop="departname">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.departname" placeholder="请输入科室"></el-input>
                <span v-else>{{ scope.row.departname }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="cnt">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.cnt" @blur="priceAndcntHandler(scope.row)" type="number" placeholder="请输入数量"></el-input>
                <span v-else>
                  <span v-if="scope.row.donecnt == scope.row.cnt" style="color:#67C23A; cursor:pointer;">
                    <span title="完成数量">{{ scope.row.donecnt || 0 }}</span>
                    /
                    <span title="申请数量">{{ scope.row.cnt }}</span>
                  </span>
                  <span v-else style="color:#E6A23C;cursor:pointer;">
                    <span title="完成数量">{{ scope.row.donecnt || 0 }}</span>
                    /
                    <span title="申请数量">{{ scope.row.cnt }}</span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="单价" prop="price">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.price" @blur="priceAndcntHandler(scope.row)" type="number" placeholder="请输入单价"></el-input>
                <span v-else>{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总价" prop="totalMoney" width="90">
              <template slot-scope="scope">
                <span>{{ scope.row.totalMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="memo">
              <template slot-scope="scope">
                <el-input v-if="scope.row.isAddDetail" v-model="scope.row.memo" placeholder="请输入备注"></el-input>
                <span v-else>{{ scope.row.memo }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="!isShowDetail_p" label="操作" align="left" fixed="right" width="150">
              <!-- <template slot-scope="scope">
                <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'saleApplication', id: 'btn-update-row' }" @click="saveDetailHandler(scope.row)" v-if="scope.row.isAddDetail">
                  保存
                </el-button>
                <el-button id="btn-update-row" type="primary" size="mini" v-authorize="{ name: 'update', type: 'saleApplication', id: 'btn-update-row' }" @click="editDetailHandler(scope.row)" v-if="!scope.row.isAddDetail">
                  编辑
                </el-button>
                <el-button id="btn-remove-row" type="danger" size="mini" v-authorize="{ name: 'remove', type: 'saleApplication', id: 'btn-remove-row' }" @click="removeDetailHandler(scope)">
                  删除
                </el-button>
              </template> -->
              <template slot-scope="scope">
                <el-button id="btn-update-row" type="primary" size="mini" @click="saveDetailHandler(scope.row)" v-if="scope.row.isAddDetail">
                  保存
                </el-button>
                <el-button id="btn-update-row" type="primary" size="mini" @click="editDetailHandler(scope.row)" v-if="!scope.row.isAddDetail">
                  编辑
                </el-button>
                <el-button :disabled="scope.row.donecnt > 0" :title="scope.row.donecnt > 0 ? '已执行出库的物料不能删除' : ''" id="btn-remove-row" type="danger" size="mini" @click="removeDetailHandler(scope)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
      <el-col :span="24" style="text-align: right; margin-top: 10px">
        <span class="dialog-footer" style="text-align: right">
          <!-- <template v-if="editId !== -1">
            <el-button @click="ShowAEDialogFasttransfer" type="warning">一键生成调拨单</el-button>
            <el-button type="success" :disabled="isAddMetarial" @click="formSubmit({type:'purchase'})">一键生成销售单</el-button>
          </template> -->
          <template v-if="editId !== -1">
            <el-button @click="reduceStockListHandler" type="primary">
              一键出库
            </el-button>
            <el-button @click="ShowAEDialogFasttransfer" type="warning">
              一键生成调拨单
            </el-button>
            <el-button @click="ShowAEDialogFastpurchase" type="success">
              一键生成采购单
            </el-button>
          </template>
          <el-button @click="formCancel">取 消</el-button>
          <el-button type="success" @click="viewImgs">查看单据</el-button>
          <el-button type="primary" :disabled="isAddMetarial" @click="formSubmit({ type: 'sale' })">
            确 定
          </el-button>
        </span>
      </el-col>

      <Dialog title="单据图片列表" :visible.sync="isShowImgsDialog" width="70%">
        <el-image class="p5b1" v-for="item in extFile" :key="item.name" style="width: 120px; height: 120px; margin-right: 14px" :src="item.url" :preview-src-list="extFileUrlList" :fit="'contain'">
          <div slot="error" class="image-slot">暂无图片</div>
        </el-image>
      </Dialog>
    </el-row>
    <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
      <MaterialAddForm v-if="isShowAEDialog" :isShowAEDialog_p.sync="isShowAEDialog" :isRefreshList_p.sync="isRefreshList" @isToParentGoodsNmae_p="materialList" :selectData_p="selectData" :isFastAddGood_p.sync="isFastAddGood"></MaterialAddForm>
    </Dialog>
    <!-- TODELETE 一键出库 -->
    <Dialog :title="dialogTitleFastOut" :visible.sync="isShowReduceStockListDialog" width="70%">
      <CurrentStockReduceListForm v-if="isShowReduceStockListDialog" :isShowReduceStockDialog_p.sync="isShowReduceStockListDialog" :isRefreshDetail_p.sync="isRefreshDetail" :selectData_p="selectDatainOutRecord.items" :selectSearchData_p="{
					inoutType: selectDatainOutRecord.inoutType,
					code: selectDatainOutRecord.code
				}" :isShowDetail_p="false"></CurrentStockReduceListForm>
    </Dialog>
    <!-- TODELETE 一键快速调拨单 -->
    <Dialog :title="dialogTitleFasttransfer" :visible.sync="isShowAEDialogFasttransfer" width="70%">
      <TransferAddFormSale v-if="isShowAEDialogFasttransfer" :isShowAEDialog_p.sync="isShowAEDialogFasttransfer" :selectDataFasttransfer_p="selectDataFasttransfer" :isShowDetail_p="false"></TransferAddFormSale>
    </Dialog>
    <!-- TODELETE 一键快速采购单 -->
    <Dialog :title="dialogTitleFastpurchase" :visible.sync="isShowAEDialogFastpurchase" width="70%">
      <PurchaseApplicationAddForm v-if="isShowAEDialogFastpurchase" :isShowAEDialog_p.sync="isShowAEDialogFastpurchase" :selectDataFastpurchase_p="selectDataFastpurchase" :isShowDetail_p="false"></PurchaseApplicationAddForm>
    </Dialog>

    <!-- // 关联文档 文档所有列表数据 -->
    <Dialog title="物料选择" :visible.sync="isShowGoodDialog" width="70%" custom-class="dialogDoc dialogCom3" :modal-append-to-body="false">
      <MaterialChoose v-if="isShowGoodDialog" @getChooseGood="getChooseGood" :tableDataP="tableData" :queryInfoGoodP="{enable:null}"></MaterialChoose>
    </Dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import {
	saleApplicationAddService,
	saleApplicationEditService,
	saleApplicationMaterialAddService,
	saleApplicationMaterialDeleteService,
	saleApplicationMaterialEditService,
	saleGetRequestCntService,
	saleApplicationDetailService
} from '@s/spareParts/SaleApplicationService';
// eslint-disable-next-line import/no-cycle
import { currentStockListService } from '@s/spareParts/CurrentStockService';
import { warehouseonlymyselfService } from '@s/spareParts/WarehouseService';
import { materialAllListService, materialDetailService } from '@s/spareParts/MaterialService';
import { supplierAllListService } from '@s/spareParts/SupplierService';
import Dialog from '@c/ui/Dialog';
// eslint-disable-next-line import/no-cycle
import { htimeFormat } from '@u/htools.web';
import MaterialChoose from '@/components//MaterialChoose';
// eslint-disable-next-line import/no-cycle
import MaterialAddForm from '@f/spareParts/material/MaterialAdd.form';
import MaterialTooltip from '@/components//MaterialTooltip';
import CurrentStockReduceListForm from '@f/spareParts/currentStock/CurrentStockReduceList.form';
import ListMixin from '@m/List.mixin';
import TransferAddFormSale from '@f/spareParts/transfer/TransferAdd.form';
// import PurchaseApplicationAddForm from '@f/spareParts/purchaseApplication/PurchaseApplicationAdd.form';
import * as math from 'mathjs';

export default {
	props: ['selectData_p', 'isShowDetail_p', 'selectDataFastsale_p', 'selectNativesData_p'],
	mixins: [ListMixin],
	components: {
		Dialog,
		MaterialAddForm,
		TransferAddFormSale,
		PurchaseApplicationAddForm: () =>
			import('@f/spareParts/purchaseApplication/PurchaseApplicationAdd.form'),
		CurrentStockReduceListForm,
		MaterialTooltip,
		MaterialChoose
	},
	data() {
		return {
			selectData: [],
			selectData1: [],
			// 一键出库 刷新弹框数据
			isRefreshDetail: false,
			isFastAddGood: true,
			extFile: [],
			extFileUrlList: [],
			devExtImgUrlArr: [],
			isShowImgsDialog: false,
			editId: -1,
			isAddMetarial: false,
			tableData: [],
			warehouseData: [],
			// 弹窗
			formData: {
				warehouseId: '',
				requestTime: new Date(),
				code: '',
				kaipiao: false,
				fukuan: false
			},
			formDataOld: {},
			formRules: {
				warehouseId: [
					{
						required: true,
						message: '请选择库房',
						trigger: 'blur'
					}
				],
				code: [
					{
						required: true,
						message: '请输入单号',
						trigger: 'blur'
					}
				],
				requestTime: [
					{
						required: true,
						message: '请选择申请时间',
						trigger: 'blur'
					}
				]
			},
			supplierData: [],
			materialData: [],
			reason: '',
			state: '',
			materialDetailData: '',
			selectDatainOutRecord: null,
			selectDataFasttransfer: [],
			selectDataFastpurchase: [],
			// <!-- TODELETE 一键快速调拨单 -->
			isShowReduceStockListDialog: false,
			isShowAEDialogFasttransfer: false,
			isShowAEDialogFastpurchase: false,
			// 新的物料添加
			isShowGoodDialog: false
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					this.selectDataFasttransfer = [newValue[0]];
					this.selectDataFastpurchase = [newValue[0]];
					// this.selectDataFasttransfer = [newValue[0]];
					this.editId = newValue[0].id;
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					this.reason = newValue[0].auditorReason;
					this.state = newValue[0].state;
					this.tableData = [];
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						/* const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0]; */
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name || item.goodsName,
							cnt: item.cnt,
							donecnt: item.donecnt,
							id: item.id,
							memo: item.memo,
							price: item.price,
							departname: item.departname,
							totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
							requestId: '',
							/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
							isAddDetail: false
						});
					});
					newValue[0].requestTime = htimeFormat(newValue[0].requestTime);
					this.formData = { ...newValue[0] };

					this.formDataOld = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		selectDataFastsale_p: {
			async handler(newValue) {
				if (newValue?.length > 0) {
					// this.selectDataFasttransfer = [newValue[0]];
					/* this.supplierData.length === 0 && (await this.supplierList()); */
					this.materialData.length === 0 && (await this.materialList());
					this.reason = newValue[0].auditorReason;
					this.state = newValue[0].state;
					this.formData.warehouseId = newValue[0].warehouseId;
					this.formData.warehouseId && this.getRequestCnt();
					this.tableData = [];
					newValue[0].items?.forEach(item => {
						const materialId = item.goodsId;
						const materialItem = this.materialData.filter(item => {
							return item.id === materialId;
						})[0];
						/* const { supplierId } = item;
						const supplierItem = this.supplierData.filter(item => {
							return item.id === supplierId;
						})[0]; */
						this.tableData.push({
							goodsId: item.goodsId,
							goodsName: materialItem?.name || item.goodsName,
							cnt: item.cnt,
							id: item.id,
							memo: item.memo,
							// 采购过来的用预计销售单价
							price: item.willsell || 0,
							departname: item.departname,
							totalMoney: (item.cnt * 100 * ((item.willsell || 0) * 100)) / 10000,
							requestId: '',
							/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
							isAddDetail: false
						});
					});
					newValue[0].requestTime = htimeFormat(newValue[0].requestTime);
					// this.formData = { ...newValue[0] };

					// this.formDataOld = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		},
		// 当前库存列表 备选来的申请销售出库
		selectNativesData_p: {
			handler(newValue) {
				if (!newValue) {
					return false;
				}
				this.formData.warehouseId = newValue[0].warehouseId;
				this.getRequestCnt();
				console.log('this.materialData =================================');
				console.log(this.materialData);
				newValue.forEach(item => {
					console.log(item);
					this.tableData.push({
						goodsId: item.goodsid,
						goodsName: item.goodsName,
						cnt: item.cnt,
						indonecnt: item.indonecnt || 0,
						outdonecnt: item.outdonecnt || 0,
						id: item.id,
						memo: item.memo,
						price: null,
						departname: item.departname,
						// price: item.price || 0,
						totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
						requestId: '',
						/* supplierId: item.supplierId,
							supplierName: supplierItem.name, */
						isAddDetail: false
					});
				});
			},
			deep: true,
			immediate: true
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.materialList();
			}
		},
		isRefreshDetail: {
			handler(newValue) {
				console.log('newValue 一键出库 刷新');
				console.log(newValue);
				if (newValue) {
					this.getDetailById();
				}
			},
			deep: true
		}
	},
	computed: {
		dialogTitle() {
			return '快速添加物料';
		},
		// <!-- TODELETE 一键快速调拨单 -->
		dialogTitleFasttransfer() {
			return '添加调拨';
		},
		dialogTitleFastpurchase() {
			return '添加采购';
		},
		dialogTitleFastOut() {
			return '一键出库';
		}
	},
	created() {
		/* this.supplierList(); */
		this.materialList();
		this.warehouseonlymyself();
	},
	methods: {
		// 一键出库后 刷新详情 TODO
		async getDetailById() {
			const dataJson = {
				id: this.formData.id
			};
			const res = await saleApplicationDetailService(dataJson);
			const arr = res.items;
			for (let index = 0; index < arr.length; index++) {
				this.tableData.forEach((item1, index1, arr1) => {
					if (arr[index].id === item1.id) {
						item1.donecnt = arr[index].donecnt;
					}
				});
			}
		},
		// 一键出库
		async reduceStockListHandler() {
			const goods = [];
			const obj = {};
			let errorGoodsLen = [];
			let errorGoodsNum = [];
			let errorGoodsName = [];
			let errorMsg = null;
			let flag = false;

			// 过滤调已经执行入库的数据
			const tableData =
				this.tableData.filter(item => {
					return item.cnt > item.donecnt;
				}) || [];
			tableData.forEach(item => {
				goods.push(item.goodsId);
			});
			if (goods.length === 0) {
				this.$message.warning('当前没有需要出库的！');
				return false;
			}
			const dataJson = {
				pageNum: 1,
				pageSize: tableData?.length + 1,
				warehouse: this.formData.warehouseId,
				goods: goods
			};
			const res = await currentStockListService(dataJson);
			obj.inoutType = 5;
			obj.code = this.formData.code;
			obj.items = [...res.records];
			// if (obj.items.length === 0) {
			// 	this.$message.warning('当前库存没有合适的物料！');
			// 	return false;
			// }
			// 遍历表格数据和接口数据，获取errorLen表格物料的长度错误 数量错误
			tableData.forEach((item1, index1) => {
				item1.errorLen = 0;
				item1.errorNum = false;
				obj.items.forEach(item2 => {
					if (item1.goodsId == item2.goodsid) {
						item1.errorLen += 1;
					}
					if (item1.goodsId == item2.goodsid && item1.cnt - item1.donecnt > item2.cnt) {
						item1.errorNum = true;
					}
				});
				if (obj.items.length == 0 || !obj.items?.length) {
					item1.errorNum = true;
				}
			});

			// 情况1 S ------------------------------物料放在了不同的地方，需要手动出库
			errorGoodsLen = tableData.filter(item => item.errorLen > 1);
			if (errorGoodsLen?.length > 0) {
				errorGoodsLen.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料放在了不同的地方，需要手动出库!</p>`;
				errorMsg = message;
			}
			// 情况1 E ------------------------------

			// 情况2 S ------------------------------物料，库房中没有，请先把库房中的物料补齐
			errorGoodsLen = tableData.filter(item => item.errorLen == 0);
			if (errorGoodsLen?.length > 0) {
				errorGoodsName = [];
				errorGoodsLen.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料，库房中没有，请先把库房中的物料补齐!</br></p>`;
				errorMsg = errorMsg ? errorMsg + message : message;
			}
			// 情况2 E ------------------------------

			// 情况3 S ------------------------------物料，库房中数量不够，请先把库房中的物料补齐
			errorGoodsNum = tableData.filter(item => item.errorNum && item.errorLen == 1);
			if (errorGoodsNum?.length > 0) {
				errorGoodsName = [];
				errorGoodsNum.forEach(item => {
					errorGoodsName.push(item.goodsName);
				});
				flag = true;
				const message = `<p style="line-height:30px;"><b style="color:red;">${errorGoodsName.join(
					'、'
				)}</b> 物料，库房中数量不够，请先把库房中的物料补齐!</p>`;
				errorMsg = errorMsg ? errorMsg + message : message;
			}
			// 情况3 E ------------------------------

			if (flag) {
				await this.$message({
					dangerouslyUseHTMLString: true,
					type: 'warning',
					showClose: true,
					customClass: 'messageClass',
					message: errorMsg
				});
				return false;
			}

			obj.items.forEach(item => {
				item.cnt1 = undefined;
				item.price1 = undefined;
			});

			this.selectDatainOutRecord = obj;
			this.isRefreshDetail = false;
			this.isShowReduceStockListDialog = true;
		},
		// 快速生成调拨单
		ShowAEDialogFasttransfer() {
			this.isShowAEDialogFasttransfer = true;
		},
		// 快速生成调拨单
		ShowAEDialogFastpurchase() {
			this.isShowAEDialogFastpurchase = true;
		},
		// 快速生成调拨单 TODELETE
		// ShowAEDialogFasttransfer() {
		// 	const items = [];
		// 	this.selectDataFasttransfer[0].items?.forEach(item => {
		// 		items.push({
		// 			goodsId: item.goodsId,
		// 			cnt: item.cnt,
		// 			id: item.id,
		// 			memo: item.memo,
		// 			price: item.price,
		// 			totalMoney: (item.cnt * 100 * (item.price * 100)) / 10000,
		// 			requestId: '',
		// 			isAddDetail: false
		// 		});
		// 	});
		// 	const dataJson = {
		// 		fromWarehouseId: this.selectDataFasttransfer[0].warehouseId,
		// 		items: items
		// 	};
		// 	hremoveStorage('fastSaleOrPurchase');
		// 	hsetStorage('fastSaleOrPurchase', dataJson);
		// 	this.formCancel();
		// 	this.$router.push({
		// 		path: '/spareParts/transfer',
		// 		query: { type: 'fastSaleOrPurchase' }
		// 	});
		// 	// this.isShowAEDialogFasttransfer = true;
		// },
		// 获取仓库
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		// 改变仓库时间 设置相关单号
		warehouseChangHandler() {
			this.getRequestCnt();
		},
		// 改变仓库时间 设置相关单号
		requestTimeChangHandler() {
			this.getRequestCnt();
		},
		// 设置相关单号
		async getRequestCnt() {
			const { warehouseId, requestTime } = this.formData;
			const json = {
				warehouseId,
				date: htimeFormat(requestTime)
			};
			if (warehouseId && requestTime) {
				const res = await saleGetRequestCntService(json);
				this.setformDataCode(res);
			} else {
				this.formData.code = null;
			}
		},
		// 设置相关单号 B-code-yyyymm-0005  S-code-yyyymm-0012
		setformDataCode(cnt) {
			let code = 0;

			this.warehouseData.forEach(item => {
				if (Number(item.id) === Number(this.formData.warehouseId)) {
					code = item.code;
				}
			});
			// 编辑时修改后改回原来的仓库日期  数量不加一 赋默认初始修改值
			if (
				this.formData.warehouseId === this.formDataOld.warehouseId &&
				this.formData.requestTime === this.formDataOld.requestTime
			) {
				this.formData.code = this.formDataOld.code;
				return;
			}
			// 旧的相关单号不做操作
			if (this.formData.code && this.formData.code.indexOf('-20') === -1) {
				return;
			}

			// 不足4位补0
			if (cnt < 9) {
				cnt = `000${cnt + 1}`;
			} else if (cnt < 99) {
				cnt = `00${cnt + 1}`;
			} else if (cnt < 999) {
				cnt = `0${cnt + 1}`;
			}
			// 截取日期的年月
			const date = htimeFormat(this.formData.requestTime, '{y}{m}');
			// console.log(cnt, date, code);

			this.formData.code = `B-${code}-${date}-${cnt}`;
		},
		viewImgs() {
			console.log(this.formData);
			this.extFile = [];
			this.extFileUrlList = [];
			this.formData.imgs && (this.devExtImgUrlArr = this.formData.imgs.split(','));

			this.formData.imgs &&
				this.devExtImgUrlArr.forEach((img, index) => {
					this.extFile.push({
						name: `图片${index}`,
						path: `${img}`,
						url: `${this.$envConfig.imgHttpAdd}wms/sellrequest/${img}`
					});
					this.extFileUrlList.push(`${this.$envConfig.imgHttpAdd}wms/sellrequest/${img}`);
				});

			if (this.extFile.length > 0) {
				this.isShowImgsDialog = true;
			} else {
				this.$message.warning('暂无单据图片！');
			}
		},
		addDetailHandler() {
			this.isAddMetarial = true;
			this.tableData.push({
				goodsId: '',
				goodsName: '',
				cnt: '',
				id: '',
				memo: '',
				price: '',
				departname: '',
				totalMoney: '',
				requestId: '',
				supplierId: '',
				supplierName: '',
				isAddDetail: true
			});
		},
		idToName(row, goodsId) {
			const materialId = goodsId;
			const materialItem = this.materialData.filter(item => {
				return item.id === materialId;
			})[0];
			row.goodsName = materialItem.name;
			/* const { supplierId } = row;
			const supplierItem = this.supplierData.filter(item => {
				return item.id === supplierId;
			})[0];
			row.supplierName = supplierItem.name; */
		},
		saveDetailHandler(row) {
			if (!row.goodsId || row.cnt === '' || row.price === '') {
				this.$message.warning('请输入正确的参数');
				return false;
			}
			if (row.cnt < 0 || row.price < 0) {
				this.$message.warning('请输入正确的数量和价格');
				return false;
			}
			if (Number(row.donecnt) > Number(row.cnt)) {
				this.$message.warning(`已出库${row.donecnt}个，不能修改数量小于已出库数量`);
				return false;
			}

			row.isAddDetail = false;
			this.isAddMetarial = false;
			if (this.editId === -1) {
				// 新增
				this.idToName(row, row.goodsId);
			}
			if (this.editId !== -1) {
				// 编辑
				if (row.id) {
					// 编辑物料
					const dataJson = {
						id: row.id,
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						departname: row.departname,
						price: Number(row.price),
						requestId: this.editId,
						supplierId: Number(row.supplierId)
					};
					this.materialEdit(dataJson);
				} else {
					// 新增物料
					const dataJson = {
						cnt: row.cnt,
						goodsId: Number(row.goodsId),
						memo: row.memo,
						departname: row.departname,
						price: Number(row.price),
						requestId: this.editId,
						supplierId: Number(row.supplierId)
					};
					this.materialAdd(dataJson);
				}
				this.idToName(row, row.goodsId);
			}
		},
		editDetailHandler(row) {
			row.isAddDetail = true;
			this.isAddMetarial = true;
		},
		removeDetailHandler(scope) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const index = scope.$index;
					if (this.editId === -1) {
						// 新增
						this.tableData.splice(index, 1);
					} else {
						// 编辑
						const dataJson = {
							id: scope.row.id
						};
						this.materialDelete(dataJson, index);
					}
					if (!this.isHasEdit() || this.tableData.length === 0) {
						this.isAddMetarial = false;
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		// 判断是否还有未编辑项目
		isHasEdit() {
			let isHas = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					isHas = true;
				}
			});
			return isHas;
		},
		priceAndcntHandler(row) {
			if (row.cnt && row.price) {
				// row.totalMoney = (row.cnt * 100 * (row.price * 100)) / 10000;
				row.totalMoney = math.format(
					math.multiply(math.bignumber(row.cnt), math.bignumber(row.price || 0))
				);
			} else {
				row.totalMoney = 0;
			}
		},
		async materialList(goodsName) {
			// goodsName 子组件新加的文字label
			let goodsId = null;
			const dataJson = {
				// enable: true
			};
			const res = await materialAllListService(dataJson);
			this.materialData = res;

			goodsName &&
				this.materialData.forEach(item => {
					if (item.name === goodsName) {
						goodsId = item.id;
					}
				});
			goodsName &&
				this.tableData.forEach(item => {
					if (item.isAddDetail) {
						item.goodsId = goodsId;
					}
				});
		},
		async materialDetail(id) {
			const dataJson = {
				id: id
			};
			const res = await materialDetailService(dataJson);
			this.materialDetailData = res;
		},
		async supplierList() {
			const dataJson = {};
			const res = await supplierAllListService(dataJson);
			this.supplierData = res;
		},
		async materialAdd(data) {
			const dataJson = {
				...data
			};
			await saleApplicationMaterialAddService(dataJson);
		},
		async materialEdit(data) {
			const dataJson = {
				...data
			};
			await saleApplicationMaterialEditService(dataJson);
		},
		async materialDelete(data, index) {
			const dataJson = {
				...data
			};
			await saleApplicationMaterialDeleteService(dataJson);
			this.tableData.splice(index, 1);
		},
		formSubmit(e) {
			let flag = true;
			this.tableData.forEach(item => {
				if (item.price == null || item.price == undefined) {
					this.$message.warning({
						dangerouslyUseHTMLString: true,
						message: `请输入 <b style='color:#F56C6C;'>${item.goodsName}</b> 的价格！`
					});
					flag = false;
				}
			});
			if (!flag) {
				return flag;
			}
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (!this.isShowDetail_p) {
						// 采购销售 俩边快速一键切换新增
						const { type } = e;
						this.formData.items = [];
						this.formData.requestTime = htimeFormat(this.formData.requestTime);
						this.tableData.forEach(item => {
							this.formData.items.push({
								cnt: item.cnt,
								departname: item.departname,
								goodsId: item.goodsId,
								memo: item.memo,
								price: item.price,
								supplierId: item.supplierId
							});
						});
						if (this.editId === -1) {
							await saleApplicationAddService(this.formData);
							this.$emit('update:isRefreshList_p', true);
						} else {
							const dataJson = {
								id: this.editId,
								warehouseId: this.formData.warehouseId,
								requestTime: htimeFormat(this.formData.requestTime),
								code: this.formData.code,
								...this.formData
							};
							console.log(dataJson);
							await saleApplicationEditService(dataJson);
							this.$emit('update:isRefreshList_p', true);
						}
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		},
		showFastAddGood() {
			this.isShowAEDialog = true;
			this.selectData = [];
			this.selectData = this.selectData1;
		},
		getGoodsVal(val) {
			this.selectData1 = [];
			this.selectData1.push({
				name: document.querySelector('.fastChooseGood input')?.value || ''
			});
		},
		showGoodDialog(row) {
			console.log('row');
			console.log(row);
			this.isShowGoodDialog = true;
		},
		getChooseGood(row) {
			console.log(row);
			console.log(this.tableData);
			this.isShowGoodDialog = false;
			this.tableData.forEach(item => {
				if (item.isAddDetail) {
					item.goodsId = row.id;
				}
			});
		},
		// 新增物料 时 不能选择已经选择过的
		changeGood(e) {
			let flag = false;
			this.tableData.forEach(item => {
				if (item.goodsId == e && item.isAddDetail == false) {
					flag = true;
				}
			});
			this.tableData.forEach(item => {
				if (item.isAddDetail && flag) {
					this.$message.warning('不能选择已经添加过的物料！');
					item.goodsId = null;
					flag = false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__arrow {
	background: #42b983;
}

.p5b1 {
	padding: 5px;
	border: 1px solid #cccccc;
}

.faseAddGood {
	padding: 10px;
	color: #cccccc;
}

.faseAddGood span {
	cursor: pointer;
	color: #409eff;
}
/deep/ .dialogCom3 {
	min-height: 800px !important;
	height: 800px !important;
	position: relative !important;
}
/deep/ .el-table__body-wrapper {
	max-height: 500px !important;
	overflow-y: auto !important;
}
</style>
