<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="elForm" :model="elForm" :rules="elFormRules" label-width="100px">
				<el-form-item label="标题" prop="title">
					<el-input v-model="elForm.title" placeholder="请输入标题" />
				</el-form-item>
				<el-form-item label="内容" prop="body">
					<el-input
						type="textarea"
						:rows="2"
						placeholder="请输入内容"
						v-model="elForm.body"
					></el-input>
				</el-form-item>
				<el-form-item label="附件" prop="filepath">
					<Upload
						:action="uploadAction"
						class="isShowUpload"
						:limit="1"
						:file-list="fileList"
						:on-success="handleSuccess"
						:on-remove="handleRemove"
						:isShowTip_p="true"
					>
						<el-alert
							title="如有多个文件，请上传压缩包"
							style="padding: 0"
							type="warning"
							:closable="false"
						></el-alert>
						<!-- <p>图片只能上传一张图片</p> -->
					</Upload>
				</el-form-item>
				<el-form-item label="分类" prop="typeName">
					<el-input v-model="elForm.typeName" placeholder="请输入分类" />
				</el-form-item>
				<el-form-item label="申请人" prop="applicantName">
					<el-input v-model="elForm.applicantName" placeholder="请输入申请人" />
				</el-form-item>
				<el-form-item label="审批人" prop="approverName">
					<el-input v-model="elForm.approverName" placeholder="请输入审批人" />
				</el-form-item>
				<el-form-item label="时间" prop="applyDate">
					<el-date-picker
						v-model="elForm.applyDate"
						type="datetime"
						placeholder="选择日期时间"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="formCancel">取 消</el-button>
				<el-button type="primary" @click="formSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { addService, editService, detailService } from '@s/doc/ApplyService';
import Upload from '@c/ui/Upload.vue';

export default {
	props: ['selectData_p', 'editId_p'],
	components: {
		Upload
	},
	data() {
		return {
			editId: -1,
			elForm: {
				title: '', // 标题
				body: '', // 内容
				filepath: '', // 附件
				typeName: '', // 分类
				applicantName: '', // 申请人
				approverName: '', // 审批人
				applyDate: '' // 时间
			},
			elFormRules: {
				title: [
					{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}
				],
				body: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}
				],
				typeName: [
					{
						required: true,
						message: '请输入分类',
						trigger: 'blur'
					}
				],
				applicantName: [
					{
						required: true,
						message: '请输入申请人',
						trigger: 'blur'
					}
				],
				approverName: [
					{
						required: true,
						message: '请输入审批人',
						trigger: 'blur'
					}
				],
				applyDate: [
					{
						required: true,
						message: '请选择日期时间',
						trigger: 'blur'
					}
				]
			},
			fileList: [],
			fileTempList: [],
			uploadAction: `${this.$envConfig.imgHttpWms}contract/ImgUpdate`
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.fileList = [];
					if (newValue[0].filepath) {
						this.fileList.push({
							name: newValue[0].filename || newValue[0].filepath || '文件名',
							url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.norm}/${newValue[0].filepath}`
						});
					}
					// console.log(this.parentName, 'this.parentNname');
					this.elForm = { ...newValue[0] };
					setTimeout(() => {
						this.isShowUpload();
					}, 30);
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		// if (this.editId !== -1) {
		// 	this.getDetail();
		// }
	},
	methods: {
		async getDetail() {
			const dataJson = {
				id: this.editId
			};
			const res = await detailService(dataJson);
			this.elForm = { ...res };
			this.$emit('powers', res.powers);
		},
		handleSuccess(res) {
			this.elForm.filepath = res.result.path;
			if (!this.elForm.title) {
				this.elForm.title = res.result.oldName;
			}
			this.elForm.filename = res.result.oldName;
			this.isShowUpload();
		},
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--text');
			if (this.elForm.filepath === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handleRemove() {
			this.elForm.filepath = '';
			this.isShowUpload();
		},
		formSubmit() {
			console.log(this.elForm);
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await addService(this.elForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.elForm
						};
						await editService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style></style>
