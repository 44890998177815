<template>
	<div>
		<template>
			<div
				class="search_box"
				style="display: flex;    justify-content: flex-start;    align-items: center;"
			>
				<span style=" font-size: 15px;">所在科室：</span>
				<!-- <el-select v-model="deptId" placeholder="请选择" :disabled="!!defaultDeptId" @change="deptSwitch">
						<el-option :value="0" label="请选择"></el-option>
						<el-option v-for="item in departmentData" :key="item.id" :label="item.department" :value="item.id"></el-option>
					</el-select> -->
				<!-- <SelectTree v-model="deptId" :data="departmentData" :disabled="!!defaultDeptId" @change="deptSwitch" node-key="id" :isLastLeaf="true" :clearable_p="false" style="width: 20%"></SelectTree> -->

				<treeselect
					v-model="deptId"
					:multiple="false"
					:options="departmentData"
					style="width:300px; margin-right: 10px;"
					:disabled="!!defaultDeptId"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>

				<el-date-picker
					v-model="date"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button
					type="success"
					class="search_button"
					@click="searchSubmit"
					size="mini"
				>
					搜索
				</el-button>
			</div>
			<el-row :gutter="24" style="margin-bottom: 0px">
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>设备数量(台)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelbuydevices({
										index: 1,
										title: '设备数量、金额',
										options: options1
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(1)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts1" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>设备金额(元)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelbuydevices({
										index: 2,
										title: '设备数量、金额',
										options: options2
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(2)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts2" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>价值区间统计(台)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelDevicePriceRange({
										index: 3,
										title: '价值区间统计(台)',
										options: options3
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(3)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts3" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>价值区间统计（折旧后）</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelDevicePriceRangeAfterzj({
										index: 31,
										title: '价值区间统计（折旧后）',
										options: options31
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(31)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts31" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>设备分类数量</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelDevCntPriceByType({
										index: 4,
										title: '设备分类数量',
										options: options4
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(4)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts4" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>设备分类价值</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelDevCntPriceByType({
										index: 5,
										title: '设备分类价值',
										options: options5
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(5)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts5" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</template>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	allHospitalDeviceNumberService,
	allHospitalDevicePriceService,
	allHospitalDevicePriceAfterzjService,
	devCntPriceByTypeService
} from '@s/hospital/StatisticsService';
// eslint-disable-next-line import/no-cycle
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import { htimeFormat, hgetStorage, hdateInMonth } from '@u/htools.web';
// import SelectTree from '@c/ui/SelectTree';
import Dialog from '@c/ui/Dialog';

import Treeselect from '@riophae/vue-treeselect';

export default {
	components: {
		// SelectTree,
		Dialog,
		Treeselect
	},
	data() {
		return {
			date: '',
			deptId: null,
			defaultDeptId: '',
			departmentData: [],
			dialogTitle: '',
			options1: {
				// title: {
				// 	text: '设备数量(台)'
				// },
				legend: {
					data: ['当年累计数量', '当年购买数量'],
					left: '0'
				},
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}台`;
					}
				},
				xAxis: {
					data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					axisLine: { onZero: true },
					splitLine: { show: false },
					splitArea: { show: false }
				},
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				grid: {
					left: 40,
					bottom: 40
				},
				series: [
					{
						name: '当年累计数量',
						type: 'bar',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '当年购买数量',
						type: 'line',
						yAxisIndex: 1,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					}
				]
			},
			options2: {
				// title: {
				// 	text: '设备金额(元)'
				// },
				legend: {
					data: ['当年累计金额', '折旧金额', '当年购买金额'],
					left: '0'
				},
				tooltip: {
					trigger: 'axis'
					// formatter(arg) {
					// 	return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元<br>${arg[1].seriesName}: ${arg[1].value}元`;
					// }
				},
				xAxis: {
					data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
					axisLine: { onZero: true },
					splitLine: { show: false },
					splitArea: { show: false }
				},
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				grid: {
					left: 100,
					bottom: 40
				},
				series: [
					{
						name: '当年累计金额',
						type: 'bar',
						stack: 'one',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					},
					{
						name: '折旧金额',
						type: 'bar',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					},
					{
						name: '当年购买金额',
						type: 'line',
						yAxisIndex: 1,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0,0,0,0.3)'
							}
						},
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
					}
				]
			},
			options3: {
				// title: {
				// 	text: '价值区间统计(台)'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}台`;
					}
				},
				legend: {
					orient: 'vertical',
					left: '0'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options31: {
				// title: {
				// 	text: '全院价值区间统计（折旧后）'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}台`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options4: {
				series: {
					type: 'sunburst',
					// emphasis: {
					//     focus: 'ancestor'
					// },
					data: [],
					radius: [0, '50%'],
					label: {
						rotate: 'radial',
						formatter: params => {
							let aaa = '';
							if (params.value < 1) {
								aaa = ' ';
							} else {
								aaa = params.name;
							}
							return aaa;
						}
						// width: '100',
						// overflow: 'truncate',
						// ellipsis: '...'
					}
				}
			},
			options5: {
				series: {
					type: 'sunburst',
					// emphasis: {
					//     focus: 'ancestor'
					// },
					data: [
						{
							name: 'Grandpa',
							children: [
								{
									name: 'Uncle Leo',
									value: 15,
									children: [
										{
											name: 'Cousin Jack',
											value: 2
										},
										{
											name: 'Cousin Mary',
											value: 5
										},
										{
											name: 'Cousin Ben',
											value: 4
										}
									]
								},
								{
									name: 'Father',
									value: 10,
									children: [
										{
											name: 'Me',
											value: 5
										},
										{
											name: 'Brother Peter',
											value: 1
										}
									]
								}
							]
						}
					],
					radius: [0, '50%'],
					label: {
						// show: false,
						rotate: 'radial',
						formatter: params => {
							let aaa = '';
							if (params.value < 1) {
								aaa = ' ';
							} else {
								aaa = params.name;
							}
							return aaa;
						}
						// width: '100',
						// overflow: 'truncate',
						// ellipsis: '...'
					}
				}
			},
			isShowBigEcharts: false,
			tempData: null
		};
	},
	mounted() {
		this.deptId =
			hgetStorage('deptId') === 0 ? null : hgetStorage('deptId');
		this.defaultDeptId = hgetStorage('deptId');
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.date = [
			`${curYear - 10}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];
		if (this.deptId) {
			this.allHospitalDeviceList();
			this.allHospitalRepairList();
			this.allHospitalDevicePriceAfterzjList();
			this.devCntPriceByTypeList();
		}
		this.departmentList();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				const myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '设备数量(台)';
					myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '设备金额(元)';
					myChart.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '价值区间统计(台)';
					myChart.setOption(this.options3);
				}
				if (index === 31) {
					this.dialogTitle = '价值区间统计（折旧后）';
					this.myChart.setOption(this.options31);
				}
				if (index === 4) {
					this.dialogTitle = '设备分类数量';
					delete this.options4.series.label.formatter;
					this.options4.series.radius = [0, '90%'];
					const plist = [];
					this.tempData.forEach(item => {
						let isHas = false;
						item.value = item.cnt;
						plist.forEach($item => {
							if (item.pid === $item.id) {
								$item.value += item.cnt;
								isHas = true;
								$item.children.push(item);
							}
						});
						if (!isHas) {
							plist.push({
								id: item.pid,
								value: item.cnt,
								name: item.pname,
								children: [item]
							});
						}
					});
					this.options4.series.data = plist;
					myChart.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '设备分类价值';
					delete this.options5.series.label.formatter;
					this.options5.series.radius = [0, '90%'];
					const plist = [];
					this.tempData.forEach(item => {
						let isHas = false;
						item.value = item.price;
						plist.forEach($item => {
							if (item.pid === $item.id) {
								$item.value += item.price;
								isHas = true;
								$item.children.push(item);
							}
						});
						if (!isHas) {
							plist.push({
								id: item.pid,
								value: item.price,
								name: item.pname,
								children: [item]
							});
						}
					});
					this.options5.series.data = plist;
					myChart.setOption(this.options5);
				}
			}, 500);
		},
		deptSwitch() {
			this.allHospitalDeviceList();
			this.allHospitalRepairList();
			this.allHospitalDevicePriceAfterzjList();
			this.devCntPriceByTypeList();
		},
		searchSubmit() {
			this.allHospitalDeviceList();
			this.allHospitalRepairList();
			this.allHospitalDevicePriceAfterzjList();
			this.devCntPriceByTypeList();
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.departmentData = res.records;
		},
		async allHospitalDeviceList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDeviceNumberService(dataJson);
			this.tableDownDatabuydevices = res || [];
			this.echartsInit1(res);
			this.echartsInit2(res);
		},
		async allHospitalRepairList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDevicePriceService(dataJson);
			this.tableDownDataDevicePriceRange = res || [];
			this.echartsInit3(res);
		},
		async allHospitalDevicePriceAfterzjList() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization')
			};
			const res = await allHospitalDevicePriceAfterzjService(dataJson);

			this.tableDownDataDevicePriceRangeAfterzj = res || [];
			this.echartsInit31(res);
		},
		async devCntPriceByTypeList() {
			const dataJson = {
				departid: this.deptId || 0,
				hosid: hgetStorage('organization')
			};
			const res = await devCntPriceByTypeService(dataJson);
			this.tempData = res;

			this.tableDownDataDevCntPriceByType = res || [];
			this.echartsInit4(res);
			this.echartsInit5(res);
		},
		echartsInit1(res) {
			const buyYearArr = [];
			const buycntArr = [];
			const hascntArr = [];
			res.forEach(item => {
				buyYearArr.push(item.buyyear);
				buycntArr.push(item.buycnt);
				hascntArr.push(item.hascnt);
			});
			this.options1.xAxis.data = buyYearArr;
			this.options1.series[0].data = hascntArr;
			this.options1.series[1].data = buycntArr;
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			const buyYearArr = [];
			const haspriceArr = [];
			const afterzjArr = [];
			const buypriceArr = [];
			res.forEach(item => {
				buyYearArr.push(item.buyyear);
				buypriceArr.push(item.buyprice);
				haspriceArr.push(item.hasprice);
				afterzjArr.push(item.afterzj.toFixed(2));
			});
			this.options2.xAxis.data = buyYearArr;
			this.options2.series[0].data = haspriceArr;
			this.options2.series[1].data = afterzjArr;
			this.options2.series[2].data = buypriceArr;
			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			this.options3.series[0].data = [];
			res.forEach(item => {
				this.options3.series[0].data.push({
					value: item.cnt,
					name: item.ranges
				});
			});
			const chartDom = document.getElementById('echarts3');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit31(res) {
			this.options31.series[0].data = [];
			res.forEach(item => {
				this.options31.series[0].data.push({
					value: item.cnt,
					name: item.ranges
				});
			});
			const chartDom = document.getElementById('echarts31');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options31);
		},
		echartsInit4(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.cnt;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.cnt;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.cnt,
						name: item.pname,
						children: [item]
					});
				}
			});
			this.options4.series.data = plist;
			this.options4.tooltip = {
				formatter: info => {
					const { value, treePathInfo } = info;
					const treePath = [];
					for (let i = 1; i < treePathInfo.length; i++) {
						treePath.push(treePathInfo[i].name);
					}
					return [
						`<div class='tooltip-title' > ${treePath.join(
							'/'
						)} </div>`,
						`数量: ${value} 个`
					].join('');
				}
			};
			const chartDom = document.getElementById('echarts4');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			const plist = [];
			res.forEach(item => {
				let isHas = false;
				item.value = item.price;
				plist.forEach($item => {
					if (item.pid === $item.id) {
						$item.value += item.price;
						isHas = true;
						$item.children.push(item);
					}
				});
				if (!isHas) {
					plist.push({
						id: item.pid,
						value: item.price,
						name: item.pname,
						children: [item]
					});
				}
			});
			this.options5.series.data = plist;
			this.options5.tooltip = {
				formatter: info => {
					const { value, treePathInfo } = info;
					const treePath = [];
					for (let i = 1; i < treePathInfo.length; i++) {
						treePath.push(treePathInfo[i].name);
					}
					return [
						`<div class='tooltip-title' > ${treePath.join(
							'/'
						)} </div>`,
						`价值: ${value} ￥`
					].join('');
				}
			};
			const chartDom = document.getElementById('echarts5');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExcelbuydevices(obj) {
			const tHeader = [
				'日期',
				'当年累计数量',
				'当年累计购买',
				'当年累计金额',
				'折旧金额',
				'当年购买金额'
			];
			const filterVal = [
				'buyyear',
				'hascnt',
				'buycnt',
				'hasprice',
				'afterzj',
				'buyprice'
			];
			const data = this.formatJson(
				filterVal,
				this.tableDownDatabuydevices
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备数量、金额${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelDevicePriceRange(obj) {
			const tHeader = ['价格区间', '数量'];
			const filterVal = ['ranges', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevicePriceRange
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `价值区间统计(台)${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelDevicePriceRangeAfterzj(obj) {
			const tHeader = ['价格区间', '数量'];
			const filterVal = ['ranges', 'cnt'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevicePriceRangeAfterzj
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `价值区间统计（折旧后）${htimeFormat(
						new Date()
					)}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelDevCntPriceByType(obj) {
			const tHeader = ['分类1', '分类2', '数量', '价格'];
			const filterVal = ['pname', 'name', 'cnt', 'price'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataDevCntPriceByType
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `设备分类数量、价值${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.search_box {
	margin-bottom: 20px;
}
.echart_item {
	width: 460px;
	height: 360px;
	margin: 0 auto;
}
.echart_box {
	width: 460px;
	height: 360px;
}

.el-col {
	margin-bottom: 20px;
}
.search_button {
	margin-left: 10px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
