<template>
	<div>websocket</div>
</template>

<script>
import socketMixin from '../../mixins/socket';

export default {
	data() {
		return {};
	},
	mixins: [socketMixin],

	components: {},

	watch: {},

	computed: {},

	mounted: {},

	methods: {},

	destroyed: {}
};
</script>
<style lang='less' scoped>
</style>
