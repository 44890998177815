<template>
	<div class="home">
		<!-- <el-select v-model="hospital" placeholder="请选择">
			<el-option label="全部" :value="0"></el-option>
			<el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
		</el-select> -->
		<el-row :gutter="20" class="row_1">
			<el-col :span="4">
				<div
					class="item1 bgColor1 leftBlock"
					@click="deviceTotalUrl"
					style="cursor: pointer"
				>
					<img src="../assets/bg_icon011.png" alt class="bg_img" />
					<div>
						<p>设备总数</p>
						<h3>{{ countData.deviceNum }}</h3>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div
					class="item1 bgColor2 leftBlock"
					@click="engineerTotalUrl"
					style="cursor: pointer"
				>
					<img src="../assets/bg_icon012.png" alt class="bg_img" />
					<div>
						<p>工程师人数</p>
						<h3>{{ countData.engineerNum }}</h3>
					</div>
				</div>
			</el-col>
			<el-col :span="16">
				<div class="item1 bgColor2 rightBlock">
					<div class="DF" @click="repairTotalUrl" style="cursor: pointer">
						<img src="../assets/bg_icon021.png" alt class="bg_img" />
						<div>
							<p>维修工单</p>
							<h3>{{ countData.repairingNum }}</h3>
						</div>
					</div>
					<div class="DF" @click="keepTotalUrl" style="cursor: pointer">
						<img src="../assets/bg_icon022.png" alt class="bg_img" />
						<div>
							<p>保养工单</p>
							<h3>{{ countData.upkeepingNum }}</h3>
						</div>
					</div>
					<div class="DF" @click="inspectionTotalUrl" style="cursor: pointer">
						<img src="../assets/bg_icon023.png" alt class="bg_img" />
						<div>
							<p>巡检工单</p>
							<h3>{{ countData.inspectingNum }}</h3>
						</div>
					</div>
					<div class="DF" @click="measureTotalUrl" style="cursor: pointer">
						<img src="../assets/bg_icon024.png" alt class="bg_img" />
						<div>
							<p>计量工单</p>
							<h3>{{ countData.measuringNum }}</h3>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-card class="box-card row_2">
			<el-row :gutter="20">
				<el-col :span="7">
					<div
						class="devRingPie"
						v-if="orgType === 1"
						ref="devRingPie"
						style="width: 100%; height: 300px"
					></div>
					<div
						class="devRingPie"
						v-if="orgType === 2"
						id="devRingPie1"
						ref="devRingPie1"
						style="width: 100%; height: 300px"
					></div>
				</el-col>
				<el-col :span="8">
					<div class="devPie" ref="devPie" style="width: 100%; height: 300px"></div>
				</el-col>
				<el-col :span="9">
					<div class="devBar" ref="devBar" style="width: 100%; height: 300px"></div>
				</el-col>
			</el-row>
		</el-card>
		<el-card class="box-card row_3">
			<h3 class="mb15">待修记录 - 重点设备</h3>
			<el-table
				:data="tableData"
				style="width: 100%"
				highlight-current-row
				:header-cell-style="{ background: '#F4F4F4' }"
			>
				<el-table-column type="index"></el-table-column>
				<el-table-column prop="devRepairId" label="报修编号"></el-table-column>
				<el-table-column prop="devInfoName" label="报修设备"></el-table-column>
				<el-table-column prop="hospitalAssetCode" label="院内编号"></el-table-column>
				<el-table-column prop="engineerName" label="负责工程师"></el-table-column>
				<el-table-column prop="devInfoDepartmentName" label="所在科室"></el-table-column>
				<el-table-column prop="repairUserName" label="报修人"></el-table-column>
				<el-table-column prop="actionState" label="报修状态">
					<template slot-scope="scope">
						{{
							scope.row.actionState === 0
								? '未开始'
								: scope.row.actionState === 1
								? '接单'
								: scope.row.actionState === 2
								? '签到'
								: scope.row.actionState === 3
								? '修理'
								: scope.row.actionState === 4
								? '结束'
								: '评价'
						}}
					</template>
				</el-table-column>
				<el-table-column prop="repairTime" label="报修时间"></el-table-column>
			</el-table>
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 30, 50]"
				:page-size="10"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
				class="pagination"
			></el-pagination>
		</el-card>
	</div>
</template>

<script>
import { hgetStorage } from '@u/htools.web';
// eslint-disable-next-line import/no-cycle
import {
	getCountService,
	getCountCompanyService,
	getHosTableListService,
	getCompTableListService,
	deviceTotalListService,
	deviceTotalListCompanyService,
	deviceDistributeService,
	deviceDistributeCompanyService,
	deviceStatusService,
	deviceStatusCompanyService
} from '@s/homeService';

export default {
	name: 'Home',
	components: {},
	data() {
		return {
			hospital: null,
			total: 0,
			options: [
				{
					id: '1',
					label: '测试医院'
				}
			],
			devRingPieObj: null,
			currentPage: 1,
			pageSize: 10,
			devRingPieOptions: {
				title: {
					text: '设备状态占比',
					left: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.seriesName}<br>${arg.name}: ${arg.data.cnt || arg.value}台 ${
							arg.percent
						}%`;
					}
				},
				legend: {
					orient: 'vertical',
					top: '5%',
					left: 'lfet'
				},
				series: [
					{
						name: '设备状态占比',
						type: 'pie',
						radius: ['40%', '50%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [
							{ value: 1, name: '维修' },
							{ value: 1, name: '正常' }
						]
					}
				]
			},
			devPieObj: null,
			devPieOptions: {
				title: {
					text: '设备分布占比',
					left: 'center'
				},
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						// console.log(arg);
						// console.log(arg.data.cnt);
						return `${arg.seriesName}<br>${arg.name}: ${arg.data.cnt || arg.value}台 ${
							arg.percent
						}%`;
					}
				},
				legend: {
					orient: 'vertical',
					top: '5%',
					left: 'lfet',
					show: false
				},
				series: [
					{
						name: '设备分布占比',
						type: 'pie',
						radius: '50%',
						// minAngle: 100,
						data: [
							{ value: 1048, name: '暂无' },
							{ value: 735, name: '急症室' },
							{ value: 580, name: '麻醉手术科' },
							{ value: 484, name: '三层手术室' },
							{ value: 300, name: '其他' }
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			devBarObj: null,
			devBarOptions: {
				title: {
					text: '设备状态记录'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}:${arg[0].data}单<br>${arg[1].seriesName}:${arg[1].data}单`;
					}
				},
				xAxis: {
					type: 'category',
					data: ['二月', '三月', '四月', '五月', '六月']
				},
				yAxis: {
					type: 'value'
				},
				legend: {
					y: 'bottom'
				},
				series: [
					{
						name: '报修量',
						type: 'bar',
						data: [23, 33, 5, 56, 8]
					},
					{
						name: '修复量',
						type: 'bar',
						data: [3, 33, 45, 56, 78]
					}
				]
			},
			tableData: [],
			countData: {},
			orgType: null,
			deviceTotal: {},
			option: {
				title: {
					text: '月度修复率'
				},
				color: ['#FFAE5E', '#44F0FF', '#0096FF', '#EA788B', '#346D96'],
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgba(50,50,50,0.7)',
					borderColor: 'rgba(50,50,50,0.7)',
					textStyle: {
						color: 'rgba(255,255,255)'
					}
				},
				legend: {
					textStyle: {
						color: '#303133'
					},
					y: 'bottom'
				},
				xAxis: {
					type: 'category',
					// data: this.dataX,
					data: ['01', '02', '03', '04', '05'],
					axisTick: {
						show: false // 去除刻度线
					},
					axisLabel: {
						show: true, // 是否显示日期
						interval: 0, // 强制显示全部 // rotate: 40,//倾斜的角度
						textStyle: {
							color: '#909198', // 日期的颜色
							fontSize: 12 // 字体的大小
						}
					},
					axisLine: {
						lineStyle: {
							color: '#162B5F' // x轴的颜色
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						formatter: '{value}',
						textStyle: {
							color: '#B9BABE' // 数字的颜色
						},
						inside: false // 控制数据是否在内侧还是外侧显示
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: '#E0E6F1',
							width: 1,
							type: 'solid'
						}
					}
				},
				series: [
					{
						// data: this.dataY,
						name: '报修量',
						data: [60, 20, 40, 10, 50],
						type: 'bar'
					},
					{
						name: '维修量',
						data: [55, 15, 28, 8, 49],
						type: 'bar'
					},
					{
						name: '巡检次数',
						data: [55, 15, 28, 8, 49],
						type: 'bar'
					},
					{
						name: '保养设备台次',
						data: [55, 15, 28, 8, 49],
						type: 'bar'
					},
					{
						name: '计量台次',
						data: [55, 15, 28, 8, 49],
						type: 'bar'
					}
				]
			}
		};
	},
	mounted() {
		setTimeout(() => {
			this.orgType = hgetStorage('organizationType');
			// console.log(this.orgType);
			this.getCountList();
			if (this.orgType === 1) {
				this.getHosTableList();
			}
			if (this.orgType === 2) {
				this.getCompTableList();
			}

			this.deviceTotalList();
			this.deviceDistributeList();
			this.deviceStatusList();
		}, 500);
	},
	methods: {
		deviceTotalUrl() {
			if (hgetStorage('organizationType') === 2) {
				this.$router.push('/company/device');
			} else {
				this.$router.push('/hospital/devBase');
			}
		},
		engineerTotalUrl() {
			// if (hgetStorage('organizationType') === 1) {
			this.$router.push('/hospital/engineer');
			// }
		},
		repairTotalUrl() {
			if (hgetStorage('organizationType') === 2) {
				this.$router.push('/company/repair');
			} else {
				this.$router.push('/hospital/repair');
			}
		},
		inspectionTotalUrl() {
			if (hgetStorage('organizationType') === 2) {
				this.$router.push('/company/inspection');
			} else {
				this.$router.push('/hospital/inspection');
			}
		},
		keepTotalUrl() {
			if (hgetStorage('organizationType') === 2) {
				this.$router.push('/company/keep');
			} else {
				this.$router.push('/hospital/keep');
			}
		},
		measureTotalUrl() {
			if (hgetStorage('organizationType') === 2) {
				this.$router.push('/company/measure');
			} else {
				this.$router.push('/hospital/measure');
			}
		},
		async deviceDistributeList() {
			let res = null;
			if (this.orgType === 1) {
				res = await deviceDistributeService();
				res.forEach(item => {
					for (const key in item) {
						item.name = key;
						item.value = item[key];
					}
				});
			}
			if (this.orgType === 2) {
				res = await deviceDistributeCompanyService();
				res.forEach(item => {
					item.name = item.hosname;
					item.value = item.percent;
				});
			}

			this.devPieOptions.series[0].data = res;
			this.initPieEcharts();
		},
		async deviceStatusList() {
			let res = null;
			if (this.orgType === 1) {
				res = await deviceStatusService();
			}
			if (this.orgType === 2) {
				res = await deviceStatusCompanyService();
			}
			this.devBarOptions.xAxis.data = res.dates;
			this.devBarOptions.series[0].data = res.repaircnt;
			this.devBarOptions.series[1].data = res.repairdonecnt;
			this.initBarEcharts();
		},
		async getCountList() {
			if (this.orgType === 1) {
				const res = await getCountService();
				this.countData = res;
			}
			if (this.orgType === 2) {
				const res = await getCountCompanyService();
				this.countData = res;
			}
		},
		async getHosTableList() {
			const dataJson = {
				pagenum: this.currentPage,
				pagesize: this.pageSize
			};
			const res = await getHosTableListService(dataJson);
			this.total = res.total;
			this.tableData = res.records;
		},
		async getCompTableList() {
			const dataJson = {
				pagenum: this.currentPage,
				pagesize: this.pageSize
			};
			const res = await getCompTableListService(dataJson);
			this.total = res.total;
			this.tableData = res.records;
		},
		initRingPieEcharts() {
			this.devRingPieObj = this.$echarts.init(this.$refs.devRingPie);
			// 获取数据渲染
			this.devRingPieObj.setOption(this.devRingPieOptions);
		},
		async deviceTotalList() {
			const dataJson = {
				orgId: hgetStorage('organization')
			};
			if (this.orgType === 1) {
				this.deviceTotal = await deviceTotalListService(dataJson);
				this.devRingPieOptions.series[0].data[1].value =
					this.deviceTotal.total - this.deviceTotal.repair;
				this.devRingPieOptions.series[0].data[0].value = this.deviceTotal.repair;
				console.log(this.deviceTotal);
				this.initRingPieEcharts();
			}
			if (this.orgType === 2) {
				const result = await deviceTotalListCompanyService(dataJson);
				this.option.series[0].data = result.repair;
				this.option.series[1].data = result.doneRepair;
				this.option.series[2].data = result.inspections;
				this.option.series[3].data = result.upkeeps;
				this.option.series[4].data = result.measures;
				this.option.xAxis.data = result.mth;
				this.getRepairRate();
			}
		},
		getRepairRate() {
			const myChart = this.$echarts.init(document.getElementById('devRingPie1'));
			myChart.setOption(this.option);
		},
		initPieEcharts() {
			this.devPieObj = this.$echarts.init(this.$refs.devPie);
			// 获取数据渲染
			this.devPieObj.setOption(this.devPieOptions);
		},
		initBarEcharts() {
			this.devBarObj = this.$echarts.init(this.$refs.devBar);
			// 获取数据渲染
			this.devBarObj.setOption(this.devBarOptions);
		},

		handleSizeChange(val) {
			this.pageSize = val;
			if (this.orgType === 1) {
				this.getHosTableList();
			}
			if (this.orgType === 2) {
				this.getCompTableList();
			}
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			if (this.orgType === 1) {
				this.getHosTableList();
			}
			if (this.orgType === 2) {
				this.getCompTableList();
			}
		}
	}
};
</script>

<style lang="less">
.row_1,
.row_2,
.row_3 {
	margin-top: 0.2rem;
}
.leftBlock {
	display: flex;
	align-items: center;
}
.leftBlock .bg_img {
	width: 0.6rem;
	height: 0.6rem;
	margin-left: 0.2rem;
}
.rightBlock .bg_img {
	width: 0.6rem;
	height: 0.6rem;
}
.DF {
	display: flex;
	align-items: center;
}
.rightBlock {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.rightBlock .list {
	width: 3.3rem;
	height: 0.3rem;
	padding-left: 0.2rem;
	border-radius: 0.1rem;
	display: flex;
	justify-content: left;
	align-items: center;
	color: #ffffff;
}

.rightBlock .list:nth-child(odd) {
	margin-left: 1.5rem;
}
.rightBlock .list:nth-child(even) {
	margin-left: 1rem;
}
.rightBlock .list p {
	margin: 0;
	padding: 0;
}

.rightBlock .list p .title {
	font-size: 0.16rem;
}

.rightBlock .list p .number {
	font-size: 0.32rem;
	font-weight: bold;
}
.item1 {
	width: 100%;
	border-radius: 0.04rem;
	color: #fff;
	height: 100px;
	p {
		padding-top: 0.14rem;
		padding-left: 0.14rem;
		font-size: 0.16rem;
	}
	h3 {
		font-size: 0.32rem;
		padding-bottom: 0.14rem;
		padding-left: 0.14rem;
	}
}
.bgColor1 {
	background: #47bbbb;
}
.bgColor2 {
	background: #6895f0;
}
.bgColor3 {
	background: #23a5e3;
}
.pagination {
	margin-top: 0.2rem;
	text-align: right;
}
.mb15 {
	margin-bottom: 0.15rem;
}
</style>
