import { hgetRequest, hpostRequest } from '../../util/htools.axios';
import { hgetStorage } from '../../util/htools.web';

/*
 *@Description: 翻页查询,仅当前登录人管理的
 *@MethodAuthor:  zxh
 *@Date: 2020-12-04 11:36:01
 */
 export const warehouseonlymyselfService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 999,
				name: '',
				code: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouse/onlymyself', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全部库房列表（下拉不翻页）
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
 export const warehouseSelectListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/warehouse/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 全部库房列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const warehouseAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				code: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouse/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 我的库房列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
 export const warehouseSelfListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				code: '',
				...data
			};
			const res = await hpostRequest('hwms/warehouse/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库房添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const warehouseAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				address: '',
				code: '',
				dutys: [0],
				name: '',
				orgid: hgetStorage('organization'),
				...data
			};
			const res = await hpostRequest('hwms/warehouse/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库房编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const warehouseEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				address: '',
				code: '',
				dutys: [0],
				name: '',
				orgid: hgetStorage('organization'),
				...data
			};
			const res = await hpostRequest('hwms/warehouse/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库房删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const warehouseDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
                id: '',
				...data
			};
			const res = await hgetRequest('hwms/warehouse/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 库房详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const warehouseDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
                id: '',
				...data
			};
			const res = await hgetRequest('hwms/warehouse/getbyid', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default warehouseAllListService;
