import { hgetRequest, hpostRequest } from '../../util/htools.axios';

/*
 *@Description: 相关单号问题
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const purchaseGetRequestCntService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/getRequestCnt', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:01
 */
export const purchaseApplicationListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				begin: '',
				end: '',
				code: '',
				states: '',
				pageNum: 1,
				pageSize: 10,
				...data
			};
			if (dataJson.states !== '') {
				dataJson.states = [dataJson.states];
			} else {
				dataJson.states = [];
			}
			const res = await hpostRequest('hwms/buyRequest/list', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 当前库存列表 》 添加入库 》 相关单号 新接口
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const purchaseApplicationListundoneService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/listundone', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const purchaseApplicationAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				code: '',
				requestTime: '',
				items: [
					{
						cnt: 0,
						goodsId: 0,
						memo: '',
						price: 0,
						supplierId: 0
					}
				],
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请添加 -> 物料列表添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 11:36:10
 */
export const purchaseApplicationMaterialAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				cnt: 0,
				goodsId: 0,
				memo: '',
				price: 0,
				requestId: 0,
				supplierId: 0,
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/additem', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const purchaseApplicationEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				code: '',
				requestTime: '',
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请编辑 -> 物料列表编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:01:46
 */
export const purchaseApplicationMaterialEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				cnt: 0,
				goodsId: 0,
				memo: '',
				price: 0,
				requestId: 0,
				supplierId: 0,
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/updateitem', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const purchaseApplicationDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请删除 -> 物料列表删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-04 14:40:48
 */
export const purchaseApplicationMaterialDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/deleteitem', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const purchaseApplicationDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/buyRequest/getdetail', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 采购申请改变状态
 *@MethodAuthor:  myw
 *@Date: 2020-12-08 10:36:27
 */
export const purchaseApplicationChangeStateService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/state', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设置付款
export const purchaseApplicationFukuanService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id,
				state: data.state
			};
			const res = await hgetRequest('hwms/buyRequest/fukuan', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设置开票
export const purchaseApplicationKaipiaoService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id,
				state: data.state
			};
			const res = await hgetRequest('hwms/buyRequest/kaipiao', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设置是否申请调拨
export const purchaseApplicationDBService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id,
				state: data.state
			};
			const res = await hgetRequest('hwms/buyRequest/dodb', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 设置是否申请调拨
export const purchaseApplicationSellService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id,
				state: data.state
			};
			const res = await hgetRequest('hwms/buyRequest/dosell', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
// /web/wms/api/buyRequest/detailsearch 采购详情查询
export const detailSearchService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hwms/buyRequest/detailsearch', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

// 采购申请取消
export const purchasebuyRequestCancelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: data.id
			};
			const res = await hgetRequest('hwms/buyRequest/cancel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default purchaseApplicationListService;
