import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 巡检工单列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const keepListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				states: [0, 1, 2],
				...data
			};
			if (dataJson.states == null || dataJson.states == '' || dataJson.states == undefined) {
				dataJson.states = [0, 1, 2];
			}
			const res = await hpostRequest('hlzlong_21/inspection/inspectionAllQueryImplement', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const devBaseAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hljwen/devBase/addOneDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const devBaseEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				consumptionNatural: 0,
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
				name: '',
				naturalAfter: '',
				naturalFront: '',
				powerConsumption: '',
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hljwen/devBase/updateDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const devBaseDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hpostRequest('hljwen/devBase/deleteDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const devBaseDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				devBaseId: '',
				...data
			};
			const res = await hgetRequest('hljwen/devBase/queryOneDev', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default keepListService;
