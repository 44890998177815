<template>
  <div class="head-serch">
    <el-form class="form" ref="formEle" size="mini" :model="formData" label-width="80px" @submit.native.prevent @keyup.enter.native="searchHandler">

      <el-form-item label="物料">
        <el-select v-model="formData.goodsid" filterable clearable size="mini" placeholder="请选择库房">
          <el-option v-for="(item, index) in goodsList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker v-model="formData.begin" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker v-model="formData.end" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="申请人">
        <el-select ref="multiSelect" v-model="formData.userid" placeholder="请选择申请人" class="select-item" filterable clearable>
          <el-option v-for="(user, index) in userData" :key="index" :label="user.nickName" :value="user.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { userListService } from '@s/system/UserService';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';
// eslint-disable-next-line import/no-cycle
import { materialListService } from '@s/spareParts/MaterialService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				begin: '',
				end: ''
			},
			// 物料下拉
			goodsList: [],
			userData: [],
			warehouseData: []
		};
	},
	created() {
		this.warehouseList();
		this.userList();
		this.getMaterialListPage();
	},
	methods: {
		// 获取物料列表 分页
		async getMaterialListPage() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			const res = await materialListService(dataJson);
			res.records.forEach(item => {
				item.goodsId = item.id;
				item.goodsName = item.name;
				if (item.pic && item.pic.length > 0) {
					item.picList = [
						`${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`
					];
					item.newPic = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${item.pic}`;
				} else {
					item.newPic = item.pic;
					item.picList = [];
				}
			});
			this.goodsList = res.records;
		},
		async userList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await userListService(dataJson);
			this.userData = res.records;
			console.log(this.userData);
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		}
	}
};
</script>

<style lang="less" scoped>
</style>
