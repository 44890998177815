import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 巡检模板列表
 *@ClassAuthor: myw
 *@Date: 2021-05-31 17:25:50
 */
export const inspectionTempListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionType/inspectionTypeAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 巡检模板添加
 *@ClassAuthor: myw
 *@Date: 2021-05-31 14:25:55
 */
export const inspectionTempAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionType/inspectionTypeAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检模板删除
 *@ClassAuthor: myw
 *@Date: 2021-05-31 14:26:05
 */
export const inspectionTempRemoveService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionType/inspectionTypeDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检模板编辑
 *@ClassAuthor: myw
 *@Date: 2021-05-31 15:53:48
 */
export const inspectionTempEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionType/inspectionTypeUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检项目列表
 *@ClassAuthor: myw
 *@Date: 2021-06-02 16:33:14
 */
export const inspectionItemListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionTypeItem/inspectionTypeItemAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检项目添加
 *@ClassAuthor: myw
 *@Date: 2021-06-02 16:33:14
 */
export const inspectionItemAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionTypeId: '',
				itemOne: '',
				itemTwo: '',
				itemThree: '',
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionTypeItem/inspectionTypeItemAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检项目编辑
 *@ClassAuthor: myw
 *@Date: 2021-06-02 17:09:12
 */
export const inspectionItemEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionTypeId: '',
				itemOne: '',
				itemTwo: '',
				itemThree: '',
				name: '',
				id: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionTypeItem/inspectionTypeItemUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检项目详情
 *@ClassAuthor: myw
 *@Date: 2021-06-02 17:09:12
 */
export const inspectionItemDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionTypeItemId: '',
				...data
			};
			const res = await hgetRequest('hlzlong_2/inspectionTypeItem/inspectionTypeItemByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检项目删除
 *@ClassAuthor: myw
 *@Date: 2021-06-02 17:09:12
 */
 export const inspectionItemRemoveService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: '',
				...data
			};
			const res = await hpostRequest('hlzlong_2/inspectionTypeItem/inspectionTypeItemDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default inspectionTempListService;
