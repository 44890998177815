<template>
  <div>
    <div class="baseInfo">
      <el-row>
        <el-col :span="12">
          <el-row>
            <el-col :span="7">
              <el-image class="p5b1" style="width: 120px; height: 120px" :src="deviceInfo.devInfoImgUrlStr" :preview-src-list="[deviceInfo.devInfoImgUrlStr]" :fit="'contain'">
                <div slot="error" class="image-slot">暂无图片</div>
              </el-image>
            </el-col>
            <el-col :span="17">
              <p>{{ deviceInfo.devInfoName }}</p>
              <p>{{ deviceInfo.devInfoTypeName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col :span="12">
              <p>所在科室</p>
              <span>Department</span>
            </el-col>
            <el-col :span="12">
              <h2>{{ deviceInfo.devInfoDepartmentName }}</h2>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="repairForm">
      <el-alert title="注意，从此处添加报修不会给工程师发送短信通知！" type="warning" :closable="false"></el-alert>
      <el-form ref="formEle" :model="formDataRepair" :rules="formRules" label-width="100px">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="报修" name="1">
            <el-form-item label="报修人" prop="opUserId">
              <el-select v-model="formDataRepair.opUserId" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in faultUsersData" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报修时间" prop="repairTime">
              <el-date-picker v-model="formDataRepair.repairTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="故障现象" prop="faultType">
              <el-select v-model="formDataRepair.faultType" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in ploblemData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="故障描述" prop="faultDescription">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formDataRepair.faultDescription"></el-input>
            </el-form-item>
            <el-form-item label="紧急程度" prop="urgentLv">
              <el-radio-group v-model="formDataRepair.urgentLv">
                <el-radio :label="0">非常紧急</el-radio>
                <el-radio :label="1">比较紧急</el-radio>
                <el-radio :label="2">一般紧急</el-radio>
              </el-radio-group>
            </el-form-item>
            <span style="float: left; padding-top: 6px; padding-left: 20px; color: red">*</span>
            <span style="margin-left: -26px; display: inline-block">
              <el-form-item label="故障照片" prop="faultImgUrl">
                <el-upload :action="uploadOneUrl" list-type="picture-card" :on-success="faultSuccessHandler" :on-remove="faultRemoveHandler">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </span>
          </el-collapse-item>
          <el-collapse-item title="维修" name="2">
            <el-form-item label="接单人" prop="engineerId">
              <el-select v-model="formDataRepair.engineerId" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in hospitalEngineer" :key="item.id" :label="item.nickName" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接单时间" prop="undertakeTime">
              <el-date-picker v-model="formDataRepair.undertakeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="签到人" prop="goUserId">
              <el-select v-model="formDataRepair.goUserId" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in hospitalEngineer" :key="item.id" :label="item.nickName" :value="item.userId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签到时间" prop="goTime">
              <el-date-picker v-model="formDataRepair.goTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
            </el-form-item>
            <span style="float: left; padding-top: 6px; padding-left: 20px; color: red">*</span>
            <span style="margin-left: -26px; display: inline-block">
              <el-form-item label="签到图片" prop="goImg">
                <el-upload :action="uploadOneUrl" list-type="picture-card" :on-success="goSuccessHandler" :on-remove="goRemoveHandler" :file-list="goFileList">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </span>
            <el-form-item label="状态" prop="complete">
              <el-radio-group v-model="formDataRepair.complete">
                <!-- <el-radio :label="1">未开始</el-radio> -->
                <el-radio :label="false">维修中</el-radio>
                <el-radio :label="true">已维修</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="formDataRepair.complete == true">
              <el-form-item label="完成人" prop="actionCompleteId" :rules="formRules.actionCompleteId">
                <el-select v-model="formDataRepair.actionCompleteId" placeholder="请选择" style="width: 100%">
                  <el-option v-for="item in hospitalEngineer" :key="item.id" :label="item.nickName" :value="item.userId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="完成时间" prop="actionCompleteTime" :rules="formRules.actionCompleteTime">
                <el-date-picker v-model="formDataRepair.actionCompleteTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%"></el-date-picker>
              </el-form-item>
              <el-form-item label="处理结果" prop="complateMsg" :rules="{ required: true, message: '请输入处理结果', trigger: 'blur' }">
                <el-input type="textarea" :rows="2" placeholder="请输入处理结果" v-model="formDataRepair.complateMsg"></el-input>
              </el-form-item>
              <span style="float: left; padding-top: 6px; margin-left: -2px; color: red">*</span>
              <span style="margin-left: -2px; display: inline-block">
                <el-form-item label="处理结果图片" prop="complateImg" :rules="formRules.complateImg">
                  <el-upload :action="uploadOneUrl" list-type="picture-card" :on-success="completeSuccessHandler" :on-remove="completeRemoveHandler">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </span>
            </template>
          </el-collapse-item>
        </el-collapse>
        <el-form-item label="签单时间">
          <el-date-picker v-model="formDataRepair.signTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
        </el-form-item>
        <div style="margin-top: 20px; text-align: right">
          <el-button @click="cancelHandler">取消</el-button>
          <el-button type="primary" @click="submitRepair">确定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
	repairProblemQueryService,
	repairAddService,
	repairDepartmentUserService
} from '@s/repair/RepairService';
import { queryOneDevService } from '@s/hospital/devBase/DevBaseService';
import { contractHospitalEngineerService } from '@s/company/contract/ContractDetailService';
import { hgetStorage } from '@u/htools.web';

export default {
	props: ['repairDevId', 'repairDevTypeId', 'repairDepartmentId'],
	data() {
		const faultImgValid = (rule, value, callback) => {
			if (value.length === 0) {
				callback(new Error('请上传故障图片'));
			} else {
				callback();
			}
		};
		const goImgValid = (rule, value, callback) => {
			if (!value) {
				callback(new Error('请上传签到图片'));
			} else {
				callback();
			}
		};
		const complateImgValid = (rule, value, callback) => {
			console.log(value);
			if (!value) {
				callback(new Error('请上传处理结果图片'));
			} else {
				callback();
			}
		};
		const repairTimeValid = (rule, value, callback) => {
			const repairTime = new Date(this.formDataRepair.repairTime).getTime();
			const undertakeTime = new Date(this.formDataRepair.undertakeTime).getTime();
			const goTime = new Date(this.formDataRepair.goTime).getTime();
			const actionCompleteTime = new Date(this.formDataRepair.actionCompleteTime).getTime();

			if (value && this.formDataRepair.undertakeTime) {
				if (repairTime > undertakeTime) {
					callback(new Error('报修时间不能大于接单时间'));
					return false;
				}
			}
			if (value && this.formDataRepair.goTime) {
				if (repairTime > goTime) {
					callback(new Error('报修时间不能大于签到时间'));
					return false;
				}
			}
			if (value && this.formDataRepair.actionCompleteTime) {
				if (repairTime > actionCompleteTime) {
					callback(new Error('报修时间不能大于完成时间'));
					return false;
				}
			}
			callback();
		};
		const undertakeTimeValid = (rule, value, callback) => {
			const repairTime = new Date(this.formDataRepair.repairTime).getTime();
			const undertakeTime = new Date(this.formDataRepair.undertakeTime).getTime();
			const goTime = new Date(this.formDataRepair.goTime).getTime();
			const actionCompleteTime = new Date(this.formDataRepair.actionCompleteTime).getTime();

			if (value && this.formDataRepair.repairTime) {
				if (undertakeTime < repairTime) {
					callback(new Error('接单时间不能小于报修时间'));
				}
			}
			if (value && this.formDataRepair.goTime) {
				if (undertakeTime > goTime) {
					callback(new Error('接单时间不能大于签到时间'));
				}
			}
			if (value && this.formDataRepair.actionCompleteTime) {
				if (undertakeTime > actionCompleteTime) {
					callback(new Error('接单时间不能大于完成时间'));
				}
			}
			callback();
		};
		const goTimeValid = (rule, value, callback) => {
			const repairTime = new Date(this.formDataRepair.repairTime).getTime();
			const undertakeTime = new Date(this.formDataRepair.undertakeTime).getTime();
			const goTime = new Date(this.formDataRepair.goTime).getTime();
			const actionCompleteTime = new Date(this.formDataRepair.actionCompleteTime).getTime();

			if (value && this.formDataRepair.repairTime) {
				if (goTime < repairTime) {
					callback(new Error('签到时间不能小于报修时间'));
				}
			}
			if (value && this.formDataRepair.undertakeTime) {
				if (goTime < undertakeTime) {
					callback(new Error('签到时间不能小于接单时间'));
				}
			}
			if (value && this.formDataRepair.actionCompleteTime) {
				if (goTime > actionCompleteTime) {
					callback(new Error('签到时间不能大于完成时间'));
				}
			}
			callback();
		};
		const actionCompleteTimeValid = (rule, value, callback) => {
			const repairTime = new Date(this.formDataRepair.repairTime).getTime();
			const undertakeTime = new Date(this.formDataRepair.undertakeTime).getTime();
			const goTime = new Date(this.formDataRepair.goTime).getTime();
			const actionCompleteTime = new Date(this.formDataRepair.actionCompleteTime).getTime();
			if (value && this.formDataRepair.repairTime) {
				if (actionCompleteTime < repairTime) {
					callback(new Error('完成时间不能小于报修时间'));
					return false;
				}
			}
			if (value && this.formDataRepair.undertakeTime) {
				if (actionCompleteTime < undertakeTime) {
					callback(new Error('完成时间不能小于接单时间'));
					return false;
				}
			}
			if (value && this.formDataRepair.goTime) {
				if (actionCompleteTime < goTime) {
					callback(new Error('完成时间不能小于签到时间'));
					return false;
				}
			}
			callback();
		};
		return {
			formDataRepair: {
				opUserId: '',
				faultType: '',
				faultDescription: '',
				urgentLv: 2,
				faultImgUrl: [],
				engineerId: '',
				undertakeTime: '',
				goUserId: '',
				goTime: '',
				goImg: null,
				goMsg: '',
				complete: true,
				actionCompleteId: '',
				actionCompleteTime: '',
				complateImg: null,
				complateMsg: '',
				repairTime: '',
				signTime: ''
			},
			formRules: {
				opUserId: [
					{
						required: true,
						message: '请选择报修人',
						trigger: 'change'
					}
				],
				repairTime: [
					{
						required: true,
						message: '请选择报修时间',
						trigger: 'change'
					},
					{
						validator: repairTimeValid,
						trigger: 'change'
					}
				],
				faultType: [
					{
						required: true,
						message: '请选择故障现象',
						trigger: 'change'
					}
				],
				faultDescription: [
					{
						required: true,
						message: '请输入故障描述',
						trigger: 'blur'
					}
				],
				faultImgUrl: [
					{
						validator: faultImgValid,
						trigger: 'change'
					}
				],
				engineerId: [
					{
						required: true,
						message: '请选择接单人',
						trigger: 'blur'
					}
				],
				undertakeTime: [
					{
						required: true,
						message: '请选择接单时间',
						trigger: 'change'
					},
					{
						validator: undertakeTimeValid,
						trigger: 'change'
					}
				],
				goUserId: [
					{
						required: true,
						message: '请选择签到人',
						trigger: 'change'
					}
				],
				goTime: [
					{
						required: true,
						message: '请选择签到时间',
						trigger: 'change'
					},
					{
						validator: goTimeValid,
						trigger: 'change'
					}
				],
				goImg: [
					{
						validator: goImgValid,
						trigger: 'change'
					}
				],
				actionCompleteId: [
					{
						required: true,
						message: '请选择完成人',
						trigger: 'change'
					}
				],
				actionCompleteTime: [
					{
						required: true,
						message: '请选择完成时间',
						trigger: 'change'
					},
					{
						validator: actionCompleteTimeValid,
						trigger: 'change'
					}
				],
				complateImg: [
					{
						validator: complateImgValid,
						trigger: 'change'
					}
				],
				complateMsg: [
					{
						required: true,
						message: '请输入处理结果',
						trigger: 'change'
					}
				],
				signTime: [
					{
						required: true,
						message: '请选择签单时间',
						trigger: 'change'
					}
				]
			},
			ploblemData: [],
			deviceInfo: '',
			nickName: '',
			telphone: '',
			hospitalEngineer: '',
			uploadOneUrl: '',
			goFileList: [],
			activeNames: ['1', '2'],
			faultUsersData: []
		};
	},
	created() {
		this.uploadOneUrl = `${this.$envConfig.btyong_5}/devRepairInfoService/ImgUpdate`;
	},
	mounted() {
		this.getDevDetail();
		this.repairPloblemList();
		this.nickName = hgetStorage('nickName');
		this.telphone = hgetStorage('telphone');
		this.faultUserList();
	},
	methods: {
		async faultUserList() {
			const dataJson = {
				departid: this.repairDepartmentId
			};
			const res = await repairDepartmentUserService(dataJson);
			console.log(res);
			this.faultUsersData = res;
		},
		async repairPloblemList() {
			const dataJson = {
				id: this.repairDevTypeId,
				name: '',
				pageNum: 1,
				pageSize: 1000000
			};
			const res = await repairProblemQueryService(dataJson);
			console.log(res);
			this.ploblemData = res.records;
		},
		async getDevDetail() {
			const json = {
				devId: this.repairDevId
			};
			const res = await queryOneDevService(json);
			if (res.devInfoImgUrl) {
				if (res.devInfoImgUrl.indexOf(',') !== -1) {
					res.devInfoImgUrlStr =
						this.$envConfig.imgHttpAdd +
						this.$envConfig.devbase +
						res.devInfoImgUrl.split(',')[0];
				} else {
					res.devInfoImgUrlStr =
						this.$envConfig.imgHttpAdd + this.$envConfig.devbase + res.devInfoImgUrl;
				}
			}
			this.deviceInfo = res;
			this.contractHospitalEngineer();
		},
		async contractHospitalEngineer() {
			console.log(this.deviceInfo);
			let dataJson = null;
			if (hgetStorage('organizationType') === 2) {
				dataJson = {
					hospitalId: this.deviceInfo.affiliatedHospitalId
				};
			} else if (hgetStorage('organizationType') === 1) {
				dataJson = {
					companyId: this.deviceInfo.serviceOrganizationId
				};
			}
			const res = await contractHospitalEngineerService(dataJson);
			this.hospitalEngineer = [...res];
		},
		goSuccessHandler(res) {
			const { code, result } = res;
			if (code === 200) {
				const oEle = document.querySelectorAll('.el-upload.el-upload--picture-card')[1];
				const { path } = result;
				this.formDataRepair.goImg = `${path}`;
				oEle.style.display = 'none';
			} else {
				this.$message.warning('请联系管理员！');
			}
		},
		goRemoveHandler() {
			this.formDataRepair.goImg = '';
			const oEle = document.querySelectorAll('.el-upload.el-upload--picture-card')[1];
			oEle.style.display = 'inline-block';
		},
		faultSuccessHandler(res) {
			const { code, result } = res;
			if (code === 200) {
				const { path } = result;
				this.formDataRepair.faultImgUrl.push(`${path}`);
			} else {
				this.$message.warning('请联系管理员！');
			}
		},
		faultRemoveHandler(file, fileList) {
			this.formDataRepair.faultImgUrl = [];
			fileList.forEach(item => {
				const { response } = item;
				const { result } = response;
				this.formDataRepair.faultImgUrl.push(`${result.path}`);
			});
		},
		completeSuccessHandler(res) {
			const { code, result } = res;
			if (code === 200) {
				const oEle = document.querySelectorAll('.el-upload.el-upload--picture-card')[2];
				const { path } = result;
				this.formDataRepair.complateImg = `${path}`;
				oEle.style.display = 'none';
			} else {
				this.$message.warning('请联系管理员！');
			}
		},
		completeRemoveHandler() {
			this.formDataRepair.complateImg = '';
			const oEle = document.querySelectorAll('.el-upload.el-upload--picture-card')[2];
			oEle.style.display = 'inline-block';
		},
		submitRepair() {
			console.log(this.deviceInfo);
			console.log(this.formDataRepair);
			this.$refs.formEle.validate(async valid => {
				console.log(valid);
				if (valid) {
					const dataJson = {
						devInfoId: this.deviceInfo.devInfoId,
						code: this.deviceInfo.hospitalAssetCode,
						repairUserId: hgetStorage('id'),
						// opUserId: hgetStorage('id'),
						opUserOrgId: `${hgetStorage('organization')}`,
						...this.formDataRepair,
						faultType: `${this.formDataRepair.faultType}`
					};
					await repairAddService(dataJson);
					this.cancelHandler();
				}
			});
		},
		cancelHandler() {
			this.$refs.formEle.resetFields();
			this.$emit('update:isShowAERepairDialog_p', false);
		}
	}
};
</script>

<style lang='less' scoped>
.baseInfo {
	border: 1px solid #eee;
	padding: 10px;
}
.repairForm .el-form-item {
	float: none !important;
	width: 100% !important;
	height: auto !important;
}
.image-slot {
	line-height: 118px;
	text-align: center;
	font-weight: normal;
}
</style>
