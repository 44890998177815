import { hgetRequest } from '@u/htools.axios.js';
/*
 *@Description: 科室设备统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const allDepartmentDeviceNumberService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/DeviceCntPriceByDepartment?begin=${data.begin}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全院设备统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const allHospitalDeviceNumberService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/buydevices?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全院设备价格统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const allHospitalDevicePriceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/DevicePriceRange?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 全院价值区间统计（折旧后）
 *@ClassAuthor: ZXH
 *@Date: 2022-07-14
 */
export const allHospitalDevicePriceAfterzjService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/DevicePriceRangeAfterzj?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 全院设备使用年限
 *@ClassAuthor: ZXH
 *@Date: 2022-07-14
 */
export const allHospitalDeviceUsedYearRangeService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/DeviceUsedYearRange?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全院维修统计
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const allHospitalRepairService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairCntAndPriceByMonth?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}
				&grouptype=${data.grouptype}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 按月统计报修完成量和费用
 *@ClassAuthor: zxh
 *@Date: 2022-2-15
 */
export const repairCntPriceByMonthService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDoneCntAndPriceByMonth?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}
				&grouptype=${data.grouptype}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 按月统计报修完成量和费用(签单时间)
 *@ClassAuthor: zxh
 *@Date: 2022-2-15
 */
export const RepairSignCntAndPriceByMonthService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairSignCntAndPriceByMonth?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}
				&grouptype=${data.grouptype}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备报修次数top
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairDeviceCntTopService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDeviceCntTop?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}&top=${data.top}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 设备报修费用top
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairDevicePriceTopService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDevicePriceTop?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}&top=${data.top}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 设备类型报修次数top
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairDeviceTypeCntTopService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDeviceTypeCntTop?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}&top=${data.top}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 设备类型报修费用top
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairDeviceTypePriceTopService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDeviceTypePriceTop?begin=${data.begin}&departid=${data.departid}&end=${data.end}&hosid=${data.hosid}&top=${data.top}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全院科室报修数量及费用
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairCntPriceByDepartmentService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairCntPriceByDepartment?begin=${data.begin}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 全院科室报修过的数量及费用
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const repairDevCntbyDepartmentService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hossummary/RepairDevCntbyDepartment?begin=${data.begin}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 医院按设备类型统计设备台数和价格
 *@ClassAuthor: MYW
 *@Date: 2021-10-20
 */
export const devCntPriceByTypeService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {};
			const res = await hgetRequest(
				`hcz/hospitalBigScreen/DevCntPriceByType?departid=${data.departid}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 系统使用基础费用
 *@ClassAuthor: zxh
 *@Date: 2023-09-18
 */
export const systemBasePriceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				orgid: null,
				hosid: null,
				begin: null,
				end: null
			};
			const res = await hgetRequest(
				`hcz/hossummary/systemBasePrice?begin=${data.begin}&end=${data.end}&orgid=${data.orgid}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 驻场工程师费用
 *@ClassAuthor: zxh
 *@Date: 2023-09-18
 */
export const workerpriceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				orgid: null,
				hosid: null,
				begin: null,
				end: null
			};
			const res = await hgetRequest(
				`hcz/hossummary/workerprice?begin=${data.begin}&end=${data.end}&orgid=${data.orgid}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 驻场工程师费用
 *@ClassAuthor: zxh
 *@Date: 2023-09-18
 */
export const xjbypriceService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				orgid: null,
				hosid: null,
				begin: null,
				end: null
			};
			const res = await hgetRequest(
				`hcz/hossummary/xjbyprice?begin=${data.begin}&end=${data.end}&orgid=${data.orgid}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检保养月度
 *@ClassAuthor: zxh
 *@Date: 2023-09-18
 *https://ygongyun.com/web/comp/api/hosBigScreenNew/allcnt?begin=2023-07-01%2000:00:00&end=2023-12-28%2023:59:59&hosid=1120
 */
export const allcntService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				orgid: null,
				hosid: null,
				begin: null,
				end: null
			};
			const res = await hgetRequest(
				`hcz/hosBigScreenNew/allcnt?begin=${data.begin}&end=${data.end}&hosid=${data.hosid}`,
				dataJson
			);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default allHospitalDeviceNumberService;
