<template>
	<el-row>
		<el-col :span="12">
			<i class="el-icon-s-fold collapse-btn" @click="collapseHandler" style="font-size: 20px"></i>
			<span style="margin-left: 10px">{{ organization || '' }}</span>
		</el-col>
		<el-col :span="12" style="text-align: right">
			<el-dropdown>
				<i class="el-icon-setting" style="margin-right: 15px; font-size: 20px; color: #bfcbd9; cursor: pointer"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="dialogVisibleChangeInfo">修改资料</el-dropdown-item>
					<el-dropdown-item @click.native="dialogVisibleChangePassword">修改密码</el-dropdown-item>
					<el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<span style="font-size: 16px">{{ userName }}</span>
		</el-col>
		<el-col>
			<Dialog title="修改密码" :visible.sync="dialogVisible" width="25%">
				<el-form :model="changePwd" :rules="rulesChangePwd" ref="changePwd" label-width="130px">
					<el-form-item label="原密码" prop="userPwdOld">
						<el-input v-model="changePwd.userPwdOld" autocomplete="off" type="password" class="w200"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="userPwdNew">
						<el-input v-model="changePwd.userPwdNew" autocomplete="off" type="password" class="w200"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="checkPass">
						<el-input v-model="changePwd.checkPass" autocomplete="off" type="password" class="w200"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('changePwd')">提交</el-button>
						<el-button @click="resetForm('changePwd')">重置</el-button>
					</el-form-item>
				</el-form>
			</Dialog>
		</el-col>
		<el-col>
			<Dialog title="修改资料" :visible.sync="dialogVisibleInfo" width="25%">
				<el-form :model="changeInfo" :rules="rulesChangeInfo" ref="changeInfo" label-width="130px">
					<el-form-item label="昵称">
						<el-input v-model="changeInfo.nickName" autocomplete="off" @input="onInput()"
							class="w200"></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="changeInfo.telphone" autocomplete="off" @input="onInput()"
							class="w200"></el-input>
					</el-form-item>
					<el-form-item label="头像" prop="businessLicense">
						<Upload :action="uploadLicenseAction" :limit="1" class="isShowUpload" list-type="picture-card"
							:file-list="avatarList" :on-success="handlerAvatarSuccess" :on-remove="handleAvatarRemove">
							<p>只能上传一张图片</p>
						</Upload>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormInfo">提交</el-button>
						<el-button @click="resetFormInfo">重置</el-button>
					</el-form-item>
				</el-form>
			</Dialog>
		</el-col>
	</el-row>
</template>

<script>
import md5 from 'js-md5';
// eslint-disable-next-line import/named
import { userLoginDetailService, userPasswordEditService1, updateUserBySelfService1 } from '@s/system/UserService.js';
import Upload from '@c/ui/Upload.vue';
import { hsetStorage, hsetCookie, hclearCookie } from '@u/htools.web.js';
import Dialog from '@c/ui/Dialog.vue';

export default {
	props: ['isCollapse_p'],
	components: {
		Dialog,
		Upload
	},
	data() {
		const validatePass3 = (rule, value, callback) => {
			if (md5(value) !== this.userPwd.replace('{MD5}', '')) {
				callback(new Error('原密码错误，请重新输入！'));
			} else {
				callback();
			}
			// else if (value.length < 6 || value > 16) {
			// 	callback(new Error('密码长度不符！'));
			// }
		};
		const validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.changePwd.checkPass !== '') {
					this.$refs.changePwd.validateField('checkPass');
				}
				callback();
			}
		};
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.changePwd.userPwdNew) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			userName: '',
			userPwd: '',
			organization: '',
			dialogVisible: false,
			dialogVisibleInfo: false,
			changePwd: {
				userPwdOld: null, // 旧密码
				userPwdNew: null, // 新密码
				checkPass: null // 确认密码
			},
			changeInfo: {
				// 昵称
				// 手机号
				// 头像
				headPortrait: ''
			},
			rulesChangePwd: {
				userPwdOld: [
					{ required: true, message: '请输入原密码！', trigger: 'blur' },
					// { required: true, validator: validatePass3, trigger: 'blur' },
					{ min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur' }
				],
				userPwdNew: [
					{ required: true, validator: validatePass, trigger: 'blur' },
					{ min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur' }
				],
				checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }]
			},
			rulesChangeInfo: {},

			uploadLicenseAction: '/',

			avatarList: []
		};
	},
	created() {
		this.userDetail();
		setTimeout(() => {
			// this.$envConfig && (this.uploadLicenseAction = `${this.$envConfig.imgHttpAdd}headportrait`);
			this.uploadLicenseAction = `${this.$envConfig.imgHttp}HeadPortraitUploadController/uploadheadPortrait`;
			// this.uploadLicenseAction = 'http://192.168.1.142:9100/headportrait';
			// console.log('this.uploadLicenseAction');
			// console.log(this.uploadLicenseAction);
		}, 200);
	},
	methods: {
		onInput() {
			this.$forceUpdate();
		},
		collapseHandler() {
			this.$emit('update:isCollapse_p', !this.isCollapse_p);
		},
		async userDetail() {
			const dataJson = {};
			const res = await userLoginDetailService(dataJson);
			this.userName = res.nickName || res.userName;
			this.userPwd = res.userPwd;
			this.organization = res.organizationName;
			// console.log('res 获取到的用户信息');
			// console.log(res);
			this.changeInfo.nickName = res.nickName;
			this.changeInfo.telphone = res.telphone;
			this.changeInfo.id = res.id;
			if (res.headPortrait) {
				this.avatarList = [{ name: 'headPortrait', url: `${this.$envConfig.imgHttpAdd}headportrait/${res.headPortrait}` }];
				this.changeInfo.headPortrait = res.headPortrait;
			}
			// console.log(res);
			hsetStorage('organization', res.organization === null ? 0 : res.organization.id);
			hsetStorage('organizationType', res.organization === null ? 0 : res.organization.type);
			hsetStorage('organizationName', res.organizationName === null ? 0 : res.organization.name);
			hsetStorage('roleType', res.organization.type);
			hsetStorage('powers', res.powers);
			hsetStorage('roleIds', res.userRoles);
			hsetStorage('nickName', res.nickName);
			hsetStorage('telphone', res.telphone);
			hsetStorage('deptId', res.deptId === null ? 0 : res.deptId);
			hsetStorage('deptName', res.deptName === null ? '' : res.deptName);
			hsetStorage('id', res.id);
			hsetCookie('organization', res.organization === null ? 0 : res.organization.id);
			hsetCookie('organizationType', res.organization === null ? 0 : res.organization.type);
		},
		dialogVisibleChangePassword() {
			// 修改密码 弹框
			this.dialogVisible = true;
			// setTimeout(() => {
			this.$refs.changePwd && this.$refs.changePwd.resetFields();
			// }, 100);
		},
		dialogVisibleChangeInfo() {
			// 修改基本信息 弹框
			this.dialogVisibleInfo = true;
			this.$refs.changeInfo && this.$refs.changeInfo.resetFields();
			setTimeout(() => {
				this.isShowUpload();
			}, 100);
		},

		submitForm(formName) {
			this.$refs[formName].validate(async valid => {
				if (!valid) {
					return false;
				}
				const res = await this.$confirm('确认修改', {
					type: 'warning'
				});

				if (res !== 'confirm') {
					return false;
				}
				delete this.changePwd.checkPass;
				const res1 = await userPasswordEditService1(this.changePwd);
				if (res1.code !== 200) {
					res1.msg && this.$message.warning(res1.msg);
				}
				this.$message.success('修改密码成功！');
				this.dialogVisible = true;
				this.loginOut();
				// userPasswordEditService
			});
		},
		async submitFormInfo() {
			const res = await updateUserBySelfService1(this.changeInfo);
			console.log(res);
			this.dialogVisibleInfo = false;
			this.changeInfo = {};
			this.userDetail();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		resetFormInfo() {
			this.$refs.changeInfo.resetFields();
			this.changeInfo = {
				headPortrait: ''
			};
			this.avatarList = [];
			this.isShowUpload();
		},
		loginOut() {
			this.$store.dispatch('loginOutStore');
			this.$router.replace('/login');
			hclearCookie();
		},
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--picture-card');
			if (this.changeInfo.headPortrait === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handlerAvatarSuccess(res) {
			console.log(res);
			this.changeInfo.headPortrait = res.result;
			this.isShowUpload();
		},
		handleAvatarRemove() {
			this.changeInfo.headPortrait = '';
			this.isShowUpload();
		}
	}
};
</script>

<style scoped lang="less">
.collapse-btn {
	cursor: pointer;
}

.el-dropdown {
	height: 34px;
}

.w200 {
	width: 200px;
}
</style>
