<template>
	<div class="app-container">
		<BadEventSearchForm
			@searchForm="searchFormHandler"
		></BadEventSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'badEvent'"
		></Button>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			highlight-current-row
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="id" prop="id"></el-table-column>
			<el-table-column label="科室" prop="departid">
				<template slot-scope="scope">
					{{
						scope.row.departname ||
							scope.row.departName ||
							scope.row.departid
					}}
				</template>
			</el-table-column>
			<el-table-column label="设备名称" prop="devname"></el-table-column>
			<el-table-column label="发生时间" prop="eventDate">
				<template slot-scope="scope">
					{{ scope.row.eventDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="状态" prop="reportState">
				<template slot-scope="scope">
					{{ scope.row.reportState == 0 ? '内部登记' : '上报提交' }}
				</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{
							name: 'update',
							type: 'badEvent',
							id: 'btn-update-row'
						}"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{
							name: 'remove',
							type: 'badEvent',
							id: 'btn-remove-row'
						}"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination
			:total="total"
			:pageIndex_p.sync="pageIndex"
			:pageSize_p.sync="pageSize"
		></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<BadEventAdd
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></BadEventAdd>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	listService,
	deleteService,
	detailService
} from '@s/hospital/BadEventService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import BadEventAdd from '@f/hospital/BadEvent/BadEventAdd.form';
import BadEventSearchForm from '@f/hospital/BadEvent/BadEventSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'BadEvent',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		BadEventAdd,
		BadEventSearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.getList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.getList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.getList();
				this.selectData = [];
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.getList();
					this.selectData = [];
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.getList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async getList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await listService(dataJson);
			console.log('res');
			console.log(res);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await detailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await deleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await detailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await deleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.getList();
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
	width: 1100px;
	overflow: hidden;
}

/deep/.el-dialog .el-form-item {
	width: 30%;
	margin-right: 15px;
	float: left;
	height: 33px;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-form-item {
	width: initial;
}

/deep/ .dialogVisibleBatchMaintenanceClass .textArea {
	width: 100%;
}

/deep/ .dialogVisibleBatchMaintenanceClass .el-dialog__body {
	overflow: hidden;
}
</style>
