<template>
  <div>
    <el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="100px">
      <el-form-item label="金额" prop="money">
        <el-input-number v-model="formData.money" controls-position="right" :controls="false" :precision="2" :step="0.1"></el-input-number>
      </el-form-item>
      <el-form-item label="付款日期" prop="paydate">
        <el-date-picker v-model="formData.paydate" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否开票">
        <el-switch v-model="formData.fp"></el-switch>
      </el-form-item>
      <el-form-item label="是否支付">
        <el-switch v-model="formData.payed"></el-switch>
      </el-form-item>
      <el-form-item>
        <div style="text-align: right">
          <span class="dialog-footer">
            <el-button @click="formCancel">取 消</el-button>
            <el-button type="primary" @click="formSubmit">确 定</el-button>
          </span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import {
	contractAddPaymentService,
	contractUpdatePaymentService
} from '@s/spareParts/ContractService';
import { hgetStorage } from '@u/htools.web';

export default {
	components: {},
	props: ['selectData_p', 'isRefreshList_p', 'contractId_p'],
	data() {
		return {
			pickerOptions: {
				disabledDate() {
					return false;
				}
			},
			editId: -1,
			currentOrgid: '',
			formData: {
				contractId: null, // 合同id
				fp: null, // 是否开票
				money: null, // 金额
				paydate: null, // 付款日期
				payed: null // 是否支付
			},
			rules: {
				money: [
					{
						required: true,
						message: '请输入金额',
						trigger: 'blur'
					}
				],
				paydate: [
					{
						required: true,
						message: '请选择付款日期',
						trigger: 'blur'
					}
				]
			},
			parentName: '',
			typeData: null,
			leftNamerightNameData: null,
			fileList: [],
			fileTempList: [],
			uploadAction: `${this.$envConfig.imgHttpWms}contract/ImgUpdate`
		};
	},
	created() {
		this.currentOrgid = hgetStorage('organization');
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				console.log(newValue);
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					this.formData.contractId = this.contractId_p;
					console.log(this.formData.dataArr);
					if (this.formData?.dataArr?.length === 2) {
						[this.formData.beginDate, this.formData.endDate] = this.formData.dataArr;
					}
					if (this.editId === -1) {
						await contractAddPaymentService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await contractUpdatePaymentService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', true);
				}
			});
		},
		formCancel() {
			this.$emit('update:isRefreshList_p', true);
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
