import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

/* 路由守卫 */
// eslint-disable-next-line import/no-cycle
import beforeRouter from './before.router';
import afterRouter from './after.router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	// 页面跳转显示在顶部
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
});

// 路由前置守卫
beforeRouter(router);
// 路由后置钩子
afterRouter(router);

export default router;
