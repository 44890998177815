<template>
	<div class="head-serch">
		<el-form
			ref="formEle"
			:inline="true"
			:model="formData"
			size="mini"
			label-width="70px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="启用状态">
				<el-select
					v-model="formData.enable"
					placeholder="请选择启用状态"
					class="select-item"
					filterable
					clearable
					style="width:100px;"
				>
					<el-option
						v-for="(item, index) in enableData"
						:key="index"
						:label="item.name"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="名称">
				<el-input
					v-model="formData.name"
					placeholder="请输入名称"
					clearable
					style="width:150px;"
				></el-input>
			</el-form-item>
			<el-form-item label="编码">
				<el-input
					v-model="formData.code"
					placeholder="请输入编码"
					clearable
					style="width:150px;"
				></el-input>
			</el-form-item>
			<el-form-item label="厂家">
				<el-input
					v-model="formData.factory"
					placeholder="请输入厂家"
					clearable
					style="width:150px;"
				></el-input>
			</el-form-item>
			<el-form-item label="型号">
				<el-input
					v-model="formData.goodsVer"
					placeholder="请输入型号"
					clearable
					style="width:150px;"
				></el-input>
			</el-form-item>
			<el-form-item label="管理方式">
				<el-select
					v-model="formData.manageType"
					placeholder="请选择管理方式"
					class="select-item"
					filterable
					clearable
					style="width:150px;"
				>
					<el-option
						v-for="(item, index) in managerStyleData"
						:key="index"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="规格">
				<el-input
					v-model="formData.spec"
					placeholder="请输入规格"
					clearable
				></el-input>
			</el-form-item>
			<el-form-item label="标签">
				<el-select
					v-model="formData.tags"
					class="select-item"
					clearable
					filterable
					placeholder="请选择标签"
				>
					<el-option
						v-for="item in tagsData"
						:key="item.value"
						:label="item"
						:value="item"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="计量单位">
				<el-select
					v-model="formData.units"
					placeholder="请选择计量单位"
					class="select-item"
					filterable
					clearable
					style="width:150px;"
				>
					<el-option
						v-for="(item, index) in measureUnitData"
						:key="index"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="分类">
				<!-- <SelectTreeMultiple v-model="formData.typeIds" :data="materialTypeData" multiple @getValue="checkChange" clearable collapseTags></SelectTreeMultiple> -->

				<el-select
					v-model="formData.typeIds"
					class="select-item"
					clearable
					filterable
					placeholder="请选择标签"
					multiple
					popper-class="materialOpen"
				>
					<div v-for="item in materialTypeData" :key="item.value">
						<span style="display:flex; ">
							<i
								v-if="item.children.length > 0"
								:class="[
									item.isOpen
										? 'el-icon-caret-bottom isOpenIcon'
										: 'el-icon-caret-right isOpenIcon'
								]"
								:data-aaa="1"
								@click="openMaterial(item)"
							></i>
							<i class="isOpenIcon" v-else></i>

							<el-option
								style="flex:1;"
								:label="item.name"
								:value="item.value"
								class="materialSelectItem"
							></el-option>
						</span>
						<p v-if="item.isOpen" style="margin-left:50px; ">
							<el-option
								v-for="item in item.children"
								:key="item.value"
								:label="item.name"
								:value="item.value"
								class="materialSelectItem"
							></el-option>
						</p>
					</div>
				</el-select>
			</el-form-item>
			<el-form-item label="适用设备">
				<el-input
					v-model="formData.forDev"
					placeholder="请输入适用设备"
					clearable
				></el-input>
			</el-form-item>
			<el-form-item label="规格型号">
				<el-input
					v-model="formData.forDevSpec"
					placeholder="请输入适用设备型号规格"
					clearable
				></el-input>
			</el-form-item>
			<el-form-item>
				<el-button
					class="filter-item"
					size="mini"
					type="success"
					icon="el-icon-search"
					@click.enter="searchHandler"
				>
					搜索
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import {
	managerStyleListService,
	tagsAllListService,
	measureUnitAllListService
} from '@s/spareParts/MaterialService';
// import SelectTreeMultiple from '@c/ui/SelectTreeMultiple/index';
import { materialTypeListService } from '@s/spareParts/MaterialTypeService';

export default {
	components: {
		// SelectTreeMultiple
	},
	data() {
		return {
			// 搜索
			formData: {
				name: '',
				code: '',
				factory: '',
				goodsVer: '',
				manageType: '',
				spec: '',
				tags: [],
				typeIds: ''
			},
			enableData: [
				{ name: '全部', value: '' },
				{ name: '是', value: true },
				{ name: '否', value: false }
			],
			managerStyleData: [],
			tagsData: [],
			measureUnitData: [],
			materialTypeData: []
		};
	},
	created() {
		this.managerStyleList();
		this.tagsList();
		this.measureUnitList();
		this.materialTypeList();
	},
	methods: {
		searchHandler() {
			console.log(this.formData);
			this.$emit('searchForm', this.formData);
		},
		async managerStyleList() {
			const dataJson = {};
			const res = await managerStyleListService(dataJson);
			this.managerStyleData = res;
		},
		async tagsList() {
			const dataJson = {};
			const res = await tagsAllListService(dataJson);
			this.tagsData = res;
		},
		async measureUnitList() {
			const dataJson = {};
			const res = await measureUnitAllListService(dataJson);
			this.measureUnitData = res;
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.name;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async materialTypeList() {
			const dataJson = {};
			const res = await materialTypeListService(dataJson);
			this.addTreeKey(res, 0);
			res.forEach(item => {
				if (!item.children) {
					item.children = [];
					item.isOpen = false;
				}
			});
			console.log('res');
			console.log(res);
			console.log(JSON.stringify(res));
			this.materialTypeData = res;
		},
		openMaterial(row) {
			console.log(row);
			this.materialTypeData.forEach((item, index, arr) => {
				if (item.id == row.id) {
					if (item.isOpen) {
						this.$set(arr, index, { ...item, isOpen: false });
					} else {
						this.$set(arr, index, { ...item, isOpen: true });
					}
				}
			});
		},
		checkChange(key, data) {
			console.log(key);
			console.log(data);
			this.formData.typeIds = key;
		}
	}
};
</script>

<style lang="less" scoped>
.isOpenIcon {
	width: 40px;
	height: 34px;
	line-height: 1em;
	// background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
/deep/.materialSelectItem {
	padding-left: 0 !important;
}
</style>
