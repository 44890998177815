<template>
  <el-row>
    <el-col :span="24">
      <!-- <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="96px"> -->
      <el-row style="margin-bottom: 20px; margin-top: -20px" v-if="!isList_p">
        <el-col :span="4">
          <el-image style="width: 100px; height: 100px" :src="apiMaterialDetailData.img"></el-image>
        </el-col>
        <el-col :span="10">
          <ul class="contentText">
            <li>
              <span>物料名称：</span>
              <span>{{ apiMaterialDetailData.name }}</span>
            </li>
            <li>
              <span>物料编码：</span>
              <span>{{ apiMaterialDetailData.code }}</span>
            </li>
            <li>
              <span>厂家：</span>
              <span>{{ apiMaterialDetailData.factory }}</span>
            </li>
            <li>
              <span>规格：</span>
              <span>{{ apiMaterialDetailData.spec }}</span>
            </li>
            <li>
              <span>型号：</span>
              <span>{{ apiMaterialDetailData.typeName }}</span>
            </li>
            <li>
              <span>标签：</span>
              <span>{{ apiMaterialDetailData.tags }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="10">
          <ul class="contentText">
            <li>
              <span>库存：</span>
              <span>{{ materialDetailData.cnt }}</span>
            </li>
            <li>
              <span>库房：</span>
              <span>{{ materialDetailData.warehouseName }}</span>
            </li>
            <li>
              <span>库位：</span>
              <span>{{ materialDetailData.positionName }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
      <!-- </el-form> -->
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="100px" :inline="true">
        <el-form-item label="移动到" prop="posId">
          <!-- <span><b style="color:red;">{{ materialDetailData.positionName }}</b> 移动到<i class="el-icon-d-arrow-right"></i>&nbsp;</span> -->
          <el-select v-model="formData.posId" clearable filterable placeholder="请选择移动到库位">
            <el-option v-for="(item, index) in wareareaData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { stockMoveService, stockMoveListService } from '@s/spareParts/CurrentStockService';
import { wareareaListService } from '@s/spareParts/WareareaService';
import { materialDetailService } from '@s/spareParts/MaterialService';
import { htimeFormat } from '../../../util/htools.web';

export default {
	props: ['selectData_p', 'isList_p'],
	components: {},
	data() {
		return {
			editId: -1,
			wareareaData: [],
			ids: [],
			// 弹窗
			formData: {
				posId: ''
			},
			formRules: {
				posId: [
					{
						required: true,
						message: '请选择移动到库位',
						trigger: 'change'
					}
				]
			},
			// isAddSameWarehouse: false,
			materialDetailData: '',
			apiMaterialDetailData: ''
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					console.log('newValue');
					console.log(newValue);
					this.ids = [];
					if (this.isList_p) {
						const temp = newValue;
						[this.materialDetailData] = newValue;
						// const flag = temp[0];
						temp.forEach(item => {
							// if (item.warehouseName !== flag.warehouseName) {
							// 	this.isAddSameWarehouse = true;
							// }
							this.ids.push(item.id);
						});
					} else {
						const temp = newValue[0];
						this.materialDetailData = temp;
						this.materialDetail();
						this.formData.posId = temp.positionId;
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.wareareaList();
	},
	methods: {
		async wareareaList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				warehouseId: this.materialDetailData.warehouseId
			};
			const res = await wareareaListService(dataJson);
			this.wareareaData = res.records;
		},
		async materialDetail() {
			const dataJson = {
				id: this.materialDetailData.goodsid
			};
			const res = await materialDetailService(dataJson);
			res.img = `${this.$envConfig.imgHttpAdd}${this.$envConfig.imgFile.wmsGoodsImg}/${res.pic}`;
			console.log(this.materialDetailData);
			this.apiMaterialDetailData = res;
		},

		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						if (this.isList_p) {
							// if (this.isAddSameWarehouse) {
							// 	this.$message.warning('不可以选择不在同一仓库的！');
							// 	this.$emit('update:isShowRemoveStockDialog_p', false);
							// 	return;
							// }
							// 批量移库
							this.formData.ids = this.ids;
							await stockMoveListService(this.formData);
						} else {
							// 单独移库
							this.formData.id = this.materialDetailData.id;
							await stockMoveService(this.formData);
						}
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowRemoveStockDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
.contentText {
	padding-left: 10px;
	li {
		line-height: 24px;
	}
}
</style>
