<template>
	<div class="head-serch">
		<el-form class="form" ref="parameterSearchForm" :model="parameterSearchForm" label-width="88px" @submit.native.prevent>
			<el-form-item label="名称" prop="name">
				<el-input v-model="parameterSearchForm.name" placeholder="请输入名称" @keyup.enter.native="searchHandler"/>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">搜索</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			parameterSearchForm: {
				name: ''
			}
		};
	},
	methods: {
		searchHandler() {
            this.$emit('searchForm', this.parameterSearchForm);
        }
	}
};
</script>

<style>
</style>
