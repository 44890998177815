<template>
	<div>
		<div class="search_box">
			<el-form ref="form" :model="form" label-width="80px" :inline="true">
				<el-form-item label="日期">
					<el-date-picker
						v-model="form.date"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:clearable="false"
					></el-date-picker>
				</el-form-item>

				<el-form-item label="维修公司" prop="orgid">
					<el-select
						v-model="form.orgid"
						placeholder="请选择维修公司名称"
						clearable
						filterable
						style="width: 100%"
					>
						<el-option
							v-for="item in optionsCompany"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-button
					type="success"
					class="search_button"
					@click="searchSubmit"
					size="mini"
				>
					搜索
				</el-button>
			</el-form>
		</div>
		<el-row :gutter="24" style="margin-bottom: 20px">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>整体服务费1</span>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(1)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts1"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>整体服务费2</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelAll({
									index: 2,
									title: '整体服务费2',
									options: options2
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(2)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item" style="width:100%; !important">
						<div
							id="echarts2"
							class="echart_box"
							style="width:100%; !important"
						></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>系统使用基础费</span>
					</div>
					<div class="echart_item">
						<b
							style="color: #02a7f0;font-size: 0.6rem; height: 100%;display: flex;align-items: center;justify-content: center; "
						>
							{{ basePrice }}元 / 年
						</b>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>数据维护技术服务费</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelworkerprice({
									index: 4,
									title: '数据维护技术服务费',
									options: options4
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(4)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts4" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>维修技术服务费</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelRepairDoneCntAndPriceByMonth({
									index: 5,
									title: '维修技术服务费',
									options: options5
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(5)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts5" class="echart_box"></div>
					</div>
					<!-- <div class="date_tag">
						<el-button :class="{ activeDate: dateEndType == 0 }" size="mini" round @click="dateEndSwitch(0)">
							年
						</el-button>
						<el-button :class="{ activeDate: dateEndType == 1 }" size="mini" round @click="dateEndSwitch(1)">
							季
						</el-button>
						<el-button :class="{ activeDate: dateEndType == 2 }" size="mini" round @click="dateEndSwitch(2)">
							月
						</el-button>
					</div> -->
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>巡检技术服务费</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelxjbyprice({
									index: 6,
									title: '巡检技术服务费',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(6)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts6" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="position: relative">
					<div slot="header" class="clearfix">
						<span>保养技术服务费</span>
						<el-button
							size="mini"
							round
							@click="
								downloadExcelxjbyprice({
									index: 6,
									title: '巡检技术服务费',
									options: options6
								})
							"
						>
							导出
						</el-button>
						<el-button
							style="float: right; padding: 3px 0"
							type="text"
							@click="showBigEchartsHandler(7)"
						>
							<i
								class="el-icon-top-right"
								style="font-size: 20px"
							></i>
						</el-button>
					</div>
					<div class="echart_item">
						<div id="echarts7" class="echart_box"></div>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	allHospitalRepairService,
	workerpriceService,
	allHospitalDevicePriceService,
	repairCntPriceByMonthService,
	xjbypriceService,
	systemBasePriceService
} from '@s/hospital/StatisticsService';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
import Dialog from '@c/ui/Dialog';
import { htimeFormat, hgetStorage, hdateInMonth, add } from '@u/htools.web';
import * as math from 'mathjs';

export default {
	components: {
		Dialog
	},
	data() {
		return {
			optionsCompany: [],
			form: {
				date: null,
				hosid: null,
				orgid: null
			},
			dateType: 2, // 月
			dateEndType: 2, // 月
			dateEndTypeSigntime: 2, // 月
			dialogTitle: '',
			options1: {
				// title: {
				// 	text: '整体服务费1'
				// },
				tooltip: {
					trigger: 'item',
					formatter(arg) {
						return `${arg.name}: ${arg.value}元`;
					}
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: '50%',
						left: '-30%',
						data: [],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			},
			options2: {
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						// return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
						arg.forEach(item => {
							item.data = item.data.toFixed(2);
							item.dataArr = item.data.split('.');
						});
						let str0 = `<div class="ownTooltip">${arg[0].axisValueLabel}<br/>`;
						arg.forEach(item => {
							str0 += `<p class="itemWrap">
								<span class="legendRound" style="background:${item.color}"></span>
								<span class="key">${item.seriesName}</span>
								<span class="value"><span class="dataArr0">${item.dataArr[0]}</span>.<span class="dataArr1">${item.dataArr[1]}</span></span></p>`;
						});
						str0 += '</div>';
						return str0;
					},
					axisPointer: {
						// Use axis to trigger tooltip
						type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
					}
				},
				legend: {},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '数据维护费',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [150, 212, 201, 154, 190, 330, 410]
					},
					{
						name: '维修技术服务费',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [320, 302, 301, 334, 390, 330, 320]
					},
					{
						name: '巡检费',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [120, 132, 101, 134, 90, 230, 210]
					},
					{
						name: '保养费',
						type: 'bar',
						stack: 'total',
						label: {
							show: true,
							formatter: v => {
								return v.data != 0 ? v.data : '';
							}
						},
						emphasis: {
							focus: 'series'
						},
						data: [220, 182, 191, 234, 290, 330, 310]
					}
				]
			},
			options4: {
				// title: {
				// 	text: '数据维护技术服务费'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '数据维护技术服务费',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					}
				]
			},
			options5: {
				// title: {
				// 	text: '维修技术服务费'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '维修技术服务费',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					}
				]
			},
			options6: {
				// title: {
				// 	text: '巡检技术服务费'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '巡检技术服务费',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					}
				]
			},
			options7: {
				// title: {
				// 	text: '保养技术服务费'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: '保养技术服务费',
						type: 'bar',
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					}
				]
			},
			isShowBigEcharts: false,
			basePrice: 0,
			// 维修
			wxData: [],
			// 巡检
			xjData: [],
			// 保养
			byData: [],
			// 技术
			jsData: [],
			allPriceData: []
		};
	},
	async mounted() {
		await this.getCompanyList();
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.form.date = [
			`${curYear}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];

		await this.systemBasePrice();
		await this.workerprice();
		await this.repairDoneCntPriceByMonthList(this.dateEndType);
		await this.xjbyprice();
		this.initAllMoney();
	},
	methods: {
		//  *维修公司 下拉
		async getCompanyList() {
			const res = await getcompanysignListService({
				pageNum: 1,
				pageSize: 999
			});

			this.optionsCompany = res.records || [];
			const isHasorgid = this.optionsCompany.some(
				item => item.id == 1118
			);
			this.form.orgid = isHasorgid ? 1118 : this.optionsCompany[0]?.id;

			this.optionsCompany = res.records;
		},
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				this.myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '整体服务费1';
					this.myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '整体服务费2';
					// delete this.options2.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options2);
				}
				if (index === 4) {
					this.dialogTitle = '数据维护技术服务费';
					// delete this.options4.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '维修技术服务费';
					// delete this.options5.series[0].itemStyle.normal.label.width;
					this.myChart.setOption(this.options5);
				}
				if (index === 6) {
					this.dialogTitle = '巡检技术服务费';
					this.myChart.setOption(this.options6);
				}
				if (index === 7) {
					this.dialogTitle = '保养技术服务费';
					this.myChart.setOption(this.options7);
				}
			}, 500);
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		async searchSubmit() {
			this.allPriceData = [];

			await this.systemBasePrice();
			await this.workerprice();
			await this.repairDoneCntPriceByMonthList(this.dateEndType);
			await this.xjbyprice();
			this.initAllMoney();
		},

		async systemBasePrice(dateType) {
			const dataJson = {
				...this.form,
				begin: htimeFormat(this.form.date[0]),
				end: htimeFormat(this.form.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await systemBasePriceService(dataJson);
			this.basePrice = res || 0;
		},
		async workerprice(dateType) {
			const dataJson = {
				...this.form,
				begin: htimeFormat(this.form.date[0]),
				end: htimeFormat(this.form.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await workerpriceService(dataJson);

			res.forEach(item => {
				this.allPriceData.push({
					mth: item.mth,
					wxData: item.sumprice,
					wx: 0,
					xj: 0,
					by: 0
				});
			});
			this.tableDownDataworkerprice = res;

			this.echartsInit4(res);
			console.log('workerpriceService');
			console.log(res);
		},
		async repairDoneCntPriceByMonthList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.form.date[0]),
				departid: 0,
				end: htimeFormat(this.form.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await repairCntPriceByMonthService(dataJson);

			this.echartsInit5(res);

			res.forEach(item => {
				// item.mth = htimeFormat(`${item.year}-${item.month}`,'{y}-{m}-{d} {h}:{i}:{s}')
				item.mth = htimeFormat(`${item.year}-${item.month}`, '{y}-{m}');
			});

			this.tableDownDataRepairDoneCntAndPriceByMonth = res || [];
			this.allPriceData.forEach(item1 => {
				res.forEach(item2 => {
					if (item1.mth == item2.mth) {
						item1.wx = item2.workerprice;
					}
				});
			});
		},
		async xjbyprice(dateType) {
			const dataJson = {
				...this.form,
				begin: htimeFormat(this.form.date[0]),
				end: htimeFormat(this.form.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await xjbypriceService(dataJson);
			this.tableDownDataxjbyprice = res || [];
			this.echartsInit6(res);
			this.echartsInit7(res);

			this.allPriceData.forEach(item1 => {
				res.forEach(item2 => {
					if (item1.mth == item2.mth) {
						item1.by = item2.byprice;
						item1.xj = item2.xjprice;
					}
				});
			});
		},
		// 整体服务费
		async initAllMoney() {
			this.echartsInit1(this.allPriceData);
			this.echartsInit2(this.allPriceData);
		},
		echartsInit1(res) {
			this.options1.series[0].data = [];

			let wxData = 0;
			let wx = 0;
			let xj = 0;
			let by = 0;
			// console.log(res);
			wxData = res.reduce((p, i) => {
				return math.add(math.bignumber(p), math.bignumber(i.wxData));
			}, 0);
			wx = res.reduce((p, i) => {
				return math.add(math.bignumber(p), math.bignumber(i.wx));
			}, 0);
			xj = res.reduce((p, i) => {
				return math.add(math.bignumber(p), math.bignumber(i.xj));
			}, 0);
			by = res.reduce((p, i) => {
				return math.add(math.bignumber(p), math.bignumber(i.by));
			}, 0);

			this.options1.series[0].data.push(
				{ value: Number(wxData), name: '数据维护费' },
				{ value: Number(wx), name: '维修技术服务费' },
				{ value: Number(xj), name: '巡检费' },
				{ value: Number(by), name: '保养费' }
			);

			console.log('this.options1.series');
			console.log(this.options1.series);
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);
		},
		echartsInit2(res) {
			const xAxisArr = [];
			const wxData = [];
			const wx = [];
			const xj = [];
			const by = [];

			res.forEach(item => {
				xAxisArr.push(item.mth);
				wxData.push(item.wxData);
				wx.push(item.wx);
				xj.push(item.xj);
				by.push(item.by);
			});

			this.options2.xAxis.data = xAxisArr;
			this.options2.series[0].data = wxData;
			this.options2.series[1].data = wx;
			this.options2.series[2].data = xj;
			this.options2.series[3].data = by;

			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit4(res) {
			const xAxisArr = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.mth}`);
				barArr.push(item.sumprice);
			});
			this.options4.xAxis[0].data = xAxisArr;
			this.options4.series[0].data = barArr;
			const chartDom = document.getElementById('echarts4');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			const xAxisArr = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				barArr.push(item.workerprice);
			});
			this.options5.xAxis[0].data = xAxisArr;
			this.options5.series[0].data = barArr;
			const chartDom = document.getElementById('echarts5');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit6(res) {
			const xAxisArr = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.mth}`);
				barArr.push(item.xjprice);
			});
			this.options6.xAxis[0].data = xAxisArr;
			this.options6.series[0].data = barArr;
			const chartDom = document.getElementById('echarts6');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options6);
		},
		echartsInit7(res) {
			const xAxisArr = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.mth}`);
				barArr.push(item.byprice);
			});
			this.options7.xAxis[0].data = xAxisArr;
			this.options7.series[0].data = barArr;
			const chartDom = document.getElementById('echarts7');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options7);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExcelAll(obj) {
			const tHeader = [
				'日期',
				'数据维护费',
				'维修技术服务费',
				'巡检费',
				'保养费'
			];
			const filterVal = ['mth', 'wxData', 'wx', 'xj', 'by'];
			const data = this.formatJson(filterVal, this.allPriceData);
			console.log(this.allPriceData);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `整体服务费2${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelworkerprice(obj) {
			const tHeader = ['日期', '数据维护技术服务费'];
			const filterVal = ['mth', 'sumprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataworkerprice
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `数据维护技术服务费${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDoneCntAndPriceByMonth(obj) {
			const tHeader = ['日期', '维修技术服务费'];
			const filterVal = ['mth', 'workerprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDoneCntAndPriceByMonth
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修技术服务费${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelxjbyprice(obj) {
			const tHeader = ['日期', '巡检技术服务费', '保养技术服务费'];
			const filterVal = ['mth', 'xjprice', 'byprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataxjbyprice
			);

			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `巡检、保养技术服务费${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.date_tag {
	position: absolute;
	top: 96px;
	right: 13px;
	height: 30px;
}

.activeDate {
	color: #409eff;
	border-color: #c6e2ff;
	background-color: #ecf5ff;
}

.search_box {
	margin-bottom: 20px;
}

.echart_item {
	width: 80%;
	height: 360px;
	margin: 0 auto;
}

.echart_box {
	width: 460px;
	width: 100%;
	height: 360px;
}

.search_button {
	margin-left: 10px;
}

.el-col {
	margin-bottom: 20px;
}

/deep/.ownTooltip {
}
/deep/.ownTooltip .itemWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/deep/.ownTooltip .itemWrap .legendRound {
	width: 0.1rem;
	height: 0.1rem;
	border-radius: 50%;
	display: inline-block;
}
/deep/.ownTooltip .itemWrap .key {
	margin-left: 0.02rem;
	margin-right: 10px;
}
/deep/.ownTooltip .itemWrap .value {
	flex: 1;
	text-align: right;
}
/deep/.ownTooltip .itemWrap .dataArr0 {
	font-size: 0.18rem;
}
/deep/.ownTooltip .itemWrap .dataArr2 {
	font-size: 0.16rem;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
