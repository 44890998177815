import {
    hgetRequest,
    hpostRequest
} from '../../util/htools.axios';
/*
 *@Description: 添加报价
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const addpriceService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/priceask/addprice', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 添加询价
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const addpriceaskService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/priceask/addpriceask', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 更改状态
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const changestateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/changestate', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 查询默认邀请人
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const defaultInviteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/defaultInvite', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 删除巡检
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const deleteService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/delete', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 删除报价
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const deletePriceService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/deletePrice', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 主键查询1个
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const getbyidService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/getbyid', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 主键获取报价详情
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const getpriceService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hgetRequest('hwms/priceask/getprice', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

/*
 *@Description: 翻页列表
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
 export const searchService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/priceask/search', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 编辑询价
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const updateService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/priceask/update', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};
/*
 *@Description: 更新报价
 *@MethodAuthor:  zxh
 *@Date: 2023-06-30 11:36:01
 */
export const updatepriceService = data => {
    return new Promise(async resolve => {
        try {
            const dataJson = {
                ...data
            };
            const res = await hpostRequest('hwms/priceask/updateprice', dataJson);
            resolve(res);
        } catch (error) {
            console.log(error);
        }
    });
};

export default searchService;
