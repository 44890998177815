<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchForm"
			:model="searchForm"
			label-width="60px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="科室" prop="departid">
				<treeselect
					v-model="searchForm.departid"
					:multiple="false"
					:options="optionsDept"
					style="width:200px;"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
					@close="againGetDevList"
					@search-change="resetDevDid1"
					@select="resetDevDid"
				/>
			</el-form-item>

			<el-form-item label="设备名称" prop="devid" label-width="100px">
				<el-select
					v-model="searchForm.devid"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
					@change="searchHandler"
				>
					<el-option
						v-for="(dev, index) in devListData"
						:key="index"
						:label="dev.devInfoName"
						:value="dev.devInfoId"
					></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item label="科室" prop="departname" label-width="100px">
				<el-input v-model="searchForm.departname" disabled placeholder="请输入科室" />
			</el-form-item>
			<el-form-item label="设备名称" prop="devname" label-width="100px">
				<el-input v-model="searchForm.devname" disabled placeholder="请输入设备名称" />
			</el-form-item> -->

			<el-form-item label="开始时间" label-width="100px">
				<el-date-picker
					v-model="searchForm.begintime"
					type="date"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="结束时间" label-width="100px">
				<el-date-picker
					v-model="searchForm.endtime"
					type="date"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd HH:mm:ss"
					placeholder="选择日期时间"
				></el-date-picker>
			</el-form-item>
		</el-form>
		<el-button
			class="filter-item"
			size="mini"
			type="success"
			icon="el-icon-search"
			@click="searchHandler"
		>
			搜索
		</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import { devBaseListService } from '@s/hospital/devBase/DevBaseService';
import { hgetStorage } from '@/util/htools.web';

// eslint-disable-next-line import/no-cycle
import Treeselect from '@riophae/vue-treeselect';

export default {
	props: ['elForm_P'],
	components: {
		Treeselect
	},
	data() {
		return {
			searchForm: {
				devid: null,
				departid: null,
				devname: null,
				departname: null
			},
			devListData: [],
			optionsDept: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			}
		};
	},
	created() {
		const { devid, departid, devname, departname } = this.elForm_P;
		this.searchForm.devid = Number(devid) || null;
		this.searchForm.departid = Number(departid) || null;
		this.searchForm.devname = devname;
		this.searchForm.departname = departname;
	},
	watch: {
		'searchForm.departid': {
			async handler(newValue, oldValue) {
				if (newValue == undefined) {
					this.searchForm.devid = null;
					this.devListData = [];
				}
			},
			deep: true
		}
	},
	async mounted() {
		// this.deviceTypeList();
		await this.departmentList();

		// this.searchForm.devid = this.elForm_P.devid;
		await this.devList();
		console.log('this.searchForm.devid');
		console.log(this.searchForm.devid);
		console.log(typeof this.searchForm.devid);
	},
	methods: {
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		// 科室下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		async againGetDevList(e) {
			// setTimeout(async () => {
			// 	await this.devList();
			// }, 10);
		},
		resetDevDid1(e) {
			console.log(e);
			setTimeout(async () => {
				this.searchForm.devid = null;
				this.$emit('searchForm', this.searchForm);
				await this.devList();
			}, 10);
		},
		resetDevDid(a, b) {
			console.log('++++++++++++++++');
			console.log(a);
			console.log(b);
			setTimeout(async () => {
				this.searchForm.devid = null;
				await this.devList();
			}, 10);
		},
		async devList() {
			if (!this.searchForm.departid) {
				return;
			}
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				did: this.searchForm.departid
			};
			const res = await devBaseListService(dataJson);
			this.devListData = res.records;
			console.log(this.devListData);
		},
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
	align-items: center;
}
</style>
