// eslint-disable-next-line import/no-cycle
import routes from '@/router/routes';

const permission = {
	state: {
		routes: [],
		addRoutes: [],
		defaultRoutes: []
	},
	mutations: {
		SET_ROUTES: (state, routesSet) => {
			state.addRoutes = routesSet;
			state.routes = routes.concat(routesSet);
			console.log(routes);
		},
		SET_DEFAULT_ROUTES: (state, routes) => {
			state.defaultRoutes = routes.concat(routes);
		}
	},
	actions: {}
};

export default permission;
