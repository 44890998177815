<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="factorySearchForm"
			:model="factorySearchForm"
			label-width="80px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="设备名称">
				<el-input v-model="factorySearchForm.devInfoName" placeholder="请输入设备名称" />
			</el-form-item>
			<el-form-item label="维保公司">
				<el-select
					v-model="factorySearchForm.orgId"
					placeholder="请选择"
					class="select-item"
					clearable
					filterable
					@clear="clearOrg"
				>
					<el-option
						v-for="(contract, index) in contractData"
						:key="index"
						:label="contract.name"
						:value="contract.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备类型" prop="name">
				<!-- <el-select v-model="devInfoTypeName" class="select-item" placeholder="请选择" collapse-tags clearable filterable @clear="clearDevInfo">
          <el-option :value="factorySearchForm.devType" style="height: auto">
            <el-tree :data="deviceTypeData" node-key="id" ref="tree" highlight-current :props="defaultTypeProps" @node-click="nodeTypeClickHandler"></el-tree>
          </el-option>
        </el-select> -->

				<treeselect
					v-model="factorySearchForm.devType"
					:multiple="false"
					:options="deviceTypeData"
					style="width:300px;"
					:disable-branch-nodes="true"
					:normalizer="normalizer"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>
			</el-form-item>
			<el-form-item label="科室" prop="name">
				<!-- <el-select v-model="parentName" class="select-item" placeholder="请选择" clearable filterable @clear="clearDid">
          <el-option :value="factorySearchForm.did" style="height: auto">
            <el-tree :data="optionsDept" node-key="id" ref="tree" highlight-current :props="defaultProps" @node-click="nodeClickHandler"></el-tree>
          </el-option>
        </el-select> -->

				<treeselect
					v-model="factorySearchForm.did"
					:multiple="false"
					:options="optionsDept"
					style="width:200px;"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>
			</el-form-item>
			<template v-if="searchHandlerBigFlag">
				<el-form-item label="在账状态">
					<el-select
						v-model="factorySearchForm.useType"
						placeholder="请选择在账状态"
						clearable
						filterable
					>
						<el-option label="在账在用" value="1"></el-option>
						<el-option label="不在账在用" value="2"></el-option>
						<el-option label="在账不在用" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="厂家报修到期状态" label-width="130px">
					<el-select
						v-model="factorySearchForm.factoryouttime"
						placeholder="请选择厂家报修到期状态"
						multiple
						clearable
						filterable
					>
						<el-option label="未到期" value="1"></el-option>
						<el-option label="已到期" value="2"></el-option>
						<el-option label="一月内到期" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备序列号" label-width="100px">
					<el-input v-model="factorySearchForm.sncode" placeholder="请输入设备序列号" />
				</el-form-item>
				<el-form-item label="设备价格" label-width="100px">
					<el-input
						v-model="factorySearchForm.priceBegin"
						placeholder="设备价格"
						type="number"
						style="width:100px;"
					/>
					到
					<el-input
						v-model="factorySearchForm.priceEnd"
						placeholder="设备价格"
						type="number"
						style="width:100px;"
					/>
				</el-form-item>
			</template>
		</el-form>
		<div style="display:flex; float:right;">
			<el-button
				class="filter-item"
				size="mini"
				type="success"
				icon="el-icon-search"
				@click="searchHandler"
			>
				搜索
			</el-button>
			<el-button
				class="filter-item"
				size="mini"
				type="primary"
				icon="el-icon-search"
				@click="searchHandlerBig"
			>
				{{ searchHandlerBigFlag ? '普通搜索' : '高级搜索' }}
			</el-button>
			<el-button
				class="filter-item"
				size="mini"
				type="default"
				@click="resetForm('devBaseSearchForm')"
			>
				重置
			</el-button>
		</div>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage } from '@/util/htools.web';
// eslint-disable-next-line import/no-cycle
import { getcompanysignListService } from '@s/hospital/contract/ContractService';
import { devBaseTypeListService } from '@s/hospital/devBase/DevBaseService';
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import Treeselect from '@riophae/vue-treeselect';

export default {
	components: {
		Treeselect
	},
	data() {
		return {
			factorySearchForm: {
				devInfoName: null,
				orgId: null,
				devType: null,
				did: null,
				sncode: null,
				factoryouttime: null
			},
			currentUserOrganization: '',
			contractData: [],
			devInfoTypeName: '',
			parentName: null,
			defaultProps: {
				children: 'children',
				label: 'department'
			},
			searchHandlerBigFlag: false,
			defaultTypeProps: {
				children: 'children',
				label: 'deviceType'
			},
			optionsDept: [],
			deviceTypeData: [],
			normalizer(node) {
				return {
					id: node.id,
					label: node.deviceType,
					children: node.children
				};
			}
		};
	},
	created() {
		this.currentUserOrganization = hgetStorage('organization');
		this.contractList();
		this.deviceTypeList();
		this.departmentList();
	},
	methods: {
		initSearchForm() {
			this.factorySearchForm = {
				devInfoName: null,
				orgId: null,
				devType: null,
				did: null,
				sncode: null,
				factoryouttime: null
			};
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await getcompanysignListService(dataJson);
			const { records } = res;
			this.contractData = records;
		},
		clearOrg() {
			this.factorySearchForm.orgId = null;
		},
		clearDevInfo() {
			this.factorySearchForm.devType = null;
		},
		clearDid() {
			this.factorySearchForm.did = 0;
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		addTreeKeyTypeList(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.deviceType;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 1000000,
				type: -1
			};
			const res = await devBaseTypeListService(dataJson);
			this.addTreeKeyTypeList(res, 0);
			this.deviceTypeData = [...res];
		},
		// 添加用户部门下拉
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.optionsDept = res.records;
		},
		nodeTypeClickHandler(data) {
			if (Number(data.pid) === 0) {
				return false;
			}
			this.factorySearchForm.devType = data.id;
			this.devInfoTypeName = data.deviceType;
		},

		resetForm(formName) {
			this.initSearchForm();
			this.searchHandler();
		},
		searchHandler() {
			this.$emit('searchForm', this.factorySearchForm);
		},
		searchHandlerBig() {
			this.searchHandlerBigFlag = !this.searchHandlerBigFlag;
		},
		nodeClickHandler(data) {
			this.factorySearchForm.did = data.id;
			this.parentName = data.department;
		}
	}
};
</script>

<style scoped>
.form {
	flex-wrap: wrap;
	float: left;
}

.head-serch {
	justify-content: space-between;
}
</style>
