import Login from '../views/Login.vue';
import v404 from '../views/404.vue';
import Layout from '../views/Layout.vue';
import Home from '../views/Home.vue';
/* 设备效益分析 设备 科室 全院 */
import AllHospitalDevearningDev from '../views/hospital/allHospitalDevearning/AllHospitalDevearningDev';
import AllHospitalDevearningDept from '../views/hospital/allHospitalDevearning/AllHospitalDevearningDept';
import AllHospitalDevearningAll from '../views/hospital/allHospitalDevearning/AllHospitalDevearningAll';
/* 全院统计 */
import AllHospitalDevice from '../views/hospital/allHospitalDevice/AllHospitalDevice';
import AllHospitalRepair from '../views/hospital/allHospitalRepair/AllHospitalRepair';
import allHospitalServiceCharge from '../views/hospital/allHospitalServiceCharge/allHospitalServiceCharge';
/* ygadmin 统计 */
import AllYgRepair from '../views/hospital/AllYgRepair/AllYgRepair';
// 进销统计
import InOutStatistics from '../views/hospital/AllYgRepair/InOutStatistics';
/* 科室统计 */
import DepartmentDevice from '../views/hospital/departmentDevice/DepartmentDevice';
import DepartmentRepair from '../views/hospital/departmentRepair/DepartmentRepair';
/* 系统管理 */
import UserManager from '../views/systemManager/UserManager';
import RoleManager from '../views/systemManager/RoleManager';
import AuthorizeManager from '../views/systemManager/AuthorizeManager';
import MenuManager from '../views/systemManager/MenuManager';
// 维修公司和医院修改基本信息
import HospitalEdit from '../views/systemManager/HospitalEditForm';
import CompanyEdit from '../views/systemManager/CompanyEditForm';

import Xjbydevprice from '../views/company/Xjbydevprice/Xjbydevprice';
import Enginerrlev from '../views/company/Enginerrlev/Enginerrlev';
import Systemprice from '../views/company/Systemprice/Systemprice';
import UserInHosHistory from '../views/company/UserInHosHistory/UserInHosHistory';

/* WebServer 临时测试websocket */
import WebServer from '../views/webServer/WebServer';
import jk from '../views/webServer/jk.vue';

/* 基础数据管理 */
import Hospital from '../views/baseData/Hospital';
import Organization from '../views/baseData/Organization';
import Department from '../views/baseData/Department';
import HospitalLevel from '../views/baseData/HospitalLevel';
import EquipmentType from '../views/baseData/EquipmentType';
import EquipmentCate from '../views/baseData/EquipmentCate';
import EquipmentModel from '../views/baseData/EquipmentModel';
import Equipment from '../views/baseData/Equipment';
import Parameter from '../views/baseData/Parameter';
import Problem from '../views/baseData/Problem';

/* 企业列表测试 */
import Company from '../views/company';

// 医院 设备管理
import DevBase from '../views/hospital/devBase/DevBase';
import Devearning from '../views/hospital/devBase/Devearning';
import ExpirationManufacturerMaintenance from '../views/hospital/ExpirationManufacturerMaintenance/ExpirationManufacturerMaintenance';
import DevBaseDetai from '../views/hospital/devBase/DevBaseDetai';
// 医院 计量提醒
import MeteringRemind from '../views/hospital/meteringRemind/MeteringRemind';
// 医院 巡检提醒
import PatrolRemind from '../views/hospital/patrolRemind/PatrolRemind';
// 医院 保养提醒
import MaintenanceRemind from '../views/hospital/maintenanceRemind/MaintenanceRemind';
// 医院 维修管理
import HospitalContract from '../views/hospital/contract/Contract';
import HospitalContractDetai from '../views/hospital/contract/ContractDetail';
// 医院 工程师管理
import Engineer from '../views/hospital/engineer/Engineer';
// 医院 厂家管理
import HospitalFactory from '../views/hospital/factory/Factory';
// 医院 部门
import HospitaDept from '../views/hospital/department/Department';
// 医院维修公司报修列表查看
import HospitalRepair from '../views/hospital/repair/Repair';
import CompanyRepair from '../views/company/repair/Repair';

import RepairDetail from '../views/repair/RepairDetail';
// 医院 巡检
import InspectionHospital from '../views/hospital/inspection/InspectionList';
// 医院 计量
import MeasureHospital from '../views/hospital/measure/MeasureList';
// 医院 保养
import KeepHospital from '../views/hospital/keep/KeepList';
// 医院 不良事件
import BadEvent from '../views/hospital/BadEvent/BadEvent';

// 维修公司 医院列表管理
import CompanyContract from '../views/company/contract/Contract';
import CompanyContractDetail from '../views/company/contract/ContractDetail';
// 维修公司 部门
import CompanyDept from '../views/company/department/Department';
// 员工维修分析
import EmpSummary from '../views/company/EmpSummary/EmpSummary';
// 员工维修分析图表
import EmpSummaryEcharts from '../factory/company/EmpSummary/EmpSummaryEcharts';
// 员工维修分析
import GroupSummary from '../views/company/GroupSummary/GroupSummary';
// import EmpSummaryEcharts from '../factory/company/EmpSummary/EmpSummaryEcharts';
// 维修公司 巡检模板
import InspectionTemp from '../views/company/inspectionTemp/InspectionTemp';
// 维修公司 巡检保养
import Inspection from '../views/company/inspectionTemp/Inspection';
// 维修公司 设备列表
import CompanyDevice from '../views/company/device/Device';
// 维修公司 巡检实施
import InspectionImplement from '../views/company/inspectionTemp/inspectionImplement';
// 维修公司 计量实施
import MeasureImplement from '../views/company/measure/MeasureImplement';
// 维修公司 计量计划
import Measure from '../views/company/measure/Measure';
// 维修公司 保养模板
import KeepTemp from '../views/company/keep/InspectionTemp';
// 维修公司 保养保养
import Keep from '../views/company/keep/Inspection';
// 维修公司 保养实施
import KeepImplement from '../views/company/keep/inspectionImplement';
/* 备品备件 库房管理 */
import WarehouseAll from '../views/spareParts/WarehouseAll';
import WarehouseSelf from '../views/spareParts/WarehouseSelf';
import Warearea from '../views/spareParts/Warearea';
/* eslint-disable */
import Supplier from '../views/spareParts/Supplier';
/* eslint-disable */
import SupplierH from '../views/spareParts/Supplier';
import Material from '../views/spareParts/Material';
import MaterialDetail from '../views/spareParts/MaterialDetail';
import MaterialType from '../views/spareParts/MaterialType';
import MaterialFlow from '../views/spareParts/MaterialFlow';
import MaterialTable from '../views/spareParts/MaterialTable';
import PurchaseApplication from '../views/spareParts/PurchaseApplication';
import BuyRequestDetail from '../views/spareParts/BuyRequestDetail';
import CheckStock from '../views/spareParts/CheckStock';
import SaleApplication from '../views/spareParts/SaleApplication';
import SellRequestDetail from '../views/spareParts/SellRequestDetail';
import Transfer from '../views/spareParts/Transfer';
import Borrow from '../views/spareParts/Borrow';
import PriceAsk from '../views/spareParts/PriceAsk';
import PriceAskDetail from '../views/spareParts/PriceAskDetail';
import Video from '../views/spareParts/Video';
import CurrentStock from '../views/spareParts/CurrentStock';
import PositonGraph from '../views/spareParts/PositonGraph';
import Transfering from '../views/spareParts/Transfering';
import InOutRecord from '../views/spareParts/InOutRecord';
import Contract from '../views/spareParts/Contract';

/* 日志 */
import LoginLog from '../views/log/LoginLog';
import OperationLog from '../views/log/OperationLog';
import ServiceLog from '../views/log/ServiceLog';
/* 文档管理 */
import DocType from '../views/docmentManager/DocType';
import Doc from '../views/docmentManager/Doc';
// 相关审批
import Apply from '../views/docmentManager/Apply';

const routes = [
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '登录'
		},
		component: Login
	},
	{
		path: '/loginhome',
		redirect: '/home'
	},
	{
		path: '/ws', // 临时测试websocket
		name: 'WebServer',
		meta: {
			title: 'WebServer'
		},
		component: WebServer
	},
	{
		path: '/',
		redirect: '/home',
		name: 'Layout',
		component: Layout,
		children: [
			{
				path: 'system/jk',
				name: 'jk',
				meta: {
					title: '监控',
					keepAlive: true
				},
				component: jk
			},
			{
				path: 'home',
				name: 'Home',
				meta: {
					title: '首页',
					keepAlive: true
				},
				component: Home
			},
			{
				path: 'AllHospitalDevearningDev',
				name: 'AllHospitalDevearningDev',
				meta: {
					title: '效益分析'
				},
				component: AllHospitalDevearningDev
			},
			{
				path: 'AllHospitalDevearningDept',
				name: 'AllHospitalDevearningDept',
				meta: {
					title: '科室效益分析'
				},
				component: AllHospitalDevearningDept
			},
			{
				path: 'AllHospitalDevearningAll',
				name: 'AllHospitalDevearningAll',
				meta: {
					title: '全院效益分析'
				},
				component: AllHospitalDevearningAll
			},
			{
				path: 'allHospitalDevice',
				name: 'AllHospitalDevice',
				meta: {
					title: '全院设备统计',
					keepAlive: true
				},
				component: AllHospitalDevice
			},
			{
				path: 'allHospitalRepair',
				name: 'AllHospitalRepair',
				meta: {
					title: '全院维修统计',
					keepAlive: true
				},
				component: AllHospitalRepair
			},
			{
				path: 'allHospitalServiceCharge',
				name: 'allHospitalServiceCharge',
				meta: {
					title: '技术服务费',
					keepAlive: true
				},
				component: allHospitalServiceCharge
			},
			{
				path: 'allYgRepair',
				name: 'AllYgRepair',
				meta: {
					title: '维修统计',
					keepAlive: true
				},
				component: AllYgRepair
			},
			{
				path: 'inOutStatistics',
				name: 'InOutStatistics',
				meta: {
					title: '进销统计',
					keepAlive: true
				},
				component: InOutStatistics
			},
			{
				path: 'departmentDevice',
				name: 'DepartmentDevice',
				meta: {
					title: '科室设备统计',
					keepAlive: true
				},
				component: DepartmentDevice
			},
			{
				path: 'departmentRepair',
				name: 'DepartmentRepair',
				meta: {
					title: '科室维修统计',
					keepAlive: true
				},
				component: DepartmentRepair
			},
			{
				path: 'system/HospitalEdit',
				name: 'HospitalEdit',
				meta: {
					title: '医院信息修改',
					keepAlive: true
				},
				component: HospitalEdit
			},
			{
				path: 'system/CompanyEdit',
				name: 'CompanyEdit',
				meta: {
					title: '维修公司修改',
					keepAlive: true
				},
				component: CompanyEdit
			},
			{
				path: 'system/Xjbydevprice',
				name: 'Xjbydevprice',
				meta: {
					title: '巡检保养价格'
				},
				component: Xjbydevprice
			},
			{
				path: 'system/Enginerrlev',
				name: 'Enginerrlev',
				meta: {
					title: '工程师等级'
				},
				component: Enginerrlev
			},
			{
				path: 'system/Systemprice',
				name: 'Systemprice',
				meta: {
					title: '系统价格'
				},
				component: Systemprice
			},
			{
				path: 'system/UserInHosHistory',
				name: 'UserInHosHistory',
				meta: {
					title: '工程师调动'
				},
				component: UserInHosHistory
			},
			{
				path: 'system/userManager',
				name: 'UserManager',
				meta: {
					title: '用户管理',
					keepAlive: true
				},
				component: UserManager
			},
			{
				path: 'system/roleManager',
				name: 'RoleManager',
				meta: {
					title: '角色管理',
					keepAlive: true
				},
				component: RoleManager
			},
			{
				path: 'system/authorizeManager',
				name: 'AuthorizeManager',
				meta: {
					title: '权限管理',
					keepAlive: true
				},
				component: AuthorizeManager
			},
			{
				path: 'system/menuManager',
				name: 'MenuManager',
				meta: {
					title: '菜单管理',
					keepAlive: true
				},
				component: MenuManager
			},
			{
				path: 'baseData/hospital', // 医院管理
				name: 'Hospital',
				meta: {
					title: '医院管理',
					keepAlive: true
				},
				component: Hospital
			},
			{
				path: 'baseData/organization', // 维修公司
				name: 'Organization',
				meta: {
					title: '维修公司',
					keepAlive: true
				},
				component: Organization
			},
			{
				path: 'baseData/department', // 部门管理
				name: 'Department',
				meta: {
					title: '部门管理',
					keepAlive: true
				},
				component: Department
			},
			{
				path: 'baseData/hospitalLevel', // 医院等级
				name: 'HospitalLevel',
				meta: {
					title: '医院等级',
					keepAlive: true
				},
				component: HospitalLevel
			},
			{
				path: 'baseData/equipmentType', // 设备类型
				name: 'EquipmentType',
				meta: {
					title: '设备类型',
					keepAlive: true
				},
				component: EquipmentType
			},
			{
				path: 'baseData/equipmentCate', // 设备种类
				name: 'EquipmentCate',
				meta: {
					title: '设备种类',
					keepAlive: true
				},
				component: EquipmentCate
			},
			{
				path: 'baseData/equipmentModel', // 设备型号
				name: 'EquipmentModel',
				meta: {
					title: '设备型号',
					keepAlive: true
				},
				component: EquipmentModel
			},
			{
				path: 'baseData/equipment', // 设备管理
				name: 'Equipment',
				meta: {
					title: '设备管理',
					keepAlive: true
				},
				component: Equipment
			},
			{
				path: 'metering/remind', // 计量提醒
				name: 'MeteringRemind',
				meta: {
					title: '计量提醒',
					keepAlive: true
				},
				component: MeteringRemind
			},
			{
				path: 'patrol/remind', // 巡检提醒
				name: 'PatrolRemind',
				meta: {
					title: '巡检提醒',
					keepAlive: true
				},
				component: PatrolRemind
			},
			{
				path: 'maintenance/remind', // 保养提醒
				name: 'MaintenanceRemind',
				meta: {
					title: '保养提醒',
					keepAlive: true
				},
				component: MaintenanceRemind
			},
			{
				path: 'baseData/problem', // 常见故障
				name: 'Problem',
				meta: {
					title: '常见故障',
					keepAlive: true
				},
				component: Problem
			},
			{
				path: 'baseData/parameter', // 参数管理
				name: 'Parameter',
				meta: {
					title: '参数管理',
					keepAlive: true
				},
				component: Parameter
			},
			{
				path: '/company',
				name: 'company',
				meta: {
					title: '企业列表',
					keepAlive: true
				},
				component: Company
			},
			// 医院 S
			{
				path: 'hospital/devBase',
				name: 'DevBase',
				meta: {
					title: '设备列表',
					keepAlive: true
				},
				component: DevBase
			},
			{
				path: 'hospital/Devearning',
				name: 'Devearning',
				meta: {
					title: '设备效益'
				},
				component: Devearning
			},
			{
				path: 'hospital/ExpirationManufacturerMaintenance',
				name: 'ExpirationManufacturerMaintenance',
				meta: {
					title: '厂家维保到期',
					keepAlive: true
				},
				component: ExpirationManufacturerMaintenance
			},
			{
				path: 'hospital/devBaseDetai',
				name: 'DevBaseDetai',
				meta: {
					title: '设备详情'
				},
				component: DevBaseDetai
			},
			{
				path: 'hospital/contract',
				name: 'HospitalContract',
				meta: {
					title: '维修公司管理',
					keepAlive: true
				},
				component: HospitalContract
			},
			{
				path: 'hospital/contractDetai',
				name: 'HospitalContractDetai',
				meta: {
					title: '维修管理详情'
				},
				component: HospitalContractDetai
			},
			{
				path: 'hospital/engineer',
				name: 'Engineer',
				meta: {
					title: '工程师管理',
					keepAlive: true
				},
				component: Engineer
			},

			{
				path: 'hospital/factory',
				name: 'HospitalFactory',
				meta: {
					title: '厂家管理',
					keepAlive: true
				},
				component: HospitalFactory
			},
			{
				path: 'hospital/dept',
				name: 'HospitaDept',
				meta: {
					title: '科室',
					keepAlive: true
				},
				component: HospitaDept
			},
			{
				path: 'hospital/inspection',
				name: 'InspectionHospital',
				meta: {
					title: '巡检工单',
					keepAlive: true
				},
				component: InspectionHospital
			},
			{
				path: 'hospital/measure',
				name: 'MeasureHospital',
				meta: {
					title: '计量工单',
					keepAlive: true
				},
				component: MeasureHospital
			},
			{
				path: 'hospital/keep',
				name: 'KeepHospital',
				meta: {
					title: '保养工单',
					keepAlive: true
				},
				component: KeepHospital
			},
			{
				path: 'hospital/BadEvent',
				name: 'BadEvent',
				meta: {
					title: '不良事件'
				},
				component: BadEvent
			},
			{
				path: 'company/inspectionTemp',
				name: 'InspectionTemp',
				meta: {
					title: '巡检模板',
					keepAlive: true
				},
				component: InspectionTemp
			},
			{
				path: 'company/inspection',
				name: 'Inspection',
				meta: {
					title: '巡检计划',
					keepAlive: true
				},
				component: Inspection
			},
			{
				path: 'company/inspectionImplement',
				name: 'InspectionImplement',
				meta: {
					title: '巡检实施',
					keepAlive: true
				},
				component: InspectionImplement
			},

			{
				path: 'company/keepTemp',
				name: 'KeepTemp',
				meta: {
					title: '保养模板',
					keepAlive: true
				},
				component: KeepTemp
			},
			{
				path: 'company/keep',
				name: 'Keep',
				meta: {
					title: '保养计划',
					keepAlive: true
				},
				component: Keep
			},
			{
				path: 'company/keepImplement',
				name: 'KeepImplement',
				meta: {
					title: '保养实施',
					keepAlive: true
				},
				component: KeepImplement
			},
			{
				path: 'hospital/repair',
				name: 'HospitalRepair',
				meta: {
					title: '报修列表',
					keepAlive: true
				},
				component: HospitalRepair
			},
			{
				path: 'company/repair',
				name: 'CompanyRepair',
				meta: {
					title: '报修列表',
					keepAlive: true
				},
				component: CompanyRepair
			},
			{
				path: 'hospital/RepairDetail',
				name: 'RepairDetail',
				meta: {
					title: '报修详情'
				},
				component: RepairDetail
			},
			{
				path: 'company/measureImplement',
				name: 'MeasureImplement',
				meta: {
					title: '计量实施',
					keepAlive: true
				},
				component: MeasureImplement
			},
			{
				path: 'company/measure',
				name: 'Measure',
				meta: {
					title: '计量计划',
					keepAlive: true
				},
				component: Measure
			},
			{
				path: 'company/device',
				name: 'CompanyDevice',
				meta: {
					title: '设备列表',
					keepAlive: true
				},
				component: CompanyDevice
			},
			// 医院 E
			// 维修公司 S
			{
				path: 'company/contract',
				name: 'CompanyContract',
				meta: {
					title: '医院列表',
					keepAlive: true
				},
				component: CompanyContract
			},
			{
				path: 'company/contractDetail',
				name: 'CompanyContractDetail',
				meta: {
					title: '医院详情'
				},
				component: CompanyContractDetail
			},
			{
				path: 'company/dept',
				name: 'CompanyDept',
				meta: {
					title: '部门',
					keepAlive: true
				},
				component: CompanyDept
			},
			{
				path: 'company/EmpSummary',
				name: 'EmpSummary',
				meta: {
					title: '员工工作统计列表',
					keepAlive: true
				},
				component: EmpSummary
			},
			{
				path: 'company/EmpSummaryEcharts',
				name: 'EmpSummaryEcharts',
				meta: {
					title: '维修分析'
				},
				component: EmpSummaryEcharts
			},
			{
				path: 'company/GroupSummary',
				name: 'GroupSummary',
				meta: {
					title: '项目组',
					keepAlive: true
				},
				component: GroupSummary
			},
			{
				path: 'spareParts/warehouseAll',
				name: 'WarehouseAll',
				meta: {
					title: '全部库房',
					keepAlive: true
				},
				component: WarehouseAll
			},
			{
				path: 'spareParts/warehouseSelf',
				name: 'WarehouseSelf',
				meta: {
					title: '我的库房管理',
					keepAlive: true
				},
				component: WarehouseSelf
			},
			{
				path: 'spareParts/warearea',
				name: 'Warearea',
				meta: {
					title: '库位管理',
					keepAlive: true
				},
				component: Warearea
			},
			{
				path: 'spareParts/supplier',
				name: 'Supplier',
				meta: {
					title: '供应商管理',
					keepAlive: true
				},
				component: Supplier
			},
			// 医院 设备 》 供应商管理菜单
			{
				path: 'hospital/supplier',
				name: 'SupplierH',
				meta: {
					title: '供应商管理',
					keepAlive: true
				},
				component: SupplierH
			},
			{
				path: 'spareParts/material',
				name: 'Material',
				meta: {
					title: '物料管理',
					keepAlive: true
				},
				component: Material
			},
			{
				path: 'spareParts/MaterialDetail',
				name: 'MaterialDetail',
				meta: {
					title: '物料详情'
					// keepAlive: true
				},
				component: MaterialDetail
			},
			{
				path: 'spareParts/materialType',
				name: 'MaterialType',
				meta: {
					title: '物料类型',
					keepAlive: true
				},
				component: MaterialType
			},
			{
				path: 'spareParts/materialFlow',
				name: 'MaterialFlow',
				meta: {
					title: '出入库桑基图',
					keepAlive: true
				},
				component: MaterialFlow
			},
			{
				path: 'spareParts/materialTable',
				name: 'MaterialTable',
				meta: {
					title: '出入库统计',
					keepAlive: true
				},
				component: MaterialTable
			},
			{
				path: 'spareParts/purchaseApplication',
				name: 'PurchaseApplication',
				meta: {
					title: '采购申请',
					keepAlive: true
				},
				component: PurchaseApplication
			},
			{
				path: 'spareParts/BuyRequestDetail',
				name: 'BuyRequestDetail',
				meta: {
					title: '采购详情',
					keepAlive: true
				},
				component: BuyRequestDetail
			},
			{
				path: 'spareParts/checkStock',
				name: 'CheckStock',
				meta: {
					title: '盘点',
					keepAlive: true
				},
				component: CheckStock
			},
			{
				path: 'spareParts/saleApplication',
				name: 'SaleApplication',
				meta: {
					title: '销售申请',
					keepAlive: true
				},
				component: SaleApplication
			},
			{
				path: 'spareParts/SellRequestDetail',
				name: 'SellRequestDetail',
				meta: {
					title: '销售详情',
					keepAlive: true
				},
				component: SellRequestDetail
			},
			{
				path: 'spareParts/transfer',
				name: 'Transfer',
				meta: {
					title: '调拨',
					keepAlive: true
				},
				component: Transfer
			},
			{
				path: 'spareParts/borrow',
				name: 'Borrow',
				meta: {
					title: '领用借用',
					keepAlive: true
				},
				component: Borrow
			},
			{
				path: 'spareParts/priceAsk',
				name: 'PriceAsk',
				meta: {
					title: '询价报价',
					keepAlive: true
				},
				component: PriceAsk
			},
			{
				path: 'PriceAskDetail/priceAsk',
				name: 'PriceAskDetail',
				meta: {
					title: '询价报价详情'
				},
				component: PriceAskDetail
			},
			{
				path: 'spareParts/video',
				name: 'Video',
				meta: {
					title: '视频管理',
					keepAlive: true
				},
				component: Video
			},
			{
				path: 'spareParts/PositonGraph',
				name: 'PositonGraph',
				meta: {
					title: '库位图管理',
					keepAlive: true
				},
				component: PositonGraph
			},
			{
				path: 'spareParts/currentStock',
				name: 'CurrentStock',
				meta: {
					title: '当前库存',
					keepAlive: true
				},
				component: CurrentStock
			},
			{
				path: 'spareparts/transfering',
				name: 'Transfering',
				meta: {
					title: '调拨在途',
					keepAlive: true
				},
				component: Transfering
			},
			{
				path: 'spareparts/inOutRecord',
				name: 'InOutRecord',
				meta: {
					title: '出入库记录',
					keepAlive: true
				},
				component: InOutRecord
			},
			{
				path: 'spareparts/contract',
				name: 'Contract',
				meta: {
					title: '合同管理',
					keepAlive: true
				},
				component: Contract
			},
			// 维修公司 E
			{
				path: 'log/login', // 登录日志
				name: 'LoginLog',
				meta: {
					title: '登录日志',
					keepAlive: true
				},
				component: LoginLog
			},
			{
				path: 'log/operation', // 操作日志
				name: 'OperationLog',
				meta: {
					title: '操作日志',
					keepAlive: true
				},
				component: OperationLog
			},
			{
				path: 'log/service', // 错误日志
				name: 'ServiceLog',
				meta: {
					title: '错误日志',
					keepAlive: true
				},
				component: ServiceLog
			},
			{
				path: 'doc/type',
				name: 'DocType',
				meta: {
					title: '文档分类',
					keepAlive: true
				},
				component: DocType
			},
			{
				path: 'doc/apply',
				name: 'Apply',
				meta: {
					title: '审批',
					keepAlive: true
				},
				component: Apply
			},
			{
				path: 'doc/doc',
				name: 'Doc',
				meta: {
					title: '文档管理',
					keepAlive: true
				},
				component: Doc
			},
			{
				path: '404',
				name: '404',
				meta: {
					title: '404'
				},
				component: v404
			}
		]
	},
	{
		path: '*',
		name: '404',
		meta: {
			title: '404'
		},
		component: v404
	}
];

export default routes;
