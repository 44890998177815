/* *@Description: 物料选择 不需要参数 返回选择对象数组？ *@ClassAuthor: Happy ZXH *@Date: 2024-01-02
15:30:37 */
<template>
  <div>
    <div class="dialogDocSearch">
      <el-form class="form" ref="queryInfoGood" :model="queryInfoGood" label-width="70px" @submit.native.prevent @keyup.enter.native="getGoodList">
        <el-form-item label="关键字">
          <el-input v-model="queryInfoGood.keywords" placeholder="关键字" />
        </el-form-item>
        <!-- <el-form-item label="配件名称">
          <el-input v-model="queryInfoGood.name" placeholder="配件名称" />
        </el-form-item> -->
        <el-form-item label="" label-width="0" style="margin-left:10px;">
          <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getGoodList">
            搜索
          </el-button>
          <el-button class="filter-item" size="mini" icon="el-icon-refresh-left" @click="resetFormGood('searchForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="width:100%; height:10px;"></div>
    <el-table ref="tableDataGood" :data="tableDataGood" highlight-current-row style="width: 100%" :header-cell-style="{ background: '#F4F4F4' }" :row-key="getRowKeys" @select="handleSelectionChangeGood" @select-all="handleSelectionChangeAllGood" class="tableDataGood tableDataNoChockbox">
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column property="id" label="序号"></el-table-column>
      <el-table-column property="code" label="编码"></el-table-column>
      <el-table-column property="name" label="配件名称"></el-table-column>
      <el-table-column property="goodsVer" label="配件型号"></el-table-column>
      <el-table-column property="factory" label="品牌"></el-table-column>
      <el-table-column property="forDev" label="适用设备"></el-table-column>
      <el-table-column property="forDevSpec" label="适用设备型号"></el-table-column>
      <el-table-column property="pic" label="图片">
        <template slot-scope="scope">
          <el-image v-if="scope.row.pic" class="devBaseFormLogoStr" style="width: 105px; height: 105px" :src="$envConfig.imgHttpAdd + 'wms/goods/' + scope.row.pic" :preview-src-list="[
									$envConfig.imgHttpAdd + 'wms/goods/' + scope.row.pic
								]" :fit="'contain'"></el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column property="manageTypeName" label="管理方式"></el-table-column> -->
      <el-table-column label="操作" align="left">
        <template slot-scope="scope">
          <!-- <el-button id="btn-update-row" type="success" @click="toGoodDetail(scope.row)">
						详情
					</el-button> -->
          <el-button id="btn-update-row" type="primary" @click="toGoodChoose(scope.row)">
            选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="margin: 20px;position:absolute; bottom:10px; right:0;" @size-change="handleDocSizeChange" @current-change="handleDocCurrentChange" :current-page="queryInfoGood.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfoGood.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalGood"></el-pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { materialListService as goodListService } from '@s/spareParts/MaterialService';

export default {
	props: ['tableDataP', 'queryInfoGoodP'],
	components: {},

	data() {
		return {
			// 新的物料添加
			isShowGoodDialog: false,
			tableDataGood: [],
			queryInfoGood: {
				pageNum: 1,
				pageSize: 10,
				enable: true
			},
			totalGood: 0,
			dataArrGood: []
		};
	},

	watch: {},

	computed: {},

	mounted() {
		this.getGoodList();
		console.log('this.queryInfoGoodP');
		console.log(this.queryInfoGoodP);
	},

	methods: {
		// #region 新的物料添加 S===============================
		// 文档列表 全部
		initSearchFormGood() {
			this.dataArrGood = [];
			this.queryInfoGood = {
				pageNum: 1,
				pageSize: 10,
				...this.queryInfoGoodP
			};
		},
		resetFormGood(formName) {
			this.initSearchFormGood();
			this.getGoodList();
		},
		async getGoodList() {
			console.log('this.queryInfoGoodP');
			console.log(this.queryInfoGoodP);
			if (this.dataArrGood) {
				[this.queryInfoGood.applyDatebegin, this.queryInfoGood.applyDateend] =
					this.dataArrGood;
			} else {
				[this.queryInfoGood.applyDatebegin, this.queryInfoGood.applyDateend] = [null, null];
			}

			const dataJson = { ...this.queryInfoGood, ...this.queryInfoGoodP };
			const res = await goodListService(dataJson);
			this.totalGood = res.total;
			this.tableDataGood = res.records;

			// 判断在defaultSelectedIds中含有的，通过方法去选中，保留defaultSelectedIds是因为defaultSelectedIds中的值无法对全部页选中
			// this.$nextTick(() => {
			// 	if (this.tableDataGood && this.defaultSelectedIdsGood) {
			// 		this.tableDataGood.forEach(row => {
			// 			if (this.defaultSelectedIdsGood.includes(row.id)) {
			// 				this.multipleSelectionGood.push(row);
			// 				this.$refs.tableDataGood.toggleRowSelection(row, true);
			// 			}
			// 		});
			// 	}
			// });
		},
		toGoodDetail(row) {
			console.log('row');
			console.log(row);
			this.$router.push({
				name: 'MaterialDetail',
				query: { id: row.id }
			});
		},
		// 获取行的key，用于表格保留分页选中
		getRowKeys(row) {
			return row.id;
		},
		handleSelectionChangeGood(selection, row) {
			this.multipleSelectionGood = selection;
		},
		handleSelectionChangeAllGood(selection) {
			console.log(selection);
		},
		handleDocSizeChange(val) {
			console.log(`设备文档 列表每页 ${val} 条`);
			this.queryInfoGood.pageSize = val;
			this.getGoodList();
			console.log(this.defaultSelectedIdsGood);
		},
		handleDocCurrentChange(val) {
			console.log(`设备文档 列表当前页: ${val}`);
			this.queryInfoGood.pageNum = val;
			this.getGoodList();
		},
		toGoodChoose(row) {
			console.log('选中的row');
			console.log(row);
			console.log(this);
			let flag = true;
			this.tableDataP &&
				this.tableDataP.forEach(item => {
					if (item.goodsId == row.id && !item.isAddDetail) {
						this.$message.warning('不能选择已经添加过的物料！');
						flag = false;
					}
				});

			flag && this.$emit('getChooseGood', row);
		}
		// #endregion
	}
};
</script>
<style lang="less" scoped>
// 深度选择器 去掉全选按钮
/deep/ .tableDataNoChockbox .el-table__header-wrapper .el-checkbox__inner {
	display: none;
}
/deep/ .dialogCom3 {
	min-height: 800px !important;
	height: 800px !important;
	position: relative !important;
}
/deep/ .dialogCom3 .el-table__body-wrapper {
	max-height: 500px !important;
	overflow-y: auto !important;
}
/deep/ .el-table__body-wrapper {
	max-height: 500px !important;
	overflow-y: auto !important;
}
</style>
