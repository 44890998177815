<template>
	<div class="app-container">
		<!-- <DevBaseSearchForm @searchForm="searchFormHandler"></DevBaseSearchForm> -->
		<!-- <Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devBase'"
		></Button> -->
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="工程师" prop="nickName"></el-table-column>
			<el-table-column label="电话" prop="telphone"></el-table-column>
			<el-table-column label="所属公司" prop="organizationName"></el-table-column>
			<el-table-column label="备注" prop="remark"></el-table-column>

			<!-- <el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'devBase', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column> -->
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<!-- <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DevBaseAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></DevBaseAddForm>
		</Dialog> -->
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { engineerListService, devBaseDeleteService, devBaseDetailService } from '@s/hospital/engineer/EngineerService';
/* import Dialog from '@c/ui/Dialog'; */
/* import Button from '@c/ui/Button'; */
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
/* import DevBaseAddForm from '@f/hospital/equipment/DevBaseAdd.form';
import DevBaseSearchForm from '@f/hospital/equipment/DevBaseSearch.form'; */
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: 'Engineer',
	mixins: [ListMixin],
	components: {
		Table,
		/* Dialog, */
		Pagination
		/* Button, */
		/* DevBaseAddForm,
		DevBaseSearchForm */
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.devBaseList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.devBaseList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.devBaseList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.devBaseList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.devBaseList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async devBaseList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				// hospitalId: hgetStorage('organization'),
				...this.searchForm
			};
			const res = await engineerListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devBaseId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			// this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				devBaseId: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				devInfoId: row.id
			};
			const res = await devBaseDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await devBaseDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.devBaseList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang="less" scoped></style>
