<template>
	<div class="head-serch">
		<el-form class="form" ref="searchForm" :model="searchForm" label-width="70px" @submit.native.prevent
			@keyup.enter.native="searchHandler">
				<el-form-item label="医院" prop="hosid">
					<el-select v-model="searchForm.hosid" placeholder="请选择医院" clearable filterable style="width: 100%">
						<el-option v-for="item in contractData" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="工程师" prop="uid">
					<el-select v-model="searchForm.uid" placeholder="请选择工程师" clearable filterable style="width: 100%">
						<el-option v-for="item in engineerData" :key="item.id" :label="item.nickName"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
			@click="searchHandler">搜索</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { getcompanysignListService } from '@s/company/contract/ContractService';
import { userListService } from '@s/system/UserService';
import { hgetStorage } from '@/util/htools.web';

export default {
	data() {
		return {
			searchForm: {
				name: ''
			},
			contractData: [],
			engineerData: []
		};
	},
	created() {
		this.engineerList();
		this.contractList();
	},
	methods: {
		async engineerList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: 1000000000,
				userName: '',
				...this.searchForm
			};
			const res = await userListService(dataJson);
			this.engineerData = [...res.records];
		},
		async contractList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await getcompanysignListService(dataJson);
			this.contractData = [...res.records];
		},
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
