<template>
	<div class="head-serch">
		<el-form class="form" ref="formEle" :model="formData" label-width="77px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="物料">
				<el-select v-model="formData.goods" multiple clearable filterable>
					<el-option v-for="(item, index) in materialData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="源库房">
				<el-select v-model="formData.froms" multiple clearable>
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="目标库房">
				<el-select v-model="formData.tos" multiple clearable>
					<el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
import { materialAllListService } from '@s/spareParts/MaterialService';
import { warehouseSelectListService } from '@s/spareParts/WarehouseService';

export default {
	data() {
		return {
			// 搜索
			formData: {
				goods: [],
				froms: [],
				tos: []
			},
			materialData: [],
			warehouseData: []
		};
	},
	created() {
		this.materialList();
		this.warehouseList();
	},
	methods: {
		async materialList() {
			const dataJson = {};
			const res = await materialAllListService(dataJson);
			this.materialData = res;
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			console.log(res);
			this.warehouseData = res;
		},
		searchHandler() {
			this.$emit('searchForm', this.formData);
		}
	}
};
</script>

<style lang="less" scoped></style>
