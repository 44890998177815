<template>
	<div>
		<!-- <el-alert v-if="!deptId" title="请先设置部门" type="warning" :closable="false"></el-alert> -->
		<template>
			<div
				class="search_box"
				style="display: flex;    justify-content: flex-start;    align-items: center;"
			>
				<span style=" font-size: 15px;">所在科室：</span>
				<!-- <el-select v-model="deptId" placeholder="请选择" :disabled="!!defaultDeptId" @change="deptSwitch">
						<el-option :value="0" label="请选择"></el-option>
						<el-option v-for="item in departmentData" :key="item.id" :label="item.department" :value="item.id"></el-option>
					</el-select> -->
				<!-- <SelectTree v-model="deptId" :data="departmentData" :disabled="!!defaultDeptId" @change="deptSwitch" node-key="id" :isLastLeaf="true" :clearable_p="false" style="width: 20%"></SelectTree> -->
				<treeselect
					v-model="deptId"
					:multiple="false"
					:options="departmentData"
					style="width:300px; margin-right:10px;"
					:disabled="!!defaultDeptId"
					:disable-branch-nodes="false"
					placeholder="请选择"
					noResultsText="暂无结果"
					:close-on-select="true"
					:show-count="true"
				/>

				<el-date-picker
					v-model="date"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button
					type="success"
					class="search_button"
					@click="searchSubmit"
					size="mini"
				>
					搜索
				</el-button>
			</div>
			<el-row :gutter="24" style="margin-bottom: 20px">
				<el-col :span="12">
					<el-card class="box-card" style="position: relative">
						<div slot="header" class="clearfix">
							<span>报修数量和维修费用</span>

							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairCntAndPriceByMonth({
										index: 1,
										title: '报修数量和维修费用',
										options: options1
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(1)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts1" class="echart_box"></div>
						</div>
						<div class="date_tag">
							<el-button
								:class="{ activeDate: dateType == 0 }"
								size="mini"
								round
								@click="dateSwitch(0)"
							>
								年
							</el-button>
							<el-button
								:class="{ activeDate: dateType == 1 }"
								size="mini"
								round
								@click="dateSwitch(1)"
							>
								季
							</el-button>
							<el-button
								:class="{ activeDate: dateType == 2 }"
								size="mini"
								round
								@click="dateSwitch(2)"
							>
								月
							</el-button>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card" style="position: relative">
						<div slot="header" class="clearfix">
							<span>维修完成数量和费用</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairDoneCntAndPriceByMonth({
										index: 8,
										title: '维修完成数量和费用',
										options: options8
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(8)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts8" class="echart_box"></div>
						</div>
						<div class="date_tag">
							<el-button
								:class="{ activeDate: dateEndType == 0 }"
								size="mini"
								round
								@click="dateEndSwitch(0)"
							>
								年
							</el-button>
							<el-button
								:class="{ activeDate: dateEndType == 1 }"
								size="mini"
								round
								@click="dateEndSwitch(1)"
							>
								季
							</el-button>
							<el-button
								:class="{ activeDate: dateEndType == 2 }"
								size="mini"
								round
								@click="dateEndSwitch(2)"
							>
								月
							</el-button>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card" style="position: relative">
						<div slot="header" class="clearfix">
							<span>维修完成数量和费用（签单时间）</span>

							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairSignCntAndPriceByMonth({
										index: 9,
										title: '维修完成数量和费用（签单时间）',
										options: options9
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(9)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts9" class="echart_box"></div>
						</div>
						<div class="date_tag">
							<el-button
								:class="{
									activeDate: dateEndTypeSigntime == 0
								}"
								size="mini"
								round
								@click="dateEndSwitchSigntime(0)"
							>
								年
							</el-button>
							<el-button
								:class="{
									activeDate: dateEndTypeSigntime == 1
								}"
								size="mini"
								round
								@click="dateEndSwitchSigntime(1)"
							>
								季
							</el-button>
							<el-button
								:class="{
									activeDate: dateEndTypeSigntime == 2
								}"
								size="mini"
								round
								@click="dateEndSwitchSigntime(2)"
							>
								月
							</el-button>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>报修次数(次)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairDeviceCntTop({
										index: 2,
										title: '报修次数(次)',
										options: options2
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(2)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts2" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<!-- </el-row> -->
				<!-- <el-row :gutter="24" style="margin-bottom: 20px"> -->
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>报修费用(元)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairDeviceCntTop({
										index: 3,
										title: '报修费用(元)',
										options: options3
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(3)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts3" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>类型报修次数(次)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairDeviceTypeCntTop({
										index: 4,
										title: '类型报修次数(次)',
										options: options4
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(4)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts4" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
				<!-- </el-row> -->
				<!-- <el-row :gutter="24"> -->
				<el-col :span="12">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>类型报修费用(元)</span>
							<el-button
								size="mini"
								round
								@click="
									downloadExcelRepairDeviceTypeCntTop({
										index: 5,
										title: '类型报修费用(元)',
										options: options5
									})
								"
							>
								导出
							</el-button>
							<el-button
								style="float: right; padding: 3px 0"
								type="text"
								@click="showBigEchartsHandler(5)"
							>
								<i
									class="el-icon-top-right"
									style="font-size: 20px"
								></i>
							</el-button>
						</div>
						<div class="echart_item">
							<div id="echarts5" class="echart_box"></div>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</template>
		<Dialog
			:title="dialogTitle"
			:visible.sync="isShowBigEcharts"
			width="90%"
			@before-close="beforeCloseHandler"
		>
			<div v-if="isShowBigEcharts">
				<div id="bigEchartsBox" class="bigEchartsBox"></div>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	allHospitalRepairService,
	repairDeviceCntTopService,
	repairDevicePriceTopService,
	repairDeviceTypeCntTopService,
	repairDeviceTypePriceTopService,
	repairCntPriceByMonthService,
	RepairSignCntAndPriceByMonthService
} from '@s/hospital/StatisticsService';
// eslint-disable-next-line import/no-cycle
import { departmentListDefaultService } from '@s/hospital/department/DepartmentService';
import { htimeFormat, hgetStorage, hdateInMonth } from '@u/htools.web';
// import SelectTree from '@c/ui/SelectTree';
import Dialog from '@c/ui/Dialog';
import Treeselect from '@riophae/vue-treeselect';

export default {
	components: {
		// SelectTree,
		Dialog,
		Treeselect
	},
	data() {
		return {
			date: '',
			dateType: 2, // 月
			dateEndType: 2, // 月
			dateEndTypeSigntime: 2, // 月
			dialogTitle: '',
			deptId: null,
			defaultDeptId: '',
			departmentData: [],
			options1: {
				// title: {
				// 	text: '报修数量和维修费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				grid: {
					containLabel: true,
					bottom: 10
				},
				legend: {
					left: 0
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '报修数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修应收费用',
						type: 'line',
						data: [],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options2: {
				// title: {
				// 	text: '报修次数(次)'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}: ${arg[0].value[0]}次`;
					}
				},
				dataset: {
					source: []
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#FFC655'
							}
						}
					}
				]
			},
			options3: {
				// title: {
				// 	text: '报修费用(元)'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}: ${arg[0].value[0]}元`;
					}
				},
				dataset: {
					source: [
						['amount', 'product'],
						[58212, 'Matcha Latte'],
						[78254, 'Milk Tea'],
						[41032, 'Cheese Cocoa'],
						[12755, 'Cheese Brownie'],
						[20145, 'Matcha Cocoa'],
						[79146, 'Tea'],
						[91852, 'Orange Juice'],
						[101852, 'Lemon Juice'],
						[20112, 'Walnut Brownie']
					]
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								}
							},
							color: '#00BC88'
						}
					}
				]
			},
			options4: {
				// title: {
				// 	text: '类型报修次数(次)'
				// },
				tooltip: {
					trigger: 'axis',
					position: pos => {
						// 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
						return pos;
					},
					formatter(arg) {
						const a = `${arg[0].name}: ${arg[0].value[0]}次`;
						let b = a.split(''); // 输出为["1","2","3","4","5","6"]
						const c = 26;
						if (b.length > c * 3) {
							b.splice(c * 3, 0, '<br />');
						}
						if (b.length > c * 2) {
							b.splice(c * 2, 0, '<br />');
						}
						if (b.length > c) {
							b.splice(c, 0, '<br />');
						}
						b = b.join('');

						return b;
					}
				},
				dataset: {
					source: []
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									// position: 'inside',
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#22A4FF'
							}
						}
					}
				]
			},
			options5: {
				// title: {
				// 	text: '类型报修费用(元)'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}: ${arg[0].value[0]}元`;
					}
				},
				dataset: {
					source: []
				},
				grid: { containLabel: true },
				xAxis: {},
				yAxis: {
					axisLabel: {
						show: false
					},
					type: 'category'
				},
				series: [
					{
						type: 'bar',
						encode: {
							// Map the "amount" column to X axis.
							x: 'amount',
							// Map the "product" column to Y axis
							y: 'product'
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'insideLeft',
									formatter: '{b}',
									width: '350',
									overflow: 'truncate',
									ellipsis: '...'
								},
								color: '#FF5C5C'
							}
						}
					}
				]
			},
			options8: {
				// title: {
				// 	text: '维修完成数量和费用'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '维修完成数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修完成费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			options9: {
				// title: {
				// 	text: '维修完成数量和费用（签单时间）'
				// },
				tooltip: {
					trigger: 'axis',
					formatter(arg) {
						return `${arg[0].name}<br>${arg[0].seriesName}: ${arg[0].value}台<br>${arg[1].seriesName}: ${arg[1].value}元<br>${arg[2].seriesName}: ${arg[2].value}元`;
					}
				},
				legend: {
					left: 0
				},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
					}
				],
				yAxis: [
					{
						type: 'value',
						scale: true,
						min: 0
					},
					{
						type: 'value',
						scale: true,
						min: 0
					}
				],
				series: [
					{
						name: '维修完成数量',
						type: 'bar',
						yAxisIndex: 1,
						data: [21, 22, 23, 24, 25, 26, 27, 28, 19],
						itemStyle: {
							normal: {
								color: '#4BC2DF'
							}
						}
					},
					{
						name: '维修完成费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#C51CDD'
							}
						}
					},
					{
						name: '维修实收费用',
						type: 'line',
						data: [11, 2, 3, 4, 5, 6, 7, 8, 19],
						itemStyle: {
							normal: {
								color: '#ee6666'
							}
						}
					}
				]
			},
			isShowBigEcharts: false
		};
	},
	mounted() {
		this.deptId =
			hgetStorage('deptId') === 0 ? null : hgetStorage('deptId');
		this.defaultDeptId = hgetStorage('deptId');
		const curYear = new Date().getFullYear();
		const curMonth = new Date().getMonth() + 1;
		const endDate = hdateInMonth(`${curYear}-${curMonth}`);
		this.date = [
			`${curYear}-01-01 00:00:00`,
			`${curYear}-${curMonth}-${endDate} 23:59:59`
		];
		if (this.deptId) {
			this.allHospitalRepairList(this.dateType);
			this.repairDeviceCntTop();
			this.repairDevicePriceTop();
			this.repairDeviceTypeCntTop();
			this.repairDeviceTypePriceTop();
		}
		this.departmentList();
	},
	methods: {
		beforeCloseHandler() {
			this.isShowBigEcharts = false;
		},
		showBigEchartsHandler(index) {
			this.dialogTitle = '';
			this.isShowBigEcharts = true;
			setTimeout(() => {
				const chartDom = document.getElementById('bigEchartsBox');
				const myChart = this.$echarts.init(chartDom);

				// this.myChart = this.$echarts.init(chartDom);
				if (index === 1) {
					this.dialogTitle = '报修数量和维修费用';
					myChart.setOption(this.options1);
				}
				if (index === 2) {
					this.dialogTitle = '报修次数(次)';
					delete this.options2.series[0].itemStyle.normal.label.width;
					myChart.setOption(this.options2);
				}
				if (index === 3) {
					this.dialogTitle = '报修费用(元)';
					delete this.options3.series[0].itemStyle.normal.label.width;
					myChart.setOption(this.options3);
				}
				if (index === 4) {
					this.dialogTitle = '类型报修次数(次)';
					delete this.options4.series[0].itemStyle.normal.label.width;
					myChart.setOption(this.options4);
				}
				if (index === 5) {
					this.dialogTitle = '类型报修费用(元)';
					delete this.options5.series[0].itemStyle.normal.label.width;
					myChart.setOption(this.options5);
				}
				if (index === 8) {
					this.dialogTitle = '维修完成数量和费用';
					myChart.setOption(this.options8);
				}
				if (index === 9) {
					this.dialogTitle = '维修完成数量和费用（签单时间）';
					myChart.setOption(this.options9);
				}
			}, 500);
		},
		deptSwitch() {
			this.allHospitalRepairList(this.dateType);
			this.repairDoneCntPriceByMonthList(this.dateEndType);
			this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
			this.repairDeviceCntTop();
			this.repairDevicePriceTop();
			this.repairDeviceTypeCntTop();
			this.repairDeviceTypePriceTop();
		},
		// 递归设置value label 级联下拉数据
		addTreeKey(data, level) {
			if (data.length > 0) {
				data.forEach(item => {
					let TLevel = level;
					item.value = item.id;
					item.label = item.department;
					item.level = TLevel;
					if (item.children) {
						this.addTreeKey(item.children, ++TLevel);
					}
				});
				return data;
			}
		},
		async departmentList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 999
			};
			const res = await departmentListDefaultService(dataJson);
			this.addTreeKey(res.records, 0);
			this.departmentData = res.records;
		},
		dateSwitch(dateType) {
			this.dateType = dateType;
			this.allHospitalRepairList(this.dateType);
		},
		dateEndSwitch(dateType) {
			this.dateEndType = dateType;
			this.repairDoneCntPriceByMonthList(this.dateEndType);
		},
		dateEndSwitchSigntime(dateType) {
			this.dateEndTypeSigntime = dateType;
			this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
		},
		searchSubmit() {
			this.allHospitalRepairList(this.dateType);
			this.repairDoneCntPriceByMonthList(this.dateEndType);
			this.RepairSignCntAndPriceByMonthService(this.dateEndTypeSigntime);
			this.repairDeviceCntTop();
			this.repairDevicePriceTop();
			this.repairDeviceTypeCntTop();
			this.repairDeviceTypePriceTop();
		},
		async allHospitalRepairList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await allHospitalRepairService(dataJson);
			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairCntAndPriceByMonth = res || [];
			this.echartsInit1(res);
		},
		async repairDoneCntPriceByMonthList(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await repairCntPriceByMonthService(dataJson);
			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairDoneCntAndPriceByMonth = res || [];
			this.echartsInit8(res);
		},
		async RepairSignCntAndPriceByMonthService(dateType) {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				grouptype: dateType
			};
			const res = await RepairSignCntAndPriceByMonthService(dataJson);
			res.forEach(item => {
				if (item.month > 12) {
					item.mthTemp = `${item.year}`;
				} else {
					item.mthTemp = `${item.year}-${item.month}`;
				}
			});
			this.tableDownDataRepairSignCntAndPriceByMonth = res || [];
			this.echartsInit9(res);
		},
		async repairDeviceCntTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceCntTopService(dataJson);
			this.tableDownDataRepairDeviceCntTop = res || [];
			this.echartsInit2(res);
		},
		async repairDevicePriceTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDevicePriceTopService(dataJson);
			this.echartsInit3(res);
		},
		async repairDeviceTypeCntTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceTypeCntTopService(dataJson);

			this.tableDownDataRepairDeviceTypeCntTop = res || [];
			this.echartsInit4(res);
		},
		async repairDeviceTypePriceTop() {
			const dataJson = {
				begin: htimeFormat(this.date[0]),
				departid: this.deptId || 0,
				end: htimeFormat(this.date[1]),
				hosid: hgetStorage('organization'),
				top: 10
			};
			const res = await repairDeviceTypePriceTopService(dataJson);
			this.echartsInit5(res);
		},
		echartsInit1(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options1.xAxis[0].data = xAxisArr;
			this.options1.series[0].data = barArr;
			this.options1.series[1].data = lineArr;
			this.options1.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts1');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options1);

			myChart.on('click', params => {
				this.$router.push(
					`/hospital/repair?date=${
						xAxisArr[params.dataIndex]
					}&dateType=${this.dateType}&deptId=${this.deptId}`
				);
			});
		},
		echartsInit2(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.cnt, item.deviceName]);
			});
			this.options2.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts2');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options2);
		},
		echartsInit3(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.repairPrice, item.deviceName]);
			});
			this.options3.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts3');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options3);
		},
		echartsInit4(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.cnt, item.deviceType]);
			});
			this.options4.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts4');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options4);
		},
		echartsInit5(res) {
			const dataArr = [];
			res.forEach(item => {
				dataArr.push([item.repairPrice, item.deviceType]);
			});
			this.options5.dataset.source = dataArr;
			const chartDom = document.getElementById('echarts5');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options5);
		},
		echartsInit8(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options8.xAxis[0].data = xAxisArr;
			this.options8.series[0].data = barArr;
			this.options8.series[1].data = lineArr;
			this.options8.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts8');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options8);
		},
		echartsInit9(res) {
			const xAxisArr = [];
			const lineArr = [];
			const lineArr1 = [];
			const barArr = [];
			res.forEach(item => {
				xAxisArr.push(`${item.year}-${item.month}`);
				lineArr.push(item.price);
				lineArr1.push(item.realprice);
				barArr.push(item.cnt);
			});
			this.options9.xAxis[0].data = xAxisArr;
			this.options9.series[0].data = barArr;
			this.options9.series[1].data = lineArr;
			this.options9.series[2].data = lineArr1;
			const chartDom = document.getElementById('echarts9');
			const myChart = this.$echarts.init(chartDom);
			myChart.setOption(this.options9);
		},
		formatJson(filterVal, tableData) {
			return tableData.map(v => {
				return filterVal.map(j => {
					return v[j];
				});
			});
		},

		downloadExcelRepairCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'报修数量',
				'维修应收费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairCntAndPriceByMonth
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修数量和维修费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDoneCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'维修完成数量',
				'维修完成费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDoneCntAndPriceByMonth
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修完成数量和费用${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairSignCntAndPriceByMonth(obj) {
			const tHeader = [
				'日期',
				'维修完成数量',
				'维修完成费用',
				'维修实收费用'
			];
			const filterVal = ['mthTemp', 'cnt', 'price', 'realprice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairSignCntAndPriceByMonth
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `维修完成数量和费用（签单时间）${htimeFormat(
						new Date()
					)}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDeviceCntTop(obj) {
			const tHeader = ['设备名称', '报修次数', '报修价格'];
			const filterVal = ['deviceName', 'cnt', 'repairPrice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDeviceCntTop
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `报修次数、价格${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		},

		downloadExcelRepairDeviceTypeCntTop(obj) {
			const tHeader = ['类型', '报修次数', '报修价格'];
			const filterVal = ['deviceType', 'cnt', 'repairPrice'];
			const data = this.formatJson(
				filterVal,
				this.tableDownDataRepairDeviceTypeCntTop
			);
			import('@/util/Export2Excel').then(excel => {
				excel.export_json_to_excel({
					header: tHeader, // 表头 必填
					data, // 具体数据 必填
					filename: `类型报修次数、价格${htimeFormat(new Date())}`, // 非必填
					autoWidth: true, // 非必填
					bookType: 'xlsx' // 非必填
				});
			});
		}
	}
};
</script>

<style scoped>
.date_tag {
	position: absolute;
	top: 76px;
	right: 13px;
	height: 30px;
}
.search_box {
	margin-bottom: 20px;
}
.echart_item {
	width: 500px;
	height: 360px;
	margin: 0 auto;
}
.echart_box {
	width: 460px;
	height: 360px;
}
.search_button {
	margin-left: 10px;
}
.el-col {
	margin-bottom: 20px;
}
.clearfix {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.clearfix > span {
	flex: 1;
}

.bigEchartsBox {
	width: 100%;
	height: calc(100vh - 160px);
}
</style>
