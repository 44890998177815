<template>
  <el-row>
    <el-col :span="24">
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="88px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData.name" clearable placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="电话" prop="telphone">
          <el-input v-model="formData.telphone" clearable placeholder="请输入电话" />
        </el-form-item>
        <el-form-item label="联系人" prop="contract">
          <el-input v-model="formData.contract" clearable placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="银行账号" prop="bankcode">
          <el-input v-model="formData.bankcode" clearable placeholder="请输入银行账号" />
        </el-form-item>
        <el-form-item label="开户行" prop="bank">
          <el-input v-model="formData.bank" clearable placeholder="请输入开户行" />
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="formData.address" clearable placeholder="请输入地址" />
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { supplierAddService, supplierEditService } from '@s/spareParts/SupplierService';

export default {
	props: ['selectData_p', 'isFastAddSupplier_p'],
	components: {},
	data() {
		const phoneValid = (rule, value, callback) => {
			if (value !== '') {
				const regTel = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
				const regPhone = /^(?:(?:\+|00)86)?1\d{10}$/;
				if (!regTel.test(value) && !regPhone.test(value)) {
					callback(new Error('请输入正确的电话号码'));
				}
				callback();
			}
		};
		return {
			editId: -1,
			// 弹窗
			formData: {
				name: '',
				telphone: '',
				contract: '',
				bankcode: '',
				bank: '',
				address: ''
			},
			formRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				telphone: [
					{
						required: true,
						message: '请输入电话',
						trigger: 'blur'
					},
					{
						validator: phoneValid,
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					console.log('newValue[0] 快速添加供应商');
					console.log(newValue[0]);
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await supplierAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await supplierEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
				this.$emit('update:isRefreshFastAddSupplier_p', true);
				this.$emit('isToParentSupplierNmae_p', this.formData.name || '');
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
