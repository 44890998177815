<template>
	<div class="app-container">
		<MeasureSearchForm @searchForm="searchFormHandler"></MeasureSearchForm>
		<!-- <Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'devBase'"
		></Button> -->
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="编号" prop="listNum"></el-table-column>
			<el-table-column label="服务公司" prop="orgName"></el-table-column>
			<el-table-column label="负责人" prop="dutyPeopleName"></el-table-column>
			<el-table-column label="设备数量" prop="devNumber"></el-table-column>
			<el-table-column label="科室" prop="hospitalOfficeName"></el-table-column>
			<el-table-column label="实施日期" prop="executionTime">
				<template slot-scope="scope">
					{{ scope.row.executionTime | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="状态" prop="state">
				<template slot-scope="scope">
					<el-link v-if="Number(scope.row.state) === 0" type="warning" :underline="false">未开始</el-link>
					<el-link v-else-if="Number(scope.row.state) === 1" type="primary" :underline="false">进行中</el-link>
					<el-link v-else-if="Number(scope.row.state) === 2" type="success" :underline="false">已完成</el-link>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button id="btn-update-row" type="text" @click="showDetail(scope.row)">详情</el-button>
					<!-- <el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'devBase', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'devBase', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<!-- <Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DevBaseAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></DevBaseAddForm>
		</Dialog> -->
		<Dialog title="计量单信息" :visible.sync="isShowAEDialog" width="50%">
			<InspectionImplementAdd
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:editId_p="editId"
				:status_p="status"
				:isHideAction_p="true"
			></InspectionImplementAdd>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { measureListService, devBaseDeleteService, devBaseDetailService } from '@s/hospital/measure/MeasureService';
import Dialog from '@c/ui/Dialog';
/* import Button from '@c/ui/Button'; */
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
/* import DevBaseAddForm from '@f/hospital/equipment/DevBaseAdd.form'; */
import MeasureSearchForm from '@f/hospital/measure/MeasureSearch.form';
import InspectionImplementAdd from '@f/company/measure/MeasureImplementAdd.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	name: '',
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		/* Button, */
		/* DevBaseAddForm, */
		MeasureSearchForm,
		InspectionImplementAdd
	},
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.inspectionList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.inspectionList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.inspectionList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.inspectionList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.inspectionList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		async inspectionList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				hospitalId: hgetStorage('organization'),
				...this.searchForm
			};
			const res = await measureListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devBaseId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			// this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				devBaseId: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				devInfoId: row.id
			};
			const res = await devBaseDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.id);
					const dataJson = {
						ids: ids
					};
					await devBaseDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.inspectionList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		},
		showDetail(row) {
			this.isShowAEDialog = true;
			this.editId = row.id;
			this.status = row.state;
		}
	}
};
</script>
<style lang="less" scoped></style>
