import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 计量列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const measureListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				state: '',
				devId: '',
				dutyPeople: '',
				hospitalId: '',
				hospitalOfficeId: '',
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/measureAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 巡检计划负责人列表
 *@ClassAuthor: myw
 *@Date: 2021-06-04 13:54:38
 */
export const inspectionUserListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong/user/getUserByCompanyId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量计划添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const measureAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: 0,
				userId: 0,
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/measureAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const inspectionEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/measureUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const inspectionDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong_3/measure/measureDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 计量单详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const measureDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				measureId: '1',
				...data
			};
			const res = await hgetRequest('hlzlong_3/measure/measureByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 工厂下载表格
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const downloadExcelService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				inspectionId: '1',
				...data
			};
			const res = await hgetRequest('hlzlong_2/inspection/downloadExcel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

/*
 *@Description: 获取父级科室下所有子科室[],巡检设备【保养设备】按钮先调张龙的接口返回list给薄的设备列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const getSonIdsByDepartmentIdService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hlzlong_1/department/getSonIdsByDepartmentId', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
export default measureListService;
