<template>
	<el-aside class="sidebar-container">
		<el-menu
			class="el-menu-vertical-demo"
			router
			:default-openeds="defaultOpeneds"
			:default-active="defaultActive"
			:collapse="isCollapse_p"
			:unique-opened="true"
		>
			<router-link
				:to="{ path: '/home' }"
				v-if="!isCollapse_p"
				style="display: flex; just-item: center; line-height: 60px; font-weight: bold; color: #ffffff"
			>
				<img
					src="../assets/logo3.png"
					alt
					style="width: 40px; height: 30px; margin: 15px"
				/>
				医工云管理系统
			</router-link>
			<template v-for="(menu, index) in menuData">
				<span :key="index">
					<Submenu
						:menu_p="menu"
						v-if="menu.children && menu.children.length !== 0"
					></Submenu>
					<li v-if="menu.targetProperty == '_blank'" class="el-menu-item">
						<a
							:href="bigScreenUrl"
							target="_blank"
							style="color: #bfcbd9; display: inline-block; width: 100%"
							@click="hospitalBigScreen(menu.url)"
						>
							<i class="el-icon-message"></i>
							{{ menu.menuName }}
						</a>
					</li>
					<template v-else>
						<el-menu-item
							v-if="!menu.children || menu.children.length === 0"
							:route="{
								path: `/${menu.frontendRoute ? menu.frontendRoute.path : '404'}`
							}"
							:index="`${menu.id}`"
						>
							<i class="el-icon-message"></i>
							{{ menu.menuName }}
						</el-menu-item>
					</template>
				</span>
				<!-- :route="{ path: `/${menu.frontendRoute.path}` }" -->
			</template>
			<!-- <el-submenu index="2">
				<template slot="title">
					<i class="el-icon-message"></i>
					日志管理
				</template>
				<el-menu-item index="2-1" :route="{ path: '/log/system' }">系统日志</el-menu-item>
			</el-submenu>
			<el-submenu index="3">
				<template slot="title">
					<i class="el-icon-message"></i>
					基础数据管理
				</template>
				<el-menu-item index="3-1">省市区管理</el-menu-item>
			</el-submenu>-->
		</el-menu>
	</el-aside>
</template>

<script>
import Submenu from '@c/ui/Submenu.vue';
import { userMenuAuthorizeService } from '@s/system/UserService';
import { mergeRoutes } from '@u/index';
import { getTreePNodeByNodeId } from '@u/htools.tree';
import { hsetStorage, hgetStorage } from '../util/htools.web';

export default {
	props: ['isCollapse_p'],
	components: {
		Submenu
	},
	data() {
		return {
			menuData: null,
			defaultOpeneds: [],
			defaultActive: '',
			bigScreenUrl: null
		};
	},
	async created() {
		await this.menuAuthorizeList();
		let routeName = null;
		// 点开详情  左侧不展开问题 打印出同级的routeName  console.log(this.$route.name); 111
		if (this.$route.name === 'DevBaseDetai') {
			routeName = 'DevBase';
		} else if (this.$route.name === 'RepairDetail') {
			routeName = 'HospitalRepair';
		} else if (this.$route.name === 'contractDetai') {
			routeName = 'HospitalContract';
		} else if (this.$route.name === 'CompanyContractDetail') {
			routeName = 'CompanyContract';
		} else if (
			this.$route.name === 'EventProcessing' ||
			this.$route.name === 'EventProcessingCondition'
		) {
			routeName = 'ParameterSetting';
		} else {
			routeName = this.$route.name;
		}
		getTreePNodeByNodeId(this.menuData, routeName, null, (node, pNode) => {
			// console.log('node.powers');
			// console.log(node.powers);
			// console.log(this.menuData);
			// console.log(node);
			// console.log(pNode);
			hsetStorage('btnPowers', node.powers);
			this.defaultActive = `${node.id}`;
			// pNode?.id && 医院端报错 TODELETE
			pNode?.id && this.defaultOpeneds.push(`${pNode.id}`);
		});
	},
	watch: {
		isCollapse_p: {
			handler(newValue) {
				const i = document.querySelector('.el-submenu__icon-arrow');
				if (newValue) {
					console.log(i);
				} else {
					console.log(newValue);
				}
			}
		},
		'$store.state.userStore.isRefreshAside': {
			handler(newValue) {
				newValue && this.menuAuthorizeList();
				this.$store.commit('setRefreshAside', false);
			}
		},
		$route: {
			handler() {
				let routeName = null;

				// 点开详情  左侧不展开问题 打印出同级的routeName  console.log(this.$route.name); 222
				if (this.$route.name === 'DevBaseDetai') {
					routeName = 'DevBase';
				} else if (this.$route.name === 'contractDetai') {
					routeName = 'HospitalContract';
				} else if (this.$route.name === 'CompanyContractDetail') {
					routeName = 'CompanyContract';
				} else if (
					this.$route.name === 'EventProcessing' ||
					this.$route.name === 'EventProcessingCondition'
				) {
					routeName = 'ParameterSetting';
				} else {
					routeName = this.$route.name;
				}
				// 20210702
				// if (this.$route.name === 'AddEnterprise') {
				// 	routeName = 'Enterprise';
				// } else if (this.$route.name === 'EventProcessing' || this.$route.name === 'EventProcessingCondition') {
				// 	routeName = 'ParameterSetting';
				// } else {
				// 	routeName = this.$route.name;
				// }
				getTreePNodeByNodeId(this.menuData, routeName, null, (node, pNode) => {
					hsetStorage('btnPowers', node.powers);
					this.defaultActive = `${node.id}`;
					this.defaultOpeneds = [];
					// pNode?.id && 医院端报错 TODELETE
					pNode?.id && this.defaultOpeneds.push(`${pNode.id}`);
				});
			}
		}
	},
	methods: {
		clickMenu(menu) {
			hsetStorage('btnPowers', menu.powers);
		},
		async menuAuthorizeList() {
			const res = await userMenuAuthorizeService({});
			this.menuData = mergeRoutes(res);
		},
		hospitalBigScreen(menu) {
			if (hgetStorage('organizationType') === 2) {
				this.bigScreenUrl = `${menu}?companyId=${hgetStorage('organization')}`;
			}
			if (hgetStorage('organizationType') === 1) {
				this.bigScreenUrl = `${menu}?hospitalId=${hgetStorage('organization')}`;
			}
		}
	}
};
</script>

<style lang="less">
.logo {
	display: flex;
	justify-content: left;
	align-items: center;
	margin: 15px 0;
}
.logo img {
	width: 30px;
	height: 30px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
}
.sidebar-container {
	color: #333;
	background: #304156;
	white-space: nowrap;
	/* width: 219px !important; */
	width: auto !important;
	.el-menu {
		background: transparent;
		color: rgb(191, 203, 217);
	}
	.el-menu-item {
		background-color: #1f2d3d !important;
		color: rgb(191, 203, 217);
		&:hover {
			background-color: #001528 !important;
		}
		&.is-active {
			color: #409eff !important;
		}
	}
	/deep/ .el-submenu__title {
		color: rgb(191, 203, 217);
		background-color: #1f2d3d !important;
		&:hover {
			background-color: #263445 !important;
		}
	}
	.el-menu--collapse {
		i {
			margin-right: 17px !important;
		}
	}
	.is-active > .el-submenu__title {
		color: #f4f4f5 !important;
	}
}
.el-submenu__title i {
	margin-right: 10px;
}
</style>
