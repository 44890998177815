<template>
  <el-row>
    <el-col :span="24">
      <el-form ref="formEle" :model="formData" :rules="formRules" size="small" label-width="88px" :disabled="isShowDetail_p">
        <el-form-item label="库房" prop="warehouseId">
          <el-select v-model="formData.warehouseId" placeholder="请选择" class="select-item">
            <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请日期" prop="requestTime">
          <el-date-picker v-model="formData.requestTime" type="datetime" placeholder="请选择日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="formData.info" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" style="text-align: right">
      <span class="dialog-footer" style="text-align: right">
        <el-button @click="formCancel">取 消</el-button>
        <el-button type="primary" @click="formSubmit">确 定</el-button>
      </span>
    </el-col>
  </el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { checkStockAddService, checkStockEditService } from '@s/spareParts/CheckStockService';
// eslint-disable-next-line import/no-cycle
import { warehouseSelectListService, warehouseonlymyselfService } from '@s/spareParts/WarehouseService';

export default {
	// isShowDetail_p 表格过来的不能编辑
	props: ['selectData_p', 'isShowDetail_p'],
	components: {},
	data() {
		return {
			editId: -1,
			// 弹窗
			formData: {
				warehouseId: '',
				requestTime: '',
				info: ''
			},
			formRules: {
				warehouseId: [
					{
						required: true,
						message: '请选择库房',
						trigger: 'change'
					}
				],
				requestTime: [
					{
						required: true,
						message: '请选择日期',
						trigger: 'blur'
					}
				]
			},
			warehouseData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					// this.warehouseData.length === 0 && (await this.warehouseList());
					this.warehouseData.length === 0 && (await this.warehouseonlymyself());
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		// this.warehouseList();
		this.warehouseonlymyself();
	},
	methods: {
		async warehouseonlymyself() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await warehouseonlymyselfService(dataJson);
			this.warehouseData = [...res.records];
		},
		async warehouseList() {
			const dataJson = {};
			const res = await warehouseSelectListService(dataJson);
			this.warehouseData = res;
		},
		formSubmit() {
			this.$refs.formEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await checkStockAddService(this.formData);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await checkStockEditService(dataJson);
					}
					this.formCancel();
					this.$emit('update:isRefreshList_p', true);
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
